import React from 'react';
import { connect } from 'react-redux';
import { Button, Drawer, Spin, List, Avatar, Tag } from 'antd';
import type { ProjectListVo } from '../../types/types';
import {
  projectListActions,
  projectListSelectors,
} from '../../feature/projectList/projectList';
import { editionActions } from '../../feature/edition/edition';
import { GetText } from '../../data/LanguageHelper';
import { popupHelper } from '../../feature/alert/popupHelper';
import { history, ROUTE_CONST } from '../../utils/history';
import { PROJECT_CONST } from '../../data/config';
import { PROJECT_CLASS } from '../../data/Constants';
import { EmbedAssets } from '../../images/EmbedAssets';

class ProjectListView extends React.Component {
  // --------------------- Constructor ------------------------

  constructor(props) {
    super(props);

    // bind functions
    // this.handleDeleteProject = this.handleDeleteProject.bind(this);

    // retrieve project list
    this.props.dispatch(projectListActions.getAll());
  }

  // --------------------- react ------------------------

  componentDidMount() {}

  shouldComponentUpdate(nextProps) {
    // const differentTitle = this.props.title !== nextProps.title;
    // const differentDone = this.props.done !== nextProps.done
    // return differentTitle || differentDone;

    // COnditions to re render this component, only if the layout list is different!
    const projectlistDifferent = this.props.projects !== nextProps.projects;
    const projectIDDifferent = !!(
      // case no project
      (
        !this.props.project ||
        !nextProps.project ||
        // case project ids are different
        this.props.project.id !== nextProps.project.id
      )
    );

    return projectlistDifferent || projectIDDifferent;
  }

  // --------------------- methods ------------------------

  handleClose() {
    history.push(ROUTE_CONST.HOME);
  }

  handleProjectClick(item: ProjectListVo) {
    const { classname, id, isFlash } = item;

    // case old editor project, display popup and allow redirect!
    if (isFlash) {
      popupHelper.showOldEditorPopup(item);
      return;
    }

    // open in other editor type, reload with correct editor class
    // force load via GET to fix issues with projects not loading correct
    // if (classname !== PROJECT_CONST.project_class) {
    //   window.location = `/${classname}?proj=${id}`;
    //   return;
    // }
    this.handleClose();
    window.location = `/${classname}?proj=${id}`;
  }

  // --------------------- render ------------------------

  render() {
    const { user, projects, projectList } = this.props;
    const currentProject = this.props.project;

    return (
      <Spin spinning={projects.loading}>
        <Drawer
          title={GetText('projectList.title')}
          width={600}
          onClose={(e) => {
            this.handleClose();
          }}
          // visible={this.state.visible}
          visible
          bodyStyle={{ paddingBottom: 80 }}
        >
          {/* <h3>All your projects: {currentProject? `(current: ${currentProject.id} )` : ""} </h3> */}
          {projects.loading && <em>Loading projects...</em>}
          {projects.error && (
            <span className="text-danger">
              ERROR:
              {projects.error}
            </span>
          )}
          {projectList && (
            <div>
              <List
                itemLayout="horizontal"
                className="projectList"
                dataSource={projectList}
                renderItem={(item) => {
                  const selected =
                    currentProject && item.id === currentProject.id;
                  const projectClass = item.classname;
                  const avatarSrc = EmbedAssets.classNameImage(item.classname);
                  return (
                    <List.Item
                      // actions={[<a key="list-loadmore-edit">load</a>, <a key="list-loadmore-more">delete</a>]}
                      onClick={
                        !selected
                          ? () => {
                              this.handleProjectClick(item);
                            }
                          : null
                      }
                      className={`${selected ? 'selected' : ''} item`}
                    >
                      <List.Item.Meta
                        // avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                        avatar={
                          <Avatar
                            src={avatarSrc}
                            shape="circle"
                            size={80}
                            style={
                              {
                                // color: '#f56a00',
                                // backgroundColor: `${(selected)? "#0169a1": "#cccccc"}`
                              }
                            }
                          />
                        }
                        // title={<a href="#" onClick={ ()=> {this.handleProjectLoad(item.id)}} >{item.name}</a>}
                        title={
                          <span>
                            {item.isFlash && <Tag color="orange">Flash</Tag>}
                            {item.name}
                          </span>
                        }
                        description={
                          <div>
                            {/* <div>Description:{JSON.stringify(item)}</div> */}
                            <div>
                              <b>{GetText('projectList.item.desc')}:</b>{' '}
                              {item.description}{' '}
                            </div>
                            <div>
                              <b>{GetText('projectList.item.date')}:</b>{' '}
                              {item.last_modified}
                            </div>
                            {item.isFlash && (
                              <div style={{ color: 'orange' }}>
                                <i>{GetText('projectlist.oldEditor.desc')}</i>
                              </div>
                            )}

                            {/* <div><Button size="small" type="primary" onClick={ ()=> {this.handleProjectLoad(item.id)}}>Open</Button></div> */}
                          </div>
                        }
                      />
                    </List.Item>
                  );
                }}
              />
            </div>
          )}
        </Drawer>
      </Spin>
    );
  }
}

// Redux map
function mapStateToProps(state) {
  const { authentication, edition, projects } = state;
  const { user } = authentication;
  const { project } = edition;

  // we need user and projects
  return {
    user,
    project,
    projects,
    projectList: projectListSelectors.getAllProjects(state),
  };
}

// Redux connect
const reduxConnected = connect(mapStateToProps)(ProjectListView);
export { reduxConnected as ProjectListView };
