import { createSelector, createReducer } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { cloneDeep } from 'lodash';
import type { ProjectOptions } from '../../types/types';
import { API } from '../../utils/API';
import { alertActions } from '../alert/alert';
import { IEnvelope, CardHelper } from '../../utils/card/CardHelper';

import {
  GetCoverLabelName,
  HasClassicCoverCorners,
  HasClassicCoverText,
} from '../../utils/coverHelper';
import { authSelectors } from '../auth/authentification';
import { GetProjectCode } from '../../utils/ProductHelper';
import { SwapObjectKeyValue } from '../../utils/ObjectUtils';
import { CANVAS_FORMAT } from '../../utils/canvas/CanvasHelper';
import { pixelToCm } from '../../utils/MeasureUtils';
import { getSpineWidthMM } from '../../utils/spineHelper';
import {
  IsAlbumEditor,
  IsCalendarEditor,
  IsCanvasEditor,
  IsCardEditor,
} from '../../data/config';
import { ALBUM_TYPES, PAPER_QUALITY } from '../../data/Constants';
import { GetProjectOptions } from '../project/GetProjectOptions';

/** **********************************
// ACTIONS TYPES
************************************ */

const GETALL_REQUEST = 'PRICING/GET_ALL_REQUEST';
const GETALL_ERROR = 'PRICING/GET_ALL_ERROR';
const GETALL_SUCCESS = 'PRICING/GET_ALL_SUCCESS';

/** **********************************
// REDUCERS
************************************ */

const initialState = {
  isLoading: false, // is the price loading
  error: null, // error with current pricing

  priceList: null, // list of prices by docs
  optionList: null, // list of options
  canvasCustomPriceList: null, // list of canvas custom pricing
  envelopePriceList: null, // list of enveloppe pricing
  classicCoverOptions: null, // list of all options for classic covers
};

// see doc here for immutabiliy with createReducer function instead
// https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns/
// export default function reducer(state = initialState, action = {})

const reducer = createReducer(initialState, {
  // --------------------- GET ALL ------------------------

  [GETALL_REQUEST]: (state, action) => {
    state.isLoading = true;
    state.error = null;
  },
  [GETALL_SUCCESS]: (state, action) => {
    state.isLoading = false;
    state.error = null;
    state.priceList = action.priceList;
    state.optionList = action.optionList;
    state.canvasCustomPriceList = action.canvasCustomPriceList;
    state.envelopePriceList = action.envelopePriceList;
    state.classicCoverOptions = action.classicCoverOptions;
  },
  [GETALL_ERROR]: (state, action) => {
    state.isLoading = false;
    state.error = action.error;
  },
});
export default reducer;

/** **********************************
// Simple ACTIONS Creators
************************************ */

/** **********************************
// COMPLEX ASYNC ACTIONS
************************************ */

function GetAll() {
  return (dispatch, getState) => {
    dispatch(request());
    const vendorID = authSelectors.GetVendorID(getState());
    API.getPricing(vendorID).then(
      (resultObj) => {
        batch(() => {
          // when retrieving full pricing list, we also retrieve options for projects (stock, available options etc..)
          // TODO: handle this also...
          /*
            "contemporary": {
              "corners": 0,
              "cover_text": 0,
              "covers": 0,
              "custom_cover": 0,
              "envelopes": 0,
              "flyleaf": 1,
              "inserts": 1,
              "matte": 1,
              "paper_weight": 1,
              "varnish": 1
            },
            "corners": 1,
            "cover_text": 1,
            "covers": 1,
            "custom_cover": 1,
            "envelopes": 0,
            "flyleaf": 1,
            "inserts": 1,
            "matte": 1,
            "paper_weight": 1,
            "varnish": 1
            }
            */
          dispatch(success(resultObj));
        });
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: GETALL_REQUEST };
  }
  function success({
    priceList,
    optionList,
    canvasCustomPriceList,
    envelopePriceList,
    classicCoverOptions,
  }) {
    return {
      type: GETALL_SUCCESS,
      priceList,
      optionList,
      canvasCustomPriceList,
      envelopePriceList,
      classicCoverOptions,
    };
  }
  function failure(error) {
    return { type: GETALL_ERROR, error };
  }
}

// --------------------- SELECTORS ------------------------

// const GetProjectSelector = state => state.edition.project? state.edition.project : null;
const GetProjectOptionsSelector = (state, explicitOptions) =>
  explicitOptions || GetProjectOptions(state.edition.project);
const GetPricingOptionList = (state) =>
  state.pricing.optionList ? state.pricing.optionList : {};
const GetClassicCoverOptionsList = (state) =>
  state.pricing.classicCoverOptions ? state.pricing.classicCoverOptions : {};
const GetEnvelopePriceListSelector = (state) =>
  state.pricing.envelopePriceList ? state.pricing.envelopePriceList : {};
const GetCanvasCustomPriceList = (state) =>
  IsCanvasEditor() && state.pricing.canvasCustomPriceList
    ? state.pricing.canvasCustomPriceList
    : {};
const IsPricingLoaded = (state) =>
  state.pricing && state.pricing.priceList !== null;

// get all envelopes {{ 0: "white", 1: "black", etc... }}
const GetAllEnvelopes = createSelector(
  GetEnvelopePriceListSelector,
  (envelopePriceList) => {
    if (!envelopePriceList.envelopes) return null;

    const allEnvelopes = {};
    Object.keys(envelopePriceList.envelopes).forEach((key) => {
      allEnvelopes[key] = String(envelopePriceList.envelopes[key])
        .toLowerCase()
        .split(' ')
        .join('_');
    });
    return allEnvelopes;
  }
);

// get all envelop codes {{ "white": 0, "black": 1, etc... }}
const GetEnvelopeCodes = createSelector(GetAllEnvelopes, (envelopes) => {
  if (!envelopes) return null;
  const inverted = SwapObjectKeyValue(envelopes);
  return inverted;
});

// get available stock and price : {"white" : {id:"white", color:"#fffff", code:0, price:0.90}}
const GetAvailableEnvelopeStockAndPrice: Record<IEnvelope> = createSelector(
  GetProjectOptionsSelector,
  GetAllEnvelopes,
  GetEnvelopeCodes,
  GetEnvelopePriceListSelector,
  (options: ProjectOptions, allEnvelopes, envelopeCodes, envelopePriceList) => {
    if (!options || !envelopePriceList || !envelopePriceList.envelopes_cost)
      return [];

    // use doc code to filter stock and price
    const docCode = GetProjectCode(options);
    const docEnvelopes = envelopePriceList.envelopes_cost[1][docCode];
    const envelopeList = {};
    if (docEnvelopes) {
      Object.keys(docEnvelopes).forEach((key) => {
        const envelopeID = allEnvelopes[key];
        envelopeList[envelopeID] = {
          id: envelopeID,
          color: CardHelper.GetEnvelopeColorByID(envelopeID),
          code: key,
          price: docEnvelopes[key],
        };
      });
    }

    return envelopeList;
  }
);

const GetProductPriceByID = createSelector(
  (state) => state.pricing.priceList,
  (priceList) => {
    try {
      const productPricesByID = {};
      priceList.forEach((item, key) => {
        productPricesByID[item.product_id] = item;
      });
      return productPricesByID;
    } catch (e) {
      // console.warn("Not able to create product price by ID:" + e)
      return {};
    }
  }
);

const GetCoverPriceByType = createSelector(
  GetPricingOptionList,
  (optionList) => {
    // alert("OptionList: " + JSON.stringify(optionList));

    // list all cover types
    const coverPrice = {};
    if (optionList.covers) {
      Object.keys(optionList.covers).forEach((key, index, arr) => {
        coverPrice[optionList.covers[key]] = parseFloat(
          optionList.covers_cost[1][key]
        ); // TODO: ask Keith why we have a parent node "1" here...
      });
    }

    // alert("Coverprices:" + JSON.stringify(coverPrice) )

    return coverPrice;
  }
);

// For cards and calendars, as for project the price of options is depending on apges
const GetProjectOptionsPrice = createSelector(
  GetProjectOptionsSelector,
  (state) => authSelectors?.GetVendorID(state),
  GetProductPriceByID,
  GetCoverPriceByType,
  GetPricingOptionList,
  GetAvailableEnvelopeStockAndPrice,
  (
    projectOptions: ProjectOptions,
    vendorID,
    productPricesByID,
    coverPriceByType,
    priceOptionList,
    envelopePriceList
  ) => {
    const options = {
      coated: 0,
      matte: 0,
      paper: 0,
      quality_250: 0,
      cover_matte: 0,
      flyleaf: 0,

      // classic cover
      cover_corner_price: 0,
      cover_text_price: 0,
    };

    if (!projectOptions) return options;

    try {
      const docCode: number = GetProjectCode(projectOptions);
      const productDetail = productPricesByID[docCode];
      const price = Number(productDetail.cost);

      // ---- ALBUMS ----
      if (IsAlbumEditor()) {
        // add page price
        let additionalPages =
          projectOptions.numPages - Number(productDetail.base_page);
        additionalPages -= 1; // remove cover from cost

        // 250gr cost
        options.quality_250 =
          Number(productDetail.cost_250gr) +
          additionalPages * Number(productDetail.page_cost_250gr);

        // insert/paper
        options.paper =
          Number(productDetail.cost_inserts) +
          additionalPages * Number(productDetail.page_cost_inserts);

        // coating
        options.coated =
          Number(productDetail.cost_varnish) +
          additionalPages * Number(productDetail.page_cost_varnish);

        // matte
        options.matte =
          Number(productDetail.cost_matte) +
          additionalPages * Number(productDetail.page_cost_matte);

        // cover matte
        options.cover_matte = Number(productDetail.cover_cost_matte);

        // fly leaf
        options.flyleaf = priceOptionList.flyleaf_cost[vendorID][docCode];

        const classicOptions = projectOptions.coverClassicOptions;
        if (classicOptions) {
          // corners
          options.cover_corner_price = Number(
            priceOptionList.corners_cost[vendorID]
          );
          options.cover_text_price += Number(
            priceOptionList.cover_text_cost[vendorID]
          );
        }
      }

      // ---- CALENDAR ----
      if (IsCalendarEditor()) {
        // coated
        options.coated = Number(
          priceOptionList.varnish_cost[vendorID][docCode]
        );
      }

      // ---- CARD ----
      if (IsCardEditor()) {
        // finish
        options.coated = Number(
          priceOptionList.varnish_cost[vendorID][docCode]
        );
        options.matte = Number(priceOptionList.matte_cost[vendorID][docCode]);
      }
    } catch (e) {
      console.warn(`No project price found: ${e.toString()}`);
    }
    return options;
  }
);

const GetClassicCoverStock = createSelector(
  GetProjectOptionsSelector,
  GetClassicCoverOptionsList,
  (projectOptions: ProjectOptions, classicCoverOptionList) => {
    // security
    if (!projectOptions) return null;

    // classic cover stock is dependant on the spine width
    const spineWith = getSpineWidthMM(projectOptions);
    const stock = classicCoverOptionList;
    const product = GetProjectCode(projectOptions);

    // we filter the spine stock by the current spine width
    const filteredStockBySpineWidth = cloneDeep(stock);
    // for (const optionName in stock) {
    Object.keys(stock).forEach((optionName) => {
      const stockOK = false;
      if (
        stock[optionName][product] &&
        stock[optionName][product].stock === 'ok'
      ) {
        const stockOK = true;
        let spineOK = true;
        // Do we need to check the spine?
        if (stock[optionName][product] && stock[optionName][product].spine) {
          spineOK = false;
          const spineRanges: Array =
            stock[optionName][product].spine.split(',');
          let spineValues: Array;
          let startVal: number;
          let endVal: number;
          for (let i = 0; i < spineRanges.length; i++) {
            spineValues = spineRanges[i].split('-');
            startVal = parseFloat(spineValues[0]);
            // case spinewidth is equal to start range value (works even if range is not complete, for example just a value separated by "," )
            if (startVal === spineWith) spineOK = true;
            // case real range
            if (spineValues.length > 1) {
              endVal = parseFloat(spineValues[1]);
              if (spineWith >= startVal && spineWith <= endVal) spineOK = true;
            }
          }
        }

        // replace object by boolean
        filteredStockBySpineWidth[optionName] = stockOK && spineOK;
      }
    });

    return filteredStockBySpineWidth;
  }
);

const GetProjectPrice = createSelector(
  GetProjectOptionsSelector,
  (state) => authSelectors?.GetVendorID(state),
  GetProductPriceByID,
  GetCoverPriceByType,
  GetPricingOptionList,
  GetAvailableEnvelopeStockAndPrice,
  GetCanvasCustomPriceList,
  (
    projectOptions: ProjectOptions,
    vendorID: string,
    productPricesByID,
    coverPriceByType,
    priceOptionList,
    envelopePriceList,
    canvasCustomPriceList
  ) => {
    // product_id: "30651"
    // vendor_id: "1"
    // cost: "11.95"
    // base_page: "20"
    // page_price: "0.45"
    // max_pages: "150"
    // page_cost: "0.45"
    // page_cost_inserts: 0.05
    // page_cost_250gr: 0.1
    // page_cost_varnish: "0.09"
    // page_cost_matte: "0.08"
    // cost_inserts: 5
    // cost_250gr: 2
    // cost_varnish: "2.5"
    // cost_matte: "2.5"

    if (!projectOptions) return 0;

    try {
      // alert(JSON.stringify(productPricesByID[project.docCode]));
      const docCode: number = GetProjectCode(projectOptions);
      const productDetail = productPricesByID[docCode];
      if (productDetail === undefined) {
        // This seems to happen when you change product class from one to another
        // console.log('pricing not ready yet returning 0');
        return 0;
      }
      let price = Number(productDetail.cost);

      // add page price
      let additionalPages =
        projectOptions.numPages - Number(productDetail.base_page);

      // ---- ALBUMS ----
      if (IsAlbumEditor()) {
        if (additionalPages > 0 && additionalPages % 2 === 1)
          additionalPages -= 1; // remove cover from cost

        // cover price in case of classic or contemporary
        if (
          projectOptions.type === ALBUM_TYPES.CLASSIC ||
          projectOptions.type === ALBUM_TYPES.CONTEMPORARY
        )
          price += coverPriceByType[GetCoverLabelName(projectOptions.type)];

        // ---- COVER COST ----
        // classic cover cost
        const classicOptions = projectOptions.coverClassicOptions;
        if (classicOptions) {
          // corners
          if (HasClassicCoverText(projectOptions))
            price += Number(priceOptionList.cover_text_cost[vendorID]);
          // text price
          if (HasClassicCoverCorners(projectOptions))
            price += Number(priceOptionList.corners_cost[vendorID]);
        }

        price += additionalPages * Number(productDetail.page_cost);

        // 250gr cost
        if (projectOptions.pagePaperQuality === PAPER_QUALITY.QUALITY_250) {
          price += Number(productDetail.cost_250gr);
          price += additionalPages * Number(productDetail.page_cost_250gr);
        }

        // insert
        if (projectOptions.paper) {
          price += Number(productDetail.cost_inserts);
          price += additionalPages * Number(productDetail.page_cost_inserts);
        }

        if (projectOptions.coated) {
          price += Number(productDetail.cost_varnish);
          price += additionalPages * Number(productDetail.page_cost_varnish);
        }

        // matte
        if (projectOptions.matte) {
          price += Number(productDetail.cost_matte);
          price += additionalPages * Number(productDetail.page_cost_matte);
        }

        // flyleaf
        if (
          projectOptions.flyleaf &&
          projectOptions.flyleaf !== 'white' &&
          priceOptionList.flyleaf_cost[vendorID][docCode]
        ) {
          price += Number(
            priceOptionList.flyleaf_cost[vendorID][docCode][
              projectOptions.flyleaf
            ]
          );
        }

        // let flyleafCost:number = 0;

        // if(optionsPriceList.flyleaf_cost[vendorId] !==undefined)
        // {
        //   if(optionsPriceList.flyleaf_cost[vendorId][docType] !==undefined)
        //   {
        //     if(optionsPriceList.flyleaf_cost[vendorId][docType][colorId] !==undefined)
        //     flyleafCost = parseFloat(optionsPriceList.flyleaf_cost[vendorId][docType][colorId]);
        //   }
        // }

        // cover matte
        if (projectOptions.cover_matte) {
          price += Number(productDetail.cover_cost_matte);
        }
      }

      // ---- CALENDAR ----
      if (IsCalendarEditor()) {
        // coated
        if (projectOptions.coated) {
          price += Number(priceOptionList.varnish_cost[vendorID][docCode]);
        }
      }

      // ---- CARD ----
      if (IsCardEditor()) {
        // finish
        if (projectOptions.coated) {
          price += Number(priceOptionList.varnish_cost[vendorID][docCode]);
        }
        if (projectOptions.matte) {
          price += Number(priceOptionList.matte_cost[vendorID][docCode]);
        }
        // enveloppe
        if (projectOptions.envelope !== 'none') {
          price += Number(envelopePriceList[projectOptions.envelope].price);
        }
      }

      // --- CANVAS ---
      if (
        IsCanvasEditor() &&
        projectOptions.canvasFormat === CANVAS_FORMAT.FREE
      ) {
        // round current width and height to nearest 5 version
        const roundNearest5 = (_value: number) => Math.round(_value / 5) * 5;

        let w = roundNearest5(pixelToCm(projectOptions.width));
        let h = roundNearest5(pixelToCm(projectOptions.height));

        // for pricing calculation, it's always in portrait
        if (w > h) {
          const t = w;
          w = h;
          h = t;
        }

        // find correct node!
        price = 0;
        canvasCustomPriceList.forEach((item) => {
          if (
            item.product_id === docCode &&
            item.height === h &&
            item.width === w
          )
            price = item.cost;
        });
      }

      return price.toFixed(2);

      // return Number(productPricesByID[project.docCode].cost);
    } catch (e) {
      console.warn(`No project price found: ${e.toString()}`);
      return 0;
    }
  }
);

/** **********************************
// EXPORT PUBLIC ACTIONS & selectors
************************************ */

export const pricingSelectors = {
  GetProjectPrice,
  GetProjectOptionsPrice,
  GetAvailableEnvelopeStockAndPrice,
  GetClassicCoverStock,
  IsPricingLoaded,
  // GetAvailableClassicCoverFabric
};

export const pricingActions = {
  GetAll, // retreive all pricing data
};
