export const DATE_ACTION_ENUM = {
  NOTES: '[notes]',
  MINICALENDAR_MONTH_PREVIOUS: '[mcmpre]', // mini calendar of previous month
  MINICALENDAR_MONTH_NEXT: '[mcmpost]', // mini calendar or next month
  MINICALENDAR_WEEK_PREVIOUS: '[mcwpre]',
  MINICALENDAR_WEEK_NEXT: '[mcwpost]',
  MINICALENDAR_PREVIOUS: '[mcpre]',
  MINICALENDAR_NEXT: '[mcpost]',
  MINICALENDAR_MONTH: '[mcmvar]', // month + year for mini calendar (September 2017) -> magnet
  MINICALENDAR_WEEK: '[mcwvar]', // vertical week for mini calender -> magnet
  MINICALENDAR_FULL: '[mcfull]', // mini calendar full (organizer first page)

  URL: '[url]', // editor url (also used in cards)
  YEAR: '[year]',
  MONTH: '[month]', // january, february
  MONTHYEAR: '[monthyear]', // january 2014, february 2014
  WEEKOFYEAR: '[weekofyear]', // week number
  WEEKDAY: '[weekday]', // Mon, Tue, ect..
  WEEKDAYFULL: '[weekdayfull]', // monday, tuesday etc...
  DATE: '[date]',
  DATENAME: '[datename]', // Calendar Lux Rules:  Mon 1, Tue 2
  DAYDATE: '[daydate]', // 1->31 simple number day date
  HOURS: '[hour]', // hours in organizer
};

export const DAYDATE_TYPE = {
  WEEKDAY: 'daydateWeekDay', // day is a normal week day
  WEEKEND: 'daydateWeekEnd', // day is a weekend day
  OFFSET: 'daydateOffset', // day is from another month
};

export const ALL_MONTHS = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];
export const WEEK_DAYS = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];
