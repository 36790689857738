import type { IPage } from '../types/types';
import { IsClassicCoverPage } from './coverHelper';
import { LAYOUT_TYPE } from '../feature/layouts/layoutHelper';
import { GetUID } from './UID';
import { GetText } from '../data/LanguageHelper';
import {
  PROJECT_CONST,
  IsAlbumEditor,
  IsCalendarEditor,
  IsCardEditor,
} from '../data/config';
import { GetDoc } from './ProductHelper';
import { CalendarHelper } from './calendar/CalendarHelper';
import { CardHelper } from './card/CardHelper';
import { PROJECT_CLASS } from '../data/Constants';
import { Project } from '../data/Project';

/**
 *
 * @param {number} pageIndex
 * @param {number} pageWidth
 * @param {number} pageHeight ()
 * @returns {IPage}
 */
export function CreatePage(pageIndex, pageWidth, pageHeight) {
  return {
    id: GetUID(),
    index: pageIndex,
    isCover: false, // TODO: this should not be used!! cover pages shoudl be calculated based on PageIndex and DOCID
    width: pageWidth,
    height: pageHeight,
    frames: [],
    coverClassicOptions: null,
  };
}

// export class Page
// {
//  /**
//   *
//   * @param {Number} pageIndex
//   * @param {Number} pageWidth
//   * @param {Number} pageHeight
//   * @param {Layout} layout
//   */
//   constructor( pageIndex, pageWidth, pageHeight, layout)
//   {
//    this.index = pageIndex;
//    this.isCover = false;
//    this.width = pageWidth;
//    this.height = pageHeight;

//    // TODO: ... review element needed for the page..
//    this.frames = [];
//    ApplyLayoutToPage( this, layout, null);
//   }
// }

/**
 *
 * @param {IPage} page
 * @param {string} frameID
 * @returns
 */
export function PageContainsFrame(page, frameID) {
  let contains = false;
  page.frames.forEach((frame, index) => {
    if (frame.id === frameID) contains = true;
  });
  return contains;
}

export function GetPageOfFrame(pageList, frameID) {
  let thePage = null;
  pageList.forEach((page, pageIndex) => {
    page.frames.forEach((frame, frameIndex) => {
      if (frame.id === frameID) thePage = page;
    });
  });
  return thePage;
}

/**
 * content pages repetition for ordering.
 * Actually only used in cards right now but system is ready
 */
export function GetPagesRepetition(project: Project): number {
  if (IsCardEditor())
    return CardHelper.GetNumRepetitionFromPack(project.cardPack);
  return 1;
}

/**
 *
 * @param {IPage} page
 * @param {string} frameID
 * @returns
 */
export function GetPageFrameByID(page, frameID: string) {
  for (const frame of page.frames) {
    if (frame.id === frameID) return frame;
  }
}

/**
 * Get page groups depending on the project type
 */
/**
 *
 * @param {*} docID
 * @param {*} pageList
 * @returns {Array.<Array.<IPage>>}
 */
export function GetPageGroupList(docID, pageList) {
  const pageGroup = [];
  const projectClass = PROJECT_CONST.project_class;
  const doc = GetDoc(docID);

  for (let index = 0; index < pageList.length; index++) {
    const group = getPageGroup(docID, index, pageList);
    pageGroup.push(group);
    index += group.length - 1;
  }

  return pageGroup;
}

export function PageCanBeDragged(page: IPage): boolean {
  if (page.isCover) return false;

  if (page.isCalendar) return false;

  if (
    page.layoutType === LAYOUT_TYPE.POSTCARD_BG ||
    page.layoutType === LAYOUT_TYPE.PROMO_URL
  )
    return false;

  return true;
}

/**
 *
 * @param {Array<IPage>} pageGroup
 */
export function getPageGroupDisplayName(pageGroup, docID) {
  let displayName = '';

  // -- cover --
  if (
    pageGroup.length === 1 &&
    pageGroup[0].index === 0 &&
    (IsCalendarEditor() || IsAlbumEditor())
  ) {
    displayName =
      IsAlbumEditor() && !IsClassicCoverPage(pageGroup[0])
        ? `${GetText('common.backCover')}   |   ${GetText('common.front')}`
        : GetText('common.cover');
  }

  // -- pages --
  else {
    pageGroup.forEach((page, index, arr) => {
      if (page.index) {
        // inside pages for exampe do not have indexes, should not be displayed

        // ---- CALENDAR ----
        if (IsCalendarEditor()) {
          const monthIndex = CalendarHelper.GetPageCalendarIndex(page.index);
          const monthWithOffset = CalendarHelper.GetMonthIndexWithOffSet(
            monthIndex,
            true
          );

          // TODO: handle this for all calendars
          displayName = GetText(
            `calendar.month.${CalendarHelper.GetMonthNameByIndex(
              monthWithOffset
            )}`
          );
        }

        // ---- CARD ----
        else if (IsCardEditor()) {
          displayName = ''; // we do not display page group name for cards, we display "per page"
        } else {
          if (displayName !== '') displayName += ' - ';
          displayName += `${GetText('common.page')} ${page.index}`;
        }
      }
    });
  }

  return displayName;
}

export function GetPageDisplayName(docID, pageIndex) {
  let displayName = '';
  const doc = GetDoc(docID);

  // ---- CARDS ----
  if (IsCardEditor()) {
    const displayNum = doc.pages_per_group;
    displayName =
      pageIndex % displayNum === 0
        ? GetText('common.front')
        : pageIndex % displayNum === displayNum - 1
        ? GetText('common.backCover')
        : GetText('common.inside');
  }

  return displayName;
}

/**
 * Retrieve the page group corresponding to a specific page index.
 * @param {string} docID
 * @param {number} pageIndex
 * @param {Array.<IPage>} pageList
 * @returns {Array<IPage>}
 */
export function getPageGroup(docID: string, pageIndex: number, pageList) {
  let pageGroup = [];
  const projectClass = PROJECT_CONST.project_class;
  const doc = GetDoc(docID);

  // ---- ALBUMS ----
  if (projectClass === PROJECT_CLASS.ALBUM) {
    // case for only one page display
    if (
      pageIndex === 0 ||
      pageIndex === 1 ||
      pageIndex === pageList.length - 1
    ) {
      pageGroup.push(pageList[pageIndex]);
      return pageGroup;
    }

    // case normal pages
    if (pageIndex % 2 === 0) {
      pageGroup.push(pageList[pageIndex]);
      pageGroup.push(pageList[pageIndex + 1]);
    } else {
      pageGroup.push(pageList[pageIndex - 1]);
      pageGroup.push(pageList[pageIndex]);
    }
    return pageGroup;
  }

  // ---- CALENDARS ----
  if (projectClass === PROJECT_CLASS.CALENDAR) {
    // case project calendar has cover
    if (pageIndex === 0) {
      pageGroup.push(pageList[pageIndex]);
      return pageGroup;
    }

    // case pages display is only 1
    if (doc.pages_per_group === 1) {
      pageGroup.push(pageList[pageIndex]);
      return pageGroup;
    }

    // case normal pages, check page display
    if (pageIndex % 2 === 1) {
      pageGroup.push(pageList[pageIndex]);
      pageGroup.push(pageList[pageIndex + 1]);
    } else {
      pageGroup.push(pageList[pageIndex - 1]);
      pageGroup.push(pageList[pageIndex]);
    }
    return pageGroup;
  }

  // ---- CARDS ----
  if (projectClass === PROJECT_CLASS.CARD) {
    const pagePerGroup = doc.pages_per_group;
    const indexInGroup = pageIndex % pagePerGroup;
    const startIndex = pageIndex - indexInGroup;
    for (let index = 0; index < pagePerGroup; index++) {
      pageGroup.push(pageList[startIndex + index]);
    }

    // if more than 2 pages per group, we want to change the display
    if (doc.pages_per_group > 2) {
      // if pages per group is bigger than 2 (for panormic card per example), we do not display the whole group.
      if (pageGroup[0].index === pageIndex) pageGroup = [pageGroup[0]];
      // new array with the first page
      else if (pageGroup[pageGroup.length - 1].index === pageIndex)
        pageGroup = [pageGroup[pageGroup.length - 1]];
      // new array with the last page
      else {
        // page group with all middle/inside pages
        pageGroup.pop();
        pageGroup.shift();
      }
    }

    return pageGroup;
  }

  // ---- CANVAS ----
  if (projectClass === PROJECT_CLASS.CANVAS) {
    pageGroup.push(pageList[pageIndex]);
    return pageGroup;
  }

  // TODO: we should never be here..
  return null;
}
