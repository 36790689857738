import { SettingOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import React from 'react';
import { CirclePicker } from 'react-color';
import { connect } from 'react-redux';
import { GetText } from '../../../data/LanguageHelper';
import { GetProductCatalogue } from '../../../data/productCatalogue';
import {
  editionActions,
  editionSelectors,
} from '../../../feature/edition/edition';
import { pricingSelectors } from '../../../feature/pricing/pricing';
import { GetProjectOptions } from '../../../feature/project/GetProjectOptions';
import { GetDoc } from '../../../utils/ProductHelper';
import ColorPicker from '../../../_components/ColorPicker';
import { ForeignObjectUnscaled } from '../../../_components/SvgForeignObjectUnscaled';

interface InsidePageAreaProps {
  x: number;
  y: number;
  width: number;
  height: number;
}

class InsidePageArea extends React.Component<InsidePageAreaProps> {
  // --------------------- constructor ------------------------

  // constructor( props ){
  //     super(props);
  // }

  // --------------------- react ------------------------

  // --------------------- methods ------------------------

  handleFlyLeafChange = (newColor) => {
    const options = GetProjectOptions(this.props.project);
    options.flyleaf = Object.keys(GetProductCatalogue().flyleafColors).find(
      (key) => GetProductCatalogue().flyleafColors[key] === newColor
    );
    // update options
    this.props.dispatch(editionActions.UpdateProjectOptions(options));
  };

  /// ///////////////////////////////////////////////////
  // Render
  /// ///////////////////////////////////////////////////
  render() {
    // const { pageIndex, page, photosByID, backgroundsByID, clipartsByID } = this.props;
    const flyLeafColors = GetProductCatalogue().flyleafColors;
    const colorList = Object.keys(flyLeafColors).map((key) => ({
      hex: flyLeafColors[key],
      name: key,
    }));
    const fillColor = flyLeafColors[this.props.project.flyleaf];
    const allowColorChange = GetDoc(this.props.project.docID).flyleaf;

    const flyleafPriceAndStock = this.props.priceOptions.flyleaf;
    // TODO: maybe here we could handle the stock by verifiying the colors and the price..

    let currentPrice = '';

    if (
      flyleafPriceAndStock &&
      flyleafPriceAndStock[this.props.project.flyleaf]
    ) {
      currentPrice = Number(flyleafPriceAndStock[this.props.project.flyleaf]);
      if (!Number.isNaN(currentPrice) && currentPrice !== 0)
        currentPrice = ` (+${currentPrice.toFixed(2)}€)`;
      else currentPrice = '';
    }

    return (
      <svg
        x={this.props.x}
        y={this.props.y}
        width={this.props.width}
        height={this.props.height}
      >
        <g>
          <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            className="insidePageArea"
            style={{ fill: fillColor }}
          />
          {/* <text x={this.props.width*0.47} y={this.props.height*0.49}
                        className="unselectable"
                        fontSize="20"
                        fontStyle="italic"
                        fill="#cccccc"
                        >
                        {GetText("common.inside")}
                    </text> */}

          {!this.props.isPreviewMode && (
            <ForeignObjectUnscaled
              editionScale={this.props.editionScale}
              x={0}
              y={0}
              width={this.props.width}
              height={this.props.height}
            >
              <div className="insidePageEditor wrapperFlexCenterColumn svgForeignObjectFix">
                <p>{GetText('common.inside')}</p>

                {
                  /// --------------------- COLOR CHANGE ------------------------
                  allowColorChange && (
                    <Popover
                      // placement="left"
                      // title={GetText("edition.cover.classic.editor.panel.options.title1")}
                      content={
                        <div className="wrapperFlexCenterColumn">
                          <p className="unselectable">
                            {GetText('albums.flyleaf.title') + currentPrice}
                          </p>

                          <ColorPicker
                            colors={colorList}
                            selected={fillColor}
                            onChange={(color) =>
                              this.handleFlyLeafChange(color.hex)
                            }
                          />
                        </div>
                      }
                    >
                      <Button
                        type="default"
                        icon={<SettingOutlined />}
                        // className="button"
                        shape="circle-outline"
                        onClick={(e) => e.target.focus()}
                        style={{
                          // width: this.props.height/15,
                          // height: this.props.height/15,
                          // fontSize: (this.props.height/15/2) + "px",
                          // fontSize:this.props.width/10,
                          // color:this.allCoverOptionsColors[currentOptions.coverLabelName],
                          backgroundColor: '#ffffff66',
                        }}
                      />
                    </Popover>
                  )
                }
              </div>
            </ForeignObjectUnscaled>
          )}
        </g>
      </svg>
    );
  }
}

// --------------------- redux ------------------------

// Redux map
function mapStateToProps(state) {
  return {
    project: editionSelectors.GetProjectSelector(state),
    priceOptions: pricingSelectors.GetProjectOptionsPrice(state),
  };
}

// --------------------- export ------------------------

const reduxConnected = connect(mapStateToProps)(InsidePageArea);
export { reduxConnected as InsidePageArea };
