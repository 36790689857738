import React from 'react';
import { Colors } from '../../../data/Colors';

export const FramePostcardBG = (props) => {
  // const {page, docID} = props;
  const width = 876;
  const height = 620;

  const lineStyle = {
    pointerEvents: 'none',
    stroke: Colors.BLACK,
    strokeWidth: '1px',
  };

  // ---- RENDER ----
  return (
    <svg width="100%" height="100%" viewBox={`0 0 ${width} ${height}`}>
      <g>
        {/* // BG */}
        <rect
          x={0}
          y={0}
          width={width}
          height={height}
          style={{
            pointerEvents: 'none',
            // stroke: Colors.BLACK,
            // strokeWidth: "1px",
            fill: Colors.RED_FLASH,
            fillOpacity: '0',
          }}
        />

        {
          // --- STAMP ---
          <rect
            x={706}
            y={56}
            width={119}
            height={136}
            style={{
              pointerEvents: 'none',
              stroke: Colors.BLACK,
              strokeWidth: '1px',
              fill: Colors.RED_FLASH,
              fillOpacity: '0',
            }}
          />
        }

        {
          // --- Separator ---
          <line x1={473} y1={156} x2={473} y2={156 + 380} style={lineStyle} />
        }

        {/*  --- adress lines ---*/}
        <line x1={500} y1={350} x2={823} y2={350} style={lineStyle} />
        <line x1={500} y1={400} x2={823} y2={400} style={lineStyle} />
        <line x1={500} y1={450} x2={823} y2={450} style={lineStyle} />
        <line x1={500} y1={500} x2={823} y2={500} style={lineStyle} />
      </g>
    </svg>
  );
};

// public function drawPostCardBack( forPrint:Boolean = false ):*
// {
//   var postCardBackground:Sprite = new Sprite();

//   var backgroundBounds : Rectangle = BackgroundsManager.instance.getPageBackgroundBounds(); // postcard background has same bounds than project background bounds
//   var ratio:number = backgroundBounds.width / 876; // ratio from old image width
//   var g:Graphics = postCardBackground.graphics;

//   g.clear();

//   //bg
//   g.lineStyle(0,0,0,true,LineScaleMode.NORMAL);
//   g.beginFill(Colors.RED_FLASH, 0);
//   g.drawRect(0,0,backgroundBounds.width,backgroundBounds.height);//g.drawRect(0,0,pageVo.bounds.width,pageVo.bounds.height);
//   g.endFill();

//   //stamp
//   g.lineStyle(1,0,1,true,LineScaleMode.NORMAL);
//   g.drawRect(Math.round(706*ratio),Math.round(56*ratio),Math.round(119*ratio),Math.round(136*ratio));

//   //separator
//   g.moveTo(Math.round(473*ratio),Math.round(156*ratio));
//   g.lineTo(Math.round(473*ratio),Math.round((156+380)*ratio));

//   //Address lines
//   g.moveTo(Math.round(500*ratio),Math.round(350*ratio));
//   g.lineTo(Math.round(823*ratio),Math.round(350*ratio));

//   g.moveTo(Math.round(500*ratio),Math.round(400*ratio));
//   g.lineTo(Math.round(823*ratio),Math.round(400*ratio));

//   g.moveTo(Math.round(500*ratio),Math.round(450*ratio));
//   g.lineTo(Math.round(823*ratio),Math.round(450*ratio));

//   g.moveTo(Math.round(500*ratio),Math.round(500*ratio));
//   g.lineTo(Math.round(823*ratio),Math.round(500*ratio));

// /* //Center it
//   postCardBackground.x = -postCardBackground.width/2;
//   postCardBackground.y = -postCardBackground.height/2;

//   //add child it
//   if(!postCardBackground.parent) addChild(postCardBackground);*/

//   if(forPrint)
//   {
//     var qualityScale :number = 3; // for best performancess
//     var MAX_SIZE :number = 2000; // with a max width of 2000 to avoid problems with upload
//     if(backgroundBounds.width *qualityScale > MAX_SIZE) qualityScale = MAX_SIZE/backgroundBounds.width;
//     if(backgroundBounds.height *qualityScale > MAX_SIZE) qualityScale = MAX_SIZE/backgroundBounds.height;
//     /*
//     var highBmd : BitmapData = new BitmapData(bmd.width*qualityScale, bmd.height*qualityScale,true, 0x000000);
//     var m:Matrix = new Matrix();
//     m.translate(highBmd.width*.5, highBmd.height*.5);
//     m.scale(qualityScale,qualityScale);
//     highBmd.draw(bmd, m, null,null, null, true);
//     */
//     var bmd:BitmapData = SnapShot.snap(postCardBackground,qualityScale);// two times bigger for best print
//     return bmd
//   }
//   else
//     return postCardBackground;
