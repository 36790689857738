import {
  GetProjectCover,
  GetProjectCoverType,
  GetCoverLabelName,
} from '../../utils/coverHelper';
import { GetProjectCode } from '../../utils/ProductHelper';
import { OrderHelper } from '../order/orderHelper';
import { GetOrderPriceURL, PROJECT_CONST } from '../../data/config';
import { COVER_TYPE, PROJECT_CLASS } from '../../data/Constants';
import { Project } from '../../data/Project';

/**
 *  generate price url
 */
export function generatePriceUrl(
  project: Project,
  vendorID: string,
  getJobId = false
): string {
  // console.log("orderHelper.generatePriceUrl : getJobId is : "+getJobId);

  // get base url
  let url = GetOrderPriceURL();

  // prepare url
  url += '?';
  url += `cp=${new Date().getTime().toString()}`;

  url += `&pcode=${GetProjectCode(project)}`;
  url += `&project=${project.id}`;
  url += `&vendor=${vendorID}`;

  // TODO: is this needed?
  // url += "&subvendor=" + subvendor;

  url += `&build=${PROJECT_CONST.build_start}`; // 90040
  // url += "&build=90040";

  // TODO: get correct lang!
  // url += "&lang=" + Infos.lang ; // CM.LocalLanguage; // TODO check if local needed more like fr-FR than FR
  // url += "&lang=fr-FR" ; // CM.LocalLanguage; // TODO check if local needed more like fr-FR than FR

  if (!getJobId) url += '&display=1';

  /// /////////////////////////////////////////////////////////////////
  // Cover
  const cover = GetProjectCover(project);
  let coverName = '0';
  let coverHasText = '0';
  let coverCorners = 'None';
  if (cover) {
    const coverType: string = GetProjectCoverType(project.type);
    coverName = GetCoverLabelName(project.type);
    if (coverType === COVER_TYPE.COVER_CLASSIC) {
      const classicOptions = cover.coverClassicOptions;
      coverName = classicOptions.cover;
      switch (classicOptions.corners) {
        case 'none':
          coverCorners = 'None';
          break;
        case 'roundgold':
          coverCorners = 'RoundGold';
          break;
        case 'roundsilver':
          coverCorners = 'RoundSilver';
          break;
        case 'squaregold':
          coverCorners = 'SquareGold';
          break;
        case 'squaresilver':
          coverCorners = 'SquareSilver';
          break;
      }

      // has text?
      if (
        classicOptions.line1 !== '' ||
        classicOptions.line2 !== '' ||
        classicOptions.spine !== ''
      )
        coverHasText = '1';
    }

    // CUSTOM COVERS
    else {
      // cover finish
      url += `&coverfinish=${project.cover_matte ? 'matte' : 'gloss'}`;
    }
  }

  url += `&cover=${coverName}`;
  url += `&covertext=${coverHasText}`;
  url += `&corners=${coverCorners}`;

  /// /////////////////////////////////////////////////////////////////

  // inserts (called paper)
  if (project.paper) url += '&paper=1';
  else url += '&paper=0';

  // paper quality
  url += `&paperQuality=${project.pagePaperQuality}`;

  // paper coated or not
  if (project.coated) url += '&coated=1';
  else url += '&coated=0';

  // matte
  if (project.matte) url += '&matte=1';
  else url += '&matte=0';

  // fly leaf color & page count
  if (project.classname === PROJECT_CLASS.ALBUM) {
    url += `&flyleaf=${project.flyleaf}`;
    url += `&page_count=${project.pageList.length - 1}`; // without cover page..
  }

  url += '&store=editor';
  // url += "&vendorpromo=" + getVendorPromo();
  url += '&vendorpromo=0'; // TODO: validate later, was already 0 in current online editor
  url += '&promodoc=0';

  // TODO: print warnings
  // if(getJobId && hasPrintWarnings) url += "&warned=1";
  // else url += "&warned=0";

  url += OrderHelper.WriteOrderUrlHeader(project);

  url += `&campaign=${PROJECT_CONST.campaign}`;

  if (PROJECT_CONST.branding) {
    url += `&branding=${PROJECT_CONST.branding}`;
  }

  // console.log("priceHelper.generatePriceUrl: "+ url);
  return url;
}
