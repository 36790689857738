/* eslint-disable import/no-dynamic-require */
import React from 'react';
import { connect } from 'react-redux';
import { Button, Drawer, Row, Col } from 'antd';
import { editionActions } from '../../feature/edition/edition';

import { GetText } from '../../data/LanguageHelper';
import { DebugFlags } from '../../debug/DebugFlags';
import { API } from '../../utils/API';
import { history, ROUTE_CONST } from '../../utils/history';
import {
  GetPrintsProductUrl,
  GetFunProductURL,
  PROJECT_CONST,
} from '../../data/config';
import { PROJECT_CLASS } from '../../data/Constants';
import { EmbedAssets } from '../../images/EmbedAssets';

class ProjectClassSelectionView extends React.Component {
  handleClose = () => {
    history.push(ROUTE_CONST.HOME);
  };

  handleOpenProjectList = () => {
    history.push(ROUTE_CONST.PROJECT_LIST);
  };

  handleButtonClick(classname) {
    // reset backend session object
    API.resetSessionProject().then(() => {
      // new project link
      const link = `/${classname}/new_project`;

      // clear current project to avoid issue with current project
      this.props.dispatch(editionActions.ClearProject());

      // wait the store to be updated
      setTimeout(() => {
        // fixing issue with project state management not working with history.push after a new project
        // is started
        // if (PROJECT_CONST.project_class === classname) {
        //  history.push(link);
        // } else {
        window.location = link;
        // }
      }, 50);
    });
  }

  // --------------------- render ------------------------

  render() {
    const productFilter: string[] = this.props.rebranding?.productFilter;
    const projectClassAvailable = Object.values(PROJECT_CLASS).filter(
      (productClass) => {
        if (!productFilter || productFilter.includes(productClass)) {
          return true;
        }
        return false;
      }
    );

    return (
      <Drawer
        open
        className="wizard"
        footer={null}
        headerStyle={{ display: 'none' }}
        placement="top"
        height="100%"
        onClose={this.handleClose}
      >
        {/* <Spin spinning={projects.loading} > */}
        <div className="topBar">
          <Button
            type="primary"
            onClick={(e) => {
              this.handleOpenProjectList();
            }}
          >
            {GetText('noProject.open.button')}
          </Button>
          <div className="spacer" />
          <Button
            type="primary"
            onClick={(e) => {
              this.handleClose();
            }}
          >
            {GetText('common.close')}
          </Button>
        </div>
        <div className="content">
          <h2>{GetText('wizard.choose.classname')}</h2>

          <div className="productsWrapper">
            {projectClassAvailable.map((value, index) => {
              // const disabled = ( value===PROJECT_CLASS.CANVAS );
              const disabled = false;

              return (
                <div key={`selection_${value}`}>
                  <div
                    role="button"
                    tabIndex="0"
                    className={`productSelection${
                      disabled ? ' unclickable' : ''
                    }`}
                    onClick={() => {
                      this.handleButtonClick(value);
                    }}
                  >
                    <h4>{GetText(`class.${value}`)}</h4>
                    <img src={EmbedAssets.classNameImage(value)} />
                    <Button
                      type="primary"
                      size="large"
                      disabled={!!disabled}
                      onClick={() => {
                        this.handleButtonClick(value);
                      }}
                    >
                      {GetText('common.start.new.project')}
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>

          {
            // --------------------- FUN PRODUCTS ------------------------
            !DebugFlags.DO_NOT_SHOW_OTHER_PRODUCTS && (
              <Row type="flex" justify="center" gutter={20}>
                <h3>{GetText('wizard.discover.online.prodcuts')}</h3>
                {/* <Col>
<a className="otherProductSelection"
onClick={()=>{ window.open(GetTextileURL(), "_blank"); }}
>
{/ * <h4>{GetText("wizard.other.product.btn.textile.label")}</h4> * /}
<div>
<img src={require("../../images/products/textile.png")} />
</div>
<Button type="primary" size="small"
>{GetText("wizard.other.product.btn.textile.label")}</Button>
</a>
</Col> */}
                <Col>
                  <div
                    role="button"
                    tabIndex="0"
                    className="otherProductSelection"
                    onClick={() => {
                      window.open(GetFunProductURL(), '_blank');
                    }}
                  >
                    <h5>{GetText('wizard.other.product.btn.fun.label')}</h5>
                    <img src={EmbedAssets.FunProductImage} />
                    <Button type="primary" size="small">
                      {GetText('common.start.new.project')}
                    </Button>
                  </div>
                </Col>

                <Col>
                  <div
                    role="button"
                    tabIndex="0"
                    className="otherProductSelection"
                    onClick={() => {
                      window.open(GetPrintsProductUrl(), '_blank');
                    }}
                  >
                    <h5>{GetText('wizard.other.product.btn.prints.label')}</h5>
                    <img src={EmbedAssets.ProductPrints} />
                    <Button type="primary" size="small">
                      {GetText('common.start.new.project')}
                    </Button>
                  </div>
                </Col>
              </Row>
            )
          }
          {/* </Spin> */}
        </div>
      </Drawer>
    );
  }
}

// Redux map
function mapStateToProps(state) {
  const { authentication, projects, edition, layouts } = state;
  const { user, rebranding } = authentication;
  const { project } = edition;

  // we need user and projects
  return {
    user,
    rebranding,
    project,
    projects,
    layouts,
  };
}

// Redux connect
const reduxConnected = connect(mapStateToProps)(ProjectClassSelectionView);
export { reduxConnected as ProjectClassSelectionView };
