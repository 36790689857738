import GrabIcon from './icons/grab-icon.png';
import InsideRotateIcon from './icons/inside_rotate.png';
import LayerDownIcon from './icons/layer_down.png';
import LayerUpIcon from './icons/layer_up.png';
import MaskIcon from './icons/mask.png';
import ShadowIcon from './icons/shadow.png';
import SwapIcon from './icons/swap.png';
import ZoomInIcon from './icons/zoom_in.png';
import ZoomOutIcon from './icons/zoom_out.png';

// TODO: replace those icons by svg format!
export const EmbedIcons = {
  GrabIcon,
  InsideRotateIcon,
  LayerDownIcon,
  LayerUpIcon,
  MaskIcon,
  ShadowIcon,
  SwapIcon,
  ZoomInIcon,
  ZoomOutIcon,
};
