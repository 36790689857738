import React from 'react';
import { Dropdown, Menu } from 'antd';
import { PROJECT_CONST } from '../data/config';
import { ENVIRONEMENT } from '../data/Constants';

interface CustomContextMenuProps {
  debugOnly: boolean; // menu displayed only on debug mode
  items: Array; // list of items to display [{ label:"menu item", key:"item_key", callback:()=>{} }]
}

class CustomContextMenu extends React.Component<CustomContextMenuProps> {
  handleContextMenuClick = (
    clickParam // :import('antd/lib/menu').ClickParam) {
  ) => {
    this.props.items.forEach((item, index, arr) => {
      if (item.key === clickParam.key && item.callback) {
        item.callback();
      }
    });
  };

  render() {
    const menu = (
      <div>
        {(!this.props.debugOnly || PROJECT_CONST.env === ENVIRONEMENT.DEV) && (
          <Menu onClick={this.handleContextMenuClick}>
            {this.props.items.map((value, index, arr) => (
              <Menu.Item key={value.key}>{value.label}</Menu.Item>
            ))}
          </Menu>
        )}
      </div>
    );

    return (
      <Dropdown overlay={menu} trigger={['contextMenu']}>
        {this.props.children}
      </Dropdown>
    );
  }
}

export default CustomContextMenu;
