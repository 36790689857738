/** **********************************
// ACTIONS TYPES
************************************ */

const SUCCESS = 'ALERT/SUCCESS';
const ERROR = 'ALERT/ERROR';
const CLEAR = 'ALERT/CLEAR';

/** **********************************
// REDUCERS
************************************ */

const initialState = {
  type: null, // the type of the alert to display
  message: null, // the message to display
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SUCCESS:
      return {
        type: 'success',
        message: action.message,
      };

    case ERROR:
      return {
        type: 'error',
        message: action.message,
      };

    case CLEAR:
      return {};

    default:
      return state;
  }
}

/** **********************************
// SIMPLE ACTIONS (creator)
************************************ */

function success(message) {
  return { type: SUCCESS, message };
}

function error(message) {
  return { type: ERROR, message };
}

function clear() {
  return { type: CLEAR };
}

/** **********************************
// COMPLEX ASYNC ACTIONS
************************************ */

/** **********************************
// EXPORT PUBLIC ACTIONS
************************************ */

export const alertActions = {
  success,
  error,
  clear,
};
