import React from 'react';
import { connect } from 'react-redux';
import { Button, Icon, Upload, message, Tooltip, Popconfirm } from 'antd';
import type { IPage } from '../../../types/types';
import PageNavigatorItem from './PageNavigatorItem';
import {
  editionActions,
  editionSelectors,
} from '../../../feature/edition/edition';
import { getPageGroupDisplayName } from '../../../utils/pageHelper';
import { photoListSelector } from '../../../feature/photoList/photoList';
import { GetText } from '../../../data/LanguageHelper';
import ImgIcon from '../../../_components/ImgIcon';
import { CardHelper } from '../../../utils/card/CardHelper';
import { overlayerSelectors } from '../../../feature/overlayers/overlayers';
import {
  IsAlbumEditor,
  IsCalendarEditor,
  IsCardEditor,
  PROJECT_CONST,
} from '../../../data/config';
import { PROJECT_CLASS } from '../../../data/Constants';
import { EmbedIcons } from '../../../images/EmbedIcons';
// import VisibilitySensor from 'react-visibility-sensor';

class PageNavigator extends React.Component {
  // --------------------- constructor ------------------------

  constructor(props) {
    super(props);

    // keep track of currently selected item ref, so we can scroll to it!
    this.selectedItemRef = React.createRef();

    this.state = {
      groupOver: null, // index of group currently "over"
      groupDragging: null, // index of group currently "dragge"
      groupTarget: null, // index of current group drop target

      pageOver: null, // index of page currently "over"
      pageDragging: null, // index of page currently "dragged"
      pageTarget: null, // index of current page drop target
    };

    // TODO: lazyload items here..

    // this._handleSubmit = this._handleSubmit.bind(this);
    // this._handleImageChange = this._handleImageChange.bind(this);
    // this.checkRenderPreview = this.checkRenderPreview.bind(this);
  }

  // --------------------- react ------------------------

  componentDidMount() {
    // message.info("component did mount, we will now retrieve the photo list!");
    // this.selectedItemRef.current.scrollIntoView({ behavior: 'smooth' })
    // this.props.dispatch( photoListActions.getAll() );
    this.scrollToCurrentItem();
  }

  componentDidUpdate(prevProps, prevState) {
    // once page did change update scroll to new position!
    if (prevProps.selectedPage !== this.props.selectedPage) {
      this.scrollToCurrentItem();
    }
  }

  /*
    shouldComponentUpdate( newProps )
    {
        return
    }
    */

  // --------------------- methods ------------------------

  // _handleSubmit(e) {
  //   e.preventDefault();

  // alert("TODO : here we upload!");

  //   // TODO: do something with -> this.state.file
  //   //console.log('handle uploading-', this.state.file);
  // }

  scrollToCurrentItem() {
    if (this.selectedItemRef && this.selectedItemRef.current) {
      // && this.selectedItemRef.current.scrollIntoView)
      this.selectedItemRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  handleAddPagesAfterGroup = (pageGroup: Array<IPage>) => {
    // alert("adding pages after page:" + pageGroup[pageGroup.length-1].index )
    this.props.dispatch(
      editionActions.AddPagesAtIndex(pageGroup[pageGroup.length - 1].index + 1)
    );
  };

  // _handleImageChange(e) {

  //     e.preventDefault();

  //     // save file data
  //     var files = e.target.files;
  //     this.setState({ files:files, urlList:[] }, ()=>{ this.checkRenderPreview(0); });
  //     //this.checkRenderPreview(0);

  //     /*
  //   let reader = new FileReader();
  //   let files = e.target.files;
  //   let imageUrls = []

  //   for (let index = 0; index < files.length; index++) {
  //       let f = files[index];
  //       reader.onloadend = () => {
  //             imageUrls.push(reader.result);
  //             this.setState({
  //                 files: files,
  //                 urlList: imageUrls
  //         });
  //       }
  //       reader.readAsDataURL(f)
  //   }
  //   */
  // }

  // checkRenderPreview( fileIndex )
  // {
  //     let {files, urlList} = this.state;

  //     // break
  //     if(fileIndex > files.length -1 )
  //         return;

  //     let reader = new FileReader();
  //     let f = files[fileIndex].originFileObj;
  //     reader.onloadend = () => {
  //         urlList.push(reader.result);
  //         this.setState({
  //             files: files,
  //             urlList: urlList
  //         }, ()=>{
  //             this.checkRenderPreview( fileIndex +1  );
  //         });
  //     }
  //     reader.readAsDataURL(f)
  // }

  // onImageDragStart( evt, id, src )
  // {
  //     console.log("dragstart: image id: ", id);
  //     evt.dataTransfer.setData("id", id);
  //     evt.dataTransfer.setData("src", src);
  // }

  handlePageItemClick(pageIndex) {
    // notify store
    this.props.dispatch(editionActions.ChangePage(pageIndex));
  }

  handlePageDrop = (fromIndex: number, toIndex: number) => {
    this.props.dispatch(editionActions.SwapPages(fromIndex, toIndex));
  };

  //
  // Handle page drag/drop system
  //
  // handlePageOver = ( pageIndex )=> {
  //     this.checkSetState({pageIndex:pageIndex});
  // }
  // handlePageOut = ( pageIndex )=> {
  //     this.checkSetState({pageIndex:pageIndex});
  // }
  // handlePageDragOver = ( pageIndex )=> {
  //     this.checkSetState({pageIndex:pageIndex});
  // }
  // handlePageDragOut = ( pageIndex )=> {
  //     this.checkSetState({pageIndex:pageIndex});
  // }
  // handlePageDragStart = ( pageIndex )=> {
  //     this.checkSetState({pageIndex:pageIndex});
  // }
  // handlePageDragEnd = ( pageIndex )=> {
  //     this.checkSetState({pageIndex:pageIndex});
  // }
  // handlePageDrop = ( pageIndex ) =>
  // {}

  //
  // Handle group drag/drop system
  //
  handleGroupOver = (groupIndex) => {
    if (!this.state.groupDragging)
      this.checkSetState({ groupOver: groupIndex });
  };

  handleGroupOut = (groupIndex) => {
    this.checkSetState({ groupOver: null });
  };

  handleGroupDragOver = (groupIndex) => {
    if (
      this.state.groupDragging &&
      groupIndex !== 0 // cannot drag just after the cover
      // && this.state.groupDragging !==groupIndex
      // && this.state.groupDragging !==groupIndex+1)
    )
      this.checkSetState({ groupTarget: groupIndex });
  };

  handleGroupDragOut = (groupIndex) => {
    this.checkSetState({ groupTarget: null });
  };

  handleGroupDragStart = (groupIndex) => {
    this.checkSetState({ groupDragging: groupIndex });
  };

  handleGroupDragEnd = (groupIndex) => {
    this.checkSetState({ groupDragging: null, groupTarget: null });
  };

  handleGroupDrop = (groupIndex) => {
    if (this.state.groupDragging && this.state.groupTarget) {
      // alert("Dropped "+ this.state.groupDragging+" on :"+groupIndex);
      this.props.dispatch(
        editionActions.MovePageGroup(this.state.groupDragging, groupIndex)
      );
    }
    this.checkSetState({ groupDragging: null, groupTarget: null });
  };

  //
  // check state function
  //
  checkSetState = (newState) => {
    // console.log("---------------- checkSetState:" + JSON.stringify(newState));
    let stateChanged = false;
    Object.keys(newState).forEach((key, index) => {
      if (this.state[key] !== newState[key]) stateChanged = true;
    });
    // console.log("-changed:" +stateChanged);
    if (stateChanged) {
      this.setState(newState);
    }
  };

  // handleDraggerChange = ( info )=> {

  //     const status = info.file.status;
  //     message.info("File change: (" + info.file.name + "): " + status );

  //     const files = info.fileList;
  //     this.setState({
  //         files:files,
  //         urlList:[]
  //     },
  //     // on complete
  //     ()=>{
  //         this.checkRenderPreview(0);
  //     });

  //     /*

  //     const status = info.file.status;
  //     if (status !== 'uploading') {
  //         console.log(info.file, info.fileList);
  //     }
  //     if (status === 'done') {
  //         message.success(`${info.file.name} file uploaded successfully.`);
  //     } else if (status === 'error') {
  //         message.error(`${info.file.name} file upload failed.`);
  //     }
  //     */

  // }

  // SEE DOC : https://stackoverflow.com/questions/51514757/action-function-is-required-with-antd-upload-control-but-i-dont-need-it
  // dummyRequest = ({ file, onSuccess }) => {
  //     setTimeout(() => {
  //       onSuccess("ok");
  //     }, 0);
  //   };

  /// ///////////////////////////////////////////////////
  // Render
  /// ///////////////////////////////////////////////////
  render() {
    const {
      projectID,
      backgroundsByID,
      hasMaxPages,
      pageList,
      selectedPage,
      photos,
      photoListLoaded,
      clipartsByID,
      overlayersByID,
    } = this.props;

    // console.log("RENDER PAGE NAVIGATOR");

    // create pagegroups
    const pageGroups = this.props.pageGroup;
    let currentPageIndex = 0;

    // // start rendering pagenavigator only when the photo list is available
    // const startRender = (photos.photosList.length > 0)?true:false;

    // if (urlList.length > 0) {

    //     /*
    //     $imagePreview = (urlList.forEach(element => {
    //         <img className="photoPreview" src={element} />;
    //     }));
    //     */

    //    $imagePreview = urlList.map((src, key) => <div className="pic"
    //                                                 onDragStart = {(e)=> this.onImageDragStart(e, key, src)}
    //                                                 key={key}
    //                                                 draggable ><img className="photoPreview" draggable={false} src={src} /></div> );

    // } else {
    //     $imagePreview = (<div className="previewText">Please select an Image for Preview</div>);
    // }

    return (
      <div className="pageNavigator">
        <div key={projectID} className="itemsContainer">
          {photoListLoaded &&
            pageGroups &&
            pageGroups.map((group, groupIndex) => {
              const groupCanBeDragged =
                IsAlbumEditor() && groupIndex !== 0 && group.length > 1;

              // ---- DISPLAY OR NOT PAGE REPETITION ----
              // show card rep only for cards and middle page for folded cards
              const cardRep = CardHelper.ShouldDisplayPageGroupRepetition(
                this.props.docID,
                groupIndex
              );

              return (
                <div
                  role="button"
                  tabIndex="0"
                  className={
                    this.state.groupTarget === groupIndex
                      ? 'pageNavigatorGroup_dropTarget'
                      : 'pageNavigatorGroup'
                  }
                  key={groupIndex}
                  // mouse hover
                  onFocus={() => {}}
                  onMouseOver={
                    groupCanBeDragged
                      ? (e) => {
                          this.handleGroupOver(groupIndex);
                        }
                      : null
                  }
                  onMouseLeave={(e) => {
                    this.handleGroupOut(groupIndex);
                  }}
                  onDragOver={(e) => {
                    e.preventDefault();
                    this.handleGroupDragOver(groupIndex);
                  }}
                  onDrop={(e) => {
                    this.handleGroupDrop(groupIndex);
                  }}
                  style={{
                    margin: IsAlbumEditor()
                      ? '0px 30px 0px 25px'
                      : IsCardEditor()
                      ? CardHelper.GetNavigatorPageMargin(
                          this.props.docID,
                          groupIndex
                        )
                      : IsCalendarEditor()
                      ? '0px 15px 0px 15px'
                      : '0px 15px 0px 15px',
                  }}
                  // on={this.handleGroupDragOver(groupIndex)}
                  // onMouseLeave={ groupCanBeDragged && this.state.groupOver!=null? (e)=>{console.log("onMouseLeave");this.setState({groupOver : null})} : null}
                  // style={{
                  //     paddingRight:(this.state.groupTarget===groupIndex)? "80px" : null,
                  //     marginRight:(this.state.groupTarget===groupIndex)? "120px" : null,
                  //     // pointerEvents:(this.state.groupDragged)?"none":null
                  // }}
                  // onDragOver={
                  //     (this.state.groupDropTarget !==groupIndex) ? (e)=> {  console.log("dragover");this.setState({ groupDropTarget: groupIndex })}:null}
                  // // onDragLeave={
                  //     // this.state.groupDragged !==null? (e)=> {  console.log("dragleave");this.setState({ groupDropTarget: null })} : null }
                  // onDrop={
                  //     (e)=> {  console.log("drop");this.setState({ groupDropTarget: null})}}

                  // >
                >
                  <div className="pages">
                    {group.map((page, pageIndexInGroup) => {
                      const pageIndex = currentPageIndex;
                      currentPageIndex++;
                      return (
                        // <VisibilitySensor >
                        <PageNavigatorItem
                          key={pageIndex}
                          docID={this.props.docID}
                          ref={
                            selectedPage === pageIndex
                              ? this.selectedItemRef
                              : null
                          }
                          selected={selectedPage === pageIndex}
                          page={page}
                          pageIndex={pageIndex}
                          // visibility={(pageIndex < selectedPage+2 && pageIndex > selectedPage-2)}
                          // visibility={true}
                          backgroundsByID={backgroundsByID}
                          clipartsByID={clipartsByID}
                          overlayersByID={overlayersByID}
                          photos={photos}
                          onClick={(e) => {
                            this.handlePageItemClick(pageIndex);
                          }}
                          onPageDrop={this.handlePageDrop}
                        />
                        // </VisibilitySensor>
                      );
                    })}

                    {this.state.groupOver === groupIndex && (
                      <Tooltip
                        title={GetText('edition.navigator.groupCTA')}
                        style={{ backgroundColor: 'red' }}
                      >
                        <div
                          className="navigatorGroupGrab"
                          draggable={groupCanBeDragged}
                          onDragStart={(e) => {
                            this.handleGroupDragStart(groupIndex);
                          }}
                          onDragEnd={(e) => {
                            this.handleGroupDragEnd(groupIndex);
                          }}
                        >
                          <div className="icon">
                            <ImgIcon
                              icon={EmbedIcons.GrabIcon}
                              style={{ width: '90%', height: '90%' }}
                            />
                          </div>
                        </div>
                      </Tooltip>
                    )}
                  </div>

                  {/* Page Group display name  */}
                  <div className="pageDetail unselectable">
                    {getPageGroupDisplayName(group, this.props.docID)}
                  </div>

                  {
                    // ---- card repetition -----
                    cardRep && (
                      <div className="cardRep unselectable unclickable">
                        <span>
                          X
                          {CardHelper.GetNumRepetitionFromPack(
                            this.props.cardPack
                          )}
                        </span>
                      </div>
                    )
                  }

                  {/* ADD Pages between  */}
                  {PROJECT_CONST.project_class === PROJECT_CLASS.ALBUM &&
                    groupIndex !== 0 && (
                      <Tooltip
                        mouseEnterDelay={hasMaxPages ? 0.1 : 1.5}
                        title={
                          hasMaxPages
                            ? GetText('navigator.page.add.tooltip.max')
                            : GetText('navigator.page.add.tooltip')
                        }
                      >
                        <Popconfirm
                          title={GetText('navigator.page.add.title')}
                          okText={GetText('navigator.page.add.ok')}
                          cancelText={GetText('common.cancel')}
                          onConfirm={() => {
                            this.handleAddPagesAfterGroup(group);
                          }}
                          disabled={hasMaxPages}
                        >
                          {/* TODO: label */}
                          <Button
                            className="addPagesBetweenButton"
                            disabled={hasMaxPages}
                            type="dashed"
                            size="small"
                          >
                            +
                          </Button>
                        </Popconfirm>
                      </Tooltip>
                    )}

                  {this.state.groupTarget === groupIndex && (
                    <div
                      className="dropTargetArea"
                      onDragLeave={(e) => {
                        this.handleGroupDragOut(groupIndex);
                      }}
                    />
                    // onDragOver={e=>{e.preventDefault()}}
                    // onDrop={(e)=>{this.handleGroupDrop(groupIndex)}} />(this.state.groupTarget === groupIndex)
                  )}
                </div>
              );
            })}
          {/* <ProjectPageModifierPopup >
                        <Button type="dashed" size="small" className="addPagesButton">+ more pages</Button>
                    </ProjectPageModifierPopup> */}
        </div>
      </div>
    );
  }
}

// --------------------- redux ------------------------

// Redux map
function mapStateToProps(state) {
  const { edition, photos, backgrounds, cliparts } = state;
  const photosByID = photoListSelector.getAllPhotosByID(state);
  const photoList = photoListSelector.getProjectPhotosList(state);
  const hasMaxPages = editionSelectors.HasMaxPages(state);

  // what do we need
  return {
    hasMaxPages,
    docID: edition.project.docID,
    cardPack: edition.project.cardPack,
    selectedPage: edition.selectedPage,
    projectID: edition.project.id,
    pageList: edition.project.pageList,
    photos: photosByID,
    backgroundsByID: backgrounds.byID,
    clipartsByID: cliparts.byID,
    overlayersByID: overlayerSelectors.getAllOverlayersByID(state),
    pageGroup: editionSelectors.GetPageGroupListSelector(state),
    // photoListLoaded: (photoList && photoList.length > 0)
    photoListLoaded: !!photoList,
  };
}

// --------------------- expo ------------------------

// Redux connect
const reduxConnected = connect(mapStateToProps)(PageNavigator);
export { reduxConnected as PageNavigator };
