import { createSelector } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { API } from '../../utils/API';
import { alertActions } from '../alert/alert';
import { GetText } from '../../data/LanguageHelper';
import { history, ROUTE_CONST } from '../../utils/history';
import type { ProjectListVo } from '../../types/types';

/** **********************************
// ACTIONS TYPES
************************************ */

const GETALL_REQUEST = 'projectList/GETALL_REQUEST';
const GETALL_SUCCESS = 'projectList/GETALL_SUCCESS';
const GETALL_FAILURE = 'projectList/GETALL_FAILURE';

// const DELETE_REQUEST = "projectList/DELETE_REQUEST";
// const DELETE_SUCCESS = "projectList/DELETE_SUCCESS";
// const DELETE_FAILURE = "projectList/DELETE_FAILURE";

/** **********************************
// REDUCERS
************************************ */

const initialState = {
  items: [], // current project list
  loading: false, // current project list is loading
  error: null, // current error message
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GETALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GETALL_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.projectList,
      };
    case GETALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    /*
      case projectConstants.DELETE_REQUEST:
        // add 'deleting:true' property to user being deleted
        return {
          ...state,
          items: state.items.map(user =>
            user.id === action.id
              ? { ...user, deleting: true }
              : user
          )
        };
      case projectConstants.DELETE_SUCCESS:
        // remove deleted user from state
        return {
          items: state.items.filter(user => user.id !== action.id)
        };
      case projectConstants.DELETE_FAILURE:
        // remove 'deleting:true' property and add 'deleteError:[error]' property to user
        return {
          ...state,
          items: state.items.map(user => {
            if (user.id === action.id) {
              // make copy of user without 'deleting:true' property
              const { deleting, ...userCopy } = user;
              // return copy of user with 'deleteError:[error]' property
              return { ...userCopy, deleteError: action.error };
            }

            return user;
          })
        };
        */
    default:
      return state;
  }
}

/** **********************************
// SIMPLE ACTIONS (creator)
************************************ */

/*
export function requestLogin() {
  return { type: LOAD };
}

export function createWidget(widget) {
  return { type: CREATE, widget };
}

export function updateWidget(widget) {
  return { type: UPDATE, widget };
}

export function removeWidget(widget) {
  return { type: REMOVE, widget };
}
*/

/*
function sort (sortFilter){
  return { type: SORT, sortFilter };
}
*/

/** **********************************
// COMPLEX ASYNC ACTIONS
************************************ */

function getAll() {
  return (dispatch) => {
    dispatch(request());

    let newProjectsList: Array<ProjectListVo> = [];
    let oldProjectsList: Array<ProjectListVo> = [];

    API.getUserProjects()
      .then((projectList) => {
        // keep new project list
        newProjectsList = projectList;
        // now get old project list
        return API.getUserProjects(true);
        // dispatch(success(projectList));
        // dispatch(alertActions.success("Project list retrieved!"));
      })
      .then((projectList) => {
        oldProjectsList = projectList;
        oldProjectsList.forEach((item) => {
          item.isFlash = true;
          // get special text for old projects!
          // in old flash projects, doccode was stored in project description
          // try to recover "description"
          const oldDocCode = item.description;
          item.description = `${GetText(
            `class.${item.classname}`
          )} - code: ${oldDocCode}`;

          // try{
          //   let possibleDocID = GetDocIDByDocCode(item.description, item.classname, true);
          //   if(possibleDocID){
          //     //item.description = `${GetText("class." + item.classname)} - - ${GetText(PROJECT_CONST.project_class_no_s + ".prefix."+project.docID)} - ${numPages} ${GetText("common.pages")}`
          //     item.description = `${GetText("class." + item.classname)} - code: ${possibleDocID}`
          //   }
          // }catch(e){
          //   console.warn(`not able to retrieve docID for class:${item.classname} and code:${item.description} => ${e.message}`);
          // }
          // add a flash editor description
          // item.description = item.description;
        });

        // concat both project list (old + new)
        const allProjectList = oldProjectsList.concat(newProjectsList);
        dispatch(success(allProjectList));
        return allProjectList;
      })
      .catch((reason) => {
        reason = reason && reason.toString();
        dispatch(alertActions.error(reason));
        dispatch(failure(reason));
        history.push(ROUTE_CONST.LOGIN);
      });
  };

  function request() {
    return { type: GETALL_REQUEST };
  }
  function success(projectList) {
    return { type: GETALL_SUCCESS, projectList };
  }
  function failure(error) {
    return { type: GETALL_FAILURE, error };
  }
}

/** **********************************
// SELECTORS
************************************ */

const getAllProjectsSelector = (state) =>
  state.projects && state.projects.items;

// selector to get all projects sorted by modification date
const getAllProjectsSortedByDate = createSelector(
  getAllProjectsSelector,
  (allProjects) => {
    const sortedList: Array = cloneDeep(allProjects);
    if (sortedList) {
      sortedList.sort((a: ProjectListVo, b: ProjectListVo) =>
        Number(a.id) > Number(b.id) ? -1 : 1
      );
    }
    return sortedList;
  }
);

/** **********************************
// EXPORT
************************************ */

export const projectListSelectors = {
  getAllProjects: getAllProjectsSortedByDate,
};

export const projectListActions = {
  getAll,
  // delete: _delete
};
