import domtoimage, { Options } from 'dom-to-image';
import $ from 'jquery';

/**
 * Escape html characters from a string
 * @param {string} unsafe
 */
export function escapeHtml(unsafe) {
  return unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
}

export function trimHtmlTags(input): string {
  return input.replace(/<\/?[^>]+(>|$)/g, '');
}

export function openCanvasContentInNewWindow(canvas) {
  // convert canvas to blob then open
  canvas.toBlob((blob) => {
    openBlobInNewWindow(blob);
  }, 'image/png');
}

export function openBlobInNewWindow(blobOrFile) {
  const objectURL = URL.createObjectURL(blobOrFile);
  window.open(objectURL);
}

export function imageURLToBase64(imageUrl): Promise {
  return new Promise((resolve, reject) => {
    /// //////////////////////////////////////////////////////////////
    // // OLD METHOD
    //  var xhr = new XMLHttpRequest();
    //  xhr.onload = function() {
    //    var reader = new FileReader();
    //    reader.onloadend = function() {
    //     resolve(reader.result);
    //    }
    //    reader.readAsDataURL(xhr.response);
    //   };
    //  xhr.onerror = function(){
    //   reject("Error image to base64: " + xhr.status + " -> " + xhr.statusText);
    //  }
    //  xhr.withCredentials = true;

    //  // OLD
    //  xhr.open('GET', imageUrl);
    //  // USING PROXY (working but credentials are not..)
    //  // var proxyUrl = 'https://cors-anywhere.herokuapp.com/';
    //  // xhr.open('GET', proxyUrl + imageUrl);
    //  // xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

    //  // alert("optionalSessionID:" + optionalSessionID)
    //  // if(optionalSessionID)
    //  //  xhr.setRequestHeader('Set-Cookie', 'TTPOESESSID=' + optionalSessionID );
    //  xhr.responseType = 'blob';
    //  xhr.send();

    /// //////////////////////////////////////////////////////////////
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'arraybuffer';
    xhr.open('GET', imageUrl);
    xhr.onload = function () {
      let base64;
      let binary;
      let bytes;
      let mediaType;

      bytes = new Uint8Array(xhr.response);
      // NOTE String.fromCharCode.apply(String, ...
      // may cause "Maximum call stack size exceeded"
      binary = [].map.call(bytes, (byte) => String.fromCharCode(byte)).join('');
      mediaType = xhr.getResponseHeader('content-type');
      base64 = [
        'data:',
        mediaType ? `${mediaType};` : '',
        'base64,',
        btoa(binary),
      ].join('');

      resolve(base64);
    };
    xhr.onerror = function () {
      reject(`Error image to base64: ${xhr.status} -> ${xhr.statusText}`);
    };
    // xhr.withCredentials = true;
    xhr.send();
  });
}

export function dataURLToBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  const ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  const blob = new Blob([ab], { type: mimeString });
  return blob;
}

export function downloadDataURL(dataURL, filename = 'my_image.jpeg') {
  const link = document.createElement('a');
  link.download = filename;
  link.href = dataURL;
  link.click();
}

export function downloadTextAsFile(
  textString: string,
  filename = 'my_text.txt'
) {
  const myblob = new Blob([textString], { type: 'text/plain' });
  const dataURL = window.URL.createObjectURL(myblob);
  downloadDataURL(dataURL, filename);
}

/**
 * Convert dataURL (base64) to file
 */
export function dataURLtoFile(dataURL, filename) {
  // first convert to blob
  const blob: Blob = dataURLToBlob(dataURL);
  // then to file
  return new File([blob], filename, { type: blob.type });
}

//  var arr = dataurl.split(','),
//    mime = arr[0].match(/:(.*?);/)[1],
//    bstr = atob(arr[1]),
//    n = bstr.length,
//    u8arr = new Uint8Array(n);

//  while(n--){
//    u8arr[n] = bstr.charCodeAt(n);
//  }

//  return new File([u8arr], filename, {type:mime});
// }
// // var data = atob( dataURL.substring( "data:image/png;base64,".length ) ),
//  var data = atob( dataURL.replace(/^.*?base64,/, '') );
//  var asArray = new Uint8Array(data.length);

//  for( var i = 0, len = data.length; i < len; ++i ) {
//    asArray[i] = data.charCodeAt(i);
//  }

//  var blob = new Blob( [ asArray.buffer ], {type: "image/png"} );

/**
 * Export a dom element to image
 * @param {} node
 */
export function domElementToPng(
  node,
  scale = 10,
  workWithACopy = false
): Promise {
  let domElement: Node = node;
  if (workWithACopy) {
    // make a clone copy and put offscreen so it's not visible!
    domElement = $(node).clone()[0];
    // hide it
    domElement.style.position = 'absolute';
    domElement.style.left = '0px';
    domElement.style.top = '0px';
    domElement.style.padding = '0px';
    domElement.style.margin = '0px';
    domElement.style.zIndex = -1000;
    // add it so it can be rendered
    document.body.append(domElement);
  }

  // scale to print in higher quality
  const props: Options = scale
    ? {
        width: domElement.clientWidth * scale,
        height: domElement.clientHeight * scale,
        style: {
          transform: `scale(${scale})`,
          'transform-origin': 'top left',
        },
      }
    : null;

  /// /////////////////////////////////////////////////////////////
  // test preloading all images
  return replaceAllSVGImages(domElement).then(() =>
    domtoimage
      .toPng(domElement, props)
      // return _domToPNG(domElement)
      .then((dataUrl) => {
        // if we worked with a copy, just remove element now that we got the image
        if (workWithACopy) document.body.removeChild(domElement);
        return dataUrl;
      })
  );
}

// function _domToPNG(node:Node):Promise
// {
//   return html2canvas(node, {
//     allowTaint: true, logging: true, backgroundColor: '#ff0000', foreignObjectRendering: true,
//   })
//     .then((canvas) => {
//       // var ctx = canvas.getContext('2d');
//       // ctx.webkitImageSmoothingEnabled = true;
//       // ctx.mozImageSmoothingEnabled = true;
//       // ctx.imageSmoothingEnabled = true;
//       // var img = canvas.toDataURL('image/png');
//       // console.log(img);

//       let dataUrl = canvas.toDataURL();

//       // var img = new Image();
//       // img.src = dataUrl

//       // openBlobInNewWindow( dataURLToBlob(dataUrl));

//       // alert("dataURL:" + dataUrl);

//       // // test download
//       // downloadDataURL(dataUrl);

//       // // test add to page
//       // document.body.append(img);

//       // replace back
//       // replaceBackImages(replacedElements);

//       // resolve( img );
//       // resolve(dataUrl);
//       return dataUrl;
//     })
//     .catch((error) => {
//       console.error('oops, something went wrong with html2canvas: ', error);
//       Promise.reject(error);
//     });
// }

// function SVGToPng()
// {
//  const ctx = document.getElementById("canvas").getContext("2d");
//  const data = `
//  <svg xmlns='http://www.w3.org/2000/svg' width='200' height='200'>
//   <foreignObject width='100%' height='100%'>
//    <div xmlns='http://www.w3.org/1999/xhtml' style='font-size:40px'>
//     <em>I</em> like <span style='color:white; text-shadow:0 0 2px blue;'>CANVAS</span>
//    </div>
//   </foreignObject>
//  </svg>
//  `;
//  const img = new Image();
//  const svg = new Blob([data], {type: "image/svg+xml;charset=utf-8"});
//  const url = URL.createObjectURL(svg);
//  img.onload = function() {
//   ctx.drawImage(img, 0, 0);
//   URL.revokeObjectURL(url);
//  };
//  img.src = url;
// }

// return;
/// /////////////////////////////////////////////////////////////

// // first we need to replace images by foreign objects otherwise the export wont function!
// const replacedElements = replaceAllSVGImages( domElement );

// return new Promise( (resolve, reject )=> {

//  //////////////////////////////////////////////////
//  // USING DOM TO IMAGE
//  // DOC: https://github.com/tsayen/dom-to-image
//  domtoimage.toSvg( domElement, props )
//   .then( (dataUrl) => {
//     var img = new Image();
//     img.src = dataUrl;

//     //openBlobInNewWindow( dataURLToBlob(dataUrl));

//     // alert("dataURL:" + dataUrl);

//     // // test download
//     //downloadDataURL(dataUrl);

//     // // test add to page
//     document.body.append(img);
//     domElement.remove();

//     // replace back
//     replaceBackImages(replacedElements);

//     resolve( img );
//   })
//   .catch(function (error) {
//    console.error('oops, something went wrong with dom-to-image: ', error);
//    reject( error );
//   });

/// ///////////////////////////////////////////////
// USING html2canvas
// DOC:
// html2canvas(domElement, { allowTaint:true, logging:true, backgroundColor:"#ff0000" })
// .then( (canvas) => {
//  var dataUrl = canvas.toDataURL();
//   var img = new Image();
//   img.src = dataUrl

//   //openBlobInNewWindow( dataURLToBlob(dataUrl));

//   alert("dataURL:" + dataUrl);

//   // // test download
//   //downloadDataURL(dataUrl);

//   // // test add to page
//   document.body.append(img);

//   // replace back
//   replaceBackImages(replacedElements);

//   resolve( img );
// })
// .catch(function (error) {
//  console.error('oops, something went wrong with html2canvas: ', error);
//  reject( error );
// });

//  })
// }

// var getCanvas; // global variable

// // convert svg to canvases elements before generating the screenshot!
// var svgElements = printArea.parentNode.querySelectorAll('svg');
// svgElements.forEach( (svgElement) => {
//     console.log("svg item found!");
//     var canvasReplacement = $('<canvas></canvas>');

//     var svg = $(svgElement);
//     svg.replaceWith(canvasReplacement);
//     //item.setAttribute("width", item.getBoundingClientRect().width);
//     //item.style.width = null;

//     // Get the raw SVG string and curate it
//     var content = svg.wrap('<p></p>').parent().html();
//     content = content.replace(/xlink:title="hide\/show"/g, "");
//     content = encodeURIComponent(content);
//     svg.unwrap();

//     // Create an image from the svg
//     var image = new Image();
//     image.src = 'data:image/svg+xml,' + content;
//     image.onload = function() {
//         alert("coucou loaded");
//         canvasReplacement[0].width = image.width;
//         canvasReplacement[0].height = image.height;

//         // Render the image to the canvas
//         var context = canvasReplacement[0].getContext('2d');
//         context.drawImage(image, 0, 0);

//           // html 2 canvas
//   html2canvas(printArea, { allowTaint:true, logging:true, backgroundColor:"#ff0000" })
//     .then( canvasElement => {
//         getCanvas = canvasElement;
//         //     onrendered: function (canvas) {
//         //     $("#previewImage").append(canvas);
//         //     getCanvas = canvas;
//         //     }
//         // });

//         openCanvasContentInNewWindow( canvasElement );

//         // //$("#download").on('click', function () {
//         // var imgageData = getCanvas.toDataURL("image/png");
//         // // Now browser starts downloading it instead of just showing it
//         // var newData = imageData.replace(/^data:image\/png/, "data:application/octet-stream");
//         // $("#download").attr("download", "image.png").attr("href", newData);

//         // var canvas=document.getElementById("canvas");
//         // var win=window.open();
//         // win.document.write("<img id='compiledImg' src='"+canvas.toDataURL('image/png')+"' width='324' height='204' style='margin: 0; padding: 0; background: #e6e6e6;'/>");
//         // win.location.assign(reload);
//     });
//     };

// });

// //https://stackoverflow.com/questions/10721884/render-html-to-an-image/10722025#10722025
// testExportDomToImage = ( domElement ) =>
// {
//     const {body} = document

//     const canvas = document.createElement('canvas')
//     const ctx = canvas.getContext('2d')
//     canvas.width = canvas.height = 100

//     const tempImg = document.createElement('img')
//     tempImg.addEventListener('load', onTempImageLoad)
//     tempImg.src = 'data:image/svg+xml,' + encodeURIComponent(domElement);

//     const targetImg = document.createElement('img')
//     body.appendChild(targetImg)

//     function onTempImageLoad(e){
//         ctx.drawImage(e.target, 0, 0)
//         //targetImg.src = canvas.toDataURL();
//                 openCanvasContentInNewWindow( canvas );
//     }
// }

/// /////////////////////////////////////////////
/// //// convert elemets to svg

// First render all SVGs to canvases
// var elements = targetElem.find('svg').map(function() {
//     var svg = $(this);
//     var canvas = $('<canvas></canvas>');
//     svg.replaceWith(canvas);

//     // Get the raw SVG string and curate it
//     var content = svg.wrap('<p></p>').parent().html();
//     content = content.replace(/xlink:title="hide\/show"/g, "");
//     content = encodeURIComponent(content);
//     svg.unwrap();

//     // Create an image from the svg
//     var image = new Image();
//     image.src = 'data:image/svg+xml,' + content;
//     image.onload = function() {
//         canvas[0].width = image.width;
//         canvas[0].height = image.height;

//         // Render the image to the canvas
//         var context = canvas[0].getContext('2d');
//         context.drawImage(image, 0, 0);
//     };
//     return {
//         svg: svg,
//         canvas: canvas
//     };
/// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//   }

/**
 *
 * This function will replace all images in a SVG in preparation for an export.
 * It will load all image tag inside the svg in a queue
 * once the items are loaded, it will put the href attribute to use a base64 version of the images.
 * This allow an SVG containing the full image directly and not a link
 */
export function replaceAllSVGImages(node: Node): Promise {
  const allImages: Array = $(node).find('image').get(); // node.querySelectorAll("Image");//.parent().find("Image")

  // load and replace svg tag by images
  const loadAndReplaceImage: Promise = (svgElement) => {
    try {
      const imageURL: string = $(svgElement).attr('href').replace('tn', 'work');
      return imageURLToBase64(imageURL).then((base64Content) => {
        $(svgElement).attr('href', base64Content);
      });
    } catch (e) {
      // TODO: fix this for calendar frames with text and image!
      console.warn(`Replace SVG Images: Error:${e}`);
      return new Promise((resolve) => resolve());
    }
  };

  const promises = [];
  allImages.forEach((svgElement) => {
    promises.push(loadAndReplaceImage(svgElement));
  });

  return Promise.all(promises);
}
