import type { IPage, Frame, ProjectOptions } from '../types/types';
import {
  HasCustomCover,
  GetProjectCover,
  GetProjectSpine,
  GetProjectSpineNum,
} from './coverHelper';
import {
  FRAME_TYPE,
  CleanAndVerifyFrameText,
  CreateFrame,
} from '../feature/edition/frameHelper';
import { mmToPoint } from './MeasureUtils';
import { PAPER_QUALITY } from '../data/Constants';
import { GetProjectOptions } from '../feature/project/GetProjectOptions';

// TODO: later this spine map should be retreived from the backend
const spineMap = {
  // Note: array : [170gr, 200gr, 250gr]

  // ---- Classic (S, M, L)
  Classic: {
    20: { noInsert: [8, 8, 8], insert: [8, 8, 8] },
    30: { noInsert: [8, 8, 10], insert: [8, 8, 10] },
    40: { noInsert: [8, 8, 10], insert: [8, 10, 14] },
    50: { noInsert: [8, 10, 14], insert: [10, 12, 14] },
    60: { noInsert: [10, 12, 14], insert: [10, 12, 14] },
    70: { noInsert: [10, 12, 14], insert: [14, 14, 16] },
    80: { noInsert: [10, 14, 16], insert: [14, 16, 18] },
    90: { noInsert: [14, 14, 18], insert: [14, 16, 22] },
    100: { noInsert: [14, 16, 20], insert: [14, 18, 22] },
    110: { noInsert: [14, 16, 20], insert: [14, 18, 22] },
    120: { noInsert: [14, 16, 20], insert: [16, 20, 26] },
    130: { noInsert: [14, 18, 22], insert: [18, 22, 26] },
    140: { noInsert: [16, 18, 26], insert: [18, 22, 30] },
    150: { noInsert: [16, 20, 26], insert: [20, 24, 30] },
    160: { noInsert: [16, 20, 26], insert: [20, 26, 32] },
    170: { noInsert: [18, 22, 28], insert: [22, 26, 32] },
    180: { noInsert: [18, 22, 28], insert: [24, 28, 34] },
    190: { noInsert: [20, 24, 30], insert: [24, 30, 34] },
    200: { noInsert: [20, 24, 32], insert: [26, 30, 36] },
  },

  // ---- soft cover (casuals) (A, B, QD, QE)
  SoftCover: {
    20: { noInsert: [2, 2, 2], insert: [2, 2, 2] },
    30: { noInsert: [3, 3, 3], insert: [3, 3, 3] },
    40: { noInsert: [3.8, 3.8, 3.8], insert: [3.8, 3.8, 3.8] },
    50: { noInsert: [4.5, 4.5, 4.5], insert: [4.5, 4.5, 4.5] },
    60: { noInsert: [5.3, 5.3, 5.3], insert: [5.3, 5.3, 5.3] },
    70: { noInsert: [6, 6, 6], insert: [6, 6, 6] },
    80: { noInsert: [6.8, 6.8, 6.8], insert: [6.8, 6.8, 6.8] },
    90: { noInsert: [7.5, 7.5, 7.5], insert: [7.5, 7.5, 7.5] },
    100: { noInsert: [8.3, 8.3, 8.3], insert: [8.3, 8.3, 8.3] },
    110: { noInsert: [9.1, 9.1, 9.1], insert: [9.1, 9.1, 9.1] },
    120: { noInsert: [10, 10, 10], insert: [10, 10, 10] },
    130: { noInsert: [10.7, 10.7, 10.7], insert: [10.7, 10.7, 10.7] },
    140: { noInsert: [11.5, 11.5, 11.5], insert: [11.5, 11.5, 11.5] },
    150: { noInsert: [12.3, 12.3, 12.3], insert: [12.3, 12.3, 12.3] },
    160: { noInsert: [13.1, 13.1, 13.1], insert: [13.1, 13.1, 13.1] },
    170: { noInsert: [13.9, 13.9, 13.9], insert: [13.9, 13.9, 13.9] },
    180: { noInsert: [14.7, 14.7, 14.7], insert: [14.7, 14.7, 14.7] },
    190: { noInsert: [15.5, 15.5, 15.5], insert: [15.5, 15.5, 15.5] },
    200: { noInsert: [16.3, 16.3, 16.3], insert: [16.3, 16.3, 16.3] },
  },

  // ---- hard cover (Q, QA, QB, QF)
  // classic, contemporary, trendy
  HardCover: {
    20: { noInsert: [8, 8, 8], insert: [8, 8, 10] },
    30: { noInsert: [8, 8, 10], insert: [8, 8, 10] },
    40: { noInsert: [8, 8, 10], insert: [8, 10, 14] },
    50: { noInsert: [8, 10, 14], insert: [10, 10, 14] },
    60: { noInsert: [10, 10, 14], insert: [10, 14, 14] },
    70: { noInsert: [10, 14, 14], insert: [14, 14, 16] },
    80: { noInsert: [10, 14, 16], insert: [14, 14, 18] },
    90: { noInsert: [14, 14, 18], insert: [14, 16, 22] },
    100: { noInsert: [14, 14, 18], insert: [14, 18, 22] },
    110: { noInsert: [14, 16, 20], insert: [16, 18, 26] },
    120: { noInsert: [14, 16, 20], insert: [16, 20, 26] },
    130: { noInsert: [14, 18, 22], insert: [18, 22, 26] },
    140: { noInsert: [16, 18, 26], insert: [18, 22, 30] },
    150: { noInsert: [16, 20, 26], insert: [20, 24, 30] },
    160: { noInsert: [16, 20, 26], insert: [20, 26, 32] },
    170: { noInsert: [18, 22, 28], insert: [22, 26, 32] },
    180: { noInsert: [18, 22, 28], insert: [24, 28, 34] },
    190: { noInsert: [20, 24, 30], insert: [24, 30, 34] },
    200: { noInsert: [20, 24, 32], insert: [26, 30, 36] },
  },

  // ---- Regular
  Regular: {
    20: { noInsert: [8, 8, 8], insert: [8, 8, 10] },
    30: { noInsert: [8, 8, 10], insert: [8, 8, 10] },
    40: { noInsert: [8, 8, 10], insert: [8, 10, 14] },
    50: { noInsert: [8, 10, 14], insert: [10, 10, 14] },
    60: { noInsert: [10, 10, 14], insert: [10, 14, 14] },
    70: { noInsert: [10, 14, 14], insert: [14, 14, 16] },
    80: { noInsert: [10, 14, 16], insert: [14, 14, 18] },
    90: { noInsert: [14, 14, 18], insert: [14, 16, 22] },
    100: { noInsert: [14, 14, 18], insert: [14, 18, 22] },
    110: { noInsert: [14, 16, 20], insert: [16, 18, 26] },
    120: { noInsert: [14, 16, 20], insert: [16, 20, 26] },
    130: { noInsert: [14, 18, 22], insert: [18, 22, 26] },
    140: { noInsert: [16, 18, 26], insert: [18, 22, 30] },
    150: { noInsert: [16, 20, 26], insert: [20, 24, 30] },
    160: { noInsert: [16, 20, 26], insert: [20, 26, 32] },
    170: { noInsert: [18, 22, 28], insert: [22, 26, 32] },
    180: { noInsert: [18, 22, 28], insert: [24, 28, 34] },
    190: { noInsert: [20, 24, 30], insert: [24, 30, 34] },
    200: { noInsert: [20, 24, 32], insert: [26, 30, 36] },
  },
};

/**
 *
 * @param {Project} project
 */
export function CheckAndUpdateProjectSpineWidth(project) {
  if (HasCustomCover(project)) {
    const newSpineWidth = getSpineWidthMM(GetProjectOptions(project));
    const cover: IPage = GetProjectCover(project);
    // alert("New Spine Width: " +newSpineWidth +"mm");

    // update spine
    let spine: Frame = GetProjectSpine(project);
    if (!spine) {
      // add if not existing!
      spine = CreateSpine(cover.width, cover.height);
      cover.frames.push(spine);
    }

    // update depending on options!
    spine.height = mmToPoint(newSpineWidth);

    // update spine num
    let spineNum: Frame = GetProjectSpineNum(project);
    if (!spineNum) {
      // add if not existing!
      spineNum = CreateSpineNum(cover.width, cover.height);
      cover.frames.push(spineNum);
    }
    spineNum.width = mmToPoint(newSpineWidth);

    // [Security]
    // update text to be able to fit the size of spine
    if (spine.text.size > spine.height * 0.75)
      spine.text.size = Math.floor(spine.height * 0.75);

    // [Security]
    // update text to be able to fit the size of spine
    if (spineNum.text.size > spineNum.width * 0.75)
      spineNum.text.size = Math.floor(spineNum.width * 0.75);
  }
}

export function CreateSpine(coverWidth, coverHeight) {
  // let newFrame = new Frame( FRAME_TYPE.SPINE, coverHeight*.9, coverHeight*0.1, coverWidth*.5,coverHeight*.45, -90 );
  const spineFrame = CreateFrame({
    type: FRAME_TYPE.SPINE,
    width: coverHeight * 0.9,
    height: coverHeight * 0.1,
    x: coverWidth * 0.5,
    y: coverHeight * 0.45,
    rotation: -90,
  });
  CleanAndVerifyFrameText(spineFrame);
  return spineFrame;
}

export function CreateSpineNum(coverWidth, coverHeight) {
  // let newFrame = new Frame( FRAME_TYPE.SPINE_NUM, coverHeight*0.1, coverHeight*0.1, coverWidth*.5, coverHeight*.95, 0 );
  const numFrame = CreateFrame({
    type: FRAME_TYPE.SPINE_NUM,
    width: coverHeight * 0.1,
    height: coverHeight * 0.1,
    x: coverWidth * 0.5,
    y: coverHeight * 0.95,
    rotation: 0,
  });
  CleanAndVerifyFrameText(numFrame);
  return numFrame;
}

// export function GetProjectSpineWidthMM( options:ProjectOptions )
// {
//  return getSpineWidthMM( options.docID, options.numPages-1, options.paper, options.coated, options.pagePaperQuality);
// }

/**
 * Helper: Get the album spine width based on the docID and paper
 * Paper is that page put between each pages of the book, it impact the width of the spine
 * result in mm !!!
 *
 * Soft cover = Casual
 * Hard cover = Classic, comptemporary et trendy
 *
 * Q* = trendy
 * QA* = trendy
 * QB* = trendy
 *
 * QE* = Casual
 * QD* = Casual
 * A* = Casual
 * B* = Casual
 * */
export function getSpineWidthMM(projectOptions: ProjectOptions): number {
  let { docID, numPages, paper, coated, pagePaperQuality } = projectOptions;

  //
  // var result:number = 0;
  // var docPrefix : String = (isNaN(parseInt(docid.charAt(docid.length-3)))) ? docid.substring(0,docid.length-2) : docid.substring(0,docid.length-3);

  // use numpages by 10 pages
  // ex 148 => Math.round(148/10) => 15*10 = 150
  numPages = Math.round(numPages / 10) * 10;

  // security
  if (numPages < 20) numPages = 20; // TODO: be sure we don't get here.. its because at creation, we get here before the num of pages of album are created...

  // using the new map system
  const paperKey: string = paper ? 'insert' : 'noInsert';
  // var coatedKey : String = (coated)?"coated" : "notCoated";
  let qualityIndex = 0; // default is paper quality 170
  if (pagePaperQuality === PAPER_QUALITY.QUALITY_200) qualityIndex = 1;
  else if (pagePaperQuality === PAPER_QUALITY.QUALITY_250) qualityIndex = 2;

  let albumType: string = null;
  switch (docID) {
    case 'S':
    case 'M':
    case 'L':
      albumType = 'Classic';
      break;

    case 'A':
    case 'B': // case "C": Casual A3 not done in the end..
    case 'AL':
    case 'BL':
    case 'DL':
    case 'QD':
    case 'QE':
      albumType = 'SoftCover';
      break;

    case 'Q':
    case 'QA':
    case 'QB':
    case 'QF':
      albumType = 'HardCover';
      break;

    case 'RA':
    case 'RB':
    case 'RC':
    case 'RAL':
    case 'RBL':
    case 'RCL':
    case 'RDL':
      albumType = 'Regular';
      break;

    default:
      // TODO: find a way to automate this list
      console.warn(
        `SpineHelper: We did not find album type for docID:${docID}`
      );
      albumType = 'Classic';
  }

  return spineMap[albumType][numPages][paperKey][qualityIndex];
}

// //TODO ("DONT FORGET TO CHANGE THIS RULE!! : we need to get the correct width based on num of pages, not rounded to 10");
//   // [EDIT] real spine width for cover seem to work with this rule, so should maybe not be changed
//   return SpineUtils.customSpineWidth(docCode,Math.round(numAlbumPagesWithoutCover/10)*10, paper, coated,_pagepagePaperQuality);
