import React from 'react';
import ReactDOM from 'react-dom';

import { isObject } from 'lodash';
import type {
  Photo,
  Frame,
  Clipart,
  BackgroundItem,
  IUploadItem,
  IPage,
} from '../../types/types';

import {
  FRAME_TYPE,
  IsFrameEmpty,
  NeedFrameUpload,
} from '../edition/frameHelper';
import { getOrderFileLocation, CheckPageMustBePrinted } from './orderHelper';
import { photoListSelector } from '../photoList/photoList';
import { backgroundSelectors } from '../backgrounds/background';
import { clipartSelectors } from '../cliparts/cliparts';
import { orderSelector } from './order';
import { FrameArea } from '../../pages/homePage/EditionArea/FrameArea';
import { domElementToPng, downloadDataURL } from '../../utils/HtmlUtils';
import { API } from '../../utils/API';
import { manifestHelper } from './manifestHelper';
import { GetText } from '../../data/LanguageHelper';
import { DebugFlags } from '../../debug/DebugFlags';
import { FRAME_SHADOW_SIZE, FrameShadow } from '../edition/ShadowHelper';
import { CalendarHelper } from '../../utils/calendar/CalendarHelper';
import { UIActions } from '../ui/ui';
import { Project } from '../../data/Project';

// public exports
export const FrameExporter = {
  InitializeStoreRef,
  ExportProjectFrames,
  // generateFrameForUpload,
};

/// /////////////////////////////////////////////////////////////
/// --------------------- Helpers ------------------------
// here, once the order starts, we keep a reference to the readonly Store, otherwise too complex to inject in each function..
// let StoreRef;

// TODO: this should not be done like this...
let StoreRef;
function InitializeStoreRef(store) {
  StoreRef = store;
}

const getStore = () => StoreRef.getState();
const getProject = () => getStore().edition.project;
const getPhotosByID = () => photoListSelector.getAllPhotosByID(getStore());
const getBackgroundsByID = () =>
  backgroundSelectors.getAllBackgroundsByID(getStore());
const getClipartsByID = () => clipartSelectors.getAllClipartsByID(getStore());
let dispatchRef;

// function getPhoto(photoID): Photo {
//   return getPhotosByID()[photoID];
// }
// function getBackground(backgroundID): BackgroundItem {
//   return getBackgroundsByID()[backgroundID];
// }
// function getClipart(clipartID): Clipart {
//   return getClipartsByID()[clipartID];
// }
function getJobID(): string {
  return orderSelector.getJobIDSelector(getStore());
}

/// /////////////////////////////////////////////////////////////

let frameExportLog;
let totalFrames;
let currentExportedFrame; // num of frames already exported

async function ExportProjectFrames(dispatch): Promise {
  dispatchRef = dispatch;
  console.log('FrameExporter.ExportProjectFrames');
  dispatchRef(
    UIActions.ShowLoading(GetText('loading.order.generate.progress'))
  );

  frameExportLog = '____ START EXPORT _____';

  const uploadItems: Array<IUploadItem> = [];
  const uploadNames: Array<string> = [];

  // small helper that adds the upload item only if it's filename doesn't already exist!
  const checkAddUploadItem = (uploadItem: IUploadItem) => {
    if (!uploadNames.includes(uploadItem.fileName)) {
      uploadItems.push(uploadItem);
      uploadNames.push(uploadItem.fileName);
    }
  };

  // Create all upload items
  const project = getProject();

  project.pageList.forEach((page: IPage, pageIndex, pageArr) => {
    if (CheckPageMustBePrinted(pageIndex)) {
      page.frames.forEach((frame: Frame, frameIndex, frameArr) => {
        // ---- Classic export of frame needing a frame upload ----
        if (NeedFrameUpload(frame)) {
          checkAddUploadItem({
            fileName: getOrderFileLocation(frame, pageIndex, frameIndex),
            frame,
            pageIndex,
            frameIndex,
          });
        }

        // ---- Border ----
        else if (frame.border > 0) {
          checkAddUploadItem({
            fileName: getOrderFileLocation(null, 0, 0, frame.borderColor),
            hexColor: frame.borderColor,
          });
        }

        // ---- shadow ----
        if (frame.shadow && isObject(frame.shadow) && frame.shadow.enabled) {
          checkAddUploadItem({
            fileName: getOrderFileLocation(frame, 0, 0, null, frame.shadow),
            frame, // we add the frame so we can handle border radius
            shadow: frame.shadow,
          });
        }

        // // ---- CALENDAR fillColor ----
        // if( frame.type === FRAME_TYPE.CALENDAR && frame.fillColor )
        // {
        //  const color = frame.fillColor;
        //  checkAddUploadItem({
        //   fileName:getOrderFileLocation(null,0,0,color),
        //   hexColor:color
        //  })
        // }

        // ---- CALENDAR Sub frames ----
        if (frame.type === FRAME_TYPE.CALENDAR) {
          // fill color
          if (frame.fillColor) {
            checkAddUploadItem({
              fileName: getOrderFileLocation(null, 0, 0, frame.fillColor),
              hexColor: frame.fillColor,
            });
          }

          // sub frame text
          if (frame.text) {
            const subFrameText = CalendarHelper.getCalendarSubFrameText(frame);
            if (!IsFrameEmpty(subFrameText)) {
              checkAddUploadItem({
                fileName: getOrderFileLocation(
                  subFrameText,
                  pageIndex,
                  frameIndex
                ),
                frame: subFrameText,
                pageIndex,
                frameIndex,
              });
            }
          }
        }
      });
    }
  });

  console.log(
    `FrameExporter: ${
      uploadItems.length
    } frame(s) will be exported:${JSON.stringify(uploadItems)}`
  );
  totalFrames = uploadItems.length;
  currentExportedFrame = 0;

  return new Promise((resolve, reject) => {
    let p = Promise.resolve();
    uploadItems.forEach((item) => {
      p = p.then(() => handleNextUploadItem(item));
    });

    if (project.pageList.length > 1)
      p = p.then(() => exportProjectPreviewPage(project));

    p.then(() => {
      resolve();
    });
    p.catch((reason) => {
      reject(`Upload sequence error:${reason}`);
    });
  });
}

function handleNextUploadItem(item: IUploadItem): Promise {
  currentExportedFrame++;
  updateFrameExporterLoadingPercentage();

  // ---- shadow ----
  if (item.shadow) return exportShadowFrame(item.frame);

  // ---- Color (border) ----
  if (item.hexColor) return exportColorFrame(item.hexColor);

  // ---- Classic frame rendering export ( text, mask )----
  if (item.frame)
    return exportAndUploadFrame(item.frame, item.pageIndex, item.frameIndex);

  // handle error
  return Promise.reject(
    `Next upload item cannot be exported: ${JSON.stringify(item)}`
  );
}

function updateFrameExporterLoadingPercentage() {
  dispatchRef(
    UIActions.ShowLoading(
      GetText('loading.order.generate.encoding'),
      Math.round((currentExportedFrame / totalFrames) * 100)
    )
  );
}
function updateFrameUploaderLoadingPercentage() {
  dispatchRef(
    UIActions.ShowLoading(
      GetText('loading.order.generate.sending'),
      Math.round((currentExportedFrame / totalFrames) * 100)
    )
  );
}

// --------------------- Private ------------------------

//------------------------------
// Export Frame
//------------------------------
function exportAndUploadFrame(
  frame: Frame,
  pageIndex,
  frameIndex
): Promise<void> {
  const p: Promise = generateFrameForUpload(frame, pageIndex, frameIndex);
  if (p) {
    return p
      .then((pngFile) => uploadFrame(frame, pageIndex, frameIndex, pngFile))
      .catch((reason) =>
        Promise.reject(
          `Export upload frame error: ${reason} in frame: ${JSON.stringify(
            frame
          )}`
        )
      );
  }
  return null;
}

/**
 * Generate a wrapper to generate div with dom to image
 * @param {string} id
 * @param {number} width
 * @param {number} height
 * @returns
 */
const createFrameWrapperDiv = (
  id: string,
  width: number,
  height: number
): HTMLDivElement => {
  // create the frame in background to allow a PNG generation of it
  const rendererDiv = document.createElement('div');
  rendererDiv.id = id;
  rendererDiv.style.width = `${width}px`;
  rendererDiv.style.height = `${height}px`;
  rendererDiv.style.padding = '0px';
  rendererDiv.style.margin = '0px';
  // hide it
  rendererDiv.style.position = 'absolute';
  rendererDiv.style.left = '0px';
  rendererDiv.style.top = '0px';
  rendererDiv.style.zIndex = '-1000';
  // rendererDiv.style.backgroundColor = 'red';
  return rendererDiv;
};

/**
 * Promise that should deliver a png file, or null if nothing to do with this frame.
 */
async function generateFrameForUpload(frame: Frame, pageIndex, frameIndex) {
  // :Promise<String>
  // if no frame uploaded needed, return nothing
  if (!NeedFrameUpload(frame)) return null;

  // create the frame in background to allow a PNG generation of it
  const rendererDiv = createFrameWrapperDiv(
    `frameExport_${frame.id}`,
    frame.width,
    frame.height
  );
  // add it so it can be rendered
  document.body.appendChild(rendererDiv);

  // be sure font is loaded before rendering`
  // EDIT: the issue is not from preloading as we can see that fonts are correcly printed but sometimes there is a line break in text..
  // if(frame.type === FRAME_TYPE.TEXT){
  //  await new Promise((resolve) => {
  //   // TODO: preload font
  //   // EDIT:: do to image does that in background.. does not seem to be the issue..
  //   // alert("frame.text.family:"+frame.text.family);
  //   resolve();
  //  })
  // }

  // Create area SVG
  const myFrame = (
    <svg width={frame.width} height={frame.height}>
      <FrameArea
        key={`print_${pageIndex}_${frameIndex}`}
        frame={frame}
        pageIndex={pageIndex}
        calendarColorOptions={getProject().calendarColorOptions}
        frameIndex={frameIndex}
        isForPrint // Important to not render not needed elements..
        photosByID={getPhotosByID()}
        backgroundsByID={getBackgroundsByID()}
        clipartsByID={getClipartsByID()}
      />
    </svg>
  );
  // update loading
  // ShowLoading(GetText("loading.order.generate.encoding"));

  // generate
  const pngURL = await new Promise((resolve, reject) => {
    // render in dom, and then resolve the png generated
    ReactDOM.render(myFrame, rendererDiv, () => {
      // STEP 3 generate PNG
      domElementToPng(rendererDiv, 3)
        .then((pngDataUrl) => {
          // png is generated, remove content
          document.body.removeChild(rendererDiv);

          if (DebugFlags.DOWNLOAD_EXPORTED_FRAMES) {
            downloadDataURL(
              pngDataUrl,
              `tictacExport/print_${pageIndex}_${frameIndex}.png`
            );
          }

          resolve(pngDataUrl);
        })
        .catch((reason) => reject(`Render dom to png error: ${reason}`));
    });
  });

  // return the png url generated
  return pngURL;
}

/**
 *
 */
function uploadFrame(
  frame: Frame,
  pageIndex,
  frameIndex,
  pngFile: string
): Promise {
  const fileName = getOrderFileLocation(frame, pageIndex, frameIndex);

  // update percent
  // exportedFrames ++;
  updateFrameExporterLoadingPercentage();

  return new Promise((resolve, reject) => {
    API.uploadImageForOrder(fileName, pngFile, getJobID()).then((response) => {
      // uploadedFrame ++;
      updateFrameUploaderLoadingPercentage();
      resolve(response);
    });
  });
}

//------------------------------
// Export frame color
//------------------------------
function exportColorFrame(hexColor: string): Promise {
  const frameSize = 50; // NOTE: IMPORTANT, there is a minimum size for a correct generation.. do not go below this
  const rendererDiv = createFrameWrapperDiv(
    `frameColor_${hexColor}`,
    frameSize,
    frameSize
  );
  // add it so it can be rendered
  document.body.appendChild(rendererDiv);

  // SVG content
  const colorFrame = (
    <svg width={frameSize} height={frameSize}>
      <rect
        x="0"
        y="0"
        width="100%"
        height="100%"
        style={{
          fill: hexColor,
        }}
      />
    </svg>
  );

  // --- Render to temporary dom
  return (
    new Promise((resolve, reject) => {
      // render in dom, and then resolve the png generated
      ReactDOM.render(colorFrame, rendererDiv, () => {
        resolve(rendererDiv);
      });
    })

      // --- generate png
      .then((divToGenerate) => domElementToPng(divToGenerate, 3))

      // --- upload
      .then((pngDataUrl) => {
        // png is generated, remove content
        document.body.removeChild(rendererDiv);
        const fileName = getOrderFileLocation(null, 0, 0, hexColor);
        if (DebugFlags.DOWNLOAD_EXPORTED_FRAMES)
          downloadDataURL(pngDataUrl, fileName);
        return API.uploadImageForOrder(fileName, pngDataUrl, getJobID());
      })

      // catch possible error
      .catch((reason) => Promise.reject(`Export color frame error: ${reason}`))
  );
}

//------------------------------
// Export Shadow frame
//------------------------------
function exportShadowFrame(frame: Frame): Promise {
  const frameShadow = frame.shadow;

  // create DIV
  const rendererDiv = createFrameWrapperDiv(
    `shadow_${frameShadow.hexColor}`,
    FRAME_SHADOW_SIZE,
    FRAME_SHADOW_SIZE
  );
  // add it so it can be rendered
  document.body.appendChild(rendererDiv);

  // SVG content
  const shadowFrame = <FrameShadow frame={frame} isForPrint />;

  // --- Render to temporary dom
  return (
    new Promise((resolve, reject) => {
      // render in dom, and then resolve the png generated
      ReactDOM.render(shadowFrame, rendererDiv, () => {
        resolve(rendererDiv);
      });
    })

      // --- generate png
      .then((divToGenerate) => domElementToPng(divToGenerate, 3))

      // --- upload
      .then((pngDataUrl) => {
        // png is generated, remove content
        document.body.removeChild(rendererDiv);
        const fileName = getOrderFileLocation(frame, 0, 0, null, frameShadow);
        if (DebugFlags.DOWNLOAD_EXPORTED_FRAMES)
          downloadDataURL(pngDataUrl, fileName);
        return API.uploadImageForOrder(fileName, pngDataUrl, getJobID());
      })

      // catch possible error
      .catch((reason) => Promise.reject(`Export shadow frame error: ${reason}`))
  );
}

//------------------------------
// Export PROJECT PREVIEW
//------------------------------
function exportProjectPreviewPage(project: Project): Promise {
  return new Promise((resolve, reject) => {
    // for albums, we also want to export a manifest page
    const previewPageIndex = manifestHelper.getPreviewPageIndex(project);

    // generate based on page navigator item visible on screen
    const itemID = `navigator_${previewPageIndex}`;
    const printArea = document.getElementById(itemID);
    if (!printArea) {
      reject('FrameExporter: Navigator preview item not found!');
      return null;
    }

    // create PNG from dom element
    return domElementToPng(printArea, 3, true)
      .then((pngDataUrl) => {
        // and upload it
        const fileName: string = getOrderFileLocation(
          null,
          0,
          0,
          false,
          false,
          previewPageIndex
        );
        return (
          API.uploadImageForOrder(fileName, pngDataUrl, getJobID())
            // then resolve the promise
            .then((response) => {
              resolve();
            })
        );
      })
      .catch((reason) => reject(`project preview: ${reason}`));
  });
}
