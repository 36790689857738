import React from 'react';

type ImgIconProps = {
  icon: string, // iconUrl from embedIcons
  className?: string,
  style?: object,
};

const ImgIcon = ({ icon, className, style }: ImgIconProps) => (
  <img
    src={icon}
    draggable={false}
    className={className || 'imgIcon'}
    style={style}
  />
);

export default ImgIcon;
