import React from 'react';

interface SvgTextMultiLineProps {
  x: number;
  y: number;
  width: number;
  height: number;
  lines: Array; // [ "line1", "line2"]
  lineSpacing: number; // default 0

  fontSize: number;
  fontColor: string;
  fontWeight: string;
  fontFamily: string;
}

const SvgTextMultiline = (props: SvgTextMultiLineProps) => {
  let { x, y, width, height, lines, lineSpacing } = props;
  if (!width) throw new Error('SGVTextMultiline must have a width');
  if (!height) throw new Error('SGVTextMultiline must have a height');

  if (!lineSpacing) lineSpacing = 0;

  const lineHeight = height / lines.length - lineSpacing * lines.length;

  const hAlign = 'center'; // Hardcoded, TODO: pass as param
  let anchor;

  // positionning
  if (hAlign === 'center') {
    x += width / 2;
    anchor = 'middle';
  } else if (hAlign === 'right') {
    x += width;
    anchor = 'end';
  } else {
    // default left
    anchor = 'start';
  }

  // if(calendarOptions.vAlign === "center"){
  //   // TODO: this won't work with multi line!
  //   y=50; // % but calculated with lines
  //   baseline="middle";
  // }
  // else if(calendarOptions.vAlign === "bottom"){
  //     // TODO: this won't work with multi line!
  //     y=95; // % but calculated with lines
  //     baseline="hanging";
  // }
  // else{ // DEFAULT TOP
  //     // y=0; // % but calculated with lines
  //     y = CalendarHelper.GetFrameTextOffset( frame ).y;
  //     if(y!=0){
  //         hasOffsetY = true;
  //     }
  //     // baseline="text-before-edge";
  //     baseline="hanging";
  // }

  return (
    <g>
      {lines.map((lineValue, lineIndex, allLines) => (
        <text
          key={lineIndex}
          className="unselectable"
          height={lineHeight}
          width={width}
          x={x}
          // y={ ( !hasOffsetY) ? (Math.round(y + lineIndex * 95/numLines ))+"%" : (y+"px") }
          y={y + lineIndex * (lineHeight + lineSpacing)}
          style={{
            textAnchor: anchor, // halign
            dominantBaseline: 'hanging', // valign
            fontSize: props.fontSize,
            fontWeight: props.fontWeight,
            fontFamily: props.fontFamily,
            fill: props.fontColor,
          }}
        >
          {lineValue}
        </text>
      ))}
    </g>
  );
};

export default SvgTextMultiline;
