import { PROJECT_CLASS, ALBUM_TYPES } from '../../data/Constants';
import { Project } from '../../data/Project';
import { ProjectCreationParams } from '../../types/types';
import { CANVAS_FORMAT } from '../../utils/canvas/CanvasHelper';
import {
  CreateClassicCoverPage,
  CreateProjectCustomCover,
} from '../../utils/coverHelper';
import { cmToPixel } from '../../utils/MeasureUtils';
import { GetDoc } from '../../utils/ProductHelper';
import {
  ApplyOptionsToProject,
  CreateProjectPages,
  InjectPagesAtIndex,
  cleanAndVerifyCustomCover,
} from '../../utils/projectHelper';
import { LAYOUT_TYPE } from '../layouts/layoutHelper';

export function CreateProject(creationParams: ProjectCreationParams) {
  const {
    classname,
    type,
    docID,
    projectName,
    numPages,
    layoutStore,
    options,
    canvasFreeWidth,
    canvasFreeHeight,
  } = creationParams;

  // TODO: to use plain object, do not use classes but interfaces with create method (default object)
  const proj = new Project(classname, projectName, type, docID);
  const doc = GetDoc(docID);
  if (options) ApplyOptionsToProject(proj, options);

  // --- CASE ALBUM ---
  if (classname === PROJECT_CLASS.ALBUM) {
    // create cover
    if (proj.type === ALBUM_TYPES.CLASSIC)
      proj.pageList.unshift(
        CreateClassicCoverPage(
          proj,
          creationParams.coverName,
          creationParams.coverCorners
        )
      );
    else
      CreateProjectCustomCover(
        proj,
        layoutStore.filtered[LAYOUT_TYPE.COVER].all,
        layoutStore.byID
      );

    // inject pages
    const pages = CreateProjectPages(
      proj,
      numPages,
      proj.pageList.length,
      layoutStore
    );
    InjectPagesAtIndex(proj.pageList, pages, proj.pageList.length);

    // now that pages are injected, cover must be updated with cover options and page size
    if (proj.type !== ALBUM_TYPES.CLASSIC) cleanAndVerifyCustomCover(proj);
  }

  // ---- CASE CALENDARS ----
  else if (classname === PROJECT_CLASS.CALENDAR) {
    const pages = CreateProjectPages(
      proj,
      numPages,
      proj.pageList.length,
      layoutStore
    );
    InjectPagesAtIndex(proj.pageList, pages, proj.pageList.length);

    // calendars have default values for coating
    proj.coated = doc.coating && doc.coating_default;
  }

  // ---- CASE CARDS ----
  else if (classname === PROJECT_CLASS.CARD) {
    const pages = CreateProjectPages(
      proj,
      numPages,
      proj.pageList.length,
      layoutStore
    );
    InjectPagesAtIndex(proj.pageList, pages, proj.pageList.length);
  }

  // ----  CASE CANVAS ----
  else if (classname === PROJECT_CLASS.CANVAS) {
    // free format
    if (proj.canvasFormat === CANVAS_FORMAT.FREE) {
      proj.width = cmToPixel(canvasFreeWidth);
      proj.height = cmToPixel(canvasFreeHeight);
    }
    const pages = CreateProjectPages(
      proj,
      numPages,
      proj.pageList.length,
      layoutStore
    );
    InjectPagesAtIndex(proj.pageList, pages, proj.pageList.length);
  }

  // This is mandatory!!!! Otherwise the class instance was set and nothing was updating... what a mess..
  // TODO: find a way to avoid this kind of issues... -> by using interface instead of class.. should be re-written
  return JSON.parse(JSON.stringify(proj));
}
