// --------------------- EXPORTS / PUBLIC -----------------------

import { GetText } from '../../data/LanguageHelper';
import { GetDoc } from '../ProductHelper';
import { CATALOGUE_CARD } from '../../data/catalogue/cardCatalogue';
import { IsCardEditor } from '../../data/config';

export const CardHelper = {
  GetNumModelFromPack,
  GetNumRepetitionFromPack,
  GetPackDisplayName,
  GetTotalCardNum,
  GetTotalNumberOfPages,
  GetEnvelopeColorByID,
  GetNavigatorPageMargin,
  GetCardPackByDocCode,
  ShouldDisplayPageGroupRepetition,
  GetCardFoldOrientation,
  GetProjectFormat,
};

// --------------------- interfaces ------------------------

export interface IEnvelope {
  id: string;
  color: string;
  code: number; // backend code
  price: number;
}

// --------------------- Methods ------------------------

const packSeparator = 'x';

// get the amount of different models
function GetNumModelFromPack(pack: string): number {
  return parseInt(pack.toLowerCase().split(packSeparator)[0], 10);
}

// get the number of repetitions for the current models
function GetNumRepetitionFromPack(pack: string): number {
  return parseInt(pack.toLowerCase().split(packSeparator)[1], 10);
}

// get the total amount of cards the user will order
function GetTotalCardNum(cardPack: string): number {
  const models = GetNumModelFromPack(cardPack);
  const reps = GetNumRepetitionFromPack(cardPack);
  return models * reps;
}

export function GetCardFoldOrientation(docID) {
  const doc = GetDoc(docID);
  if (doc.pages_group_orientation === 'vertical') return 'landscape';
  return 'portrait';
}

// gets the total pages for project depending on the cardpack
function GetTotalNumberOfPages(docID: string, cardPack: string): number {
  const pageDisplay = GetDoc(docID).pages_per_group; // === amount of pages per model
  return pageDisplay * GetNumModelFromPack(cardPack);
}

// --> from 1X10 to "10* 1 model"
function GetPackDisplayName(cardPack: string): string {
  const models = GetNumModelFromPack(cardPack);
  const reps = GetNumRepetitionFromPack(cardPack);
  return `${models} ${GetText('cards.configurator.model.label')} ( x${reps} )`;
}

function ShouldDisplayPageGroupRepetition(docID: string, groupIndex: number) {
  const isFlopped = GetDoc(docID).pages_per_group === 4;
  const isFirsPage = groupIndex % 3 === 0;
  return IsCardEditor() && !(isFlopped && !isFirsPage);
}

// page navigator margin between groups depend on product type and
function GetNavigatorPageMargin(docID: string, groupIndex: number) {
  const pagesPerGroup = GetDoc(docID).pages_per_group;
  let marginLeft = 15;
  let marginRight = 30;

  // margin differ for cards with inside pages
  if (pagesPerGroup > 2) {
    if (groupIndex % 3 === 0) {
      // first page
      marginLeft = 15;
      marginRight = 5;
    } else if (groupIndex % 3 === 1) {
      // inside pages
      marginLeft = 0;
      marginRight = 0;
    } // last page
    else {
      marginLeft = 5;
      marginRight = 30;
    }
  }
  return `0px ${marginRight}px 0px ${marginLeft}px`;
}

function GetEnvelopeColorByID(colorID: string): string {
  return CATALOGUE_CARD.envelopes.filter((x) => x.id === colorID)[0].color;
}

function GetCardPackByDocCode(docCode: number): string {
  const catalogue = CATALOGUE_CARD;
  let cardPack = null;
  const matchDocCode = Number(docCode);

  Object.keys(catalogue.docs).forEach((docID) => {
    Object.keys(catalogue.docs[docID].codes).forEach((pack) => {
      if (catalogue.docs[docID].codes[pack] === matchDocCode) {
        cardPack = pack;
      }
    });
  });

  return cardPack;
}

function GetProjectFormat(docID: string) {
  // retreive catalogue
  const catalogue = CATALOGUE_CARD;
  let projectFormat = null;
  // for (let productKey in catalogue.products)
  Object.keys(catalogue.products).forEach((productKey) => {
    const productNode = catalogue.products[productKey];

    // for (let formatKey in productNode.formats)
    Object.keys(productNode.formats).forEach((formatKey) => {
      const formatNode = productNode.formats[formatKey];
      formatNode.docs?.forEach((id) => {
        if (id === docID) {
          projectFormat = formatKey;
        }
      });
    });
  });
  return projectFormat;
}
