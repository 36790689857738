// import { createBrowserHistory } from 'history';
import { PROJECT_CONST } from '../data/config';

let pushMethod;

// export const history = createBrowserHistory();
export const history = {
  push: (path) => {
    // this was a hack to replace previous history push method.
    // TODO: we should find a better way using router hook (useNavigate)
    // window.location = path;
    pushMethod(path);
  },
  // hack to avoid big refactoring of history push
  SetPushMethod: (navigateMethod) => {
    pushMethod = navigateMethod;
  },
};

const projectClass = PROJECT_CONST.project_class;

export const ROUTE_CONST = {
  ROOT: '/',
  HOME: `/${projectClass}`,
  LOGIN: '/login',
  REGISTER: '/register',
  PROJECT_LIST: `/${projectClass}/projects`,
  USER: `/${projectClass}/user_detail`,
  NEW_PROJECT: `/${projectClass}/new_project`,
  SELECT_PROJECT_CLASS: `/${projectClass}/project_type`,
};
