import { createSelector, createReducer } from '@reduxjs/toolkit';
import { uniq } from 'lodash';
import { config } from '../../data/config';
import { API } from '../../utils/API';
import { downloadDataURL } from '../../utils/HtmlUtils';
import { alertActions } from '../alert/alert';
import { CreateClipart } from './clipartHelper';

/** **********************************
// ACTIONS TYPES
************************************ */

const TAG = 'CLIPART';

// LIST
const GETALL_START = `${TAG}/GETALL_START`;
const GETALL_SUCCESS = `${TAG}/GETALL_SUCCESS`;
const GETALL_ERROR = `${TAG}/GETALL_ERROR`;

/** **********************************
// REDUCERS
************************************ */

const initialState = {
  byID: {},
  isLoading: false, // is currently loading list
  error: null, // current error message
};

const reducer = createReducer(initialState, {
  [GETALL_START]: (state, action) => {
    state.error = null;
    state.isLoading = true;
  },

  [GETALL_ERROR]: (state, action) => {
    state.error = action.error;
    state.isLoading = false;
  },

  [GETALL_SUCCESS]: (state, action) => {
    state.error = null;
    state.isLoading = false;
    state.byID = action.byID;
  },
});

/** **********************************
// SIMPLE ACTIONS (creator)
************************************ */

// function sort (sortFilter){
//   return { type: SORT, sortFilter };
// }

/** **********************************
// COMPLEX ASYNC ACTIONS
************************************ */

function getAll(projectClass) {
  return (dispatch) => {
    dispatch(start());

    API.getCliparts(projectClass).then(
      (jsonResponse) => {
        // alert("clipart list received:"+JSON.stringify(jsonResponse));
        const resultByID = {};
        // go through items
        jsonResponse.clipart.document.clipart.forEach((item, index, arr) => {
          // TODO create clipart item interface or class
          const clipart = CreateClipart(
            item.$.name,
            Number(item.$.width),
            Number(item.$.height)
          );
          clipart.cat = item.$.catagory;
          // clipart.left = Number(item.$.left);
          // clipart.top = Number(item.$.top);
          clipart.type = item.$.type;
          clipart.luminance = Number(item.$.luminance);

          // to get correct path, we need to remove "cliparts/" at start and "jpg" at end from the proxies..
          function cleanProxy(p: string) {
            return p.split('clipart/').join('').split('.png').join('');
          }

          clipart.proxy = item.$.proxy;
          clipart.thumb_url = `${
            config.apiPhoto
          }flex/loadcliparts.php?image=${cleanProxy(item.$.tnproxy)}`;
          clipart.working_url = `${
            config.apiPhoto
          }flex/loadcliparts.php?image=${cleanProxy(item.$.proxy)}`;

          // vo from flash
          // vo = new ClipartVo();
          // vo.id = node.@name;
          // vo.category = node.@category;
          // vo.id_tn = String(String(node.@tnproxy).split(".jpg")[0]).substring(12);
          // vo.proxy = String(node.@proxy);
          // vo.id_hd = String(String(node.@proxy).split(".jpg")[0]).substring(12);
          // vo.width = MeasureManager.PFLToPixel(Number(node.@width));
          // vo.height = MeasureManager.PFLToPixel(Number(node.@height));
          // vo.isCover = isCover;

          // https://editor.tictacphoto.com/flex/loadcliparts.php?image=medium/online/christmas/tn_chaussette
          // https://editor.tictacphoto.com/flex/loadcliparts.php?image=cliparts/small/online/christmas/tn_2015-02.jpg"

          // {"id":"2015-02","cat":"christmas","height":104,"width":142,"left":-2,"top":-2,"type":"photo","luminance":0.8,
          // "thumb_url":
          // "https://editor.tictacphoto.com/flex/loadcliparts.php?image=cliparts/small/online/christmas/tn_2015-02.jpg"
          // ,"working_url":"https://editor.tictacphoto.com/flex/loadcliparts.php?image=cliparts/small/online/christmas/2015-02.jpg"}

          // add to result list
          resultByID[clipart.id] = clipart;
          // category:"new"
          // height:"104"
          // left:"-2"
          // luminance:"0.8"
          // name:"001 Amour"
          // proxy:"cliparts/small/online/love/001_Amour.jpg"
          // tnproxy:"cliparts/small/online/love/tn_001_Amour.jpg"
          // top:"-2"
          // type:"photo"
          // width:"142"
        });
        // dispatch(success(result.byID, result.filtered));
        dispatch(success(resultByID));
      },
      (error) => {
        dispatch(fail(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function start() {
    return { type: GETALL_START };
  }
  // function success(byID, filtered) { return { type: GETALL_SUCCESS, byID, filtered } }
  function success(byID) {
    return { type: GETALL_SUCCESS, byID };
  }
  function fail(error) {
    return { type: GETALL_ERROR, error };
  }
}

/** **********************************
// SELECTORS
************************************ */

const getAllClipartsByID = createSelector(
  (state) => state.cliparts.byID,
  (byID) => byID
);

const getAllClipartList = createSelector(
  (state) => state.cliparts.byID,
  (byID) => Object.keys(byID).map((key, index) => byID[key])
  // ( byID ) => Object.keys(byID)
);

const getClipartCategories = createSelector(
  (state) => state.cliparts.byID,
  (byID) => {
    let categories = Object.keys(byID).map((key, index) => byID[key].cat);
    categories = uniq(categories);
    categories = categories.sort();
    return uniq(categories);
  }
);

const getClipartsByCategories = createSelector(
  (state) => state.cliparts.byID,
  (byID) => {
    const clipartByCategories = {};
    Object.keys(byID).forEach((key, index) => {
      const item = byID[key]; // get item
      if (!clipartByCategories[item.cat]) {
        // create category arry if not existing
        clipartByCategories[item.cat] = [];
      }
      clipartByCategories[item.cat].push(item); // push item in correct category object
    });
    return clipartByCategories;
  }
);

/** **********************************
// EXPORT PUBLIC ACTIONS
************************************ */

export default reducer;

export const clipartSelectors = {
  getAllClipartsByID,
  getAllClipartList,
  getClipartCategories,
  getClipartsByCategories,
};

export const clipartActions = {
  getAll,
};
