import MD5 from 'crypto-js/md5';

export const tempImageFolderName = '@@temp@@';

export const ASSET_TYPE = {
  PHOTO: 'photo',
  BACKGROUND: 'background',
  CLIPART: 'clipart',
};

export const PHOTO_FILTER_OPTIONS = {
  BY_DATE_DESC: 'BY_DATE_DESC',
  BY_DATE_ASC: 'BY_DATE_ASC',
  BY_IMPORT_DATE_ASC: 'BY_IMPORT_DATE_ASC',
  BY_IMPORT_DATE_DESC: 'BY_IMPORT_DATE_DESC',
  BY_NAME_ASC: 'BY_NAME_ASC',
  BY_NAME_DESC: 'BY_NAME_DESC',
};

/**
 * @param {string} id
 * @param {string} name
 * @return {Photo}
 */
export const CreatePhoto = (id, name) => ({
  ...CreateDefaultImageAsset(id, name),

  // dates
  creation_date: null,
  modification_date: null,

  // temp informations
  temp_url: null,
  temp: false,

  // md5 for dupplicate
  md5: null,
});

/**
 *
 * @param {string} id
 * @param {string} name
 * @returns {ImageAsset}
 */
export const CreateDefaultImageAsset = (id, name) => ({
  id,
  name,
  cat: null,

  // we recover width and height after initial image load
  width: 0,
  height: 0,

  // url informations
  thumb_url: null,
  working_url: null,
  full_url: null,

  // order suffix (for print frameDir)
  orderSuffix: null,
});

/**
 *
 * @param {String} photoID
 * @param {*} photoList
 * @return {Photo}
 */
export function GetPhotoByID(photoID, photoList) {
  let result = null;
  photoList.forEach((photoObj) => {
    if (photoObj.id === photoID) {
      result = photoObj;
    }
  });
  return result;
}

/**
 * Create a temporary photo object
 * @param {File} fileObj Detail of image object (file)
 * @param {string} fileTempSrc blob based url // see: https://medium.com/@gabriele.cimato/on-how-to-store-an-image-in-redux-d623bcc06ca7
 */
export function CreateTempPhoto(fileObj, fileTempSrc) {
  /// ///////////// FROM FILE //////////////
  // lastModified:1266242574000
  // lastModifiedDate:Mon Feb 15 2010 15:02:54 GMT+0100 (heure normale d’Europe centrale) {}
  // name:"A_1920.jpg"
  // originFileObj:File {uid: "rc-upload-1575720035324-2", name: "A_1920.jpg", lastModified: 1266242574000, …}
  // lastModified:1266242574000
  // lastModifiedDate:Mon Feb 15 2010 15:02:54 GMT+0100 (heure normale d’Europe centrale) {}
  // name:"A_1920.jpg"
  // size:691329
  // type:"image/jpeg"
  // uid:"rc-upload-1575720035324-2"
  // webkitRelativePath:""

  const tempPhoto = CreatePhoto(fileObj.uid, fileObj.name);
  tempPhoto.creation_date = `${fileObj.lastModified}`;
  tempPhoto.modification_date = `${fileObj.lastModified}`;
  tempPhoto.temp = true;
  tempPhoto.temp_url = fileTempSrc;

  tempPhoto.md5 = MD5(`${tempPhoto.name}${fileObj.size}`);
  // alert("MD5: " + tempPhoto.md5);
  // tempPhoto.size = fileObj.size; // keep for testing in loading step afterwards

  return tempPhoto;

  // return {

  //     id: fileObj.uid,
  //     name: fileObj.name,
  //     creation_date: fileObj.lastModified,
  //     modification_date: fileObj.lastModified,

  //     temp_url: fileTempSrc,
  //     // origin: fileSrc ,// origin is the full file base64

  //     temp: true,
  // }

  // FROM BACKEND::
  // cat: "16/05/14"
  // creation_date: "1358540920000"
  // ext: "jpg"
  // full_url: "https://editor.tictacphoto.com//flex/img.php?img=3824764"
  // height: "1936"
  // id: "3824764"
  // modification_date: "1400240517000"
  // name: "2013-01-18 15.28.40.jpg"
  // thumb_url: "https://editor.tictacphoto.com//flex/tn.php?img=3824764"
  // width: "2592"
  // working_url: "https://editor.tictacphoto.com//flex/work.php?img=3824764"

  // FROM PROJECT??
  // width:1936
  // height:1936
  // highUrl:"https://editor.tictacphoto.com/flex/img.php?img=3912021"
  // id:"3912021"
  // name:"2013-01-18 15.28.43.jpg"
  // normalUrl:"https://editor.tictacphoto.com/flex/work.php?img=3912021"
  // thumbUrl:"https://editor.tictacphoto.com/flex/tn.php?img=3912021"
}
