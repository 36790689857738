import React from 'react';
import { Button } from 'antd';
import type {
  Photo,
  IPage,
  BackgroundItem,
  Clipart,
  OverlayerItem,
} from '../../../types/types';
import withForwardedRef from '../../../utils/withForwardedRef';
import {
  IsFrameEmpty,
  FRAME_TYPE,
  IMAGE_QUALITY,
  GetImageQualityIndicator,
} from '../../../feature/edition/frameHelper';
import {
  ComposeClassicCoverURL,
  IsClassicCoverPage,
} from '../../../utils/coverHelper';
import {
  PageCanBeDragged,
  GetPageDisplayName,
} from '../../../utils/pageHelper';
import { DRAG_TYPE, GetDragData, AddDragData } from '../../../utils/DragUtils';
import { mmToPoint } from '../../../utils/MeasureUtils';
import { FramePostcardBG } from '../EditionArea/FramePostcardBG';
import { Colors } from '../../../data/Colors';
import { OverlayerHelper } from '../../../feature/overlayers/overlayerHelper';
import { IsCardEditor } from '../../../data/config';

// props interface
interface PageNavigatorItemProps {
  selected: boolean;
  page: IPage;
  docID: string;
  pageIndex: number;

  // assets
  photos: Record<Photo>;
  backgroundsByID: Record<BackgroundItem>;
  clipartsByID: Record<Clipart>;

  // callbacks
  onClick: void;
  onPageDrop(fromIndex: number, toIndex: number): void;
}

/**
 * Render a page inside the page navigator
 */
class PageNavigatorItem extends React.Component<PageNavigatorItemProps> {
  // --------------------- Constructor ------------------------

  constructor(props) {
    super(props);
    this.state = {
      dragOver: false,
    };
  }

  // --------------------- react ------------------------

  shouldComponentUpdate(nextProps, nextState) {
    const selectionChange = nextProps.selected !== this.props.selected;
    const currentPageSelected = this.props.selected; // current page is always being "re rendered" to be sure frame updaes are correctly updated
    const dragOverChanged = this.state.dragOver !== nextState.dragOver;
    const pageChanged =
      this.props.page.id !== nextProps.page.id ||
      (this.props.page.frames.length > 0 &&
        this.props.page.frames[0] !== nextProps.page.frames[0]);

    // selected:PropTypes.bool,
    // page: PageType,
    // photos: PropTypes.arrayOf(PhotoType)
    return (
      currentPageSelected || selectionChange || pageChanged || dragOverChanged
    );
  }

  // --------------------- Methods ------------------------

  /// ///////////////////////////////////////////////////
  // Render
  /// ///////////////////////////////////////////////////
  render() {
    // console.log("RENDERING PAGE NAVIGTATOR ITEM" );//:"+this.props.page.index);

    const {
      selected,
      photos,
      key,
      backgroundsByID,
      clipartsByID,
      overlayersByID,
    } = this.props;
    const { page } = this.props;
    const MAX_HEIGHT = 75;

    const frameList = page.frames;
    const pageIndex = key;

    const scaleRatio = MAX_HEIGHT / page.height;
    const pageHeight = page.height * scaleRatio;
    const pageWidth = page.width * scaleRatio;

    // case classic cover
    const { isCover } = page;
    const classicCoverURL = page.coverClassicOptions
      ? ComposeClassicCoverURL(page.coverClassicOptions)
      : null;

    return (
      // TODO:check to use antd Grid here with list item card
      <div
        id={`navigator_${page.index}`}
        className={`item${IsClassicCoverPage(page) ? '_classic' : ''}${
          selected ? ' selected' : ''
        }${this.state.dragOver ? ' dragOver' : ''}`}
        // {...this.props} // shorthand to pass all props..
        key={page.id}
        ref={this.props.forwardedRef}
        // onDragStart = {(e)=> this.onImageDragStart(e, key, item)}
        role="button"
        tabIndex="0"
        onClick={this.props.onClick}
        style={{
          width: pageWidth,
          height: pageHeight,
          backgroundColor: classicCoverURL ? 'rgb(100, 100, 100,0)' : 'inerit',
          marginRight: IsCardEditor() ? 8 : 0,

          // visibility:(this.props.visibility)?"visible":"hidden"
        }}
        draggable={PageCanBeDragged(page)}
        onDragStart={(e: DragEvent) => {
          AddDragData(e, DRAG_TYPE.NAVIGATOR_ITEM, page.index.toString());
        }}
        onDragOver={(e: DragEvent) => {
          // allow only page navigator drop
          // TODO: this is not working as datatransfer is not available in dragOver event... such a shame..
          // const pageIndexDrop = GetDragData(e, DRAG_TYPE.NAVIGATOR_ITEM );
          // if(pageIndexDrop)
          // {
          //     this.setState({dragOver:true})
          //     alert(pageIndexDrop);
          // }
          // to allow the drop to work..
          if (PageCanBeDragged(page)) {
            e.preventDefault();
            // if(GetDragData(e,DRAG_TYPE.NAVIGATOR_ITEM))
            this.setState({ dragOver: true });
          }
        }}
        onDragLeave={(e: DragEvent) => {
          if (this.state.dragOver) this.setState({ dragOver: false });
        }}
        onDrop={(e: DragEvent) => {
          const pageIndexDrop = GetDragData(e, DRAG_TYPE.NAVIGATOR_ITEM);
          this.setState({ dragOver: false });
          if (
            PageCanBeDragged(page) &&
            pageIndexDrop &&
            pageIndexDrop !== page.index.toString()
          )
            this.props.onPageDrop(
              Number.parseInt(pageIndexDrop, 10),
              page.index
            );
        }}
      >
        {
          // --------------------- case cover classic ------------------------
          classicCoverURL && (
            <img
              src={classicCoverURL}
              style={{
                width: pageWidth,
                height: pageHeight,
                position: 'absolute',
              }}
            />
          )
        }

        <svg width={pageWidth} height={pageHeight}>
          {
            /// ////////////////
            // DRAW FRAMES
            frameList.map((frame, i) => {
              // with frame from json
              const t = {
                x: (frame.x - frame.width / 2) * scaleRatio,
                y: (frame.y - frame.height / 2) * scaleRatio,
                width: frame.width * scaleRatio,
                height: frame.height * scaleRatio,
                angle: frame.rotation,
              };

              const pic = frame.photo
                ? photos[frame.photo]
                : frame.background
                ? backgroundsByID[frame.background]
                : null;

              const clipart = frame.clipart
                ? clipartsByID[frame.clipart]
                : null;
              const isEmpty = IsFrameEmpty(frame);
              const borderInPixel = mmToPoint(frame.border) * scaleRatio;

              const overlayer: OverlayerItem = frame.overlayer
                ? overlayersByID[frame.overlayer]
                : null;
              const overlayerRect = overlayer
                ? OverlayerHelper.GetOverLayerOverRect(overlayer, t)
                : null;

              const imgQuality = GetImageQualityIndicator(frame);

              const hasError: boolean =
                (frame.photo || frame.background) && !pic;
              const scaledBorderRadius = frame.borderRadius
                ? frame.borderRadius * scaleRatio
                : 0;

              // Draw frame
              return (
                <g
                  key={`${pageIndex}_${i}`}
                  transform={`translate(${t.x},${t.y}) rotate(${t.angle},${
                    t.width / 2
                  },${t.height / 2})`}
                >
                  {scaledBorderRadius && (
                    <defs>
                      <clipPath id={`mask_${frame.id}_nav`}>
                        {/* <ellipse cx={frame.width/2} cy={frame.height/2} rx={frame.width/2} ry={frame.height/2} /> */}
                        <rect
                          x={0}
                          y={0}
                          ry={
                            scaledBorderRadius && frame.border
                              ? scaledBorderRadius + borderInPixel
                              : scaledBorderRadius
                          }
                          rx={
                            scaledBorderRadius
                              ? scaledBorderRadius + borderInPixel
                              : scaledBorderRadius
                          }
                          width={t.width}
                          height={t.height}
                        />
                      </clipPath>
                    </defs>
                  )}
                  <g>
                    {
                      // --------------------- empty ------------------------
                      isEmpty && (
                        <rect
                          x="0"
                          y="0"
                          width={t.width}
                          height={t.height}
                          style={{
                            stroke: '#cccccc',
                            strokeWidth: 1,
                            // strokeDasharray: "10 5",
                            fill:
                              frame.type === FRAME_TYPE.TEXT
                                ? Colors.BLUE_LIGHT
                                : '#cccccc',
                            fillOpacity: '0.4',
                          }}
                        />
                      )
                    }

                    {
                      // --------------------- fill ------------------------
                      frame.fillColor && frame.fillColor !== '#ffffff' && (
                        <rect
                          x="0"
                          y="0"
                          width={t.width}
                          height={t.height}
                          style={{
                            // stroke: "#000000",
                            // strokeWidth: 3,
                            // strokeDasharray: "10 5",
                            fill: frame.fillColor,
                          }}
                        />
                      )
                    }

                    {
                      // --------------------- calendar ------------------------
                      frame.type === FRAME_TYPE.CALENDAR && (
                        <rect
                          x="0"
                          y="0"
                          width={t.width}
                          height={t.height}
                          style={{
                            // stroke: "#000000",
                            // strokeWidth: 3,
                            // strokeDasharray: "10 5",
                            // fill: frame.fillColor,

                            stroke: '#cccccc',
                            strokeWidth: '1px',
                            fillOpacity: '0.4',
                          }}
                        />
                      )
                    }

                    {
                      // --------------------- postcardBG ------------------------
                      frame.type === FRAME_TYPE.POSTCARD_BG && (
                        <FramePostcardBG />
                      )
                    }

                    {/** *******************
                            IMAGE
                            Check this for more clipping/mask information
                            https://webdesign.tutsplus.com/tutorials/a-comprehensive-guide-to-clipping-and-masking-in-svg--cms-30380
                            ********************** */}
                    {
                      // (pic && //this.props.visibility) &&
                      pic && (
                        <g clipPath={`url(#mask_${frame.id}_nav)`}>
                          <svg
                            width={t.width}
                            height={t.height}
                            viewBox={`${frame.cLeft * scaleRatio} ${
                              frame.cTop * scaleRatio
                            } ${t.width} ${t.height}`}
                          >
                            {/*
                                width={frame.width} height={frame.height}
                                viewBox={"200 0 " + pic.width*frame.zoom + " " + pic.height*frame.zoom}
                                preserveAspectRatio="slice"

                                viewBox={"0 0 " + pic.width*frame.zoom + " " + pic.height*frame.zoom}>
                                preserveAspectRatio="slice"
                                */}

                            {/*    USING FOREIGN OBJECT AS SVG IMAGE! IT's WORKING, see for performances
                                <foreignObject
                                    width={pic.width*frame.zoom*scaleRatio}
                                    height={pic.height*frame.zoom*scaleRatio}
                                >
                                <img src={pic.thumb_url} style={{ width:pic.width*frame.zoom*scaleRatio, height:pic.height*frame.zoom*scaleRatio}} />
                                </foreignObject>
                                 */}

                            <image // id={frame.id}
                              key={pic.id}
                              // href={frame.content.image}
                              xlinkHref={
                                pic.temp ? pic.temp_url : pic.thumb_url
                              }
                              href={pic.temp ? pic.temp_url : pic.thumb_url}
                              width={pic.width * frame.zoom * scaleRatio}
                              height={pic.height * frame.zoom * scaleRatio}
                              // height={pic.height*frame.zoom}
                              // width={pic.width*frame.zoom}

                              /* // reference point is in the center of frame
                                                            x={-frame.transform.width/2} // reference point is in the center of frame
                                                            y={-frame.transform.height/2} // reference point is in the center of frame!
                                                            */

                              // backgroundcolor="#fff000"
                              // transform={"rotate("+ frame.transform.angle +"," + w/2 +"," + h/2 +")"}
                              preserveAspectRatio="none"
                              // onClick={(e)=> { this.onFrameSelect( e.target, frame ) ;}}
                              /*
                                                        style={{
                                                                                                transformOrigin: "50% 50%", // = pivot = "center"
                                                            transform: "rotate(" + frame.transform.angle+ "deg) translateX("+frame.transform.x+"px) translateY("+frame.transform.y+"px)"
                                                        }} */
                            />
                          </svg>
                        </g>
                      )
                    }

                    {
                      // --------------------- Clipart ------------------------
                      clipart && (
                        <image // id={frame.id}
                          key={clipart.id}
                          // href={frame.content.image}
                          xlinkHref={clipart.thumb_url}
                          href={clipart.thumb_url}
                          width={frame.width * scaleRatio}
                          height={frame.height * scaleRatio}
                          preserveAspectRatio="none"
                        />
                      )
                    }

                    {
                      // --------------------- Text ------------------------
                      frame.text && (
                        <foreignObject
                          x="0"
                          y="0"
                          className="unselectable"
                          height={t.height}
                          width={t.width}
                        >
                          <div
                            style={{
                              color: frame.text.color || 'white',
                              fontSize: frame.text.size * scaleRatio,
                              fontFamily: frame.text.family,
                              fontStyle: frame.text.italic
                                ? 'italic'
                                : 'normal',
                              fontWeight: frame.text.bold ? 'bold' : 'normal',
                              pointerEvents: 'none',
                              whiteSpace: 'pre-wrap', //  to keep \n
                              // height:"100%",

                              // width:"100%",

                              // display:"block",
                              // justifyContent: frame.text.halign,
                              textAlign: frame.text.halign,

                              width: t.width,
                              height: t.height,
                              // lineHeight:t.height/2,
                              // textAlign: frame.text.halign,

                              // vertical centenr
                              // TODO: this breaks on safari...
                              // position:"absolute",
                              // top:"50%",
                              // transform:"translateY(-50%)",

                              // working in safari
                              display: 'table-cell',
                              verticalAlign: 'middle',

                              // alignItems: "center",
                              // overflow:"hidden",
                              // wordWrap:"break-word"
                            }}
                          >
                            {frame.text.value}
                          </div>
                        </foreignObject>
                      )
                    }

                    {
                      // --------------------- image quality ------------------------
                      imgQuality !== IMAGE_QUALITY.GOOD && (
                        <rect
                          x={t.width - 10}
                          y={t.height - 10}
                          width={8}
                          height={8}
                          style={{
                            fill:
                              imgQuality === IMAGE_QUALITY.BAD
                                ? Colors.RED_FLASH
                                : Colors.YELLOW_FLASH,
                            fillOpacity: '1',
                          }}
                        />
                      )
                    }

                    {
                      // --------------------- has error ------------------------
                      hasError && (
                        <rect
                          x="0"
                          y="0"
                          width={t.width}
                          height={t.height}
                          style={{
                            fill: '#ff0000',
                            fillOpacity: '1',
                          }}
                        />
                      )
                    }

                    {
                      // --------------------- Frame border ------------------------
                      !isEmpty && frame.border !== 0 && (
                        <rect
                          rx={scaledBorderRadius}
                          ry={scaledBorderRadius}
                          x={borderInPixel / 2}
                          y={borderInPixel / 2}
                          width={t.width - borderInPixel}
                          height={t.height - borderInPixel}
                          style={{
                            pointerEvents: 'none',
                            stroke: frame.borderColor,
                            strokeWidth: borderInPixel,
                            fill: '#ff0000',
                            // fillOpacity: '0.4',
                            fillOpacity: '0',
                          }}
                        />
                      )
                    }

                    {
                      // --------------------- overlayer ------------------------
                      overlayer && (
                        <g key={overlayer.id}>
                          <image
                            href={overlayer.working_url}
                            xlinkHref={overlayer.working_url}
                            width={overlayerRect.width}
                            height={overlayerRect.height}
                            x={overlayerRect.x}
                            y={overlayerRect.y}
                            preserveAspectRatio="none"
                          />
                        </g>
                      )
                    }
                  </g>
                </g>
              );
            })
            // END DRA FRAMES
            /// ////////////////
          }
        </svg>

        {IsCardEditor() && (
          <div className="pageNavigatorItem_label unselectable">
            {GetPageDisplayName(this.props.docID, this.props.pageIndex)}
          </div>
        )}
      </div>
    );
  }

  // <svg x={this.props.x} y={this.props.y} width={currentPage.width} height={currentPage.height} style={ { position:"absolute", backgroundColor:"#fff000" }} >
  //                 <rect x="0" y="0" width="100%" height="100%"
  //                     style={{

  //                         stroke: (this.props.selected)?"#fff000":"#009900",
  //                         strokeWidth: (this.props.selected)?10:3,
  //                         strokeDasharray: (this.props.selected)?"0 0": "10 5",
  //                         fill: "none"
  //                         }}
  //                         />

  //             {currentPage.frames.map( ( frame, i ) => {

  //                 //const t = frame.transform;

  //                 // with frame from json
  //                 const t = {
  //                     x : frame.x - frame.width/2,
  //                     y : frame.y - frame.height/2,
  //                     width : frame.width,
  //                     height : frame.height,
  //                     angle : frame.rotation,
  //                     //angle : Math.random()* 45 - 25// frame.rotation
  //                 }

  //                 const pic = (frame.photo)? photos[frame.photo] : null;

  //                 return (

  //                     <g key={"" + pageIndex + "_" + i}
  //                        transform={"translate(" + t.x + "," + t.y + ") rotate("+ t.angle +"," + t.width/2 +"," + t.height/2 +")"}
  //                        //onClick={(e)=> { this.onFrameSelect( e.target, frame ) ;}} >
  //                        onClick={(e)=> { this.onFrameSelect( i ) ;}} >

  //                     {/* Background fill */}
  //                     <rect x="0" y="0" width={t.width} height={t.height}
  //                             style={{
  //                                 //stroke: "#000000",
  //                                 //strokeWidth: 3,
  //                                 //strokeDasharray: "10 5",
  //                                 fill: "#ffffff",
  //                                 fillOpacity:"0.4"
  //                                 }}
  //                                 />

  //                     { /*********************
  //                         IMAGE
  //                         Check this for more clipping/mask information
  //                         https://webdesign.tutsplus.com/tutorials/a-comprehensive-guide-to-clipping-and-masking-in-svg--cms-30380
  //                       ***********************/}
  //                     {pic &&

  //                     <svg width={t.width} height={t.height}
  //                         viewBox={"" + (frame.cLeft) + " " + (frame.cTop) + " " + (t.width) + " " + (t.height)}

  //                         >
  //                         {/*
  //                         width={frame.width} height={frame.height}
  //                         viewBox={"200 0 " + pic.width*frame.zoom + " " + pic.height*frame.zoom}
  //                         preserveAspectRatio="slice"

  //                         viewBox={"0 0 " + pic.width*frame.zoom + " " + pic.height*frame.zoom}>
  //                         preserveAspectRatio="slice"
  //                         */}
  //                         <image //id={frame.id}
  //                             key={pic.id}
  //                             //href={frame.content.image}
  //                             href={pic.normalUrl}
  //                             width={pic.width*frame.zoom}
  //                             height={pic.height*frame.zoom}
  //                             //height={pic.height*frame.zoom}
  //                             //width={pic.width*frame.zoom}

  //                             /* // reference point is in the center of frame
  //                             x={-frame.transform.width/2} // reference point is in the center of frame
  //                             y={-frame.transform.height/2} // reference point is in the center of frame!
  //                             */

  //                             //backgroundcolor="#fff000"
  //                             //transform={"rotate("+ frame.transform.angle +"," + w/2 +"," + h/2 +")"}
  //                             preserveAspectRatio="none"
  //                             //onClick={(e)=> { this.onFrameSelect( e.target, frame ) ;}}
  //                             /*
  //                             style={{
  //                                                                     transformOrigin: "50% 50%", // = pivot = "center"
  //                                 transform: "rotate(" + frame.transform.angle+ "deg) translateX("+frame.transform.x+"px) translateY("+frame.transform.y+"px)"
  //                             }}*/

  //                             />
  //                     </svg>
  //                     }

  //                         { /* DISPLAY DEBUG TEXT OVER FRAME */}
  //                         {/*
  //                         <foreignObject x="0" y="0" className="unselectable"
  //                          height={t.height}
  //                          width={t.width}
  //                          style={{color:"#fff000", pointerEvents:"none", overflow:"hidden", wordWrap:"break-word"}}>

  //                         <div>
  //                         {JSON.stringify(frame)}
  //                         </div>
  //                         </foreignObject>
  //                         */}

  //                             {/*
  //                             <foreignObject x="20" y="20" width="160" height="160">

  //                                 {/* Dans le cas d'un SVG intégré dans du HTML, le namespace XHTML peut
  //                                 être omis, mais il est obligatoire dans le contexte d'un document SVG * /}

  //                                 <div xmlns="http://www.w3.org/1999/xhtml">
  //                                 Lorem ipsum dolor sit amet, consectetur adipiscing elit.
  //                                 Sed mollis mollis mi ut ultricies. Nullam magna ipsum,
  //                                 porta vel dui convallis, rutrum imperdiet eros. Aliquam
  //                                 erat volutpat.
  //                                 </div>
  //                             </foreignObject>
  //                             */

  //                             //</svg>
  //                             }

  //                         {/* BORDER */}
  //                         <rect x="0" y="0" width={t.width} height={t.height}
  //                             style={{stroke: "#000000",
  //                                 strokeWidth: 3,
  //                                 //strokeDasharray: "10 5",
  //                                 fill: "none"
  //                                 }}
  //                                 />

  //                     { /*********************
  //                         TEXT
  //                       ***********************/}
  //                     { (frame.text !==null) &&
  //                         //(frame.content.text !==null)
  //                         <foreignObject x="0" y="0" className="unselectable"
  //                          height={t.height}
  //                          width={t.width}
  //                          style={{
  //                              color:"#fff000",
  //                              fontSize:frame.text.size,
  //                              //pointerEvents:"none",
  //                              overflow:"hidden",
  //                              wordWrap:"break-word"}}>

  //                             <div>
  //                             {frame.text.value}
  //                             </div>
  //                         </foreignObject>
  //                     }

  //                     {/*********************************************
  //                          SVG TEXT DO NOT WRAP......

  //                             <text id={frame.id}
  //                                     height={frame.height}
  //                                     width={frame.width}
  //                                     //x={frame.x}
  //                                     //y={frame.y}
  //                                     backgroundcolor="#fff000"
  //                                     //transform={"rotate("+ frame.angle +"," + frame.width/2 +"," + frame.height/2 +")"}
  //                                     preserveAspectRatio="none"
  //                                     color="#ff0000"
  //                                     fontFamily="Verdana"
  //                                     fontSize="55"
  //                                     onClick={(e)=> { this.onFrameSelect( e.target, frame ) ;}}
  //                                     >
  //                                 {frame.text.value}
  //                             </text>
  //                     ********************************************/}

  //                         }

  //                         {/*}
  //                                 <text //id={frame.id}
  //                                     height={frame.transform.height}
  //                                     width={frame.transform.width}
  //                                     x={frame.transform.width/2}
  //                                     y={frame.transform.height/2}
  //                                     pointerEvents="none"
  //                                     //backgroundcolor="#ffffff"
  //                                     //transform={"rotate("+ frame.transform.angle +"," + w/2 +"," + h/2 +")"}
  //                                     //preserveAspectRatio="none"
  //                                     style={ { overflow:"hidden",
  //                                             textAnchor:"middle",
  //                                             fontSize:15,
  //                                             fontWeight:"bold",
  //                                             fontFamily:"Verdana",
  //                                             fill:"white",
  //                                             stroke:"black",
  //                                             strokeWidth:2}}

  //                                     //onClick={(e)=> { this.onFrameSelect( e.target, frame ) ;}}
  //                                 >
  //                                 {JSON.stringify(frame)}
  //                         </text>
  //                         </g>
  //                     )

  //             })

  //         */}
  //         </g>
  //         )})}

  //             {/* <title>{'Rectangle 5'}</title>
  //             <path d="M0 0h48v1H0z" fill="#063855" fillRule="evenodd" />
  //             <image href="https://mdn.mozillademos.org/files/6457/mdn_logo_only_color.png" height="200" width="200"/> */}
  //             </svg>
}

// --------------------- PropTypes ------------------------
// PageNavigatorItem.propTypes = {
//     selected:PropTypes.bool,
//     page: PageType,
//     photos: PropTypes.objectOf(PhotoType),
//     backgroundsByID: PropTypes.object,
//     clipartsByID: PropTypes.object,
// }

// --------------------- export ------------------------

// export default withForwardedRef(PageNavigatorItem)
export default withForwardedRef(PageNavigatorItem);
// export { PageNavigatorItem };
