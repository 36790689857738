/* eslint-disable no-mixed-spaces-and-tabs */
import { cloneDeep, isEqual } from 'lodash';
import { Modal } from 'antd';
import { RandomInt, RandomArrayElement } from './MathUtils';
import { GetUID } from './UID';
import { DebugFlags, IsDebug } from '../debug/DebugFlags';
import { escapeHtml } from './HtmlUtils';
import {
  PROJECT_CONST,
  IsCalendarEditor,
  IsAlbumEditor,
  IsCardEditor,
  IsCanvasEditor,
} from '../data/config';
import {
  GetDoc,
  GetProjectSizeCM,
  GetDocIDByDocCode,
  GetProjectTypeByDocID,
} from './ProductHelper';
import { CreatePage } from './pageHelper';
import {
  LAYOUT_TYPE,
  ApplyLayoutToPage,
  GetForcedLayoutsForPage,
} from '../feature/layouts/layoutHelper';
import {
  FRAME_TYPE,
  AddDefaultTextToFrame,
  CleanAndVerifyFrameText,
  ScaleFrameAndContent,
} from '../feature/edition/frameHelper';
import { cmToPixel, pixelToCm } from './MeasureUtils';
import { GetText } from '../data/LanguageHelper';
import { CheckAndUpdateProjectSpineWidth } from './spineHelper';
import { Colors } from '../data/Colors';
import { CanvasHelper, CANVAS_FORMAT } from './canvas/CanvasHelper';
import { DateUtils } from './DateUtils';
import { CardHelper } from './card/CardHelper';
import { backgroundSelectors } from '../feature/backgrounds/background';
import {
  getPageBackgroundRect,
  getCoverBackgroundRect,
  UpgradeFrameBackground,
  BackgroundHelper,
  InjectBackgroundIntoFrame,
} from '../feature/backgrounds/backgroundHelper';
import { photoListSelector } from '../feature/photoList/photoList';
import { API } from './API';
import {
  ALBUM_TYPES,
  COVER_TYPE,
  PAPER_QUALITY,
  PROJECT_CLASS,
} from '../data/Constants';
import {
  CreateClassicCoverOptions,
  CreateClassicCoverPage,
  CreateProjectCustomCover,
  GetClassicCoverOptions,
  GetCoverSizeInPixel,
  GetProjectCover,
  GetProjectCoverType,
  IsClassicCoverPage,
} from './coverHelper';
import { clipartSelectors } from '../feature/cliparts/cliparts';
import { Project } from '../data/Project';
import { CreateCalendarColorOptions } from './calendar/CreateCalendarColorOptions';

const oldProject = require('../data/projectExample.json');

/**
 *
 * @param {Project} project
 * @param {ProjectOptions} options
 */
export function ApplyOptionsToProject(project, options) {
  const doc = GetDoc(project.docID);

  // common
  project.coated =
    options.coated !== undefined ? options.coated : project.coated;
  project.matte = options.matte !== undefined ? options.matte : project.matte;

  // album
  if (IsAlbumEditor()) {
    project.paper = options.paper;
    project.pageNumber = options.pageNumber;
    project.pagePaperQuality = options.pagePaperQuality
      ? options.pagePaperQuality
      : PAPER_QUALITY.QUALITY_250;
    project.cover_matte = options.cover_matte;
    project.flyleaf = options.flyleaf;

    // securities
    if (project.paper && !doc.insert) project.paper = false;
    if (!doc.pagePaperQuality.includes(project.pagePaperQuality))
      project.pagePaperQuality =
        doc.pagePaperQuality[doc.pagePaperQuality.length - 1]; // take highest quality
    if (!project.flyleaf || !doc.flyleaf) project.flyleaf = 'white';
  }

  // calendar
  if (IsCalendarEditor()) {
    if (options.startYear !== undefined) project.startYear = options.startYear;
    if (options.startMonth !== undefined)
      project.startMonth = options.startMonth;
    if (options.startDay !== undefined) project.startDay = options.startDay;
    if (options.calendarColorOptions !== undefined)
      project.calendarColorOptions = options.calendarColorOptions;

    // security
    if (project.coated && !doc.coating) project.coated = false;
    project.matte = false; // never matte for calendars
  }

  // card
  if (IsCardEditor()) {
    project.cardPack = options.cardPack;
    project.envelope = options.envelope
      ? options.envelope
      : doc.envelopes_default;

    // securities
    if (!doc.envelopes.includes(project.envelope))
      project.envelope = doc.envelopes_default;
    if (project.matte && !doc.finish.includes('mate')) project.matte = false;
    if (project.coated && !doc.finish.includes('coating'))
      project.coated = false;
    if (!Object.keys(doc.codes).includes(project.cardPack)) {
      // TODO: up
      console.log(
        'We are changing card packs here!, meaning we could need to remove pages from project..'
      );
      project.cardPack = doc.packs_default;
    }
  }

  // canvas
  if (IsCanvasEditor()) {
    project.canvasFrameColor = options.canvasFrameColor;
    project.canvasFormat = options.canvasFormat;
  }
}

/** HELPERS FOR CLASSNAME */
// export function IsAlbum( project : Project ) {
//  return (project.classname === PROJECT_CLASS.ALBUM);
// }
// export function IsCalendar( project : Project ) {
//  return (project.classname === PROJECT_CLASS.CALENDAR);
// }
// export function IsCanvas( project : Project ) {
//  return (project.classname === PROJECT_CLASS.CANVAS);
// }
// export function IsCards( project : Project ) {
//  return (project.classname === PROJECT_CLASS.CARD);
// }

export function IsClassicCoverProject(project_type: string) {
  return project_type === ALBUM_TYPES.CLASSIC;
}

export function GetProjectDisplayPage(project: Project): number {
  return project.classname === PROJECT_CLASS.ALBUM
    ? project.pageList.length - 1
    : project.pageList.length;
}

/**
 *
 Get a project description (classname type, docID, etc..)
 */
export function GetProjectDescription(project: Project) {
  // ---- CANVAS ----
  if (IsCanvasEditor()) {
    const product = GetText(`class.${project.classname}`);
    const type = GetText(`canvas.type.${project.type}_types`);
    const format = GetText(
      `canvas.orientation.types_canvas_${project.canvasFormat}`
    );
    const dimension = GetProjectSizeCM(project, 0);
    return `${product} - ${type} - ${format} - ${dimension}`;
  }

  // ---- OTHER PRODUCTS ----
  const numPages = GetProjectDisplayPage(project);
  return `${GetText(`class.${project.classname}`)} - ${
    project.type
  } - ${GetText(
    `${PROJECT_CONST.project_class_no_s}.prefix.${project.docID}`
  )} - ${numPages} ${GetText('common.pages')}`;
}

// export function AddNewPagesToProject( proj:Project, numPages:number, indexToAdd:number, layoutStore )
// {
//  let pages = CreateProjectPages(proj, numPages, indexToAdd, layoutStore);
//  InjectPagesAtIndex(proj,pages, indexToAdd);
// }

export function GetProjectMaxPages(project: Project) {
  const projectDocument = GetDoc(project.docID);
  return project.pagePaperQuality === PAPER_QUALITY.QUALITY_250
    ? projectDocument.maxPages
    : 200;
}

export function GetProjectMinPages(project: Project) {
  const projectDocument = GetDoc(project.docID);
  return projectDocument.minPages;
}

/**
 * Create a new pages for the project,
 * NOTE: this do not change the project
 */
export function CreateProjectPages(
  project: Project,
  numPages: number,
  pagesStartIndex: number,
  layoutStore
): Array {
  const newPages = [];
  const layoutList = layoutStore.filtered[LAYOUT_TYPE.DEFAULT].all;
  const layoutsByID = layoutStore.byID;

  const doc = GetDoc(project.docID);
  // default layout list for this docID
  const defaultLayoutList = doc.layouts_default;

  // create pages
  for (
    let index = pagesStartIndex;
    index < pagesStartIndex + numPages;
    index++
  ) {
    const page = CreatePage(index, project.width, project.height);

    let newLayout = null;

    // check if we have a default page for this index
    // if(defaultLayoutList && defaultLayoutList.length > index && defaultLayoutList[index]) // Before, if there were non element at index in default, we would go back to random list, now we loop..
    if (defaultLayoutList && defaultLayoutList.length > 0) {
      //
      newLayout =
        layoutsByID[defaultLayoutList[index % defaultLayoutList.length]];
    } else {
      // list of available layout for this docID
      const forcedLayoutList = GetForcedLayoutsForPage(project.docID, index);
      newLayout =
        layoutsByID[RandomArrayElement(forcedLayoutList || layoutList)];
    }

    ApplyLayoutToPage(
      project,
      page,
      newLayout,
      null,
      null,
      getPageBackgroundRect(project)
    );
    newPages.push(page);
  }

  return newPages;
}

/**
 *
 * @param {Project} project
 * @param {Number} newWidth
 * @param {Number} newHeight
 * @param {Record} layoutStore
 * @param {Record.<BackgroundItem>} backgroundsByID
 */
export function UpgradeProjectSize(
  project,
  newWidth: number,
  newHeight: number,
  layoutStore,
  backgroundsByID
) {
  const doc = GetDoc(project.docID);

  // change size
  project.width = newWidth; // the width in pixel
  project.height = newHeight; // the height in pixel
  project.cutBorder = doc.cutBorder;

  // ---- ALBUM size UPGRADES ----
  if (IsAlbumEditor()) {
    // verify insert options
    if (!doc.insert && project.paper) project.paper = false;

    // verify paper quality options
    if (
      !doc.pagePaperQuality.includes('250') &&
      project.pagePaperQuality === PAPER_QUALITY.QUALITY_250
    )
      project.pagePaperQuality = PAPER_QUALITY.QUALITY_170;

    // upgrade cover if album
    upgradeProjectCover(project, layoutStore, backgroundsByID);
  }

  // upgrade all other pages
  project.pageList.forEach((page /** :IPage* */, index) => {
    if (!page.isCover)
      UpgradeProjectPage(
        project,
        page,
        project.width,
        project.height,
        getPageBackgroundRect(project),
        backgroundsByID
      );
  });
}

/**
 * Upgrade a project page to a new size
 *
 * @param {Project} project
 * @param {IPage} page
 * @param {number} newWidth
 * @param {number} newHeight
 * @param {Rectangle} backgroundBounds
 * @param {Record.<BackgroundItem>} backgroundsByID
 *
 * @returns {void}
 */
export function UpgradeProjectPage(
  project,
  page,
  newWidth,
  newHeight,
  backgroundBounds,
  backgroundsByID
) {
  const scaleX = newWidth / page.width;
  const scaleY = newHeight / page.height;

  // upgrade frames
  page.frames.forEach((frame, index, arr) => {
    if (index === 0) {
      UpgradeFrameBackground(frame, backgroundBounds, backgroundsByID);
    } else {
      // update posx and y
      frame.x *= scaleX;
      frame.y *= scaleY;

      // update size and content
      ScaleFrameAndContent(frame, frame.width * scaleX, frame.height * scaleY);
    }

    if (IsCanvasEditor())
      CanvasHelper.CheckModifyFrameForEdge(
        project.type,
        newWidth,
        newHeight,
        frame
      );
  });

  page.width = newWidth;
  page.height = newHeight;
  return page;
}

/**
 * Clean and verify custom cover
 * -> check if cover size is correct based on num pages and project options
 * -> check if spine in the cover has correct size, and if not clean it
 */
export function cleanAndVerifyCustomCover(project: Project) {
  const cover /** :IPage* */ = GetProjectCover(project);
  const doc = GetDoc(project.docID);
  const coverDoc = doc.cover;

  // previous project type is still in the cover info
  if (GetProjectCoverType(project.type) === COVER_TYPE.COVER_CUSTOM) {
    // retreive correct cover size (with spine Width and options)
    const newCoverSize = GetCoverSizeInPixel(project);
    // upgrade project size to correct width:
    UpgradeProjectPage(
      project,
      cover,
      newCoverSize.width,
      newCoverSize.height,
      getCoverBackgroundRect(project)
    );
    // be sure the spine frame is at correct position and size applyBorderToAllFrames
    CheckAndUpdateProjectSpineWidth(project);
  }
}

export function upgradeProjectCover(project: Project, layoutStore) {
  let cover /** :IPage* */ = GetProjectCover(project);
  const doc = GetDoc(project.docID);
  const coverDoc = doc.cover;

  // Security, this should never never happen!!
  if (!cover) {
    Modal.warn({
      title: 'Fixing album cover',
      content:
        'This project has no cover, we need to create one! This should never happen',
    });
    CreateProjectCustomCover(
      project,
      layoutStore.filtered[LAYOUT_TYPE.COVER].all,
      layoutStore.byID
    );
    cover = GetProjectCover(project);
  }

  // previous project type is still in the cover info
  const prevCoverType = IsClassicCoverPage(cover)
    ? COVER_TYPE.COVER_CLASSIC
    : COVER_TYPE.COVER_CUSTOM;
  const newCoverType = GetProjectCoverType(project.type);

  // case change from different cover types
  if (newCoverType !== prevCoverType) {
    // case classic :
    if (IsClassicCoverProject(project.type)) {
      // remove frames
      cover.frames = [];
      cover.coverClassicOptions = CreateClassicCoverOptions();
    }
    // Case custom cover and we come from a classic cover
    else {
      project.pageList.shift(); // remove old cover
      cover = CreateProjectCustomCover(
        project,
        layoutStore.filtered[LAYOUT_TYPE.COVER].all,
        layoutStore.byID
      );
    }
  }

  // new size (calculated also with project options)
  // retreive correct cover size
  const newCoverSize = GetCoverSizeInPixel(project);
  UpgradeProjectPage(
    project,
    cover,
    newCoverSize.width,
    newCoverSize.height,
    getCoverBackgroundRect(project)
  );
}

/**
 *
 * inject an existing page list at a specific point in the project.
 */
/**
 *
 * @param {Record.<IPage>} pageList
 * @param {Record.<IPage>} newPages
 * @param {Number} indexToInject
 */
export function InjectPagesAtIndex(pageList, newPages, indexToInject: number) {
  const numPages = pageList.length;
  // splice pageList
  pageList.splice(indexToInject, 0, ...newPages);

  // clean all indexes
  for (let index = 0; index < pageList.length; index++) {
    if (pageList[index].index !== index) {
      pageList[index].index = index;
      // notify in case of it was an addition to the end of initial page list as this should not happen..
      if (indexToInject === numPages) {
        console.warn(
          `projectHelper.InjectPagesAtIndex: index of pages were modified... (numpages:${numPages} indexToInject:${indexToInject})`
        );
      }
    }
  }
}

/**
 * Convert an existing project form flash editor to the current data structure
 * @param {bool} AndWriteInAnotherWindow : if we need to write the result in a new window
 * TODO: this has nothing to do here, it should be an utils/helper to use outside this project.
 */
export function ConvertOldEditorJson(AndWriteInAnotherWindow = false) {
  const newJSON = {};

  // const paramsToCopy = ["id", "name", "classname", ""]

  // common
  newJSON.id = oldProject.id;
  newJSON.name = oldProject.name;
  newJSON.classname = oldProject.classname;

  // size
  newJSON.docType = oldProject.docType;
  newJSON.width = oldProject.width;
  newJSON.height = oldProject.height;
  newJSON.cutBorder = oldProject.docCutBorder;

  // photoList
  newJSON.photos = {};

  // page list
  newJSON.pageList = [];
  oldProject.pageList.forEach((oldPage, pageIndex) => {
    const newPage = {};
    newPage.index = pageIndex;

    newPage.isCover = oldPage.isCover;
    newPage.width = oldPage.bounds.width;
    newPage.height = oldPage.bounds.height;
    // ... review element needed for the page..

    newPage.frames = [];
    oldPage.layoutVo.frameList.forEach((oldFrame) => {
      const newFrame = {};

      newFrame.id = GetUID(); // we need an ID for each frame to be able to work correcly in react
      newFrame.type = oldFrame.type;

      newFrame.x = oldFrame.x;
      newFrame.y = oldFrame.y;
      newFrame.width = oldFrame.width;
      newFrame.height = oldFrame.height;
      newFrame.rotation = oldFrame.rotation;

      // edition
      newFrame.editable = oldFrame.editable;
      newFrame.fixed = oldFrame.fixed;
      newFrame.isDeletable = oldFrame.isDeletable;

      // photo
      newFrame.cLeft = oldFrame.cLeft;
      newFrame.cTop = oldFrame.cTop;
      newFrame.zoom = oldFrame.zoom;

      // text
      if (oldFrame.type === 'text' && !oldFrame.isEmpty) {
        newFrame.text = {};
        newFrame.text.size = oldFrame.fontSize;
        newFrame.text.vAlign = oldFrame.vAlign;
        newFrame.text.hAlign = oldFrame.hAlign;
        newFrame.text.value = oldFrame.text;

        /*
    {
  "sCol": 0,
  "calFontSizeRaw": "18",
  "border": 0,
  "tFormat": null,
  "fixed": false,
  "posXAdjustement": 0,
  "dateAction": "",
  "hAlign": "center",
  "stroke": false,
  "upper": false,
  "dateLinkageID": null,
  "pageIndex": 0,
  "cLeft": 0,
  "y": 578.15,
  "cTop": 0,
  "x": 1419.15,
  "uniqFrameLinkageID": null,
  "rotation": 0,
  "alignment": 0,
  "text": "<P ALIGN=\"CENTER\"><FONT FACE=\"GillSans\" SIZE=\"40\" COLOR=\"#000000\" LETTERSPACING=\"0\" KERNING=\"0\">COUCOU</FONT></P>",
  "isDeletable": true,
  "fontSize": 40,
  "dateIndex": 1,
  "isPopart": false,
  "QRLink": "",
  "mask": 0,
  "calBold": true,
  "fillColor": 16777215,
  "PopArtBlackTreshold": 30,
  "cr": 0,
  "calItalic": false,
  "tb": false,
  "isPageNumber": false,
  "shadow": false,
  "editable": true,
  "width": 702.496338,
  "photoVo": null,
  "color": 0,
  "height": 68.0194866952381,
  "PopArtTreshold": 50,
  "grouptype": 0,
  "fillAlpha": 1,
  "isMultiple": false,
  "PopArtDarkColor": 173029,
  "isPostCardBackground": false,
  "vAlign": "top",
  "zoom": 1,
  "PopArtLightColor": 10344175,
  "calFontName": "Arial",
  "showBorder": true,
  "isValidVo": true,
  "printQuality": 1,
  "IsCustomRebrandedFrame": false,
  "isEmpty": false,
  "visible": true,
  "type": "text",
  "isPrintUpload": true,
  "printCoords": {
    "x": 997.6521972,
    "y": 537.3383079828571,
    "length": 1133.155930930897
  },

}
    */

        // console.log(JSON.stringify(oldFrame));
      }

      // if(oldFrame.photoVo)
      if (
        oldFrame.photoVo &&
        (!DebugFlags.RANDOM_EMPTY_IMAGE || Math.random() < 0.8)
      ) {
        newFrame.photo = oldFrame.photoVo.id;

        // WHY KEEPING OLD PHOTOS FROM ALBUM? THOSE SHOULD BE IN THE PHOTO LIST
        // let oldPhoto = oldFrame.photoVo;
        // let newPhoto = {};

        // newPhoto.id = oldPhoto.id;
        // newPhoto.name = oldPhoto.name;

        // // size
        // newPhoto.width = oldPhoto.width;
        // newPhoto.height = oldPhoto.height;

        // // path
        // newPhoto.thumbUrl = oldPhoto.thumbUrl;
        // newPhoto.normalUrl = oldPhoto.normalUrl;
        // newPhoto.highUrl = oldPhoto.highUrl;

        // // store it in the project photos.. WHY????
        // newJSON.photos[newPhoto.id] = newPhoto;

        /*
    "workingImagePath": "/flex/work.php?img=3912016",
      "fullsizeImagePath": "/flex/img.php?img=3912016",
      "temp": false,
      "label": "2013-0...1.28.jpg",
      "suffix": "001/255650/images/3912016.jpg",
      "id": "3912016",
      "width": 2592,
      "height": 1936,
      "lastModified": 1401824246000,
      "originUrl": null,
      "exifRot": 0,
      "originalDate": 1357317088000,
      "edited": false,
      "IsCustomRebrandingPhoto": false,
      "tempID": null,
      "used": true,
      "name": "2013-01-04 11.31.28.jpg",
      "thumbImagePath": "/flex/tn.php?img=3912016",
      "customBkgId": null,
      "albumName": null,
      "folderName": "@@182891@@",
      "isValidVo": true,
      "isLocalContent": false,
      "thumbUrl": "https://editor.tictacphoto.com/flex/tn.php?img=3912016",
      "normalUrl": "https://editor.tictacphoto.com/flex/work.php?img=3912016",
      "highUrl": "https://editor.tictacphoto.com/flex/img.php?img=3912016",
      "ErrorString": null
*/

        // newJSON.photos[newPhoto.id] = newPhoto;
      }

      /*
   "type": "bkg",

   "isPageNumber": false, // should be a type of frame
   "isPostCardBackground": false, // should be a type of frame

   // --- transform
   "y": 357,
   "x": 937.15,
   "width": 1931.0551181399999, // TODO we should reduce to a fixed number here to avoid super long nums in here...
   "height": 770.6929134,
   "rotation": 0,

   // --- edition
   "editable": true, // can be edited (with transform tool)
   "fixed": false, // can or cannot be moved/draggable (but still can be edited)
   "isDeletable": true, // can or cannot be deleted
   "visible": true,
   // -- album

    // -- cal
   "calBold": false,
   "calItalic": false,
   "calFontSizeRaw": null, // ???
   "dateAction": null,
   "dateIndex": 0,
   "calFontName": null,

   // --- Canvas
   "isMultiple": false,

   // -- effects
   "shadow": false,
   "mask": 0,

   // --- border
   "showBorder": true, // ??
   "border": 0,
   "stroke": false,

   // -- fill
   "fillColor": 16777215,
   "fillAlpha": 1,

   // -- text
   "text": "",
   "fontSize": null,
   "tFormat": null,
   "upper": false,
   "alignment": 0, // ????
   "vAlign": "top",
   "hAlign": "center",

   // ---- poparts // TODO should be an object, with poparts params
   "isPopart": false,
   "PopArtLightColor": 10344175,
   "PopArtTreshold": 50,
   "PopArtBlackTreshold": 30,
   "PopArtDarkColor": 173029,

   // -- photo
   "cLeft": 0,
   "cTop": 0,
   "photo": "14280169"
   */

      newPage.frames.push(newFrame);
    });

    /// ///////////////////////////////////////
    /// ///////////////////////////////////////
    // add a frame text on each page
    const textFrame = {
      type: 'text',
      x: RandomInt(0, 300),
      y: RandomInt(0, 300),
      width: RandomInt(200, 500),
      height: RandomInt(200, 500),
      rotation: RandomInt(-20, 20),
      text: {
        size: 25,
        vAlign: 'middle',
        hAlign: 'center',
        value:
          '<P ALIGN="CENTER"><FONT FACE="GillSans" SIZE="40" COLOR="#000000" LETTERSPACING="0" KERNING="0">COUCOU</FONT></P>',
      },
    };
    newPage.frames.push(textFrame);
    /// ///////////////////////////////////////
    /// ///////////////////////////////////////

    newJSON.pageList.push(newPage);
  });

  // display in another window
  if (AndWriteInAnotherWindow) {
    const newWin = window.open('url', 'windowName', 'height=500,width=500');
    newWin.document.write(escapeHtml(JSON.stringify(newJSON)));
  }

  // return
  return newJSON;
}

/**
 * Clean and verify a loaded project.
 * @param {Project} project
 */
export function CleanAndVerifyProject(project, storeRef) {
  const layoutStore = storeRef.layouts;
  const backgroundsByID = backgroundSelectors.getAllBackgroundsByID(storeRef);
  const photosByID = photoListSelector.getAllBackendPhotosByID(storeRef);
  const clipartsByID = clipartSelectors.getAllClipartsByID(storeRef);
  const tempPhotoMapping =
    photoListSelector.getTempPhotoToNewPhotoMapping(storeRef);

  /// ///////////////////////////////////////
  if (!project.type) {
    console.warn(
      `TODO: Project has no type.. please fix: docID:${project.docID}`
    );
  }
  if (!project.docID) {
    return `Project with no DocID cannot be loaded`;
  }

  const doc = GetDoc(project.docID);
  if (!doc) {
    return `Project with DOCID:'${project.docID}' is not recognized by editor.`;
  }

  // photo corrupted message
  let photoCorrupted = null;

  // if( !project.docCode ){
  //  project.docCode = doc.code; // doc code (for backend)
  // }
  /// ///////////////////////////////////////

  /// /////////////////////////////////////////////////////
  // Temporary Fixes
  // issue before 0.0.38 with wrong size for albums
  // if( project.docCode !== doc.code )
  // {
  //  Modal.info({
  //   title: "Fixing album",
  //   content: "This project had wrong references: '"+project.docCode+"' but is in fact '"+doc.code+"'"
  //  })
  //  // alert("DEBUG: Old bug: Fixing project referenced as '"+project.docCode+"' but is in fact '"+doc.code+"'");
  //  UpgradeProjectSize( project, project.docID, layoutStore );
  // }

  // verify project size!
  if (
    project.canvasFormat !== CANVAS_FORMAT.FREE && // do not make checks for free format!
    (project.width !== doc.width * doc.multiplier ||
      project.height !== doc.height * doc.multiplier)
  ) {
    Modal.info({
      title: 'Fixing Project size',
      content: `This project size has been updated from ${pixelToCm(
        project.width
      ).toFixed(1)}x${pixelToCm(project.height).toFixed(1)}cm to ${pixelToCm(
        doc.width * doc.multiplier
      ).toFixed(1)}x${pixelToCm(doc.height * doc.multiplier).toFixed(1)}cm`,
    });
    // alert(`DEBUG: Fix previous album: this album size must be updated from ${project.width}x${project.height} to ${(doc.width*doc.multiplier)}x${(doc.height*doc.multiplier)}`);
    UpgradeProjectSize(
      project,
      doc.width * doc.multiplier,
      doc.height * doc.multiplier,
      layoutStore,
      backgroundsByID
    );
  }

  // verify project cover size, which seems to not be always correct neither... probably older projects issues..
  if (IsAlbumEditor() && !IsClassicCoverProject(project.type)) {
    const coverSize = GetCoverSizeInPixel(project);
    const cover = GetProjectCover(project);
    const currentCoverSize = { width: cover.width, height: cover.height };

    // fixing cover size
    if (
      coverSize.width !== currentCoverSize.width ||
      coverSize.height !== currentCoverSize.height
    ) {
      Modal.info({
        title: 'Fixing album cover',
        content: `This project had wrong cover size: '${JSON.stringify(
          currentCoverSize
        )}' but should be '${JSON.stringify(coverSize)}'`,
      });
      upgradeProjectCover(project, layoutStore);
    }

    const backgroundFrame = cover.frames ? cover.frames[0] : null;
    if (backgroundFrame) {
      const currentBackgroundRect = {
        width: backgroundFrame.width,
        height: backgroundFrame.height,
        x: backgroundFrame.x,
        y: backgroundFrame.y,
      };
      const backgroundRect = getCoverBackgroundRect(project);
      // fixing cover background size!
      if (!isEqual(currentBackgroundRect, backgroundRect)) {
        Modal.info({
          title: 'Fixing album cover background bleed.',
          content: 'This project had wrong bleed for cover',
        });
        upgradeProjectCover(project, layoutStore);
      }
    }
  }

  // be sure to have the correct cut border (was wrong before)
  project.cutBorder = doc.cutBorder; // Even if in the end it's taken from doc, so shoul even not be in the project..

  /// /////////////////////////////////////////////////////////////

  // flyleaf correction
  if (project.flyleaf === undefined) project.flyleaf = 'white';
  if (project.coated === undefined) project.coated = true;
  if (project.pagePaperQuality === undefined)
    project.pagePaperQuality = PAPER_QUALITY.QUALITY_170;
  if (project.cover_matte === undefined) project.cover_matte = false;

  // if album classic, verify we have a classic cover!
  if (IsClassicCoverProject(project.type)) {
    if (!project.pageList[0].coverClassicOptions) {
      project.pageList[0] = CreateClassicCoverPage(project);
    }
  }

  // verify that page list is not wrong (was an issue with previously created project)
  if (project.classname === PROJECT_CLASS.ALBUM) {
    if (project.pageList.length % 2 === 0) {
      alert(
        'DEBUG: Due to a previous bug now fixed, this project has a wrong page amount, we fix this by adding a new page at the end'
      );
      project.pageList.concat(
        CreateProjectPages(project, 1, project.pageList.length, layoutStore)[0]
      );
    }
  }

  // ---- Calendar ----
  if (IsCalendarEditor()) {
    if (!project.calendarColorOptions)
      project.calendarColorOptions = CreateCalendarColorOptions();
  }

  // ---- cards ----
  if (IsCardEditor()) {
    // fix for wrong cardpack!
    if (!Object.keys(doc.codes).includes(project.cardPack)) {
      const oldModelNum = CardHelper.GetNumModelFromPack(project.cardPack);
      const oldRepNum = CardHelper.GetNumRepetitionFromPack(project.cardPack);
      const packs = Object.keys(doc.codes);
      let closestPack = packs[0];
      for (const pack of packs) {
        const m = CardHelper.GetNumModelFromPack(pack);
        const r = CardHelper.GetNumRepetitionFromPack(pack);
        const cm = CardHelper.GetNumModelFromPack(closestPack);
        const cr = CardHelper.GetNumRepetitionFromPack(closestPack);
        if (Math.abs(oldModelNum - m) < Math.abs(oldModelNum - cm))
          closestPack = pack;
        else if (cm === m && Math.abs(oldRepNum - r) < Math.abs(oldRepNum - cr))
          closestPack = pack;
      }

      console.warn(
        `Fixing: wrong cardpack: from:'${project.cardPack}' to:'${closestPack}'`
      );
      project.cardPack = closestPack;
    }
    // fix for wrong amount of pages
    if (
      project.pageList.length !==
      CardHelper.GetTotalNumberOfPages(project.docID, project.cardPack)
    ) {
      const numPages = CardHelper.GetTotalNumberOfPages(
        project.docID,
        project.cardPack
      );
      console.warn(
        `Fixing: wrong card num of pages: from:'${project.pageList.length}' to:'${numPages}'`
      );
      const diff = numPages - project.pageList.length;
      // add
      if (diff > 0)
        project.pageList = project.pageList.concat(
          CreateProjectPages(
            project,
            diff,
            project.pageList.length,
            layoutStore
          )[0]
        );
      // remove
      else project.pageList.splice(project.pageList.length + diff, -diff);
    }
  }

  // recover background lists
  const allBackgroundList = backgroundSelectors.getAllBackgroundList(storeRef);
  const projectValidBackgrounds =
    BackgroundHelper.GetValidInternalBackgroundList(project, allBackgroundList);
  const coverValidBackgrounds = BackgroundHelper.GetValidCoverBackgroundList(
    project,
    allBackgroundList
  );

  /// ////////////////////////////////////////////////////////////////
  // ---- START Page and frame verifications ----
  // verify that all page index are correc
  project.pageList.forEach((page, index) => {
    // check page index
    if (page.index !== index) page.index = index;

    const displayPageIndex = IsAlbumEditor() ? page.index : page.index + 1;

    // for a better rendering system, pages should have uniq ID
    if (!page.id) page.id = GetUID();

    // verify frames
    const keptFrames = [];
    page.frames.forEach((frame, index, frameArr) => {
      let keepFrame = true;
      // check frame has an ID, otherwhise give one
      if (!frame.id) frame.id = GetUID();

      // check rotation
      frame.rotation = Number(frame.rotation);
      if (!frame.rotation || typeof frame.rotation !== 'number')
        frame.rotation = 0;

      // check frame border
      if (!frame.border) {
        frame.border = 0;
      }
      if (!frame.borderColor) {
        frame.borderColor = Colors.WHITE;
      }
      if (!frame.shadow) {
        frame.shadow = null;
      }

      // be sure frame calendars are in calendar layouts
      if (frame.type === FRAME_TYPE.CALENDAR && !page.isCalendar)
        page.isCalendar = true;

      // case an upload was not completed..
      if (frame.photo && !photosByID[frame.photo]) {
        if (!photoCorrupted) photoCorrupted = {};

        photoCorrupted[displayPageIndex] =
          tempPhotoMapping && tempPhotoMapping[`${frame.photo}_name`]
            ? `(${tempPhotoMapping[`${frame.photo}_name`]})`
            : IsDebug
            ? `(${frame.photo})`
            : '';

        frame.photo = null;
      }

      // fix postcard layouts
      if (
        frame.type === FRAME_TYPE.POSTCARD_BG &&
        page.layoutType !== LAYOUT_TYPE.POSTCARD_BG
      )
        page.layoutType = LAYOUT_TYPE.POSTCARD_BG;

      // bug reported for calendar frames having white space at the end
      // december 2020
      // EDIT: If we do this, this could break other projects... User are using line breaks to align vertically
      // if(frame.type === FRAME_TYPE.CALENDAR && frame.text && frame.text.value){
      //  frame.text.value = frame.text.value.trimEnd();
      // }

      // check that frames has default text
      if (
        frame.type === FRAME_TYPE.TEXT ||
        frame.type === FRAME_TYPE.SPINE_NUM ||
        frame.type === FRAME_TYPE.SPINE
      ) {
        CleanAndVerifyFrameText(frame);
      }

      // CLIPART verification
      if (frame.clipart) {
        const clipart = clipartsByID[frame.clipart];
        if (!clipart) {
          console.warn(`No Clipart found with ID: ${frame.clipart}`);
          warnCustomer(
            'Clipart error',
            `There is a clipart error (${frame.clipart}) on page ${page.index}, we removed it, please verify the page`
          );
          keepFrame = false;
        }
      }

      //   if( !frame.text )
      //   AddDefaultTextToFrame( frame );
      //  else
      //   CleanAndVerifyFrameText(frame);
      // }

      // // check that frames has default text
      // if( frame.type === FRAME_TYPE.SPINE_NUM && !frame.text ){
      //  AddDefaultTextToFrame( frame );
      //  frame.text.value = "09"; // TODO: upate this
      //  frame.text.size = 15;
      // }

      // fix for client that did add "none" overlayer to pages
      if (frame.overlayer === 'none') {
        frame.overlayer = null;
        frame.type = FRAME_TYPE.TEXT;
        AddDefaultTextToFrame(frame);
        // frame.text.value="coucou";
        // frame.fillColor = "#ff0000";
        console.warn(
          "Removed old 'none' overlayer frame and replaced by text frame empty"
        );
      }

      // // check that frames has default text
      // if( frame.type === FRAME_TYPE.SPINE && !frame.text ){
      //  AddDefaultTextToFrame( frame );
      //  frame.text.value = "this the spine"; // TODO: upate this
      //  frame.text.size = 15;
      // }

      // verify frame background!
      if (frame.background && !backgroundsByID[frame.background]) {
        console.warn(
          `Frame background is not found, try to find a match:${frame.background}`
        );
        // try to find a background that matches!
        const matchesFound = projectValidBackgrounds.filter((item) =>
          item.id.includes(frame.background)
        );

        // match found!
        if (matchesFound.length > 0) {
          console.log(
            `--> match found from ${frame.background} to ${matchesFound[0].id}`
          );
          InjectBackgroundIntoFrame(frame, matchesFound[0]);
        } else {
          const message = `--> NO match found for background on page ${displayPageIndex} (ID:${frame.background})`;
          alert(message);
          API.sendDevMail(new Error(message));
          // frame.background = null; --> [EDIT] if we do this.. the backgrounds are removed... it's not ok..
        }
      }

      // keep frame
      if (keepFrame) {
        keptFrames.push(frame);
      }
    });
    page.frames = keptFrames;
  });
  // ---- END : Page and frame verifications ----
  /// ////////////////////////////////////////////////////////////////

  if (photoCorrupted) {
    let photoCorruptedError = GetText(
      'popup.projectcheck.image_corrupted.content'
    );
    Object.keys(photoCorrupted).forEach((pageIndex) => {
      photoCorruptedError += `page ${pageIndex} ${photoCorrupted[pageIndex]}, `;
    });

    // warn
    warnCustomer(
      GetText('popup.projectcheck.corrupted.title'),
      photoCorruptedError
    );
  }
}

// TODO: this method should be outside
const warnCustomer = (title: string, content: string) => {
  Modal.warn({
    width: '600px',
    title,
    content: (
      <div style={{ whiteSpace: 'pre-wrap' }}>
        {content.split('\\n').join('\n')}
      </div>
    ),
  });
};

/**
 * Clean project photo list
 * -> verify that all project photos are in the project photo list
 * -> if they are not, find it in the backend list and add it to project photo list
 */
/**
 *
 * @param {Project} project
 * @param {*} projectPhotosByID
 * @param {*} backendPhotosByID
 * @returns
 */
export function CleanProjectPhotoList(
  project,
  projectPhotosByID,
  backendPhotosByID
) {
  if (!projectPhotosByID) projectPhotosByID = {};

  const backendPhotos = cloneDeep(backendPhotosByID);

  // verify that all page index are correc
  project.pageList.forEach((page, index) => {
    // check page index
    if (page.index !== index) page.index = index;

    // verify frames
    page.frames.forEach((frame, index) => {
      if (frame.photo && !projectPhotosByID[frame.photo]) {
        if (backendPhotos[frame.photo])
          projectPhotosByID[frame.photo] = backendPhotos[frame.photo];
        else {
          console.error(
            `Photo with id ${frame.photo} was not found in backend photo list!`
          );
          // TODO: what do we do in this case!
        }
      }
    });
  });

  // now that we checked all possible missing frames, we replace by backend version (to be sure to be up to date!)
  // this might not be mandatory later if this webservice do not change anymore.. but for now let's keep this..
  const newProjectPhotosByID = {};
  Object.keys(projectPhotosByID).forEach((photoID, index) => {
    newProjectPhotosByID[photoID] = backendPhotos[photoID];
  });

  return newProjectPhotosByID;
}

/**
 * check if everything is right before ordering
 * if not, throw a string with the error to debug
 */
export function verifyProjectBeforeOrder(project: Project): string {
  if (!project.id) return 'Project has no ID';

  return null;
}

/**
 * Save current project online
 */
// export function SaveProject()
// {
//  project.build_saved = Infos.buildVersion;

//  // data loading
//  DataLoadingManager.instance.showLoading(false, null, ResourcesManager.getString("loading.project.save"));

//   clearUploader();
//   saveUploader = new MultipartURLLoader();
//   saveUploader.dataFormat = URLLoaderDataFormat.TEXT;
//   saveUploader.addEventListener(Event.COMPLETE, projectSaveComplete);
//   saveUploader.addEventListener(IOErrorEvent.IO_ERROR, projectSaveError);

//   var fileName : String = "0"; // don't know why but was previously like this

//   //Add Variables
//   var docTypeStr:String = (Infos.isCanvas)?String( parseInt(project.docType) + CanvasManager.instance.CanvasModifier ) : project.docType;
//   saveUploader.addVariable("classname", project.classname);
//   saveUploader.addVariable("build", project.build_created);
//   saveUploader.addVariable("product", docTypeStr);
//   if(project.IsCustomRebrandedProduct) saveUploader.addVariable("promoEditor", Infos.REBRANDING_ID);

//   if(project.id) saveUploader.addVariable("id", project.id);
//   else Debug.log("Save new project (no existing id)"); // save project with no ID = create a new project

//   saveUploader.addVariable("name", project.name);
//   saveUploader.addVariable("Filename", fileName);
//   saveUploader.addFile(project.getSaveBytes(), fileName, "uploadfile");

//   //send
//   var url : String = Infos.config.serverNormalUrl + "/" + "flex/project_save.php"
//   Debug.log("  url : "+url);
//   saveUploader.load(url, false);
// }

// !! TODO:: fix below !!!

// package data
// {
//  import manager.CanvasManager;

//  import offline.data.CanvasPriceInfo;

//  /**
//   * Parameters that can be passed to the projectManager createProject function
//   * Those parameters are set in the configuratorTabs
//   */
//  public class ProjectCreationParamsVo
//  {
//   // COMMON
//   public var coated : Boolean; //aka vernish
//   public var matte : Boolean; // not compatible with coated-varnish
//   public var themeId: String; // theme to be applied to project (if there is one)
//   public var pagePaperQuality : String;
//   public var flyleaf : String;

//   // ALBUM
//   public var coverType : String;
//   public var pageCount :number = -1; // amount of pages for this album
//   public var paper : Boolean;
//   public var pageNumber : Boolean; // do we display page numbers?
//   public var classicCoverColor : String;
//   public var classicCoverCorner : String;

//   // CALENDARS
//   public var calendarStartDayIndex:number;
//   public var calendarStartMonthIndex:number;
//   public var calendarYear:number;

//   // CANVAS
//   public var canvasType : String ;  // = canvas type/material (forex, wood, alu, etc..)
//   public var canvasFrameColor : String ;  // = canvas frame color for kadapak (black, silver, gold, blur or red)
//   public var canvasWidth :number;  // = width of canvas for custom/multiple canvas type
//   public var canvasHeight :number;  // = height of canvas for custom/multiple canvas type
//   public var canvasMargin :number; // = margin of canvas for multiple canvas type
//   public var canvasMLayout : String; // = layout for multiple canvas type
//   public var canvasStyle : String ; // = styles_style1, styles_style2, styles_style5
//   public var canvasPriceInfo : CanvasPriceInfo ; // offline calculation price

//   // CARDS
//   public var envelope : String ;

//   /**
//    * SHORTCUT TO CREATE project init params based on session xml
//    */
/**
 *
 * @param {ISession} sess
 * @returns {ProjectCreationParams}
 */
export const GetProjectCreationParamsFromSession = (sess) => {
  // var sess : SessionVo = Infos.session;
  const classname: string = sess.classname ? sess.classname : sess.return_page;

  // if we are not in the correct classname, switch!
  if (classname !== PROJECT_CONST.project_class) {
    // TODO: what to do? REDIRECT!! and be sure to keep session..
    // alert("WE ARE NOT IN THE CORRECT CLASSNAME:" + classname + " but editor is: "+ PROJECT_CONST.project_class);
    // alert("project creation aborded..");

    // SWITCH TO CORRECT EDITOR? TODO:
    // window.location = "/"+classname;
    // return;

    // DO NOTHING IF NOT IN THE CORRECT EDITOR (BUT LOG WARNING)
    console.warn(
      `Cannot create '${classname}' project in '${PROJECT_CONST.project_class}' editor! `
    );
    return null;
  }

  /** @type {ProjectCreationParams} * */
  const params = {
    classname,
    options: {},
  };

  // Common
  params.projectName = `Project ${DateUtils.ToYYYYMMDD()}`;
  const docCode = sess.product_id;
  params.docID = GetDocIDByDocCode(docCode);
  const doc = GetDoc(params.docID);

  // ---- CASE ALBUMS
  if (IsAlbumEditor()) {
    params.type = GetProjectTypeByDocID(
      params.docID,
      sess.cover_name === 'Custom Cover'
    );
    params.numPages = !Number.isNaN(parseFloat(sess.product_page_count))
      ? parseFloat(sess.product_page_count)
      : doc.minPages;

    // options
    params.options.paper = sess.product_inserts === '1';
    params.options.pagePaperQuality = sess.product_pagePaperQuality
      ? sess.product_pagePaperQuality
      : doc.pagePaperQuality[0];

    params.options.flyleaf = sess.product_flyleaf
      ? sess.product_flyleaf
      : 'white';
    params.options.coated = sess.product_coated === '1';
    params.options.matte = sess.product_matte === '1';

    // Cover details
    if (params.type === ALBUM_TYPES.CLASSIC) {
      params.coverName = sess.cover_name && sess.cover_name; // (Mostly this is 'Custom Cover', except on classic it can be 'Leather Black' and the others)
      params.coverCorners = sess.cover_corners && sess.cover_corners; // (defaults to none, can be roundgold, roundsilver, squaregold, squaresilver)
    }
  }

  // ---- CASE CALENDARS
  else if (IsCalendarEditor()) {
    params.type = GetProjectTypeByDocID(params.docID);
    params.numPages = doc.numPages;

    params.options.startDay = sess.weekstart ? parseInt(sess.weekstart, 10) : 1;
    params.options.startMonth = sess.month ? parseInt(sess.month, 10) : 0;
    params.options.startYear = sess.year
      ? parseInt(sess.year, 10)
      : new Date().getFullYear() + 1;
    params.options.coated = sess.product_coated === '1';
    params.options.matte = sess.product_matte === '1';
  }

  // ---- CASE CALENDARS
  else if (IsCardEditor()) {
    params.type = GetProjectTypeByDocID(params.docID);
    const cardPack = CardHelper.GetCardPackByDocCode(docCode); // TODO: change this if we have the dropdown in this view!
    params.numPages = CardHelper.GetTotalNumberOfPages(params.docID, cardPack);

    params.options.cardPack = cardPack;
    params.options.envelope = sess.envelope
      ? sess.envelope
      : doc.envelopes_default;
    params.options.matte = sess.product_matte === '1';
  }

  // ---- CASE CANVAS
  else if (IsCanvasEditor()) {
    // check for canvas modifier system
    params.type = CanvasHelper.GetCanvasTypeByDocCode(docCode);
    params.numPages = 1; // always 1 for canvas
    params.canvasFreeWidth = !Number.isNaN(parseFloat(sess.width))
      ? parseFloat(sess.width)
      : null; // in cm
    params.canvasFreeHeight = !Number.isNaN(parseFloat(sess.height))
      ? parseFloat(sess.height)
      : null; // in cm

    params.options.canvasFormat =
      CanvasHelper.GetCanvasFormatByDocCode(docCode);
    params.options.canvasFrameColor = sess.frame_color
      ? sess.frame_color
      : 'black';

    // params.canvasMargin = (!isNaN(parseFloat(sess.gap)))? parseFloat(sess.gap)  : null; // in cm
    // params.canvasWidth = (!isNaN(parseFloat(sess.width)))? parseFloat(sess.width)  : null; // in cm
    // params.canvasHeight = (!isNaN(parseFloat(sess.height)))? parseFloat(sess.height) : null; // in cm
    // params.canvasMLayout = (!isNaN(parseFloat(sess.rows)))? sess.rows + "x" + sess.cols : null;
    // switch (sess.product_style ) {
    //  case "popart" :
    //   params.canvasStyle = "styles_style5";
    //   break;
    //  case "pelemele" :
    //   params.canvasStyle = "styles_style2";
    //   break;
    //  default :
    //   params.canvasStyle = "styles_style1";
    //   break;
    // }
  }

  return params;
};

//  }
// }
