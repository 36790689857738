import { createSelector, createReducer } from '@reduxjs/toolkit';
import { uniq, isEmpty, cloneDeep } from 'lodash';
import type { IPage, Layout } from '../../types/types';
import { API } from '../../utils/API';
import { alertActions } from '../alert/alert';
import {
  ConvertOldLayoutList,
  LAYOUT_TYPE,
  GetPageLayoutType,
  GetCustomLayoutFromPage,
} from './layoutHelper';
import { editionActions, editionSelectors } from '../edition/edition';
import { GetDoc } from '../../utils/ProductHelper';
import { DebugFlags } from '../../debug/DebugFlags';
import { hasOwn } from '../../utils/ObjectUtils';

/** **********************************
// ACTIONS TYPES
************************************ */

const TAG = 'LAYOUTS';

// LIST
const GETALL_REQUEST = `${TAG}/GETALL_REQUEST`;
const GETALL_SUCCESS = `${TAG}/GETALL_SUCCESS`;
const GETALL_ERROR = `${TAG}/GETALL_ERROR`;
const CLEAR_ALL = `${TAG}/CLEAR_ALL`;

// CUSTOM
const SAVE_CUSTOM = `${TAG}/SAVE_CUSTOM`;
const DELETE_CUSTOM = `${TAG}/DELETE_CUSTOM`;

// SORT
const SORT = `${TAG}/SORT`;

/** **********************************
// REDUCERS
************************************ */

const initialState = {
  // filtered layouts by layout types and num photos (see layoutHelper)
  filtered: {
    // TYPE_COMMON:[], TYPE_CALENDAR:{}, etc...
  },
  // find layouts by ID
  byID: {},
  isLoading: false, // is currently loading list
  error: null, // current error message

  customLayoutList: [], // list of all custom layouts
};

const reducer = createReducer(initialState, {
  // --------------------- GET FROM BACKEND ------------------------

  [GETALL_REQUEST]: (state, action) => {
    state.isLoading = true;
  },
  [GETALL_SUCCESS]: (state, action) => {
    state.byID = action.byID;
    state.filtered = action.filtered;
    state.isLoading = false;
    state.customLayoutList = action.customLayouts;
  },
  [GETALL_ERROR]: (state, action) => {
    state.isLoading = false;
    state.error = action.error;
  },

  [CLEAR_ALL]: (state, action) => {
    state.isLoading = false;
    state.error = null;
    state.customLayoutList = [];
    state.filtered = {};
    state.byID = {};
  },

  // --------------------- CUSTOM LAYOUT ------------------------

  [SAVE_CUSTOM]: (state, action) => {
    // state.isLoading = false;
    state.customLayoutList.push(action.layout);
  },

  [DELETE_CUSTOM]: (state, action) => {
    // state.isLoading = false;
    let itemIndex = -1;
    for (let index = 0; index < state.customLayoutList.length; index++) {
      if (state.customLayoutList[index].id === action.layoutID)
        itemIndex = index;
    }
    if (itemIndex > -1) {
      state.customLayoutList.splice(itemIndex, 1);
    }
  },
});

/** **********************************
// SIMPLE ACTIONS (creator)
************************************ */

/*
export function requestLogin() {
  return { type: LOAD };
}

export function createWidget(widget) {
  return { type: CREATE, widget };
}

export function updateWidget(widget) {
  return { type: UPDATE, widget };
}

export function removeWidget(widget) {
  return { type: REMOVE, widget };
}
*/

function sort(sortFilter) {
  return { type: SORT, sortFilter };
}

/** **********************************
// COMPLEX ASYNC ACTIONS
************************************ */

function getAll(projectClass) {
  return (dispatch) => {
    dispatch(request());
    API.getLayouts(projectClass).then(
      (resultObject) => {
        const allLayouts = ConvertOldLayoutList(resultObject.layoutList);
        const customLayouts =
          resultObject.customLayoutList &&
          !isEmpty(resultObject.customLayoutList)
            ? resultObject.customLayoutList
            : [];
        dispatch(success(allLayouts.byID, allLayouts.filtered, customLayouts));
      },
      (error) => {
        alert(`Layouts error: ${error.toString()}`);
        // TODO: what we do in case of error with layouts?
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: GETALL_REQUEST };
  }
  function success(byID, filtered, customLayouts) {
    return {
      type: GETALL_SUCCESS,
      byID,
      filtered,
      customLayouts,
    };
  }
  function failure(error) {
    return { type: GETALL_ERROR, error };
  }
}

function SaveCurrentPageLayout() {
  return (dispatch, getState) => {
    // get page
    const selectedPage: IPage = getSelectedPage(getState());

    // extract layout from page
    const layout = GetCustomLayoutFromPage(selectedPage);

    // console.log("SaveCurrentPageLayout:" + JSON.stringify(layout));

    // add to custom layout list
    dispatch({ type: SAVE_CUSTOM, layout });

    // DEBUG: Apply to all for direct testing, should be in DEBUG menu
    if (DebugFlags.APPLY_TO_ALL_WHEN_SAVING_CUSTOM_LAYOUT)
      dispatch(editionActions.ApplyLayoutToAllPages(layout.id));

    // save to backend!, no result expected. TODO: confirm correct save?
    API.SaveCustomLayout(getState().layouts.customLayoutList);
  };
}

function DeleteCustomLayout(layoutID) {
  return (dispatch, getState) => {
    dispatch({ type: DELETE_CUSTOM, layoutID });
    // save to backend!, no result expected. TODO: confirm correct save?
    API.SaveCustomLayout(getState().layouts.customLayoutList);
  };
}

function clearLayouts() {
  return { type: CLEAR_ALL };
}

/** **********************************
// SELECTORS
************************************ */

const getLayoutStore = (state) => state.layouts && state.layouts;
const getSelectedPage = (state) => editionSelectors.GetSelectedPage(state);
const getDocID = (state) =>
  state.edition.project && state.edition.project.docID;

const getLayoutsByID = createSelector(
  (state) => state.layouts && state.layouts.byID,
  (state) => state.layouts && state.layouts.customLayoutList,
  (backendLayoutsByID, customLayoutList) => {
    if (!backendLayoutsByID) return {};

    const customLayoutsByID = {};
    if (customLayoutList) {
      for (const lay: Layout of customLayoutList) {
        customLayoutsByID[lay.id] = lay;
      }
    }

    return { ...backendLayoutsByID, ...customLayoutsByID };
  }
);

const getCurrentPageLayoutType = createSelector(
  getSelectedPage,
  getDocID,
  (selectedPage, docID) => {
    if (!docID) return null;

    return GetPageLayoutType(selectedPage, docID);
  }
);

const AllowSavePageLayout = createSelector(
  getSelectedPage,
  (selectedPage: IPage) =>
    selectedPage.layoutType !== LAYOUT_TYPE.CALENDAR &&
    selectedPage.layoutType !== LAYOUT_TYPE.COVER &&
    selectedPage.layoutType !== LAYOUT_TYPE.POSTCARD_BG &&
    selectedPage.layoutType !== LAYOUT_TYPE.PROMO_URL &&
    !selectedPage.coverClassicOptions
);

const getProjectForcedProjectLayouts = createSelector(
  getDocID,
  getLayoutsByID,
  (docID, layoutsByID) => {
    if (!docID) return null;

    // check if we have a forced layout list!
    const doc = GetDoc(docID);
    const forcedLayoutList = uniq(doc.layouts_all);
    const forcedLayoutCoverList: Array<string> = uniq(doc.layouts_cover);

    // if we have a forced list for this document
    if (forcedLayoutList && forcedLayoutList.length > 0) {
      const result = {};
      let newLayout;
      for (const layoutID of forcedLayoutList) {
        newLayout = cloneDeep(layoutsByID[layoutID]);

        // here we force the layouts in the cover list to be of type "cover"
        if (forcedLayoutCoverList && forcedLayoutCoverList.includes(layoutID))
          newLayout.type = LAYOUT_TYPE.COVER;

        // add to filtered result
        if (!result[newLayout.type]) result[newLayout.type] = {};

        // let filterResult = cloneDeep(result[newLayout.type]);
        const filterResult = result[newLayout.type]; // why clonedeep? If using clone

        // add to filtered result
        if (!filterResult.all) filterResult.all = [];
        filterResult.all.push(newLayout.id);

        // add to filtered by photo
        if (!filterResult[newLayout.numPhotos])
          filterResult[newLayout.numPhotos] = [];
        filterResult[newLayout.numPhotos].push(newLayout.id);
      }

      return result;
    }
    return null;
  }
);

const getCurrentPageLayouts = createSelector(
  getCurrentPageLayoutType,
  (state) => state.layouts.filtered,
  getProjectForcedProjectLayouts,
  (state) => state.layouts.customLayoutList,
  (layoutType, filteredLayouts, forcedProjectLayouts, customLayoutList) => {
    let listToUse = forcedProjectLayouts || filteredLayouts;

    if (listToUse && listToUse[layoutType]) {
      // add custom layout types, and clone the list as it's a read only object!
      if (layoutType === LAYOUT_TYPE.DEFAULT && !isEmpty(customLayoutList)) {
        listToUse = cloneDeep(listToUse);
        listToUse[layoutType].custom = customLayoutList.map((item) => item.id);
      }

      return listToUse[layoutType];
    }
    return {};
  }
);

const getLayoutPhotosCategories = createSelector(
  getCurrentPageLayouts,
  (filteredLayouts) => {
    // all : [],
    // "1" : [],
    // "2" : [],
    // }
    if (filteredLayouts && filteredLayouts) {
      const categories: Array = Object.keys(filteredLayouts).map(
        (key, index) => key
      );

      // be sure to add custom at start
      if (hasOwn(filteredLayouts, 'custom')) categories.unshift('custom');

      // be sure to add the all before!
      categories.unshift('all');

      return uniq(categories);
    }
    return [];
  }
);

/** **********************************
// EXPORT PUBLIC ACTIONS
************************************ */

export default reducer;

export const layoutListSelectors = {
  getLayoutsByID,
  getLayoutStore,
  getCurrentPageLayouts,
  getLayoutPhotosCategories,
  AllowSavePageLayout,
};

export const layoutListActions = {
  getAll,
  sort,
  SaveCurrentPageLayout,
  DeleteCustomLayout,
  clearLayouts,
};
