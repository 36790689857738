import { cloneDeep, isEmpty } from 'lodash';
import type {
  Frame,
  IPage,
  Point,
  IDocument,
  Photo,
  BackgroundItem,
  ICalendarFrameOptions,
  IFrameShadow,
  ProjectOptions,
  ClassicCoverOptions,
  ICalendarColorOptions,
} from '../../types/types';
import {
  GetProjectCover,
  HasCustomCover,
  GetClassicCoverOptions,
  CoverToCoverLabelName,
} from '../../utils/coverHelper';
import {
  GetDoc,
  GetProjectCode,
  IsCalendar_Magnet,
} from '../../utils/ProductHelper';
import { DebugFlags } from '../../debug/DebugFlags';
import { GetPagesRepetition } from '../../utils/pageHelper';

// import {Frame} from "../edition/frameHelper"; // TODO: don't know why but this doensnt compile...
import {
  FRAME_TYPE,
  IsFrameEmpty,
  NeedFrameUpload,
  ResetFrameCropValues,
} from '../edition/frameHelper';
import { mmToPoint, pixelToCm } from '../../utils/MeasureUtils';
import { PrintColorHelper } from './printColorsHelper';
import { photoListSelector } from '../photoList/photoList';
import { backgroundSelectors } from '../backgrounds/background';
import { Colors } from '../../data/Colors';
import { clipartSelectors } from '../cliparts/cliparts';
import { rotatePointAround } from '../../utils/MathUtils';
import { GetUID } from '../../utils/UID';
import { authSelectors } from '../auth/authentification';
import { GetCurrentLanguage } from '../../data/LanguageHelper';
import { manifestHelper } from './manifestHelper';
import { orderSelector } from './order';
import { getSpineWidthMM } from '../../utils/spineHelper';
import { GetPrintCutBorderInPixel } from '../../utils/printHelper';
import { GetShadowFrameRect } from '../edition/ShadowHelper';
import { CANVAS_FORMAT, CANVAS_TYPES } from '../../utils/canvas/CanvasHelper';
import { CardHelper } from '../../utils/card/CardHelper';
import { OverlayerHelper } from '../overlayers/overlayerHelper';
import { overlayerSelectors } from '../overlayers/overlayers';
import {
  config,
  IsAlbumEditor,
  IsCalendarEditor,
  IsCanvasEditor,
  IsCardEditor,
  PROJECT_CONST,
} from '../../data/config';
import { CalendarHelper } from '../../utils/calendar/CalendarHelper';
import { ALBUM_TYPES } from '../../data/Constants';
import { DATE_ACTION_ENUM } from '../calendar/CalendarEnums';
import { Project } from '../../data/Project';
import { GetProjectOptions } from '../project/GetProjectOptions';

// --------------------- exports ------------------------

export const OrderHelper = {
  // write order
  writeOrder,

  // urls
  generateCheckJobUrl,
  generateOrderFinalURL,
  WriteOrderUrlHeader,

  // debug
  getDemoOrderString,
};

/**
 * Type of order we can make
 */
export const ORDER_SUFFIX = {
  ALBUM: '-album.xml',
  COVER: '-cover.xml',
};

/// --------------------- Store Helpers ------------------------
// here, once the order starts, we keep a reference to the readonly Store, otherwise too complex to inject in each function..
let StoreRef;

/**
 *
 * @returns {Projec}
 */
function getProject() {
  return StoreRef.edition.project;
}
/**
 *
 * @returns {Photo}
 */
function getPhoto(photoID) {
  return photoListSelector.getAllPhotosByID(StoreRef)[photoID];
}
/**
 *
 * @returns {BackgroundItem}
 */
function getBackground(backgroundID) {
  return backgroundSelectors.getAllBackgroundsByID(StoreRef)[backgroundID];
}
/**
 *
 * @returns {Clipart}
 */
function getClipart(clipartID) {
  return clipartSelectors.getAllClipartsByID(StoreRef)[clipartID];
}
/**
 *
 * @returns {OverlayerItem}
 */
function getOverlayer(overlayerID) {
  return overlayerSelectors.getAllOverlayersByID(StoreRef)[overlayerID];
}

function getJobID(): string {
  return orderSelector.getJobIDSelector(StoreRef);
}

// --------------------- Methods ------------------------

/**
 * start by writing order
 */
function writeOrder(
  storeRef,
  orderSuffix: string = ORDER_SUFFIX.ALBUM
): string {
  StoreRef = storeRef;

  // get document
  const { project } = storeRef.edition;
  const doc: IDocument = GetDoc(project.docID);

  let orderStr = '*photoforlife*\n';
  orderStr += 'mode=print\n';
  orderStr += 'document=begin';
  orderStr += `&build=${PROJECT_CONST.build_start}`; // project.build_created;
  orderStr += `&projectname=${encodeURI(project.name)}`;
  orderStr += `&projectid=${project.id}`;
  orderStr += `&name=${project.docID}`;
  orderStr += `&type=${GetProjectCode(project)}`;
  orderStr += `&class=${project.type}`; // project.type;
  orderStr += '&modifier=0'; // _projectXMLClass.DocModifier.toString(); // TODO : ask what this is!?

  // order
  if (orderSuffix === ORDER_SUFFIX.COVER)
    orderStr += `&measure=${doc.cover.multiplier}`;
  else orderStr += `&measure=${doc.multiplier}`;

  let orderImpose = 'none';
  if (IsCardEditor()) {
    // orderImpose is a flag indicating the webservice the way he must handle the pages.
    // It's always the same but for cards with 4 pages per unit
    if (doc.pages_per_group === 4) {
      orderImpose = CardHelper.GetCardFoldOrientation(project.docID);
    }
  }
  orderStr += `&impose=${orderImpose}`;

  // WriteDocumentOrderHeader contains the \n character
  orderStr += WriteOrderHeader(project, orderSuffix);

  // write pages
  let startIndex = 0;
  let endIndex: number = project.pageList.length;

  // ALBUM only
  if (IsAlbumEditor()) {
    if (orderSuffix === ORDER_SUFFIX.ALBUM) {
      startIndex =
        DebugFlags.ORDER_TO_PDF &&
        HasCustomCover(project) &&
        !DebugFlags.ORDER_NO_COVER
          ? 0
          : 1; // if we print to pdf in debug, we want to see the cover in the pdf, otherwise it's sent in order with suffix ORDER_SUFFIX.COVER
      endIndex = project.pageList.length;
    } else if (orderSuffix === ORDER_SUFFIX.COVER && HasCustomCover(project)) {
      startIndex = 0;
      endIndex = 1;
    }
  }

  // write content pages
  let printPageIndex: number = startIndex; // current page index is just the final pageindex, with repetition taken into account (it's for now used only for cards)
  const pagesRepetition = GetPagesRepetition(project);
  for (let r = 0; r < pagesRepetition; r++) {
    //  cards with 4 pages exception
    if (IsCardEditor() && doc.pages_per_group === 4) {
      for (let i: number = startIndex; i < endIndex; i += 4) {
        // portrait
        if (CardHelper.GetCardFoldOrientation(project.docID) === 'portrait') {
          orderStr += writeAPage(project, i, printPageIndex, '1n');
          orderStr += writeAPage(project, i + 3, printPageIndex + 1, '2n');
          orderStr += writeAPage(project, i + 2, printPageIndex + 2, '1n');
          orderStr += writeAPage(project, i + 1, printPageIndex + 3, '2n');
        }
        // landscape
        else {
          orderStr += writeAPage(project, i, printPageIndex, '1u');
          orderStr += writeAPage(project, i + 3, printPageIndex + 1, '2n');
          orderStr += writeAPage(project, i + 1, printPageIndex + 2, '1n');
          orderStr += writeAPage(project, i + 2, printPageIndex + 3, '2n');
        }

        printPageIndex += 4;
      }
    }

    // normal way of handling pages
    else {
      for (let i = startIndex; i < endIndex; i++) {
        // check if we need to print the page
        if (CheckPageMustBePrinted(printPageIndex)) {
          orderStr += writeAPage(project, i, printPageIndex);
        }
        printPageIndex++;
      }
    }

    // reset startindex for repetition amount, if we later need pages repetition for album, need to be changed according to start index/ cover stuff
    startIndex = 0;
  }

  // // write print manifest page at end (added at the end of project) (For albums)
  const manifeststr: string = manifestHelper.writeOrderEndingManifestPage(
    project,
    printPageIndex,
    orderSuffix
  );
  if (manifeststr) {
    orderStr += manifeststr;
  }

  // end
  orderStr += 'document=end\n';
  orderStr += '*/photoforlife*\n';
  return orderStr;
}

/**
 * write a page
 * > orderPageIndex takes the page repetition into account. It's the final print page index
 */
/**
 *
 * @param {Project} project
 * @param {*} pageIndex
 * @param {*} orderPageIndex
 * @param {*} imposeCode
 * @returns
 */
function writeAPage(
  project,
  pageIndex: number,
  orderPageIndex: number,
  imposeCode = '2n'
): string {
  /** @type {Ipage} * */
  const page = cloneDeep(project.pageList[pageIndex]); // clone so we can modify frame for the print process.
  const pageNr: number = page.index;
  let pageStr = 'page=begin';

  pageStr += `&number=${pageNr}`;

  // keep track of layout id if it exists
  pageStr += `&layout=${page.layoutID}`;

  // // TODO:
  // // adjust crop x // TODO : ask what this is..
  if (page.isCover) {
    pageStr += '&adjcropx=0';
    pageStr += '&adjcropy=0';
  }
  // else{
  //     pageStr += "&adjcropx="+ Project.adjustCropX;
  //     pageStr += "&adjcropy="+ Project.adjustCropY;
  // }

  // cut border
  // pageStr += "&cutborder=0" ; // will display the cut border in the print document // 113.3858268" ;
  const border: number = GetPrintCutBorderInPixel(project, page.isCover); // , Project.coverCutBorder * MeasureManager.mmToPoint : Project.docCutBorder * MeasureManager.mmToPoint; // TODO : here we should maybe change something related to 96dpi instead of 72dpi
  pageStr += `&cutborder=${border.toString()}`;

  const pageWidth: number = page.width;
  const pageHeight: number = page.height;
  pageStr += `&width=${pageWidth}`;
  pageStr += `&height=${pageHeight}`;

  // imposecode =
  // 1n for top (or right) normal,
  // 1u for top upside down,
  // 2n for bottom (or left) normal (default)
  pageStr += `&impose=${imposeCode}`;
  pageStr += '\n';

  /** **********************************************************
    /  Write manifest info (print infos) before frames if needed
    ************************************************************ */
  let manifeststr: string = manifestHelper.writeAManifestPrePageContent(
    project,
    page
  );
  if (manifeststr) {
    pageStr += manifeststr;
  }
  /** ***************************** */

  // add frames
  let nextFrame: Frame;
  let tempFrame: Frame;
  let frameIndex: number;
  for (let i = 0; i < page.frames.length; i++) {
    frameIndex = i;
    nextFrame = page.frames[i];

    // write frame as usual (photo, fill, postcard background, etc..)
    try {
      // shadow
      if (nextFrame.photo && nextFrame.shadow && nextFrame.shadow.enabled)
        pageStr += writeAFrameShadow(nextFrame);

      // border
      // if( nextFrame.photo && nextFrame.border !==0 && !nextFrame.borderRadius) // write border frame only if the photo is there
      if (nextFrame.photo && nextFrame.border !== 0) {
        // write border frame only if the photo is there
        pageStr += writeAFrameBorder(nextFrame);
      }

      // case overlayer, we keep overlayer info first and reprint it after
      let overlayerFrame = null;
      if (nextFrame.overlayer) {
        overlayerFrame = { ...nextFrame };
        ResetFrameCropValues(overlayerFrame);
        const overlayerRect = OverlayerHelper.GetOverLayerOverRect(
          getOverlayer(nextFrame.overlayer),
          nextFrame
        );
        overlayerFrame.photo = null;
        // overlayerFrame.x += (-nextFrame.width/2 + overlayerRect.x + overlayerRect.width /2 );
        // overlayerFrame.y += (-nextFrame.height/2 + overlayerRect.y + overlayerRect.height /2 );
        overlayerFrame.x +=
          overlayerRect.x + overlayerRect.width / 2 - nextFrame.width / 2;
        overlayerFrame.y +=
          overlayerRect.y + overlayerRect.height / 2 - nextFrame.height / 2;
        overlayerFrame.width = overlayerRect.width;
        overlayerFrame.height = overlayerRect.height;

        // Note: if the frame is rotated, the frame offset will be wrong as the frame is rendered in a svg group but printed as separated frame.
        if (nextFrame.rotation !== 0) {
          const centerFrame = { x: nextFrame.x, y: nextFrame.y };
          const centerOverlay = { x: overlayerFrame.x, y: overlayerFrame.y };
          const finalCenterPoint = rotatePointAround(
            centerOverlay.x,
            centerOverlay.y,
            centerFrame.x,
            centerFrame.y,
            nextFrame.rotation
          );
          overlayerFrame.x = finalCenterPoint.x;
          overlayerFrame.y = finalCenterPoint.y;
        }

        // print next frame as simple image
        nextFrame.overlayer = null;
        nextFrame.type = FRAME_TYPE.PHOTO;
      }

      // case frame is mask, we upload the full frame!
      // TODO: we should handle a generic way to write frames that were "uploaded"!! (border, shadows, calendars, masks...)
      // if(nextFrame.photo && nextFrame.borderRadius){
      //     pageStr += writeAFrameMask(nextFrame, pageIndex, i);
      // }
      // normal frame
      // else

      // ---- CALENDAR SUB FRAMES ----
      if (nextFrame.type === FRAME_TYPE.CALENDAR) {
        // fill color
        if (nextFrame.fillColor) {
          pageStr += writeAFrameColor(nextFrame);
          nextFrame.fillColor = null;
        }
        // photo
        if (nextFrame.photo) {
          pageStr += writeAFrame(
            CalendarHelper.getCalendarSubFramePhoto(nextFrame),
            pageIndex,
            i
          );
          nextFrame.photo = null;
        }
        // text
        if (nextFrame.text) {
          pageStr += writeAFrame(
            CalendarHelper.getCalendarSubFrameText(nextFrame),
            pageIndex,
            i
          );
          nextFrame.text = null;
        }
      }

      // write frame normally
      pageStr += writeAFrame(nextFrame, pageIndex, i);

      // overlaye frame over..
      if (overlayerFrame) pageStr += writeAFrame(overlayerFrame, pageIndex, i);
    } catch (e) {
      // TODO: localize!
      throw new Error(
        `There is an error on page ${page.index} at frame ${i}: ${
          e.message
        } --> Frame: ${JSON.stringify(nextFrame)}`
      );
    }

    // // TODO:
    // // 9.23.04 fix
    // // Special case (Hack) where the frame is a postcartd background, but also have a fill color.
    // // as this is managed as one frame only, it was just added as a simple fill, so we write again as a postcard background but without the fill
    // if( nextFrame.isPostCardBackground && IsPrintableBackgroundColor(nextFrame,false) )
    // {
    //     var keepColor:number = nextFrame.fillColor; // save frame color
    //     nextFrame.fillColor = -1; // set it to null so it is not handle as a fill color
    //     pageStr += writeAFrame(nextFrame); // write the frame again (now the real postcard background frame)
    //     nextFrame.fillColor = keepColor; // put back the fill color in the frame
    // }
  }

  /** **********************************************************
    /  Write manifest info (print infos) after frames if needed
    ************************************************************ */
  manifeststr = manifestHelper.writeAManifestPostPageContent(
    project,
    page,
    getJobID()
  );
  if (manifeststr) {
    pageStr += manifeststr;
  }
  /** ***************************** */

  /** **********************************************************
    /  write page number on pdf
    ************************************************************ */
  if (project.pageList.length > 1 && !page.isCover) {
    const dcborder: number = GetPrintCutBorderInPixel(project); // Project.docCutBorder * MeasureManager.mmToPoint;
    let toff: number;
    pageStr += 'frame=begin';

    // TODO:
    // // ---- TOP ----
    // // (page id must be on top for casual 24x24 and trendy 25x25) // TODO put this on projects.xml to avoid hardcoded values
    // if( Project.docPrefix === ProductsCatalogue.PREFIX_ALBUM_TRENDY_25 // trendy 25
    //     || Project.docPrefix === ProductsCatalogue.PREFIX_ALBUM_CASUAL_24 ) // casual 24
    // {
    //     toff =  - dcborder + (3.5 * MeasureManager.mmToPoint); // 3.5 for 3.5 mm from the border of the page
    //     pageStr += "&orientate=north";
    //     pageStr += "&top="+toff.toFixed(6);
    //     pageStr += "&left="+(pageWidth / 2 - 10*MeasureManager.mmToPoint).toFixed(6); // 10 for 1cm (center of the text approx)
    // }

    // // ---- RIGHT ----
    // else if(imposeCode === "1u") {
    //     toff = (dcborder / 5) - dcborder;
    //     pageStr += "&orientate=west";
    //     // 13 is ascent of Helvetica 10 pt.
    //     pageStr += "&left="+(pageWidth - toff - 13).toFixed(6);
    //     pageStr += "&top="+(pageHeight / 2).toFixed(6);
    // }

    // // ---- Regular A4 landscape ----
    // else if (Project.docPrefix === ProductsCatalogue.PREFIX_ALBUM_REGULAR_LANDSCAPE_A4)
    // {
    //     toff = 0 - (18.0 + (0.5 * MeasureManager.mmToPoint)); // add 3mm from default (see below)
    //     pageStr += "&orientate=east";
    //     pageStr += "&left="+toff.toFixed(6);
    //     pageStr += "&top="+(pageHeight / 2).toFixed(6);
    // }

    // // ---- LEFT (DEFAULT) ----
    // else {
    //     toff = 0 - (18.0 + (3.5 * MeasureManager.mmToPoint));
    //     pageStr += "&orientate=east";
    //     pageStr += "&left="+toff.toFixed(6);
    //     pageStr += "&top="+(pageHeight / 2).toFixed(6);
    // }
    toff = 0 - (18.0 + mmToPoint(3.5));
    pageStr += '&orientate=east';
    pageStr += `&left=${toff.toFixed(6)}`;
    pageStr += `&top=${(pageHeight / 2).toFixed(6)}`;

    pageStr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
    pageStr += '&justify=left';
    pageStr += '&fontname=Helvetica';
    pageStr += '&fontsize=18.0';
    pageStr += '&framedir=none';

    // as the cover is counted in the indexes, content pages start at index 1 for albums but index 0 for other types
    if (IsAlbumEditor()) pageStr += `&text={orderid} - ${page.index}`;
    else pageStr += `&text={orderid} - ${orderPageIndex + 1}`;

    pageStr += '\n';
    pageStr += 'frame=end\n';
  }
  /** ***************************** */

  // end of page
  pageStr += `page=end&number=${pageNr}\n`;

  return pageStr;
}

/**
 * write a frame
 */
function writeAFrame(
  frame: Frame,
  pageIndex,
  frameIndex,
  isBorder = false,
  isShadow = false,
  isPostCardBack = false,
  isCalendarFrameBackground = false
): string {
  // empty frame that are not shadows or borders should not be printed
  if (
    IsFrameEmpty(frame) &&
    !isShadow &&
    !isBorder &&
    frame.type !== FRAME_TYPE.OVERLAYER
  )
    return '';

  // if( frame.type === Frame.TYPE_BKG
  //     && !frame.photo
  //     && !frame.background
  //     && !frame.isPostCardBackground
  //     && !(IsPrintableBackgroundColor(frame, isCalendarFrameBackground))) return ""; // if frame is a background, has no photo and no color, and no postcard background, return

  // WRITE Text without png (from debugflags)
  if (DebugFlags.PRINT_TEXT_WITHOUT_PNG && frame.type === FRAME_TYPE.TEXT)
    return writeFrameText(frame);

  // // TODO:
  // //remove spine and edition from casual
  // if(Project.type === ProductsCatalogue.ALBUM_CASUAL && (frame.grouptype === Frame.GROUP_TYPE_SPINE || frame.grouptype === Frame.GROUP_TYPE_EDITION)) return ""; // do not print spine or edition for casuals!!!

  // ---- CALENDAR FRAMES ----
  if (frame.type === FRAME_TYPE.CALENDAR) {
    // TODO::
    // if( !frame.visible) return ""; // calendar frames that are not visible should not be printed

    // calendar frame that are not uploads should be rendered using server technology
    if (!NeedFrameUpload(frame)) {
      // TODO:
      // // check if calendar frame has background
      // if( !frame.uniqFrameLinkageID // if frame has a linkage, nor linked photo frame nor linked text frame will handle background
      //     && frame.dateAction === CalendarManager.DATEACTION_DAYDATE )
      // {
      //     // if frame day must be rendered by sever but has a background color, we still need to generate it
      //     var colors : CalendarColors = new CalendarColors();
      //     var page : page = PagesManager.instance.getpageByFrame(frame);
      //     if(page) colors = page.customColors;
      //     if(colors.dayBackground !==-1) return writeCalendarFrameWithBackground( frame, colors.dayBackground );
      // }

      // if calendar frame has a photo, we add it first
      // if(frame.photo)
      // {
      //     let calendarFrameWithPhoto:Frame = cloneDeep(frame);
      //     calendarFrameWithPhoto.type = FRAME_TYPE.PHOTO;
      //     return writeAFrame( calendarFrameWithPhoto, pageIndex, frameIndex )
      //         + writeAFrameCalendar( pageIndex, frame );

      // }

      return writeAFrameCalendar(pageIndex, frame); // calendar frame that are not uploads should be rendered using server technology
    }
  }

  // flag to indicate if this frame is a desktop photo upload
  // var isDesktopPhoto : Boolean = (Infos.IS_DESKTOP && frame.photo && !(frame.photo is BackgroundVo) && (frame.type === Frame.TYPE_PHOTO || frame.type === Frame.TYPE_BKG));//&& !frame.isPopart

  // the frame offset is the difference between original frame and final rendering of frame (used for border)
  const borderOffset: number = mmToPoint(frame.border);

  // frame width
  // TODO:
  // var framePrintMargin :number = (frame.type === Frame.TYPE_TEXT )? FrameExporter.TEXT_FRAME_SECURITY_MARGIN : 1; // print margin is done for frame text upload, add some security margin to avoid text to be cut
  const framePrintMargin = 1;
  const frameWidth: number = frame.width * framePrintMargin - borderOffset * 2; // TODO : if tomorrow 1pixel is not the same as 1 point, we need to change the values here with the pixelToPoint function
  const frameHeight: number =
    frame.height * framePrintMargin - borderOffset * 2;

  // real width and height of image
  let rWidth: number;
  let rHeight: number;

  const photo: Photo = getPhoto(frame.photo);
  const background: BackgroundItem = getBackground(frame.background);

  if (photo || background) {
    // if(isDesktopPhoto){ // for desktop images, we do not use fullsize image, but the image already cropped.
    //     var fullsizeCropArea : Rectangle = Frame.GetRealSizeCropRect( frame );
    //     rWidth = fullsizeCropArea.width * frame.zoom;
    //     rHeight = fullsizeCropArea.height * frame.zoom;
    // }
    // else
    // {
    //     rWidth  = photo.width * frame.zoom;
    //     rHeight  = photo.height * frame.zoom;
    // }
    if (background) {
      rWidth = background.width * frame.zoom;
      rHeight = background.height * frame.zoom;
    } else if (photo) {
      rWidth = photo.width * frame.zoom;
      rHeight = photo.height * frame.zoom;
    }
  } else {
    rWidth = frameWidth;
    rHeight = frameHeight;
  }

  // FIX / SECURITY
  // if(rWidth < frameWidth) rWidth = frameWidth;
  // if(rHeight < frameHeight) rHeight = frameHeight;
  // frame positionning
  const printCoords: Point = getPrintCoords(frame);
  const fLeft: number = printCoords.x;
  const fTop: number = printCoords.y;

  // frame rotation
  const fRotation: number = frame.rotation;

  // crop
  const cLeft: number = frame.cLeft + borderOffset;
  const cTop: number = frame.cTop + borderOffset;

  // FIX / SECURITY (WHY???)
  // if(cTop < 0) cTop = 0;
  // if(cLeft < 0) cLeft = 0;

  // start frame
  let frameStr = 'frame=begin';

  // write frame transform
  frameStr += writeFrameTransform(
    fLeft,
    fTop,
    frameWidth,
    frameHeight,
    cLeft,
    cTop,
    rWidth,
    rHeight,
    fRotation
  );

  // write frame fill ( will be deprecated with new background fill system using color png )
  frameStr += getFrameFill(frame);

  // handle mask
  // if( !isShadow && frame.mask && frame.mask !==Frame.MASK_TYPE_NONE ){
  //     // ellipse
  //     if(frame.mask === Frame.MASK_TYPE_CIRCLE) frameStr += "&clip=ellipse" ;
  //     // corner radius
  //     else if(frame.mask === Frame.MASK_TYPE_CORNER_RADIUS) frameStr += "&clip=rounded" ; // &clipradius=<float/integer>
  //     // else there is something wrong
  //     else console.warn("OrderManager.writeAFrame : frame.mask is : "+frame.mask);
  // }

  if (frame.borderRadius) {
    if (
      frame.borderRadius > frame.height / 2 ||
      frame.borderRadius > frame.width / 2
    )
      frameStr += '&clip=ellipse';
    // &clipradius=<float/integer>
    else {
      frameStr += '&clip=rounded'; // &clipradius=<float/integer>
      frameStr += `&clipradius=${frame.borderRadius}`; // &clipradius=<float/integer>
    }
  }
  //     // ellipse
  //     if(frame.mask === Frame.MASK_TYPE_CIRCLE) frameStr += "&clip=ellipse" ;
  //     // corner radius
  //     else if(frame.mask === Frame.MASK_TYPE_CORNER_RADIUS) frameStr += "&clip=rounded" ; // &clipradius=<float/integer>
  //     // else there is something wrong
  //     else console.warn("OrderManager.writeAFrame : frame.mask is : "+frame.mask);
  // }

  // stroke
  /*
    if(frame.stroke && (frame.rotation === 0)) {
        frameStr += "&stroke=true";
        frameStr += getCMYKColorStringFromRGB("strokecolor", 0xff0000);// TODO : here implement correct stroke color
    }
    */
  /*
    if(frame.type === Frame.TYPE_CALENDAR && frame.text !==""){
        //frameStr += PrintColorHelper.getCMYKColorString("textcolor", "#000000");
        //frameStr += "&fontname=Helvetica";
        //frameStr += "&fontsize=8.0";
        frameStr += "&framedir=none";
        frameStr += "&text=this is a test";
        frameStr += "&stroke=true";
        frameStr += getCMYKColorStringFromRGB("strokecolor", 0xff0000);// TODO : here implement correct stroke color
        frameStr += "&urldecode=true";

        var t:TextField = new TextField();
        t = new TextField();
        t.width = frame.width / frame.zoom;
        t.height = frame.height / frame.zoom;
        t.wordWrap = true;
        t.embedFonts = true;
        t.multiline = true;
        t.antiAliasType = AntiAliasType.ADVANCED;
        t.text = frame.text;
        t.setTextFormat(frame.textFormat);

        var encoded:String = encodeURIComponent(t.htmlText);
        frameStr += "&text="+encoded;
        /*if((encoded.indexOf("%0D") > 0) || (encoded.indexOf("%0A") > 0)) {
            frameStr += "&textflow=true";
        }* /
        frameStr += "&textflow=true";
    }
    */

  // north is by default, do not add this if not needed
  frameStr += '&orientate=north'; //  TODO : later if we change orientation possibilities inside frame

  // FIt method : https://www.uni-regensburg.de/rechenzentrum/medien/cms-support/pdflib-manual-5_02.pdf
  // auto, nofit, clip, meet, slice, and entire
  if (
    isShadow ||
    frame.type === FRAME_TYPE.CLIPART
    // || frame.type === FRAME_TYPE.OVERLAYER
  )
    frameStr += '&fitmethod=entire';
  else if (frame.type === FRAME_TYPE.OVERLAYER) frameStr += '&fitmethod=entire';
  else frameStr += '&fitmethod=meet';

  if (DebugFlags.PRINT_ALL_FRAMES_WITH_STROKE) {
    frameStr += '&stroke=true';
    frameStr += PrintColorHelper.getCMYKColorString('strokecolor', '#ff0000');
  }

  // // TODO:
  // // ---- ADD A FRAME COLOR (border, background, color fill frame) ----
  // if( isBorder  // case border
  //     || IsPrintableBackgroundColor( frame, isCalendarFrameBackground ) // case it's a background color
  //     || frame.type === Frame.TYPE_COLOR_FILL )
  // {
  //     if(frameExporter.ColorsToUpload[""+frame.fillColor] === null)
  //         Debug.warn("FRAME COLOR_"+frame.fillColor+" @ p"+pageIndex+" - f"+frameIndex + " was not uploaded...");
  //     frameStr += "&framedir=upload&filelocation="+getOrderFileLocation(frame, pageIndex,frameIndex,true);
  // }

  // // ---- ADD A SHADOW -----
  // else if( isShadow ) frameStr += "&framedir=upload&filelocation=" + getOrderFileLocation(frame, pageIndex,frameIndex,false,true);

  // // ---- POSTCARD BACK -----
  // else if( frame.isPostCardBackground ) frameStr += "&framedir=upload&filelocation=" + getOrderFileLocation(frame, pageIndex,frameIndex,false,false,true);

  // // ---- ADD FRAME FILE ----
  // else {
  //     frameStr += "&framedir=" + getImageDirectory(frame); //001/10000/images/102499.jpg" ;
  //     frameStr += "&filelocation=" + getOrderFileLocation(frame,pageIndex,frameIndex);  //iStock_000000564419Large.jpg\n";
  // }
  frameStr += `&framedir=${getImageDirectory(frame)}`; // 001/10000/images/102499.jpg" ;
  // frameStr += "&filelocation=" + getOrderFileLocation(frame,pageIndex,frameIndex);  //iStock_000000564419Large.jpg\n";
  frameStr += `&filelocation=${getOrderFileLocation(
    frame,
    pageIndex,
    frameIndex
  )}`; // iStock_000000564419Large.jpg\n";

  //------------------------------------------------------------------------------
  // demo frame
  // TODO : comment this line, it's for testing purpose
  // frameStr = "frame=begin&left=-56.625000&top=-56.625000&width=1247.250000&height=1247.250000&rleft=0.000000&rtop=-311.812500&rwidth=1247.250000&rheight=1871.543408&cleft=0.000000&ctop=0.000000&cright=0.000000&cbottom=0.000000&rotation=10&bleft=0.000000&btop=0.000000&bright=0.000000&bbottom=0.000000&fillalpha=0&fillcolorspace=cmyk&fillcolor=0.000000,0.000000,0.000000,0.000000&orientate=north&fitmethod=meet&framedir="+ photo.suffix+"&filelocation="+ photo.name;
  //------------------------------------------------------------------------------

  // end of frame
  frameStr += '\n';
  frameStr += 'frame=end\n';

  return frameStr;
}

//------------------------------
// Write a frame transform
//------------------------------
function writeFrameTransform(
  left: number,
  top: number,
  width: number,
  height: number,
  cropX: number,
  cropY: number,
  contentW: number,
  contentH: number,
  rotation: number
): string {
  let frameStr = '';
  frameStr += `&left=${left.toFixed(6)}`; // -56.625000" ;
  frameStr += `&top=${top.toFixed(6)}`; // -56.625000" ;
  frameStr += `&width=${width.toFixed(6)}`; // 1247.250000" ;  // Looks like it's the current frame width of image in points
  frameStr += `&height=${height.toFixed(6)}`; // 1247.250000" ; // Looks like it's the current frame height of image in points
  frameStr += `&rleft=${(-cropX).toFixed(6)}`; // ;0.000000" ;//
  frameStr += `&rtop=${(-cropY).toFixed(6)}`; // 0.000000" ;//-311.812500" ;
  frameStr += `&rwidth=${contentW.toFixed(6)}`; // 1247.250000" ;  // Looks like it's the real width of image in points
  frameStr += `&rheight=${contentH.toFixed(6)}`; // 1871.543408" ;  // Looks like it's the real height of image in points

  frameStr += `&cleft=${0}`; // cLeft; // 0.000000" //;
  frameStr += `&ctop=${0}`; // 0.000000" ;
  frameStr += '&cright=0'; // + cRight; //0.000000" ;
  frameStr += '&cbottom=0'; // + cBott//0.000000" ;
  frameStr += `&rotation=${rotation}`;

  // seems that no default values, all of this must be set once image is a "upload" type
  frameStr += '&bleft=0.000000';
  frameStr += '&btop=0.000000';
  frameStr += '&bright=0.000000';
  frameStr += '&bbottom=0.000000';

  return frameStr;
}

/**
 * write a border
 */
function writeAFrameBorder(frame: Frame): string {
  const frameWidth: number = frame.width;
  const frameHeight: number = frame.height;
  const printCoords: Point = getPrintCoords(frame, true);
  const fLeft: number = printCoords.x;
  const fTop: number = printCoords.y;

  // frame rotation
  const fRotation: number = frame.rotation;

  let frameStr = 'frame=begin';
  frameStr += writeFrameTransform(
    fLeft,
    fTop,
    frameWidth,
    frameHeight,
    0,
    0,
    frameWidth,
    frameHeight,
    fRotation
  );

  // north is by default, do not add this if not needed
  frameStr += '&orientate=north'; //  TODO: later if we change orientation possibilities inside frame

  if (frame.borderRadius) {
    if (
      frame.borderRadius > frame.height / 2 ||
      frame.borderRadius > frame.width / 2
    )
      frameStr += '&clip=ellipse';
    // &clipradius=<float/integer>
    else {
      frameStr += '&clip=rounded'; // &clipradius=<float/integer>
      frameStr += `&clipradius=${frame.borderRadius + mmToPoint(frame.border)}`; // &clipradius=<float/integer>
    }
  }

  frameStr += '&fitmethod=meet';
  // frameStr += "&fitmethod=entire" ;

  frameStr += `&framedir=upload&filelocation=${getOrderFileLocation(
    null,
    0,
    0,
    frame.borderColor
  )}`;

  // end of frame
  frameStr += '\n';
  frameStr += 'frame=end\n';

  return frameStr;
}

function writeAFrameMask(frame: Frame, pageIndex, frameIndex): string {
  const frameWidth: number = frame.width;
  const frameHeight: number = frame.height;
  const printCoords: Point = getPrintCoords(frame, true);
  const fLeft: number = printCoords.x;
  const fTop: number = printCoords.y;

  // frame rotation
  const fRotation: number = frame.rotation;
  let frameStr = 'frame=begin';
  frameStr += writeFrameTransform(
    fLeft,
    fTop,
    frameWidth,
    frameHeight,
    0,
    0,
    frameWidth,
    frameHeight,
    fRotation
  );

  // north is by default, do not add this if not needed
  frameStr += '&orientate=north'; //  TODO: later if we change orientation possibilities inside frame
  frameStr += '&fitmethod=meet';
  // frameStr += "&fitmethod=entire" ;
  frameStr += `&framedir=upload&filelocation=${getOrderFileLocation(
    frame,
    pageIndex,
    frameIndex
  )}`;

  // end of frame
  frameStr += '\n';
  frameStr += 'frame=end\n';

  return frameStr;
}

function writeAFrameColor(frame: Frame): string {
  // frame rotation
  let frameStr = 'frame=begin';
  const printCoords: Point = getPrintCoords(frame, true);
  frameStr += writeFrameTransform(
    printCoords.x,
    printCoords.y,
    frame.width,
    frame.height,
    0,
    0,
    frame.width,
    frame.height,
    frame.rotation
  );
  // frameStr += "&orientate=north" ; // north is by default, do not add this if not needed
  frameStr += '&fitmethod=meet';
  frameStr += `&framedir=upload&filelocation=${getOrderFileLocation(
    null,
    0,
    0,
    frame.fillColor
  )}`;
  // end of frame
  frameStr += '\n';
  frameStr += 'frame=end\n';
  return frameStr;
}

//------------------------------
// Write A Frame shadow
//------------------------------
function writeAFrameShadow(frame: Frame): string {
  const shadowPrintFrame = cloneDeep(frame);
  const shadowRect = GetShadowFrameRect(frame);
  shadowPrintFrame.width = shadowRect.width;
  shadowPrintFrame.height = shadowRect.height;
  shadowPrintFrame.x =
    frame.x - frame.width * 0.5 + shadowRect.x + shadowRect.width * 0.5;
  shadowPrintFrame.y =
    frame.y - frame.height * 0.5 + shadowRect.y + shadowRect.height * 0.5;

  // Note: if the frame is rotated, the shadow offset will be wrong as the shadow is rendered in a svg group but printed as separated frame.
  if (frame.rotation !== 0) {
    const centerFrame = { x: frame.x, y: frame.y };
    const centerShadow = { x: shadowPrintFrame.x, y: shadowPrintFrame.y };
    const finalCenterPoint = rotatePointAround(
      centerShadow.x,
      centerShadow.y,
      centerFrame.x,
      centerFrame.y,
      frame.rotation
    );
    shadowPrintFrame.x = finalCenterPoint.x;
    shadowPrintFrame.y = finalCenterPoint.y;
  }

  const printCoords: Point = getPrintCoords(shadowPrintFrame, true);
  const fLeft: number = printCoords.x;
  const fTop: number = printCoords.y;
  const fRotation: number = frame.rotation;

  let frameStr = 'frame=begin';
  // write tranfsorm
  frameStr += writeFrameTransform(
    fLeft,
    fTop,
    shadowPrintFrame.width,
    shadowPrintFrame.height,
    0,
    0,
    shadowPrintFrame.width,
    shadowPrintFrame.height,
    fRotation
  );

  // north is by default, do not add this if not needed
  frameStr += '&orientate=north'; //  TODO: later if we change orientation possibilities inside frame

  // shadow fitmethod
  frameStr += '&fitmethod=entire'; // "&fitmethod=meet" ;
  // frameStr += "&alpha=0.1"; // TODO: not working.. ask keith for correct parameter

  frameStr += `&framedir=upload&filelocation=${getOrderFileLocation(
    frame,
    0,
    0,
    null,
    frame.shadow
  )}`;

  // end of frame
  frameStr += '\n';
  frameStr += 'frame=end\n';

  return frameStr;
}

/**
 * write a calendar frame server side using font size and color
 * For more complex calendar frame we use image uploads
 */
function writeAFrameCalendar(pageIndex: number, frame: Frame): string {
  const frameWidth: number = frame.width;
  const frameHeight: number = frame.height;
  const printCoords: Point = getPrintCoords(frame, true);
  const fLeft: number = printCoords.x;
  const fTop: number = printCoords.y;

  // frame rotation
  const fRotation: number = frame.rotation;
  let frameStr = 'frame=begin';
  frameStr += writeFrameTransform(
    fLeft,
    fTop,
    frameWidth,
    frameHeight,
    0,
    0,
    frameWidth,
    frameHeight,
    fRotation
  );

  // retreive calendar colors!
  const { project } = StoreRef.edition;
  const { docID } = project;
  const colorOptions: ICalendarColorOptions = project.calendarColorOptions;

  // // -----
  // // TEMPORARY HACK FOR ROTATED FRAME TEXT IN ORGANISER
  // // EDIT : we will use the upload system for those frames
  // /*
  // if(ProductsCatalogue.IsCalendar_Organizer()
  //     && ( frameVo.dateAction === CalendarManager.DATEACTION_MONTH || frameVo.dateAction === CalendarManager.DATEACTION_YEAR )
  //     && frameVo.rotation !==0)
  // {
  //     frameStr += "&textflow=true"; // textflow allows the system to take width and height into account while printing text
  // }
  // */
  // /*
  // if(ProductsCatalogue.IsCalendar_Organizer()
  //     && ( frameVo.dateAction === CalendarManager.DATEACTION_MONTH || frameVo.dateAction === CalendarManager.DATEACTION_YEAR )
  //     && frameVo.rotation !==0)
  // {
  //     // frame=begin&left=567.0084585067567&top=333.2005900743243&width=281.5607961486486&height=80.17508&rotation=-90&textcolorspace=cmyk&textcolor=0.850000,0.850000,0.850000,1.000000&fontsize=35&fontname=Helvetica&textoffx=0&textoffy=0&align=top&justify=right&text=DÉCEMBRE&urldecode=true&fill=true&fillcolorspace=cmyk&fillcolor=1.000000,0.000000,1.000000,0.000000&framedir=none
  //     frameStr += "&left="+ (frameVo.x + frameVo.height/2);
  //     frameStr += "&top="+ (frameVo.y + frameVo.height/2);
  //     frameStr += "&orientate=west";
  //     frameStr += "&textcolorspace=cmyk&textcolor=0.850000,0.850000,0.850000,1.000000";
  //     frameStr += "&fontsize=" + tf.size;
  //     frameStr += "&fontname=Helvetica";
  //     if(frameVo.calBold)
  //         frameStr += "&fontweight=" + FontStyle.BOLD;
  //     if(frameVo.calItalic)
  //         frameStr += "&fontstyle=" + FontStyle.ITALIC;
  //     frameStr += "&align=top&justify=right";
  //     frameStr += "&text="+ CalendarManager.instance.getFrameText( frameVo );
  //     frameStr += "&urldecode=true";
  //     frameStr += "&fill=true&fillcolorspace=cmyk&fillcolor=1.000000,0.000000,1.000000,0.000000";
  //     frameStr += "&framedir=none";
  //     frameStr += "\n";
  //     frameStr += "frame=end\n";
  //     return frameStr;
  // }
  // */
  // // -----

  // frameStr += "&left="+fLeft //(frame.PDFTextLiteralLocationPoint.x * _docScale).toFixed(6);
  // frameStr += "&top="+fTop //frameVo.r(frame.PDFTextLiteralLocationPoint.y * _docScale).toFixed(6);
  // frameStr += "&width="+frameVo.width;
  // frameStr += "&height="+frameVo.height;

  // // frame rotation // not handled for calendar yet!
  // /*
  // if(frameVo.rotation === -90 || frameVo.rotation === 270)
  //     frameStr += "&orientate=west";
  // else if(frameVo.rotation === 90)
  //     frameStr += "&orientate=east";
  // else if(frameVo.rotation !==0)
  //     Debug.warnAndSendMail("OrderManager.WriteCalendarFrame : frame rotation not recognized : '"+frameVo.rotation + "' on frame : "+frameVo.toDebugString());
  // */
  // //frameStr += "&rotation="+frameVo.rotation;

  const calOptions: ICalendarFrameOptions = frame.calendarOptions;

  // STROKE
  if (calOptions.stroke || DebugFlags.PRINT_ALL_CALENDAR_FRAME_WITH_STROKE) {
    frameStr += '&stroke=true';
    frameStr += PrintColorHelper.getRGBColorString(
      'strokecolor',
      colorOptions.strokeColor
    );
  }

  // color
  frameStr += PrintColorHelper.getRGBColorString(
    'textcolor',
    CalendarHelper.GetFrameTextColor(pageIndex, frame)
  );

  frameStr += `&fontsize=${calOptions.fontSize}`;

  // TODO: fixme for any font (or allowed fonts)
  frameStr += '&fontname=Helvetica';
  // frameStr += "&fontname=" + calOptions.fontFamily; // TODO: this so not work for some reason.. let's keep helvetica for now!

  // frameStr += "&fontweight=bold"
  if (calOptions.bold) {
    frameStr += '&fontweight=bold';
  }
  if (calOptions.italic) {
    frameStr += '&fontstyle=italic';
  }
  /*
        var fontweight : String = (frameVo.calBold)? FontStyle.BOLD : FontStyle.REGULAR;
        frameStr += "&fontweight=" + fontweight;
        */
  // var fontstyle : String = (frameVo.calItalic)? FontStyle.ITALIC : FontStyle.REGULAR;
  // frameStr += "&fontstyle="+fontstyle;
  /*
        if(frame.TLFontWeight !==FontStyle.REGULAR) {
            frameStr += "&fontweight="+frame.TLFontWeight;
        }
        if(frame.TLFontStyle !==FontStyle.REGULAR) {
            frameStr += "&fontstyle="+frame.TLFontStyle;
        }
        */

  // ---- OFFSET and ALIGNEMNT ----
  const offset = CalendarHelper.GetFrameTextOffset(frame);
  const textOffsetX: number = offset.x;
  const textOffsetY: number = offset.y;
  frameStr += `&textoffx=${textOffsetX}`;
  frameStr += `&textoffy=${textOffsetY}`;
  frameStr += `&align=${
    calOptions.vAlign === 'center' ? 'middle' : calOptions.vAlign
  }`;
  frameStr += `&justify=${calOptions.hAlign}`;
  // frameStr += "&urldecode=true";
  // var encoded:String = encodeURIComponent(frameVo.text);
  // frameStr += "&text="+encoded;

  /**
   * 2 types of frame to write
   * > simple frame in one line
   * > multiple line text
   */
  const frameText: string = CalendarHelper.GetFrameText(pageIndex, frame);

  if (CalendarHelper.IsFrameMultiline(frame)) {
    frameStr += '&urldecode=true';

    // calendar magnets needs to have a biggerr spacing between week lines
    if (IsCalendar_Magnet(docID)) frameStr += '&leading=150%';
    // frameText = frameText.split("\n").join("\n\n\n"); // old way of doing...

    // TODO: why those lines in previous version??
    // var encoded:String = encodeURIComponent( frameText + "\n\n\n\n\n\n\n\n\n\n\n\n");
    // frameStr += "&text="+encoded;
    // if((encoded.indexOf("%0D") > 0) || (encoded.indexOf("%0A") > 0)) {
    //     frameStr += "&textflow=true";
    // }

    // test with textflow
    const encoded: string = encodeURIComponent(`${frameText}\n`);
    frameStr += `&text=${encoded}`;
    frameStr += '&textflow=true';
  } else {
    frameStr += `&text=${frameText}`;
    frameStr += '&urldecode=true';
  }

  // SHOW BACKGROUND -- EDIT : we can't use this anymore as we are now using a map for print VS display colors
  /*
        var fill:Boolean = ((frameVo.dateAction === CalendarManager.DATEACTION_DAYDATE) && pageVo.customColors.dayBackground !==-1)?true:false;

        if(fill)
        {
            frameStr += "&fill=true";
            frameStr += getCMYKColorStringFromRGB("fillcolor", pageVo.customColors.dayBackground);
        }
        */

  // ----- DEBUG

  /*
        frameStr += "&fill=true";
        frameStr += getCMYKColorStringFromRGB("fillcolor", Colors.GREEN_FLASH);
        */

  // ------

  frameStr += '&framedir=none';
  frameStr += '\n';
  frameStr += 'frame=end\n';

  return frameStr;
}

// function writeCalendarFrameWithBackground( frameVo : FrameVo, backgroundColor :number ):String
// {
//     var framesStr : String = "";

//     // create a background frame for this calendar frame
//     var bkg:FrameVo = CalendarManager.instance.GetCalendarBackgroundColorFrame( frameVo, backgroundColor );
//     framesStr = writeAFrame( bkg, false, false, false, true );

//     // write the calendar frame
//     framesStr += writeCalendarFrame( frameVo );
//     return framesStr;
// }

/**
 * image fill color & alpha information
 */
function getFrameFill(frame: Frame): string {
  let fillColorStr: string;

  if (
    frame.type === FRAME_TYPE.BKG &&
    !frame.photo &&
    frame.fillColor &&
    frame.fillColor !== Colors.WHITE
  ) {
    fillColorStr = '&fillalpha=1';

    // ---- COLOR CONVERSION NOTES ----
    // first we tried a new "converter" from color-convert
    // -> same result as current "home made" converters
    // then we used the rgb color space instead of cmyk.
    // --> BOOM same result as on screen.. probably won't print correctly in the end.. but at least we get the same colors as in the editor!
    // var convert = require('color-convert');
    // // frame.fillColor = "#ff0000";
    // // const cmyk:Array = convert.hex.cmyk(frame.fillColor.substring(1)).map(value => (value/100).toFixed(6));
    // // const cmykString = cmyk.join(",");
    // const rgb:Array = convert.hex.rgb(frame.fillColor.substring(1)).map(value => (value/255).toFixed(6));
    // const rgbString = rgb.join(",");
    // fillColorStr += "&fillcolorspace=rgb&fillcolor="+rgbString;
    // ---> finaly use the home made existing RGB color string..

    fillColorStr += PrintColorHelper.getRGBColorString(
      'fillcolor',
      frame.fillColor
    );
  } else {
    fillColorStr = '&fillalpha=0';
    fillColorStr +=
      '&fillcolorspace=cmyk&fillcolor=0.000000,0.000000,0.000000,0.000000';
  }

  return fillColorStr;
}

function writeFrameText(frame: Frame): string {
  // return "frame=begin&orientate=south&left=428.679636&top=-36.850394&textcolorspace=cmyk&textcolor=0.850000,0.850000,0.850000,1.000000&justify=center&fontname=Helvetica&fontsize=12.0&framedir=none&text=1029664-{orderid}-{totalqty}-30\n"+

  const printCoords: Point = getPrintCoords(frame);
  const fLeft: number = printCoords.x;
  const fTop: number = printCoords.y;
  // var halign : String = CalendarManager.instance.getFrameTextHAlign( frameVo );
  // var valign : String = CalendarManager.instance.getFrameTextVAlign( frameVo );
  // var tf:TextFormat = TextFormats.getCalendarTextFormatByDateAction(frameVo,halign);
  // var pageVo:PageVo = PagesManager.instance.getPageVoByFrameVo(frameVo);

  let frameStr = '';
  frameStr += 'frame=begin';

  // write frame transformt
  frameStr += writeFrameTransform(
    fLeft,
    fTop,
    frame.width,
    frame.height,
    0,
    0,
    frame.width,
    frame.height,
    frame.rotation
  );

  // TODO:
  // if(frameVo.stroke) {
  //  frameStr += "&stroke=true";
  //  frameStr += getCMYKColorStringFromRGB("strokecolor", pageVo.customColors.border);
  // }

  const textObject = frame.text;
  /*
    value : "Add your text here",
    size : 24,
    family : "Open Sans",
    color : "#000000",
    valign : "middle",
    halign : "center",
    bold : false,
    italic : false,
    */

  frameStr += PrintColorHelper.getRGBColorString('textcolor', textObject.color);
  frameStr += `&fontsize=${textObject.size}`;
  /// ////fixme for any font (or allowed fonts)
  frameStr += '&fontname=Helvetica';
  // frameStr += "&fontname="+frame.TLFontFamily;

  // frameStr += "&fontweight=bold"
  if (textObject.bold) {
    frameStr += '&fontweight=bold'; // , + FontStyle.BOLD;
  }
  if (textObject.italic) {
    frameStr += '&fontstyle=italic'; // + FontStyle.ITALIC;
  }
  /*
    var fontweight : String = (frameVo.calBold)? FontStyle.BOLD : FontStyle.REGULAR;
    frameStr += "&fontweight=" + fontweight;
    */
  // var fontstyle : String = (frameVo.calItalic)? FontStyle.ITALIC : FontStyle.REGULAR;
  // frameStr += "&fontstyle="+fontstyle;
  /*
    if(frame.TLFontWeight !==FontStyle.REGULAR) {
        frameStr += "&fontweight="+frame.TLFontWeight;
    }
    if(frame.TLFontStyle !==FontStyle.REGULAR) {
        frameStr += "&fontstyle="+frame.TLFontStyle;
    }
    */

  // TODO:
  // var offset : Point = CalendarManager.instance.getFrameTextOffset( frameVo );
  // var textOffsetX :number = offset.x;
  // var textOffsetY :number = offset.y;
  // frameStr += "&textoffx=" + textOffsetX;
  // frameStr += "&textoffy=" + textOffsetY;
  // frameStr += "&align="+ valign;
  // frameStr += "&justify=" +halign;
  // frameStr += "&urldecode=true";

  // var encoded:String = encodeURIComponent(frameVo.text);
  // frameStr += "&text="+encoded;

  /**
   * 2 types of frame to write
   * > simple frame in one line
   * > multiple line text
   */
  // var frameText : String = CalendarManager.instance.getFrameText( frameVo );
  // if( CalendarManager.instance.isFrameMultiline( frameVo ) ){
  //  frameStr += "&urldecode=true";
  //  var encoded:String = encodeURIComponent( frameText + "\n\n\n\n\n\n\n\n\n\n\n\n");
  //  frameStr += "&text="+encoded;
  //  if((encoded.indexOf("%0D") > 0) || (encoded.indexOf("%0A") > 0)) {
  //   frameStr += "&textflow=true";
  //  }
  // }
  // else
  // {
  frameStr += `&text=${textObject.value}`;
  frameStr += '&urldecode=true';
  // }

  // SHOW BACKGROUND -- EDIT : we can't use this anymore as we are now using a map for print VS display colors
  /*
    var fill:Boolean = ((frameVo.dateAction === CalendarManager.DATEACTION_DAYDATE) && pageVo.customColors.dayBackground !==-1)?true:false;

    if(fill)
    {
        frameStr += "&fill=true";
        frameStr += getCMYKColorStringFromRGB("fillcolor", pageVo.customColors.dayBackground);
    }
    */

  // ----- DEBUG

  /*
    frameStr += "&fill=true";
    frameStr += getCMYKColorStringFromRGB("fillcolor", Colors.GREEN_FLASH);
    */

  // ------

  frameStr += '&framedir=none';
  frameStr += '\n';
  frameStr += 'frame=end\n';

  return frameStr;
  // "frame=begin&orientate=south&left=428.679636&top=-36.850394&textcolorspace=cmyk&textcolor=0.850000,0.850000,0.850000,1.000000&justify=center&fontname=Helvetica&fontsize=12.0&framedir=none&text=1029664-{orderid}-{totalqty}-30\n"+
}

// --------------------- Order HEADERS ------------------------

/**
 *  ORDER HEADER
 *  > it's injected in the order just before starting to inject the pages
 *  > it's one header by order
 *  > for albums cover header looks like some kind of xml with information about the order
 */
function WriteOrderHeader(project: Project, orderSuffix: string): string {
  if (IsAlbumEditor()) return writeAlbumOrderHeader(project, orderSuffix);
  if (IsCalendarEditor()) return writeCalendarOrderHeader(project);
  if (IsCanvasEditor()) return writeCanvasOrderHeader(project);
  if (IsCardEditor()) return '&cutmarks=false\n';
  return '\n';
}

/**
 * ALBUM ORDER HEADERS
 *  > album header can have two types, cover and normal (depending on orderSuffix)
 */
function writeAlbumOrderHeader(project: Project, orderSuffix: string): string {
  let headerStr = '';
  const projectOptions: ProjectOptions = GetProjectOptions(project);

  // --- CASE album classic (send along with album data)
  if (orderSuffix === ORDER_SUFFIX.ALBUM) {
    const coverClassicOptions: ClassicCoverOptions =
      GetClassicCoverOptions(project);
    const coverLabelName = coverClassicOptions
      ? CoverToCoverLabelName(coverClassicOptions.cover)
      : 'customcover';
    let coverTextFont: string = coverClassicOptions
      ? coverClassicOptions.fontName
      : 'Arial';
    if (!coverTextFont || coverTextFont === '') coverTextFont = 'Arial';
    // var manifestName : String = (classicCoverVo)? project.coverType : "Custom Cover";
    const manifestName: string = coverLabelName;

    headerStr = `<cover encoding="Windows-1252" name="${coverLabelName}"`;
    headerStr += ` font="${coverTextFont}"`;
    headerStr += ' binding="Case"';
    headerStr += ` manifestname="${manifestName}"`;

    if (coverClassicOptions) {
      headerStr += ` foil="${coverClassicOptions.color}"`;
      headerStr += ` corners="${coverClassicOptions.corners}"`;
    }

    // paper between pages
    if (project.paper) {
      headerStr += ' paper="1"';
    } else {
      headerStr += ' paper="0"';
    }
    headerStr += '>\n';

    if (coverClassicOptions) {
      headerStr += `<coverline location="Cover 1" encoding="Windows-1252">${encodeURIComponent(
        coverClassicOptions.line1
      )}</coverline>\n`;
      headerStr += `<coveractual location="Cover 1" encoding="Windows-1252">${coverClassicOptions.line1}</coveractual>\n`; // TODO CHECK htmlEscape.. htmlEscape(classicCoverVo.line1)
      headerStr += `<coverline location="Cover 2" encoding="Windows-1252">${encodeURIComponent(
        coverClassicOptions.line2
      )}</coverline>\n`;
      headerStr += `<coveractual location="Cover 2" encoding="Windows-1252">${coverClassicOptions.line2}</coveractual>\n`;
      headerStr += `<coverline location="Spine" encoding="Windows-1252">${encodeURIComponent(
        coverClassicOptions.spine
      )}</coverline>\n`;
      headerStr += `<coveractual location="Spine" encoding="Windows-1252">${coverClassicOptions.spine}</coveractual>\n`;
      headerStr += `<coverline location="Edition" encoding="Windows-1252">${coverClassicOptions.edition}</coverline>\n`;
      headerStr += `<coveractual location="Edition" encoding="Windows-1252">${coverClassicOptions.edition}</coveractual>\n`;
    }
    headerStr += '</cover>\n';
  }

  // --- CASE COVER (custom cover only, classic covers are not sent in another order)
  else if (orderSuffix === ORDER_SUFFIX.COVER) {
    const coverPage: IPage = GetProjectCover(project);
    const cutBorder: number = GetPrintCutBorderInPixel(project, true);
    const spinewidth: number = mmToPoint(getSpineWidthMM(projectOptions));
    let trimborder = '&trimborder=';
    const cutmarks = 'true';
    if (project.type === ALBUM_TYPES.CASUAL) {
      trimborder += '0';
    } else {
      // ((DocCutBorder - 10) * MMToPoints).toString();
      trimborder += `${(cutBorder - mmToPoint(10)).toFixed(6)}`;
    }
    return `&spinewidth=${spinewidth.toString()}${trimborder}&cutmarks=true\n${headerStr}`;
  }

  return `&cutmarks=true\n${headerStr}`;
}

/**
 * CALENDAR ORDER HEADERS
 *  > it's injected in the order just before starting to inject the pages
 * > it's one header by order
 */
function writeCalendarOrderHeader(project: Project): string {
  let headerStr = '';
  headerStr = '&cutmarks=true\n'; // headerStr = "&cutmarks=false\n";
  headerStr += manifestHelper.writeOrderStartingManifestPage(project);
  return headerStr;
}

// /**
//  * CANVAS ORDER HEADERS
//  *  > it's injected in the order just before starting to inject the pages
//  *  > it's one header by order
//  */
function writeCanvasOrderHeader(project: Project): string {
  const frameColor =
    project.type === CANVAS_TYPES.KADAPAK ? project.canvasFrameColor : 'none';
  let orderstr = `&framecolor=${frameColor}`;
  orderstr += '&cutmarks=false\n';
  return orderstr;
}

// function htmlEscape(str:String):String {
//  return XML( new XMLNode( XMLNodeType.TEXT_NODE, str ) ).toXMLString();
// }

/// /////////////////////////////////////////////////////////////
// PRINT MANIFEST
/// /////////////////////////////////////////////////////////////

/**
 * check if this page must be printed (USING Debug.PRINT_SPECIFIC_PAGES)
 */
export function CheckPageMustBePrinted($pageIndex: number): boolean {
  // if not debugging, page must be printed
  if (
    !DebugFlags.ORDER_TO_PDF ||
    !DebugFlags.PDF_PRINT_SPECIFIC_PAGES ||
    IsCardEditor()
  ) {
    return true;
  }

  // recover pages to print
  const neededPrintPages = DebugFlags.PDF_PRINT_SPECIFIC_PAGES.split(',');

  for (let i = 0; i < neededPrintPages.length; i++) {
    // check for ranges also
    const pageRange: Array = neededPrintPages[i].split('-');

    // case range
    if (pageRange.length > 1) {
      if ($pageIndex >= pageRange[0] && $pageIndex <= pageRange[1]) return true;
    }

    // case no range
    else if (parseInt(neededPrintPages[i], 10) === $pageIndex) return true;
  }
  return false;
}

/**
 * check if the frame is a background color that must be printed (if there is no photo, if there is a valid color and if the background is not white (or is calendar frame background)
 */
function IsPrintableBackgroundColor(
  frame: Frame,
  isCalendarFrameBackground: boolean
): boolean {
  // TODO:
  // if( frame.type === FRAME_TYPE.BKG // Frame is type background
  //  && !frame.photo     // Frame has not photo
  //  && frame.fillColor !==-1 && !isNaN(frame.fillColor) // frame has a valid fill color
  //  &&  ( frame.fillColor !==Colors.WHITE || isCalendarFrameBackground)) return true; // frame color is not white or is calendar frame background (which can be white)

  if (frame.fillColor && frame.fillcolor !== Colors.WHITE) return true;

  return false;
}

/**
 * image directory (used in ordering system)
 */
function getImageDirectory(frame: Frame): string {
  // ---- UPLOADED FILE ----
  if (NeedFrameUpload(frame)) return 'upload';

  // ---- RESOURCE FILE ----
  if (
    frame.type === FRAME_TYPE.CLIPART ||
    frame.type === FRAME_TYPE.OVERLAYER ||
    (frame.type === FRAME_TYPE.BKG && frame.background)
  )
    return 'resource';

  // ---- USER PHOTO FILE ----
  if (frame.photo) {
    const photo: Photo = getPhoto(frame.photo);
    return photo.orderSuffix;
  }

  // // others ??
  return 'none';
}

/**
 * image directory (used in ordering system)
 */
// function getOrderFileLocation(frame:Frame, pageIndex:number, frameIndex:number, isColor:Boolean = false, isShadow:Boolean = false, isPostcardBKG:Boolean = false, isFullPagePreview = false):String
export function getOrderFileLocation(
  frame: Frame,
  pageIndex: number,
  frameIndex: number,
  hexColor: string = null,
  frameShadow: IFrameShadow = null,
  fullPagePreviewIndex = -1
): string {
  // PAGE PREVIEW
  if (fullPagePreviewIndex !== -1)
    return `PagePreview_${fullPagePreviewIndex}.jpg`;

  // COLOR
  if (hexColor !== null && hexColor !== false)
    return `Color_${hexColor.replace('#', '')}.png`;

  // SHADOW
  if (frameShadow)
    return `Shadow_${frameShadow.hexColor.replace('#', '')}_a${
      frameShadow.opacity * 100
    }${frame && frame.borderRadius ? `_radius${frame.borderRadius}` : ''}.png`;

  // POSTCARD
  if (frame.type === FRAME_TYPE.POSTCARD_BG)
    return `postcardbg_${frame.width.toFixed(2)}x${frame.height.toFixed(
      2
    )}.png`;

  // POSTCARD
  if (frame.type === FRAME_TYPE.PROMO_URL)
    return `promo_url_${frame.width.toFixed(2)}x${frame.height.toFixed(2)}.png`;

  // TEXT
  if (
    frame.type === FRAME_TYPE.TEXT ||
    frame.type === FRAME_TYPE.SPINE_NUM ||
    frame.type === FRAME_TYPE.SPINE
  )
    return `Txt_${pageIndex}-${frameIndex}.png`;
  // if(frame.type === FRAME_TYPE.TEXT)
  //     return "Txt_"+ frame.id + ".png";

  // TODO:
  // QR
  // if (frame.type === FrameVo.TYPE_QR) return "QR_"+pageIndex+"-"+frameIndex+".png";

  if (frame.type === FRAME_TYPE.CALENDAR) {
    if (frame.rotation !== 0) {
      // a calendar frame with the same text, color, width, height, fontsize and rotation should be considered the same
      // eslint-disable-next-line max-len
      return sanitizeFilenameForServer(
        `CAL_${CalendarHelper.GetFrameText(
          pageIndex,
          frame
        )}_${frame.width.toFixed(2)}x${frame.height.toFixed(2)}_${
          frame.calendarOptions.fontSize
        }p_r${frame.rotation}_${CalendarHelper.GetFrameTextColor(
          pageIndex,
          frame
        )}.png`
      );
    }
    // notes
    if (frame.calendarOptions.dateAction === DATE_ACTION_ENUM.NOTES) {
      return 'CAL_NOTES.png';
    }
    return `CAL_${pageIndex}-${frameIndex}.png`;
  }

  // // TODO:
  // // POPART
  // if (frame.isPopart) return "POP_"+pageIndex+"-"+frameIndex+".jpg";

  // CLIPART
  if (frame.type === FRAME_TYPE.CLIPART) {
    const clipart = getClipart(frame.clipart);
    if (!clipart) {
      alert(`NO clipart found with ID:${frame.clipart}`);
    }
    return clipart.proxy;
  }

  // BKG
  if (frame.type === FRAME_TYPE.BKG && frame.background) {
    const bg = getBackground(frame.background);
    if (!bg) {
      alert(`NO Background found with ID:${frame.background}`);
    }
    return bg.proxy;
  }

  // OVERLAYER
  if (frame.type === FRAME_TYPE.OVERLAYER) {
    const overlayer = getOverlayer(frame.overlayer);
    if (!overlayer) {
      alert(`NO Overlay found with ID:${frame.overlayer}`);
    }
    return overlayer.proxy;
  }

  // Mask
  // if(frame.photo && frame.borderRadius)
  //     return "mask_"+pageIndex+"-"+frameIndex+".png";

  // PHOTO ONLINE
  if (frame.photo) {
    // //TODO:
    // // STANDALONE
    // if(frame.photoVo.IsCustomRebrandingPhoto)
    //     return Infos.project.CustomRebrandedProductUrl + "/" + frame.photoVo.fullsizeImagePath; //return "standalone/belbo/test1/px_1481719821039_0.jpg";
    //     //return "standalone/belbo/test1/px_1481719821039_0.jpg";
    //     //return Infos.config.rebrandedProduct + "/" + frame.photoVo.fullsizeImagePath; //return "standalone/belbo/test1/px_1481719821039_0.jpg";

    // normal online photo
    return getPhoto(frame.photo).name;
    // return frame.photo.name;
  }

  // ...
  // console.warn("FileLocation issue for frame : "+frame.toString());
  return '';
}

// http://stackoverflow.com/questions/8927711/flex-filereference-prohibited-characters
function sanitizeFilenameForServer(fileName: string): string {
  // var p:RegExp = /[:()\/\\\*\?"<>\|%@]/g;
  const p = /[^A-Za-z0-9\-_.]/g;
  fileName = fileName.replace(p, '');
  return fileName.trim();
}

// --------------------- Print coordinate calculation ------------------------

// Translate editor coords to print coords
const posXAdjustement = 0; // Added to the X position . For some calendar frame (Cal lux) X position can change depending on the month index
// 1.1 10% of security for frame text margin
// EDIT : this makes the whole text print system not correct with rotations and big fonts.. set back to 1
// EDIT2 : fixed in 9.19.28, set back to not having frame text cropped
const TEXT_FRAME_SECURITY_MARGIN = 1; // 1.1;

function getPrintCoords(frame: Frame, doNotUseBorderInCalculation = false) {
  const frameOffset: number = doNotUseBorderInCalculation
    ? 0
    : mmToPoint(frame.border);

  // var framePrintScaleWithSecurityOffset :number = (type === FrameVo.TYPE_TEXT )? FrameExporter.TEXT_FRAME_SECURITY_MARGIN : 1; // print margin is done for frame text upload, add some security margin to avoid text to be cut
  const framePrintScaleWithSecurityOffset: number =
    frame.type === FRAME_TYPE.TEXT ? TEXT_FRAME_SECURITY_MARGIN : 1; // print margin is done for frame text upload, add some security margin to avoid text to be cut
  const w: number =
    frame.width * framePrintScaleWithSecurityOffset - frameOffset * 2;
  const h: number =
    frame.height * framePrintScaleWithSecurityOffset - frameOffset * 2;

  let x = frame.x + posXAdjustement - w * 0.5;
  let y = frame.y - h * 0.5;

  // handle rotated frames
  if (frame.rotation !== 0) {
    // the printing system use a rotation point of 'bottom left' (WTF??)
    // but an origin point of top left !!??
    // so we need a matrix convertion to find the correct top left point

    // let's first find bottom left point of "rotated" rect
    const BL = { x: frame.x + posXAdjustement - w * 0.5, y: frame.y + h * 0.5 };
    const rotatedBL = rotatePointAround(
      BL.x,
      BL.y,
      frame.x + posXAdjustement,
      frame.y,
      frame.rotation
    );
    // now get the topleft point of with this bottom left rotated point
    const TL = { x: rotatedBL.x, y: rotatedBL.y - h };
    x = TL.x;
    y = TL.y;
  }

  return { x, y };
}

/**
 * Print demo file to check webservice system communication and process
 * > use to verify viability of Print to PDF Debug system
 */
function getDemoOrderString(): string {
  console.log('--- PRINT DEMO ---');
  const orderStr = '';

  if (isEmpty(orderStr)) throw new Error('nothing to print');

  return orderStr;
}

/**
 * generate url use to check if job is correct
 */
function generateCheckJobUrl(jobID: string): string {
  const today: Date = new Date();
  let url = `${config.secureUrl}check_job.php`; // Infos.config.getSettingsValue("CheckJobPage");
  url += '?';
  url += `jobid=${jobID}`;
  url += `&email=${authSelectors.GetUserEmail(StoreRef)}`;
  url += `&lang=${GetCurrentLanguage()}`;
  url += `&GUID={${GetUID()}}`; // +_projectXMLClass.GUID; //  TODO : ask what this is?
  url += `&cp=${today.getTime()}`;

  console.log(`generateCheckJobUrl: ${url}`);
  return url;
}

/**
 * generate order complete url
 * > url to reach after order is complete
 */
function generateOrderFinalURL(
  project: Project,
  jobID: string,
  vendorID: string,
  orderConnectID: string
): string {
  const today: Date = new Date();
  let url = `${config.baseUrl}/flex/load_order.php`; // Infos.config.getSettingsValue("OrderPage");
  url += `?cp=${today.getTime()}`;
  url += '&step=2';
  url += `&oid=${jobID}`;
  url += `&email=${authSelectors.GetUserEmail(StoreRef)}`;
  url += `&lang=${GetCurrentLanguage()}`;
  url += `&vid=${vendorID}`;
  url += `&pw=${orderConnectID}`;

  // promo code TODO:
  // if( Infos.project.promoCode ){
  //  url += "&promo=" + Infos.project.promoCode;
  // }

  url += `&campaign=${PROJECT_CONST.campaign}`;
  if (PROJECT_CONST.branding) {
    url += `&branding=${PROJECT_CONST.branding}`;
  }
  url += WriteOrderUrlHeader(project);
  return url;
}

function WriteOrderUrlHeader(project: Project): string {
  let url = '';

  // ---- CARDS ----
  if (IsCardEditor()) {
    url = `&envelope=${project.envelope}`;
    // switch(Infos.project.docPrefix) {
    //  case "PC":
    //  case "PX":
    //   url = "&envelope=none";
    //   break;
    // }
    return url;
  }

  // --- CANVAS ---
  if (IsCanvasEditor()) {
    url = '';

    // TODO: canvas multiple
    // if ((Infos.project.docPrefix === "CPF") || (Infos.project.docPrefix === "CPM"))
    if (project.canvasFormat === CANVAS_FORMAT.FREE) {
      const w: number = Math.round(pixelToCm(project.width));
      const h: number = Math.round(pixelToCm(project.height));
      url += `&width=${w.toFixed(1)}&height=${h.toFixed(1)}`;
    }

    // TODO: canvas multiple
    // if (Infos.project.docPrefix === "CPM")
    // {
    //  url += "&rows=";
    //  url += "" + CanvasManager.instance.multipleRowsCount;
    //  url += "&cols=";
    //  url += "" + CanvasManager.instance.multipleColsCount;
    // }
    return url;
  }

  // DEFAULT
  return url;
}
