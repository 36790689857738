import type { IPage, Frame } from '../../types/types';
import { GetPrintCutBorderInPixel } from '../../utils/printHelper';
import {
  GetCoverSizeInPixel,
  HasCustomCover,
  GetClassicCoverOptions,
} from '../../utils/coverHelper';
import { mmToPoint } from '../../utils/MeasureUtils';
import { PrintColorHelper } from './printColorsHelper';
import { ORDER_SUFFIX, getOrderFileLocation } from './orderHelper';

import { CanvasHelper, CANVAS_TYPES } from '../../utils/canvas/CanvasHelper';
import { GetDoc } from '../../utils/ProductHelper';
import { ALBUM_TYPES } from '../../data/Constants';
import {
  IsAlbumEditor,
  IsCalendarEditor,
  IsCanvasEditor,
  IsCardEditor,
} from '../../data/config';
import { Project } from '../../data/Project';

export const manifestHelper = {
  // page manifests
  writeAManifestPrePageContent,
  writeAManifestPostPageContent,
  writeAManifestBlock,

  // special for "recomposition of order" after print, barcode etc...
  writeOrderStartingManifestPage,
  writeOrderEndingManifestPage,
  getPreviewPageIndex,
};

// /**
//  * Total pages for current project
//  */
// function get totalPages():number
// {
//  var pageCount :number = Infos.project.pageList.length;
//  if(Infos.isCards) return pageCount*CardsManager.instance.cardsRepeatValue;
//  return pageCount;
// }

/**
 * print information on a page to write before the actual frames are written
 * NOTE: actually only needed for Canvas...
 */
function writeAManifestPrePageContent(project: Project, page: IPage): string {
  if (IsCanvasEditor()) return writeACanvasManifestBlock(project, page.index);
  return null;
}

/**
 * print information on a page to write after the actual frames are written
 * NOTE: actually only used for cover
 */
function writeAManifestPostPageContent(
  project: Project,
  page: IPage,
  jobID: string
): string {
  if (IsAlbumEditor() && page.isCover)
    return writeACoverManifest(project, page, jobID);
  return null;
}

/**
 Write a page before the actual order pages
 */
function writeOrderStartingManifestPage(project: Project) {
  // this is only done for calendar editor right now
  if (IsCalendarEditor()) {
    // TODO: do this again?
    // If yes, add a manifestAtEnd in the card catalogue..
    // ---- MANIFEST IS WRITTEN AS !! FIRST !! PAGE
    //  case ProductsCatalogue.PREFIX_CAL_LUX_A3 :
    //  case ProductsCatalogue.PREFIX_CAL_LUX_A4 :
    //  case ProductsCatalogue.PREFIX_CAL_DESK_A5 :
    //  case ProductsCatalogue.PREFIX_CAL_PORTRAIT_A5 :
    //  case ProductsCatalogue.PREFIX_CAL_PORTRAIT_A6 :

    // ---- MANIFEST IS WRITTEN AS !! END !!  PAGE
    // case ProductsCatalogue.PREFIX_CAL_WALL :
    // case ProductsCatalogue.PREFIX_CAL_WALL_XL :
    // case ProductsCatalogue.PREFIX_CAL_WALL_XXL :
    // case ProductsCatalogue.PREFIX_CAL_BIRTHDAY_WALL :
    // case ProductsCatalogue.PREFIX_CAL_DESK_PANO :

    // TODO: for now we only print pages at the end of order, not at start..
    // return writeCalendarManifestPage(project,0);
    return '';
  }
  return '';
}

/**
 * Print manifest page is a special page with reference of the project
 * > used for print and recomposition of order
 * > different for each project
 */
function writeOrderEndingManifestPage(
  project: Project,
  pageIndex: number,
  orderSuffix: string
): string {
  if (IsAlbumEditor() && orderSuffix !== ORDER_SUFFIX.COVER)
    return writeAlbumManifestPage(project, pageIndex);
  if (IsCalendarEditor())
    return writeCalendarManifestPage(project, pageIndex + 1); // add a new page at the end
  if (IsCardEditor()) return writeCardsManifestPage(project, pageIndex + 1); // add a new page at the end
  return null;
}

/**
 * ALBUM SPECIAL MANIFEST PAGE
 */
function writeAlbumManifestPage(project: Project, pageIndex: number): string {
  let pagestr = '';

  const border: number = GetPrintCutBorderInPixel(project);
  const pagewidth: number = project.width; // OrderManager.pixelToPoint( project.getPageBounds().width );
  const pageheight: number = project.height; // OrderManager.pixelToPoint( project.getPageBounds().height );

  // white page before manifest page, manifest page will be recto of this one
  pagestr += 'page=begin';
  pagestr += `&number=${pageIndex.toString()}`;
  pagestr += `&cutborder=${border.toString()}`;
  pagestr += `&width=${pagewidth.toString()}`;
  pagestr += `&height=${pageheight.toString()}`;
  pagestr += '&impose=1n';
  pagestr += '\n';
  pagestr += 'frame=begin';

  const leftoff: number = 0 - (18.0 + mmToPoint(3.5));
  pagestr += `&left=${leftoff.toFixed(6)}`;
  pagestr += `&top=${(pageheight / 2).toFixed(6)}`;
  pagestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
  pagestr += '&justify=left';
  pagestr += '&orientate=east';
  pagestr += '&fontname=Helvetica';
  pagestr += '&fontsize=18.0';
  pagestr += '&framedir=none';
  pagestr += `&text={orderid} - ${pageIndex.toString()}`;
  pagestr += '\n';
  pagestr += 'frame=end\n';
  pagestr += 'page=end';
  pagestr += `&number=${pageIndex.toString()}`;
  pagestr += '\n';
  pageIndex++;

  // manifest page
  pagestr += 'page=begin';
  pagestr += `&number=${pageIndex.toString()}`;
  pagestr += `&cutborder=${border.toString()}`;
  pagestr += `&width=${pagewidth.toString()}`;
  pagestr += `&height=${pageheight.toString()}`;
  pagestr += '&impose=2n';
  pagestr += '\n';
  pagestr += writeAManifestBlock(project, pagewidth, pageheight, pageIndex);
  pagestr += 'page=end';
  pagestr += `&number=${pageIndex.toString()}`;
  pagestr += '\n';

  return pagestr;
}

/**
 * WRITE MANIFEST CONTENT block (a frame that can be added on a page)
 * > barcode info
 * > vendor name infos
 * > and other product infos.
 * IT's written in the write manifest special page(album) or in another page (header for calendar)
 */
function writeAManifestBlock(
  project: Project,
  pagewidth: number,
  pageheight: number,
  pageIndex: number
): string {
  if (IsAlbumEditor())
    return writeAlbumManifestBlock(project, pagewidth, pageheight, pageIndex);
  if (IsCalendarEditor())
    return writeCalendarManifestBlock(
      project,
      pagewidth,
      pageheight,
      pageIndex
    );
  if (IsCardEditor())
    return writeACardsManifestBlock(project, pagewidth, pageheight, pageIndex);
  return '';
}

/**
 * ALBUM MANIFEST CONTENT
 */
function writeAlbumManifestBlock(
  project: Project,
  pagewidth: number,
  pageheight: number,
  pageIndex: number
): string {
  let framestr = '';
  const border: number = GetPrintCutBorderInPixel(project);
  const pcenter: number = border + pageheight / 2;
  const bccenter: number = pagewidth / 2;
  const pagecount: number = project.pageList.length - 1; // display amount of pages but do not take cover into count

  // Tictac logo
  framestr = 'frame=begin';
  framestr += `&left=${(bccenter - 97).toFixed(6)}`;
  framestr += `&top=${(pcenter - 200).toFixed(6)}`;
  framestr += '&width=194';
  framestr += '&height=120';
  framestr += '&rleft=0';
  framestr += '&rtop=0';
  framestr += '&rwidth=194';
  framestr += '&rheight=120';
  framestr += '&cleft=0.000000&ctop=0.000000&cright=0.000000&cbottom=0.000000';
  framestr += '&rotation=0.000000';
  framestr += '&bleft=0.000000&btop=0.000000&bright=0.000000&bbottom=0.000000';
  framestr +=
    '&orientate=north&fitmethod=meet&framedir=resource&filelocation={manifestlogo}';
  framestr += '\n';
  framestr += 'frame=end\n';

  // barcode num
  framestr += 'frame=begin';
  framestr += `&left=${bccenter.toFixed(6)}`;
  framestr += `&top=${(pcenter - 75).toFixed(6)}`;
  framestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
  framestr += '&justify=center';
  framestr += '&fontname=Code-39-25';
  framestr += '&fontsize=24.0';
  framestr += '&framedir=none';
  framestr += '&text={barcodenumber}';
  framestr += '\n';
  framestr += 'frame=end\n';

  // barcode text
  framestr += 'frame=begin';
  framestr += `&left=${bccenter.toFixed(6)}`;
  framestr += `&top=${(pcenter - 51).toFixed(6)}`;
  framestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
  framestr += '&justify=center';
  framestr += '&fontname=Helvetica';
  framestr += '&fontsize=12.0';
  framestr += '&framedir=none';
  framestr += '&text={barcodetext}';
  framestr += '\n';
  framestr += 'frame=end\n';

  // vendor name
  framestr += 'frame=begin';
  framestr += `&left=${bccenter.toFixed(6)}`;
  framestr += `&top=${(pcenter - 39).toFixed(6)}`;
  framestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
  framestr += '&justify=center';
  framestr += '&fontname=Helvetica';
  framestr += '&fontsize=12.0';
  framestr += '&framedir=none';
  framestr += '&text={vendorname}';

  // product detail and page count
  framestr += '\n';
  framestr += 'frame=end\n';
  framestr += 'frame=begin';
  framestr += `&left=${bccenter.toFixed(6)}`;
  framestr += `&top=${(pcenter - 27).toFixed(6)}`;
  framestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
  framestr += '&justify=center';
  framestr += '&fontname=Helvetica';
  framestr += '&fontsize=12.0';
  framestr += '&framedir=none';
  framestr += '&text=(';
  framestr += pagecount.toString();
  framestr += '), {productinfo}';
  framestr += '\n';
  framestr += 'frame=end\n';

  // more info
  framestr += 'frame=begin';
  framestr += `&left=${bccenter.toFixed(6)}`;
  framestr += `&top=${(pcenter - 15).toFixed(6)}`;
  framestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
  framestr += '&justify=center';
  framestr += '&fontname=Helvetica';
  framestr += '&fontsize=12.0';
  framestr += '&framedir=none';
  framestr += '&text={moreinfo}';
  framestr += '\n';
  framestr += 'frame=end\n';

  // extra infos
  framestr += 'frame=begin';
  framestr += `&left=${bccenter.toFixed(6)}`;
  framestr += `&top=${(pcenter - 1).toFixed(6)}`;
  framestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
  framestr += '&justify=center';
  framestr += '&fontname=Helvetica';
  framestr += '&fontsize=12.0';
  framestr += '&framedir=none';
  framestr += '&text={extrainfo}';
  framestr += '\n';
  framestr += 'frame=end\n';

  // cover classic detail
  if (!HasCustomCover(project)) {
    framestr += 'frame=begin';
    framestr += `&left=${bccenter.toFixed(6)}`;
    framestr += `&top=${(pcenter + 11).toFixed(6)}`;
    framestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
    framestr += '&justify=center';
    framestr += '&fontname=Helvetica';
    framestr += '&fontsize=12.0';
    framestr += '&framedir=none';
    framestr += '&text={fontinfo}';
    framestr += '\n';
    framestr += 'frame=end\n';
    framestr += 'frame=begin';
    framestr += `&left=${bccenter.toFixed(6)}`;
    framestr += `&top=${(pcenter + 23).toFixed(6)}`;
    framestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
    framestr += '&justify=center';
    framestr += '&fontname=Helvetica';
    framestr += '&fontsize=12.0';
    framestr += '&urldecode=true';
    framestr += '&framedir=none';
    framestr += '&text={line1}';
    framestr += '\n';
    framestr += 'frame=end\n';
    framestr += 'frame=begin';
    framestr += `&left=${bccenter.toFixed(6)}`;
    framestr += `&top=${(pcenter + 35).toFixed(6)}`;
    framestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
    framestr += '&justify=center';
    framestr += '&fontname=Helvetica';
    framestr += '&fontsize=12.0';
    framestr += '&urldecode=true';
    framestr += '&framedir=none';
    framestr += '&text={line2}';
    framestr += '\n';
    framestr += 'frame=end\n';
    framestr += 'frame=begin';
    framestr += `&left=${bccenter.toFixed(6)}`;
    framestr += `&top=${(pcenter + 47).toFixed(6)}`;
    framestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
    framestr += '&justify=center';
    framestr += '&fontname=Helvetica';
    framestr += '&fontsize=12.0';
    framestr += '&urldecode=true';
    framestr += '&framedir=none';
    framestr += '&text={spine}';
    framestr += '\n';
    framestr += 'frame=end\n';
    framestr += 'frame=begin';
    framestr += `&left=${bccenter.toFixed(6)}`;
    framestr += `&top=${(pcenter + 59).toFixed(6)}`;
    framestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
    framestr += '&justify=center';
    framestr += '&fontname=Helvetica';
    framestr += '&fontsize=12.0';
    framestr += '&framedir=none';
    framestr += '&text={edition}';
    framestr += '\n';
    framestr += 'frame=end\n';
  }

  // add thumb preview of first page
  framestr += getManifestPreviewPages(project, bccenter, pcenter + 15); // pageheight - 100);

  framestr += 'frame=begin';
  const leftoff: number = 0 - (18.0 + mmToPoint(3.5));
  framestr += `&left=${leftoff.toFixed(6)}`;
  framestr += `&top=${(pageheight / 2).toFixed(6)}`;
  framestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
  framestr += '&justify=left';
  framestr += '&orientate=east';
  framestr += '&fontname=Helvetica';
  framestr += '&fontsize=18.0';
  framestr += '&framedir=none';
  framestr += `&text={orderid} - ${pageIndex.toString()}`; // +orderXML.PageNumberAppend; // TODO : check what this is
  framestr += '\n';
  framestr += 'frame=end\n';

  return framestr;
}

/**
 * write a cover manifest
 */
function writeACoverManifest(
  project: Project,
  page: IPage,
  JobID: string
): string {
  let framestr: string = null;

  const cutBorder: number = GetPrintCutBorderInPixel(project, true);

  const pagecount: number = project.pageList.length - 1; // display 30 and not 31 for ex (do not take cover in account)
  const pagewidth: number = GetCoverSizeInPixel(project).width + cutBorder * 2;
  const top: number = 0 - cutBorder + mmToPoint(12);
  // var pageheight:number = 5
  const bccenter: number = pagewidth / 4 - cutBorder;
  // var bleedpixels:number = border + MeasureManager.PFLToPixel(2, true) - 1;
  framestr = 'frame=begin';

  // WITH CONDITION FOR CASUAL
  if (project.type === ALBUM_TYPES.CASUAL) {
    /*
        framestr += "&orientate=west";
        framestr += "&left="+(border-5).toFixed(6);
        framestr += "&top="+(project.getCoverBounds().height / 2).toFixed(6);
        framestr += OrderManager.getCMYKColorStringFromRGB("fillcolor", 0xFFFFFF);
        framestr += "&fill=true";
        */
    framestr += '&orientate=south';
    framestr += `&left=${bccenter.toFixed(6)}`;
    // framestr += "&top="+(project.getCoverBounds().height / 2).toFixed(6);
    framestr += '&top=-13'; // fontsize is 12; //5*Mea...;
    framestr += PrintColorHelper.getCMYKColorString('fillcolor', '#ffffff');
    framestr += '&fill=true';
  } else {
    framestr += '&orientate=south'; // TODO : check why it's south
    framestr += `&left=${bccenter.toFixed(6)}`;
    framestr += `&top=${top.toFixed(6)}`;
    // framestr += "&top="+PagesManager.instance.getCoverBackgroundBounds().height.toFixed(6);
  }

  framestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
  framestr += '&justify=center';
  framestr += '&fontname=Helvetica';
  framestr += '&fontsize=12.0';
  framestr += '&framedir=none';
  framestr += '&text=';
  framestr += `${JobID}-{orderid}-{totalqty}-${pagecount.toString()}`;
  if (project.paper) {
    framestr += 'I';
  }

  // add varnish info for casuals only
  if (project.coated && project.type === ALBUM_TYPES.CASUAL) {
    framestr += '-varnish';
  }

  // add cover finish
  framestr += `-coverMatte=${project.cover_matte ? 'YES' : 'NO'}`;

  framestr += '\n';
  framestr += 'frame=end\n';

  return framestr;
}

/**
 * Get the index of the page that needs to be used to preview album
 */
function getPreviewPageIndex(project: Project): number {
  const isClassicCoverAlbum = !!GetClassicCoverOptions(project);
  return isClassicCoverAlbum ? 1 : 0;
}

/** ////////////////////////////////////////////////////////////
 *  CALENDAR SPECIFIC
 * //////////////////////////////////////////////////////////// */

/**
 * CALENDAR SPECIAL MANIFEST PAGE
 */
function writeCalendarManifestPage(
  project: Project,
  pageIndex: number
): string {
  let pagestr: string = null;
  const border = GetPrintCutBorderInPixel(project);
  const pageWidth = project.width;
  const pageHeight = project.height;

  pagestr = 'page=begin';
  pagestr += `&number=${pageIndex.toString()}`;
  pagestr += `&cutborder=${border.toFixed(6)}`;
  pagestr += `&width=${pageWidth.toFixed(6)}`;
  pagestr += `&height=${pageHeight.toFixed(6)}`;
  pagestr += '&impose=2n';
  pagestr += '\n';
  pagestr += writeAManifestBlock(project, pageWidth, pageHeight, pageIndex);
  pagestr += 'page=end';
  pagestr += `&number=${pageIndex.toString()}`;
  pagestr += '\n';

  return pagestr;
}

/**
 * WRITE CALENDEAR MANIFEST Frames
 * */
function writeCalendarManifestBlock(
  project: Project,
  pagewidth: number,
  pageheight: number,
  pageIndex: number
): string {
  const thumbsize = 120;
  const border: number = GetPrintCutBorderInPixel(project);
  const pcenter: number = pageheight / 2;
  const bccenter: number = pagewidth / 2;

  let framestr = 'frame=begin';
  framestr += `&left=${(bccenter - 97).toFixed(6)}`;
  framestr += `&top=${(pcenter - 200).toFixed(6)}`;
  framestr += '&width=194';
  framestr += '&height=120';
  framestr += '&rleft=0';
  framestr += '&rtop=0';
  framestr += '&rwidth=194';
  framestr += '&rheight=120';
  framestr += '&cleft=0.000000&ctop=0.000000&cright=0.000000&cbottom=0.000000';
  framestr += '&rotation=0.000000';
  framestr += '&bleft=0.000000&btop=0.000000&bright=0.000000&bbottom=0.000000';
  framestr +=
    '&orientate=north&fitmethod=meet&framedir=resource&filelocation={manifestlogo}';
  framestr += '\n';
  framestr += 'frame=end\n';
  framestr += 'frame=begin';
  framestr += `&left=${bccenter.toFixed(6)}`;
  framestr += `&top=${(pcenter - 75).toFixed(6)}`;
  framestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
  framestr += '&justify=center';
  framestr += '&fontname=Code-39-25';
  framestr += '&fontsize=24.0';
  framestr += '&framedir=none';
  framestr += '&text={barcodenumber}';
  framestr += '\n';
  framestr += 'frame=end\n';
  framestr += 'frame=begin';
  framestr += `&left=${bccenter.toFixed(6)}`;
  framestr += `&top=${(pcenter - 51).toFixed(6)}`;
  framestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
  framestr += '&justify=center';
  framestr += '&fontname=Helvetica';
  framestr += '&fontsize=12.0';
  framestr += '&framedir=none';
  framestr += '&text={barcodetext}';
  framestr += '\n';
  framestr += 'frame=end\n';
  framestr += 'frame=begin';
  framestr += `&left=${bccenter.toFixed(6)}`;
  framestr += `&top=${(pcenter - 39).toFixed(6)}`;
  framestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
  framestr += '&justify=center';
  framestr += '&fontname=Helvetica';
  framestr += '&fontsize=12.0';
  framestr += '&framedir=none';
  framestr += '&text={vendorname}';
  framestr += '\n';
  framestr += 'frame=end\n';
  framestr += 'frame=begin';
  framestr += `&left=${bccenter.toFixed(6)}`;
  framestr += `&top=${(pcenter - 27).toFixed(6)}`;
  framestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
  framestr += '&justify=center';
  framestr += '&fontname=Helvetica';
  framestr += '&fontsize=12.0';
  framestr += '&framedir=none';
  framestr += '&text={productinfo}';
  framestr += '\n';
  framestr += 'frame=end\n';
  framestr += 'frame=begin';
  framestr += `&left=${bccenter.toFixed(6)}`;
  framestr += `&top=${(pcenter - 15).toFixed(6)}`;
  framestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
  framestr += '&justify=center';
  framestr += '&fontname=Helvetica';
  framestr += '&fontsize=12.0';
  framestr += '&framedir=none';
  framestr += '&text={moreinfo}';
  framestr += '\n';
  framestr += 'frame=end\n';

  framestr += 'frame=begin';
  framestr += `&left=${bccenter.toFixed(6)}`;
  framestr += `&top=${(pcenter - 1).toFixed(6)}`;
  framestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
  framestr += '&justify=center';
  framestr += '&fontname=Helvetica';
  framestr += '&fontsize=12.0';
  framestr += '&framedir=none';
  framestr += '&text={extrainfo}';
  framestr += '\n';
  framestr += 'frame=end\n';

  // add thumb preview of first page
  framestr += getManifestPreviewPages(project, bccenter, pcenter + 20);

  // TODO : thumb preview
  /*
 if(_thumbCounter > 0) {
  framestr += "frame=begin";
  var scale1:number = pagewidth / pageheight;
  var height1:number = thumbsize;
  var width1:number = thumbsize;
  framestr += "&top="+(pcenter + border).toFixed(6);
  if(pagewidth > pageheight) {
   height1 = thumbsize / scale1;
   framestr += "&left="+(bccenter - (border / 2) - thumbsize).toFixed(6);
   framestr += "&width="+thumbsize.toFixed(6);
   framestr += "&height="+height1.toFixed(6);
  }
  else {
   width1 = thumbsize * scale1;
   framestr += "&left="+(bccenter - (border / 2) - width1).toFixed(6);
   framestr += "&width="+width1.toFixed(6);
   framestr += "&height="+thumbsize.toFixed(6);
  }
  framestr += "&rleft=0";
  framestr += "&rtop=0";
  framestr += "&rwidth="+width1.toFixed(6);
  framestr += "&rheight="+height1.toFixed(6);
  framestr += "&cleft=0.000000&ctop=0.000000&cright=0.000000&cbottom=0.000000";
  framestr += "&rotation=0.000000";
  framestr += "&bleft=0.000000&btop=0.000000&bright=0.000000&bbottom=0.000000";
  framestr += "&stroke=true&strokecolorspace=cmyk&strokecolor=0.850000,0.850000,0.850000,1.000000";
  framestr += "&orientate=north&fitmethod=meet&framedir=upload&filelocation=thumb1.jpg";
  framestr += "\n";
  framestr += "frame=end\n";
 }
 */

  // TODO : thumb preview
  /*
 if(_thumbCounter > 1) {
  framestr += "frame=begin";
  var scale2:number = pagewidth / pageheight;
  var height2:number = thumbsize;
  var width2:number = thumbsize;
  framestr += "&top="+(pcenter + border).toFixed(6);
  if(pagewidth > pageheight) {
   height2 = thumbsize / scale2;
   framestr += "&left="+(bccenter + (border / 2)).toFixed(6);
   framestr += "&width="+thumbsize.toFixed(6);
   framestr += "&height="+height2.toFixed(6);
  }
  else {
   width2 = thumbsize * scale2;
   framestr += "&left="+(bccenter + (border / 2)).toFixed(6);
   framestr += "&width="+width2.toFixed(6);
   framestr += "&height="+thumbsize.toFixed(6);
  }
  framestr += "&rleft=0";
  framestr += "&rtop=0";
  framestr += "&rwidth="+width2.toFixed(6);
  framestr += "&rheight="+height2.toFixed(6);
  framestr += "&cleft=0.000000&ctop=0.000000&cright=0.000000&cbottom=0.000000";
  framestr += "&rotation=0.000000";
  framestr += "&bleft=0.000000&btop=0.000000&bright=0.000000&bbottom=0.000000";
  framestr += "&stroke=true&strokecolorspace=cmyk&strokecolor=0.850000,0.850000,0.850000,1.000000";
  framestr += "&orientate=north&fitmethod=meet&framedir=upload&filelocation=thumb2.jpg";
  framestr += "\n";
  framestr += "frame=end\n";
 }
 */

  framestr += 'frame=begin';
  const leftoff: number = 0 - (18.0 + mmToPoint(3.5));
  framestr += `&left=${leftoff.toFixed(6)}`;
  framestr += `&top=${(pageheight / 2).toFixed(6)}`;
  framestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
  framestr += '&justify=left';
  framestr += '&orientate=east';
  framestr += '&fontname=Helvetica';
  framestr += '&fontsize=18.0';
  framestr += '&framedir=none';
  framestr += `&text={orderid} - ${pageIndex.toString()}`; // +orderXML.PageNumberAppend; // TODO : check what this is
  framestr += '\n';
  framestr += 'frame=end\n';
  return framestr;
}

function getManifestPreviewPages(
  project: Project,
  left: number,
  top: number
): string {
  const previewPageIndex: number = getPreviewPageIndex(project);
  const page: IPage = project.pageList[previewPageIndex];

  // do not print page preview if there is only one page..
  if (project.pageList.length < 2) return '';

  // if(Infos.project.classname === ProductsCatalogue.CLASS_ALBUM && !Infos.project.type === ProductsCatalogue.ALBUM_CLASSIC)
  const thumbsize = 120;
  const pagewidth: number = page.width;
  const pageheight: number = page.height;
  // var pagewidth:number =  MeasureManager.PFLToPixel( Infos.project.width ); // projectXML.DocWidth * PFLToPoints; // TODO : should be PFLTopoint if point was not the same as pixel in project
  // var pageheight:number = MeasureManager.PFLToPixel( Infos.project.height ); // projectXML.DocHeight * PFLToPoints;

  let framestr = 'frame=begin';
  const scale1: number = pagewidth / pageheight;
  let height1: number = thumbsize;
  let width1: number = thumbsize;

  if (pagewidth > pageheight) {
    height1 = thumbsize / scale1;
    framestr += `&left=${(left - thumbsize / 2).toFixed(6)}`;
    framestr += `&top=${(top + height1 / 2).toFixed(6)}`;
    framestr += `&width=${thumbsize.toFixed(6)}`;
    framestr += `&height=${height1.toFixed(6)}`;
  } else {
    width1 = thumbsize * scale1;
    framestr += `&left=${(left - width1 / 2).toFixed(6)}`;
    framestr += `&top=${(top + thumbsize / 2).toFixed(6)}`;
    framestr += `&width=${width1.toFixed(6)}`;
    framestr += `&height=${thumbsize.toFixed(6)}`;
  }
  framestr += '&rleft=0';
  framestr += '&rtop=0';
  framestr += `&rwidth=${width1.toFixed(6)}`;
  framestr += `&rheight=${height1.toFixed(6)}`;
  framestr += '&cleft=0.000000&ctop=0.000000&cright=0.000000&cbottom=0.000000';
  framestr += '&rotation=0.000000';
  framestr += '&bleft=0.000000&btop=0.000000&bright=0.000000&bbottom=0.000000';
  framestr +=
    '&stroke=true&strokecolorspace=cmyk&strokecolor=0.850000,0.850000,0.850000,1.000000';
  framestr += `&orientate=north&fitmethod=meet&framedir=upload&filelocation=${getOrderFileLocation(
    null,
    0,
    0,
    false,
    false,
    previewPageIndex
  )}`;
  framestr += '\n';
  framestr += 'frame=end\n';

  return framestr;
}

/** ////////////////////////////////////////////////////////////
 *  CARDS SPECIFIC
 * //////////////////////////////////////////////////////////// */

/**
 * CARDS SPECIAL MANIFEST PAGE
 */
function writeCardsManifestPage(project: Project, pageIndex: number): string {
  const border: number = GetPrintCutBorderInPixel(project);
  const pagewidth: number = project.width; // projectXML.DocWidth * PFLToPoints; // TODO : should be PFLTopoint if point was not the same as pixel in project
  const pageheight: number = project.height; // projectXML.DocHeight * PFLToPoints;
  const leftoff: number = 0 - (18.0 + mmToPoint(3.5)); // 0 - (18.0 + (3.5 * MMToPoints));

  let pagestr = 'page=begin';
  pagestr += `&number=${pageIndex.toString()}`;
  pagestr += `&cutborder=${border.toString()}`;
  pagestr += `&width=${pagewidth.toString()}`;
  pagestr += `&height=${pageheight.toString()}`;
  pagestr += '&impose=1n';
  pagestr += '\n';
  pagestr += 'frame=begin';
  pagestr += `&left=${leftoff.toFixed(6)}`;
  pagestr += `&top=${(pageheight / 2).toFixed(6)}`;
  pagestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
  pagestr += '&justify=left';
  pagestr += '&orientate=east';
  pagestr += '&fontname=Helvetica';
  pagestr += '&fontsize=18.0';
  pagestr += '&framedir=none';
  pagestr += `&text={orderid} - ${pageIndex.toString()}`;
  pagestr += '\n';
  pagestr += 'frame=end\n';
  pagestr += 'page=end';
  pagestr += `&number=${pageIndex.toString()}`;
  pagestr += '\n';
  pageIndex++;

  if (GetDoc(project.docID).pages_per_group === 4) {
    pagestr += 'page=begin';
    pagestr += `&number=${pageIndex.toString()}`;
    pagestr += `&cutborder=${border.toString()}`;
    pagestr += `&width=${pagewidth.toString()}`;
    pagestr += `&height=${pageheight.toString()}`;
    pagestr += '&impose=2n';
    pagestr += '\n';
    pagestr += 'frame=begin';
    pagestr += `&left=${leftoff.toFixed(6)}`;
    pagestr += `&top=${(pageheight / 2).toFixed(6)}`;
    pagestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
    pagestr += '&justify=left';
    pagestr += '&orientate=east';
    pagestr += '&fontname=Helvetica';
    pagestr += '&fontsize=18.0';
    pagestr += '&framedir=none';
    pagestr += `&text={orderid} - ${pageIndex.toString()}`;
    pagestr += '\n';
    pagestr += 'frame=end\n';
    pagestr += 'page=end';
    pagestr += `&number=${pageIndex.toString()}`;
    pagestr += '\n';
    pageIndex++;
    pagestr += 'page=begin';
    pagestr += `&number=${pageIndex.toString()}`;
    pagestr += `&cutborder=${border.toString()}`;
    pagestr += `&width=${pagewidth.toString()}`;
    pagestr += `&height=${pageheight.toString()}`;
    pagestr += '&impose=1n';
    pagestr += '\n';
    pagestr += 'frame=begin';
    pagestr += `&left=${leftoff.toFixed(6)}`;
    pagestr += `&top=${(pageheight / 2).toFixed(6)}`;
    pagestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
    pagestr += '&justify=left';
    pagestr += '&orientate=east';
    pagestr += '&fontname=Helvetica';
    pagestr += '&fontsize=18.0';
    pagestr += '&framedir=none';
    pagestr += `&text={orderid} - ${pageIndex.toString()}`;
    pagestr += '\n';
    pagestr += 'frame=end\n';
    pagestr += 'page=end';
    pagestr += `&number=${pageIndex.toString()}`;
    pagestr += '\n';
    pageIndex++;
  }
  pagestr += 'page=begin';
  pagestr += `&number=${pageIndex.toString()}`;
  pagestr += `&cutborder=${border.toString()}`;
  pagestr += `&width=${pagewidth.toString()}`;
  pagestr += `&height=${pageheight.toString()}`;
  pagestr += '&impose=2n';
  pagestr += '\n';
  pagestr += writeAManifestBlock(project, pagewidth, pageheight, pageIndex);
  pagestr += 'page=end';
  pagestr += `&number=${pageIndex.toString()}`;
  pagestr += '\n';
  return pagestr;
}

/**
 * cardw manifest block
 */
function writeACardsManifestBlock(
  project: Project,
  pagewidth: number,
  pageheight: number,
  pageIndex: number
): string {
  const border: number = GetPrintCutBorderInPixel(project); // project.docCutBorder * MeasureManager.mmToPoint;//projectXML.DocCutBorder * MMToPoints;
  const pcenter: number = pageheight / 2; // border + 200;
  const bccenter: number = pagewidth / 2;
  let framestr = 'frame=begin';
  framestr += `&left=${(bccenter - 97).toFixed(6)}`;
  framestr += `&top=${(pcenter - 200).toFixed(6)}`;
  framestr += '&width=194';
  framestr += '&height=120';
  framestr += '&rleft=0';
  framestr += '&rtop=0';
  framestr += '&rwidth=194';
  framestr += '&rheight=120';
  framestr += '&cleft=0.000000&ctop=0.000000&cright=0.000000&cbottom=0.000000';
  framestr += '&rotation=0.000000';
  framestr += '&bleft=0.000000&btop=0.000000&bright=0.000000&bbottom=0.000000';
  framestr +=
    '&orientate=north&fitmethod=meet&framedir=resource&filelocation={manifestlogo}';
  framestr += '\n';
  framestr += 'frame=end\n';
  framestr += 'frame=begin';
  framestr += `&left=${bccenter.toFixed(6)}`;
  framestr += `&top=${(pcenter - 75).toFixed(6)}`;
  framestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
  framestr += '&justify=center';
  framestr += '&fontname=Code-39-25';
  framestr += '&fontsize=24.0';
  framestr += '&framedir=none';
  framestr += '&text={barcodenumber}';
  framestr += '\n';
  framestr += 'frame=end\n';
  framestr += 'frame=begin';
  framestr += `&left=${bccenter.toFixed(6)}`;
  framestr += `&top=${(pcenter - 51).toFixed(6)}`;
  framestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
  framestr += '&justify=center';
  framestr += '&fontname=Helvetica';
  framestr += '&fontsize=12.0';
  framestr += '&framedir=none';
  framestr += '&text={barcodetext}';
  framestr += '\n';
  framestr += 'frame=end\n';
  framestr += 'frame=begin';
  framestr += `&left=${bccenter.toFixed(6)}`;
  framestr += `&top=${(pcenter - 39).toFixed(6)}`;
  framestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
  framestr += '&justify=center';
  framestr += '&fontname=Helvetica';
  framestr += '&fontsize=12.0';
  framestr += '&framedir=none';
  framestr += '&text={vendorname}';
  framestr += '\n';
  framestr += 'frame=end\n';
  framestr += 'frame=begin';
  framestr += `&left=${bccenter.toFixed(6)}`;
  framestr += `&top=${(pcenter - 27).toFixed(6)}`;
  framestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
  framestr += '&justify=center';
  framestr += '&fontname=Helvetica';
  framestr += '&fontsize=12.0';
  framestr += '&framedir=none';
  framestr += '&text={productinfo}';
  framestr += '\n';
  framestr += 'frame=end\n';
  framestr += 'frame=begin';
  framestr += `&left=${bccenter.toFixed(6)}`;
  framestr += `&top=${(pcenter - 15).toFixed(6)}`;
  framestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
  framestr += '&justify=center';
  framestr += '&fontname=Helvetica';
  framestr += '&fontsize=12.0';
  framestr += '&framedir=none';
  framestr += '&text={moreinfo}';
  framestr += '\n';
  framestr += 'frame=end\n';

  framestr += 'frame=begin';
  framestr += `&left=${bccenter.toFixed(6)}`;
  framestr += `&top=${(pcenter - 1).toFixed(6)}`;
  framestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
  framestr += '&justify=center';
  framestr += '&fontname=Helvetica';
  framestr += '&fontsize=12.0';
  framestr += '&framedir=none';
  framestr += '&text={extrainfo}';
  framestr += '\n';
  framestr += 'frame=end\n';

  // add thumb preview of first page
  framestr += getManifestPreviewPages(project, bccenter, pcenter - 10);

  /*
 if(isThumbLoaded) {
  framestr += "frame=begin";
  framestr += "&left="+(bccenter - (border / 2)).toFixed(6);
  framestr += "&top="+(pageheight - (border * 1.5)).toFixed(6);
  framestr += "&width="+border.toFixed(6);
  framestr += "&height="+border.toFixed(6);
  var scale:number = pagewidth / pageheight;
  if(pagewidth > pageheight) {
   var height:number = border / scale;
   framestr += "&rleft=0";
   framestr += "&rtop="+(height / 2).toFixed(6);
   framestr += "&rwidth="+border.toFixed(6);
   framestr += "&rheight="+height.toFixed(6);
  }
  else {
   var width:number = border * scale;
   framestr += "&rleft="+(width / 2).toFixed(6);
   framestr += "&rtop=0";
   framestr += "&rwidth="+width.toFixed(6);
   framestr += "&rheight="+border.toFixed(6);
  }
  framestr += "&cleft=0.000000&ctop=0.000000&cright=0.000000&cbottom=0.000000";
  framestr += "&rotation=0.000000";
  framestr += "&bleft=0.000000&btop=0.000000&bright=0.000000&bbottom=0.000000";
  framestr += "&orientate=north&fitmethod=entire&framedir=upload&filelocation=thumb0.jpg";
  framestr += "\n";
  framestr += "frame=end\n";
 }
 */

  framestr += 'frame=begin';
  const leftoff: number = 0 - (18.0 + mmToPoint(5.0)); // 0 - (18.0 + (5.0 * MMToPoints));
  framestr += `&left=${leftoff.toFixed(6)}`;
  framestr += `&top=${(pageheight / 2).toFixed(6)}`;
  framestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
  framestr += '&justify=left';
  framestr += '&orientate=east';
  framestr += '&fontname=Helvetica';
  framestr += '&fontsize=18.0';
  framestr += '&framedir=none';
  framestr += `&text={orderid} - ${pageIndex.toString()}`;
  framestr += '\n';
  framestr += 'frame=end\n';
  return framestr;
}

/**
 * CANVAS manifest block
 */
function writeACanvasManifestBlock(
  project: Project,
  _pageIndex: number
): string {
  const border: number = GetPrintCutBorderInPixel(project);
  let leftoffset = 100;

  const IsLargeFormat: boolean = CanvasHelper.GetCodeModifier(project.type) > 0; // TODO : check this -- EDIT : it looks like it's not used in old online
  if (IsLargeFormat) {
    leftoffset = 50;
  }
  const pagewidth: number = project.width + border * 2;
  const pageheight: number = project.height + border;
  const bccenter: number = pagewidth / 2 - border;
  const pageIndex: number = _pageIndex; // Page(pagedata.layout).pageIndex - 1;
  let framestr = 'frame=begin';
  framestr += `&left=${(bccenter - (120 + leftoffset)).toFixed(6)}`;
  framestr += `&top=${(pageheight - 37).toFixed(6)}`;
  framestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
  framestr += '&justify=center';
  framestr += '&fontname=Helvetica';
  framestr += '&fontsize=12.0';
  framestr += '&framedir=none';
  framestr += '&text=';
  // framestr += "" + pageIndex + "/" + totalPages; //Page(pagedata.layout).pageIndex.toString()+"/"+ActualPageCount.toString();
  framestr += `${pageIndex}/${project.pageList.length}`; // Page(pagedata.layout).pageIndex.toString()+"/"+ActualPageCount.toString();
  framestr += '\n';
  framestr += 'frame=end\n';

  if (project.type === CANVAS_TYPES.KADAPAK) {
    framestr += 'frame=begin';
    framestr += `&left=${(bccenter - (150 + leftoffset)).toFixed(6)}`;
    framestr += `&top=${(pageheight - 18).toFixed(6)}`;
    framestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
    framestr += '&justify=left';
    framestr += '&fontname=Helvetica';
    framestr += '&fontsize=10.0';
    framestr += '&framedir=none';
    framestr += `&text=frame ${project.canvasFrameColor}`;
    framestr += '\n';
    framestr += 'frame=end\n';
  }

  const isThumbLoaded = false; // TODO: change probably this with real info of miniature
  if (IsLargeFormat && isThumbLoaded) {
    framestr += 'frame=begin';
    framestr += `&left=${(bccenter - border / 4).toFixed(6)}`;
    framestr += `&top=${(pageheight - (border / 4) * 3).toFixed(6)}`;
    framestr += `&width=${(border / 2).toFixed(6)}`;
    framestr += `&height=${(border / 2).toFixed(6)}`;
    const scale: number = project.width / project.height; // var scale:number = Page(pagedata.layout).PageWidth / Page(pagedata.layout).PageHeight;

    if (project.width > project.height) {
      // if(Page(pagedata.layout).PageWidth > Page(pagedata.layout).PageHeight) {
      const height: number = border / 2 / scale;
      framestr += '&rleft=0';
      framestr += `&rtop=${(height / 2).toFixed(6)}`;
      framestr += `&rwidth=${(border / 2).toFixed(6)}`;
      framestr += `&rheight=${height.toFixed(6)}`;
    } else {
      const width: number = (border / 2) * scale;
      framestr += `&rleft=${(width / 2).toFixed(6)}`;
      framestr += '&rtop=0';
      framestr += `&rwidth=${width.toFixed(6)}`;
      framestr += `&rheight=${(border / 2).toFixed(6)}`;
    }
    framestr +=
      '&cleft=0.000000&ctop=0.000000&cright=0.000000&cbottom=0.000000';
    framestr += '&rotation=0.000000';
    framestr +=
      '&bleft=0.000000&btop=0.000000&bright=0.000000&bbottom=0.000000';
    framestr += `&orientate=north&fitmethod=entire&framedir=upload&filelocation=thumb${pageIndex.toString()}.jpg`;
    framestr += '\n';
    framestr += 'frame=end\n';
  } else {
    framestr += 'frame=begin';
    framestr += `&left=${(bccenter - (leftoffset - 16)).toFixed(6)}`;
    framestr += `&top=${(pageheight - 110).toFixed(6)}`;
    framestr += '&width=167';
    framestr += '&height=104';
    framestr += '&rleft=0';
    framestr += '&rtop=0';
    framestr += '&rwidth=167';
    framestr += '&rheight=104';
    framestr +=
      '&cleft=0.000000&ctop=0.000000&cright=0.000000&cbottom=0.000000';
    framestr += '&rotation=0.000000';
    framestr +=
      '&bleft=0.000000&btop=0.000000&bright=0.000000&bbottom=0.000000';
    framestr +=
      '&orientate=north&fitmethod=meet&framedir=resource&filelocation={manifestlogo}';
    framestr += '\n';
    framestr += 'frame=end\n';
  }

  framestr += 'frame=begin';
  framestr += `&left=${(bccenter - (40 + leftoffset)).toFixed(6)}`;
  framestr += `&top=${(pageheight - 40).toFixed(6)}`;
  framestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
  framestr += '&justify=center';
  framestr += '&fontname=Code-39-25';
  framestr += '&fontsize=24.0';
  framestr += '&framedir=none';
  framestr += '&text={barcodenumber}';
  framestr += '\n';
  framestr += 'frame=end\n';
  framestr += 'frame=begin';
  framestr += `&left=${(bccenter - (40 + leftoffset)).toFixed(6)}`;
  framestr += `&top=${(pageheight - 16).toFixed(6)}`;
  framestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
  framestr += '&justify=center';
  framestr += '&fontname=Helvetica';
  framestr += '&fontsize=8.0';
  framestr += '&framedir=none';
  framestr += '&text={barcodetext}';
  framestr += '\n';
  framestr += 'frame=end\n';
  framestr += 'frame=begin';
  framestr += `&left=${(bccenter + leftoffset).toFixed(6)}`;
  framestr += `&top=${(pageheight - 37).toFixed(6)}`;
  framestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
  framestr += '&justify=left';
  framestr += '&fontname=Helvetica';
  framestr += '&fontsize=8.0';
  framestr += '&framedir=none';
  framestr += '&text={vendorname}';
  framestr += '\n';
  framestr += 'frame=end\n';
  framestr += 'frame=begin';
  framestr += `&left=${(bccenter + leftoffset).toFixed(6)}`;
  framestr += `&top=${(pageheight - 26).toFixed(6)}`;
  framestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
  framestr += '&justify=left';
  framestr += '&fontname=Helvetica';
  framestr += '&fontsize=8.0';
  framestr += '&framedir=none';
  framestr += '&text={productinfo}';
  framestr += '\n';
  framestr += 'frame=end\n';
  framestr += 'frame=begin';
  framestr += `&left=${(bccenter + leftoffset).toFixed(6)}`;
  framestr += `&top=${(pageheight - 15).toFixed(6)}`;
  framestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
  framestr += '&justify=left';
  framestr += '&fontname=Helvetica';
  framestr += '&fontsize=8.0';
  framestr += '&framedir=none';
  framestr += '&text={moreinfo}';
  framestr += '\n';
  framestr += 'frame=end\n';

  framestr += 'frame=begin';
  framestr += `&left=${bccenter.toFixed(6)}`;
  framestr += `&top=${(pageheight - 1).toFixed(6)}`;
  framestr += PrintColorHelper.getCMYKColorString('textcolor', '#000000');
  framestr += '&justify=center';
  framestr += '&fontname=Helvetica';
  framestr += '&fontsize=12.0';
  framestr += '&framedir=none';
  framestr += '&text={extrainfo}';
  framestr += '\n';
  framestr += 'frame=end\n';
  return framestr;
}

// function getProjecttotalPages():number
// {
//     var pageCount :number = Infos.project.pageList.length;
//     if(Infos.isCards) return pageCount*CardsManager.instance.cardsRepeatValue;
//     return pageCount;
// }
