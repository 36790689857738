import React from 'react';
import { Icon, Popover, Spin } from 'antd';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { cloneDeep } from 'lodash';
import { CheckCircleTwoTone, CloudUploadOutlined } from '@ant-design/icons';
import type { Photo } from '../../../types/types';
import CustomContextMenu from '../../../_components/CustomContextMenu';
import { photoListActions } from '../../../feature/photoList/photoList';

interface PhotoItemProps {
  photo: Photo;
  used: boolean; // is the photo used?
  onDragStart: (e) => void;
  dispatch: () => void; // redux dispatch
}

/**
 * Photo item
 */
class PhotoItem extends React.Component<PhotoItemProps> {
  // --------------------- Constructor ------------------------

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      popOverVisible: false,
    };
  }

  // --------------------- react ------------------------

  shouldComponentUpdate(nextProps, nextState) {
    const selectionChange = nextProps.selected !== this.props.selected;
    const usedChange = nextProps.used !== this.props.used;
    const loadingChange = nextState.isLoading !== this.state.isLoading;
    const tempPhotoChange = !!nextProps.photo.temp; // && (nextProps.photo.width !==this.props.photo.width))? true : false;
    const popOverVisibilityChange =
      nextState.popOverVisible !== this.state.popOverVisible;

    // selected:PropTypes.bool,
    // page: PageType,
    // photos: PropTypes.arrayOf(PhotoType)
    return (
      selectionChange ||
      tempPhotoChange ||
      loadingChange ||
      usedChange ||
      popOverVisibilityChange
    );
    // return selectionChange;
  }

  // --------------------- Methods ------------------------

  handleLoadStart() {
    this.setState({
      isLoading: true,
    });
    // alert("loaded!");
  }

  handleLoadComplete(e) {
    this.setState({
      isLoading: false,
    });
    // alert("loaded!");
    if (!this.props.photo.width) {
      const tempPhoto = cloneDeep(this.props.photo);
      tempPhoto.width = e.target.naturalWidth;
      tempPhoto.height = e.target.naturalHeight;
      this.props.dispatch(photoListActions.UpdateTempPhotoSize(tempPhoto));
    }
  }

  getOverContent(item) {
    const highURL = item.temp ? item.temp_url : item.working_url;
    const w = 350;
    return (
      <div
        role="button"
        tabIndex="0"
        style={{
          width: w,
          maxWidth: w,
          wordWrap: 'break-word',
          fontSize: 12,
        }}
        onFocus={() => {}}
        onMouseOver={(e) => {
          this.handlePopoverVisibleChange(false);
        }}
        onDragOver={(e) => {
          this.handlePopoverVisibleChange(false);
        }}
      >
        <img width={w} height={(w * item.height) / item.width} src={highURL} />
        <h4>{`${item.name} (${item.width}x${item.height}px)`} </h4>
      </div>
    );
  }

  handlePopoverVisibleChange = (show) => {
    // console.log("coucou:" + show);
    this.setState({
      popOverVisible: show,
    });
    // console.log("coucou:" + JSON.stringify(this.state));
    this.render();
  };

  /// ///////////////////////////////////////////////////
  // Render
  /// ///////////////////////////////////////////////////
  render() {
    const { isLoading } = this.state;
    // console.log("RENDERING PHOTO ITEM");

    const item: Photo = this.props.photo;
    const imageUrl = item.error
      ? null
      : item.temp
      ? item.temp_url
      : item.thumb_url;
    const highURL = item.temp ? item.temp_url : item.working_url;
    const draggable = !!item.width;

    return (
      <div // className="pic"
        className={`photoItem unselectable ${
          !draggable ? 'photoItem_disabled' : ''
        }`}
        style={{
          // width:"50%", height:"50%",
          // backgroundColor:"#ffffff",
          // border:"1px dotted #b8e1f8",
          // margin: "2px",
          // maxWidth:"90px",
          // maxHeight:"90px",
          width: '90px',
          height: '90px',
          // textAlign: "center",
          // padding: "2px 0",
          // padding: "2px",
          // overflow: "hidden"
        }}
        // key={item.id}
        onDragStart={(e) => this.props.onDragStart(e, item)}
        // onDragOver= {(e)=> this.handleDragOver(e, key, item)}
        // onDrop= {(e)=> this.handleDrop(e, key, item)}

        // for now, we cannot drag images that are temporary..
        // draggable={true}
        draggable={draggable}
      >
        {
          // see doc of popover that extends the tooltip
          // https://gary-shen.github.io/ant-design/components/tooltip/
        }
        {/* <Tooltip placement="right" title={"id:"+item.id + "\nname:"+item.name}> */}
        <CustomContextMenu
          debugOnly
          items={[
            {
              label: 'Debug:Show Detail',
              key: 'debug1',
              callback: () => {
                alert(JSON.stringify(item));
              },
            },
          ]}
        >
          <Popover
            content={this.getOverContent(item)}
            open={this.state.popOverVisible}
            key={item.id}
            placement="right"
            trigger="hover"
            mouseEnterDelay={0.2}
            onOpenChange={this.handlePopoverVisibleChange}
          >
            {/* NOTE : TODO: SPIN IS A PERFORMANCE KILLER !!! <Spin spinning={isLoading}> */}

            {imageUrl && (
              <Spin spinning={isLoading}>
                <LazyLoadImage // className="photoPreview"
                  // style={{margin: "0px auto 0px auto"}}
                  // key={item.id}
                  threshold={100}
                  beforeLoad={(e) => {
                    this.handleLoadStart(e);
                  }}
                  //  effect="opacity"
                  style={{
                    width: '100%',
                    maxHeight: '70px',
                    height: '70px',
                    objectFit: 'contain',
                  }}
                  onLoad={(e) => {
                    this.handleLoadComplete(e);
                  }}
                  draggable={false}
                  src={imageUrl}
                />
              </Spin>
            )}

            {/* <img //className="photoPreview"
                        //style={{margin: "0px auto 0px auto"}}
                        // key={item.id}
                        // threshold={600}
                        // effect="opacity"
                        style={{
                            width: "100%",
                            height: item.temp ? "60%" : "80%",
                            objectFit: "contain"
                        }}
                        src={ imageUrl } />  */}

            {/* </Tooltip> */}
          </Popover>
        </CustomContextMenu>
        <div className="label">{item.name}</div>

        {/* ProgressBar for uploading */}
        {item.temp && !isLoading && (
          <span className="uploadingState">
            <CloudUploadOutlined className="loadingIcon" />
          </span>
        )}

        {this.props.used && (
          <span className="usedState">
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          </span>
        )}
      </div>
    );
  }
}

// --------------------- PropTypes ------------------------

// PhotoItem.propTypes = {
//     selected: PropTypes.bool,
//     photo: PropTypes.object, // PropTypes.objectOf( PhotoType),
//     onDragStart: PropTypes.func,
//     // scrollContainer: PropTypes.instanceOf(Element)
// };

// --------------------- export ------------------------

// export default withForwardedRef(PageNavigatorItem)
// export default withForwardedRef(PhotoItem);
export default PhotoItem;
// export { PageNavigatorItem };
