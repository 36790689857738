import React from 'react';
import { connect } from 'react-redux';
import { Button, Popover, Spin, Select, Empty, Modal } from 'antd';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { cloneDeep } from 'lodash';
import type { IPage, BackgroundItem } from '../../../types/types';
import {
  editionActions,
  editionSelectors,
} from '../../../feature/edition/edition';
import { backgroundSelectors } from '../../../feature/backgrounds/background';
import { AddDragData, DRAG_TYPE } from '../../../utils/DragUtils';
import {
  ApplyBackgroundToPage,
  ApplyBackgroundFillToPage,
} from '../../../feature/backgrounds/backgroundHelper';
import { DebugFlags } from '../../../debug/DebugFlags';
import { GetText } from '../../../data/LanguageHelper';
import { Colors } from '../../../data/Colors';
import ColorPicker from '../../../_components/ColorPicker';
import { IsCanvasEditor } from '../../../data/config';
import { TABS_ENUM } from './TABS_ENUM';

class BackgroundsArea extends React.Component {
  // --------------------- constructor ------------------------

  constructor(props) {
    super(props);
    this.state = {
      currentCategory: null,
    };
  }

  // --------------------- react ------------------------

  componentDidMount() {
    if (
      this.props.backgroundCategories &&
      this.props.backgroundCategories.length > 0
    )
      this.handleCategoryChange(this.props.backgroundCategories[0]);
  }

  shouldComponentUpdate(nextProps, nextState) {
    // const differentTitle = this.props.title !== nextProps.title;
    // const differentDone = this.props.done !== nextProps.done
    // return differentTitle || differentDone;
    const { props } = this;

    // we should update layouts only if layouts did change, or if we switch from cover to page
    const tabActive = nextProps.selectedTab === TABS_ENUM.BACKGROUND;
    const tabBecameActive =
      tabActive && props.selectedTab !== TABS_ENUM.BACKGROUND;
    const categoryDidChange =
      nextState.currentCategory !== this.state.currentCategory;

    // set default category
    if (
      !nextState.currentCategory &&
      nextProps.backgroundCategories &&
      nextProps.backgroundCategories.length > 0
    ) {
      this.handleCategoryChange(nextProps.backgroundCategories[0]);
      return true;
    }
    const coverChange =
      props.selectedPageIndex !== nextProps.selectedPageIndex &&
      (props.selectedPageIndex === 0 || nextProps.selectedPageIndex === 0);

    const fillColorChange =
      props.selectedPageObject &&
      nextProps.selectedPageObject &&
      props.selectedPageObject.frames.length > 0 &&
      nextProps.selectedPageObject.frames.length > 0 &&
      props.selectedPageObject.frames[0].fillColor !==
        nextProps.selectedPageObject.frames[0].fillColor;

    // Conditions to re render this component, only if the layout list is different!
    const different = this.props.backgrounds !== nextProps.backgrounds;
    return (
      tabActive &&
      (different ||
        coverChange ||
        categoryDidChange ||
        fillColorChange ||
        tabBecameActive)
    );
  }

  // --------------------- methods ------------------------

  handleCategoryChange(newCategory) {
    this.setState({
      currentCategory: newCategory,
    });
  }

  handleItemClick(item) {
    const { project, selectedPageObject, backgroundsById } = this.props;

    if (!project) {
      alert('TODO: no project selected');
      return;
    }

    // get a copy of page to work with
    // const pageCopy:IPage = cloneDeep( project.pageList[ selectedPage ] );
    const pageCopy: IPage = cloneDeep(selectedPageObject);

    // apply to page
    ApplyBackgroundToPage(pageCopy, item);

    // dispatch page update
    this.props.dispatch(editionActions.UpdatePage(pageCopy));

    // layout applied, allow undoable action
    this.props.dispatch(editionActions.AddUndoableAction());
  }

  handleApplyToAll(item) {
    this.props.dispatch(editionActions.ApplyBackgroundToAllPages(item.id));
  }

  handleItemDragStart(evt, item) {
    AddDragData(evt, DRAG_TYPE.BACKGROUND, item.id);
  }

  handleBackgroundColorSelect = (color) => {
    // alert("new color selected:" + JSON.stringify(color));

    // for canvas do not add apply to all selection.
    if (IsCanvasEditor()) {
      this.applyBackgroundColor(color, false);
      return;
    }

    // select for all pages or only this page
    Modal.confirm({
      title: GetText('popup.background.applytoall.title'),
      okText: GetText('popup.background.applytoall.yes'),
      cancelText: GetText('popup.background.applytoall.no'),
      width: 500,
      onOk: () => {
        this.applyBackgroundColor(color, true);
      },
      onCancel: () => {
        this.applyBackgroundColor(color, false);
      },
    });
  };

  applyBackgroundColor = (color, allPages: boolean) => {
    const { project, selectedPageObject, backgroundsById } = this.props;

    if (!project) {
      alert('TODO: no project selected');
      return;
    }

    if (allPages) {
      this.props.dispatch(
        editionActions.ApplyBackgroundToAllPages(null, color.hex)
      );
    }
    // only selected Page
    else {
      // get a copy of page to work with
      const pageCopy: IPage = cloneDeep(selectedPageObject);
      // apply to page
      ApplyBackgroundFillToPage(pageCopy, color.hex);
      // dispatch page update
      this.props.dispatch(editionActions.UpdatePage(pageCopy));
      // layout applied, allow undoable action
      this.props.dispatch(editionActions.AddUndoableAction());
    }
  };

  /// ///////////////////////////////////////////////////
  // Render
  /// ///////////////////////////////////////////////////
  render() {
    const {
      backgrounds,
      backgroundCategories,
      project,
      isLoading,
      selectedPageObject,
    } = this.props;
    const { currentCategory } = this.state;
    const { Option } = Select;

    const pageRatio = project ? project.width / project.height : -1;
    const selectedPage: IPage = selectedPageObject;
    const currentBackgroundColor =
      selectedPage &&
      !selectedPage.coverClassicOptions &&
      selectedPage.frames[0].fillColor
        ? selectedPage.frames[0].fillColor
        : '#ffffff';
    console.log(`WE RENDER BACKGROUNDS NOW (category:${currentCategory})`);

    // TODO: change this rule later with other products
    const pageHasBackgrounds = !selectedPage.coverClassicOptions;

    return (
      <div className="tabContent backgroundsArea">
        <h3>{GetText('lefttab.backgrounds')}</h3>

        {
          // --------------------- CASE PAGE HAS BACKGROUND ------------------------
          pageHasBackgrounds && (
            <Spin spinning={isLoading}>
              <h4>{GetText('lefttab.backgrounds.colors')}</h4>
              <ColorPicker
                colors={Colors.displayColors}
                selected={currentBackgroundColor}
                onChange={this.handleBackgroundColorSelect}
                itemSize={15}
                borderRadius={8}
                spacing={2}
              />

              {/* <span className="spacer"/> */}

              {/* <CirclePicker width="100%"
                    key="colorpicker"
                    circleSpacing={2}
                    circleSize={15}
                    colors={Colors.displayColors } color={currentBackgroundColor}
                    onChange={this.handleBackgroundColorSelect} />  */}

              {/* <h4>{GetText("lefttab.backgrounds.select.category")}</h4> */}

              {
                // --------------------- background category filter ------------------------
                backgroundCategories && backgroundCategories.length > 0 && (
                  <Select
                    value={currentCategory}
                    placeholder="select a category"
                    onChange={(value) => {
                      this.handleCategoryChange(value);
                    }}
                    className="dropdown"
                  >
                    {backgroundCategories.map((category, index) => (
                      <Option key={category} value={category}>
                        {GetText(`lefttab.category.${category}`)}
                      </Option>
                    ))}
                  </Select>
                )
              }

              <div
                style={{
                  width: '100%',
                  display: 'grid',
                  padding: '10px 0px',
                  gridTemplateColumns: '1fr 1fr',
                  gridTemplateRows: 'auto',
                }}
              >
                {backgrounds &&
                  backgrounds[currentCategory] &&
                  backgrounds[currentCategory].map((backgroundItem, key) => {
                    // const item:Layout = layoutsByID[layoutID];
                    const item: BackgroundItem = backgroundItem;
                    const overContent = (
                      <div className="backgroundItemOverlay">
                        <b>{item.id}</b>
                        {DebugFlags.DEBUGGING ? JSON.stringify(item) : ''}
                        <Button className="button" type="primary">
                          {GetText('lefttab.backgrounds.apply')}
                        </Button>
                        <Button
                          className="button"
                          type="primary"
                          onClick={() => this.handleApplyToAll(item)}
                        >
                          {GetText('lefttab.backgrounds.applytoall')}
                        </Button>
                      </div>
                    );
                    const overContentDelay = DebugFlags.DEBUGGING ? 0.5 : 0.5;

                    const pageRatio = item.width / item.height;
                    const contentW = 90;
                    const contentH = contentW / pageRatio;

                    return (
                      // TODO:check to use antd Grid here with list item card

                      <div
                        role="button"
                        tabIndex="0"
                        className="photoItem"
                        style={{
                          width: contentW,
                          textAlign: 'center',
                          // height:contentH,
                        }}
                        key={item.id}
                        onDragStart={(e) => this.handleItemDragStart(e, item)}
                        onClick={(e) => this.handleItemClick(item)}
                        draggable
                      >
                        {
                          // see doc of popover that extends the tooltip
                          // https://gary-shen.github.io/ant-design/components/tooltip/
                        }
                        {/* <Tooltip placement="right" title={"id:"+item.id + "\nname:"+item.name}> */}
                        <Popover
                          content={overContent} // title={"id:" + item.id}
                          placement="right"
                          trigger="hover"
                          mouseEnterDelay={overContentDelay}
                        >
                          <LazyLoadImage // className="photoPreview"
                            // style={{margin: "0px auto 0px auto"}}
                            // key={item.id}
                            threshold={600}
                            effect="opacity"
                            style={{
                              width: '100%',
                              maxHeight: '70px',
                              height: '70px',
                              objectFit: 'contain',
                            }}
                            draggable={false}
                            src={item.thumb_url}
                          />
                        </Popover>
                      </div>
                    );
                  })}
              </div>
            </Spin>
          )
        }

        {
          // --------------------- CASE EMPTY ------------------------
          !pageHasBackgrounds && (
            <Empty
              style={{ width: '100%' }}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={GetText('lefttab.background.empty.label')}
            />
          )
        }
      </div>
    );
  }
}

// --------------------- redux ------------------------

// Redux map
function mapStateToProps(state) {
  const { backgrounds, edition, photos, ui } = state;
  const { project } = edition;

  // we need user, project and layouts
  return {
    project,
    selectedTab: ui.selectedTab,
    selectedPageIndex: edition.selectedPage,
    selectedPageObject: editionSelectors.GetSelectedPage(state),
    isLoading: backgrounds.isLoading,
    backgroundsById: backgrounds,
    backgrounds: backgroundSelectors.getBackgroundsByCategories(state),
    backgroundCategories: backgroundSelectors.getBackgroundCategories(state),
  };
}

// --------------------- export ------------------------

const reduxConnected = connect(mapStateToProps)(BackgroundsArea);
export { reduxConnected as BackgroundsArea };
