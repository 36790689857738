import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
// reducers to combine
import { combineReducers, createStore, applyMiddleware } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import edition from '../feature/edition/edition';
import ui from '../feature/ui/ui';
import authentication from '../feature/auth/authentification';
import pricing from '../feature/pricing/pricing';
import order from '../feature/order/order';
import projectList from '../feature/projectList/projectList';
import photoList from '../feature/photoList/photoList';
import alert from '../feature/alert/alert';
import layoutList from '../feature/layouts/layoutList';
import background from '../feature/backgrounds/background';
import cliparts from '../feature/cliparts/cliparts';
import overlayers from '../feature/overlayers/overlayers';
import { DebugFlags } from '../debug/DebugFlags';
import { userTimingMiddleware } from './middleware/userTimingMiddleware';

import { CalendarHelper } from './calendar/CalendarHelper';
import { FrameExporter } from '../feature/order/frameExporter';
import { InitializeAPI } from './API';

const loggerMiddleware = createLogger();
// const reduxStateName = "reduxState_v2"; // name of the cookie used in this version, can be changed if later there is an update in the state that might compromise the "reload" of the state.
const localStorateReduxPrefix = 'reduxState';
const reduxStateName = `${localStorateReduxPrefix}_${process.env.REACT_APP_VERSION}`; // name of the cookie used in this version, can be changed if later there is an update in the state that might compromise the "reload" of the state.

// try to read previous persisted state of application
if (!DebugFlags.USE_PERSISTED_STATE_COOKIE)
  localStorage.removeItem(reduxStateName);

const persistedState = localStorage.getItem(reduxStateName)
  ? JSON.parse(localStorage.getItem(reduxStateName))
  : {};

/**
 * ROOT REDUCER
 */
const rootReducer = combineReducers({
  authentication,
  alert,
  pricing,
  projects: projectList,
  photos: photoList,
  layouts: layoutList,
  backgrounds: background,
  cliparts,
  overlayers,
  edition,
  ui,
  order,
});

/**
 * STORE
 */
// export const store = createStore(
//     rootReducer,
//     persistedState,
//     applyMiddleware(
//         thunkMiddleware,
//         loggerMiddleware
//     )
// );

export const store = configureStore({
  reducer: rootReducer,
  preloadedState: persistedState,
  middleware: [thunkMiddleware, loggerMiddleware, userTimingMiddleware],
});

// initialize helpers that could need the store!
CalendarHelper.InitializeStoreRef(store);
FrameExporter.InitializeStoreRef(store);
InitializeAPI(store);

// write store to local storage to keep previous state of application
// TODO : be careful here as it could be intensive to use stringify each time there is a change
// we should maybe use a timer here to auto save each xx seconds.
// Check this : https://medium.com/@jrcreencia/persisting-redux-state-to-local-storage-f81eb0b90e7e
if (DebugFlags.STORE_REDUX_STATE_AT_EACH_CHANGE) {
  store.subscribe(() => {
    this.SaveStoreLocally();
  });
}

export function SaveStoreLocally() {
  // when saving, first we remove all assets
  // check for previous version of store
  for (let i = localStorage.length - 1, l = localStorage.length; i >= 0; i--) {
    const key: string = localStorage.key(i);
    if (key.includes(localStorateReduxPrefix) && key !== reduxStateName) {
      localStorage.removeItem(key);
    }
  }

  // then we save the store without the "history" to avoid too much mamory
  const storeCopy = cloneDeep(store.getState());
  // clear history before saving
  storeCopy.edition.historyIndex = 0; // clear history
  storeCopy.edition.history = []; // clear history
  // clear layouts
  storeCopy.layouts.isLoading = false;
  storeCopy.layouts.error = null;
  storeCopy.layouts.byID = {};
  storeCopy.layouts.filtered = {};
  // clear photos
  storeCopy.photos.backendPhotosByID = null; // clear backend photos and layouts
  storeCopy.photos.isLoading = false; // clear backend photos and layouts
  storeCopy.photos.error = null; // clear backend photos and layouts

  // set item
  localStorage.setItem(reduxStateName, JSON.stringify(storeCopy));
}
