import React from 'react';
import { connect } from 'react-redux';
import { authActions } from '../feature/auth/authentification';
import { alertActions } from '../feature/alert/alert';
import { RegisterForm } from './registerPage/RegisterForm';
import { GetText } from '../data/LanguageHelper';

class RegisterPage extends React.Component {
  // --------------------- constructor ------------------------

  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      // submitted: false,
    };

    // bindings
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // --------------------- methods ------------------------

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(username, password) {
    // e.preventDefault();
    // this.setState({ submitted: true });

    // const { username, password } = this.state;
    const { dispatch } = this.props;

    // clear alerts
    dispatch(alertActions.clear());

    // dispatch
    if (username && password) {
      dispatch(authActions.register(username, password));
    }
  }

  // --------------------- render ------------------------

  render() {
    const { registering, error } = this.props;
    const { username, password } = this.state;
    return (
      // <Col span={8}>
      <div className="mainCenteringContainer">
        <div className="registerPage">
          <h2>{GetText('register.title')}</h2>
          <h4>{GetText('register.description')}</h4>

          <RegisterForm
            onSubmit={(user, pass) => {
              this.handleSubmit(user, pass);
            }}
            username={username}
            registering={registering}
            errorMessage={error}
          />

          {/*
                <form name="form" onSubmit={this.handleSubmit}>

                    <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                        <label htmlFor="username">Username</label>
                        <input type="text" className="form-control" name="username" value={username} onChange={this.handleChange} />
                        {submitted && !username &&
                            <div className="help-block">Username is required</div>
                        }
                    </div>
                    <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                        <label htmlFor="password">Password</label>
                        <input type="password" className="form-control" name="password" value={password} onChange={this.handleChange} />
                        {submitted && !password &&
                            <div className="help-block">Password is required</div>
                        }
                    </div>
                    <div className="form-group">
                        <button className="btn btn-primary">Login</button>
                        {loggingIn &&
                            <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA===" />
                        }
                        <Link to="/register" className="btn btn-link">Register</Link>
                    </div>
                </form>
                */}

          {/* </Col> */}
        </div>
      </div>
    );
  }
}

// --------------------- redux ------------------------

function mapStateToProps(state) {
  const { registering, error } = state.authentication;
  return {
    registering,
    error,
  };
}

// --------------------- export ------------------------

// connect with redux
const connectedPage = connect(mapStateToProps)(RegisterPage);
// export
export { connectedPage as RegisterPage };
