import React, { forwardRef } from 'react';

/**
 * See documentation : https://zhenyong.github.io/react/docs/more-about-refs.html
 * see forum : https://stackoverflow.com/questions/51526461/how-to-use-react-forwardref-in-a-class-based-component
 */

const withForwardedRef = (Comp) => {
  const handle = (props, ref) => <Comp {...props} forwardedRef={ref} />;

  const name = Comp.displayName || Comp.name;
  handle.displayName = `withForwardedRef(${name})`;

  return forwardRef(handle);
};

export default withForwardedRef;
