// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../types/types.d.ts" />

import React, { useEffect, useState } from 'react';
// import ReactDOM from 'react-dom';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import { notification, Result, Spin } from 'antd';
// import { gsap } from 'gsap';
import { cloneDeep } from 'lodash';
import { HomePage } from '../pages/HomePage';
import { LoginPage } from '../pages/LoginPage';
import { RegisterPage } from '../pages/RegisterPage';

import { alertActions } from '../feature/alert/alert';
import { authActions, authSelectors } from '../feature/auth/authentification';
import { photoListActions } from '../feature/photoList/photoList';
import { layoutListSelectors } from '../feature/layouts/layoutList';
import { editionActions, editionSelectors } from '../feature/edition/edition';
import { orderActions } from '../feature/order/order';
import { DebugFlags, IsDebug } from '../debug/DebugFlags';
import { API } from '../utils/API';
import { popupHelper } from '../feature/alert/popupHelper';
import { Analytic } from './Analytic';

import { IsMobileOrTablet } from '../utils/BrowserHelper';
import { GetText } from '../data/LanguageHelper';
import { history, ROUTE_CONST } from '../utils/history';
import { config, PROJECT_CONST } from '../data/config';
import { EmbedAssets } from '../images/EmbedAssets';
import { CreateProject } from '../feature/project/CreateProject';

// [EDIT] : this do not seem to work...
// process.title = "tictacApp"; // this allows to kill process if already running..

const App = (props) => {
  const navigate = useNavigate();
  const [lastSentErrorTime, setLastSentErrorTime] = useState(0);
  const {
    alert,
    loggedIn,
    initializing,
    languageLoaded,
    dispatch,
    needSessionCheckAtNextFocus,
    session,
    project,
    resourcesAreLoaded,
    projectCreationParams,
    layoutStore,
  } = props;

  const projectId = project?.id;

  // --------------------- CONSTRUCTOR ------------------------

  // on mount
  useEffect(() => {
    // initialize analytic
    if (!IsDebug) {
      Analytic.Initialize();
    }
    // at start clear alerts (could be still alerts alive as state is stored on local storage)
    dispatch(alertActions.clear());
    // reset possible order details (if reloading from state!)
    dispatch(orderActions.resetOrder());
    // reset possible order details (if reloading from state!)
    if (DebugFlags.CLEAN_UPLOAD_AT_START)
      dispatch(photoListActions.resetUploads());
    // firt think we do is check session!
    dispatch(authActions.checkCurrentSession());
    // TODO: We need to set it here as this is important to recover the layouts, backgrounds etc.. So we may need to check the url for .
    // EDIT : this is now a project constant
    // dispatch(editionActions.SetProjectClassname( PROJECT_CLASS.ALBUM ));

    ///--------------------------------
    // listen to history change to clear alerts and analytic log
    // TODO: reset analytic library!
    // history.listen((location, action) => {
    //   // clear possible messages
    //   dispatch(alertActions.clear()); // relly needed?
    //   // google analytic
    //   Analytic.TrackPage(location.pathname);
    // });
    ///--------------------------------

    ///--------------------------------
    // INITIALIZE LANGUAGE
    const languages = ['en', 'fr', 'nl', 'es', 'de'];
    dispatch(authActions.loadLanguages(languages, config.spreadSheetID));
    ///--------------------------------
  }, [dispatch]);

  useEffect(() => {
    // --------------------- Global error handler ------------------------
    const handleGlobalError = (event) => {
      // called before any tests are run
      if (
        !DebugFlags.IS_LOCALHOST &&
        event.error &&
        !event.error.hasBeenCaught
      ) {
        const { error } = event;
        error.hasBeenCaught = true;
        event.stopPropagation();
        event.preventDefault();

        // avoid sending multiple times the same error!
        const newErrorTime = new Date().getTime();
        if (!lastSentErrorTime || newErrorTime - lastSentErrorTime > 2000) {
          // save time
          setLastSentErrorTime(newErrorTime);
          // send analytic
          Analytic.TrackError(error.message, true);
          // send dev mail
          API.sendDevMail(error)
            .then(() => popupHelper.showErrorPopup(error))
            .then(() => popupHelper.showFeedbackPopup());
        }
      }
    };

    // --------------------- handle window focus, check if we need a new check session ------------------------
    const handleWindowFocus = (e) => {
      console.log(
        `>> window got focus, need session check:${needSessionCheckAtNextFocus}`
      );
      if (needSessionCheckAtNextFocus)
        dispatch(authActions.checkCurrentSession());
    };

    // global error handler!
    window.addEventListener('error', handleGlobalError);
    // app focus
    window.addEventListener('focus', handleWindowFocus);

    // cleanup function
    return () => {
      window.removeEventListener('focus', handleWindowFocus);
      window.removeEventListener('error', handleGlobalError);
    };
  }, [
    dispatch,
    needSessionCheckAtNextFocus,
    lastSentErrorTime,
    setLastSentErrorTime,
  ]);

  // hack to override the previous push method of history, to avoid a big refactoring now..
  useEffect(() => {
    history.SetPushMethod(navigate);
  }, [navigate]);

  // hook to listen to alert change
  useEffect(() => {
    // display alert if needed
    if (alert.message) {
      notification[alert.type]({
        description: alert.message,
        placement: 'top',
        duration: alert.type === 'success' ? 2 : 5,
        top: 6,
      });
    }
  }, [alert, dispatch]);

  useEffect(() => {
    // --------------------- INITIALIZE  ------------------------
    // once we are logged in and resources are loaded
    if (loggedIn && resourcesAreLoaded) {
      console.log('All resources are loaded.');

      // -- case a project is already loaded (session update) -> do nothing
      if (projectId) {
        // keep project
        console.log(
          `session has been renewed with existing project running:${projectId}`
        );
        // For some reason the ROUTE_CONTST.SELECT_PROJECT_CLASS comes up
        // if the new project being loaded is the same class as the current
        // so this immediately switches back to the project edit view
        history.push(ROUTE_CONST.HOME);
      }

      // ---- CASE CREATE PROJECT FROM SESSION PARAMS ----
      else if (projectCreationParams) {
        history.push(ROUTE_CONST.HOME);

        // we need thee layout store
        console.log('--> starting from creation params');

        /** @type {ProjectCreationParams} * */
        const params = cloneDeep(projectCreationParams);
        params.layoutStore = layoutStore;
        const proj = CreateProject(params);
        dispatch(editionActions.CreateProject(proj));
      }

      // ---- CASE LOAD PROJECT ----
      else {
        let projectID = null;
        // Check if we need to load a project by ID

        // --> direct load debug project
        if (DebugFlags.DIRECT_LOAD_PROJECT)
          projectID = DebugFlags.DIRECT_LOAD_PROJECT;
        // --> Option 1 in url // NOT USED ANYMORE
        else if (`${window.location}`.includes('proj=')) {
          console.log('--> load project from QueryParam');
          [projectID] = `${window.location}`.split('proj=')[1].split('.');
        }

        // --> Option 2 in session
        else if (
          session.recent_project_id &&
          session.recent_project_class &&
          (session.recent_project_class === PROJECT_CONST.project_class ||
            window.location.pathname === '/')
        ) {
          // case we are on root, but not with the correct project class!
          if (session.recent_project_class !== PROJECT_CONST.project_class) {
            window.location = `/${session.recent_project_class}?proj=${session.recent_project_id}`;
            return;
          }
          console.log('--> load project from Session Param');
          projectID = session.recent_project_id;
        }

        // --> Option 3 local cookie
        // else if( localStorage.getItem( TICTAC_COOKIES.LATEST_PROJECT ) )
        //     projectID = localStorage.getItem( TICTAC_COOKIES.LATEST_PROJECT );

        // load previous project if it exists!
        // const latestProjectID = localStorage.getItem( TICTAC_COOKIES.LATEST_PROJECT );
        // const latestClassname = localStorage.getItem( TICTAC_COOKIES.LATEST_CLASSNAME );
        if (projectID) {
          dispatch(editionActions.LoadProject(projectID));
        }

        // show startup page!
        else {
          console.log('--> Show wizard');

          // go to home page
          const loc = window.location.pathname;
          if (loc !== ROUTE_CONST.NEW_PROJECT && loc !== ROUTE_CONST.HOME)
            history.push(ROUTE_CONST.SELECT_PROJECT_CLASS);
        }
      }
    }
  }, [
    loggedIn,
    resourcesAreLoaded,
    session,
    layoutStore,
    projectCreationParams,
    dispatch,
    projectId,
  ]);

  // --------------------- RENDERING ------------------------

  return (
    <div className="container-fluid">
      <Spin spinning={initializing || !languageLoaded} className="mainLoading">
        {!initializing && languageLoaded && !IsMobileOrTablet() && (
          <div>
            <div>
              <Routes>
                <Route path={ROUTE_CONST.LOGIN} element={<LoginPage />} />
                <Route path={ROUTE_CONST.REGISTER} element={<RegisterPage />} />
                {
                  // case not logged in, go to login page
                  !loggedIn && <Route path="*" element={<LoginPage />} />
                }
                <Route path="*" element={<HomePage />} />
              </Routes>
            </div>
          </div>
        )}

        {
          // --------------------- Mobile screen ------------------------
          IsMobileOrTablet() && languageLoaded && (
            <div
              className="mobileStartScreen wrapperFlexCenterColumn"
              style={{
                display: 'flex',
                height: '100vh',
                flex: '1 1 500px',
                flexFlow: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '100%',
              }}
            >
              <Result
                icon={
                  <img
                    src={EmbedAssets.LogoMobileScreen}
                    width="100"
                    height="100"
                  />
                }
                title={
                  <span className="mobileStartScreenText">
                    {GetText('startup.isMobile.message')}
                  </span>
                }
                // extra={<Button type="primary">Next</Button>}
              />
            </div>
          )
        }
      </Spin>
    </div>
  );
};

// --------------------- REDUX ------------------------

function mapStateToProps(state) {
  const { alert, authentication, edition } = state;
  const { initializing, languageLoaded } = authentication;
  return {
    alert,
    project: editionSelectors.GetProjectSelector(state),
    loggedIn: authSelectors.IsLoggedIn(state),
    session: authSelectors.GetSession(state),
    sessionID: authSelectors.GetSessionID(state),
    projectCreationParams: authSelectors.GetProjectCreationParams(state),
    needSessionCheckAtNextFocus: authSelectors.NeedSessionCheck(state),
    userMail: authSelectors.GetUserEmail(state),
    userID: authSelectors.GetUserID(state),
    layoutStore: layoutListSelectors.getLayoutStore(state),
    initializing,
    languageLoaded,

    // resourcesAreLoading: authSelectors.ResourcesAreLoading(state),
    resourcesAreLoaded: authSelectors.ResourcesAreLoaded(state),
  };
}

// --------------------- EXPORT ------------------------

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };
