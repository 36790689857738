import React from 'react';
import { IsSafari } from '../utils/BrowserHelper';

export const ForeignObjectUnscaled = ({
  width,
  height,
  x = 0,
  y = 0,
  editionScale = 1,
  children,
}) => (
  <g transform={`translate(${x},${y})`}>
    <foreignObject
      x="0"
      y="0"
      className="foreignObjectUnscaled unselectable"
      height={height}
      width={width}
    >
      {
        /* SAFARI IS A MESS WITH SVG SCALING... */
        IsSafari() ? (
          <div
            className="foreignObjectUnscaled"
            style={{
              position: 'unset',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',

              // transform:"scale("+(1/editionScale)+")",
              // transformOrigin:"0% 0%",
              // // backgroundColor:"red",
              // width:width*editionScale,
              // height:height*editionScale

              // transform:"scale("+(1/editionScale)+")",
              // WebkitTransform:"scale("+(1/editionScale)+")",
              // transformOrigin:"0% 0%",
              // transform:`translate(${x},${y}) scale(${(1/editionScale)})`,
              // backgroundColor:"red",
              width,
              height,
            }}
          >
            {children}
          </div>
        ) : (
          // case NOT safari
          <div
            style={{
              position: 'unset',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              transform: `scale(${1 / editionScale})`,
              transformOrigin: '0% 0%',
              // backgroundColor:"red",
              width: width * editionScale,
              height: height * editionScale,
            }}
          >
            {children}
          </div>
        )
      }
    </foreignObject>
  </g>
);
