import React from 'react';
import { connect } from 'react-redux';
import { Popover, Spin, Select } from 'antd';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { cloneDeep } from 'lodash';
import type { OverlayerItem, IPage } from '../../../types/types';
import {
  editionActions,
  editionSelectors,
} from '../../../feature/edition/edition';
import { overlayerSelectors } from '../../../feature/overlayers/overlayers';
import { AddDragData, DRAG_TYPE } from '../../../utils/DragUtils';

import { OverlayerHelper } from '../../../feature/overlayers/overlayerHelper';
import { GetText } from '../../../data/LanguageHelper';
import { IsDebug } from '../../../debug/DebugFlags';
import { TABS_ENUM } from './TABS_ENUM';

class OverlayersArea extends React.Component {
  // --------------------- constructor ------------------------

  // constructor(props) {
  //     super(props);
  //     // this.state = {
  //     //     currentCategory: 'new',
  //     // };
  // }

  // --------------------- react ------------------------

  componentDidMount() {}

  shouldComponentUpdate(nextProps, nextState) {
    // const differentTitle = this.props.title !== nextProps.title;
    // const differentDone = this.props.done !== nextProps.done
    // return differentTitle || differentDone;

    // we should update layouts only if layouts did change, or if we switch from cover to page
    const tabActive = this.props.selectedTab === TABS_ENUM.OVERLAYER;
    // const categoryDidChange = nextState.currentCategory !== this.state.currentCategory;
    const coverChange =
      this.props.selectedPageIndex !== nextProps.selectedPageIndex &&
      (this.props.selectedPageIndex === 0 || nextProps.selectedPageIndex === 0);

    // Conditions to re render this component, only if the layout list is different!
    const different = this.props.overlayers !== nextProps.overlayers;
    // return tabActive && (different || coverChange || categoryDidChange );
    return tabActive && (different || coverChange);
  }

  // --------------------- methods ------------------------

  // handleCategoryChange( newCategory )
  // {
  //     this.setState({
  //         currentCategory: newCategory,
  //     });
  // }

  handleItemClick(item: OverlayerItem) {
    // TODO: HANDLE SIMPLE OVERLAYER ITEM CLICK!!!
    const { project, currentPage, overlayersById } = this.props;

    if (!project || !currentPage) {
      alert('TODO: project or page selected.. ');
      return;
    }

    // get a copy of page to work with
    const pageCopy: IPage = cloneDeep(currentPage);

    // apply to page
    OverlayerHelper.AddOverlayerToPage(pageCopy, item);

    // dispatch page update
    this.props.dispatch(editionActions.UpdatePage(pageCopy));

    // layout applied, allow undoable action
    this.props.dispatch(editionActions.AddUndoableAction());
  }

  handleItemDragStart(evt, item) {
    AddDragData(evt, DRAG_TYPE.OVERLAYER, item.id);
  }

  /// ///////////////////////////////////////////////////
  // Render
  /// ///////////////////////////////////////////////////
  render() {
    const { overLayerList, overlayersById, project, isLoading } = this.props;
    // const { currentCategory } = this.state;
    const { Option } = Select;

    // const pageRatio = (project) ? project.width / project.height : -1;
    // console.log("WE RENDER OVERLAYERS NOW (category:"+currentCategory+")");
    console.log('WE RENDER OVERLAYERS NOW');

    return (
      <div className="tabContent overlayersArea">
        <h3>{GetText('lefttab.overlayers')}</h3>
        <Spin spinning={isLoading}>
          {/* <Select defaultValue={currentCategory}
                    placeholder="select a category"
                    onChange={ (value)=>{ this.handleCategoryChange(value); } }
                    style={{ width: 120 }} >
                    {
                        overlayerCategories.map( ( category, index )=>{
                        return (<Option key={category} value={category}>{category}</Option>)
                        })
                    }
                </Select> */}
          <div
            className="layoutsContainer"
            style={{
              width: '100%',
              display: 'grid',
              padding: '10px 0px',
              gridTemplateColumns: '1fr 1fr',
              gridTemplateRows: 'auto',
            }}
          >
            {overLayerList &&
              overLayerList.map((overlayerItem, key) => {
                // const item:Layout = layoutsByID[layoutID];
                const item = overlayerItem;
                const overContent = IsDebug ? (
                  <div style={{ width: 500, wordWrap: 'break-word' }}>
                    {JSON.stringify(item)}
                  </div>
                ) : null;

                const itemRatio = item.width / item.height;
                const contentW = 90;
                const contentH = contentW / itemRatio;

                return (
                  // TODO:check to use antd Grid here with list item card

                  <div
                    className="photoItem"
                    style={{
                      width: contentW,
                      // height:contentH,
                      // textAlign="center"
                      textAlign: 'center',
                    }}
                    key={item.id}
                    onDragStart={(e) => this.handleItemDragStart(e, item)}
                    onDoubleClick={(e) => this.handleItemClick(item)}
                    draggable
                  >
                    {
                      // see doc of popover that extends the tooltip
                      // https://gary-shen.github.io/ant-design/components/tooltip/
                    }
                    {/* <Tooltip placement="right" title={"id:"+item.id + "\nname:"+item.name}> */}
                    <Popover
                      content={overContent}
                      title={`id:${item.id}`}
                      placement="right"
                      trigger="hover"
                      mouseEnterDelay={1}
                    >
                      <LazyLoadImage // className="photoPreview"
                        // style={{margin: "0px auto 0px auto"}}
                        // key={item.id}
                        threshold={600}
                        effect="opacity"
                        style={{
                          width: '100%',
                          maxHeight: '70px',
                          height: '70px',
                          objectFit: 'contain',
                        }}
                        draggable={false}
                        src={item.thumb_url}
                      />
                    </Popover>
                  </div>
                );
              })}
          </div>
        </Spin>
      </div>
    );
  }
}

// --------------------- redux ------------------------

// Redux map
function mapStateToProps(state) {
  const { overlayers, edition, ui } = state;
  const { project } = edition;

  // we need user, project and layouts
  return {
    project,
    selectedTab: ui.selectedTab,
    selectedPageIndex: editionSelectors.GetSelectedPageIndex(state),
    currentPage: editionSelectors.GetSelectedPage(state),
    isLoading: overlayers.isLoading,
    overlayersById: overlayerSelectors.getAllOverlayersByID(state),
    overLayerList: overlayerSelectors.getAllOverlayerList(state),
    // overlayers: overlayerSelectors.getOverlayersByCategories( state ),
    // overlayerCategories: overlayerSelectors.getOverlayerCategories(state),
  };
}

// --------------------- export ------------------------

const reduxConnected = connect(mapStateToProps)(OverlayersArea);
export { reduxConnected as OverlayersArea };
