// const inchToCm = 2.54;
// const dpi = 72;
/*
  NOTE : inch > cm > point (PostScript point) > pixel

  1 inch = 96 pixel OR 72 pixel ===> we use 72 so the pixel to point is identical
  1 inch = 72 point
  1 inch = 2.54 cm
  1 pixel = 0.75 point
  1 cm = 28.3464567 points
  */

// const pixelToCM = 0.03527777778; // = 2.54/72;

export const INCH_TO_PIXEL = 72; // TODO : check if Server side use same ratio and values.. otherwise it will not work correctly. EDIT : seems that they work with 72 and not 96.. EDIT : they work with 96 but don't want to change all the system..
// const inchToPoint :number = 72;
// const _pixelToPoint :number = inchToPoint/INCH_TO_PIXEL;
// const inchToCm :number = 2.54;
const CM_TO_POINT = 28.3464567; // = 72/2.54
const MM_TO_POINT: number = CM_TO_POINT / 10; //

// NOTE : pixel and points are the same as we use the same conversion 72pixel = 72points = 1Inch = 2.54cm

export function mmToPoint(mm) {
  return MM_TO_POINT * mm;
}
export function cmToPixel(cm) {
  // return cm / pixelToCM;
  return CM_TO_POINT * cm;
}
export function pixelToPoint(pixel) {
  return 1; // as inch to point and inch to pixel is the same (72) we can set the pixel to point to be 1
}
export function pixelToCm(pixel) {
  return pixel / CM_TO_POINT;
}

/**
 *
 fiddle : http://jsfiddle.net/r491oe7z/3/
 usage: GetTexSize("Hello World", "30px Arial").width
 */
export function GetTexSize(txt, font) {
  const element = document.createElement('canvas');
  element.className = 'textDimensionCalculation';
  const context = element.getContext('2d');
  context.style = 'height: auto; width: auto;white-space: nowrap;';
  context.font = font;
  // return context.measureText(txt).width;
  return context.measureText(txt);
}
