import React from 'react';
import { connect, batch } from 'react-redux';
import {
  Button,
  Select,
  Row,
  Typography,
  Checkbox,
  Tooltip,
  Modal,
} from 'antd';

import { cloneDeep } from 'lodash';
import { FormOutlined } from '@ant-design/icons';
import type { ProjectOptions, IDocument } from '../../../../types/types';
import {
  editionActions,
  editionSelectors,
} from '../../../../feature/edition/edition';
import {
  GetProjectDisplayPage,
  GetProjectMaxPages,
} from '../../../../utils/projectHelper';
import { GetText } from '../../../../data/LanguageHelper';
import { GetProjectDocSizeCM, GetDoc } from '../../../../utils/ProductHelper';
import { AlbumProjectCreationView } from '../../projectCreation/AlbumProjectCreationView';
import {
  GetAlbumCoverDisplayName,
  HasCustomCover,
} from '../../../../utils/coverHelper';
import { pricingSelectors } from '../../../../feature/pricing/pricing';
import { PAPER_FINISH, PAPER_QUALITY } from '../../../../data/Constants';
import { Project } from '../../../../data/Project';

const { Paragraph } = Typography;
const { Option } = Select;

class AlbumProjectArea extends React.Component {
  // --------------------- Constructor ------------------------

  constructor(props) {
    super(props);
    this.state = {
      upgradePopupVisible: false,
    };

    // bind functions
    // this.handleDeleteProject = this.handleDeleteProject.bind(this);
  }

  // --------------------- react ------------------------

  componentDidMount() {}

  // shouldComponentUpdate(nextProps) {

  //     if(nextProps.project !==this.props.project )
  //         return true;
  //     if(nextProps.project.name !==this.props.project.name )
  //         return true;
  //     return false;
  // }

  // --------------------- methods ------------------------

  handleProjectNameChange(newName) {
    this.props.dispatch(editionActions.UpdateProjectName(newName));
  }

  handlePaperFinishChange(paperFinish: string, flag) {
    const newProjectOptions: ProjectOptions = { ...this.props.projectOptions };
    if (paperFinish === PAPER_FINISH.COATED) {
      newProjectOptions.coated = flag;
      if (flag) newProjectOptions.matte = false; // be sure to force false to avoid having both options
    } else {
      newProjectOptions.matte = flag;
      if (flag) newProjectOptions.coated = false; // be sure to force false to avoid having both options
    }
    this.props.dispatch(editionActions.UpdateProjectOptions(newProjectOptions));
  }

  handleInsertChange = (val) => {
    const newProjectOptions: ProjectOptions = { ...this.props.projectOptions };
    newProjectOptions.paper = val;
    this.props.dispatch(editionActions.UpdateProjectOptions(newProjectOptions));
  };

  handleCoverFinishChange = (matteCover: boolean) => {
    const newProjectOptions: ProjectOptions = { ...this.props.projectOptions };
    newProjectOptions.cover_matte = matteCover;
    this.props.dispatch(editionActions.UpdateProjectOptions(newProjectOptions));
  };

  handlepagePaperQualityChange = (val) => {
    const options: ProjectOptions = { ...this.props.projectOptions };
    const proj: Project = cloneDeep(this.props.project);
    proj.pagePaperQuality = val;
    const newMaxPages: number = GetProjectMaxPages(proj);

    // check for max pages min pages change!
    if (proj.pageList.length > newMaxPages) {
      const numPagesToDelete = proj.pageList.length - newMaxPages - 1; // -1 for cover
      Modal.confirm({
        title: GetText('popup.paperquality.confirmDelete.title'),
        content: GetText('popup.paperquality.confirmDelete.desc').replace(
          '{NUM}',
          numPagesToDelete
        ),
        okText: GetText('common.confirm'),
        cancelText: GetText('common.cancel'),
        onOk: () => {
          batch(() => {
            // upgrade page amount
            options.pagePaperQuality = val;
            this.props.dispatch(editionActions.UpdateProjectOptions(options));
            this.props.dispatch(
              editionActions.UpgradeCurrentProject(
                { type: proj.type, docID: proj.docID, numPages: newMaxPages },
                false
              )
            );
          });
        },
      });
      return;
    }

    options.pagePaperQuality = val; // val ? PAPER_QUALITY.QUALITY_250:PAPER_QUALITY.QUALITY_170;
    this.props.dispatch(editionActions.UpdateProjectOptions(options));
  };

  // --------------------- render ------------------------

  render() {
    // const { user, project } = this.props;
    const currentProject: Project = this.props.project;
    // let projectName = project.name;
    const numPages: number = currentProject
      ? GetProjectDisplayPage(currentProject)
      : null;
    const projectSizeInCM: string = currentProject
      ? GetProjectDocSizeCM(currentProject.docID)
      : null;

    let doc: IDocument;
    let allowInserts: boolean;
    let pagePaperQualityOptions: Array;
    let coverDetail = null;
    if (currentProject) {
      doc = GetDoc(currentProject.docID);
      allowInserts = doc.insert;
      pagePaperQualityOptions = doc.pagePaperQuality.map(
        (val: number, i, arr) =>
          val === '250' ? PAPER_QUALITY.QUALITY_250 : PAPER_QUALITY.QUALITY_170
      );
      coverDetail = GetAlbumCoverDisplayName(currentProject);
    }

    console.log('RENDER PROJECT AREA');
    const previewURL = currentProject
      ? `${process.env.PUBLIC_URL}/images/ui/menu/lefttabs/project/${currentProject.classname}/types_${currentProject.classname}_${currentProject.type}_${currentProject.docID}.png`
      : null;

    return (
      <div className="tabContent projectArea">
        {this.state.upgradePopupVisible && (
          <AlbumProjectCreationView
            isUpgrade="true"
            onCloseCallback={() => {
              this.setState({ upgradePopupVisible: false });
            }}
          />
        )}

        <h3>{GetText('lefttab.project')}</h3>

        {currentProject && (
          <div>
            <Paragraph
              editable={{
                onChange: (str) => {
                  this.handleProjectNameChange(str);
                },
              }}
            >
              {currentProject.name}
            </Paragraph>

            <h4>{GetText('album.type')}</h4>
            <ul style={{ paddingLeft: '20px', listStyleType: 'circle' }}>
              <li>
                {GetText(`album.type.types_albums_${currentProject.type}`)}
              </li>
              <li>{coverDetail}</li>
              <li>
                {GetText(`album.prefix.${currentProject.docID}`)}:{' '}
                {projectSizeInCM}
              </li>
              <li>
                {numPages} {GetText('common.pages')}
              </li>
            </ul>

            <Button
              type="primary"
              size="default"
              icon={<FormOutlined />}
              onClick={() => {
                this.setState({ upgradePopupVisible: true });
              }}
            >
              {GetText('common.modify')}
            </Button>

            <div className="spacerV" />
            <div className="spacerV" />
            <h4>{GetText('album.option')}</h4>
            <div>
              <Tooltip
                title={GetText('lefttab.project.album.matte')}
                placement="right"
              >
                <Checkbox
                  checked={currentProject.matte}
                  onChange={(e) =>
                    this.handlePaperFinishChange(
                      PAPER_FINISH.MATTE,
                      e.target.checked
                    )
                  }
                  style={{
                    display: currentProject.matte ? 'inline-flex' : 'none',
                  }}
                >
                  {GetText('lefttab.project.album.matte')}{' '}
                  {`(${this.props.projectOptionsPrice.matte.toFixed(2)}€)`}
                </Checkbox>
              </Tooltip>
            </div>
            <div>
              <Tooltip
                title={GetText('infos.lefttab.albums.coating')}
                placement="right"
              >
                <Checkbox
                  checked={currentProject.coated}
                  onChange={(e) =>
                    this.handlePaperFinishChange(
                      PAPER_FINISH.COATED,
                      e.target.checked
                    )
                  }
                >
                  {GetText('lefttab.project.album.coated')}{' '}
                  {`(${this.props.projectOptionsPrice.coated.toFixed(2)}€)`}
                </Checkbox>
              </Tooltip>
            </div>
            <div>
              <Tooltip
                title={GetText('infos.lefttab.albums.pergamine')}
                placement="right"
              >
                <Checkbox
                  disabled={!allowInserts}
                  checked={currentProject.paper}
                  onChange={(e) => this.handleInsertChange(e.target.checked)}
                >
                  {GetText('lefttab.project.album.paper')}{' '}
                  {`(${this.props.projectOptionsPrice.paper.toFixed(2)}€)`}
                </Checkbox>
              </Tooltip>
            </div>

            {HasCustomCover(currentProject) && (
              <div>
                <Tooltip
                  title={GetText('infos.lefttab.albums.cover.matte')}
                  placement="right"
                >
                  <Checkbox
                    checked={currentProject.cover_matte}
                    onChange={(e) =>
                      this.handleCoverFinishChange(e.target.checked)
                    }
                  >
                    {GetText('lefttab.project.album.cover.matte')}{' '}
                    {`(${this.props.projectOptionsPrice.cover_matte.toFixed(
                      2
                    )}€)`}
                  </Checkbox>
                </Tooltip>
              </div>
            )}

            <div>
              {/* <Tooltip title={GetText("leftab.project.album.paper.quality.label")} placement="right" ><Checkbox checked={currentProject.pagePaperQuality === PAPER_QUALITY.QUALITY_250} onChange={(e) => this.handlepagePaperQualityChange( e.target.checked )}>{GetText("leftab.project.album.paper.quality.250")}</Checkbox></Tooltip> */}

              <h5 style={{ paddingTop: '12px' }}>
                {GetText('leftab.project.album.paper.quality.label')}
              </h5>
              <Select
                // defaultValue="lucy"
                style={{ width: '100%' }}
                value={currentProject.pagePaperQuality}
                size="small"
                // placeholder="Select a Product"
                disabled={pagePaperQualityOptions.length < 2}
                onChange={(val) => this.handlepagePaperQualityChange(val)}
              >
                {Object.keys(pagePaperQualityOptions).map((key) => (
                  <Option
                    key={pagePaperQualityOptions[key]}
                    value={pagePaperQualityOptions[key]}
                  >
                    {GetText(
                      `leftab.project.album.paper.quality.${pagePaperQualityOptions[key]}`
                    )}
                  </Option>
                ))}

                {/* <Option value="jack">Jack</Option>
                    <Option value="lucy">Lucy</Option>
                    <Option value="disabled" disabled>
                        Disabled
                    </Option>
                    <Option value="Yiminghe">yiminghe</Option> */}
              </Select>
            </div>

            <Row className="preview">
              <img src={previewURL} />
            </Row>

            {/* <Row type="flex" justify="end">
                    <Button
                        // onClick={this.handleConfirm}
                        disabled
                        >Upgrade Project</Button>
                 </Row> */}
          </div>
        )}
      </div>
    );
  }
}

// Redux map
function mapStateToProps(state) {
  const { authentication, edition } = state;
  // const { user } = authentication;
  const { project } = edition;

  // we need user and projects
  return {
    // user,
    project,
    // projects
    projectName: editionSelectors.GetProjectName(state),
    projectOptions: editionSelectors.GetProjectOptionsSelector(state),
    projectOptionsPrice: pricingSelectors.GetProjectOptionsPrice(state),
  };
}

// Redux connect
const reduxConnected = connect(mapStateToProps)(AlbumProjectArea);
export { reduxConnected as AlbumProjectArea };
