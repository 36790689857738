import { FRAME_TYPE, InjectPhotoIntoFrame } from '../edition/frameHelper';
import { CreateDefaultImageAsset } from '../photoList/photoHelper';
import { IsClassicCoverProject } from '../../utils/projectHelper';
import { GetCoverSizeInPixel } from '../../utils/coverHelper';
import { mmToPoint } from '../../utils/MeasureUtils';
import { ALBUM_TYPES } from '../../data/Constants';
import { IsAlbumEditor } from '../../data/config';

export const BackgroundHelper = {
  GetValidBackgroundListForSizeRatio,
  GetValidInternalBackgroundList,
  GetValidCoverBackgroundList,
};

/**
 *
 * @param {string} id
 * @param {number} width
 * @param {number} height
 * @returns {BackgroundItem}
 */
export const CreateBackroundItem = (id, width, height) => ({
  ...CreateDefaultImageAsset(id, id),
  width: Number(width),
  height: Number(height),
  realWidth: null,
  realHeight: null,
  proxy: null,
});

// export class BackgroundItem extends ImageAsset
// {
// constructor( id, width, height )
// {
// super(id, id);
// this.width = Number(width);
// this.height = Number(height);
// this.realWidth = null;
// this.realHeight = null;
// this.proxy = null;
// }
// }

/**
 *
 * @param {IPage} page : the page that needs to be updated
 * @param {BackgroundItem} newLayout : the new layout to apply to this page
 */
export function ApplyBackgroundToPage(page, backgroundItem) {
  const backgroundFrame = page.frames[0];
  InjectBackgroundIntoFrame(backgroundFrame, backgroundItem);
}

/**
 *
 * @param {Frame} frame
 * @param {BackgroundItem} background
 */
export function InjectBackgroundIntoFrame(frame, background) {
  // security
  if (frame.type === FRAME_TYPE.BKG) {
    // as background has the same params as photo, we can safely inject background as a photo
    InjectPhotoIntoFrame(frame, background);

    // but we need to be sure this is handled as background
    frame.background = background.id;
    frame.photo = null;
  } else alert(`This frame is not a background!!!: ${JSON.stringify(frame)}`);
}

/**
 *
 * @param {IPage} page
 * @param {String} fillColor
 */
export function ApplyBackgroundFillToPage(page, fillColor) {
  const pageBackground = page.frames[0];

  // security
  if (pageBackground.type === FRAME_TYPE.BKG) {
    // but we need to be sure this is handled as background
    pageBackground.cTop = 0;
    pageBackground.cLeft = 0;
    pageBackground.zoom = 1;
    pageBackground.background = null;
    pageBackground.photo = null;
    pageBackground.fillColor = fillColor;
  } else
    alert(
      `This frame is not a background!!!: ${JSON.stringify(pageBackground)}`
    );
}

// --------------------- Background size ------------------------

/**
 * upgrade a new background frame to new bakground bounds
 * @param {Frame} frame
 * @param {Rectangle} backgroundBounds
 * @param {*} backgroundsByID
 */
export function UpgradeFrameBackground(
  frame,
  backgroundBounds,
  backgroundsByID
) {
  const scaleX = backgroundBounds.width / frame.width;
  const scaleY = backgroundBounds.height / frame.height;
  const zoomScale = scaleX > scaleY ? scaleX : scaleY; // use biggest zoom to be sure we are still correctly linked in frame

  frame.width = backgroundBounds.width;
  frame.height = backgroundBounds.height;
  frame.x = backgroundBounds.x;
  frame.y = backgroundBounds.y;

  // re-inject background with optimal zoom and size!
  if (
    frame.background &&
    backgroundsByID &&
    // eslint-disable-next-line no-prototype-builtins
    backgroundsByID.hasOwnProperty(frame.background)
  ) {
    const backgroundItem = backgroundsByID[frame.background];
    InjectBackgroundIntoFrame(frame, backgroundItem);
  }
  // simply be sure the zoom is kept
  else if (frame.photo || frame.background) {
    frame.cLeft *= zoomScale;
    frame.cTop *= zoomScale;
    frame.zoom *= zoomScale;
  }
}

/**
 * retrieve the page background bounds (in pixel)
 * (it has specific bleed of 10mm) and take the spine into calculation
 * @param {Project} project
 * @returns {Rectangle}
 */
export function getPageBackgroundRect(project) {
  const bleed = GetPageBleedInPixel(project, false);
  return {
    width: project.width + bleed * 2,
    height: project.height + bleed * 2,
    x: project.width * 0.5,
    y: project.height * 0.5,
  };
}

/**
 * retrieve the cover background bounds (it has specific bleed of 10mm) and take the spine into calculation
 * @param {Project} project
 * @returns {Rectangle}
 */
export function getCoverBackgroundRect(project) {
  const bleed = GetPageBleedInPixel(project, true);
  const coverSize = GetCoverSizeInPixel(project);
  return {
    width: coverSize.width + bleed * 2,
    height: coverSize.height + bleed * 2,
    x: coverSize.width * 0.5,
    y: coverSize.height * 0.5,
  };
}

/**
 * @param {Project} project
 * @param {Boolean} forCover
 * @returns {number}
 */
export function GetPageBleedInPixel(project, forCover) {
  // TODO: handle for canvas : var pageBleed :number = (Infos.isCanvas)? CanvasManager.instance.edgeSize : MeasureManager.millimeterToPixel(4);
  let bleedMM = 4; // always 4 mm for internal pages
  if (forCover) {
    if (project.type === ALBUM_TYPES.CASUAL) bleedMM = 4;
    // 4mm for casuals
    else if (project.type === ALBUM_TYPES.TRENDY) bleedMM = 7;
    // 7mm for trendy
    else bleedMM = 10; // 10mm by default for covers
  }

  // return in value in pixel
  return mmToPoint(bleedMM);
}

/**
 * @param {Project} project
 * @param {Array<BackgroundItem>} backgroundList
 * @returns
 */
function GetValidInternalBackgroundList(project, backgroundList) {
  let pageRatio = 1;
  if (IsAlbumEditor())
    pageRatio = project.pageList[1].width / project.pageList[1].height;
  else pageRatio = project.pageList[0].width / project.pageList[0].height;
  return GetValidBackgroundListForSizeRatio(pageRatio, backgroundList);
}

function GetValidCoverBackgroundList(project, backgroundList) {
  if (IsAlbumEditor() && !IsClassicCoverProject(project.type)) {
    const pageRatio = project.pageList[0].width / project.pageList[0].height;
    return GetValidBackgroundListForSizeRatio(pageRatio, backgroundList);
  }
  return [];
}

function GetValidBackgroundListForSizeRatio(sizeRatio, backgroundList) {
  const result = backgroundList?.filter(
    (backgroundItem) =>
      Math.abs(1 - backgroundItem.width / backgroundItem.height / sizeRatio) <
      0.2
  );
  // return background having almost the same ratio (20% margin)
  // // return Object.keys(byID).map( key => byID[key]).filter( backgroundItem =>
  //   (   ( projectOrientation === "square" ) // allow all backgrounds for square projects.
  //   ||  ( (backgroundItem.realWidth/backgroundItem.realHeight) > 1 && projectOrientation === "landscape" )
  //   ||  ( (backgroundItem.realWidth/backgroundItem.realHeight) < 1 && projectOrientation === "portrait" )
  return result;
}

// /**
//  *
//  * @param {Frame} frameObj
//  * @param {Background} backgroundObj
//  */
// export function InjectBackgroundIntoFrame( frameObj:Frame, backgroundObj:Background )
// {
// // be sure to remove possible photo info
// frameObj.photo = null;

// // calculate correct zoom
// const photoObj = backgroundObj;
// const frameRatio = frameObj.width / frameObj.height;
// const photoRatio = photoObj.width / photoObj.height;
// if(frameRatio > photoRatio){
// frameObj.zoom = frameObj.width / photoObj.width;
// }
// else{
// frameObj.zoom = frameObj.height / photoObj.height;
// }

// // crop left and right
// frameObj.cLeft = ((photoObj.width * frameObj.zoom) - frameObj.width)/2;
// frameObj.cTop = ((photoObj.height * frameObj.zoom) - frameObj.height)/2;

// // add background ref
// frameObj.background = backgroundObj.id;

// return frameObj;
// }
