import React from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Button,
  Tooltip,
  Popover,
  Select,
  Slider,
  Radio,
  Switch,
} from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import { cloneDeep, isObject, isEqual } from 'lodash';
import TextArea from 'antd/lib/input/TextArea';
import { CirclePicker } from 'react-color';
import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  BgColorsOutlined,
  BoldOutlined,
  BorderOutlined,
  CopyOutlined,
  DeleteOutlined,
  DesktopOutlined,
  DiffOutlined,
  EditOutlined,
  FileImageOutlined,
  FontSizeOutlined,
  FormOutlined,
  InfoOutlined,
  ItalicOutlined,
  LeftOutlined,
  RedoOutlined,
  RightOutlined,
  ScissorOutlined,
  TableOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import type { Photo, Frame, IPage, IFrameShadow } from '../../types/types';
import TransformTool, {
  TRANFORM_TOOL_ACTION,
} from './EditionArea/TransformTool';
import { RandomRange } from '../../utils/MathUtils';
import { PageArea } from './EditionArea/PageArea';
import { InsidePageArea } from './EditionArea/InsidePageArea';
import {
  editionActions,
  editionSelectors,
} from '../../feature/edition/edition';
import {
  getPageGroup,
  getPageGroupDisplayName,
  PageContainsFrame,
  GetPageOfFrame,
  GetPageFrameByID,
} from '../../utils/pageHelper';
import {
  photoListActions,
  photoListSelector,
} from '../../feature/photoList/photoList';
import { AddClipartToPage } from '../../feature/cliparts/clipartHelper';
import {
  ApplyBackgroundToPage,
  getPageBackgroundRect,
  getCoverBackgroundRect,
} from '../../feature/backgrounds/backgroundHelper';
import { GetDragData, DRAG_TYPE } from '../../utils/DragUtils';
import { ApplyLayoutToPage, Layout } from '../../feature/layouts/layoutHelper';
import { ArrayMove } from '../../utils/ArrayUtils';
import { DebugFlags, IsLocalhost } from '../../debug/DebugFlags';
import { ProjectPageModifierPopup } from './EditionArea/ProjectPageModifierPopup';
import { GetText } from '../../data/LanguageHelper';
import { Colors } from '../../data/Colors';
import { mmToPoint, pixelToCm } from '../../utils/MeasureUtils';
import { UISelectors, UIActions, GRID_MODE } from '../../feature/ui/ui';
import ImgIcon from '../../_components/ImgIcon';
import { getDefaultFrameShadow } from '../../feature/edition/ShadowHelper';
import { CalendarColorsModifierPopover } from './EditionArea/CalendarColorsModifierPopover';
import { GetDoc } from '../../utils/ProductHelper';
import { CanvasHelper, CANVAS_TYPES } from '../../utils/canvas/CanvasHelper';
import { layoutListSelectors } from '../../feature/layouts/layoutList';
import ColorPicker from '../../_components/ColorPicker';
import { overlayerSelectors } from '../../feature/overlayers/overlayers';
import { popupHelper } from '../../feature/alert/popupHelper';
import { OverlayerHelper } from '../../feature/overlayers/overlayerHelper';
import { ImageEditor } from '../../_components/ImageEditor';
import { DateUtils } from '../../utils/DateUtils';
import {
  ClearFrame,
  CreateFrame,
  CreateNewFrameText,
  defaultTextOptions,
  frameCanChangeDepth,
  frameCanHaveBorder,
  frameCanHaveMask,
  frameCanHavePhoto,
  frameCanHaveShadow,
  frameCanHaveText,
  frameCanInsideRotation,
  frameCanMove,
  frameCanPhotoEdit,
  frameCanSwapDrop,
  frameCanSwapStart,
  frameHasDeleteButton,
  FRAME_TYPE,
  getFrameMaxZoom,
  getFrameMinZoom,
  InjectPhotoIntoFrame,
  IsFrameEmpty,
  MAX_ALLOWED_FRAME_ZOOM,
} from '../../feature/edition/frameHelper';
import {
  IsAlbumEditor,
  IsCalendarEditor,
  IsCanvasEditor,
  IsCardEditor,
  PROJECT_CONST,
} from '../../data/config';
import { FONT_LIST_EMBED, FONT_SIZES_LIST } from '../../data/fonts';
import { Project } from '../../data/Project';
import { EmbedIcons } from '../../images/EmbedIcons';

class EditionArea extends React.Component {
  constructor(props) {
    super(props);

    // const
    this.tooltipDelay = 1;

    // state
    this.state = {
      rootX: null,
      rootY: null,
      transformBounds: null,
      editedPage: null, // Page Object -> we use it only when editing something, then is set to null again. So we render from edited page when we are in edition, and from redux when we are not!
      editedFrame: null, // Frame Object
      editionScale: 1, // is the scale factor of the svg
      editAction: null, // action sent by transform tool

      displayBorderToolbar: false,
      displayShadowToolbar: false,
      displayMaskToolbar: false,
      swapFrameID: null, // id of frame photo currently active for swapping
    };

    // create refs
    this.editionAreaRef = React.createRef();
    this.pageContainerRef = React.createRef();
    this.transformToolRef = React.createRef();
    this.textInputRef = React.createRef();
    this.stageAreaRef = React.createRef();
  }

  // --------------------- react ------------------------

  componentDidMount() {
    // const boundingBox = ReactDOM.findDOMNode(this).getBoundingClientRect();
    // this.setState({
    //     rootX: boundingBox.x,
    //     rootY: boundingBox.y,
    // });

    // leave warning
    window.addEventListener('beforeunload', this.handleCheckLeaveWarning);
    // resize
    window.addEventListener('resize', this.handleEditionResize);
    this.handleEditionResize();
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedPageIndex, pageList } = this.props;
    const { editedFrame, transformBounds, editedPage } = this.state;

    // change transform bounds
    if (this.transformToolRef.current) {
      const newTransformBounds =
        this.transformToolRef.current.transformRef.current.getBoundingClientRect();
      if (
        !transformBounds ||
        newTransformBounds.x !== transformBounds.x ||
        newTransformBounds.y !== transformBounds.y ||
        newTransformBounds.width !== transformBounds.width ||
        newTransformBounds.height !== transformBounds.height
      ) {
        this.setState({
          transformBounds: newTransformBounds,
        });
      }
    }

    // --- PAGE HAS BEEN CHANGED
    if (prevProps.selectedPageIndex !== this.props.selectedPageIndex) {
      const editedPage = pageList[selectedPageIndex];

      // check if we have a edited frame, we keep it if the new page contains it
      let keepFrame;
      if (editedFrame) {
        editedPage.frames.forEach((frame, index, arr) => {
          if (frame.id === editedFrame.id) {
            keepFrame = frame;
          }
        });
      }

      this.setState({
        ...this.state,
        // editedPage: editedPage, // we should only have edited page when editing something
        editedFrame: cloneDeep(keepFrame),
        swapFrameID: null, // reset swapping frame
      });
    }

    // if we are not editing a page (!editepage)
    // and we have a selected frame currently, we need to be sure this is the same as the one from the store
    if (
      !editedPage &&
      editedFrame &&
      editedFrame.id === this.props.selectedFrameID
    ) {
      const storeFrame = cloneDeep(
        GetPageFrameByID(this.props.currentPage, editedFrame.id)
      );
      if (!isEqual(storeFrame, editedFrame)) {
        this.setState({
          ...this.state,
          editedFrame: storeFrame,
        });
      }
    }

    // if selectedframe has been cleared, we need to be sure to remove it here
    if (
      editedFrame &&
      (!this.props.selectedFrameID ||
        editedFrame.id !== this.props.selectedFrameID)
    ) {
      // TODO: the line above was added to allow the selected frame to be
      // if( editedFrame && !this.props.selectedFrameID)
      this.setState({
        editedFrame: null,
      });
    }

    // update editor scale factor
    this.updateEditorScaleFactor();

    // always focus on text area if open!
    if (this.textInputRef.current) {
      this.textInputRef.current.focus();
    }

    // Case swapping!
    if (
      this.state.swapFrameID &&
      editedFrame &&
      editedFrame.id !== this.state.swapFrameID &&
      frameCanSwapDrop(editedFrame)
    ) {
      // handle FrameSwap!
      this.props.dispatch(
        editionActions.SwapFrameContent(this.state.swapFrameID, editedFrame.id)
      );
      this.setState({ swapFrameID: null });
    }
  }

  componentWillUnmount() {
    // leave warning
    window.removeEventListener('beforeunload', this.handleCheckLeaveWarning);
    // resize
    window.removeEventListener('resize', this.handleEditionResize);
  }

  // --------------------- Methods ------------------------

  handleCheckLeaveWarning = (e) => {
    if (this.props.needSave || this.props.hasPhotosImporting) {
      popupHelper.ShowLeaveWarningPopup(this.props.hasPhotosImporting);

      const confirmationMessage = this.props.hasPhotosImporting
        ? 'Photos still importing, do not leave!'
        : 'Please save your project before leaving!';
      (e || window.event).returnValue = confirmationMessage; // Gecko + IE
      return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
    }
    return null;
  };

  handleEditionResize = (e) => {
    this.updateEditorScaleFactor();
  };

  updateEditorScaleFactor = () => {
    // update edition scale!
    if (this.pageContainerRef) {
      const { selectedPageIndex } = this.props;
      const pageDomElement = document.getElementById(
        `page_${this.props.selectedPageIndex}`
      );
      if (!pageDomElement) {
        return;
      }

      // const pageBounds =this.pageContainerRef.current.getBoundingClientRect();
      const pageBounds = pageDomElement.getBoundingClientRect();
      const scaleFactor =
        pageBounds.width / this.props.pageList[selectedPageIndex].width;

      // const svgSize = this.getPageGroupSize();
      // const scaleFactorW = pageBounds.width/svgSize.width;
      // const scaleFactorH = pageBounds.height/svgSize.height;
      // const scaleFactor = (scaleFactorH < scaleFactorW)? scaleFactorH : scaleFactorW;

      // console.log("-----------")
      // console.log("bounds : " + JSON.stringify(pageBounds));
      // console.log("svgsize : " + JSON.stringify(svgSize));
      // console.log("scalefactorW : " + scaleFactorW);
      // console.log("scaleFactorH : " + scaleFactorH);
      // console.log("scaleFactor : " + scaleFactor);
      // console.log("-----------")

      // editionarea bounding box
      // const boundingBox = this.editionAreaRef.current.getBoundingClientRect();

      if (
        scaleFactor !== this.state.editionScale ||
        pageBounds.x !== this.state.rootX ||
        pageBounds.y !== this.state.rootY
      ) {
        // alert(`rootX:${pageBounds.x} rootY:${pageBounds.y}`)
        // alert("PAGE BOUNDS:" + JSON.stringify(pageBounds))
        this.setState({
          editionScale: scaleFactor,
          rootX: pageBounds.x,
          rootY: pageBounds.y,
        });
      }
    }
  };

  /**
   * Start editing
   * when editing, we create a copy of the page from the Store
   * We work on this copy until edition is over.
   * Then we save to the store the edited page
   */
  startPageEditing(editAction): Promise {
    return new Promise((resolve, reject) => {
      const { pageList, selectedPageIndex } = this.props;
      const { editedFrame } = this.state;
      const editedPage = cloneDeep(pageList[selectedPageIndex]);

      // check if we already have an edited frame,
      // we keep it if the new page contains it
      let keepFrame;
      if (editedFrame) {
        editedPage.frames.forEach((frame, index, arr) => {
          if (frame.id === editedFrame.id) {
            keepFrame = frame;
          }
        });
      }

      // set edited page
      this.setState(
        {
          ...this.state,
          editedPage,
          editedFrame: keepFrame,
          editAction,
        },
        () => resolve()
      );
    });
  }

  endPageEditing(clearSelectedFrame = false) {
    const { editedPage, editedFrame } = this.state;

    if (!editedPage) {
      console.warn('we should never endPageEdition with no edited page...');
      return;
    }

    // ---- Move to other page ----
    // check if frame must be moved to other page!
    if (
      !clearSelectedFrame &&
      editedFrame &&
      (editedFrame.x < 0 || editedFrame.x > editedPage.width)
    ) {
      const currentPageGroup: Array<IPage> = getPageGroup(
        this.props.project.docID,
        editedPage.index,
        this.props.pageList
      );

      console.warn('TODO: frame should be put to other page!');
      // check move to previous page
      if (
        editedFrame.x < 0 &&
        currentPageGroup.length > 1 &&
        editedPage.index === currentPageGroup[1].index
      ) {
        console.warn('TODO: frame CAN be moved to PREVIOUS page!');
      }
      // check move to next page
      else if (
        editedFrame.x > editedPage.width &&
        currentPageGroup.length > 1 &&
        editedPage.index === currentPageGroup[0].index
      ) {
        console.warn('TODO: frame CAN be moved to NEXT page!');
      }
      // frame cannot be moved to another page, we might need to delete it!
      else {
        console.warn('TODO: Might need to delete this frame...');
      }
    }

    // each time we end a page editing, we verify the photoID
    if (editedFrame && editedFrame.photo) {
      editedFrame.photo = this.checkReplaceTempPhotoID(editedFrame.photo);
    }

    // save the page on store
    this.props.dispatch(editionActions.UpdatePage(cloneDeep(editedPage)));
    // remove edited page from state so the we renders the pages from props and not state
    this.setState({
      editedPage: null,
      editedFrame: clearSelectedFrame ? null : editedFrame,
      editAction: null,
    });

    // editing over, add undoable action
    this.props.dispatch(editionActions.AddUndoableAction());
  }

  /**
   * Add empty frame to current page
   */
  handleAddImageToPage() {
    this.startPageEditing().then(() => {
      const { editedPage } = this.state;

      const newFrame = CreateFrame({
        type: FRAME_TYPE.PHOTO,
        width: editedPage.width * 0.3,
        height: editedPage.height * 0.3,
        x: editedPage.width * RandomRange(0.4, 0.6),
        y: editedPage.height * RandomRange(0.4, 0.6),
      });
      // let newFrame = new Frame( FRAME_TYPE.PHOTO, editedPage.width*0.3, editedPage.height*0.3);
      // newFrame.x = editedPage.width*RandomRange(.4, .6);
      // newFrame.y = editedPage.height*RandomRange(.4, .6);
      editedPage.frames.push(newFrame);

      // end edition (save)
      this.endPageEditing();
    });
  }

  handleChangePages() {}

  getInfoOverContent(frame: Frame) {
    const photoItem: Photo = frame.photo
      ? this.props.photosByID[frame.photo]
      : null;
    const imgURL = photoItem
      ? photoItem.temp
        ? photoItem.temp_url
        : photoItem.working_url
      : null;

    const w = 350;
    return (
      <div
        style={{
          width: w,
          maxWidth: w,
          wordWrap: 'break-word',
          fontSize: 12,
        }}
      >
        {photoItem && (
          <div>
            <img
              width={w}
              height={(w * photoItem.height) / photoItem.width}
              src={imgURL}
              alt="thumbnail overlay"
              style={{ marginBottom: 6 }}
            />

            <div>{`${GetText('tooltip.image.info.name')} : ${
              photoItem.name
            }`}</div>
            <div>{`${GetText('tooltip.image.info.size')} : ${photoItem.width}x${
              photoItem.height
            }px`}</div>
            <div>{`${GetText(
              'tooltip.photo.info.creationDate'
            )} : ${DateUtils.ToReadableDate(
              new Date(Number(photoItem.creation_date))
            )}`}</div>
          </div>
        )}
        {IsLocalhost && <div>{JSON.stringify(frame)}</div>}
        {IsLocalhost && photoItem && <div>{JSON.stringify(photoItem)}</div>}
      </div>
    );
  }

  handleAddTextToPage() {
    this.startPageEditing().then(() => {
      const { editedPage } = this.state;
      const newTextFrame = CreateNewFrameText(
        '',
        editedPage.width * 0.3,
        editedPage.height * 0.3
      );
      newTextFrame.x = editedPage.width * RandomRange(0.4, 0.6);
      newTextFrame.y = editedPage.height * RandomRange(0.4, 0.6);
      editedPage.frames.push(newTextFrame);

      // end edition (save)
      this.endPageEditing();
    });
  }

  handleAddDefaultTextToFrame() {
    this.startPageEditing().then(() => {
      const { editedFrame } = this.state;
      editedFrame.text = { ...defaultTextOptions };
      this.endPageEditing();
    });
  }

  handleFrameInsideRotate() {
    this.startPageEditing().then(() => {
      const { editedPage } = this.state;
      let { editedFrame } = this.state;

      // check for temp photo
      editedFrame = this.checkCorrectEditedTempPhoto(editedFrame);

      // invert width and height
      const tempW = editedFrame.width;
      const tempC = editedFrame.cLeft;
      editedFrame.width = editedFrame.height;
      editedFrame.height = tempW;

      // update rotation
      editedFrame.rotation += 90;
      if (editedFrame.rotation > 360) {
        editedFrame.rotation -= 360;
      }

      // update image
      editedFrame = InjectPhotoIntoFrame(
        editedFrame,
        this.props.photosByID[editedFrame.photo],
        true
      );

      this.endPageEditing();
    });
  }

  handleFrameMoveUp() {
    this.moveEditedFrameIndex(1);
  }

  handleFrameMoveDown() {
    this.moveEditedFrameIndex(-1);
  }

  moveEditedFrameIndex(moveAdd) {
    this.startPageEditing().then(() => {
      const { editedPage, editedFrame } = this.state;
      let index = -1;
      // find index
      editedPage.frames.forEach((f, i) => {
        if (f.id === editedFrame.id) {
          index = i;
        }
      });

      // TODO: check security here, this should never happen!
      if (index === -1) {
        alert(`FRAME with ID ${editedFrame.id} not found in edited page`);
      } else {
        const newIndex = index + moveAdd;

        // do not allow to go below index 1 (as background is index 0)
        if (newIndex < 1 || newIndex > editedPage.frames.length - 1) {
          // alert("FRAME CANNOT BE AT INDEX "+newIndex); // FAIL SILENTELY
        } else {
          ArrayMove(editedPage.frames, index, newIndex);
        }
      }

      this.endPageEditing();
    });
  }

  // --------------------- Frame Zoom ------------------------

  handleFrameZoom(zoomValue) {
    // if we are not editing the page currently, start editing
    if (!this.state.editedPage) {
      return this.handleEditedFrameStart(null, TRANFORM_TOOL_ACTION.ZOOM).then(
        () => {
          this.updateFrameZoom(zoomValue);
        }
      );
    }
    return this.updateFrameZoom(zoomValue);
  }

  handleFrameZoomEnd(zoomValue) {
    this.handleFrameZoom(zoomValue).then(() => {
      // this.updateFrameZoom(zoomValue).then(()=>{
      this.endPageEditing();
    });
  }

  updateFrameZoom(newZoomValue) {
    return new Promise((resolve, reject) => {
      const { editedPage } = this.state;
      let { editedFrame } = this.state;

      // update zoom
      editedFrame = this.checkCorrectEditedTempPhoto(editedFrame);
      const pic: Photo = this.props.photosByID[editedFrame.photo];
      if (!pic || !pic.width) {
        reject('no pic found');
      }

      const diffX = pic.width * newZoomValue - pic.width * editedFrame.zoom;
      const diffY = pic.height * newZoomValue - pic.height * editedFrame.zoom;
      editedFrame.zoom = newZoomValue;
      editedFrame.cLeft += diffX / 2;
      editedFrame.cTop += diffY / 2;

      // update photo crop values and position (manage limits of zoom/cleft/ctop)
      editedFrame = InjectPhotoIntoFrame(
        editedFrame,
        this.props.photosByID[editedFrame.photo],
        true
      );

      // update state
      this.setState(
        {
          ...this.state,
          editedPage,
          editedFrame,
          editAction: TRANFORM_TOOL_ACTION.ZOOM,
        },
        () => resolve()
      );
    });
  }

  zoomTooltipFormatter(value) {
    return `Zoom: ${(value * 100).toFixed(1)}%`;
  }

  // --------------------- Frame Delete ------------------------

  handleFrameDelete() {
    const { editedFrame } = this.state;

    // do nothing if no edited frame!
    if (!editedFrame) {
      alert('NO FRAME TO REMOVE, button should not be active');
      return;
    }

    this.startPageEditing().then(() => {
      const { editedPage } = this.state;
      const { editedFrame } = this.state;

      // if frame is background, we actually need to clear it
      if (editedFrame.type === FRAME_TYPE.BKG) {
        editedFrame.photo = null;
        editedFrame.background = null;
        this.endPageEditing();
      } else if (
        editedFrame.type === FRAME_TYPE.SPINE ||
        editedFrame.type === FRAME_TYPE.SPINE_NUM
      ) {
        editedFrame.text.value = '';
        this.endPageEditing();
      }
      // clear photo if not empty
      else if (
        editedFrame.type === FRAME_TYPE.PHOTO &&
        !IsFrameEmpty(editedFrame)
      ) {
        ClearFrame(editedFrame);
        this.endPageEditing();
      }
      // clear photo in calendar frames
      else if (editedFrame.type === FRAME_TYPE.CALENDAR) {
        ClearFrame(editedFrame);
        this.endPageEditing();
      }

      // otherwise we remove frame
      else {
        editedPage.frames = editedPage.frames.filter(
          (val, index, arr) => val.id !== editedFrame.id
        );

        // update state
        this.setState(
          {
            editedFrame: null,
          },
          () => {
            // end edition
            this.endPageEditing();
          }
        );
      }
    });
  }

  // addNewFrameAndSave( newFrame )
  // {
  //     let stateCopy = Object.assign({}, this.state);
  //     stateCopy.editedPage.frames = stateCopy.editedPage.frames.concat(newFrame);

  //     // update state
  //     this.setState({
  //         editedPage: stateCopy.editedPage,
  //     });

  //     // save to global store
  //     this.saveChanges();
  // }

  // savePageChanges( editedPage : Page )
  // {
  //     //let updatedPage = {...this.props.editedPage};
  //     // this.props.dispatch(editionActions.UpdatePage(this.state.editedPage));
  //     this.props.dispatch(editionActions.UpdatePage( cloneDeep(editedPage) ));
  //     // dispatch to store
  //     /*
  //     this.props.dispatch({
  //         type: 'OBJECTS_CANVAS_CHANGE',
  //         payload: {
  //             // send complete fabric canvas object to store
  //            canvasObject: this.state.frames,
  //             // also keep lastly active (selected) object
  //            selectedObject: null // for now it is null
  //         }
  //      });
  //      */

  //      /*
  //      // TODO: use action creator instead..
  //     this.props.dispatch({
  //         type: 'PAGE_UPDATED',
  //         payload: {
  //            page: this.state.editedPage,
  //         }
  //      });
  //      */

  // }

  /**
   * Page navigation
   */
  handleNextPageClick() {
    const { selectedPageIndex, pageList, project } = this.props;
    const currentPageGroup: Array<IPage> = getPageGroup(
      project.docID,
      selectedPageIndex,
      pageList
    );

    const lastPageIndex = currentPageGroup[currentPageGroup.length - 1].index;
    let newPage = lastPageIndex + 1;
    // let newPage = (selectedPageIndex%2===0)? selectedPageIndex+2 : selectedPageIndex +1;
    const limit = pageList.length - 1;
    if (newPage > limit) newPage = limit;
    this.goTopage(newPage);
  }

  handlePreviousPageClick() {
    const { selectedPageIndex } = this.props;
    let newPage =
      selectedPageIndex % 2 === 0
        ? selectedPageIndex - 1
        : selectedPageIndex - 2;
    if (newPage < 0) newPage = 0;
    this.goTopage(newPage);
  }

  handleFirstPageClick() {
    this.goTopage(0);
  }

  handleLastPageClick() {
    this.goTopage(this.props.pageList.length - 1);
  }

  goTopage(newPage) {
    this.props.dispatch(editionActions.ChangePage(newPage));
  }

  handleUndo() {
    this.props.dispatch(editionActions.Undo());
  }

  handleRedo() {
    this.props.dispatch(editionActions.Redo());
  }

  handleFrameCopy() {
    this.props.dispatch(editionActions.CopyFrame());
  }

  handleFrameCut() {
    this.props.dispatch(editionActions.CutFrame());
  }

  handleFramePaste() {
    this.props.dispatch(editionActions.PasteFrame());
  }

  /** handle edited frame double click */
  handleEditedFrameDoubleClick() {
    // case frame photo, if empty, start import

    // case frame test, focus on edited frame text
    // THIS IS Bad, but I need to make a generic way of uploading outside the view...
    const { editedFrame } = this.state;

    if (editedFrame) {
      // photo
      if (
        (editedFrame.type === FRAME_TYPE.PHOTO ||
          editedFrame.type === FRAME_TYPE.OVERLAYER) &&
        IsFrameEmpty(editedFrame)
      ) {
        const uploader = document.getElementById(`${editedFrame.id}_uploader`);
        if (uploader) {
          uploader.click();
        }
      }
      // texst
      else if (editedFrame && editedFrame.type === FRAME_TYPE.TEXT) {
        const toolbarTextEditButton = document.getElementById(
          'toolbarTextEditButton'
        );
        if (toolbarTextEditButton) {
          toolbarTextEditButton.click();
        }
      }
    }
  }

  /**
   * FRAME edition
   */
  handleEditedFrameStart(newFrameState: Frame, editAction = null): Promise {
    return this.startPageEditing(editAction);
  }

  /**
   *
   * @param {Fame} newFrameState
   * We receive the updated frame object from the transforml too
   * -> we update the edited page and frame from the state
   * -> once the edition is over, we push to redux store and remove edited page so we render only from props.
   */
  handleEditedFrameUpdate(newFrameState: Frame, refreshContent = false) {
    if (this.state.editedFrame) {
      let { editedPage, editedFrame } = this.state;

      // refresh content inside the frame (for example on zoom, scale, crop etc..)
      if (refreshContent) {
        // when changing scaling, if there is an image, we need to update image too!
        // let frameRatio = frame.width/frame.height;
        // let newFrameRatio = newFrameState.width/newFrameState.height;
        // if(frame.photo && (frameRatio !== newFrameRatio || frame.zoom !== newFrameState.zoom)){
        //     newFrameState = InjectPhotoIntoFrame( newFrameState, this.props.photos[newFrameState.photo]);
        // }
        // refresh photo
        if (editedFrame.photo) {
          // retreive photo object
          editedFrame = this.checkCorrectEditedTempPhoto(editedFrame);
          const photoObj = this.props.photosByID[newFrameState.photo];
          editedFrame = InjectPhotoIntoFrame(newFrameState, photoObj, true);
        }
      }

      // update state
      this.setState({
        editedPage,
        editedFrame,
      });
    }
  }

  /**
   * Handle frame update complete from the transforml tool (on modification complete)
   */
  handleEditedFrameUpdateComplete(newFrameState) {
    // TODO: we should also add an action for the UNDO/REDO?
    this.endPageEditing();
  }

  /** this verify with currently edited frame photo that we correctly have a photo object */
  checkCorrectEditedTempPhoto(frame) {
    if (frame.photo) {
      frame.photo = this.checkReplaceTempPhotoID(frame.photo);
    }
    return frame;
  }

  /*
      give a photo ID and check if it correctly exist!
  */
  checkReplaceTempPhotoID(photoID): string {
    // try to get photo by ID
    const photoObj = this.props.photosByID[photoID];
    if (!photoObj) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.props.tempPhotoMapping.hasOwnProperty(photoID)) {
        photoID = this.props.tempPhotoMapping[photoID];
      } else {
        console.warn(`Not able to find photo object for ID:${photoID}`);
      }
    }
    return photoID;
  }

  /**
   * handle drag and drop of photo on the transform tool!
   * @param {string} photoID
   */
  handleItemDroppedOnTransformTool(evt) {
    const { editedFrame } = this.state;
    this.handleItemDropOnFrame(evt, editedFrame);

    // this.startPageEditing().then(( editedPage )=>{
    //     const {editedFrame } = this.state;
    //     const { photos } = this.props;
    //     InjectPhotoIntoFrame( editedFrame, photos[photoID]);
    //     this.endPageEditing();
    // })
  }

  /**
   * Handle item drop on frame
   */
  handleItemDropOnFrame(evt, frame: Frame) {
    // TODO: here we drop on a frame that is not on the current page, we need to change page!!
    const { selectedPageIndex, pageList } = this.props;
    let { currentPage, project } = this.props;

    if (!PageContainsFrame(currentPage, frame.id)) {
      currentPage = GetPageOfFrame(pageList, frame.id);
      if (!currentPage) {
        throw new Error(
          `frame with id:${frame.id} not found in page list... => ${pageList.length}`
        );
        return;
      }
      this.props.dispatch(editionActions.ChangePage(currentPage.index)); // first change page
    }

    // try to get exact position on page
    const pageDomElement = document.getElementById(`page_${selectedPageIndex}`);
    let pageBounds = null;
    const dropPoint = {
      x: currentPage.width / 2,
      y: currentPage.height / 2,
    };
    if (pageDomElement) {
      pageBounds = pageDomElement.getBoundingClientRect();
      dropPoint.x =
        (evt.clientX - pageBounds.left) *
        (currentPage.width / pageBounds.width);
      dropPoint.y =
        (evt.clientY - pageBounds.top) *
        (currentPage.height / pageBounds.height);
    }

    // check for type of drop
    let photoID = GetDragData(evt, DRAG_TYPE.PHOTO);
    const clipartID = GetDragData(evt, DRAG_TYPE.CLIPART);
    const backgroundID = GetDragData(evt, DRAG_TYPE.BACKGROUND);
    const layoutID = GetDragData(evt, DRAG_TYPE.LAYOUT);
    const overlayerID = GetDragData(evt, DRAG_TYPE.OVERLAYER);

    // ---- PHOTO ----
    if (photoID && frameCanHavePhoto(frame)) {
      photoID = this.checkReplaceTempPhotoID(photoID);
      const { photosByID } = this.props;
      const frameCopy = cloneDeep(frame);
      InjectPhotoIntoFrame(frameCopy, photosByID[photoID]);
      this.props.dispatch(editionActions.UpdateFrame(frameCopy));
      this.props.dispatch(editionActions.AddUndoableAction());
    }

    // case layout
    else if (layoutID) {
      const pageCopy: IPage = cloneDeep(currentPage);
      const layout: Layout = this.props.layoutsByID[layoutID];

      ApplyLayoutToPage(
        this.props.project,
        pageCopy,
        this.props.layoutsByID[layoutID],
        this.props.photosByID,
        this.props.backgroundsByID,
        pageCopy.isCover
          ? getCoverBackgroundRect(project)
          : getPageBackgroundRect(project)
      );
      this.props.dispatch(editionActions.UpdatePage(pageCopy));
      this.props.dispatch(editionActions.AddUndoableAction());
    }

    // case background
    else if (backgroundID) {
      const pageCopy = cloneDeep(currentPage);
      ApplyBackgroundToPage(pageCopy, this.props.backgroundsByID[backgroundID]);
      this.props.dispatch(editionActions.UpdatePage(pageCopy));
      this.props.dispatch(editionActions.AddUndoableAction());

      // orce update
      this.setState({ editedFrame: null }); // TODO: bug here when going from photo to background (inside move stays..) so we force a "unselect!"
      // this.props.dispatch( editionActions.ChangeSelectedFrame( frame.id ));
    }

    // case clipart
    else if (clipartID) {
      const pageCopy = cloneDeep(currentPage);
      AddClipartToPage(pageCopy, this.props.clipartsByID[clipartID], dropPoint);
      this.props.dispatch(editionActions.UpdatePage(pageCopy));
      this.props.dispatch(editionActions.AddUndoableAction());
    }

    // case overlayer
    else if (overlayerID) {
      // case drop overlayer on frame photo
      if (
        (frame.type === FRAME_TYPE.PHOTO ||
          frame.type === FRAME_TYPE.OVERLAYER) &&
        frame.photo
      ) {
        const frameCopy = cloneDeep(frame);
        const photoID = this.checkReplaceTempPhotoID(frame.photo);
        OverlayerHelper.AddOverlayerToFrame(
          frameCopy,
          this.props.overlayersByID[overlayerID],
          this.props.photosByID[photoID]
        );

        this.props.dispatch(editionActions.UpdateFrame(frameCopy));
        this.props.dispatch(editionActions.AddUndoableAction());
      }
      // otherwise add new overlayer to page!
      else {
        const pageCopy = cloneDeep(currentPage);
        OverlayerHelper.AddOverlayerToPage(
          pageCopy,
          this.props.overlayersByID[overlayerID],
          dropPoint
        );
        this.props.dispatch(editionActions.UpdatePage(pageCopy));
        this.props.dispatch(editionActions.AddUndoableAction());
      }
    }
  }

  handleFrameMouseDown(pageIndex, frameIndex, event) {
    const { pageList, selectedPageIndex, projectClass } = this.props;
    const frame = pageList[pageIndex].frames[frameIndex];
    const { editedFrame } = this.state;

    // case already selected, move!
    if (editedFrame && editedFrame.id === frame.id) {
      /// //////////////////////////////////////////////////////////////
      // [hack] no better way for now...
      if (
        this.transformToolRef &&
        this.transformToolRef.current &&
        frameCanMove(frame)
      ) {
        this.transformToolRef.current.handleMoveStart(event);
      }
      /// //////////////////////////////////////////////////////////////
    } else {
      this.handleFrameSelect(pageIndex, frameIndex);
    }
  }

  handleFrameDoubleClick(frame: Frame) {
    const { editedFrame } = this.state;

    // case already selected, handle edited frame douhle click
    if (editedFrame && editedFrame.id === frame.id) {
      this.handleEditedFrameDoubleClick();
    }
  }

  /**
   * Handle frame select
   * - update selected frame
   * - update current page
   * - update pagePosX (to correctly positionate the transform tool)
   */
  handleFrameSelect(pageIndex, frameIndex) {
    const { pageList, selectedPageIndex, projectClass } = this.props;
    // const { editedPage } = this.state;

    const currentPageGroup: Array<IPage> = getPageGroup(
      this.props.project.docID,
      pageIndex,
      pageList
    );
    // TODO:change this if more than two pages!
    const pagePosX =
      currentPageGroup[0].index === pageIndex ? 0 : currentPageGroup[0].width;
    const selectedFrame = pageList[pageIndex].frames[frameIndex];

    // update global selected FrameID
    this.props.dispatch(editionActions.ChangeSelectedFrame(selectedFrame.id));

    this.setState(
      {
        ...this.state,
        pagePosX,
        // reset toolbar visibility
        displayBorderToolbar: false,
        displayShadowToolbar: false,
        editedFrame: cloneDeep(selectedFrame), // edited frame and page must be deep copys to allow edition without touching to the store!
      },

      // Succesfully set state
      () => {
        // be sure to update the selected page on the store if frame is not on current page
        if (pageIndex !== selectedPageIndex) {
          this.props.dispatch(editionActions.ChangePage(pageIndex));
        }
      }
    );
  }

  /**
   *
   * @param {Photo} tempPhoto
   * @param {Frame} frame
   */
  handlePhotoImportIntoFrame(tempPhoto, frame) {
    this.props.dispatch(photoListActions.importPhotos(tempPhoto, frame));

    // TODO: check undoable here as the photo might still be "temp" during uploading process...
  }

  // ---- HANDLE FRAME SIZE LIMIT ----
  handleFrameTextLimits = (frameID, { width, height }) => {
    // Case the frame limit happens with the currently edited frame!
    if (this.state.editedFrame && this.state.editedFrame.id === frameID) {
      const isEditing = !!this.state.editedPage; // if we have an edited page, we are currenctly in editing mode

      // if we are not currently editing the page
      if (!isEditing) {
        this.startPageEditing().then(() => {
          const { editedFrame } = this.state;
          if (width) {
            editedFrame.width = width;
          }
          if (height) {
            editedFrame.height = height;
          }
          this.endPageEditing();
        });
      } else {
        const { editedFrame } = this.state;
        if (width) {
          editedFrame.width = width;
        }
        if (height) {
          editedFrame.height = height;
        }
        this.setState({
          ...this.state,
          editedFrame,
        });
      }
    }

    // otherwise, it is another frame that must be updated!
    else {
      // we never update a frame directly, but the current page
      // makes sure we are not in the editing of a page/frame
      console.warn('Text limit reached on non edited frame!');
      this.setState(
        {
          editedPage: null,
          editedFrame: null,
        },
        () => {
          try {
            const frames = this.props.currentVisibleFrames;
            const frame = cloneDeep(
              this.props.currentVisibleFrames &&
                this.props.currentVisibleFrames[frameID]
            );
            if (width) {
              frame.width = width;
            }
            if (height) {
              frame.height = height;
            }
            // find frame in current page
            this.props.dispatch(editionActions.UpdateFrame(frame));
          } catch (e) {
            alert(`error while fixing limit of text frame:${e}`);
          }
        }
      );
    }
  };

  // ---- BORDER CHANGE ----

  handleBorderColorChange = (color) => {
    this.startPageEditing().then(() => {
      const { editedPage, editedFrame } = this.state;
      editedFrame.borderColor = color.hex;
      this.endPageEditing();
    });
  };

  handleBorderSizeChange = (newSize) => {
    this.startPageEditing().then(() => {
      const { editedPage, editedFrame } = this.state;
      editedFrame.border = newSize;
      this.endPageEditing();
    });
  };

  applyBorderToAllFrames = (borderSize: number, borderColor: string) => {
    this.props.dispatch(
      editionActions.ApplyBorderToAll(borderSize, borderColor)
    );
  };

  applyBorderRadiusToAllFrames = (borderRadius: number) => {
    this.props.dispatch(editionActions.ApplyBorderRadiusToAll(borderRadius));
  };

  applyCurrentTextOptionsToAll = () => {
    this.props.dispatch(
      editionActions.ApplyTextOptionsToAll(this.state.editedFrame)
    );
  };

  handleBorderRadiusChange = (
    newRadius: IFrameShadow,
    andEndEdition = true
  ) => {
    this.startPageEditing().then(() => {
      const { editedPage, editedFrame } = this.state;
      editedFrame.borderRadius = newRadius;
      this.setState({ editedFrame });

      if (andEndEdition) {
        this.endPageEditing();
      }
    });
  };

  // ---- SHADOW CHANGE ----

  handleFrameShadowUpdate = (
    frameShadow: IFrameShadow,
    andEndEdition = true
  ) => {
    this.startPageEditing().then(() => {
      const { editedPage, editedFrame } = this.state;
      editedFrame.shadow = frameShadow;
      this.setState({ editedFrame });

      if (andEndEdition) {
        this.endPageEditing();
      }
    });
  };

  applyShadowToAllFrames = (shadow: IFrameShadow) => {
    this.props.dispatch(editionActions.ApplyShadowToAll(shadow));
  };

  // ---- TEXT CHANGE ----

  onTextChange = ({ target: { value } }) => {
    // console.log("----new value is:" + value)
    // editedFrame.text.value = value;
    this.startPageEditing().then(() => {
      const { editedPage, editedFrame } = this.state;
      editedFrame.text.value = value;
      // end edition (save)
      this.endPageEditing();
    });
  };

  onTextColorChange = (color) => {
    this.startPageEditing().then(() => {
      const { editedPage, editedFrame } = this.state;
      editedFrame.text.color = color.hex;
      // end edition (save)
      this.endPageEditing();
    });
  };

  onTextBGColorChange = (color) => {
    this.startPageEditing().then(() => {
      const { editedPage, editedFrame } = this.state;
      if (color && color.hex) {
        editedFrame.fillColor = color.hex;
      } else editedFrame.fillColor = null;

      // end edition (save)
      this.endPageEditing();
    });
  };

  handleTextFamilyChange = (value) => {
    this.startPageEditing().then(() => {
      const { editedPage, editedFrame } = this.state;
      editedFrame.text.family = value;
      this.endPageEditing();
    });
  };

  handleTextSizeChange = (value) => {
    this.startPageEditing().then(() => {
      const { editedPage, editedFrame } = this.state;
      editedFrame.text.size = value;
      this.endPageEditing();
    });
  };

  toggleTextItalic = () => {
    this.startPageEditing().then(() => {
      const { editedPage, editedFrame } = this.state;
      editedFrame.text.italic = !editedFrame.text.italic;
      this.endPageEditing();
    });
  };

  toggleTextBold = () => {
    this.startPageEditing().then(() => {
      const { editedPage, editedFrame } = this.state;
      editedFrame.text.bold = !editedFrame.text.bold;
      this.endPageEditing();
    });
  };

  changeTextAlign = (align) => {
    this.startPageEditing().then(() => {
      const { editedPage, editedFrame } = this.state;
      editedFrame.text.halign = align;
      this.endPageEditing();
    });
  };

  clearText = () => {
    this.startPageEditing().then(() => {
      const { editedPage, editedFrame } = this.state;
      editedFrame.text.value = '';
      if (this.textInputRef) {
        this.textInputRef.current.setValue('');
      }
      this.endPageEditing();
    });
  };

  handlePreviewClick = () => {
    this.props.dispatch(UIActions.SetPreviewMode(!this.props.isPreviewMode));
  };

  handeGridButtonClick = () => {
    let { gridMode } = this.props;
    gridMode = (gridMode + 1) % Object.keys(GRID_MODE).length;
    this.props.dispatch(UIActions.ChangeCurrentGridMode(gridMode));
  };

  // onFrameSelect( target, frameVo )
  // {
  //     console.log("frameSelected : " + frameVo.id );
  //     //alert( "frameVo : " + JSON.stringify(frameVo));
  //     //alert( "Frame selected : " + frame.transform );
  //     //alert( "this.refs.tansformToolRefToRemove : " + this.transformToolRef );

  //     /*
  //     const transformState = Object.assign( {}, this.state.`transformToo`l ); // make copy of needed part Object.assign{ {}, this.state.transformTool };
  //     transformState.transform.x = frameVo.transform.x;
  //     transformState.transform.y = frameVo.transform.y;
  //     transformState.transform.width = frameVo.transform.width;
  //     transformState.transform.height = frameVo.transform.height;
  //     transformState.transform.angle = frameVo.transform.angle;
  //     */

  //     //alert ("new x : " + transformState.transform.x);
  //     //alert ("new y : " + transformState.transform.y);

  //     this.setState({
  //          // transformTool: transformState,
  //         editedFrame:frameVo
  //     });

  //     /*
  //     let styleRef = window.getComputedStyle(this.transformToolRef); //.style;
  //     alert( "styleRef : " + styleRef );
  //     this.transformToolRef.style = frame.style.style;
  //     */
  // }

  onDropContent(ev, optionalInfo) {
    console.log('EditionArea.onDropContent');
    alert('dropped on edition area!');
    /*
    let id = ev.dataTransfer.getData("id");
    console.log("Image with id " + id + " has been dropped") ;

    this.addImageToCanvas( ev.dataTransfer.getData("src"));
    */

    // let tasks = this.state.tasks.filter((task) => {
    //     if (task.name === id) {
    //              task.category = cat;
    //     }
    //      return task;
    //  });
    //  this.setState({
    //     ...this.state,
    //     tasks
    //  });
  }

  // getPageGroupSize = function()
  // {
  //     const {pageList, projectClass, selectedPageIndex } = this.props;
  //     const {Option} = Select;

  //     // find current pages to display
  //     const pageGroup = pageList ? getPageGroup(selectedPageIndex, pageList, projectClass) : [];

  //     //const pageGroup = currentPageGroup;
  //     const numPages = pageGroup.length;
  //     let pagesWidth = 0;
  //     pageGroup.forEach( (page, index, arr)=>{
  //         pagesWidth += page.width;
  //     })
  //     const pagesHeight = (numPages!==0)? pageGroup[0].height : 0;
  //     const pageIndex = (pageGroup.length>0)? pageGroup[0].index : 0;
  //     // const outsideMargin = pagesWidth*0.05; //10%

  //     return { width: pagesWidth, height:pagesHeight };
  // }

  /// ///////////////////////////////////////////////////
  // Render
  /// ///////////////////////////////////////////////////
  render() {
    const {
      pageList,
      photosByID,
      selectedPageIndex,
      selectedFrameID,
      allowUndo,
      allowCopy,
      allowPaste,
      allowRedo,
      backgroundsByID,
      clipartsByID,
      overlayersByID,
      projectClass,
    } = this.props;
    const proj: Project = this.props.project;
    const { editedPage, editionScale, transformBounds, rootX, rootY } =
      this.state;
    const { editedFrame } = this.state;
    const { Option } = Select;
    const doc = GetDoc(proj.docID);

    // find current pages to display
    const pageGroup = pageList
      ? getPageGroup(proj.docID, selectedPageIndex, pageList)
      : [];

    // ALBUM inside pages
    // if the page group is only one page size and is not the cover, we need to display inside pages
    if (IsAlbumEditor() && pageGroup.length === 1 && !pageGroup[0].isCover) {
      const insidePage = {
        isInsidePage: true,
        width: pageGroup[0].width,
        height: pageGroup[0].height,
      };
      if (pageGroup[0].index === 1) {
        pageGroup.unshift(insidePage);
      } // first page
      else {
        pageGroup.push(insidePage);
      } // end page
    }

    // const pageGroup = currentPageGroup;
    const numPages = pageGroup.length;

    // TODO: spacing could be in "doc" so easier to customize...
    const pageSpacing = IsAlbumEditor()
      ? 0
      : IsCalendarEditor()
      ? 0
      : IsCardEditor()
      ? doc.pages_per_group > 2
        ? 0
        : 10 // cards with insidepages (>2) don't have spaces between insidepages
      : 0;

    // group width&height
    let pagesWidth = 0;
    let pagesHeight = 0;
    if (pageGroup && pageGroup.length > 0) {
      if (doc.pages_group_orientation === 'vertical') {
        pageGroup.forEach((page, index, arr) => {
          pagesHeight += page.height;
        });
        pagesWidth = pageGroup[0].width;
      } else {
        pageGroup.forEach((page, index, arr) => {
          pagesWidth += page.width;
        });
        pagesHeight = pageGroup[0].height;
      }
    }

    const pageIndex = pageGroup.length > 0 ? pageGroup[0].index : 0;

    let outsideMargin = pagesWidth * 0.05; // 10% margin

    // in case of canvas editor, we need to add the "edge size" to the margin
    const canvasEdge = {
      size: 0,
      isKadapak: false,
      style: {},
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
    };
    if (IsCanvasEditor()) {
      canvasEdge.size = mmToPoint(CanvasHelper.GetEdgeSizeMM(proj.type));
      outsideMargin += canvasEdge.size;
      canvasEdge.isKadapak = proj.type === CANVAS_TYPES.KADAPAK;
      canvasEdge.style = {
        strokeWidth: canvasEdge.size,
        stroke: canvasEdge.isKadapak
          ? CanvasHelper.GetFrameColorByID(proj.canvasFrameColor)
          : null,
        strokeOpacity: canvasEdge.isKadapak ? 1 : 0.5,
      };
      const canvasPage: IPage = pageGroup[0];
      canvasEdge.left = -canvasEdge.size / 2;
      canvasEdge.top = -canvasEdge.size / 2;
      canvasEdge.right = canvasPage.width + canvasEdge.size / 2;
      canvasEdge.bottom = canvasPage.height + canvasEdge.size / 2;
    }
    const isEditing = !!editedPage; // this is a trick, if there is an "editedPage" it means we are currenctly editing (moving, scaling, etc..)

    const isClassicCover = pageGroup && pageGroup[0].coverClassicOptions;
    const displayBackground = !isClassicCover;

    // TODO: should be in a "toolbar" component
    let editedFrameMinZoom = 1;
    let editedFrameMaxZoom = MAX_ALLOWED_FRAME_ZOOM;
    let editedFramePhotoObj: Photo = null;
    if (editedFrame && editedFrame.photo) {
      editedFramePhotoObj = photosByID[editedFrame.photo];
      editedFrameMinZoom = getFrameMinZoom(editedFrame, editedFramePhotoObj);
      editedFrameMaxZoom = getFrameMaxZoom(editedFrame, editedFramePhotoObj);
    }

    let toolbarPosX = 0;
    let toolbarPosY = 0;
    if (transformBounds) {
      const marginY = 15;
      toolbarPosX = transformBounds.left + transformBounds.width * 0.5;
      const limitY = window.innerHeight - 230;
      toolbarPosY =
        transformBounds.bottom + marginY > limitY
          ? transformBounds.top - marginY - 70
          : transformBounds.bottom + marginY;
    }

    const frameShadow: IFrameShadow = cloneDeep(
      editedFrame && isObject(editedFrame.shadow)
        ? editedFrame.shadow
        : getDefaultFrameShadow()
    );

    /// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Snapping lines
    // TODO: to avoid having this calculation all the time, put this in a selector!
    const snappingLines = {
      v: [],
      h: [],
    };
    const touchedSnappingLines = { ...snappingLines };

    // conditions to show snapping lines
    if (
      pageGroup &&
      pageGroup.length > 0 &&
      this.state.editedPage &&
      this.state.editedFrame &&
      (this.state.editAction === TRANFORM_TOOL_ACTION.MOVE ||
        this.state.editAction === TRANFORM_TOOL_ACTION.SCALE ||
        this.state.editAction === TRANFORM_TOOL_ACTION.CROP)
    ) {
      // TODO: adapt for page with orientation vertical!! (test snapping lines in cards)
      // let verticalDisplay = (doc.pages_group_orientation === "vertical");
      let pageIndex = 0;
      for (const page of pageGroup) {
        // let pageX:Number = verticalDisplay? 0 : pageIndex * page.width + pageSpacing;
        // let pageY:Number = verticalDisplay? pageIndex * page.height + pageSpacing : 0 ;

        // keep this security for special pages (linke insidepagearea) that do not have frames!
        if (page && page.frames) {
          for (const frame of page.frames) {
            if (this.state.editedPage.index === page.index) {
              snappingLines.v.push(frame.x);
              if (editedFrame.id !== frame.id) {
                // do not snap the same frame border lines, only center
                snappingLines.v.push(frame.x - frame.width / 2);
                snappingLines.v.push(frame.x + frame.width / 2);
              }
            }
            // if(this.state.editedPage.index === page.index)
            // {

            snappingLines.h.push(frame.y);
            if (editedFrame.id !== frame.id) {
              // do not snap the same frame border lines, only center
              snappingLines.h.push(frame.y - frame.height / 2);
              snappingLines.h.push(frame.y + frame.height / 2);
            }

            // }
          }
        }

        pageIndex++;
      }

      // filter the snapping lines with the current frame position!
      const snapTreshold = mmToPoint(0.5); // less than in thransform tool (5mm) because here it's just for displaying!
      const frame = editedFrame;
      const frameYs =
        frame.rotation === 0
          ? [frame.y, frame.y - frame.height / 2, frame.y + frame.height / 2]
          : [frame.y];
      const frameXs =
        frame.rotation === 0
          ? [frame.x, frame.x - frame.width / 2, frame.x + frame.width / 2]
          : [frame.x];
      touchedSnappingLines.h = snappingLines.h.filter((posY) => {
        for (const frameY of frameYs) {
          if (Math.abs(frameY - posY) < snapTreshold) {
            return true;
          }
        }
        return false;
      });
      touchedSnappingLines.v = snappingLines.v.filter((posX) => {
        for (const frameX of frameXs) {
          if (Math.abs(frameX - posX) < snapTreshold) {
            return true;
          }
        }
        return false;
      });
    }

    return (
      <div
        className={`editionArea ${
          this.props.isPreviewMode
            ? `previewBG_${PROJECT_CONST.project_class}`
            : ''
        }`}
        // onDrop={(e)=>this.onDropContent(e, "this is optional info" )}
        // onDragOver={(e)=>{e.preventDefault();}}
        ref={this.editionAreaRef}
      >
        {
          /* // --------------------- Main Toolbar ------------------------     */
          !this.props.isPreviewMode && (
            <div className="toolbar">
              {pageList && (
                <Row type="flex" justify="start" align="middle" gutter={[8]}>
                  <Col>
                    <ButtonGroup>
                      {
                        // <Button type="primary" shape="rounded" icon="undo" size="small" disabled={this.prop} onClick={(e)=> {this.props.dispatch( undoAction() } } >Undo</Button>
                      }
                      <Button
                        className="button"
                        disabled={!allowUndo}
                        shape="round"
                        icon={<UndoOutlined />}
                        size="small"
                        onClick={(e) => {
                          this.handleUndo();
                        }}
                      >
                        {GetText('tooltip.toolbar.button.undo')}
                      </Button>
                      <Button
                        className="button"
                        disabled={!allowRedo}
                        shape="round"
                        icon={<RedoOutlined />}
                        size="small"
                        onClick={(e) => {
                          this.handleRedo();
                        }}
                      >
                        {GetText('tooltip.toolbar.button.redo')}
                      </Button>
                    </ButtonGroup>
                  </Col>
                  <Col>
                    <ButtonGroup>
                      {/* <Button variant="dark"
                                disabled={pageIndex===0}
                                onClick={(e)=> { this.handlePreviousPageClick() }} >PREVIOUS PAGE</Button>
                            <Button variant="dark"
                                disabled={pageIndex > project.pageList.length-2}
                                onClick={(e)=> { this.handleNextPageClick() }} >NEXT PAGE</Button> */}

                      <Button
                        className="button"
                        size="small"
                        icon={<FileImageOutlined />}
                        onClick={(e) => {
                          this.handleAddImageToPage();
                        }}
                      >
                        {GetText('tooltip.toolbar.button.addPhoto')}
                      </Button>
                      <Button
                        className="button"
                        size="small"
                        icon={<FontSizeOutlined />}
                        onClick={(e) => {
                          this.handleAddTextToPage(e);
                        }}
                      >
                        {GetText('tooltip.toolbar.button.addText')}
                      </Button>
                    </ButtonGroup>
                  </Col>
                  <Col>
                    <ButtonGroup>
                      <Tooltip
                        mouseEnterDelay={this.tooltipDelay}
                        title={GetText('tooltip.toolbar.button.copy')}
                      >
                        <Button
                          className="button"
                          disabled={!allowCopy}
                          size="small"
                          icon={<CopyOutlined />}
                          onClick={(e) => {
                            this.handleFrameCopy(e);
                          }}
                        />
                      </Tooltip>
                      <Tooltip
                        mouseEnterDelay={this.tooltipDelay}
                        title={GetText('tooltip.toolbar.button.cut')}
                      >
                        <Button
                          className="button"
                          disabled={!allowCopy}
                          size="small"
                          icon={<ScissorOutlined />}
                          onClick={(e) => {
                            this.handleFrameCut(e);
                          }}
                        />
                      </Tooltip>
                      <Tooltip
                        mouseEnterDelay={this.tooltipDelay}
                        title={GetText('tooltip.toolbar.button.paste')}
                      >
                        <Button
                          className="button"
                          disabled={!allowPaste}
                          size="small"
                          icon={<DiffOutlined />}
                          onClick={(e) => {
                            this.handleFramePaste(e);
                          }}
                        />
                      </Tooltip>

                      {/* <Button className="button"  size="small" icon="remove" onClick={ this.handleDeleteCurrentFrame.bind(this) } >Delete frame</Button>                     */}
                    </ButtonGroup>
                  </Col>

                  {
                    // ---- ALBUMS ONLY ----
                    IsAlbumEditor() && (
                      <Col>
                        <ProjectPageModifierPopup>
                          <Button
                            className="button"
                            size="small"
                            icon={<FormOutlined />}
                            onClick={(e) => {
                              this.handleChangePages();
                            }}
                          >
                            {GetText('tooltip.toolbar.button.addRemovePages')}
                          </Button>
                        </ProjectPageModifierPopup>
                      </Col>
                    )
                  }

                  {
                    // ---- CALENDAR ONLY ----
                    IsCalendarEditor() && this.props.currentPage.isCalendar && (
                      <Col>
                        <CalendarColorsModifierPopover>
                          <Button
                            className="button"
                            size="small"
                            icon={<BgColorsOutlined />}
                            onClick={(e) => {
                              this.handleChangePages();
                            }}
                          >
                            {GetText('tooltip.toolbar.button.calendarColors')}
                          </Button>
                        </CalendarColorsModifierPopover>
                      </Col>
                    )
                  }

                  {
                    // ---- Grid ----
                    this.props.allowGrid && (
                      <Col>
                        <Button
                          className="button"
                          size="small"
                          icon={<TableOutlined />}
                          onClick={(e) => {
                            this.handeGridButtonClick();
                          }}
                        >
                          {GetText('tooltip.toolbar.button.grid')}
                        </Button>
                      </Col>
                    )
                  }

                  {/* <Dropdown overlay={logoMenu} trigger={['contextMenu']}>
                                            <div>
                                                <img src={require("../images/logo.png")} width="75" height="45" />

                                                {
                                                    (DebugFlags.DEBUGGING) &&
                                                        <div style={{
                                                            color:"#ffff00",
                                                            position: "absolute",
                                                            left:"2px",
                                                            top:"2px",
                                                            fontWeight:"bold",
                                                            fontStyle:"italic",
                                                            fontSize:"8px"
                                                        }}
                                                        >DEV</div>
                                                }

                                            </div>
                                        </Dropdown>
                                    </Popover> */}
                </Row>
              )}
            </div>
          )
        }

        {/* Canvas for fabric rendering */}
        <div
          role="button"
          tabIndex="0"
          ref={this.stageAreaRef}
          className="stageArea"
          onClick={(e) => {
            if (e.target.id === 'thePages') {
              this.props.dispatch(editionActions.ChangeSelectedFrame(null));
            } // update global selected FrameID
          }}
        >
          {
            // --------------------- image Editor ------------------------
            this.state.editedPhoto && (
              <ImageEditor
                photoID={this.state.editedPhoto}
                frame={this.state.editedFrame}
                onClose={() => {
                  this.setState({ editedPhoto: null });
                }}
              />
            )
          }

          {
            // --------------------- Pages ------------------------
            pageList && (
              <div className="pageDivWrapper">
                <svg
                  id="thePages"
                  ref={this.pageContainerRef}
                  className={
                    this.props.isPreviewMode ? 'pagesSVG_preview' : 'pagesSVG'
                  }
                  viewBox={`0 0 ${pagesWidth + outsideMargin * 2} ${
                    pagesHeight + outsideMargin * 2
                  }`}
                >
                  {/* { // --------------------- Background fill ------------------------
                            (displayBackground && false) &&
                            <rect id="pagesRect" x={outsideMargin-5} y={outsideMargin-5} width={pagesWidth+10} height={pagesHeight+10}
                                style={{
                                    //stroke: "#000000",
                                    //strokeWidth: 3,
                                    //strokeDasharray: "10 5",
                                    fill: "#ffffff",
                                    fillOpacity:"0.2"
                                    }}

                                    />
                        } */}

                  {
                    // --------------------- Page Group ------------------------
                    /** TODO: we need to compose this depending on the display list...
                     * as here it breaks on last page (as there is no pageIndex+1)
                     */

                    pageGroup.map((pageObj, groupIndex) => {
                      const page =
                        !pageObj.isInsidePage &&
                        editedPage &&
                        pageObj.index === editedPage.index
                          ? editedPage
                          : pageObj;

                      if (pageObj.isInsidePage) {
                        return (
                          <InsidePageArea
                            key={`insidePage_${groupIndex}`}
                            editionScale={editionScale}
                            isPreviewMode={this.props.isPreviewMode}
                            x={outsideMargin + groupIndex * pageObj.width}
                            y={outsideMargin}
                            height={pageObj.height}
                            width={pageObj.width}
                          />
                        );
                      }

                      const pageX =
                        doc.pages_group_orientation === 'vertical'
                          ? outsideMargin
                          : outsideMargin +
                            groupIndex * (pageObj.width + pageSpacing);
                      const pageY =
                        doc.pages_group_orientation === 'vertical'
                          ? outsideMargin +
                            groupIndex * (pageObj.height + pageSpacing)
                          : outsideMargin;

                      return (
                        <PageArea
                          id={`page_${page.index}`}
                          pageIndex={page.index}
                          key={page.index}
                          page={page}
                          gridMode={this.props.gridMode}
                          docID={this.props.project.docID}
                          selected={selectedPageIndex === page.index}
                          selectedFrameID={selectedFrameID}
                          isEditing={!!this.state.editedPage}
                          isPreviewMode={this.props.isPreviewMode}
                          photosByID={photosByID}
                          backgroundsByID={backgroundsByID}
                          clipartsByID={clipartsByID}
                          overlayersByID={overlayersByID}
                          editionScale={editionScale}
                          calendarColorOptions={
                            this.props.project.calendarColorOptions
                          }
                          canvasEdge={canvasEdge}
                          x={pageX}
                          y={pageY}
                          onFrameMouseDown={(p, f, e) => {
                            this.handleFrameMouseDown(p, f, e);
                          }}
                          onFrameDoubleClick={(frame) => {
                            this.handleFrameDoubleClick(frame);
                          }}
                          onFrameItemDrop={(dropEvent, frameObj) => {
                            this.handleItemDropOnFrame(dropEvent, frameObj);
                          }}
                          onFramePhotoImport={(photo, frame) => {
                            this.handlePhotoImportIntoFrame(photo, frame);
                          }}
                          onFrameTextLimitReached={(frameID, sizeLimit) => {
                            this.handleFrameTextLimits(frameID, sizeLimit);
                          }}
                          dispatch={this.props.dispatch}
                        />
                      );
                    })
                  }

                  {
                    // --------------------- Page between shadow ------------------------
                    pageGroup.length === 2 && pageSpacing === 0 && (
                      <g>
                        {
                          // --------------------- VERTICAL shadow ------------------------
                          doc.pages_group_orientation !== 'vertical' && (
                            <g>
                              <defs>
                                <linearGradient
                                  id="Gradient1"
                                  x1="0"
                                  x2="1"
                                  y1="0"
                                  y2="0"
                                >
                                  <stop
                                    offset="0%"
                                    stopColor="black"
                                    stopOpacity="0"
                                  />
                                  <stop
                                    offset="25%"
                                    stopColor="black"
                                    stopOpacity="0.1"
                                  />
                                  <stop
                                    offset="50%"
                                    stopColor="black"
                                    stopOpacity="0.4"
                                  />
                                  <stop
                                    offset="75%"
                                    stopColor="black"
                                    stopOpacity="0.1"
                                  />
                                  <stop
                                    offset="100%"
                                    stopColor="black"
                                    stopOpacity="0"
                                  />
                                </linearGradient>
                              </defs>
                              <rect
                                x={outsideMargin + pageGroup[0].width - 25}
                                y={outsideMargin}
                                height={pageGroup[0].height}
                                width={50}
                                fill="url(#Gradient1)"
                              />
                            </g>
                          )
                        }
                        {
                          // --------------------- HORIZONTAL shadow ------------------------
                          doc.pages_group_orientation === 'vertical' && (
                            <g>
                              <defs>
                                <linearGradient
                                  id="Gradient1"
                                  x1="0"
                                  x2="0"
                                  y1="0"
                                  y2="1"
                                >
                                  <stop
                                    offset="0%"
                                    stopColor="black"
                                    stopOpacity="0"
                                  />
                                  <stop
                                    offset="25%"
                                    stopColor="black"
                                    stopOpacity="0.1"
                                  />
                                  <stop
                                    offset="50%"
                                    stopColor="black"
                                    stopOpacity="0.4"
                                  />
                                  <stop
                                    offset="75%"
                                    stopColor="black"
                                    stopOpacity="0.1"
                                  />
                                  <stop
                                    offset="100%"
                                    stopColor="black"
                                    stopOpacity="0"
                                  />
                                </linearGradient>
                              </defs>
                              <rect
                                y={outsideMargin + pageGroup[0].height - 10}
                                x={outsideMargin}
                                height={20}
                                width={pageGroup[0].width}
                                fill="url(#Gradient1)"
                              />
                            </g>
                          )
                        }
                      </g>
                    )
                  }

                  {
                    // --------------------- pageDetails ------------------------
                    !isClassicCover && (
                      <g>
                        <text
                          className="unselectable"
                          // x="50%" text-anchor="middle"
                          x={10 / editionScale}
                          y={20 / editionScale}
                          fontSize={12 / editionScale}
                          fill="#aaaaaa"
                        >
                          --- {pixelToCm(pageGroup[0].width).toFixed(1)} cm ---
                        </text>
                        <text
                          className="unselectable"
                          x={-100 / editionScale}
                          y={10 / editionScale}
                          fontSize={12 / editionScale}
                          transform="rotate(-90)"
                          fill="#aaaaaa"
                        >
                          {' '}
                          --- {pixelToCm(pageGroup[0].height).toFixed(1)} cm ---
                        </text>
                      </g>
                    )
                  }
                </svg>

                {
                  // --------------------- Snapping lines ------------------------
                  this.state.editedPage &&
                    this.state.editedFrame &&
                    touchedSnappingLines && (
                      <div
                        className="unclickable unselectable"
                        style={{
                          left: 0,
                          top: 0,
                          width: '100%',
                          height: '100%',
                          overflow: 'hidden',
                          position: 'absolute',
                        }}
                      >
                        {
                          // ---- VERTICAL ----
                          touchedSnappingLines.v.map((value, index) => (
                            <div
                              key={`snapV_${index}`}
                              className="snappingLineV"
                              style={{
                                left: this.state.rootX + value * editionScale,
                              }}
                            />
                          ))
                        }
                        {
                          // ---- HORIZONTAL ----
                          touchedSnappingLines.h.map((value, index) => (
                            <div
                              key={`snapH_${index}`}
                              className="snappingLineH"
                              style={{
                                top: this.state.rootY + value * editionScale,
                              }}
                            />
                          ))
                        }
                      </div>
                    )
                }

                {
                  // --------------------- Edited Frame (Transform tools) ------------------------
                  this.state.editedFrame && (
                    <div
                      style={{
                        left: 0,
                        top: 0,
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden',
                        // backgroundColor:"#fff00022",
                        position: 'absolute',
                        pointerEvents: 'none',
                      }}
                    >
                      <TransformTool
                        // using the "Key" attribute linked to image id will force the tool to be "re created" each time we change frame
                        // THIS IS GREAT to avoid the anti pattern props->state
                        // see here : https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html
                        // key={ this.state.selected.id }
                        ref={this.transformToolRef}
                        keyRef={`selectionBox_${this.state.editedFrame.id}_${this.state.editedFrame.photo}`}
                        // pagePosX={ this.state.rootX + this.state.pagePosX }
                        pagePosX={this.state.rootX}
                        pagePosY={this.state.rootY}
                        frame={this.state.editedFrame}
                        editedPage={this.state.editedPage}
                        editionScale={editionScale}
                        photosByID={this.props.photosByID}
                        snappingLines={snappingLines}
                        onDoubleClick={(frame: Frame) => {
                          this.handleEditedFrameDoubleClick(frame);
                        }}
                        onFrameUpdateStart={(newFrameState, editAction) => {
                          this.handleEditedFrameStart(
                            newFrameState,
                            editAction
                          );
                        }}
                        onFrameUpdate={(newFrameState, refreshContent) => {
                          this.handleEditedFrameUpdate(
                            newFrameState,
                            refreshContent
                          );
                        }}
                        onFrameUpdateCompleted={(newFrameState) => {
                          this.handleEditedFrameUpdateComplete(newFrameState);
                        }}
                        onItemDropped={(dropEvent) => {
                          this.handleItemDroppedOnTransformTool(dropEvent);
                        }}
                      />

                      {
                        // --------------------- Transform toolbar ------------------------
                        // ( transformBounds && editedFrame && !isEditing ) &&
                        transformBounds &&
                          editedFrame &&
                          // do not display toolbar while those actions are performed
                          this.state.editAction !== TRANFORM_TOOL_ACTION.MOVE &&
                          this.state.editAction !==
                            TRANFORM_TOOL_ACTION.SCALE &&
                          this.state.editAction !==
                            TRANFORM_TOOL_ACTION.ROTATE &&
                          this.state.editAction !== TRANFORM_TOOL_ACTION.CROP &&
                          this.state.editAction !==
                            TRANFORM_TOOL_ACTION.INSIDE_MOVE && (
                            <div
                              className="transformToolbarWrapper"
                              key={`toolbar_${editedFrame.id}`}
                              // onClick={(e)=>{alert("transformtooblar")}}
                              style={{
                                // position:"absolute",
                                // width:"40px",
                                // minHeight:"150px",
                                //    zIndex:1001,
                                // display: "inline-block",
                                // right:(transformBounds.right > window.innerWidth*.60)?  window.innerWidth - transformBounds.left + 20 : "auto",

                                // left:transformBounds.left+transformBounds.width*.5,

                                // left:(transformBounds.right > window.innerWidth*.75)?
                                //     window.innerWidth*.25
                                //     : (editedFrame.type === FRAME_TYPE.TEXT)?
                                //         transformBounds.left  - 350
                                //         : transformBounds.left  -50,
                                // left:0,
                                // top:(transformBounds.bottom > window.innerHeight-230)?
                                //     window.innerHeight-230
                                //     : transformBounds.bottom +10 ,
                                left: toolbarPosX,
                                top: toolbarPosY,

                                // transformOrigin:"center center",

                                // transform:"translate(" + toolbarPosX + "px," + toolbarPosY+ "px)"

                                // transform:`translateX( ${-frame.width/2}px) translateY( ${-frame.height/2}px) rotate(${frame.rotation}deg)`
                                // transform={"translate(" + t.x + "," + t.y + ") rotate("+ t.angle +"," + t.width/2 +"," + t.height/2 +")"}
                                // transform:`translate( ${this.calculatePosX()} , ${this.calculatePosY()} ) rotate(${frame.rotation})`
                              }}
                            >
                              <div className="transformToolbar">
                                {
                                  // ---- ZOOM SLIDER ----
                                  editedFrame.photo && (
                                    <span className="zoomSliderWrapper">
                                      <Tooltip
                                        mouseEnterDelay={this.tooltipDelay}
                                        title={GetText(
                                          'tooltip.transformtool.zoom.minus'
                                        )}
                                        placement="top"
                                      >
                                        <Button
                                          type="primary"
                                          shape="circle"
                                          onClick={(e) => {
                                            this.handleFrameZoomEnd(
                                              editedFrame.zoom - 0.01
                                            );
                                          }}
                                        >
                                          <ImgIcon
                                            className="customIcon"
                                            icon={EmbedIcons.ZoomOutIcon}
                                          />
                                        </Button>
                                      </Tooltip>

                                      <Tooltip
                                        mouseEnterDelay={this.tooltipDelay}
                                        title="Zoom on image"
                                        placement="top"
                                      >
                                        <Slider
                                          horizontal
                                          className="zoomSlider"
                                          // Tooltip mouseEnterDelay={this.tooltipDelay} Visible={true}
                                          defaultValue={editedFrame.zoom}
                                          value={editedFrame.zoom}
                                          tooltip={{
                                            formatter:
                                              this.zoomTooltipFormatter,
                                          }}
                                          step={0.01}
                                          min={editedFrameMinZoom}
                                          max={editedFrameMaxZoom}
                                          onChange={(value) => {
                                            this.handleFrameZoom(value);
                                          }}
                                          onAfterChange={(value) => {
                                            this.handleFrameZoomEnd(value);
                                          }}
                                        />
                                      </Tooltip>

                                      <Tooltip
                                        mouseEnterDelay={this.tooltipDelay}
                                        title={GetText(
                                          'tooltip.transformtool.zoom.plus'
                                        )}
                                        placement="top"
                                      >
                                        <Button
                                          type="primary"
                                          shape="circle"
                                          onClick={(e) => {
                                            this.handleFrameZoomEnd(
                                              editedFrame.zoom + 0.01
                                            );
                                          }}
                                        >
                                          <ImgIcon
                                            className="customIcon"
                                            icon={EmbedIcons.ZoomInIcon}
                                          />
                                        </Button>
                                      </Tooltip>
                                    </span>
                                  )
                                }

                                {
                                  // ---- LAYER UP ----
                                  frameCanChangeDepth(editedFrame) && (
                                    <Tooltip
                                      mouseEnterDelay={this.tooltipDelay}
                                      title={GetText(
                                        'tooltip.transformtool.depth.plus'
                                      )}
                                      placement="top"
                                    >
                                      <Button
                                        type="primary"
                                        block
                                        onClick={(e) => {
                                          this.handleFrameMoveUp();
                                        }}
                                      >
                                        <ImgIcon
                                          className="customIcon"
                                          icon={EmbedIcons.LayerUpIcon}
                                        />
                                      </Button>
                                    </Tooltip>
                                  )
                                }
                                {
                                  // ---- LAYER DOWN ----
                                  frameCanChangeDepth(editedFrame) && (
                                    <Tooltip
                                      mouseEnterDelay={this.tooltipDelay}
                                      title={GetText(
                                        'tooltip.transformtool.depth.minus'
                                      )}
                                      placement="top"
                                    >
                                      <Button
                                        type="primary"
                                        block
                                        onClick={(e) => {
                                          this.handleFrameMoveDown();
                                        }}
                                      >
                                        <ImgIcon
                                          className="customIcon"
                                          icon={EmbedIcons.LayerDownIcon}
                                        />
                                      </Button>
                                    </Tooltip>
                                  )
                                }

                                {
                                  // ---- inside rotation icon ----
                                  frameCanInsideRotation(editedFrame) && (
                                    <Tooltip
                                      mouseEnterDelay={this.tooltipDelay}
                                      title={GetText(
                                        'tooltip.transformtool.rotate'
                                      )}
                                      placement="top"
                                    >
                                      <Button
                                        type="primary"
                                        block
                                        onClick={(e) => {
                                          this.handleFrameInsideRotate();
                                        }}
                                      >
                                        <ImgIcon
                                          className="customIcon"
                                          icon={EmbedIcons.InsideRotateIcon}
                                        />
                                      </Button>
                                    </Tooltip>
                                  )
                                }

                                {
                                  // ---- SWAP ICON ----
                                  frameCanSwapStart(editedFrame) && (
                                    <Tooltip
                                      mouseEnterDelay={this.tooltipDelay}
                                      title={GetText(
                                        'tooltip.transformtool.swap'
                                      )}
                                      placement="top"
                                    >
                                      <Button
                                        type="primary"
                                        style={{
                                          backgroundColor:
                                            this.state.swapFrameID ===
                                            editedFrame.id
                                              ? Colors.YELLOW
                                              : null,
                                          borderColor:
                                            this.state.swapFrameID ===
                                            editedFrame.id
                                              ? Colors.YELLOW
                                              : null,
                                        }}
                                        block
                                        onClick={(e) => {
                                          if (
                                            this.state.swapFrameID !==
                                            editedFrame.id
                                          )
                                            this.setState({
                                              swapFrameID: editedFrame.id,
                                            });
                                          else {
                                            this.setState({
                                              swapFrameID: null,
                                            });
                                          } // reset
                                        }}
                                      >
                                        <ImgIcon
                                          className="customIcon"
                                          icon={EmbedIcons.SwapIcon}
                                        />
                                      </Button>
                                    </Tooltip>
                                  )
                                }

                                {
                                  // ---- EDIT ICON ----
                                  editedFramePhotoObj &&
                                    !editedFramePhotoObj.temp &&
                                    frameCanPhotoEdit(editedFrame) && (
                                      <Tooltip
                                        mouseEnterDelay={this.tooltipDelay}
                                        title={GetText(
                                          'tooltip.transformtool.edit.image'
                                        )}
                                        placement="top"
                                      >
                                        <Button
                                          id="toolbarEditPhotoButton"
                                          type="primary"
                                          block
                                          icon={<EditOutlined />}
                                          onClick={(e) => {
                                            if (editedFrame.photo) {
                                              this.setState({
                                                editedPhoto: editedFrame.photo,
                                              });
                                            }
                                          }}
                                        />
                                      </Tooltip>
                                    )
                                }

                                {
                                  // --- BORDER TOOLBAR ---
                                  frameCanHaveBorder(editedFrame) && (
                                    <Popover
                                      trigger="click"
                                      placement="bottom"
                                      content={
                                        <span className="borderToolbar">
                                          <Radio.Group
                                            size="default"
                                            onChange={(e) => {
                                              this.handleBorderSizeChange(
                                                e.target.value
                                              );
                                            }}
                                            value={editedFrame.border}
                                          >
                                            <Radio.Button value={0}>
                                              {GetText(
                                                'toolbar.border.noborder'
                                              )}
                                            </Radio.Button>
                                            <Radio.Button value={1}>
                                              1mm
                                            </Radio.Button>
                                            <Radio.Button value={2}>
                                              2mm
                                            </Radio.Button>
                                            <Radio.Button value={4}>
                                              4mm
                                            </Radio.Button>
                                          </Radio.Group>

                                          <div className="spacerH" />

                                          <Popover
                                            content={
                                              <CirclePicker
                                                width={230}
                                                circleSpacing={5}
                                                circleSize={15}
                                                colors={Colors.displayColors}
                                                color={editedFrame.borderColor}
                                                onChange={
                                                  this.handleBorderColorChange
                                                }
                                              />
                                            }
                                            title="Change Color"
                                            trigger="focus"
                                          >
                                            <Button
                                              type="default"
                                              icon={<BgColorsOutlined />}
                                              className="button"
                                              onClick={(e) => e.target.focus()}
                                              style={{
                                                color: editedFrame.borderColor,
                                              }}
                                            />
                                          </Popover>

                                          <div className="spacerH" />
                                          <Button
                                            type="primary"
                                            onClick={() => {
                                              this.applyBorderToAllFrames(
                                                editedFrame.border,
                                                editedFrame.borderColor
                                              );
                                            }}
                                          >
                                            {GetText(
                                              'lefttab.backgrounds.applytoall'
                                            )}
                                          </Button>
                                        </span>
                                      }
                                    >
                                      <Tooltip
                                        mouseEnterDelay={this.tooltipDelay}
                                        title={GetText(
                                          'tooltip.transformtool.border'
                                        )}
                                        placement="top"
                                      >
                                        <Button
                                          type="primary"
                                          block
                                          icon={<BorderOutlined />}
                                          onClick={(e) => {
                                            this.setState({
                                              displayBorderToolbar:
                                                !this.state.editedFrame,
                                            });
                                          }}
                                        />
                                      </Tooltip>

                                      {/* <Button type="primary" icon="bg-colors"
                                        onClick={(e) => e.target.focus()}
                                        style={{backgroundColor:editedFrame.text.color ,margin:5}} /> */}
                                    </Popover>
                                  )
                                }

                                {
                                  // --------------------- MASK TOOLBAR ------------------------
                                  frameCanHaveMask(editedFrame) && (
                                    <Popover
                                      trigger="click"
                                      placement="bottom"
                                      content={
                                        // --------------------- MASK TOOLBAR OVERLAY ------------------------
                                        <span className="maskToolbar">
                                          <h5>
                                            {GetText(
                                              'toolbar.mask.cornerRadiusMask'
                                            )}
                                          </h5>
                                          <div className="spacer" />
                                          <div className="spacer" />
                                          <Tooltip
                                            mouseEnterDelay={this.tooltipDelay}
                                            placement="top"
                                          >
                                            <Slider
                                              horizontal
                                              className="zoomSlider"
                                              style={{ width: 100 }}
                                              tooltip={{
                                                formatter: (value) =>
                                                  `corner radius: ${value}`,
                                              }}
                                              tooltipPlacement="bottom"
                                              value={
                                                editedFrame.borderRadius
                                                  ? editedFrame.borderRadius
                                                  : 0
                                              }
                                              step={1}
                                              min={0}
                                              max={
                                                editedFrame.height >
                                                editedFrame.width
                                                  ? Math.round(
                                                      editedFrame.height / 2
                                                    )
                                                  : Math.round(
                                                      editedFrame.width / 2
                                                    )
                                              }
                                              // onChange={(value)=>{ this.handleFrameZoom(value); }}
                                              // onAfterChange={(value)=>{ this.handleFrameZoomEnd(value); }}
                                              onAfterChange={(value) => {
                                                this.handleBorderRadiusChange(
                                                  value
                                                );
                                              }}
                                              onChange={(value) => {
                                                this.handleBorderRadiusChange(
                                                  value,
                                                  false
                                                );
                                              }}
                                            />
                                          </Tooltip>

                                          <div className="spacerH" />
                                          <Button
                                            type="primary"
                                            onClick={() => {
                                              this.applyBorderRadiusToAllFrames(
                                                editedFrame.borderRadius
                                              );
                                            }}
                                          >
                                            {GetText(
                                              'lefttab.backgrounds.applytoall'
                                            )}
                                          </Button>
                                        </span> // --------------------- END of MASK TOOLBAR OVERLAY ------------------------
                                      }
                                    >
                                      <Tooltip
                                        mouseEnterDelay={this.tooltipDelay}
                                        title={GetText(
                                          'tooltip.transformtool.masks'
                                        )}
                                        placement="top"
                                      >
                                        <Button
                                          type="primary"
                                          block
                                          onClick={(e) => {
                                            this.setState({
                                              displayMaskToolbar:
                                                !this.state.editedFrame,
                                            });
                                          }}
                                        >
                                          <ImgIcon
                                            className="customIcon"
                                            icon={EmbedIcons.MaskIcon}
                                          />
                                        </Button>
                                      </Tooltip>
                                    </Popover>
                                  )
                                }

                                {
                                  // --------------------- SHADOW TOOLBAR ------------------------
                                  frameCanHaveShadow(editedFrame) && (
                                    <Popover
                                      trigger="click"
                                      placement="bottom"
                                      content={
                                        <span className="shadowToolbar">
                                          {/* <h5>Use shadow:</h5> */}
                                          {/* <Checkbox checked={frameShadow.enabled} onChange={(e)=>{ frameShadow.enabled = e.target.checked; this.handleFrameShadowUpdate( frameShadow) } }>Checkbox</Checkbox> */}
                                          <Switch
                                            checked={frameShadow.enabled}
                                            // checkedChildren={<span>Shadow: <CheckOutlined/> </span>}
                                            // unCheckedChildren={<span>Shadow: <CloseOutlined /></span>}

                                            checkedChildren={
                                              <span>Shadow: ON</span>
                                            }
                                            unCheckedChildren={
                                              <span>Shadow: OFF</span>
                                            }
                                            onChange={(value) => {
                                              frameShadow.enabled = value;
                                              this.handleFrameShadowUpdate(
                                                frameShadow
                                              );
                                            }}
                                          />

                                          <div className="spacerH" />
                                          <div className="spacerH" />

                                          <h5>Distance: </h5>
                                          <Tooltip
                                            mouseEnterDelay={this.tooltipDelay}
                                            placement="top"
                                          >
                                            <Slider
                                              disabled={!frameShadow.enabled}
                                              horizontal
                                              className="zoomSlider"
                                              style={{ width: 100 }}
                                              // Tooltip mouseEnterDelay={this.tooltipDelay} Visible={true}
                                              defaultValue={frameShadow.angle}
                                              tooltip={{
                                                formatter: (value) =>
                                                  `distance: ${value}`,
                                              }}
                                              tooltipPlacement="bottom"
                                              value={frameShadow.distance}
                                              step={1}
                                              min={0}
                                              max={100}
                                              // onChange={(value)=>{ this.handleFrameZoom(value); }}
                                              // onAfterChange={(value)=>{ this.handleFrameZoomEnd(value); }}
                                              onAfterChange={(value) => {
                                                frameShadow.distance = value;
                                                this.handleFrameShadowUpdate(
                                                  frameShadow
                                                );
                                              }}
                                              onChange={(value) => {
                                                frameShadow.distance = value;
                                                this.handleFrameShadowUpdate(
                                                  frameShadow,
                                                  false
                                                );
                                              }}
                                            />
                                          </Tooltip>

                                          <div className="spacerH" />
                                          <div className="spacerH" />

                                          <h5>Opacity: </h5>
                                          <Tooltip
                                            mouseEnterDelay={this.tooltipDelay}
                                            placement="top"
                                          >
                                            <Slider
                                              disabled={!frameShadow.enabled}
                                              horizontal
                                              className="zoomSlider"
                                              style={{ width: 100 }}
                                              // Tooltip mouseEnterDelay={this.tooltipDelay} Visible={true}
                                              defaultValue={frameShadow.opacity}
                                              tooltip={{
                                                formatter: (value) =>
                                                  `opacity: ${value}`,
                                              }}
                                              tooltipPlacement="bottom"
                                              value={frameShadow.opacity}
                                              step={0.1}
                                              min={0}
                                              max={1}
                                              // onChange={(value)=>{ this.handleFrameZoom(value); }}
                                              // onAfterChange={(value)=>{ this.handleFrameZoomEnd(value); }}
                                              onAfterChange={(value) => {
                                                frameShadow.opacity = value;
                                                this.handleFrameShadowUpdate(
                                                  frameShadow
                                                );
                                              }}
                                              onChange={(value) => {
                                                frameShadow.opacity = value;
                                                this.handleFrameShadowUpdate(
                                                  frameShadow,
                                                  false
                                                );
                                              }}
                                            />
                                          </Tooltip>

                                          <div className="spacerH" />
                                          <div className="spacerH" />

                                          <h5>Angle: </h5>
                                          <Tooltip
                                            mouseEnterDelay={this.tooltipDelay}
                                            placement="top"
                                          >
                                            <Slider
                                              disabled={!frameShadow.enabled}
                                              horizontal
                                              className="zoomSlider"
                                              style={{ width: 100 }}
                                              // Tooltip mouseEnterDelay={this.tooltipDelay} Visible={true}
                                              defaultValue={frameShadow.angle}
                                              tooltip={{
                                                formatter: (value) =>
                                                  `angle: ${value}°`,
                                              }}
                                              tooltipPlacement="bottom"
                                              value={frameShadow.angle}
                                              step={5}
                                              min={0}
                                              max={360}
                                              // onChange={(value)=>{ this.handleFrameZoom(value); }}
                                              // onAfterChange={(value)=>{ this.handleFrameZoomEnd(value); }}
                                              onAfterChange={(value) => {
                                                frameShadow.angle = value;
                                                this.handleFrameShadowUpdate(
                                                  frameShadow
                                                );
                                              }}
                                              onChange={(value) => {
                                                frameShadow.angle = value;
                                                this.handleFrameShadowUpdate(
                                                  frameShadow,
                                                  false
                                                );
                                              }}
                                            />
                                          </Tooltip>

                                          <div className="spacerH" />
                                          <div className="spacerH" />

                                          <Popover
                                            content={
                                              <CirclePicker
                                                width={230}
                                                circleSpacing={5}
                                                circleSize={15}
                                                colors={Colors.displayColors}
                                                color={frameShadow.hexColor}
                                                onChange={(e) => {
                                                  frameShadow.hexColor = e.hex;
                                                  this.handleFrameShadowUpdate(
                                                    frameShadow
                                                  );
                                                }}
                                              />
                                            }
                                            title="Change Color"
                                            trigger="focus"
                                          >
                                            <Button
                                              type="default"
                                              icon={<BgColorsOutlined />}
                                              className="button"
                                              disabled={!frameShadow.enabled}
                                              onClick={(e) => e.target.focus()}
                                              style={{
                                                backgroundColor:
                                                  frameShadow.hexColor,
                                              }}
                                            />
                                          </Popover>

                                          <div className="spacerH" />
                                          <Button
                                            type="primary"
                                            onClick={() => {
                                              this.applyShadowToAllFrames(
                                                editedFrame.shadow
                                              );
                                            }}
                                          >
                                            {GetText(
                                              'lefttab.backgrounds.applytoall'
                                            )}
                                          </Button>
                                          {/* <Button type="primary" onClick={()=>{  alert("Apply shadow!"); }} >{GetText("lefttab.shadow.apply")}</Button> */}
                                        </span>
                                      }
                                    >
                                      <Tooltip
                                        mouseEnterDelay={this.tooltipDelay}
                                        title={GetText(
                                          'tooltip.transformtool.shadow'
                                        )}
                                        placement="top"
                                      >
                                        <Button
                                          type="primary"
                                          block
                                          onClick={(e) => {
                                            this.setState({
                                              displayBorderToolbar:
                                                !this.state.editedFrame,
                                            });
                                          }}
                                        >
                                          <ImgIcon
                                            className="customIcon"
                                            icon={EmbedIcons.ShadowIcon}
                                          />
                                        </Button>
                                      </Tooltip>
                                      {/* <Button type="primary" icon="bg-colors"
                                        onClick={(e) => e.target.focus()}
                                        style={{backgroundColor:editedFrame.text.color ,margin:5}} /> */}
                                    </Popover>
                                  )
                                }

                                {/* Text toolbar */}
                                {frameCanHaveText(editedFrame) && (
                                  <Popover
                                    trigger="click"
                                    placement="bottom"
                                    content={
                                      editedFrame.text && (
                                        <div
                                          key={editedFrame.id}
                                          className="textToolBar"
                                        >
                                          <TextArea
                                            className="textArea"
                                            key={editedFrame.id}
                                            style={
                                              {
                                                // width:editedFrame.width * editionScale,
                                                // height:editedFrame.height * editionScale,
                                              }
                                            }
                                            ref={this.textInputRef}
                                            autoFocus
                                            onChange={this.onTextChange}
                                            autoSize
                                            defaultValue={
                                              editedFrame.text.value
                                            }
                                          />

                                          {/* <Tooltip mouseEnterDelay={this.tooltipDelay}  title="Change Text" placement="top"><Button type="primary" icon="font-color" onClick={(e)=>{ this.handleFrameMoveDown(); }} /></Tooltip> */}

                                          <Select
                                            className="fontFamilySelector"
                                            // defaultValue="lucy"
                                            value={editedFrame.text.family}
                                            onChange={
                                              this.handleTextFamilyChange
                                            }
                                          >
                                            {/* <Option key="font family key" value={"font family value"}>Font family</Option>      */}

                                            {FONT_LIST_EMBED.map((key) => (
                                              <Option
                                                key={key}
                                                value={key}
                                                className="fontFamilySelector_option"
                                              >
                                                <span
                                                  style={{ fontFamily: key }}
                                                >
                                                  {key}
                                                </span>
                                              </Option>
                                            ))}
                                          </Select>
                                          <Select
                                            className="fontSizeSelector"
                                            // defaultValue="lucy"
                                            value={editedFrame.text.size}
                                            onChange={this.handleTextSizeChange}
                                          >
                                            {/* <Option key="font family key" value={"font family value"}>Font family</Option>      */}

                                            {FONT_SIZES_LIST.map((key) => (
                                              <Option
                                                key={key}
                                                className="fontSizeSelector_option"
                                                value={key}
                                              >
                                                {key}
                                              </Option>
                                            ))}
                                          </Select>

                                          <ButtonGroup
                                            style={{ margin: '2px 5px' }}
                                          >
                                            {/** FRAME TEXT COLOR */}
                                            <Popover
                                              content={
                                                <div style={{ width: 230 }}>
                                                  <p>
                                                    {GetText(
                                                      'tooltip.transformtool.text.color'
                                                    )}
                                                  </p>
                                                  <ColorPicker
                                                    spacing={5}
                                                    itemSize={15}
                                                    colors={
                                                      Colors.displayColors
                                                    }
                                                    selected={
                                                      editedFrame.text.color
                                                    }
                                                    onChange={
                                                      this.onTextColorChange
                                                    }
                                                  />
                                                </div>
                                              }
                                              // title={GetText("tooltip.transformtool.text.color")}
                                              trigger="focus"
                                            >
                                              <Button
                                                type="primary"
                                                icon={<BgColorsOutlined />}
                                                onClick={(e) =>
                                                  e.target.focus()
                                                }
                                                style={{
                                                  // borderRadius:12,
                                                  color: editedFrame.text.color,
                                                  backgroundColor:
                                                    Colors.GREY_LIGHT,
                                                  fontWeight: 'bolder',
                                                }}
                                              >
                                                T
                                              </Button>
                                            </Popover>
                                            {/* <Divider type="vertical" /> */}

                                            {/** FRAME BACKGROUND COLOR */}
                                            <Popover
                                              content={
                                                <div style={{ width: 200 }}>
                                                  <p>
                                                    {GetText(
                                                      'tooltip.transformtool.textBackground'
                                                    )}
                                                  </p>
                                                  <ColorPicker
                                                    spacing={2}
                                                    itemSize={15}
                                                    colors={
                                                      Colors.displayColors
                                                    }
                                                    selected={
                                                      editedFrame.fillColor
                                                    }
                                                    onChange={
                                                      this.onTextBGColorChange
                                                    }
                                                  />
                                                  {/* <Button type="primary" size="small" onClick={this.onClearBGColor} >{GetText("")}</Button> */}
                                                  {editedFrame.fillColor && (
                                                    <Button
                                                      type="danger"
                                                      size="small"
                                                      block
                                                      onClick={(e) => {
                                                        this.onTextBGColorChange(
                                                          null
                                                        );
                                                      }}
                                                    >
                                                      {GetText(
                                                        'toolbar.text.backgroundcolor.remove'
                                                      )}
                                                    </Button>
                                                  )}
                                                </div>
                                              }
                                              trigger="focus"
                                            >
                                              <Button
                                                type="primary"
                                                // icon="bg-colors"
                                                onClick={(e) =>
                                                  e.target.focus()
                                                }
                                                style={{
                                                  backgroundColor:
                                                    Colors.GREY_LIGHT,
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    color: 'black',
                                                    width: '20px',
                                                    fontWeight: 'bolder',
                                                    height: '20px',
                                                    backgroundColor:
                                                      editedFrame.fillColor
                                                        ? editedFrame.fillColor
                                                        : 'white',
                                                  }}
                                                >
                                                  T
                                                </div>
                                              </Button>
                                            </Popover>
                                          </ButtonGroup>

                                          <ButtonGroup>
                                            <Tooltip
                                              mouseEnterDelay={
                                                this.tooltipDelay
                                              }
                                              title="Italic"
                                              placement="bottom"
                                            >
                                              <Button
                                                className={
                                                  editedFrame.text.italic
                                                    ? 'selected'
                                                    : ''
                                                }
                                                type="primary"
                                                icon={<ItalicOutlined />}
                                                onClick={(e) => {
                                                  this.toggleTextItalic();
                                                }}
                                              />
                                            </Tooltip>
                                            <Tooltip
                                              mouseEnterDelay={
                                                this.tooltipDelay
                                              }
                                              title="Bold"
                                              placement="bottom"
                                            >
                                              <Button
                                                className={
                                                  editedFrame.text.bold
                                                    ? 'selected'
                                                    : ''
                                                }
                                                type="primary"
                                                icon={<BoldOutlined />}
                                                onClick={(e) => {
                                                  this.toggleTextBold();
                                                }}
                                              />
                                            </Tooltip>
                                            <Tooltip
                                              mouseEnterDelay={
                                                this.tooltipDelay
                                              }
                                              title="align left"
                                              placement="bottom"
                                            >
                                              <Button
                                                className={
                                                  editedFrame.text.halign ===
                                                  'left'
                                                    ? 'selected'
                                                    : ''
                                                }
                                                type="primary"
                                                icon={<AlignLeftOutlined />}
                                                onClick={(e) => {
                                                  this.changeTextAlign('left');
                                                }}
                                              />
                                            </Tooltip>
                                            <Tooltip
                                              mouseEnterDelay={
                                                this.tooltipDelay
                                              }
                                              title="align center"
                                              placement="bottom"
                                            >
                                              <Button
                                                className={
                                                  editedFrame.text.halign ===
                                                  'center'
                                                    ? 'selected'
                                                    : ''
                                                }
                                                type="primary"
                                                icon={<AlignCenterOutlined />}
                                                onClick={(e) => {
                                                  this.changeTextAlign(
                                                    'center'
                                                  );
                                                }}
                                              />
                                            </Tooltip>
                                            <Tooltip
                                              mouseEnterDelay={
                                                this.tooltipDelay
                                              }
                                              title="align right"
                                              placement="bottom"
                                            >
                                              <Button
                                                className={
                                                  editedFrame.text.halign ===
                                                  'right'
                                                    ? 'selected'
                                                    : ''
                                                }
                                                type="primary"
                                                icon={<AlignRightOutlined />}
                                                onClick={(e) => {
                                                  this.changeTextAlign('right');
                                                }}
                                              />
                                            </Tooltip>
                                            <Tooltip
                                              mouseEnterDelay={
                                                this.tooltipDelay
                                              }
                                              title="clear content"
                                              placement="bottom"
                                            >
                                              <Button
                                                type="danger"
                                                icon={<DeleteOutlined />}
                                                onClick={(e) => {
                                                  this.clearText();
                                                }}
                                              />
                                            </Tooltip>
                                          </ButtonGroup>

                                          {/* <ButtonGroup> */}
                                          <div className="spacer" />
                                          <Button
                                            type="primary"
                                            onClick={() => {
                                              this.applyCurrentTextOptionsToAll();
                                            }}
                                          >
                                            {GetText(
                                              'lefttab.backgrounds.applytoall'
                                            )}
                                          </Button>
                                          {/* </ButtonGroup> */}

                                          {/* <Tooltip mouseEnterDelay={this.tooltipDelay}  title="in progress" placement="top"><Button type="primary" disabled onClick={(e)=>{ this.handleFrameMoveDown(); }} >i</Button></Tooltip>
                                    <Tooltip mouseEnterDelay={this.tooltipDelay}  title="in progress" placement="top"><Button type="primary" disabled onClick={(e)=>{ this.handleFrameMoveDown(); }} >b</Button></Tooltip>
                                    <Tooltip mouseEnterDelay={this.tooltipDelay}  title="in progress" placement="top"><Button type="primary" disabled onClick={(e)=>{ this.handleFrameMoveDown(); }} >b</Button></Tooltip>
                                    <Tooltip mouseEnterDelay={this.tooltipDelay}  title="in progress" placement="top"><Button type="primary" disabled onClick={(e)=>{ this.handleFrameMoveDown(); }} >b</Button></Tooltip>
                                    <Tooltip mouseEnterDelay={this.tooltipDelay}  title="in progress" placement="top"><Button type="primary" disabled onClick={(e)=>{ this.handleFrameMoveDown(); }} >b</Button></Tooltip>
                                    <Tooltip mouseEnterDelay={this.tooltipDelay}  title="in progress" placement="top"><Button type="primary" disabled onClick={(e)=>{ this.handleFrameMoveDown(); }} >b</Button></Tooltip>
                                    <Tooltip mouseEnterDelay={this.tooltipDelay}  title="in progress" placement="top"><Button type="primary" disabled onClick={(e)=>{ this.handleFrameMoveDown(); }} >b</Button></Tooltip>
                                    */}
                                        </div>
                                      )
                                    }
                                  >
                                    <Tooltip
                                      mouseEnterDelay={this.tooltipDelay}
                                      title={GetText(
                                        'tooltip.transformtool.text'
                                      )}
                                      placement="top"
                                    >
                                      <Button
                                        id="toolbarTextEditButton"
                                        type="primary"
                                        block
                                        icon={<EditOutlined />}
                                        onClick={(e) => {
                                          if (!editedFrame.text) {
                                            this.handleAddDefaultTextToFrame();
                                          }
                                        }}
                                      />
                                    </Tooltip>
                                  </Popover>
                                )}

                                {
                                  // ---- INFO ----
                                  ((IsLocalhost && editedFrame) ||
                                    editedFramePhotoObj) && (
                                    <Popover
                                      content={this.getInfoOverContent(
                                        editedFrame
                                      )}
                                      mouseEnterDelay={this.tooltipDelay}
                                      placement="top"
                                    >
                                      <Button
                                        type="primary"
                                        block
                                        icon={<InfoOutlined />}
                                      />
                                    </Popover>
                                  )
                                }

                                {
                                  // ---- DELETE ----
                                  frameHasDeleteButton(editedFrame) && (
                                    <Tooltip
                                      mouseEnterDelay={this.tooltipDelay}
                                      title={GetText(
                                        'tooltip.transformtool.delete'
                                      )}
                                      placement="top"
                                    >
                                      <Button
                                        block
                                        icon={<DeleteOutlined />}
                                        className="button_delete"
                                        onClick={(e) => {
                                          this.handleFrameDelete();
                                        }}
                                      />
                                    </Tooltip>
                                  )
                                }
                              </div>
                            </div>
                          )
                      }
                    </div>
                  )
                }

                {
                  /* // --------------------- Page Details -- Next/Previous pages ------------------------ */
                  pageList.length > 1 && (
                    <div
                      className="pageDetail"
                      style={{
                        marginBottom: this.props.isPreviewMode ? '35px' : null,
                      }}
                    >
                      <div className="spacer" />
                      <Button
                        type="primary"
                        size="small"
                        disabled={pageIndex === 0}
                        onClick={(e) => {
                          this.handleFirstPageClick();
                        }}
                      >
                        <LeftOutlined />
                        {GetText('edition.toolbar.page.first')}
                      </Button>
                      <div className="spacer" />
                      <Button
                        type="primary"
                        size="small"
                        disabled={pageIndex === 0}
                        onClick={(e) => {
                          this.handlePreviousPageClick();
                        }}
                      >
                        <LeftOutlined />
                        {GetText('edition.toolbar.page.previous')}
                      </Button>
                      <div className="content unselectable">
                        {getPageGroupDisplayName(
                          pageGroup,
                          this.props.project.docID
                        )}
                      </div>
                      <Button
                        type="primary"
                        size="small"
                        disabled={pageIndex > pageList.length - 2}
                        onClick={(e) => {
                          this.handleNextPageClick();
                        }}
                      >
                        {GetText('edition.toolbar.page.next')}
                        <RightOutlined />
                      </Button>
                      <div className="spacer" />
                      <Button
                        type="primary"
                        size="small"
                        disabled={pageIndex > pageList.length - 2}
                        onClick={(e) => {
                          this.handleLastPageClick();
                        }}
                      >
                        {GetText('edition.toolbar.page.last')}
                        <RightOutlined />
                      </Button>
                    </div>
                  )
                }
                <div className="spacer" />
                {DebugFlags.IS_LOCALHOST && (
                  <div className="debug_text">
                    EditoScale: {editionScale.toFixed(3)}
                  </div>
                )}
              </div>
            )
          }

          <Button
            className="previewButton"
            icon={<DesktopOutlined />}
            type={this.props.isPreviewMode ? 'danger' : 'dashed'}
            onClick={(e) => this.handlePreviewClick()}
          >
            {GetText(
              this.props.isPreviewMode
                ? 'preview.button.off'
                : 'preview.button.on'
            )}
          </Button>
        </div>
      </div>
    );
  }

  // --------------------- Transform toolbar ------------------------

  // transformToolbarComp(){

  //     const { transformBounds, rootY, rootX, editedFrame, editedPage } = this.state;
  //     const isEditing = editedPage;// if no edited page, it means we are

  //     if( transformBounds && editedFrame && !isEditing )
  //     {
  //         const canMoveLevel = (editedFrame.type !== FRAME_TYPE.BKG);
  //         const isText = (editedFrame.type === FRAME_TYPE.TEXT);

  //         return(
  //             <TextArea placeholder="Autosize height based on content lines"
  //                 key={editedFrame.id}
  //                 ref={this.textInputRef}
  //                 onChange={this.onTextChange}
  //                 autoSize defaultValue={editedFrame.text.value}
  //                 //value={editedFrame.text.value}
  //                 />
  //         );

  //         return(
  //             <div className="transformToolbar"
  //                 key={editedFrame.id}
  //                 // onClick={(e)=>{alert("transformtooblar")}}
  //                 style={{
  //                 position:"absolute",
  //                 width:"40px",
  //                 // minHeight:"150px",
  //                 zIndex:1001,
  //                 display: "inline-block",
  //                 left:transformBounds.right-rootX + 20,
  //                 // left:0,
  //                 top:transformBounds.top-rootY,
  //                 transformOrigin:"50% 50%",
  //                 //transform:`translateX( ${-frame.width/2}px) translateY( ${-frame.height/2}px) rotate(${frame.rotation}deg)`
  //                 //transform={"translate(" + t.x + "," + t.y + ") rotate("+ t.angle +"," + t.width/2 +"," + t.height/2 +")"}
  //                 //transform:`translate( ${this.calculatePosX()} , ${this.calculatePosY()} ) rotate(${frame.rotation})`
  //                 }}
  //                 >
  //                 <Tooltip mouseEnterDelay={this.tooltipDelay}  title={"name:" +JSON.stringify(editedFrame)} placement="top"><Button type="primary" block={true} icon="info" /></Tooltip>
  //                 { canMoveLevel &&
  //                     <Tooltip mouseEnterDelay={this.tooltipDelay}  title="Move Up" placement="top"><Button type="primary" block={true} icon="plus" onClick={(e)=>{ this.handleFrameMoveUp(); }} /></Tooltip>
  //                 }
  //                 { canMoveLevel &&
  //                     <Tooltip mouseEnterDelay={this.tooltipDelay}  title="Move Down" placement="top"><Button type="primary" block={true} icon="minus" onClick={(e)=>{ this.handleFrameMoveDown(); }} /></Tooltip>
  //                 }
  //                 { isText &&
  //                     <div key={editedFrame.id} className="textToolBar">
  //                         <TextArea placeholder="Autosize height based on content lines"
  //                             key={editedFrame.id}
  //                             ref={this.textInputRef}
  //                             onChange={this.onTextChange}
  //                             autoSize defaultValue={editedFrame.text.value}
  //                             //value={editedFrame.text.value}
  //                             />

  //                         <ButtonGroup>
  //                             <Popover content={(<CompactPicker colors={this.state.displayColors} color={editedFrame.text.color} onChange={this.onColorChange} />)} title="Change Color" trigger="focus">
  //                                 <Button type="primary" icon="bg-colors" />
  //                             </Popover>
  //                             <Tooltip mouseEnterDelay={this.tooltipDelay}  title="Change Text" placement="top"><Button type="primary" icon="font-color" onClick={(e)=>{ this.handleFrameMoveDown(); }} /></Tooltip>
  //                             <Tooltip mouseEnterDelay={this.tooltipDelay}  title="Change Text" placement="top"><Button type="primary" icon="font-size" onClick={(e)=>{ this.handleFrameMoveDown(); }} /></Tooltip>
  //                         </ButtonGroup>
  //                     </div>
  //                 }
  //                 <Tooltip mouseEnterDelay={this.tooltipDelay}  title="Delete this frame" placement="top"><Button type="danger" block={true} icon="delete" onClick={(e)=>{ this.handleFrameDelete(); }} /></Tooltip>
  //             </div>
  //         );
  //     }
  //     return null;
  // }
}

/*
// Redux map
function mapStateToProps(state) {
    const { authentication, edition } = state;
    const { user } = authentication;
    const { editionState } = edition;

    // we need user and projects
    return {
        user,
        editionState
    };
}

// Redux connect
const reduxConnected = connect(mapStateToProps)(EditionArea);
export { reduxConnected as EditionArea };

// another way to write (cleaner IMO)
// const reduxConnected = connect( state => ({editionState: state.edition.editionState}))(EditionArea);
*/

// --------------------- redux ------------------------

// Redux map
function mapStateToProps(state) {
  const { authentication, edition, photos, backgrounds, cliparts, layouts } =
    state;
  const { project, selectedPage, selectedFrameID } = edition;
  const { pageList } = project;

  // we need user, project and layouts
  return {
    pageList,
    project,
    currentPage: editionSelectors.GetSelectedPage(state),
    currentVisibleFrames: editionSelectors.GetAllVisibleFramesByID(state),
    // currentPageGroup : (pageList)? editionSelectors.GetCurrentPageGroup(state) : [],
    projectClass: PROJECT_CONST.project_class,
    selectedPageIndex: selectedPage,
    selectedFrameID,
    needSave: editionSelectors.NeedSave(state),
    hasPhotosImporting: photoListSelector.hasPhotosStillImporting(state),
    layoutsByID: layoutListSelectors.getLayoutsByID(state),
    backgroundsByID: backgrounds.byID,
    clipartsByID: cliparts.byID,
    overlayersByID: overlayerSelectors.getAllOverlayersByID(state),
    // user : authentication.user,
    // photos: photosByID,
    photosByID: photoListSelector.getAllPhotosByID(state),
    tempPhotoMapping: photoListSelector.getTempPhotoToNewPhotoMapping(state),

    gridMode: UISelectors.GetCurrentGridMode(state),

    // selectors
    allowGrid: UISelectors.allowGrid(state),
    allowUndo: editionSelectors.allowUndo(state),
    allowRedo: editionSelectors.allowRedo(state),
    allowCopy: editionSelectors.allowCopy(state),
    allowPaste: editionSelectors.allowPaste(state),
    isPreviewMode: UISelectors.IsPreviewMode(state),
  };
}

// --------------------- export ------------------------

const reduxConnected = connect(mapStateToProps)(EditionArea);
export { reduxConnected as EditionArea };

/* TODO: Undo / redo
const mapDispatchToProps = dispatch => {
    return {
        onUndo: () => dispatch(UndoActionCreators.undo()),
        onRedo: () => dispatch(UndoActionCreators.redo())
    }
}

https://redux.js.org/recipes/implementing-undo-history

// SHORTCUT
const reduxConnected = connect ( (state) => ({
        editionState : state.edition.editionState,
        user : state.authentication.user ,
        canUndo : state.edition.editionState.pageList.past.length > 0,
        canRedo : state.edition.editionState.pageList.future.length > 0
}), mapDispatchToProps )(EditionArea);

export { reduxConnected as EditionArea };

*/
