import React from 'react';
import { connect } from 'react-redux';
import { Popover, Button, Modal } from 'antd';
import { UIActions } from '../ui/ui';
import { orderActions } from './order';
import { GetText } from '../../data/LanguageHelper';
import { editionActions, editionSelectors } from '../edition/edition';

import { pricingSelectors } from '../pricing/pricing';
import {
  GetProjectCover,
  GetAlbumCoverDisplayName,
} from '../../utils/coverHelper';
import { GetUnsafeHTMLDiv } from '../../utils/ReactHelper';
import {
  GetProjectDocSizeCM,
  GetProjectSizeCM,
} from '../../utils/ProductHelper';
import { CardHelper } from '../../utils/card/CardHelper';
import {
  IsAlbumEditor,
  IsCanvasEditor,
  IsCalendarEditor,
  IsCardEditor,
} from '../../data/config';
import { photoListSelector } from '../photoList/photoList';
import { Project } from '../../data/Project';

class OrderDetailPopover extends React.Component {
  // --------------------- Constructor ------------------------

  constructor(props) {
    super(props);
    this.state = {
      // isVisible = false;
    };
    // this.popupRef = createRef();;
  }

  // --------------------- react ------------------------

  // componentDidUpdate(nextProps, nextState) {
  //     if (nextProps.pageList.length !==this.props.pageList.length ) {
  //       this.setState({
  //           numPages :this.props.pageList.length-1
  //       })
  //     }
  //   }

  // --------------------- Methods ------------------------

  getYesNoValue = (x) => (x ? GetText('common.yes') : GetText('common.no'));

  getProjectDetail = (project: Project) => {
    let detailString = '';

    // ----- ALBUM ----
    if (IsAlbumEditor()) {
      // var typeId:String;
      // if(project.type === ALBUM_TYPES.CLASSIC)
      // {
      //  if(CoverManager.instance.getCoverVo().coverType === ProductsCatalogue.COVER_CUSTOM)
      //   typeId = ProductsCatalogue.ALBUM_CONTEMPORARY;
      //  else
      //   typeId = ProductsCatalogue.ALBUM_CLASSIC;
      // }
      // else
      //  typeId = project.type;
      const typeId = project.type;
      detailString += `<b>${GetText(
        `album.type.types_albums_${typeId}`
      )} ${GetText(`album.prefix.${project.docID}`)}</b>`;
      detailString += `<br />${project.pageList.length - 1} ${GetText(
        'common.pages'
      )}`;
      detailString += `<br /><b>${GetText(
        'order.panel.detail.format'
      )}</b>: ${GetProjectDocSizeCM(project.docID)}`;
      detailString += `<br /><b>${GetText(
        'order.panel.detail.paper'
      )}</b>: ${this.getYesNoValue(project.paper)}`;

      const cover = GetProjectCover(project);
      if (cover.coverClassicOptions) {
        detailString += `<br /><b>${GetText(
          'order.panel.detail.cover'
        )}</b>: ${GetAlbumCoverDisplayName(project)}`;
      }

      // ---- CLASSIC ----
      // TODO:
      // if(CoverManager.instance.getCorner() !=="" && CoverManager.instance.getCorner() !=="none")
      //  detailString += "<br /><b>" + GetText("order.panel.detail.corners") + "</b>: " + CoverManager.instance.getCornerLabel();

      // if(typeId === ProductsCatalogue.ALBUM_CLASSIC)
      //  detailString += "<br /><b>" + GetText("order.panel.detail.coverText") + "</b>: " + this.getYesNoValue(ProjectManager.instance.coverText);

      detailString += `<br /><b>${GetText(
        'leftab.project.album.paper.quality.label'
      )}</b>: ${GetText(
        `leftab.project.album.paper.quality.${project.pagePaperQuality}`
      )}`;
      detailString += `<br /><b>${GetText(
        'lefttab.project.album.coated'
      )}</b>: ${this.getYesNoValue(project.coated)}`;
      detailString += `<br /><b>${GetText(
        'lefttab.project.album.matte'
      )}</b>: ${this.getYesNoValue(project.matte)}`;
      detailString += `<br /><b>${GetText(
        'order.panel.has.flyleaf'
      )}</b>: ${GetText(`album.flyleaf.label.${project.flyleaf}`)}`;

      // TODO: add cover matte info here too
    }

    // ----- CALENDAR ----
    else if (IsCalendarEditor()) {
      detailString += `<b>${GetText(`calendar.prefix.${project.docID}`)}</b>`;
      detailString += `<br /><b>${GetText(
        'order.panel.detail.format'
      )}</b>: ${GetProjectDocSizeCM(project.docID)}`;
      detailString += `<br /><b>${GetText(
        'lefttab.project.calendar.coated'
      )}</b>: ${this.getYesNoValue(project.coated)}`;
      detailString += `<br /><b>${GetText(
        'lefttab.project.calendar.matte'
      )}</b>: ${this.getYesNoValue(project.matte)}`;
    }

    // // ----- CANVAS -----
    else if (IsCanvasEditor()) {
      const product = GetText(`class.${project.classname}`);
      const type = GetText(`canvas.type.${project.type}_types`);
      const format = GetText(
        `canvas.orientation.types_canvas_${project.canvasFormat}`
      );
      const dimension = GetProjectSizeCM(project, 0);

      detailString += `<b>${product}</b>`;
      detailString += `<br /><b>${GetText(
        'canvas.configurator.type.label'
      )}</b>: ${type}`;
      detailString += `<br /><b>${GetText(
        'canvas.configurator.orientation.label'
      )}</b> ${format}`;
      detailString += `<br /><b>${GetText(
        'order.panel.detail.format'
      )}</b>: ${dimension}`;
    }

    // ----- CARDS -----
    else if (IsCardEditor()) {
      detailString += `<b>${GetText(`card.prefix.${project.docID}`)}</b>`; // + " " + GetText("cards.orientation."+GetProjectOrientation(project.docID));
      detailString += `<br /><b>${GetText(
        'order.panel.detail.format'
      )}</b>: ${GetProjectDocSizeCM(project.docID)}`;
      detailString += `<br /><b>${GetText(
        'lefttab.card.numTotal'
      )}</b>: ${CardHelper.GetTotalCardNum(project.cardPack)}`;
      detailString += `<br /><b>${GetText(
        'cards.configurator.model.label'
      )}</b>: ${CardHelper.GetPackDisplayName(project.cardPack)}`;
      detailString += `<br /><b>${GetText(
        'cards.configurator.envelope.label'
      )}</b>: ${GetText(`cards.configurator.envelope.${project.envelope}`)}`;
      detailString += `<br /><b>${GetText(
        'lefttab.project.cards.coated'
      )}</b>: ${this.getYesNoValue(project.coated)}`;
      detailString += `<br /><b>${GetText(
        'lefttab.project.cards.matte'
      )}</b>: ${this.getYesNoValue(project.matte)}`;
    }

    return detailString;
  };

  handlePopoverVisiblityChange = (value) => {
    // alert("popover visibility changed:" + value)
    this.setState({ isVisible: value });
  };

  handleOrderProject = () => {
    this.setState({ isVisible: false });

    // alert("NeedSave:" + this.props.needSave);
    if (this.props.needSave) {
      if (this.props.photosStillImporting) {
        Modal.confirm({
          title: GetText('popup.order.error.stillUploading.title'),
          content: GetText('popup.order.error.stillUploading.desc', true),
          okText: GetText('common.ok'),
        });
      } else {
        Modal.confirm({
          title: GetText('popup.order.warn.saveproject.title'),
          content: GetText('popup.order.warn.saveproject.description', true),
          cancelText: GetText('common.cancel'),
          okText: GetText('common.ok'),
          onOk: () => {
            this.props.dispatch(editionActions.SaveProject());
          },
        });
      }
    } else {
      // Set back preview mode to false, othwise issue while generating the navigator
      this.props.dispatch(UIActions.SetPreviewMode(false));
      // start order!
      this.props.dispatch(orderActions.startOrder());
    }
  };

  /// ///////////////////////////////////////////////////
  // Render
  /// ///////////////////////////////////////////////////
  render() {
    return (
      <Popover
        trigger="click"
        ref={this.popupRef}
        // className="orderPanel"
        open={this.state.isVisible}
        onOpenChange={this.handlePopoverVisiblityChange}
        overlayClassName="orderPanel"
        content={
          !this.props.project ? null : (
            <div>
              <div className="content">
                <h3>{GetText('order.panel.detail.title')}</h3>
                {/* <p> */}
                {GetUnsafeHTMLDiv(this.getProjectDetail(this.props.project))}
                {/* </p> */}
                <h3>
                  {GetText('order.panel.detail.totalPrice')} :{' '}
                  {this.props.projectPrice}€
                </h3>
              </div>
              <div className="content">
                <h3>{GetText('order.panel.promo.title')}</h3>
                <p>{GetText('order.panel.promo.description')}</p>
              </div>

              <Button
                type="primary"
                className="orderButton"
                // icon="shopping-cart"
                onClick={(e) => {
                  this.handleOrderProject();
                }}
              >
                {GetText('topbar.order.btn.label')}
              </Button>
            </div>
          )
        }
      >
        {this.props.children}
      </Popover>
    );
  }
}

// --------------------- redux ------------------------

// Redux map
function mapStateToProps(state) {
  return {
    project: state.edition.project,
    projectOptions: editionSelectors.GetProjectOptionsSelector(state),
    projectPrice: pricingSelectors.GetProjectPrice(state),
    needSave: editionSelectors.NeedSave(state),
    photosStillImporting: photoListSelector.hasPhotosStillImporting(state),
  };
}

// --------------------- export ------------------------

const reduxConnected = connect(mapStateToProps)(OrderDetailPopover);
export { reduxConnected as OrderDetailPopover };
