import React from 'react';
import { connect } from 'react-redux';
import { Button, Spin, Modal, Select, InputNumber, Row } from 'antd';
import { cloneDeep } from 'lodash';
import { Link } from 'react-router-dom';
import type { ProjectOptions } from '../../../types/types';
import { editionActions } from '../../../feature/edition/edition';
import { GetProductCatalogue } from '../../../data/productCatalogue';
// import { classicNameResolver } from 'typescript';
import { GetText } from '../../../data/LanguageHelper';
import { GetProjectDocSizeCM, GetDoc } from '../../../utils/ProductHelper';
import { popupHelper } from '../../../feature/alert/popupHelper';
import {
  CanvasHelper,
  CANVAS_FORMAT,
  CANVAS_TYPES,
} from '../../../utils/canvas/CanvasHelper';
import { cmToPixel, pixelToCm } from '../../../utils/MeasureUtils';
import { pricingSelectors } from '../../../feature/pricing/pricing';
import { history, ROUTE_CONST } from '../../../utils/history';
import { PROJECT_CONST } from '../../../data/config';
import { CreateProject } from '../../../feature/project/CreateProject';
import { GetProjectOptions } from '../../../feature/project/GetProjectOptions';

interface CanvasProjectCreationViewProps {
  isUpgrade: boolean;
  onCloseCallback: () => void;
}

class CanvasProjectCreationView extends React.Component<CanvasProjectCreationViewProps> {
  // --------------------- Constructor ------------------------

  constructor(props) {
    super(props);

    // bind functions
    this.handleProductChange = this.handleProductChange.bind(this);
    this.handleFormatChange = this.handleFormatChange.bind(this);
    this.handleSizeChange = this.handleSizeChange.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleKadapakColorChange = this.handleKadapakColorChange.bind(this);

    // retrieve product list
    const catalogue = GetProductCatalogue(); // make a copy, in case of
    // rebranding filter products
    if (
      !this.props.isUpgrade &&
      this.props.rebranding?.subProductFilter?.canvas
    ) {
      catalogue.products = this.props.rebranding.subProductFilter.canvas;
    }
    const productList = catalogue.products;
    const productOptionKeys =
      Object.keys(productList); /* .map((key, index, arr)=>{
            return key;
        }); */

    this.state = {
      // whole canvas catalogue
      catalogue,

      // = type :canvas, wood, plexi, ETC...
      product: null, // Current product selected
      productOptions: productOptionKeys,

      // potrait, landscape, square..
      format: null, // current product format
      formatOptions: null,

      // = "20x20", "30x45", etc...
      size: null,
      sizeOptions: null,

      // kadapak frame color
      kadapakColor: Object.keys(catalogue.kadapakFrameColors)[0],
      kadapakColorOptions: Object.keys(catalogue.kadapakFrameColors),

      // free format
      freeWidth: 25,
      freeHeight: 25,
    };
  }

  // --------------------- react ------------------------

  componentDidMount() {
    // automatically set the first product
    if (this.props.isUpgrade) {
      const { project } = this.props;
      const doc = GetDoc(project.docID);

      this.handleProductChange(project.type)
        .then(() => this.handleFormatChange(project.canvasFormat))
        .then(() => {
          if (project.canvasFormat === CANVAS_FORMAT.FREE) {
            this.setState({
              freeWidth: Math.round(pixelToCm(project.width)),
              freeHeight: Math.round(pixelToCm(project.height)),
            });
          } else this.handleSizeChange(project.docID.split('_')[1]);
        })
        .then(() => this.handleKadapakColorChange(project.canvasFrameColor));
    } // default
    else {
      this.handleProductChange(this.state.productOptions[0]);
    }
  }

  // --------------------- methods ------------------------

  handleClose = () => {
    // if not upgrade be sure to be on HOME
    if (!this.props.isUpgrade) {
      history.push(ROUTE_CONST.HOME);
    }

    // invoke callback if there is one
    this.props.onCloseCallback?.();
  };

  handleProductChange(val): Promise {
    return new Promise((resolve, reject) => {
      const product = this.state.catalogue.products[val];
      const formatOptions = Object.keys(product);
      let { format } = this.state;
      if (!formatOptions.includes(format)) {
        [format] = formatOptions;
      }

      this.setState(
        {
          product: val,
          formatOptions,
          format,
        },
        () => {
          this.handleFormatChange(format).then(() => resolve());
        }
      ); // after setting the product, update also the size
    });
  }

  handleFormatChange(format): Promise {
    return new Promise((resolve, reject) => {
      const formatObj =
        this.state.catalogue.products[this.state.product][format];
      const sizeOptions = formatObj.sizes;
      let size = sizeOptions ? this.state.size : null;

      // default
      if (sizeOptions && !sizeOptions.includes(size)) size = formatObj.default;

      this.setState(
        {
          format,
          sizeOptions,
          size,
        },
        () => {
          this.handleSizeChange(size).then(() => resolve());
        }
      ); // after setting the product, update also the size
    });
  }

  handleSizeChange(val): Promise {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          size: val,
        },
        () => {
          resolve();
        }
      );
    });
  }

  handleKadapakColorChange(colorID): Promise {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          kadapakColor: colorID,
        },
        () => {
          resolve();
        }
      );
    });
  }

  handleFreeFormatChange({ width, height }) {
    this.setState({
      freeWidth: !Number.isNaN(width) ? width : this.state.freeWidth,
      freeHeight: !Number.isNaN(height) ? height : this.state.freeHeight,
    });
  }

  handleConfirm() {
    const docID = this.getDocID();
    const doc = GetDoc(docID);

    // --------------------- UPGRADE ------------------------
    // case upgrade
    if (this.props.isUpgrade) {
      GetProjectOptions(this.props.project);
      this.props.dispatch(
        editionActions.UpgradeCurrentProject({
          type: this.state.product,
          docID,
          options: {
            canvasFrameColor: this.state.kadapakColor,
            canvasFormat: this.state.format,
          },
          canvasFreeHeight: this.state.freeHeight,
          canvasFreeWidth: this.state.freeWidth,
        })
      );
      this.handleClose();
      return;
    }

    // ---------------------  NEW PROJECT  ------------------------
    // create new project
    const proj = CreateProject({
      classname: PROJECT_CONST.project_class,
      docID,
      type: this.state.product,
      projectName: 'new canvas project', // TODO:
      numPages: 1, // always 1 page for canvas
      layoutStore: this.props.layouts,
      // project options
      options: {
        canvasFrameColor: this.state.kadapakColor,
        canvasFormat: this.state.format,
      },
      // canvas only creation params
      canvasFreeHeight: this.state.freeHeight,
      canvasFreeWidth: this.state.freeWidth,
    });

    // update state
    this.setState({
      project: proj,
    });

    popupHelper.showRenameProjectPopup(
      // success
      (newProjectName) => {
        const { project } = this.state;
        project.name = newProjectName;
        this.props.dispatch(editionActions.CreateProject(cloneDeep(project)));
      },
      // cancel
      () => {
        // nothing
      }
    );
  }

  getDocID(optionalSize) {
    const { product, format, size } = this.state;
    return CanvasHelper.GetCanvasDocID(product, format, optionalSize || size);
  }

  getProjectPrice() {
    // TODO: square 20X30 do not exists.. it makes the whole thing break on the first set state

    let options: ProjectOptions = this.props.project
      ? GetProjectOptions(this.props.project)
      : {};
    const docID = this.getDocID();
    if (docID) {
      options = {
        ...options,
        ...{
          docID,
          type: this.state.product,
          numPages: 1,
          canvasFrameColor: this.state.kadapakColor,
          canvasFormat: this.state.format,
          height: cmToPixel(this.state.freeHeight),
          width: cmToPixel(this.state.freeWidth),
        },
      };

      return pricingSelectors.GetProjectPrice(this.props.storeRef, options);
    }
    return 0;
  }

  // --------------------- render ------------------------

  render() {
    const currentProject = this.props.project;
    const projectClass = PROJECT_CONST.project_class;
    const { Option } = Select;

    const previewURL = CanvasHelper.GetPreviewUrl(
      this.state.product,
      this.state.format,
      this.state.kadapakColor
    );
    const projectPrice = this.getProjectPrice();

    return (
      <Modal
        title={
          this.props.isUpgrade
            ? GetText('upgradeProject.title')
            : GetText('newProject.title')
        }
        open
        footer={null}
        className="projectCreationView"
        onCancel={this.handleClose}
      >
        <Spin spinning={this.props.projects.loading}>
          {/* <h3>{GetText("newProject.canvas.desc")}</h3> */}

          {/** --- TYPES: canvas, wood, plexi --- */}
          <h4>{GetText('canvas.configurator.type.label')}</h4>
          <Select
            // defaultValue="lucy"
            value={this.state.product}
            placeholder="Select a Product"
            onChange={this.handleProductChange}
          >
            {this.state.productOptions.map((key) => (
              <Option key={key} value={key}>
                {GetText(`canvas.type.${key}_types`)}
              </Option>
            ))}
          </Select>

          <div className="spacerV" />

          {/** --- FORMAT: portrait, landscape, square, etc... --- */}
          <div className="labelAndSelect">
            <h4>{GetText('canvas.configurator.orientation.label')}</h4>
            <Select
              disabled={!this.state.format}
              value={this.state.format}
              onChange={this.handleFormatChange}
            >
              {this.state.formatOptions &&
                this.state.formatOptions.map((key) => (
                  <Option key={key} value={key}>
                    {GetText(`canvas.orientation.types_canvas_${key}`)}
                  </Option>
                ))}
            </Select>
          </div>

          <div className="spacerV" />

          {/** --- SIZES: 30x3O, 60x40, etc... --- */}
          <div className="labelAndSelect">
            <h4>{GetText('canvas.configurator.size.label')}</h4>

            {
              // normal size system
              this.state.format !== 'freeformat' && (
                <Select
                  // defaultValue="lucy"
                  disabled={!this.state.sizeOptions}
                  value={this.state.size}
                  onChange={this.handleSizeChange}
                >
                  {this.state.sizeOptions &&
                    this.state.sizeOptions.map((sizeFormat) => (
                      <Option key={sizeFormat} value={sizeFormat}>
                        {GetProjectDocSizeCM(this.getDocID(sizeFormat), 0)}
                      </Option>
                    ))}
                </Select>
              )
            }

            {
              // free size system
              this.state.format === 'freeformat' && (
                <div style={{ display: 'inline-block' }}>
                  <InputNumber
                    value={this.state.freeWidth}
                    min={GetDoc('CF').minSize}
                    max={GetDoc('CF').maxSize}
                    // size="small"
                    // formatter={value => `${value} cm`}
                    // parser={value => value.replace(' cm', '')}
                    step={1}
                    onChange={(val) =>
                      this.handleFreeFormatChange({ width: val })
                    }
                  />
                  <span> cm * </span>
                  <InputNumber
                    value={this.state.freeHeight}
                    // size="small"
                    min={GetDoc('CF').minSize}
                    max={GetDoc('CF').maxSize}
                    // formatter={value => `${value} cm`}
                    // parser={value => value.replace(' cm', '')}
                    step={1}
                    onChange={(val) =>
                      this.handleFreeFormatChange({ height: val })
                    }
                  />
                  <span> cm</span>
                </div>
              )
            }
          </div>

          {
            /** --- KADAPAK FRAME COLOR --- */
            this.state.product === CANVAS_TYPES.KADAPAK && (
              <div className="labelAndSelect">
                <h4>{GetText('kadapak.color.label')}</h4>
                <Select
                  value={
                    this.state.kadapakColor
                      ? this.state.kadapakColor
                      : this.state.kadapakColorOptions[0]
                  }
                  onChange={this.handleKadapakColorChange}
                >
                  {this.state.kadapakColorOptions.map((colorID) => (
                    <Option key={colorID} value={colorID}>
                      {GetText(`kadapak.color.${colorID}`)}
                    </Option>
                  ))}
                </Select>
              </div>
            )
          }

          <div className="spacerV" />

          {/** --- PREVIEW --- */}
          <Row className="preview">
            <img
              src={previewURL}
              style={{
                backgroundColor: this.state.kadapakColor
                  ? CanvasHelper.GetFrameColorByID(this.state.kadapakColor)
                  : null,
              }}
            />
          </Row>

          <Row type="flex" justify="space-between">
            <Link to={ROUTE_CONST.SELECT_PROJECT_CLASS}>
              <Button type="link">
                {GetText('newProject.changeEditorClass.button')}
              </Button>
            </Link>

            <Button
              type="primary"
              disabled={projectPrice === 0}
              onClick={this.handleConfirm}
            >
              {GetText('common.continue')}
              {` (${projectPrice}€)`}
            </Button>
          </Row>
        </Spin>
      </Modal>
    );
  }
}

// Redux map
function mapStateToProps(state) {
  const { authentication, projects, edition, layouts } = state;
  const { rebranding } = authentication;
  const { project } = edition;

  // we need user and projects
  return {
    storeRef: state,
    rebranding,
    project,
    projects,
    layouts,
  };
}

// Redux connect
const reduxConnected = connect(mapStateToProps)(CanvasProjectCreationView);
export { reduxConnected as CanvasProjectCreationView };
