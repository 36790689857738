import React from 'react';
import { Icon } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import type { Frame, IPage, Photo, SnappingLines } from '../../../types/types';
import {
  FRAME_TYPE,
  frameCanMove,
  frameCanInsideMove,
  frameCanScale,
  frameCanRotate,
  frameCanCrop,
} from '../../../feature/edition/frameHelper';
import {
  SnapNumberTo,
  SnapNumberToRange,
  DistanceBetweenPoints,
  MidPointsBetweenPoints,
  ToDegree,
  ToRadians,
  rotatePointAround,
} from '../../../utils/MathUtils';
import { DebugFlags } from '../../../debug/DebugFlags';
import ImgIcon from '../../../_components/ImgIcon';
import { mmToPoint, pixelToCm } from '../../../utils/MeasureUtils';
import { EmbedIcons } from '../../../images/EmbedIcons';

// const IconFont = Icon.createFromIconfontCN({
//     scriptUrl: '//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js',
// });

const DRAG_POINT = {
  TOP_LEFT: 'tl',
  TOP_RIGHT: 'tr',
  BOTTOM_LEFT: 'bl',
  BOTTOM_RIGHT: 'br',

  TOP: 't',
  RIGHT: 'r',
  BOTTOM: 'b',
  LEFT: 'l',
};

export const TRANFORM_TOOL_ACTION = {
  MOVE: 'MOVE',
  ROTATE: 'ROTATE',
  SCALE: 'SCALE',
  CROP: 'CROP',
  INSIDE_MOVE: 'INSIDE_MOVE',
  ZOOM: 'ZOOM',

  // BORDER_RADIUS: "BORDER_RADIUS",
  // SHADOW_UPDATE: "SHADOW_UPDATE",
};

interface TransformToolProps {
  pagePosX: number;
  pagePosY: number;
  frame: Frame;
  editedPage: IPage;
  editionScale: number;
  photosByID: Record<Photo>;

  snappingLines: SnappingLines;

  // callbacks
  onDoubleClick: (frame: Frame) => void;
  onFrameUpdateStart: (newFrameState: Frame, editAction: string) => void;
  onFrameUpdate: (newFrameState: Frame, refreshContent: boolean) => void;
  onFrameUpdateCompleted: (newFrameState: Frame) => void;
  onItemDropped: (dropEvent: Event) => void;
}

class TransformTool extends React.Component<TransformToolProps> {
  // TODO: all content should be centered so origin should not be at 0,0 but at 50%,50%
  // this would allow us to follow same conventions as current online and offline editor.

  /**
   *
   * @param {TransformProps} props
   */
  constructor(props) {
    super(props);

    // refs
    this.transformRef = React.createRef();
    this.textEditRef = React.createRef();

    // corner refs
    this.cornerRefs = {};
    this.cornerRefs[DRAG_POINT.TOP_LEFT] = React.createRef();
    this.cornerRefs[DRAG_POINT.TOP_RIGHT] = React.createRef();
    this.cornerRefs[DRAG_POINT.BOTTOM_LEFT] = React.createRef();
    this.cornerRefs[DRAG_POINT.BOTTOM_RIGHT] = React.createRef();

    this.cornerRefs[DRAG_POINT.TOP] = React.createRef();
    this.cornerRefs[DRAG_POINT.RIGHT] = React.createRef();
    this.cornerRefs[DRAG_POINT.BOTTOM] = React.createRef();
    this.cornerRefs[DRAG_POINT.LEFT] = React.createRef();

    // console.log("the frame:" + this.props.frame);

    // let theFrame:Frame = {...this.props.frame}; // make copy of frame so we can work on it
    // let startTransform = {...theFrame.transform}; // make copy of transform, otherwise it is just a ref we are using and will be updated each time we update the frame...
    const startTransform = { ...this.props.frame }; // make copy of transform, otherwise it is just a ref we are using and will be updated each time we update the frame...

    // initial state
    this.state = {
      // frame: theFrame, // current frame we are working on as a seed data
      textEditing: startTransform.type === FRAME_TYPE.TEXT, // if we are editing some text
      moving: false, // are we currently dragging
      rotating: false, // are we currently rotating
      scaling: false,
      cropping: false,
      insideMoving: false,

      startTransform, // original transformation
      dragDetail: {
        startX: 0, // start drag pos
        startY: 0,
      },

      transformOrigin: '50% 50%',
    };
  }

  // --------------------- React ------------------------

  componentDidMount() {
    // if(this.state.textEditing){
    //     this.textEditRef.current.focus();
    // }
  }

  // --------------------- Methods ------------------------

  calculatePosX = () => {
    const theFrame = this.props.frame;
    // console.log("theFrame: "+JSON.stringify(theFrame))
    // return this.props.pagePosX + theFrame.x ;
    return theFrame.x;
  };

  calculatePosY = () => {
    const theFrame = this.props.frame;
    // return this.props.pagePosY + theFrame.y;
    return theFrame.y;
  };

  // ********************************
  // Handle Move
  // ********************************

  /**
   * Handle Move Start
   */
  handleMoveStart = ({ clientX, clientY }) => {
    // if(this.state.textEditing)
    //     return;

    console.log(`HandleMoveStart:${clientX}`);
    const { frame } = this.props;

    // security
    this.checkEndCurrentAction({ clientX, clientY });

    // e.preventDefault();
    this.setState(
      {
        ...this.state,
        moving: true,
        startTransform: { ...frame }, // make a copy of frame
        dragDetail: {
          startX: clientX,
          startY: clientY,
        },
      },

      // callback
      () => {
        // console.log("this.state: "+JSON.stringify(this.state));
        window.addEventListener('mousemove', this.handleMoveUpdate);
        window.addEventListener('mouseup', this.handleMoveEnd);
        this.props.onFrameUpdateStart(frame, TRANFORM_TOOL_ACTION.MOVE);
      }
    );
  };

  handleMoveUpdate = ({ clientX, clientY }) => {
    const t: Frame = this.state.startTransform;

    // calculate position
    let newX =
      t.x + (clientX - this.state.dragDetail.startX) / this.props.editionScale;
    let newY =
      t.y + (clientY - this.state.dragDetail.startY) / this.props.editionScale;

    // snap x to origin points
    const snapTreshold = mmToPoint(2); // 5mm was too much.. (2 => range of 4mm to snap)
    this.props.snappingLines.h.forEach((posY) => {
      newY = SnapNumberTo(newY, posY, snapTreshold);
      if (t.rotation === 0) {
        newY = SnapNumberTo(newY + t.height * 0.5, posY, snapTreshold);
        newY = SnapNumberTo(newY - t.height * 0.5, posY, snapTreshold);
      }
    });
    this.props.snappingLines.v.forEach((posX) => {
      newX = SnapNumberTo(newX, posX, snapTreshold);
      if (t.rotation === 0) {
        newX = SnapNumberTo(newX + t.width * 0.5, posX, snapTreshold);
        newX = SnapNumberTo(newX - t.width * 0.5, posX, snapTreshold);
      }
    });
    // newX = SnapNumberTo(newX, t.x, 5);
    // newY = SnapNumberTo(newY, t.y, 5);

    // we can directly modify the frame from props
    const { frame } = this.props;
    frame.x = newX;
    frame.y = newY;
    this.props.onFrameUpdate(frame);

    // let updatedFrame = {...this.props.frame};
    // updatedFrame.x = newX;
    // updatedFrame.y = newY;

    // // TODO: should we prevent default?
    // //e.preventDefault();
    // this.setState(prevState => ({
    //     ...prevState,
    //     moving:true, // still dragging
    // }),

    // // on state updated callback
    // ()=>{
    //     // nofity parent
    //     // this.props.onFrameUpdate( this.state.frame );
    //     this.props.onFrameUpdate( updatedFrame );
    //    // console.log("frame moved to: " + this.state.frame.transform.x + " - " + this.state.frame.transform.y);
    // });
  };

  handleMoveEnd = ({ clientX, clientY }) => {
    window.removeEventListener('mousemove', this.handleMoveUpdate);
    window.removeEventListener('mouseup', this.handleMoveEnd);

    this.setState(
      {
        moving: false,
      },

      () => {
        // console.log("end:" + JSON.stringify(this.state));
        // TODO: reset this
        this.props.onFrameUpdateCompleted(this.props.frame);
      }
    );
  };

  // ********************************
  // Handle rotation
  // ********************************

  handleRotateStart = ({ clientX, clientY }) => {
    console.log(`start rotating:${clientX}`);

    // security
    this.checkEndCurrentAction({ clientX, clientY });

    const { frame } = this.props;
    const bounds = this.transformRef.current.getBoundingClientRect();
    // let c = t.current;
    // let b = c.getBoundingClientRect();
    // //let bounds = this.transformRef.current.getBoundingClientRect();
    // console.log(b);

    // these are relative to the viewport
    /*
        var rotatingCenter = viewportOffset.top;
        var left = viewportOffset.left;

        var rotatingCenter
        */

    // e.preventDefault();
    this.setState(
      {
        rotating: true,
        startTransform: { ...frame }, // make a copy, not a ref.
        dragDetail: {
          startX: bounds.x + bounds.width / 2,
          startY: bounds.y + bounds.height / 2,
        },
      },

      // callback
      () => {
        window.addEventListener('mousemove', this.handleRotateUpdate);
        window.addEventListener('mouseup', this.handleRotateEnd);
        this.props.onFrameUpdateStart(frame, TRANFORM_TOOL_ACTION.ROTATE);
      }
    );
  };

  handleRotateUpdate = ({ clientX, clientY }) => {
    const { frame } = this.props;
    const p2 = { x: clientX, y: clientY };
    const p1 = {
      x: this.state.dragDetail.startX,
      y: this.state.dragDetail.startY,
    };

    // angle in radians
    const angleRadians = Math.atan2(p2.y - p1.y, p2.x - p1.x);
    // angle in degrees
    const angleDeg = (Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180) / Math.PI;
    // const newAngle = (this.state.startTransform.angle + angleDeg) % 360  + 90 ; // -90 because the rotation icon is at 90°
    let newAngle = angleDeg + 90; // -90 because the rotation icon is at 90°

    // snap angles 45°
    newAngle = SnapNumberToRange(newAngle, 45, 5);
    newAngle = SnapNumberTo(newAngle, 0, 5);

    /*
        if( Math.abs((Math.abs(newAngle)%45)-45) < 15 ){
            newAngle = Math.round(newAngle/45) * 45;
        }
        */

    // direct modify frame
    frame.rotation = newAngle;
    this.props.onFrameUpdate(frame, TRANFORM_TOOL_ACTION.ROTATE);

    // this.setState(prevState => ({
    //     ...prevState,
    //     rotating:true, // still dragging
    //     frame:{
    //         ...prevState.frame,
    //         rotation:newAngle,
    //     }
    // }),

    // // on state updated callback
    // ()=>{
    //     // nofity parent
    //     this.props.onFrameUpdate( this.state.frame );
    //     console.log("frame rotated to: " + this.state.frame.rotation);
    // });
  };

  handleRotateEnd = ({ clientX, clientY }) => {
    window.removeEventListener('mousemove', this.handleRotateUpdate);
    window.removeEventListener('mouseup', this.handleRotateEnd);

    this.setState(
      {
        rotating: false,
      },

      () => {
        // console.log("end:" + JSON.stringify(this.state));
        this.props.onFrameUpdateCompleted(this.props.frame);
      }
    );
  };

  // ********************************
  // Handle scale
  // ********************************

  // handleScaleStart = ( dragPoint, {clientX, clientY}) =>
  handleScaleStart = (dragPoint, e) => {
    const { frame } = this.props;
    const { clientX, clientY } = e;

    console.log(`HandleScaleStart( ${dragPoint} ) :${clientX}`);

    // security
    this.checkEndCurrentAction({ clientX, clientY });

    // get client corner points and scale factor!
    const startPointRect = e.currentTarget.getBoundingClientRect();
    const startPoint = {
      x: startPointRect.left + startPointRect.width / 2,
      y: startPointRect.top + startPointRect.height / 2,
    };
    const oppositeRef =
      dragPoint === DRAG_POINT.TOP_LEFT
        ? this.cornerRefs[DRAG_POINT.BOTTOM_RIGHT]
        : dragPoint === DRAG_POINT.TOP_RIGHT
        ? this.cornerRefs[DRAG_POINT.BOTTOM_LEFT]
        : dragPoint === DRAG_POINT.BOTTOM_LEFT
        ? this.cornerRefs[DRAG_POINT.TOP_RIGHT]
        : this.cornerRefs[DRAG_POINT.TOP_LEFT];

    const oppositePointRect = oppositeRef.current.getBoundingClientRect();
    const oppositePoint = {
      x: oppositePointRect.left + oppositePointRect.width / 2,
      y: oppositePointRect.top + oppositePointRect.height / 2,
    };

    const distanceTool = DistanceBetweenPoints(
      startPoint.x,
      startPoint.y,
      oppositePoint.x,
      oppositePoint.y
    );
    const distanceFrame = Math.hypot(frame.width, frame.height);
    const scaleFactor = distanceTool / distanceFrame;
    console.log(`scaleFactor:${scaleFactor} --> distance: ${distanceTool}`);

    // e.preventDefault();
    this.setState(
      {
        ...this.state,
        scaling: true,
        startTransform: { ...frame }, // make a copy, not a ref.
        dragDetail: {
          scaleFactor,
          startPoint,
          oppositePoint,
        },
      },

      // callback
      () => {
        window.addEventListener('mousemove', this.handleScaleUpdate);
        window.addEventListener('mouseup', this.handleScaleEnd);
        this.props.onFrameUpdateStart(frame, TRANFORM_TOOL_ACTION.SCALE);
      }
    );
  };

  handleScaleUpdate = ({ clientX, clientY }) => {
    const { frame } = this.props;
    const startFrame: Frame = this.state.startTransform;
    const { startPoint, scaleFactor, oppositePoint } = this.state.dragDetail;

    // to get correct new width and height for a rotated object,
    // we need to make some matrix calculation and get back with an angle of zero
    let zeroRotationPoint = rotatePointAround(
      clientX,
      clientY,
      oppositePoint.x,
      oppositePoint.y,
      -startFrame.rotation
    );

    /// /////////////////////////////////////////////////////////////
    // Keep ratio?
    /// /////////////////////////////////////////////////////////////
    const keepRatio = frame.overlayer || frame.clipart;
    if (keepRatio) {
      let ratioW = (zeroRotationPoint.x - oppositePoint.x) / startFrame.width;
      let ratioH = (zeroRotationPoint.y - oppositePoint.y) / startFrame.height;
      // keep biggest ratio as reference
      const useRatio =
        Math.abs(ratioW) > Math.abs(ratioH)
          ? Math.abs(ratioW)
          : Math.abs(ratioH);
      // update ratios by keeping negative vs positive
      ratioW = (ratioW / Math.abs(ratioW)) * useRatio;
      ratioH = (ratioH / Math.abs(ratioH)) * useRatio;
      // update zero point position
      zeroRotationPoint = {
        x: oppositePoint.x + ratioW * startFrame.width,
        y: oppositePoint.y + ratioH * startFrame.height,
      };
      // rotate back to update clientx and clienty for later positioning
      const newRotatedPoint = rotatePointAround(
        zeroRotationPoint.x,
        zeroRotationPoint.y,
        oppositePoint.x,
        oppositePoint.y,
        startFrame.rotation
      );
      clientX = newRotatedPoint.x;
      clientY = newRotatedPoint.y;
    }
    /// /////////////////////////////////////////////////////////////

    // calculate new width
    const newWidth =
      Math.abs(oppositePoint.x - zeroRotationPoint.x) / scaleFactor;
    const newHeight =
      Math.abs(oppositePoint.y - zeroRotationPoint.y) / scaleFactor;

    // now find the correct pos x and y
    const centerPoint = MidPointsBetweenPoints(
      oppositePoint.x,
      oppositePoint.y,
      startPoint.x,
      startPoint.y
    );
    const newMidPoint = MidPointsBetweenPoints(
      oppositePoint.x,
      oppositePoint.y,
      clientX,
      clientY
    );
    const xDiff = centerPoint.x - newMidPoint.x;
    const yDiff = centerPoint.y - newMidPoint.y;

    const newX = startFrame.x - xDiff / scaleFactor;
    const newY = startFrame.y - yDiff / scaleFactor;

    // added to have a nice smooth system for scaling
    if (frame.photo) {
      // ---- USING SCALING ----
      // EDIT results are not really great.. better to use a crop system also than a scale system..
      const scaleX = newWidth / startFrame.width;
      const scaleY = newHeight / startFrame.height;
      let newZoomValue = startFrame.zoom * (scaleX > scaleY ? scaleX : scaleY);

      // security, to avoid issue with the slider (that has a 2 max limit for zoom)
      if (newZoomValue > 2) {
        newZoomValue = 2;
      }
      const pic: Photo = this.props.photosByID[frame.photo];
      frame.zoom = newZoomValue;

      // try to keep crop?
      // const diffX = (pic.width*startFrame.zoom-startFrame.width);
      // const diffY = pic.height*newZoomValue - pic.height*startFrame.zoom;
      // frame.cLeft = startFrame.cLeft - diffX/2;
      // frame.cTop = startFrame.cTop - diffY/2;

      // we need to keep center point!
      const startCenterX =
        (startFrame.cLeft + startFrame.width / 2) / startFrame.zoom;
      const startCenterY =
        (startFrame.cTop + startFrame.height / 2) / startFrame.zoom;
      frame.cLeft = startCenterX * newZoomValue - newWidth / 2;
      frame.cTop = startCenterY * newZoomValue - newHeight / 2;

      // ---- using simple crops ----
      // // always put back the start frame options, the system will correct it when using "updateFrame"
      // frame.zoom = startFrame.zoom;
      // frame.cLeft = startFrame.cLeft;
      // frame.cTop = startFrame.cTop;
    }

    // direct modify frame
    frame.width = newWidth;
    frame.height = newHeight;
    frame.x = newX;
    frame.y = newY;

    // use the "true" value to force limit check of the "injectIntoFrame"
    this.props.onFrameUpdate(frame, true);
  };

  handleScaleEnd = ({ clientX, clientY }) => {
    window.removeEventListener('mousemove', this.handleScaleUpdate);
    window.removeEventListener('mouseup', this.handleScaleEnd);

    this.setState(
      {
        scaling: false,
      },

      () => {
        // console.log("end:" + JSON.stringify(this.state));
        this.props.onFrameUpdateCompleted(this.props.frame);
      }
    );
  };

  /**
   * handle drag and drop events on this
   */
  handleDragOver(event) {
    event.preventDefault(); // prevent default to allow drop
  }

  handleItemDrop(dropEvent) {
    this.props.onItemDropped(dropEvent);
  }

  // --------------------- HANDLE CROP ------------------------

  handleCropStart = (dragPoint, e) => {
    console.log(`HandleCropStart( ${dragPoint} )`);

    const { frame } = this.props;
    const { clientX, clientY } = e;
    // security
    this.checkEndCurrentAction({ clientX, clientY });

    // get client corner points and scale factor!
    const startPointRect = e.currentTarget.getBoundingClientRect();
    const startPoint = {
      x: startPointRect.left + startPointRect.width / 2,
      y: startPointRect.top + startPointRect.height / 2,
    };
    const oppositeRef =
      dragPoint === DRAG_POINT.TOP
        ? this.cornerRefs[DRAG_POINT.BOTTOM]
        : dragPoint === DRAG_POINT.LEFT
        ? this.cornerRefs[DRAG_POINT.RIGHT]
        : dragPoint === DRAG_POINT.BOTTOM
        ? this.cornerRefs[DRAG_POINT.TOP]
        : this.cornerRefs[DRAG_POINT.LEFT];

    // this.setState({transformOrigin:"0% 50%"});

    const oppositePointRect = oppositeRef.current.getBoundingClientRect();
    const oppositePoint = {
      x: oppositePointRect.left + oppositePointRect.width / 2,
      y: oppositePointRect.top + oppositePointRect.height / 2,
    };

    // get the scale factor between client and frame
    const distanceTool = DistanceBetweenPoints(
      startPoint.x,
      startPoint.y,
      oppositePoint.x,
      oppositePoint.y
    );
    // let distanceFrame = Math.hypot( frame.width, frame.height );
    const distanceFrame =
      dragPoint === DRAG_POINT.LEFT || dragPoint === DRAG_POINT.RIGHT
        ? frame.width
        : frame.height;
    const scaleFactor = distanceTool / distanceFrame;

    // e.preventDefault();
    this.setState(
      {
        ...this.state,
        cropping: true,
        startTransform: { ...frame }, // make a copy, not a ref.
        dragDetail: {
          dragPoint,
          scaleFactor,
          startPoint,
          oppositePoint,
        },
      },
      // callback
      () => {
        window.addEventListener('mousemove', this.handleCropUpdate);
        window.addEventListener('mouseup', this.handleCropEnd);
        this.props.onFrameUpdateStart(frame, TRANFORM_TOOL_ACTION.CROP);
      }
    );
  };

  handleCropUpdate = ({ clientX, clientY }) => {
    const { frame } = this.props;
    const startFrame: Frame = this.state.startTransform;
    const { startPoint, scaleFactor, oppositePoint, dragPoint } =
      this.state.dragDetail;

    // to get correct new width and height for a rotated object,
    // we need to make some matrix calculation and get back with an angle of zero
    // let zeroRotOppositePoint = rotatePointAround(clientX, clientY,oppositePoint.x, oppositePoint.y, -startFrame.rotation);
    const zeroRotClientPoint = rotatePointAround(
      clientX,
      clientY,
      oppositePoint.x,
      oppositePoint.y,
      -startFrame.rotation
    );

    const cropWidth =
      dragPoint === DRAG_POINT.LEFT || dragPoint === DRAG_POINT.RIGHT;
    const newWidth = cropWidth
      ? Math.abs(oppositePoint.x - zeroRotClientPoint.x)
      : startFrame.width * scaleFactor;
    const newHeight = cropWidth
      ? startFrame.height * scaleFactor
      : Math.abs(oppositePoint.y - zeroRotClientPoint.y);
    // console.log(`width:${newWidth} and height:${newHeight}`);

    const oldWidth = frame.width;
    const oldHeight = frame.height;
    frame.width = newWidth / scaleFactor;
    frame.height = newHeight / scaleFactor;

    // check max width / height
    if (frame.photo) {
      const photo = this.props.photosByID[frame.photo];

      // check max size (as we don't want to zoom anymore on the image using the crop system)
      const maxWidth = photo.width * frame.zoom;
      const maxHeight = photo.height * frame.zoom;
      if (frame.width > maxWidth) frame.width = maxWidth;
      if (frame.height > maxHeight) frame.height = maxHeight;

      // check crop values
      const maxLeft = photo.width * frame.zoom - frame.width;
      const maxTop = photo.height * frame.zoom - frame.height;
      const diffX = frame.width - startFrame.width;
      const diffY = frame.height - startFrame.height;

      if (dragPoint === DRAG_POINT.LEFT) {
        frame.cLeft = startFrame.cLeft - diffX;
      }
      if (frame.cLeft > maxLeft) frame.cLeft = maxLeft;
      if (frame.cLeft < 0) frame.cLeft = 0;

      if (dragPoint === DRAG_POINT.TOP) {
        frame.cTop = startFrame.cTop - diffY;
      }
      if (frame.cTop > maxTop) frame.cTop = maxTop;
      if (frame.cTop < 0) frame.cTop = 0;
    }

    const projectedClientPoint = cropWidth
      ? rotatePointAround(
          oppositePoint.x +
            (dragPoint === DRAG_POINT.LEFT ? -newWidth : newWidth),
          oppositePoint.y,
          oppositePoint.x,
          oppositePoint.y,
          startFrame.rotation
        )
      : rotatePointAround(
          oppositePoint.x,
          oppositePoint.y +
            (dragPoint === DRAG_POINT.TOP ? -newHeight : newHeight),
          oppositePoint.x,
          oppositePoint.y,
          startFrame.rotation
        );

    // now find the correct pos x and y
    const centerPoint = MidPointsBetweenPoints(
      oppositePoint.x,
      oppositePoint.y,
      startPoint.x,
      startPoint.y
    );
    const newMidPoint = MidPointsBetweenPoints(
      oppositePoint.x,
      oppositePoint.y,
      projectedClientPoint.x,
      projectedClientPoint.y
    );
    // let newMidPoint = MidPointsBetweenPoints( oppositePoint.x, oppositePoint.y, clientX, clientY);
    const xDiff = centerPoint.x - newMidPoint.x;
    const yDiff = centerPoint.y - newMidPoint.y;

    const newX = startFrame.x - xDiff / scaleFactor;
    const newY = startFrame.y - yDiff / scaleFactor;

    frame.x = newX;
    frame.y = newY;
    this.props.onFrameUpdate(frame, false);
  };

  handleCropEnd = ({ clientX, clientY }) => {
    window.removeEventListener('mousemove', this.handleCropUpdate);
    window.removeEventListener('mouseup', this.handleCropEnd);
    this.setState({ cropping: false }, () => {
      // console.log("end:" + JSON.stringify(this.state));
      this.props.onFrameUpdateCompleted(this.props.frame);
    });
  };

  checkEndCurrentAction(e) {
    const { moving, rotating, scaling, cropping, insideMoving } = this.state;
    if (moving) {
      console.warn("Starting new action but 'move' action still pending");
      this.handleMoveEnd(e);
    }
    if (rotating) {
      console.warn("Starting new action but 'rotate' action still pending");
      this.handleRotateEnd(e);
    }
    if (scaling) {
      console.warn("Starting new action but 'scale' action still pending");
      this.handleScaleEnd(e);
    }
    if (cropping) {
      console.warn("Starting new action but 'crop' action still pending");
      this.handleCropEnd(e);
    }
    if (insideMoving) {
      console.warn(
        "Starting new action but 'inside move' action still pending"
      );
      this.handleInsideMoveEnd(e);
    }
  }

  /** **********************************
    // Inside Move
    ************************************ */

  handleInsideMoveStart = ({ clientX, clientY }) => {
    console.log(`handkeInsideMoveStart:${clientX}`);

    // security
    this.checkEndCurrentAction({ clientX, clientY });

    const { frame } = this.props;

    // [TODO:] little hack for now as when swithhing from photo to background..
    if (!frame.photo) return;

    // e.preventDefault();
    this.setState(
      {
        ...this.state,
        insideMoving: true,
        startTransform: { ...frame }, // make a copy of frame
        dragDetail: {
          startX: clientX,
          startY: clientY,
        },
      },

      // callback
      () => {
        // console.log("this.state: "+JSON.stringify(this.state));
        window.addEventListener('mousemove', this.handleInsideMoveUpdate);
        window.addEventListener('mouseup', this.handleInsideMoveEnd);
        this.props.onFrameUpdateStart(frame, TRANFORM_TOOL_ACTION.INSIDE_MOVE);
      }
    );
  };

  handleInsideMoveUpdate = ({ clientX, clientY }) => {
    const t: Frame = this.state.startTransform;

    // calculate position
    const diffX =
      (clientX - this.state.dragDetail.startX) / this.props.editionScale;
    const diffY =
      (clientY - this.state.dragDetail.startY) / this.props.editionScale;

    // snap x to origin points
    // newX = SnapNumberTo(newX, t.x, 5);
    // newY = SnapNumberTo(newY, t.y, 5);

    // we can directly modify the frame from props
    const { frame } = this.props;
    frame.cLeft = t.cLeft - diffX;
    frame.cTop = t.cTop - diffY;

    // verify ctop & cleft
    // TODO: put this verification in a helper! and check everywhere
    if (frame.cLeft < 0) frame.cLeft = 0;
    if (frame.cTop < 0) frame.cTop = 0;
    const photo: Photo = this.props.photosByID[frame.photo];
    if (photo) {
      const maxLeft = photo.width * frame.zoom - frame.width;
      const maxTop = photo.height * frame.zoom - frame.height;
      if (frame.cLeft > maxLeft) frame.cLeft = maxLeft;
      if (frame.cTop > maxTop) frame.cTop = maxTop;
    } else {
      // console.warn("No more photo here...");
    }

    this.props.onFrameUpdate(frame);
  };

  handleInsideMoveEnd = ({ clientX, clientY }) => {
    window.removeEventListener('mousemove', this.handleInsideMoveUpdate);
    window.removeEventListener('mouseup', this.handleInsideMoveEnd);

    this.setState(
      {
        insideMoving: false,
      },

      () => {
        // console.log("end:" + JSON.stringify(this.state));
        // TODO: reset this
        this.props.onFrameUpdateCompleted(this.props.frame);
      }
    );
  };

  /// ///////////////////////////////////////////////////
  // Render
  /// ///////////////////////////////////////////////////

  render() {
    const { frame } = this.props;
    const isEditing: boolean = this.props.editedPage;

    return (
      // To be sure to not have to have to transform all element, we use a parent that simulate exactly the page placement
      <div
        key="pageTransformEmulated"
        style={{
          left: this.props.pagePosX,
          top: this.props.pagePosY,
          width: '100%',
          height: '100%',
          // overflow:"hidden",
          transformOrigin: '0% 0%',
          transform: `scale(${this.props.editionScale})`,
          // backgroundColor:"#ffff0044",
          position: 'absolute',
          pointerEvents: 'none',
        }}
      >
        <div // xmlns="http://www.w3.org/1999/xhtml" // to use as foreign object
          className="selection-box"
          key={this.props.keyRef}
          // onMouseDown={(e)=>{alert("onMouseDown!")}}
          // width={transform.width} height={transform.height}
          onDragOver={(e) => {
            this.handleDragOver(e);
          }}
          onDrop={(e) => {
            this.handleItemDrop(e);
          }}
          onDoubleClick={(e) => {
            this.props.onDoubleClick(this.props.frame);
          }}
          ref={this.transformRef}
          style={{
            width: `${frame.width}px`,
            height: `${frame.height}px`,
            // zIndex:1,
            display: 'inline-block',
            left: this.calculatePosX(),
            top: this.calculatePosY(),

            transformOrigin: this.state.transformOrigin,
            transform: `translateX( ${-frame.width / 2}px) translateY( ${
              -frame.height / 2
            }px) rotate(${frame.rotation}deg)`,
            // transform={"translate(" + t.x + "," + t.y + ") rotate("+ t.angle +"," + t.width/2 +"," + t.height/2 +")"}
            // transform:`translate( ${this.calculatePosX()} , ${this.calculatePosY()} ) rotate(${frame.rotation})`
          }}
        >
          {
            /* // --------------------- frame size detail ------------------------  */
            frameCanMove(frame) && !this.state.moving && (
              <div>
                <div className="selection-box-size-width">
                  <span
                    className="label"
                    style={{ fontSize: 10 / this.props.editionScale }}
                  >
                    {` ${pixelToCm(frame.width).toFixed(1)} cm `}
                  </span>
                </div>
                <div className="selection-box-size-height">
                  <span
                    className="label"
                    style={{ fontSize: 10 / this.props.editionScale }}
                  >
                    {` ${pixelToCm(frame.height).toFixed(1)} cm `}
                  </span>
                </div>
              </div>
            )
          }

          <div className="selection-box--border">
            {/* { // --------------------- MOVE ------------------------
                        (frameCanMove(frame) && !this.state.moving) && // TODO: this must be a check in frameHelper

                        <div className="selection-box--move-hitzone"
                            onMouseDown={ (e)=>{this.handleMoveStart(e); }}
                            //draggable
                            //onDragStart={ (e)=>{this.onMoveDragStart(e); }}
                            //onDrag={ (e)=>{this.onMoveDragUpdate(e); }}
                            //onDragEnd={ (e)=>{this.onMoveDragEnd(e); }}
                            style={{
                            backgroundColor:"blue",
                            opacity:((this.state.rotating || this.state.moving)?0.5:0.1)
                            }}
                            />
                    } */}

            {
              // --------------------- SCALING ------------------------
              frameCanScale(frame) && !isEditing && (
                <div>
                  <div
                    role="button"
                    tabIndex="0"
                    aria-label="cornerDrag"
                    className="resize-hitzone resize-hitzone--topleft"
                    ref={this.cornerRefs[DRAG_POINT.TOP_LEFT]}
                    onMouseDown={(e) => {
                      this.handleScaleStart(DRAG_POINT.TOP_LEFT, e);
                    }}
                  />
                  <div
                    role="button"
                    tabIndex="0"
                    aria-label="cornerDrag"
                    className="resize-hitzone resize-hitzone--topright"
                    ref={this.cornerRefs[DRAG_POINT.TOP_RIGHT]}
                    onMouseDown={(e) => {
                      this.handleScaleStart(DRAG_POINT.TOP_RIGHT, e);
                    }}
                  />
                  <div
                    role="button"
                    tabIndex="0"
                    aria-label="cornerDrag"
                    className="resize-hitzone resize-hitzone--bottomleft"
                    ref={this.cornerRefs[DRAG_POINT.BOTTOM_LEFT]}
                    onMouseDown={(e) => {
                      this.handleScaleStart(DRAG_POINT.BOTTOM_LEFT, e);
                    }}
                  />
                  <div
                    role="button"
                    tabIndex="0"
                    aria-label="cornerDrag"
                    className="resize-hitzone resize-hitzone--bottomright"
                    ref={this.cornerRefs[DRAG_POINT.BOTTOM_RIGHT]}
                    onMouseDown={(e) => {
                      this.handleScaleStart(DRAG_POINT.BOTTOM_RIGHT, e);
                    }}
                  />
                </div>
              )
            }

            {
              // --------------------- CROP ------------------------
              (frameCanCrop(frame) || frameCanInsideMove(frame)) && (
                <div>
                  {frameCanCrop(frame) && !this.props.editedPage && (
                    <div>
                      <div
                        role="button"
                        tabIndex="0"
                        aria-label="cornerDrag"
                        className="crop-hitzone crop-hitzone--top"
                        ref={this.cornerRefs[DRAG_POINT.TOP]}
                        onMouseDown={(e) => {
                          this.handleCropStart(DRAG_POINT.TOP, e);
                        }}
                      />
                      <div
                        role="button"
                        tabIndex="0"
                        aria-label="cornerDrag"
                        className="crop-hitzone crop-hitzone--right"
                        ref={this.cornerRefs[DRAG_POINT.RIGHT]}
                        onMouseDown={(e) => {
                          this.handleCropStart(DRAG_POINT.RIGHT, e);
                        }}
                      />
                      <div
                        role="button"
                        tabIndex="0"
                        aria-label="cornerDrag"
                        className="crop-hitzone crop-hitzone--bottom"
                        ref={this.cornerRefs[DRAG_POINT.BOTTOM]}
                        onMouseDown={(e) => {
                          this.handleCropStart(DRAG_POINT.BOTTOM, e);
                        }}
                      />
                      <div
                        role="button"
                        tabIndex="0"
                        aria-label="cornerDrag"
                        className="crop-hitzone crop-hitzone--left"
                        ref={this.cornerRefs[DRAG_POINT.LEFT]}
                        onMouseDown={(e) => {
                          this.handleCropStart(DRAG_POINT.LEFT, e);
                        }}
                      />
                    </div>
                  )}
                  {frameCanInsideMove(frame) && !this.props.editedPage && (
                    <div
                      role="button"
                      tabIndex="0"
                      aria-label="insidemove"
                      className="insideMove unselectable"
                      onMouseDown={(e) => {
                        this.handleInsideMoveStart(e);
                      }}
                    >
                      <ImgIcon icon={EmbedIcons.GrabIcon} className="icon" />
                    </div>
                  )}
                </div>
              )
            }

            {
              // --------------------- ROTATE ------------------------
              frameCanRotate(frame) && (
                <div
                  role="button"
                  tabIndex="0"
                  aria-label="rotate"
                  className="selection-box--rotate-icon"
                  onMouseDown={(e) => {
                    this.handleRotateStart(e);
                  }}
                >
                  <RedoOutlined className="icon" />

                  {/*
                                // TODO: REMEMBER THIS :: when using a button from antd inside a SVG, it leads to weird async position behaviors...
                                <Button type="primary" shape="circle" icon="reload" size="large" />
                                <Icon type="reload" spin theme="filled" style={{ fontSize: '50px', color: '#ffffff' }}  />
                            */}
                </div>
              )
            }

            {
              // ---- ROTATING DEBUG ----
              DebugFlags.USE_TRANSFORM_TOOL_DEBUG && this.state.rotating && (
                <div
                  className="rotation debug"
                  style={{
                    width: 10,
                    height: 10,
                    position: 'absolute',
                    left: `${frame.width / 2 - 5}px`,
                    top: `${frame.height / 2 - 5}px`,
                    backgroundColor: '#fff000',
                  }}
                />
              )
            }

            {
              // ---- SCALING DEBUG ----
              DebugFlags.USE_TRANSFORM_TOOL_DEBUG && this.state.cropping && (
                <div
                  className="scaling debug"
                  style={{
                    width: 10,
                    height: 10,
                    position: 'absolute',
                    left: `${Math.abs(
                      this.state.dragDetail.oppositePoint.x -
                        this.state.dragDetail.startPoint.x
                    )}px`,
                    top: `${Math.abs(
                      this.state.dragDetail.oppositePoint.y -
                        this.state.dragDetail.startPoint.y
                    )}px`,
                    backgroundColor: '#fff000',
                  }}
                />
              )
            }

            {/* {   // --------------------- Frame text Edition ------------------------
                        (frame.type === FRAME_TYPE.TEXT) &&

                            <div style={{ width:"100%", height:"100%", position:"absolute", top:"0px"}}>
                                <Input ref={ this.textEditRef } style={{ width:"100%", height:"100%"}} placeholder="edit text" />
                            </div>

                    } */}
          </div>
        </div>
      </div>
    );
  }
}

// prop types
// TransformTool.propTypes = {
//     frame: PropTypes.object, // current frame to focus on
//     editionScale: PropTypes.number, // the scale of the page area for now!
//     pagePosX: PropTypes.number,
//     pagePosY: PropTypes.number,
//     onFrameUpdateStart: PropTypes.func, // function to call when frame edition is started
//     onFrameUpdate: PropTypes.func, // function to call when frame is being updated
//     onFrameUpdateCompleted: PropTypes.func, // function to call when frame update has been completed (mouse up generally)
//     onPhotoDropped: PropTypes.func, // function to call when a photo has been dropped on this transform tool
//     photosByID: PropTypes.object,
// };

export default TransformTool;
