import React from 'react';
import { connect } from 'react-redux';

import { Tabs, Button } from 'antd';
import { FaChevronCircleLeft } from 'react-icons/fa';
import {
  BorderOutlined,
  HeartOutlined,
  LayoutOutlined,
  PictureOutlined,
  PlusSquareOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { PhotosArea } from './leftArea/PhotosArea';
import { AlbumProjectArea } from './leftArea/projectArea/AlbumProjectArea';
import { CalendarProjectArea } from './leftArea/projectArea/CalendarProjectArea';
import { CardProjectArea } from './leftArea/projectArea/CardProjectArea';

import { LayoutsArea } from './leftArea/LayoutsArea';
import { BackgroundsArea } from './leftArea/BackgroundsArea';
import { ClipartsArea } from './leftArea/ClipartsArea';
import { OverlayersArea } from './leftArea/OverlayersArea';
import { UIActions } from '../../feature/ui/ui';
import { GetText } from '../../data/LanguageHelper';
import { CanvasProjectArea } from './leftArea/projectArea/CanvasProjectArea';
import { DebugFlags } from '../../debug/DebugFlags';
import {
  IsAlbumEditor,
  IsCalendarEditor,
  IsCanvasEditor,
  IsCardEditor,
} from '../../data/config';
import { TABS_ENUM } from './leftArea/TABS_ENUM';

const TabItemComp = ({
  icon,
  text,
}: {
  icon: React.ReactNode,
  text: string,
}) => (
  <div className="tabItem">
    <div className="icon">{icon}</div>
    <div className="text">{text}</div>
  </div>
);

class LeftArea extends React.Component {
  constructor(props) {
    super(props);
    if (!props.selectedTab) {
      this.handleChangeTab('photos');
    }
  }

  handleChangeTab(selectedTab) {
    this.props.dispatch(UIActions.changeMainTab(selectedTab, true));
  }

  /// ///////////////////////////////////////////////////
  // Render
  /// ///////////////////////////////////////////////////
  render() {
    const { leftAreaState, edition, selectedTab } = this.props;
    const { project } = edition;
    const { TabPane } = Tabs;

    const toggleIconStyle = {
      position: 'absolute',
      right: 5,
      top: 5,
    };

    // test declare component here...
    const CloseIconComp = (props) => (
      <Button
        onClick={() => {
          this.handleTabCloseClick();
        }}
        style={toggleIconStyle}
      >
        <FaChevronCircleLeft />
      </Button>
    );

    return (
      <div className="leftArea" id="leftArea">
        <Tabs
          tabBarGutter={1}
          activeKey={selectedTab}
          tabPosition="left"
          onChange={(tab) => this.handleChangeTab(tab)}
          items={[
            {
              key: TABS_ENUM.PROJECT,
              label: (
                <TabItemComp
                  icon={<SettingOutlined />}
                  text={GetText('lefttab.project')}
                />
              ),
              disabled: !project,
              children: (
                <>
                  {IsAlbumEditor() && <AlbumProjectArea />}
                  {IsCalendarEditor() && <CalendarProjectArea />}
                  {IsCardEditor() && <CardProjectArea />}
                  {IsCanvasEditor() && <CanvasProjectArea />}
                </>
              ),
            },
            {
              key: TABS_ENUM.PHOTO,
              disabled: !project,
              label: (
                <TabItemComp
                  icon={<PictureOutlined />}
                  text={GetText('lefttab.photos')}
                />
              ),
              children: <PhotosArea />,
            },
            {
              key: TABS_ENUM.LAYOUT,
              disabled: !project,
              label: (
                <TabItemComp
                  icon={<LayoutOutlined />}
                  text={GetText('lefttab.layouts')}
                />
              ),
              children: <LayoutsArea />,
            },
            {
              key: TABS_ENUM.BACKGROUND,
              disabled: !project,
              label: (
                <TabItemComp
                  icon={<BorderOutlined />}
                  text={GetText('lefttab.backgrounds')}
                />
              ),
              children: <BackgroundsArea />,
            },
            {
              key: TABS_ENUM.CLIPART,
              disabled: !project,
              label: (
                <TabItemComp
                  icon={<HeartOutlined />}
                  text={GetText('lefttab.cliparts')}
                />
              ),
              children: <ClipartsArea />,
            },
            {
              key: TABS_ENUM.OVERLAYER,
              disabled: !project,
              label: (
                <TabItemComp
                  icon={<PlusSquareOutlined />}
                  text={GetText('lefttab.overlayers')}
                />
              ),
              children: <OverlayersArea />,
            },
          ]}
        />
      </div>
    );
  }
}

// --------------------- Redux ------------------------

// Redux map
function mapStateToProps(state) {
  const { authentication, edition, ui } = state;
  const { user } = authentication;
  const { selectedTab, tabsOpen } = ui;

  // we need user and projects
  return {
    user,
    edition,
    selectedTab,
    // tabIsOpen,
  };
}

// --------------------- export ------------------------

// Redux connect
const reduxConnected = connect(mapStateToProps)(LeftArea);
export { reduxConnected as LeftArea };
