import React from 'react';

import type {
  Frame,
  Photo,
  IPage,
  BackgroundItem,
  Clipart,
  OverlayerItem,
  ICalendarColorOptions,
} from '../../../types/types';
import { CreateTempPhoto } from '../../../feature/photoList/photoHelper';
import { FrameArea } from './FrameArea';
import { ClassicCoverArea } from './ClassicCoverArea';
import { CalendarHolesComp } from './CalendarHolesComp';
import { IsCalendar_WithSpiralHoles } from '../../../utils/ProductHelper';
import { GetText } from '../../../data/LanguageHelper';
import { mmToPoint } from '../../../utils/MeasureUtils';
import { GRID_MODE } from '../../../feature/ui/ui';
import { IsCanvasEditor } from '../../../data/config';

export interface PageAreaProps {
  pageIndex: number;
  page: IPage;
  selected: boolean;
  calendarColorOptions: ICalendarColorOptions;
  selectedFrameID: string;
  isPreviewMode: boolean;
  isEditing: boolean;
  photosByID: Record<Photo>;
  backgroundsByID: Record<BackgroundItem>;
  clipartsByID: Record<Clipart>;
  overlayersByID: Record<OverlayerItem>;
  editionScale: number;
  x: number;
  y: number;
  docID: string;
  gridMode: number;

  // callbacks
  onFrameMouseDown(pageIndex: number, frameIndex: number, e: Event): () => void;
  onFrameDoubleClick(frame: Frame): () => void;
  onFrameItemDrop(dropEvent, frame: Frame): () => void;
  onFramePhotoImport(photo: Photo, frame: Frame): () => void;
  onFrameTextLimitReached(
    frameID,
    { width: number, height: number }
  ): () => void;
}

class PageArea extends React.Component<PageAreaProps> {
  // --------------------- constructor ------------------------

  constructor(props) {
    super(props);
    this.state = {
      // currentPage : props.page,
      // photos : props.photos,
    };
  }

  // --------------------- react ------------------------

  // --------------------- methods ------------------------

  /**
   * Handle drag and drop
   */
  handleDropOnFrame(evt, frameIndex, frameObj) {
    this.props.onFrameItemDrop(evt, frameObj); //
  }

  handleDragOverFrame(evt, frameIndex) {
    evt.preventDefault(); // needed to allow the drop! see :https://stackoverflow.com/questions/50230048/react-ondrop-is-not-firing
    // evt.currentTarget.addClass('hover'); // not working
  }

  handleDragLeaveFrame(evt, frameIndex) {
    // evt.currentTarget.removeClass('hover'); // not working
  }

  dummyUploadRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  handleUploaderChange = (info, frame) => {
    const { status } = info.file;
    console.log('HandleDraggerChange');
    // message.info("File change: (" + info.file.name + "): " + status );

    // if status is done, start the import process.. (done as we don't have a real upload )
    // Warning, this works as we don't have the upload process here!
    if (info.file.status === 'done') {
      // let reader = new FileReader();
      // let f = files[fileIndex].originFileObj;
      const imageURL = window.URL.createObjectURL(info.file.originFileObj);
      const tempPhoto = CreateTempPhoto(info.file.originFileObj, imageURL);

      // this is handled in the editionArea
      // this.props.dispatch(photoListActions.importPhotos( tempPhoto, frame ));
      this.props.onFramePhotoImport(tempPhoto, frame);
    }
  };

  /// ///////////////////////////////////////////////////
  // Render
  /// ///////////////////////////////////////////////////
  render() {
    const {
      pageIndex,
      page,
      photosByID,
      backgroundsByID,
      clipartsByID,
      overlayersByID,
    } = this.props;
    const currentPage: IPage = page;
    const { canvasEdge } = this.props;
    // const pageX = this.props.x;
    // const pageY = this.props.y;
    // const pageWidth = canvasEdge? currentPage.width + canvasEdge*2 : currentPage.width;
    // const pageHeight = canvasEdge? currentPage.height + canvasEdge*2 : currentPage.height;

    // allow to see content outside the page (for canvas we need this to see the edges!)
    const showCanvasEdge: boolean =
      IsCanvasEditor() && (!this.props.isPreviewMode || canvasEdge.isKadapak);

    const gridMM =
      this.props.gridMode === GRID_MODE.BIG
        ? 10
        : this.props.gridMode === GRID_MODE.MEDIUM
        ? 5
        : 1;

    const gridSize = Math.round(mmToPoint(gridMM) * 100) / 100;
    const gridBigSize = gridSize * 10;

    const calendarColorOptions = currentPage.calendarColorOptions
      ? currentPage.calendarColorOptions
      : this.props.calendarColorOptions;

    return (
      <svg
        x={this.props.x}
        y={this.props.y}
        width={currentPage.width}
        height={currentPage.height}
        // className="pageArea"
        style={{
          overflow: showCanvasEdge ? 'visible' : 'hidden',
          clipPath: showCanvasEdge ? 'url(#clipPath)' : null,
        }}
      >
        {
          // --------------------- CANVAS Background ------------------------
          showCanvasEdge && (
            <g>
              <defs>
                <clipPath id="clipPath">
                  <rect
                    x={-canvasEdge.size}
                    y={-canvasEdge.size}
                    width={page.width + canvasEdge.size * 2}
                    height={page.height + canvasEdge.size * 2}
                  />
                </clipPath>
              </defs>
              <rect
                x={-canvasEdge.size}
                y={-canvasEdge.size}
                width={page.width + canvasEdge.size * 2}
                height={page.height + canvasEdge.size * 2}
                className="canvasEdgeBG"
              />
            </g>
          )
        }

        {
          /* // --------------------- CASE NORMAL PAGE ------------------------ */
          !currentPage.coverClassicOptions && photosByID && (
            <g>
              {/* // --------------------- background ------------------------ */}
              <rect
                id={this.props.id}
                x="0"
                y="0"
                width="100%"
                height="100%"
                className="pageArea"
              />

              {
                // --------------------- Display Frames ------------------------
                photosByID &&
                  currentPage.frames.map((frame: Frame, i) => (
                    <FrameArea
                      key={`${pageIndex}_${i}`}
                      frame={frame}
                      pageIndex={pageIndex}
                      docID={this.props.docID}
                      frameIndex={i}
                      editionScale={this.props.editionScale}
                      isSelected={this.props.selectedFrameID === frame.id}
                      isEditing={
                        this.props.selectedFrameID === frame.id &&
                        this.props.isEditing
                      }
                      isPreviewMode={this.props.isPreviewMode}
                      photosByID={photosByID}
                      backgroundsByID={backgroundsByID}
                      clipartsByID={clipartsByID}
                      overlayersByID={overlayersByID}
                      calendarColorOptions={calendarColorOptions}
                      onFrameMouseDown={(frameIndex, e) => {
                        this.props.onFrameMouseDown(
                          this.props.pageIndex,
                          frameIndex,
                          e
                        );
                      }}
                      onFrameDoubleClick={(frame) => {
                        this.props.onFrameDoubleClick(frame);
                      }}
                      onDragOverFrame={(e, i) => {
                        this.handleDragOverFrame(e, i);
                      }}
                      onDragLeaveFrame={(e, i) => {
                        this.handleDragLeaveFrame(e, i);
                      }}
                      onDropOnFrame={(e, i, frame) => {
                        this.handleDropOnFrame(e, i, frame);
                      }}
                      onDummyUploadRequest={this.dummyUploadRequest}
                      onUploaderChange={(info, frame) => {
                        this.handleUploaderChange(info, frame);
                      }}
                      onFrameTextLimitReached={(limit) =>
                        this.props.onFrameTextLimitReached(frame.id, limit)
                      }
                    />
                  ))
              }
            </g>
          )
        }

        {
          // --------------------- Selection stroke ------------------------
          this.props.selected &&
            !this.props.isPreviewMode &&
            !currentPage.coverClassicOptions && (
              <rect
                x="0"
                y="0"
                width="100%"
                height="100%"
                className="pageSelected"
              />
            )
        }

        {
          // --------------------- CANVAS EDGE ------------------------
          showCanvasEdge && (
            <g>
              {/* // EDGE */}
              <polyline
                className="canvasEdgeFront unselectable unclickable"
                points={`${canvasEdge.left} ${canvasEdge.top}, 
                                ${canvasEdge.right} ${canvasEdge.top}, 
                                ${canvasEdge.right} ${canvasEdge.bottom}, 
                                ${canvasEdge.left} ${canvasEdge.bottom}, 
                                ${canvasEdge.left} ${canvasEdge.top}`}
                style={canvasEdge.style}
              />

              {
                /* // Ege lines */
                !this.props.isPreviewMode && (
                  <g>
                    <line
                      x1={-canvasEdge.size}
                      y1={0}
                      x2={page.width + canvasEdge.size}
                      y2={0}
                      className="edgeLine"
                    />
                    <line
                      x1={-canvasEdge.size}
                      y1={page.height}
                      x2={page.width + canvasEdge.size}
                      y2={page.height}
                      className="edgeLine"
                    />
                    <line
                      x1={0}
                      y1={-canvasEdge.size}
                      x2={0}
                      y2={page.height + canvasEdge.size}
                      className="edgeLine"
                    />
                    <line
                      x1={page.width}
                      y1={-canvasEdge.size}
                      x2={page.width}
                      y2={page.height + canvasEdge.size}
                      className="edgeLine"
                    />
                  </g>
                )
              }

              {
                /* Edge texts */
                !this.props.isPreviewMode && (
                  <g>
                    <text
                      x={page.width / 2}
                      y={-canvasEdge.size * 0.5}
                      style={{
                        fontSize: canvasEdge.size * 0.5,
                        textAnchor: 'middle',
                        dominantBaseline: 'middle',
                        fill: '#FFFFFF',
                        fillOpacity: 0.5,
                      }}
                    >
                      {GetText('canvas.edge')}
                    </text>
                    <text
                      x={page.width / 2}
                      y={page.height + canvasEdge.size * 0.5}
                      className="canvasEdgeText unselectable unclickable"
                      style={{
                        fontSize: canvasEdge.size * 0.5,
                      }}
                    >
                      {GetText('canvas.edge')}
                    </text>
                  </g>
                )
              }
            </g>
          )
        }

        {
          // --------------------- CASE cover classic ------------------------
          currentPage.coverClassicOptions && (
            <ClassicCoverArea
              key="0"
              editionScale={this.props.editionScale}
              isPreviewMode={this.props.isPreviewMode}
              page={currentPage}
              options={currentPage.coverClassicOptions}
              dispatch={this.props.dispatch}
            />
          )
        }

        {
          // --------------------- CALENDAR HOLES ------------------------
          IsCalendar_WithSpiralHoles(this.props.docID) && (
            <CalendarHolesComp docID={this.props.docID} page={currentPage} />
          )
        }

        {
          // --------------------- GRID ------------------------
          !currentPage.coverClassicOptions &&
            this.props.gridMode !== GRID_MODE.NONE && (
              <svg
                width="100%"
                height="100%"
                className="unselectable unclickable"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <pattern
                    id="smallGrid"
                    width={gridSize}
                    height={gridSize}
                    patternUnits="userSpaceOnUse"
                  >
                    <path
                      d={`M ${gridSize} 0 L 0 0 0 ${gridSize}`}
                      fill="none"
                      stroke="gray"
                      strokeWidth="0.5"
                    />
                  </pattern>
                  <pattern
                    id="grid"
                    width={gridBigSize}
                    height={gridBigSize}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      width={gridBigSize}
                      height={gridBigSize}
                      fill="url(#smallGrid)"
                    />
                    <path
                      d={`M ${gridBigSize} 0 L 0 0 0 ${gridBigSize}`}
                      fill="none"
                      stroke="gray"
                      strokeWidth="1"
                    />
                  </pattern>
                </defs>

                <rect width="100%" height="100%" fill="url(#grid)" />
              </svg>
            )
        }
      </svg>
    );
  }
}

// --------------------- export ------------------------

export { PageArea };
