import { Slider, Layout, Divider, Button, Checkbox, Modal, Input } from 'antd';

import React from 'react';
import { connect } from 'react-redux';
import domtoimage from 'dom-to-image';
import type { Frame, Photo, Options } from '../types/types';
import { Colors } from '../data/Colors';
import { GetText } from '../data/LanguageHelper';
import { downloadDataURL } from '../utils/HtmlUtils';
import {
  photoListActions,
  photoListSelector,
} from '../feature/photoList/photoList';
import { UIActions } from '../feature/ui/ui';
import { GetRotatedRectBounds } from '../utils/MathUtils';
import { IsLocalhost } from '../debug/DebugFlags';
import { Analytic } from '../App/Analytic';

const { Content, Sider } = Layout;

interface ImageEditorProps {
  photoID: string;
  frame: Frame;

  onClose: () => void;
}

class ImageEditor extends React.Component<ImageEditorProps> {
  // --------------------- Constructor ------------------------

  constructor(props) {
    super(props);
    this.state = {
      winSize: {
        width: window.innerWidth,
        height: window.innerHeight,
      },

      fullSizeLoaded: false,

      contrast: 100,
      brightness: 100,
      saturation: 100,
      sepia: 0,
      flipX: false,
      flipY: false,
      rotation: 0,
      hue: 0,
    };
    // this.popupRef = createRef();;
  }

  // --------------------- react ------------------------

  // componentDidUpdate(nextProps, nextState) {
  //     if (nextProps.pageList.length !==this.props.pageList.length ) {
  //       this.setState({
  //           numPages :this.props.pageList.length-1
  //       })
  //     }
  //   }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    const photo: Photo = this.props.photosByID[this.props.photoID];
    this.preloadFullSize(photo.full_url);
    Analytic.TrackPage('/imageEditor');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  // --------------------- Methods ------------------------

  handleResize = () => {
    this.setState({
      winSize: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    });
  };

  handleReset = (value) => {
    this.setState({
      contrast: 100,
      brightness: 100,
      saturation: 100,
      sepia: 0,
      flipX: false,
      flipY: false,
      rotation: 0,
      hue: 0,
    });
  };

  showRenameProjectPopup = (photo: Photo): Promise =>
    new Promise((resolve, reject) => {
      let newName = `${photo.name} (copy)`;
      // on change
      const handleChange = (e) => {
        newName = e.target.value;
      };

      // on confirm
      const handleConfirm = () => {
        if (!newName || newName === '') {
          // default copy
          newName = `${photo.name} (copy)`;
          // reopen popup as we don't allow an empty name!
          // this.showRenameProjectPopup(onSuccess, onCancel);
          // Modal.warn({title:GetText("newProject.popup.name.error")})
        }

        resolve(newName);
      };

      // create input field component
      const inputField = (
        <Input
          size="large"
          placeholder={GetText('newProject.popup.name.input')}
          // onChange={this.handleNameChange}
          defaultValue={newName}
          onChange={handleChange}
          autoFocus // not working
          onFocus={(e) => e.currentTarget.select()}
        />
      );

      // show modal
      Modal.confirm({
        width: 600,
        title: GetText('newProject.popup.name.title'),
        content: inputField,
        okText: GetText('common.continue'),
        cancelText: GetText('common.cancel'),
        onCancel: reject,
        onOk: handleConfirm,
      });
    });

  handleSaveImage = () => {
    const photo: Photo = this.props.photosByID[this.props.photoID];
    this.showRenameProjectPopup(photo).then((newPhotoName) => {
      const extension = '.jpeg';

      // be sure to add extension
      if (!newPhotoName.includes(extension)) newPhotoName += extension;

      this.props.dispatch(
        UIActions.UpdateMainLoading(true, GetText('loading.upload.import'))
      );

      const domElement = document.getElementById('imageWrapper');

      // scale back to have same final size!
      const scale = photo.width / domElement.clientWidth;

      const props: Options = {
        // width: domElement.clientWidth*scale,
        // height: domElement.clientHeight*scale,
        width: photo.width,
        height: photo.height,
        style: {
          transform: `scale(${scale})`,
          'transform-origin': 'top left',
        },
      };

      domtoimage
        .toJpeg(domElement, props)
        // return _domToPNG(domElement)
        .then((dataUrl) => {
          this.props.dispatch(
            photoListActions.uploadEditedPhoto(
              newPhotoName,
              dataUrl,
              this.props.frame,
              () => {
                this.props.dispatch(UIActions.UpdateMainLoading(false));
                this.props.onClose();
              }
            )
          );

          if (IsLocalhost) downloadDataURL(dataUrl, newPhotoName);
        });
    });
  };

  preloadFullSize = (fullSizeURL) => {
    const img = new Image();
    img.src = fullSizeURL;
    img.onload = () => {
      this.setState({ fullSizeLoaded: true });
    };
  };

  /// ///////////////////////////////////////////////////
  // Render
  /// ///////////////////////////////////////////////////
  render() {
    const photo: Photo = this.props.photosByID[this.props.photoID];
    const fullSizeURL = photo.full_url;
    const previewURL = photo.working_url;
    const { winSize } = this.state;
    let filterString = `contrast(${this.state.contrast}%)`;
    filterString += ` saturate(${this.state.saturation}%)`;
    filterString += ` brightness(${this.state.brightness}%)`;
    filterString += ` sepia(${this.state.sepia}%)`;
    filterString += ` hue-rotate(${this.state.hue * 3.6}deg)`;

    // box visible area
    const editionSize = {
      width: winSize.width - 500,
      height: winSize.height - 250,
    };

    const photoRatio = photo.width / photo.height;
    const editionRatio = editionSize.width / editionSize.height;
    const photoSize = {
      width:
        photoRatio > editionRatio
          ? editionSize.width
          : editionSize.height * photoRatio,
      height:
        photoRatio < editionRatio
          ? editionSize.height
          : editionSize.width / photoRatio,
    };

    // image transform
    let transformString = this.state.rotation
      ? `rotate(${this.state.rotation}deg)`
      : '';
    // let transformString = "";
    let scaleX = this.state.flipX ? -1 : 1;
    let scaleY = this.state.flipY ? -1 : 1;
    if (this.state.rotation) {
      const newBounds = GetRotatedRectBounds(
        0,
        0,
        photoSize.width,
        photoSize.height,
        this.state.rotation,
        true
      );

      // private function updateImageContainerZoom():void
      // {
      //   var newBounds : Rectangle = imageContainer.getBounds(imageContainer.parent);
      //   var newScaleX :number = newBounds.width / image.width / imageContainer.scaleX ;
      //   var newScaleY :number = newBounds.height / image.height / imageContainer.scaleY;
      //   imageContainer.scaleX = imageContainer.scaleY = (newScaleX > newScaleY)? newScaleX : newScaleY;
      // }

      // ALMOST WORKING
      const newScaleX: number = newBounds.width / photoSize.width;
      const newScaleY: number = newBounds.height / photoSize.height;
      const newScale = newScaleX > newScaleY ? newScaleX : newScaleY;
      scaleX *= newScale;
      scaleY *= newScale;
    }

    transformString += ` scale(${scaleX},${scaleY})`;

    return (
      <Modal
        open
        style={{
          top: '5%',
          display: 'flex',
          height: '95%',
          flexFlow: 'column',
        }}
        width="90%"
        cancelText={GetText('common.cancel')}
        okButtonProps={{
          loading: !this.state.fullSizeLoaded,
        }}
        okText={
          this.state.fullSizeLoaded
            ? GetText('topbar.project.saveAs.btn.label')
            : GetText('common.loading')
        }
        onOk={() => this.handleSaveImage()}
        onCancel={() => {
          this.props.onClose();
        }}
      >
        <Layout className="imageEditor" width="100%" height="100%">
          <Layout>
            {/** LEFT PART */}
            <Sider className="imageEditor_sider">
              <h3>{GetText('imageeditor.panel.title')}</h3>

              {/** CONTRAST */}
              <div className="imageEditor_filterBox">
                <span>{GetText('photomanager.panel.effect.contrast')}</span>
                <Slider
                  min={0}
                  max={200}
                  step={1}
                  value={this.state.contrast}
                  onChange={(value) => this.setState({ contrast: value })}
                />
              </div>

              {/** BRIGHTNESS */}
              <div className="imageEditor_filterBox">
                <span>{GetText('photomanager.panel.effect.brightness')}</span>
                <Slider
                  min={0}
                  max={200}
                  step={1}
                  value={this.state.brightness}
                  onChange={(value) => this.setState({ brightness: value })}
                />
              </div>

              {/** SATURATION */}
              <div className="imageEditor_filterBox">
                <span>{GetText('photomanager.panel.effect.saturation')}</span>
                <Slider
                  min={0}
                  max={200}
                  step={1}
                  value={this.state.saturation}
                  onChange={(value) => this.setState({ saturation: value })}
                />
              </div>

              {/** SEPIA */}
              <div className="imageEditor_filterBox">
                <span>{GetText('photomanager.panel.effect.sepia')}</span>
                <Slider
                  min={0}
                  max={100}
                  step={1}
                  value={this.state.sepia}
                  onChange={(value) => this.setState({ sepia: value })}
                />
              </div>

              {/** HUE */}
              <div className="imageEditor_filterBox">
                <span>{GetText('photomanager.panel.effect.hue')}</span>
                <Slider
                  min={0}
                  max={100}
                  step={1}
                  value={this.state.hue}
                  onChange={(value) => this.setState({ hue: value })}
                />
              </div>

              <Divider />

              <div>
                <Checkbox
                  checked={this.state.saturation === 0}
                  onChange={() => {
                    this.setState({
                      saturation: this.state.saturation !== 0 ? 0 : 100,
                    });
                  }}
                >
                  {GetText('photomanager.panel.effect.blackwhite')}
                </Checkbox>
              </div>

              <div>
                <Checkbox
                  checked={this.state.flipX}
                  onChange={() => {
                    this.setState({ flipX: !this.state.flipX });
                  }}
                >
                  {GetText('photomanager.panel.effect.mirror')}
                </Checkbox>
              </div>

              <div>
                <Checkbox
                  checked={this.state.flipY}
                  onChange={() => {
                    this.setState({ flipY: !this.state.flipY });
                  }}
                >
                  {GetText('photomanager.panel.effect.mirrorV')}
                </Checkbox>
              </div>

              <Divider />
              {/** ROTATION */}
              <div className="imageEditor_filterBox">
                <span>{GetText('photomanager.panel.effect.rotate')}</span>
                <Slider
                  min={-180}
                  max={180}
                  step={0.5}
                  value={this.state.rotation}
                  onChange={(value) => this.setState({ rotation: value })}
                />
              </div>

              <Divider />
              <Button type="dashed" onClick={() => this.handleReset()}>
                {GetText('photomanager.panel.btn.reset')}
              </Button>
            </Sider>

            {/** RIGHT PART */}
            {/* <span style={labelStyle}>{window.innerHeight}</span> */}
            <Content
              style={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
                background: Colors.GREY_DARKER,
                height: window.innerHeight - 200,
                padding: 20,
              }}
            >
              <div
                id="editionArea"
                style={{
                  overflow: 'hidden',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: editionSize.width,
                  height: editionSize.height,
                  // backgroundColor:"red",
                  backgroundColor: Colors.GREY_DARKER,
                }}
                // style="float:left;border:1px solid #000;overflow: auto;"
              >
                <div
                  id="imageWrapper"
                  style={{
                    width: photoSize.width,
                    height: photoSize.height,
                    overflow: 'hidden',
                    backgroundColor: 'black',
                  }}
                >
                  <img
                    style={{
                      height: '100%',
                      width: '100%',
                      // objectFit: "contain",
                      filter: filterString,
                      msTransform: transformString,
                      // /* IE 9 */
                      WebkitTransform: transformString,
                      // /* Chrome, Safari, Opera */
                      transform: transformString,
                    }}
                    src={this.state.fullSizeLoaded ? fullSizeURL : previewURL}
                  />
                </div>
              </div>
            </Content>
          </Layout>

          {/* <Footer>

              <Button onClick={()=>this.handleSaveImage()}>handleSaveImage</Button>
              </Footer> */}
        </Layout>
      </Modal>
    );
  }
}

// // --------------------- redux ------------------------

// Redux map
function mapStateToProps(state) {
  return {
    photosByID: photoListSelector.getAllBackendPhotosByID(state),
  };
}

// --------------------- export ------------------------

const reduxConnected = connect(mapStateToProps)(ImageEditor);
export { reduxConnected as ImageEditor };
