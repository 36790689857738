/* eslint-disable no-continue */
import { cloneDeep } from 'lodash';
import type { IPage, Frame } from '../../types/types';

import { GetUID } from '../../utils/UID';
import {
  FRAME_TYPE,
  CreateFrameBackground,
  InjectPhotoIntoFrame,
  CleanAndVerifyFrameText,
  IsFrameText,
  CreateFrame,
  ClearFrame,
} from '../edition/frameHelper';

import { CreateSpineNum, CreateSpine } from '../../utils/spineHelper';
import { UpgradeFrameBackground } from '../backgrounds/backgroundHelper';

import { GetDoc } from '../../utils/ProductHelper';
import { IsCover } from '../../utils/coverHelper';

import { CanvasHelper } from '../../utils/canvas/CanvasHelper';
import { DateUtils } from '../../utils/DateUtils';
import {
  IsCalendarEditor,
  IsCanvasEditor,
  PROJECT_CONST,
} from '../../data/config';
import { PROJECT_CLASS } from '../../data/Constants';
import { Project } from '../../data/Project';

export const LAYOUT_TYPE = {
  DEFAULT: 'default', // common type is a layout that can be used for common pages

  // ALBUM : "albums", // only used for albums // Note, this should not be used actually, it will always use "common"
  // ALBUM_COVER : "albums_cover",
  COVER: 'cover',

  CALENDAR: 'calendars', // those are calendar layouts( with calendar date actions )
  // CALENDAR_COVER : "calendars_cover",

  // CANVAS : "canvas",
  // CARDS : "cards",
  POSTCARD_BG: 'postcard_bg',
  PROMO_URL: 'promo_url',
};

export class Layout {
  constructor(id, width, height) {
    this.id = id;
    this.width = Number(width);
    this.height = Number(height);
    this.frames = [];
    this.isCustom = false;

    // optional
    this.type = null;
    this.numPhotos = 0;
  }
}

/**
 *  Retrieve correct layout type based on page and docID
 */

export function GetPageLayoutType(page: IPage, docID) {
  // ---- ALBUMS ----
  if (PROJECT_CONST.project_class === PROJECT_CLASS.ALBUM)
    return page.index === 0 ? LAYOUT_TYPE.COVER : LAYOUT_TYPE.DEFAULT;

  // ---- CALENDARS ----
  if (PROJECT_CONST.project_class === PROJECT_CLASS.CALENDAR) {
    const doc = GetDoc(docID);
    if (page.index === 0) return LAYOUT_TYPE.COVER;

    return page.isCalendar ? LAYOUT_TYPE.CALENDAR : LAYOUT_TYPE.DEFAULT;
  }

  return LAYOUT_TYPE.DEFAULT;
}

export function LayoutCanBeAppliedToPage(layout, page: IPage) {
  // retro compatibility before pages had layout type
  if (!page.layoutType) return true;

  // cover layout
  if (page.index === 0 && layout.type === LAYOUT_TYPE.COVER) return true;

  // same layout type
  if (page.layoutType === layout.type) return true;

  // PROMO URL
  if (
    !PROJECT_CONST.use_PROMO_URL_FRAMES &&
    page.layoutType === LAYOUT_TYPE.PROMO_URL
  )
    return true;

  return false;
}

/**
 *
 * @param {IPage} page : the page that needs to be updated
 * @param {Layout} newLayout : the new layout to apply to this page
 */
export function ApplyLayoutToPage(
  currentProject: Project,
  page: IPage,
  layoutObj,
  photosByID,
  backgroundsByID,
  backgroundBounds
) {
  // copy layout to modify original one!
  if (!LayoutCanBeAppliedToPage(layoutObj, page)) return;

  // let newLayout:Layout = {...layoutObj}; // THIS WAS NOT ENOUGH, subframes were copied by reference...
  const newLayout: Layout = cloneDeep(layoutObj);
  const { docID } = currentProject;
  const doc = GetDoc(docID);
  // let page = {...pageObj}

  // first lets keep all images of this page into an array
  const photosInPage = [];
  let spineInPage = null;
  let spineNumInPage = null;
  const textsInPage = [];
  page.frames.forEach((frame, i) => {
    // Check if we have photos, if so we keep those to add it later
    if (i !== 0 && frame.photo && frame.photo !== null && frame.photo !== '')
      photosInPage.push(frame.photo);

    // keep spine content in memory
    if (frame.type === FRAME_TYPE.SPINE) {
      spineInPage = frame;
    } else if (frame.type === FRAME_TYPE.SPINE_NUM) {
      spineNumInPage = frame;
    } else if (frame.text && frame.type === FRAME_TYPE.TEXT) {
      textsInPage.push(frame);
    }
  });

  // save background detail
  const oldBackground = page.frames[0];

  // save text content also

  // TODO: handle here photos in background to put back in backgrounds... with type:bkg instead of type:picture

  // then change the page layout
  const layoutScaleX = page.width / newLayout.width;
  const layoutScaleY = page.height / newLayout.height;
  page.frames = [];

  // keep trace of layout ID
  page.layoutID = newLayout.id;

  let nextPhotoIndex = 0;
  newLayout.frames.forEach((frame, i) => {
    const newFrame: Frame = { ...frame };
    newFrame.height *=
      newFrame.rotation === 90 || newFrame.rotation === -90
        ? layoutScaleX
        : layoutScaleY;
    newFrame.width *=
      newFrame.rotation === 90 || newFrame.rotation === -90
        ? layoutScaleY
        : layoutScaleX;
    newFrame.x *= layoutScaleX;
    newFrame.y *= layoutScaleY;

    // add a new ID
    // TODO: we should maybe have an object to dupplicate with basic frame caracteristics?
    newFrame.id = GetUID();
    newFrame.cLeft = 0;
    newFrame.cTop = 0;
    newFrame.zoom = 1;

    // --- HANDLE BACKGROUND
    if (i === 0 && newFrame.type !== FRAME_TYPE.POSTCARD_BG) {
      // update background bounds
      UpgradeFrameBackground(newFrame, backgroundBounds, backgroundsByID);

      // try to apply previous background
      if (oldBackground) {
        // keep fill
        newFrame.fillColor = oldBackground.fillColor;

        // background
        if (oldBackground.background) {
          InjectPhotoIntoFrame(
            newFrame,
            backgroundsByID[oldBackground.background]
          );
          newFrame.background = oldBackground.background;
          newFrame.photo = null;
        }

        // apply previous background photo (and keep zoom and crop values if possible)
        else if (oldBackground.photo) {
          newFrame.zoom = oldBackground.zoom;
          newFrame.cLeft = oldBackground.cLeft;
          newFrame.cTop = oldBackground.cTop;
          InjectPhotoIntoFrame(newFrame, photosByID[oldBackground.photo], true);
        }
      }
    }

    // update photos frame
    if (
      newFrame.type === FRAME_TYPE.PHOTO &&
      nextPhotoIndex < photosInPage.length
    ) {
      const photoObj = photosByID[photosInPage[nextPhotoIndex]];
      if (photoObj) InjectPhotoIntoFrame(newFrame, photoObj);
      else newFrame.photo = photoObj; // if photo obj do not exist, it means that the frame might have an error TODO:

      nextPhotoIndex++;
    }

    // if spine, we add content back
    if (newFrame.type === FRAME_TYPE.SPINE && spineInPage) {
      newFrame.text = spineInPage.text;
      CleanAndVerifyFrameText(newFrame);
    }
    if (newFrame.type === FRAME_TYPE.SPINE_NUM && spineNumInPage) {
      newFrame.text = spineNumInPage.text;
      CleanAndVerifyFrameText(newFrame);
    }
    if (newFrame.type === FRAME_TYPE.TEXT && textsInPage.length > 0) {
      newFrame.text = textsInPage.shift().text;
      CleanAndVerifyFrameText(newFrame.text);
    }

    // ---- CALENDAR FRAME ----
    if (newFrame.type === FRAME_TYPE.CALENDAR) {
      // handle scaling of calendar frames (mainly for text!)
      if (doc.layoutTextScaling)
        newFrame.calendarOptions.fontSize = Math.round(
          newFrame.calendarOptions.fontSize * doc.layoutTextScaling
        );

      // set page as calendar page
      page.isCalendar = true;
    }

    // ---- CASE CANVAS, be sure to have correct frame size with edge and cut border enough offset ----
    if (IsCanvasEditor()) {
      CanvasHelper.CheckModifyFrameForEdge(
        currentProject.type,
        page.width,
        page.height,
        newFrame
      );
    }

    // save layout type during apply
    page.layoutType = newLayout.type;

    // add to page
    page.frames.push(newFrame);
  });
}

/** Retrieve a list of ID of forced layout list for this page */
export const GetForcedLayoutsForPage = (
  docID: string,
  pageIndex: number,
  layoutStore
): Array<number> => {
  const doc = GetDoc(docID);
  const isCover = IsCover(docID, pageIndex);
  if (isCover && doc.layouts_cover) return doc.layouts_cover;
  if (doc.layouts_all) return doc.layouts_all;

  return null;
};

/**
Retreive a custom layout from a page
 */
export function GetCustomLayoutFromPage(page: IPage) {
  const pageCopy: IPage = cloneDeep(page);
  pageCopy.frames.forEach((f) => {
    ClearFrame(f);
  });
  const layout: Layout = {
    id: `custom_${DateUtils.GetTimeStamp()}`,
    isCustom: true,
    width: pageCopy.width,
    height: pageCopy.height,
    frames: pageCopy.frames,
    type: LAYOUT_TYPE.DEFAULT,
  };
  return layout;
}

/**
 * Convert an existing project form flash editor to the current data structure
 * @param {bool} AndWriteInAnotherWindow : if we need to write the result in a new window
 */
export function ConvertOldLayoutList(
  oldLayoutListJSON,
  AndWriteInAnotherWindow = false
) {
  const result = {
    filtered: {
      /*
   albums:{
    all:[],
    "1" : [],
    "2" : [],
    }
   },
   ....
   */
    },
    byID: {},
  };

  // var newWin = window.open('url','windowName','height=500,width=500');
  // newWin.document.write(escapeHtml(JSON.stringify(oldLayoutListJSON)));

  // go through layout list and convert it to new format
  oldLayoutListJSON.layouts.layout.forEach((oldNode, index) => {
    // only add layout if there is a valid content to it
    if (oldNode.$ && oldNode.frame) {
      const attributes = oldNode.$;
      const newLayout = new Layout(
        attributes.name,
        attributes.pagewidth,
        attributes.pageheight
      );
      newLayout.type = LAYOUT_TYPE.DEFAULT; // PROJECT_CONST.project_class; // LAYOUT_TYPE.ALBUM;
      let hasSpine = false; // does the layout has a spine, if so we need to clean it afterwards

      // security for canvas items with only 1 frame element! That are not parsed as array..
      if (!Array.isArray(oldNode.frame)) {
        // alert("HUSTON! We have a situation" + typeof(oldNode.frame));
        oldNode.frame = [oldNode.frame];
      }

      // add all frames from xml node converted
      // oldNode.frame.foreach(( oldFrame, i, arr )=>{ // Cannot use foreach otherwise we cannot skip an element with "continue"
      for (let i = 0; i < oldNode.frame.length; i++) {
        const oldFrame = oldNode.frame[i];
        const attr = oldFrame.$;
        const newFrame: Frame = CreateFrame({}); // new Frame();
        newFrame.height = Number(oldFrame.$.height);
        newFrame.width = Number(oldFrame.$.width);
        newFrame.type = oldFrame.$.type;

        // newFrame.rotation = Math.round(Math.random() * 180);

        newFrame.rotation = Number(oldFrame.$.rotation);
        if (!newFrame.rotation) newFrame.rotation = 0;
        newFrame.x = Number(oldFrame.$.left);
        newFrame.y = Number(oldFrame.$.top);

        // positioning in app is centered, while it's 0,0 on old layouts
        newFrame.x += newFrame.width / 2;
        newFrame.y += newFrame.height / 2;

        // --- PHOTO ---
        if (newFrame.type === FRAME_TYPE.PHOTO) {
          // keep track of num of photos
          newLayout.numPhotos++;
        }

        // if we got a spine attribute or a spine number attribute, we are in a cover layout
        // groupeType=5 : SPINE
        // groupeType=5 : SPINE_NUMBER
        if (attr.grouptype && attr.grouptype === '5') {
          newLayout.type = LAYOUT_TYPE.COVER;
          newFrame.type = FRAME_TYPE.SPINE;

          // old layout inverted width and height as rotation was "inside"
          const tempHeight = newFrame.height;
          newFrame.height = Number(newFrame.width);
          newFrame.width = tempHeight;

          // be sure it is centered
          newFrame.x = newLayout.width / 2; // positioning in old layouts is at "0,0"

          hasSpine = true;
          continue; // we don't add it to list, we will add this afterwards
        }
        if (attr.grouptype && attr.grouptype === '6') {
          newLayout.type = LAYOUT_TYPE.COVER;
          newFrame.type = FRAME_TYPE.SPINE_NUM;
          hasSpine = true;
          continue;
        }

        // check if frame is calendar frame
        if (IsCalendarEditor() && attr.dateaction) {
          // <frame stroke="true" left="110" top="164" width="30" height="8"
          // rotation="0" fit="false" clip="false"
          // bleed="0.0" halign="center" type="text"
          // editable="false" font="Helvetica"
          // size="15" color="Black" bold="true" italic="false"
          // dateindex="4" dateaction="[weekday]" valign="center"
          // showborder="false" />
          newLayout.type = LAYOUT_TYPE.CALENDAR; // if there is a calendar frame, this is a calendar layout..
          newFrame.type = FRAME_TYPE.CALENDAR;
          newFrame.calendarOptions = {
            dateAction: attr.dateaction,
            dateIndex: attr.dateindex,
            stroke: attr.stroke === 'true',
            vAlign: attr.valign,
            hAlign: attr.halign,
            fontFamily: attr.font,
            fontSize: attr.size,
            bold: attr.bold === 'true',
            italic: attr.italic === 'true',
          };
        }

        if (newFrame.type === FRAME_TYPE.POSTCARD_BG) {
          newLayout.type = LAYOUT_TYPE.POSTCARD_BG;
        }
        // special calendar url frame
        if (
          newFrame.type === FRAME_TYPE.PROMO_URL &&
          newLayout.type !== FRAME_TYPE.POSTCARD_BG
        ) {
          newLayout.type = LAYOUT_TYPE.PROMO_URL;
        }

        // verify frame text
        if (IsFrameText(newFrame)) CleanAndVerifyFrameText(newFrame);
        /* if(newFrame.type === FRAME_TYPE.TEXT)
     {
      AddDefaultTextToFrame(newFrame);
     }
     */

        // add to list
        newLayout.frames.push(newFrame);
      }

      // --- BACKGROUND check ---
      if (
        newLayout.frames[0].type !== FRAME_TYPE.BKG &&
        newLayout.frames[0].type !== FRAME_TYPE.POSTCARD_BG
      ) {
        // check if frame is bigger than page width and heith, so it's a background
        const f = newLayout.frames[0];
        if (f.width > newLayout.width && f.height > newLayout.height) {
          if (f.type === FRAME_TYPE.PHOTO) newLayout.numPhotos--; // background photos are not considered as "photos"
          f.type = FRAME_TYPE.BKG;
        }
        // otherwise create a background and push it at beginning
        else {
          newLayout.frames.unshift(
            CreateFrameBackground(newLayout.width, newLayout.height)
          );
        }
      }

      // verify that framebackground is filling enough
      // newLayout.frames[0] = CreateFrameBackground(newLayout.width, newLayout.height);
      if (hasSpine) {
        newLayout.frames.push(CreateSpine(newLayout.width, newLayout.height));
        newLayout.frames.push(
          CreateSpineNum(newLayout.width, newLayout.height)
        );
      }

      // add to result
      result.byID[newLayout.id] = newLayout;

      // add to filtered result
      if (!result.filtered[newLayout.type])
        result.filtered[newLayout.type] = {};

      const filterResult = result.filtered[newLayout.type];

      // add to filtered result
      if (!filterResult.all) filterResult.all = [];
      filterResult.all.push(newLayout.id);

      // add to filtered by photo
      if (!filterResult[newLayout.numPhotos])
        filterResult[newLayout.numPhotos] = [];
      filterResult[newLayout.numPhotos].push(newLayout.id);

      // newList.push(newLayout);
    }
    // go through frames
  });

  /*
  alert("newList: " + JSON.stringify(newList));
  var newWin = window.open('url','windowName','height=500,width=500');
  newWin.document.write(escapeHtml(JSON.stringify(newList)));
  */

  // downloadTextAsFile( JSON.stringify(result), "layouts.json");

  return result;

  //  //const paramsToCopy = ["id", "name", "classname", ""]

  //  // common
  //  newJSON.id = oldProject.id;
  //  newJSON.name = oldProject.name;
  //  newJSON.classname = oldProject.classname;

  //  // size
  //  newJSON.docType = oldProject.docType;
  //  newJSON.width = oldProject.width;
  //  newJSON.height = oldProject.height;
  //  newJSON.cutBorder = oldProject.docCutBorder;

  //  // photoList
  //  newJSON.photos = {};

  //  // page list
  //  newJSON.pageList = [];
  //  oldProject.pageList.map((oldPage, pageIndex)=>{

  //   let newPage = {};
  //   newPage.index = pageIndex;

  //   newPage.isCover = oldPage.isCover;
  //   newPage.width = oldPage.bounds.width;
  //   newPage.height = oldPage.bounds.height;
  //   // ... review element needed for the page..

  //   newPage.frames = [];
  //   oldPage.layoutVo.frameList.map((oldFrame)=>{

  //    let newFrame = {};

  //    newFrame.id = GetUID();// we need an ID for each frame to be able to work correcly in react
  //    newFrame.type = oldFrame.type;

  //    newFrame.x = oldFrame.x;
  //    newFrame.y = oldFrame.y;
  //    newFrame.width = oldFrame.width;
  //    newFrame.height = oldFrame.height;
  //    newFrame.rotation = oldFrame.rotation;

  //    // edition
  //    newFrame.editable = oldFrame.editable;
  //    newFrame.fixed = oldFrame.fixed;
  //    newFrame.isDeletable = oldFrame.isDeletable;

  //    // photo
  //    newFrame.cLeft = oldFrame.cLeft;
  //    newFrame.cTop = oldFrame.cTop;
  //    newFrame.zoom = oldFrame.zoom;

  //    // text
  //    if(oldFrame.type === "text"
  //     && !oldFrame.isEmpty)
  //    {
  //     newFrame.text = {};
  //     newFrame.text.size = oldFrame.fontSize;
  //     newFrame.text.vAlign = oldFrame.vAlign;
  //     newFrame.text.hAlign = oldFrame.hAlign;
  //     newFrame.text.value = oldFrame.text;

  //     /*
  //     {
  //   "sCol": 0,
  //   "calFontSizeRaw": "18",
  //   "border": 0,
  //   "tFormat": null,
  //   "fixed": false,
  //   "posXAdjustement": 0,
  //   "dateAction": "",
  //   "hAlign": "center",
  //   "stroke": false,
  //   "upper": false,
  //   "dateLinkageID": null,
  //   "pageIndex": 0,
  //   "cLeft": 0,
  //   "y": 578.15,
  //   "cTop": 0,
  //   "x": 1419.15,
  //   "uniqFrameLinkageID": null,
  //   "rotation": 0,
  //   "alignment": 0,
  //   "text": "<P ALIGN=\"CENTER\"><FONT FACE=\"GillSans\" SIZE=\"40\" COLOR=\"#000000\" LETTERSPACING=\"0\" KERNING=\"0\">COUCOU</FONT></P>",
  //   "isDeletable": true,
  //   "fontSize": 40,
  //   "dateIndex": 1,
  //   "isPopart": false,
  //   "QRLink": "",
  //   "mask": 0,
  //   "calBold": true,
  //   "fillColor": 16777215,
  //   "PopArtBlackTreshold": 30,
  //   "cr": 0,
  //   "calItalic": false,
  //   "tb": false,
  //   "isPageNumber": false,
  //   "shadow": false,
  //   "editable": true,
  //   "width": 702.496338,
  //   "photoVo": null,
  //   "color": 0,
  //   "height": 68.0194866952381,
  //   "PopArtTreshold": 50,
  //   "grouptype": 0,
  //   "fillAlpha": 1,
  //   "isMultiple": false,
  //   "PopArtDarkColor": 173029,
  //   "isPostCardBackground": false,
  //   "vAlign": "top",
  //   "zoom": 1,
  //   "PopArtLightColor": 10344175,
  //   "calFontName": "Arial",
  //   "showBorder": true,
  //   "isValidVo": true,
  //   "printQuality": 1,
  //   "IsCustomRebrandedFrame": false,
  //   "isEmpty": false,
  //   "visible": true,
  //   "type": "text",
  //   "isPrintUpload": true,
  //   "printCoords": {
  //     "x": 997.6521972,
  //     "y": 537.3383079828571,
  //     "length": 1133.155930930897
  //   },

  // }
  //     */

  //     //console.log(JSON.stringify(oldFrame));

  //    }

  //    //if(oldFrame.photoVo)
  //    if(oldFrame.photoVo && (!DebugFlags.RANDOM_EMPTY_IMAGE || (Math.random() < 0.8 )))
  //    {
  //     newFrame.photo = oldFrame.photoVo.id;
  //     let oldPhoto = oldFrame.photoVo;
  //     let newPhoto = {};

  //     newPhoto.id = oldPhoto.id;
  //     newPhoto.name = oldPhoto.name;

  //     // size
  //     newPhoto.width = oldPhoto.width;
  //     newPhoto.height = oldPhoto.height;

  //     // path
  //     newPhoto.thumbUrl = oldPhoto.thumbUrl;
  //     newPhoto.normalUrl = oldPhoto.normalUrl;
  //     newPhoto.highUrl = oldPhoto.highUrl;

  //     /*
  //     "workingImagePath": "/flex/work.php?img=3912016",
  //       "fullsizeImagePath": "/flex/img.php?img=3912016",
  //       "temp": false,
  //       "label": "2013-0...1.28.jpg",
  //       "suffix": "001/255650/images/3912016.jpg",
  //       "id": "3912016",
  //       "width": 2592,
  //       "height": 1936,
  //       "lastModified": 1401824246000,
  //       "originUrl": null,
  //       "exifRot": 0,
  //       "originalDate": 1357317088000,
  //       "edited": false,
  //       "IsCustomRebrandingPhoto": false,
  //       "tempID": null,
  //       "used": true,
  //       "name": "2013-01-04 11.31.28.jpg",
  //       "thumbImagePath": "/flex/tn.php?img=3912016",
  //       "customBkgId": null,
  //       "albumName": null,
  //       "folderName": "@@182891@@",
  //       "isValidVo": true,
  //       "isLocalContent": false,
  //       "thumbUrl": "https://editor.tictacphoto.com/flex/tn.php?img=3912016",
  //       "normalUrl": "https://editor.tictacphoto.com/flex/work.php?img=3912016",
  //       "highUrl": "https://editor.tictacphoto.com/flex/img.php?img=3912016",
  //       "ErrorString": null
  // */

  //     newJSON.photos[newPhoto.id] = newPhoto;
  //    }

  //    /*
  //    "type": "bkg",

  //    "isPageNumber": false, // should be a type of frame
  //    "isPostCardBackground": false, // should be a type of frame

  //    // --- transform
  //    "y": 357,
  //    "x": 937.15,
  //    "width": 1931.0551181399999, // TODO we should reduce to a fixed number here to avoid super long nums in here...
  //    "height": 770.6929134,
  //    "rotation": 0,

  //    // --- edition
  //    "editable": true, // can be edited (with transform tool)
  //    "fixed": false, // can or cannot be moved/draggable (but still can be edited)
  //    "isDeletable": true, // can or cannot be deleted
  //    "visible": true,
  //    // -- album

  //     // -- cal
  //    "calBold": false,
  //    "calItalic": false,
  //    "calFontSizeRaw": null, // ???
  //    "dateAction": null,
  //    "dateIndex": 0,
  //    "calFontName": null,

  //    // --- Canvas
  //    "isMultiple": false,

  //    // -- effects
  //    "shadow": false,
  //    "mask": 0,

  //    // --- border
  //    "showBorder": true, // ??
  //    "border": 0,
  //    "stroke": false,

  //    // -- fill
  //    "fillColor": 16777215,
  //    "fillAlpha": 1,

  //    // -- text
  //    "text": "",
  //    "fontSize": null,
  //    "tFormat": null,
  //    "upper": false,
  //    "alignment": 0, // ????
  //    "vAlign": "top",
  //    "hAlign": "center",

  //    // ---- poparts // TODO should be an object, with poparts params
  //    "isPopart": false,
  //    "PopArtLightColor": 10344175,
  //    "PopArtTreshold": 50,
  //    "PopArtBlackTreshold": 30,
  //    "PopArtDarkColor": 173029,

  //    // -- photo
  //    "cLeft": 0,
  //    "cTop": 0,
  //    "photo": "14280169"
  //    */

  //    newPage.frames.push(newFrame);
  //   });

  //   //////////////////////////////////////////
  //   //////////////////////////////////////////
  //   // add a frame text on each page
  //   let textFrame = {
  //    type : "text",
  //    x : RandomInt(0,300),
  //    y : RandomInt(0,300),
  //    width : RandomInt(200,500),
  //    height : RandomInt(200,500),
  //    rotation : RandomInt(-20,20),
  //    text : {
  //     size : 25,
  //     vAlign : "middle",
  //     hAlign : "center",
  //     value : "<P ALIGN=\"CENTER\"><FONT FACE=\"GillSans\" SIZE=\"40\" COLOR=\"#000000\" LETTERSPACING=\"0\" KERNING=\"0\">COUCOU</FONT></P>"
  //    }
  //   };
  //   newPage.frames.push(textFrame);
  //   //////////////////////////////////////////
  //   //////////////////////////////////////////

  //   newJSON.pageList.push(newPage);
  //  });

  //  // display in another window
  //  if(AndWriteInAnotherWindow)
  //  {
  //   var newWin = window.open('url','windowName','height=500,width=500');
  //   newWin.document.write(escapeHtml(JSON.stringify(newJSON)));
  //  }

  // return
  // return newJSON;
}
