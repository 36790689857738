import { ICalendarColorOptions } from '../../types/types';

export const CreateCalendarColorOptions = (): ICalendarColorOptions => ({
  firstPageYearColor: '#000000',
  strokeColor: '#000000',
  monthAndYearColor: '#000000',
  dayColor: '#000000',
  weekendColor: '#ff0000',
  prevNextMonthColor: '#cccccc',

  frameBackground: false,
  frameBackgroundColor: '#cccccc',
});
