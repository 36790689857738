// https://fonts.google.com/?query=h&selection.family=Martel+Sans|Roboto|Zhi+Mang+Xing
// 'Open Sans',
//   'Roboto', sans-serif;
//   'Zhi Mang Xing', cursive;
//   'Martel Sans', sans-serif;
//   'Sigmar One', cursive;
//   'Bebas Neue', cursive;
//   'Anton', sans-serif;
//   'Molle', cursive;
//   'Indie Flower', cursive;
//   'Dancing Script', cursive;
//   'Pacifico', cursive;
//   'EB Garamond', serif;
//   'Barlow Condensed', sans-serif;
//   'Jim Nightshade', cursive;
//   'Righteous', cursive;
//   'Amatic SC', cursive;
//   'Permanent Marker', cursive;
//   'Satisfy', cursive;
//   'Fredoka One', cursive;
//   'Alfa Slab One', cursive;
//   'Sacramento', cursive;

/** FONT LIST FOR EDITOR */
// export let FontList = FONT_LIST_EMBED;

const FONT_LIST_GOOGLE = [
  'Open Sans',
  'Roboto',
  'Zhi Mang Xing',
  'Martel Sans',
  'Sigmar One',
  'Bebas Neue',
  'Anton',
  'Molle',
  'Indie Flower',
  'Dancing Script',
  'Pacifico',
  'EB Garamond',
  'Barlow Condensed',
  'Jim Nightshade',
  'Righteous',
  'Amatic SC',
  'Permanent Marker',
  'Satisfy',
  'Fredoka One',
  'Alfa Slab One',
  'Sacramento',
];

export const FONT_SIZES_LIST = [
  8, 10, 12, 14, 16, 18, 20, 24, 28, 36, 42, 48, 52, 58, 64, 72, 86, 96, 108,
  120, 144, 192, 240,
];

// --------------------- OLD FONTS ------------------------

// export const OldFontList = [
//   "Arial",
//   "ArialRounded",
//   "Sebastian",
//   "Bauhaus93",
//   "Ravie",
//   "MTScriptBold",
//   "Stencil",
//   "Palatino",
//   "GillSans",
//   "Chalkboard",
//   "BookAntiqua",
//   "Baskerville",
//   "BernardMT",
//   "Blackadder",
//   "Bookman Old",
//   "Bradley Hand",
//   "BerlinSans",
//   "Broadway",
//   "BrushScript",
//   "Calibri",
//   "Cambria",
//   "Comic Sans MS",
//   "Curlz",
//   "Desyrel",
//   "Edwardian",
//   "Fely",
//   "Forte",
//   "Freestyle",
//   "French",
//   "Garamond",
//   "Georgia",
//   "Gothic",
//   "Helvetica",
//   "Jokerman",
//   "Kristen",
//   "Lucida Calligraphy",
//   "Lucida Hand",
//   "Monotype Corsiva",
//   "Papyrus",
//   "PhontPhreaks Handwriting",
//   "Segoe Cursiva",
//   "Tahoma",
//   "Tempu Sans",
//   "Times New Roman",
//   "Valerie",
//   "Verdana",
//   // Custom rebranded promo editor (Bel&Bo)
//   "A Little Sunshine"
//  ];

export const FONT_LIST_CLASSIC_COVER = [
  'Arial Bold Italic',
  'Arial Bold',
  'Arial Italic',
  'Arial',
  'ArialRounded',
  'BaskervilleOldFace',
  'Bauhaus 93',
  'BerlinSansBold',
  'BernardMtCond',
  'Blackadder',
  'BookAntiqua',
  'BookAntiquaBold',
  'BookAntiquaBoldItalic',
  'BookAntiquaItalic',
  'BookmanOldStyle',
  'BookmanOldStyleBold',
  'BookmanOldStyleBoldItalic',
  'BookmanOldStyleItalic',
  'BradleyhandItc',
  'Broadway',
  'BrushScriptMtItalic',
  'Calibri',
  'CalibriBold',
  'CalibriBoldItalic',
  'CalibriItalic',
  'CambriaBold',
  'CambriaItalic',
  'Curlz',
  'Desyrel',
  'Fely',
  'Forte',
  'Garamond',
  'GaramondBold',
  'GaramondItalic',
  'Georgia',
  'GeorgiaBold',
  'GeorgiaBoldItalic',
  'GeorgiaItalic',
  'HelveticaBold',
  'HelveticaItalic',
  'HelveticaRegular',
  'Jokerman',
  'Kristen',
  'LucidaCalligraphy',
  'LucidaHand',
  'MonotypeCorsiva',
  'Papyrus',
  'Ravie',
  'script_mt_bold',
  'Sebastian',
  'SegoeCursiva',
  'SegoeCursivaBoldItalic',
  'Tahoma',
  'TempuSans',
  'Verdana',
  'StencilStd',
];

// ----- BACKEND FONT NAME CHECK (from keith) -----

// case 'arial': $cover_text[3]='Arial'; break;
//   case 'bauhs93': $cover_text[3]='Bauhaus 93'; break;
//   case 'Bauhaus93': $cover_text[3]='Bauhaus 93'; break;
//   case 'ravie': $cover_text[3]='Ravie'; break;
//   case 'MTScriptBold': $cover_text[3]='Script MT Bold'; break;
//   case 'scriptbl': $cover_text[3]='Script MT Bold'; break;
//   case 'stencil': $cover_text[3]='Stencil'; break;
//   case 'Bradley Hand': $cover_text[3] = 'Bradley Hand ITC'; break;
//   case 'Kristen': $cover_text[3] = 'Kristen ITC'; break;
//   case 'ArialRounded': $cover_text[3] = 'Arial Rounded MT Bold'; break;
//   case 'Arial': $cover_text[3] = 'Arial'; break;
//   case 'ArialRounded': $cover_text[3] = 'Arial Rounded MT Bold'; break;
//   case 'Baskerville': $cover_text[3] = 'Baskerville Old Face'; break;
//   case 'Bauhaus93': $cover_text[3] = 'Bauhaus 93'; break;
//   case 'BerlinSans': $cover_text[3] = 'Berlin Sans FB'; break;
//   case 'BernardMT': $cover_text[3] = 'Bernard MT Condensed'; break;
//   case 'Blackadder': $cover_text[3] = 'Blackadder ITC'; break;
//   case 'BookAntiqua': $cover_text[3] = 'Book Antiqua'; break;
//   case 'Bookman Old': $cover_text[3] = 'Bookman Old Style'; break;
//   case 'Bradley Hand': $cover_text[3] = 'Bradley Hand ITC'; break;
//   case 'Broadway': $cover_text[3] = 'Broadway'; break;
//   case 'BrushScript': $cover_text[3] = 'Brush Script MT'; break;
//   case 'Calibri': $cover_text[3] = 'Calibri'; break;
//   case 'Cambria': $cover_text[3] = 'Cambria'; break;
//   case 'Comic Sans MS': $cover_text[3] = 'Comic Sans MS'; break;
//   case 'Curlz': $cover_text[3] = 'Curlz MT'; break;
//   case 'Desyrel': $cover_text[3] = 'Desyrel'; break;
//   case 'Fely': $cover_text[3] = 'Fely'; break;
//   case 'Forte': $cover_text[3] = 'Forte'; break;
//   case 'Garamond': $cover_text[3] = 'Garamond'; break;
//   case 'Georgia': $cover_text[3] = 'Georgia'; break;
//   case 'Helvetica': $cover_text[3] = 'Helvetica'; break;
//   case 'Jokerman': $cover_text[3] = 'Jokerman'; break;
//   case 'Kristen': $cover_text[3] = 'Kristen ITC'; break;
//   case 'Lucida Calligraphy': $cover_text[3] = 'Lucida Calligraphy'; break;
//   case 'Lucida Hand': $cover_text[3] = 'Lucida Handwriting'; break;
//   case 'Lucida Handwriting': $cover_text[3] = 'Lucida Handwriting'; break;
//   case 'Monotype Corsiva': $cover_text[3] = 'Monotype Corsiva'; break;
//   case 'Papyrus': $cover_text[3] = 'Papyrus'; break;
//   case 'Ravie': $cover_text[3] = 'Ravie'; break;
//   case 'MT Script Bold': $cover_text[3] = 'Script MT Bold'; break;
//   case 'Sebastian': $cover_text[3] = 'Sebastián Font'; break;
//   case 'Segoe Cursiva': $cover_text[3] = 'Segoe Print'; break;
//   case 'Segoe Script': $cover_text[3] = 'Segoe Script'; break;
//   case 'Tahoma': $cover_text[3] = 'Tahoma'; break;
//   case 'Tempus Sans': $cover_text[3] = 'Tempus Sans ITC'; break;
//   case 'Times New Roman': $cover_text[3] = 'Times New Roman'; break;
//   case 'Valerie': $cover_text[3] = 'Valerie'; break;
//   case 'Verdana': $cover_text[3] = 'Verdana'; break;
//   case 'Stencil': $cover_text[3] = 'Stencil'; break;

/* GENERATED by npm script - refreshEmbedFontList from folder 'src/fonts' */
export const FONT_LIST_EMBED = [
  'ALittleSunshine',
  'Arial Bold Italic',
  'Arial Bold',
  'Arial Italic',
  'Arial',
  'ArialRounded',
  'BaskervilleOldFace',
  'Bauhaus 93',
  'BerlinSans',
  'BerlinSansBold',
  'BernardMtCond',
  'Blackadder',
  'BookAntiqua',
  'BookAntiquaBold',
  'BookAntiquaBoldItalic',
  'BookAntiquaItalic',
  'BookmanOldStyle',
  'BookmanOldStyleBold',
  'BookmanOldStyleBoldItalic',
  'BookmanOldStyleItalic',
  'BradleyhandItc',
  'Broadway',
  'BrushScriptMtItalic',
  'Calibri',
  'CalibriBold',
  'CalibriBoldItalic',
  'CalibriItalic',
  'CambriaBold',
  'CambriaItalic',
  'Comic',
  'ComicBold',
  'Curlz',
  'Desyrel',
  'Edwardian',
  'Fely',
  'Forte',
  'Freestyle',
  'French',
  'Garamond',
  'GaramondBold',
  'GaramondItalic',
  'Georgia',
  'GeorgiaBold',
  'GeorgiaBoldItalic',
  'GeorgiaItalic',
  'Gothic',
  'GothicBold',
  'GothicBoldItalic',
  'GothicItalic',
  'HelveticaBold',
  'HelveticaItalic',
  'HelveticaRegular',
  'Jokerman',
  'Kristen',
  'LucidaCalligraphy',
  'LucidaHand',
  'MonotypeCorsiva',
  'Open Sans',
  'Papyrus',
  'PhontPhreaksHandwriting',
  'Ravie',
  'Sebastian',
  'SegoeCursiva',
  'SegoeCursivaBoldItalic',
  'StencilStd',
  'Tahoma',
  'TahomaBold',
  'TempuSans',
  'TimesNewRoman',
  'TimesNewRomanBold',
  'TimesNewRomanBoldItalic',
  'TimesNewRomanItalic',
  'Valerie',
  'Verdana',
  'VerdanaBold',
  'VerdanaBoldItalic',
  'VerdanaItalic',
  'pf_ronda_seven',
  'script_mt_bold',
];
/* END OF GENERATED CODE */
