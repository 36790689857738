import React from 'react';
import { connect } from 'react-redux';
import { Popover, Spin, Select } from 'antd';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { cloneDeep } from 'lodash';
import type { IPage } from '../../../types/types';
import { editionActions } from '../../../feature/edition/edition';
import { clipartSelectors } from '../../../feature/cliparts/cliparts';
import { AddDragData, DRAG_TYPE } from '../../../utils/DragUtils';
import { AddClipartToPage } from '../../../feature/cliparts/clipartHelper';
import { GetText } from '../../../data/LanguageHelper';
import { IsDebug } from '../../../debug/DebugFlags';
import { TABS_ENUM } from './TABS_ENUM';

class ClipartsArea extends React.Component {
  // --------------------- constructor ------------------------

  constructor(props) {
    super(props);
    this.state = {
      currentCategory: undefined,
    };
  }

  // --------------------- react ------------------------

  componentDidMount() {
    if (this.props.clipartCategories && this.props.clipartCategories.length > 0)
      this.handleCategoryChange(this.props.clipartCategories[0]);
  }

  shouldComponentUpdate(nextProps, nextState) {
    // const differentTitle = this.props.title !== nextProps.title;
    // const differentDone = this.props.done !== nextProps.done
    // return differentTitle || differentDone;

    // we should update layouts only if layouts did change, or if we switch from cover to page
    const tabActive = this.props.selectedTab === TABS_ENUM.CLIPART;
    const categoryDidChange =
      nextState.currentCategory !== this.state.currentCategory;
    const coverChange =
      this.props.selectedPage !== nextProps.selectedPage &&
      (this.props.selectedPage === 0 || nextProps.selectedPage === 0);

    // Conditions to re render this component, only if the layout list is different!
    const different = this.props.cliparts !== nextProps.cliparts;
    return tabActive && (different || coverChange || categoryDidChange);
  }

  // --------------------- methods ------------------------

  handleCategoryChange(newCategory) {
    this.setState({
      currentCategory: newCategory,
    });
  }

  handleItemClick(item) {
    const { project, selectedPage, clipartsById } = this.props;

    if (!project) {
      alert('TODO: no project selected');
      return;
    }

    // get a copy of page to work with
    const pageCopy: IPage = cloneDeep(project.pageList[selectedPage]);

    // apply to page
    AddClipartToPage(pageCopy, item);

    // dispatch page update
    this.props.dispatch(editionActions.UpdatePage(pageCopy));

    // layout applied, allow undoable action
    this.props.dispatch(editionActions.AddUndoableAction());
  }

  handleItemDragStart(evt, item) {
    AddDragData(evt, DRAG_TYPE.CLIPART, item.id);
  }

  /// ///////////////////////////////////////////////////
  // Render
  /// ///////////////////////////////////////////////////
  render() {
    const { cliparts, clipartCategories, project, isLoading } = this.props;
    const { currentCategory } = this.state;
    const { Option } = Select;

    // const pageRatio = (project) ? project.width / project.height : -1;
    console.log(`WE RENDER CLIPARTS NOW (category:${currentCategory})`);

    return (
      <div className="tabContent clipartsArea">
        <h3>{GetText('lefttab.cliparts')}</h3>
        <Spin spinning={isLoading}>
          <Select
            defaultValue={currentCategory || clipartCategories[0]}
            placeholder="select a category"
            onChange={(value) => {
              this.handleCategoryChange(value);
            }}
            className="dropdown"
          >
            {clipartCategories.map((category, index) => (
              <Option key={`${category}_${index}`} value={category}>
                {GetText(`lefttab.category.${category}`)}
              </Option>
            ))}
          </Select>
          <div
            className="layoutsContainer"
            style={{
              width: '100%',
              display: 'grid',
              padding: '10px 0px',
              gridTemplateColumns: '1fr 1fr',
              gridTemplateRows: 'auto',
            }}
          >
            {cliparts &&
              cliparts[currentCategory] &&
              cliparts[currentCategory].map((clipartItem, key) => {
                // const item:Layout = layoutsByID[layoutID];
                const item = clipartItem;
                const overContent = IsDebug ? (
                  <div style={{ width: 500, wordWrap: 'break-word' }}>
                    {JSON.stringify(item)}
                  </div>
                ) : null;

                const pageRatio = item.width / item.height;
                const contentW = 90;
                const contentH = contentW / pageRatio;

                return (
                  // TODO:check to use antd Grid here with list item card

                  <div
                    className="photoItem"
                    style={{
                      width: contentW,
                      // height:contentH,
                      textAlign: 'center',
                    }}
                    key={item.id}
                    onDragStart={(e) => this.handleItemDragStart(e, item)}
                    onDoubleClick={(e) => this.handleItemClick(item)}
                    draggable
                  >
                    {
                      // see doc of popover that extends the tooltip
                      // https://gary-shen.github.io/ant-design/components/tooltip/
                    }
                    {/* <Tooltip placement="right" title={"id:"+item.id + "\nname:"+item.name}> */}
                    <Popover
                      content={overContent}
                      title={`id:${item.id}`}
                      placement="right"
                      trigger="hover"
                      mouseEnterDelay={1}
                    >
                      <LazyLoadImage // className="photoPreview"
                        // style={{margin: "0px auto 0px auto"}}
                        // key={item.id}
                        threshold={600}
                        effect="opacity"
                        style={{
                          width: '100%',
                          maxHeight: '70px',
                          height: '70px',
                          objectFit: 'contain',
                        }}
                        draggable={false}
                        src={item.thumb_url}
                      />
                    </Popover>
                  </div>
                );
              })}
          </div>
        </Spin>
      </div>
    );
  }
}

// --------------------- redux ------------------------

// Redux map
function mapStateToProps(state) {
  const { cliparts, edition, photos, ui } = state;
  const { project } = edition;

  // we need user, project and layouts
  return {
    project,
    selectedTab: ui.selectedTab,
    selectedPage: edition.selectedPage,
    isLoading: cliparts.isLoading,
    clipartsById: cliparts,
    cliparts: clipartSelectors.getClipartsByCategories(state),
    clipartCategories: clipartSelectors.getClipartCategories(state),
  };
}

// --------------------- export ------------------------

const reduxConnected = connect(mapStateToProps)(ClipartsArea);
export { reduxConnected as ClipartsArea };
