import React from 'react';
import type { Frame, IFrameShadow } from '../../types/types';
import { ToRadians } from '../../utils/MathUtils';
import { Colors } from '../../data/Colors';

export const FRAME_SHADOW_SIZE = 256;
const shadowOffset = 20;

export function getDefaultFrameShadow(): IFrameShadow {
  return {
    enabled: false,
    distance: 10,
    hexColor: Colors.BLACK,
    opacity: 0.3,
    angle: 45,
  };
}

/**
 * Retrieve shadow rect based on frame properties
 */
export function GetShadowFrameRect(frame: Frame) {
  const scaleX: number = frame.width / FRAME_SHADOW_SIZE;
  const scaleY: number = frame.height / FRAME_SHADOW_SIZE;
  const relativeOffsetX = shadowOffset * scaleX;
  const relativeOffsetY = shadowOffset * scaleY;
  return {
    x:
      Math.cos(ToRadians(frame.shadow.angle)) * frame.shadow.distance -
      relativeOffsetX,
    y:
      Math.sin(ToRadians(frame.shadow.angle)) * frame.shadow.distance -
      relativeOffsetY,
    width: frame.width + relativeOffsetX * 2,
    height: frame.height + relativeOffsetY * 2,
  };
}

/**
 *
 */
export const FrameShadow = ({ frame, isForPrint }) => {
  const frameShadow = frame.shadow;
  const shadowRect = !isForPrint
    ? GetShadowFrameRect(frame)
    : {
        x: 0,
        y: 0,
        width: FRAME_SHADOW_SIZE,
        height: FRAME_SHADOW_SIZE,
      };

  // radius of the shadow
  const scaleX = frame.width / FRAME_SHADOW_SIZE;
  const scaleY = frame.height / FRAME_SHADOW_SIZE;
  const rx = frame.borderRadius ? frame.borderRadius / scaleX : 10;
  const ry = frame.borderRadius ? frame.borderRadius / scaleY : 10;

  return (
    <svg
      x={shadowRect.x}
      y={shadowRect.y}
      width={shadowRect.width}
      height={shadowRect.height}
    >
      <defs>
        <filter id="blur">
          <feGaussianBlur stdDeviation="5" />
        </filter>
      </defs>
      <g
        transform={`scale(${shadowRect.width / FRAME_SHADOW_SIZE}, ${
          shadowRect.height / FRAME_SHADOW_SIZE
        })`}
      >
        {/* THIS ALLOW TO PREVIEW THE SHADOW OFFSET */}
        {/* <rect
                    width={FRAME_SHADOW_SIZE}
                    height={FRAME_SHADOW_SIZE}
                    rx="0" ry="0" fill={Colors.GREEN_FLASH}
                    style={{opacity:0.5}}
                /> */}
        <rect
          filter="url(#blur)"
          x={shadowOffset}
          y={shadowOffset}
          width={FRAME_SHADOW_SIZE - shadowOffset * 2}
          height={FRAME_SHADOW_SIZE - shadowOffset * 2}
          rx={rx}
          ry={ry}
          fill={frameShadow.hexColor}
          style={{ opacity: frameShadow.opacity }}
        />
        {/* <text y="10">{JSON.stringify(shadowRect)}</text> */}
      </g>
    </svg>
    // </svg>
  );
};
