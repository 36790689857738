import React from 'react';
// import PropTypes from 'react';
// import PropTypes from 'prop-types';
import { Icon, Row, Col, Button, Upload, Spin, Tooltip } from 'antd';
import {
  WarningTwoTone,
  CloseCircleOutlined,
  PictureOutlined,
  LoadingOutlined,
  FontSizeOutlined,
} from '@ant-design/icons';
import { isObject, cloneDeep } from 'lodash';
import type {
  Photo,
  Frame,
  Clipart,
  OverlayerItem,
  BackgroundItem,
  ICalendarColorOptions,
  ImageAsset,
} from '../../../types/types';
import { GetText } from '../../../data/LanguageHelper';
import { IsSafari } from '../../../utils/BrowserHelper';

import { Colors } from '../../../data/Colors';
import { mmToPoint } from '../../../utils/MeasureUtils';
import {
  FrameShadow,
  FRAME_SHADOW_SIZE,
  getDefaultFrameShadow,
} from '../../../feature/edition/ShadowHelper';
import { DebugFlags } from '../../../debug/DebugFlags';
import { FramePostcardBG } from './FramePostcardBG';
import { ForeignObjectUnscaled } from '../../../_components/SvgForeignObjectUnscaled';
import { FrameCalendarText } from './FrameCalendarText';
import { OverlayerHelper } from '../../../feature/overlayers/overlayerHelper';
import {
  frameCanBeHovered,
  frameCanBeSelected,
  FRAME_TYPE,
  GetImageQualityIndicator,
  IMAGE_QUALITY,
  IsFrameEmpty,
} from '../../../feature/edition/frameHelper';
import { PROJECT_CONST } from '../../../data/config';

require('../../../types/types.d');

const zoomQualityTreshold = 1.2;

interface FrameAreaProps {
  frame: Frame;
  pageIndex: number;
  calendarColorOptions: ICalendarColorOptions;
  frameIndex: number;
  photosByID: Record<Photo>;
  backgroundsByID: Record<BackgroundItem>;
  clipartsByID: Record<Clipart>;
  overlayersByID: Record<OverlayerItem>;
  isForPrint: boolean;
  isPreviewMode: boolean;
  editionScale: number;
  isSelected: boolean;
  isEditing: boolean; // are we currenctly editing this frame? (inside move, zoom, move, rotate )

  // callbacks
  onUploaderChange(info: any, frame: Frame): any;
  onFrameTextLimitReached({ width: number, height: number }): any;
  onFrameDoubleClick(frame: Frame): any;
}

class FrameArea extends React.Component<FrameAreaProps> {
  // --------------------- constructor ------------------------

  constructor(props) {
    super(props);
    this.state = {
      loading: false, // is currently loading
      loaded: false, // current loaded url
      loading_url: null, // currently used url
      final_url: null, // final url to be loaded
      error: null, // has error
      draggingOver: false,
      isOver: false,
      qualityWarningHide: false,
    };

    // this.picRef = React.createRef();
    this.frameTextDivRef = React.createRef();
  }

  // --------------------- react ------------------------

  componentDidMount() {
    this.checkAndLoadContent();
  }

  componentDidUpdate(prevProps) {
    this.checkAndLoadContent();

    // check text frame limits
    if (
      this.props.frame.type === FRAME_TYPE.TEXT &&
      this.frameTextDivRef &&
      this.frameTextDivRef.current
    ) {
      if (
        this.frameTextDivRef.current.clientWidth - this.props.frame.width >
        1
      ) {
        // console.log( "we reached the WIDTH limit!" );
        this.props.onFrameTextLimitReached({
          width: this.frameTextDivRef.current.clientWidth,
        });
      }
      if (
        this.frameTextDivRef.current.clientHeight - this.props.frame.height >
        1
      ) {
        // console.log( "we reached the HEIGHT limit!" );
        this.props.onFrameTextLimitReached({
          height: this.frameTextDivRef.current.clientHeight,
        });
      }
    }
  }

  checkAndLoadContent = () => {
    const {
      frame,
      pageIndex,
      frameIndex,
      photosByID,
      backgroundsByID,
      clipartsByID,
      overlayersByID,
    } = this.props;
    const { loading, error, loaded } = this.state;

    const pic = frame.photo
      ? photosByID[frame.photo]
      : frame.background
      ? backgroundsByID[frame.background]
      : null;
    const clipart = frame.clipart ? clipartsByID[frame.clipart] : null;
    const overlayer = frame.overlayer ? overlayersByID[frame.overlayer] : null;
    if (pic) {
      const lowUrl = pic.temp ? pic.temp_url : pic.thumb_url;
      const highUrl = pic.temp ? pic.temp_url : pic.working_url;
      this.checkLoad(lowUrl, highUrl);
    } else if (clipart) {
      const lowUrl = clipart.thumb_url;
      const highUrl = clipart.working_url;
      this.checkLoad(lowUrl, highUrl);
    }
    // else if( overlayer )
    // {
    //     const low_url = overlayer.thumb_url ;
    //     const high_url = overlayer.working_url;
    //     this.checkLoad(low_url, high_url);
    // }

    // securities
    if (!error && frame.photo && !pic && !this.props.isEditing) {
      this.setState({ error: `Photo with ID:'${frame.photo}' not found` });
    }

    // const clipart = (frame.clipart)? clipartsByID[frame.clipart]:null;
    // let imageAsset = (pic)? pic : clipart? clipart : null;

    // if(imageAsset)

    // // image not in image list
    // if( frame.photo )
    // {
    //     if( !pic && !error )
    //         this.handleImageLoadError( "error while loading photo with id: " + frame.photo );
    //     else if(!loaded && !loading && !error)
    //         this.handleImageLoadStart( (pic.temp) ? pic.temp_url : pic.thumb_url  );
    // }
    // else if( frame.clipart )
    // {
    //     if( !clipart && !error )
    //         this.handleImageLoadError( "error while loading clipart with id: " + frame.clipart );
    //     else if(!loaded && !loading && !error)
    //         this.handleImageLoadStart( clipart.thumb_url );
    // }
  };

  // --------------------- methods ------------------------

  checkLoad = (low_url, high_url) => {
    // case new url!
    if (this.state.final_url !== high_url) {
      // case nothing loaded yet
      this.setState({
        loading: true,
        loaded: false,
        final_url: high_url,
        loading_url: low_url,
        error: null,
      });
      this.loadDummy(low_url);
    }
    // case low url loaded but still need to load high url
    else if (
      !this.state.error &&
      !this.state.loading &&
      this.state.loading_url !== this.state.final_url
    ) {
      this.setState({
        loading: true,
        loaded: false,
        final_url: high_url,
        loading_url: high_url,
        error: null,
      });
      this.loadDummy(high_url);
    }
  };

  loadDummy = (url) => {
    // load dummy image
    const img = new Image();
    img.src = url;
    img.addEventListener('load', this.handleImageLoadComplete);
    img.addEventListener('error', this.handleImageLoadError);
  };

  handleImageLoadComplete = () => {
    // case we need to load other
    this.setState({
      loading: false,
      loaded: true,
      error: null,
    });
  };

  handleImageLoadError = (e) => {
    this.setState({
      loading: false,
      loaded: false,
      error: `Error while loading:${this.state.loading_url}`,
    });
  };

  /// ///////////////////////////////////////////////////
  // Render
  /// ///////////////////////////////////////////////////
  render() {
    const {
      pageIndex,
      frameIndex,
      photosByID,
      backgroundsByID,
      clipartsByID,
      overlayersByID,
      isForPrint,
      isPreviewMode,
    } = this.props;
    const frame: Frame = cloneDeep(this.props.frame);
    const { loading, error, loaded } = this.state;

    // position (translated to center)
    const framePos = {
      x: frame.x - frame.width / 2,
      y: frame.y - frame.height / 2,
    };

    // with frame from json
    // const t = {
    //     x : frame.x - frame.width/2,
    //     y : frame.y - frame.height/2,
    //     width : frame.width,
    //     height : frame.height,
    //     angle : frame.rotation,
    //     //angle : Math.random()* 45 - 25// frame.rotation
    // }

    const pic: ImageAsset = frame.photo
      ? photosByID[frame.photo]
      : frame.background
      ? backgroundsByID[frame.background]
      : null;

    if (pic && DebugFlags.SIMULATE_ERROR_ON_FRAME_LOAD)
      pic.thumb_url = 'test_error.png';

    const clipart = frame.clipart ? clipartsByID[frame.clipart] : null;
    const overlayer: OverlayerItem = frame.overlayer
      ? overlayersByID[frame.overlayer]
      : null;
    // const overlayerContentRect = (overlayer)? OverlayerHelper.GetOverLayerContentRect(overlayer, frame) : null;
    const overlayerRect = overlayer
      ? OverlayerHelper.GetOverLayerOverRect(overlayer, frame)
      : null;
    const isEmpty = IsFrameEmpty(frame);
    const emptyButtonFullyDisplaySize = 200 / this.props.editionScale;
    const borderInPixel = mmToPoint(frame.border);
    if (!frame.shadow || !isObject(frame.shadow))
      frame.shadow = getDefaultFrameShadow();

    // quality
    const imageQuality = GetImageQualityIndicator(frame);

    // if for print, we don't use the checkLoad system, we directly use the high url
    const imageURL = pic
      ? isForPrint
        ? pic.full_url
          ? pic.full_url
          : pic.working_url
        : this.state.loading_url
      : null;

    // const contentRect = overlayerContentRect? overlayerContentRect : {
    const contentRect = {
      x: 0,
      y: 0,
      width: frame.width,
      height: frame.height,
    };

    return (
      <g
        id={this.key}
        key={this.key}
        transform={
          this.props.isForPrint
            ? ''
            : `translate(${framePos.x},${framePos.y}) rotate(${
                frame.rotation
              },${frame.width / 2},${frame.height / 2})`
        }
        // className="editionFrame"
        // onClick={(e)=> { this.onFrameSelect( e.target, frame ) ;}} >
        className={frameCanBeSelected(frame) ? '' : 'unselectable unclickable'}
        onMouseDown={(e) => {
          this.props.onFrameMouseDown(frameIndex, e);
        }}
        onDoubleClick={(e) => {
          this.props.onFrameDoubleClick(frame);
        }}
        onDragOver={(e) => {
          this.setState({
            draggingOver: true,
          });
          this.props.onDragOverFrame(e, frameIndex);
        }}
        onDragLeave={(e) => {
          this.setState({
            draggingOver: false,
          });
          this.props.onDragLeaveFrame(e, frameIndex);
        }}
        //    onDragExit={ (e)=>{ this.handleDropOnFrame(e, i )} }
        //    onDropCapture={ (e)=>{ this.handleDropOnFrame(e, i )} }
        onDrop={(e) => {
          this.setState({ draggingOver: false, isOver: false });
          this.props.onDropOnFrame(e, frameIndex, frame);
        }}
        onMouseEnter={(e) => {
          this.setState({ isOver: true });
        }}
        onMouseLeave={(e) => {
          this.setState({ isOver: false });
        }}
      >
        {frame.borderRadius && (
          <defs>
            <clipPath id={`mask_${frame.id}`}>
              {/* <ellipse cx={frame.width/2} cy={frame.height/2} rx={frame.width/2} ry={frame.height/2} /> */}
              <rect
                x={0}
                y={0}
                ry={
                  frame.borderRadius && frame.border
                    ? frame.borderRadius + borderInPixel
                    : frame.borderRadius
                }
                rx={
                  frame.borderRadius && frame.border
                    ? frame.borderRadius + borderInPixel
                    : frame.borderRadius
                }
                width={frame.width}
                height={frame.height}
              />
            </clipPath>
          </defs>
        )}

        <g>
          {
            // --------------------- SHADOW ------------------------
            frame.shadow && frame.shadow.enabled && !isForPrint && (
              <FrameShadow frame={frame} />
            )
          }

          <rect
            x="0"
            y="0"
            width={frame.width}
            height={frame.height}
            style={{
              // stroke: "#000000",
              // strokeWidth: 3,
              // strokeDasharray: "10 5",
              fill: frame.fillColor,
              fillOpacity:
                frame.fillColor && frame.fillColor !== '#ffffff' ? 1 : 0,
            }}
          />

          {/** *******************
                IMAGE
                Check this for more clipping/mask information
                https://webdesign.tutsplus.com/tutorials/a-comprehensive-guide-to-clipping-and-masking-in-svg--cms-30380
                ********************** */}
          {pic && (
            <g clipPath={`url(#mask_${frame.id})`}>
              <svg
                x={contentRect.x}
                y={contentRect.y}
                width={contentRect.width}
                height={contentRect.height}
                viewBox={`${frame.cLeft} ${frame.cTop} ${contentRect.width} ${contentRect.height}`}
              >
                {/*
                    width={frame.width} height={frame.height}
                    viewBox={"200 0 " + pic.width*frame.zoom + " " + pic.height*frame.zoom}
                    preserveAspectRatio="slice"

                    viewBox={"0 0 " + pic.width*frame.zoom + " " + pic.height*frame.zoom}>
                    preserveAspectRatio="slice"
                    */}

                <image // id={frame.id}
                  key={`${pic.id}_${isPreviewMode}`}
                  // ref={this.picRef}
                  className={`${
                    isPreviewMode
                      ? 'frameArea_image_preview'
                      : 'frameArea_image'
                  } ${this.state.loaded ? 'frame_loaded' : ''}`}
                  // href={frame.content.image}
                  // href={ (pic.temp) ? pic.temp_url : pic.thumb_url}
                  // xlinkHref={ (pic.temp) ? pic.temp_url : pic.thumb_url}
                  href={imageURL}
                  xlinkHref={imageURL}
                  width={pic.width * frame.zoom}
                  height={pic.height * frame.zoom}
                  preserveAspectRatio="none"
                />
              </svg>
            </g>
          )}

          {
            // --------------------- Clipart ------------------------
            clipart && (
              <image // id={frame.id}
                key={`${clipart.id}_${isPreviewMode}`}
                // href={frame.content.image}
                // href={ clipart.thumb_url}
                className={`${
                  isPreviewMode ? 'frameArea_image_preview' : 'frameArea_image'
                } ${this.state.loaded ? 'frame_loaded' : ''}`}
                // xlinkHref={ clipart.thumb_url}
                href={this.state.loading_url}
                xlinkHref={this.state.loading_url}
                width={frame.width}
                height={frame.height}
                preserveAspectRatio="none"
              />
            )
          }

          {DebugFlags.IS_LOCALHOST && pic && pic.temp && !isForPrint && (
            <rect x={10} y={10} width={10} height={10} fill="blue" />
          )}

          {
            // --------------------- overlayer ------------------------
            overlayer && (
              <g key={`${overlayer.id}_${isPreviewMode}`}>
                <image
                  className={`${
                    isPreviewMode
                      ? 'frameArea_image_preview'
                      : 'frameArea_image'
                  } ${this.state.loaded ? 'frame_loaded' : ''}`}
                  href={overlayer.working_url}
                  xlinkHref={overlayer.working_url}
                  width={overlayerRect.width}
                  height={overlayerRect.height}
                  x={overlayerRect.x}
                  y={overlayerRect.y}
                  preserveAspectRatio="none"
                />

                {/* { // TODO: what if no photo? Empty
                    //  (!pic) &&
                    //     <rect
                    //         x={contentRect.x} y={contentRect.y}
                    //         width={contentRect.width} height={contentRect.height}
                    //         fill="black"
                    //         />
                    } */}
              </g>
            )
          }

          {/* --------------------- CALENDAR ------------------------ */}
          {frame.type === FRAME_TYPE.CALENDAR && (
            <FrameCalendarText
              frame={frame}
              editionScale={this.props.editionScale}
              calendarColorOptions={this.props.calendarColorOptions}
              pageIndex={pageIndex}
            />
          )}

          {/* --------------------- PROMO URL ------------------------ */}
          {frame.type === FRAME_TYPE.PROMO_URL &&
            PROJECT_CONST.use_PROMO_URL_FRAMES && (
              <svg width={frame.width} height={frame.height}>
                {/*  // BACKGROUND
                    <rect
                        width={t.width} height={t.height}
                        fill={Colors.YELLOW_FLASH}
                        /> */}
                <text
                  height={contentRect.height}
                  width={contentRect.width}
                  y={contentRect.height / 2}
                  x={contentRect.width - 5} // align right // -x is the margin for correct printing
                  className="unselectable"
                  style={{
                    // overflow:"hidden",
                    textAnchor: 'end', // halign
                    dominantBaseline: 'middle', // valign
                    fontSize: '10px',
                    paddingRight: '10px',
                    // fontWeight:"bold",
                    fontFamily: 'Helvetica',
                    fill: '#000000',
                    // stroke:"black",
                    // strokeWidth:2
                  }}
                >
                  {PROJECT_CONST.displayUrl}
                </text>
              </svg>
            )}

          {/* --------------------- POSTCARD BG ------------------------ */}
          {frame.type === FRAME_TYPE.POSTCARD_BG && <FramePostcardBG />}

          {/* DISPLAY DEBUG TEXT OVER FRAME */}
          {/*
                <foreignObject x="0" y="0" className="unselectable"
                    height={t.height}
                    width={t.width}
                    style={{color:"#fff000", pointerEvents:"none", overflow:"hidden", wordWrap:"break-word"}}>

                <div>
                {JSON.stringify(frame)}
                </div>
                </foreignObject>
                */}

          {
            /*
                    <foreignObject x="20" y="20" width="160" height="160">

                        {/* Dans le cas d'un SVG intégré dans du HTML, le namespace XHTML peut
                        être omis, mais il est obligatoire dans le contexte d'un document SVG * /}

                        <div xmlns="http://www.w3.org/1999/xhtml">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Sed mollis mollis mi ut ultricies. Nullam magna ipsum,
                        porta vel dui convallis, rutrum imperdiet eros. Aliquam
                        erat volutpat.
                        </div>
                    </foreignObject>
                    */
            // </svg>
          }

          {
            // --------------------- Frame photo empty ------------------------
            !isPreviewMode &&
              isEmpty &&
              (frame.type === FRAME_TYPE.PHOTO ||
                frame.type === FRAME_TYPE.OVERLAYER) && (
                <ForeignObjectUnscaled
                  editionScale={this.props.editionScale}
                  width={frame.width}
                  height={frame.height}
                >
                  <Upload
                    id={`${frame.id}_uploader`}
                    name="frameImport"
                    multiple={false}
                    accept={PROJECT_CONST.acceptedImageFileFormats}
                    customRequest={this.props.onDummyUploadRequest}
                    onChange={(info) => {
                      this.props.onUploaderChange(info, frame);
                    }}
                    showUploadList={false}
                    className="frameEmpty"
                  >
                    {/* <p>{GetText("empty.frame.photo.btn")}</p> */}
                    <p>
                      <Button
                        type="primary"
                        // shape="round"
                        size={
                          contentRect.width < emptyButtonFullyDisplaySize
                            ? 'default'
                            : 'small'
                        }
                        style={{
                          fontSize:
                            contentRect.width < emptyButtonFullyDisplaySize
                              ? 15
                              : 12,
                          position: 'unset',
                        }} // position:unset is necessary to work correclty with svg scaling and foreign object
                        icon={<PictureOutlined />}
                      >
                        {contentRect.width < emptyButtonFullyDisplaySize
                          ? ''
                          : GetText('edition.frame.photo.cta')}
                      </Button>
                      {/* this is a text <img src="https://secure.gravatar.com/avatar/8d7547e891de94ba241c7501baffb7fa?s=160&d=retro&r=pg" /><button> truc machin brol</button> */}
                    </p>
                    {/* <p className="ant-upload-hint">Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files</p> */}
                  </Upload>
                </ForeignObjectUnscaled>
              )
          }

          {
            // --------------------- Loading! ------------------------
            loading && (
              <foreignObject
                x={contentRect.x}
                y={contentRect.y}
                className="unselectable"
                height={contentRect.height}
                width={contentRect.width}
                style={{ position: 'unset' }}
              >
                <div
                  className="unselectable"
                  style={{
                    width: contentRect.width,
                    height: contentRect.height,
                    position: 'unset',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {IsSafari() ? (
                    GetText('common.loading')
                  ) : (
                    <LoadingOutlined className="loadingIcon" spin />
                  )}
                </div>
              </foreignObject>
            )
          }

          {
            // --------------------- Error! ------------------------
            error && (
              <foreignObject
                x="0"
                y="0"
                className="unselectable"
                height={contentRect.height}
                width={contentRect.width}
              >
                <div className="frameError">
                  <CloseCircleOutlined />
                  {error}
                </div>
              </foreignObject>
            )
          }

          {/* { // --------------------- Frame text empty ------------------------
                (isEmpty && frame.type === FRAME_TYPE.TEXT) &&

                <foreignObject x="0" y="0" className="unselectable"
                    height={t.height}
                    width={t.width}
                    >
                    <div className="frameEmpty"
                        >
                        <Button type="dashed"
                            // shape="round"
                            // size="small"
                            icon="font-colors"
                            >Add some Text</Button>
                    </div>
                </foreignObject>

            } */}

          {/** *******************
                TEXT
                ********************** */}
          {!isEmpty && frame.text && (
            // (frame.content.text !==null)
            <foreignObject
              x="0"
              y="0"
              className="unselectable divReset"
              height={contentRect.height}
              width={contentRect.width}
              style={
                {
                  // display:"table-cell",
                  // verticalAlign:"bottom",
                  // backgroundColor:"#ff0000"
                }
              }
            >
              <div
                className="divReset"
                style={{
                  width: contentRect.width,
                  height: contentRect.height,
                  display: 'flex',
                  justifyContent:
                    frame.text.halign === 'right'
                      ? 'flex-end'
                      : frame.text.halign,
                  alignItems: 'center',
                }}
              >
                <div
                  ref={this.frameTextDivRef}
                  // className={ isEmpty? "frameEmpty_text" : null }
                  className="divReset"
                  style={{
                    color: isEmpty ? Colors.GREY_DARK : frame.text.color,
                    fontSize: frame.text.size,
                    fontFamily: frame.text.family,
                    fontStyle: frame.text.italic ? 'italic' : 'normal',
                    fontWeight: frame.text.bold ? 'bold' : 'normal',
                    whiteSpace: 'pre-wrap', //  to keep \n
                    // pointerEvents:"none",
                    // paddingLeft:"10px",
                    // height:"100%",
                    // backgroundColor:"#fff000",
                    // width:t.width,
                    // height:t.height,

                    textAlign: frame.text.halign,
                    // display:"table-cell",

                    // VERTICAL ALIGN
                    // TODO: this breaks on safari...
                    // position:"relative",
                    // top:"50%",
                    // transform:"translateY(-50%)",
                    display: 'flex',
                    // display: isEmpty? "table-cell" : "table-cell",
                    // display: isEmpty? "table-cell" : "table-cell",

                    // padding:"auto",
                    // margin:"50% 50%",
                    // display:"flex",
                    // justifyContent: frame.text.halign,
                    // alignItems: "center",

                    // overflow:"hidden",
                    // wordWrap:"break-word"
                  }}
                >
                  {frame.text.value}
                </div>
              </div>
            </foreignObject>
          )}

          {
            // --------------------- Frame Text empty ------------------------
            // TODO: put everything for foreign object unscaled to a single container...
            !isPreviewMode && isEmpty && frame.type === FRAME_TYPE.TEXT && (
              <ForeignObjectUnscaled
                editionScale={this.props.editionScale}
                width={contentRect.width}
                height={contentRect.height}
              >
                <div
                  className="frameEmpty_text"
                  style={{ userSelect: 'none', pointerEvents: 'none' }}
                >
                  <Button
                    type="primary"
                    size={
                      contentRect.width < emptyButtonFullyDisplaySize
                        ? 'default'
                        : 'small'
                    }
                    icon={<FontSizeOutlined />}
                    style={{
                      fontSize:
                        contentRect.width < emptyButtonFullyDisplaySize
                          ? 15
                          : 12,
                      position: 'unset',
                    }}
                  >
                    {contentRect.width < emptyButtonFullyDisplaySize
                      ? ''
                      : GetText('edition.frame.text.watermark')}
                  </Button>
                </div>
              </ForeignObjectUnscaled>
            )
          }

          {/* <foreignObject x="0" y="0" className="unselectable"
                    height={t.height}
                    width={t.width}
                    style={{
                        color:"#fff000",
                        //pointerEvents:"none",
                        overflow:"hidden",
                        wordWrap:"break-word"}}>

                    <div
                        onDrop={ (e)=>{ this.handleDropOnFrame(e, i )} }
                        onDragOver={ (e)=>{ this.handleDragOverFrame(e, i )} }
                        >
                    drop area
                    </div>
                </foreignObject> */}

          {/** *******************************************
                 SVG TEXT DO NOT WRAP......

                    <text id={frame.id}
                            height={frame.height}
                            width={frame.width}
                            //x={frame.x}
                            //y={frame.y}
                            backgroundcolor="#fff000"
                            //transform={"rotate("+ frame.angle +"," + frame.width/2 +"," + frame.height/2 +")"}
                            preserveAspectRatio="none"
                            color="#ff0000"
                            fontFamily="Verdana"
                            fontSize="55"
                            onClick={(e)=> { this.onFrameSelect( e.target, frame ) ;}}
                            >
                        {frame.text.value}
                    </text>
            ******************************************* */}

          {
            // --------------------- DEBUG ------------------------
            false && ( // TODO: debugflags!
              <text>
                FRAME INDEX:
                {frameIndex} pos:
                {frame.x}
                {/* {JSON.stringify(frame)} */}
              </text>
            )
          }

          {
            // --------------------- Frame border ------------------------
            !isEmpty && frame.border !== 0 && (
              <rect
                rx={frame.borderRadius}
                ry={frame.borderRadius}
                x={borderInPixel / 2}
                y={borderInPixel / 2}
                width={frame.width - borderInPixel}
                height={frame.height - borderInPixel}
                style={{
                  pointerEvents: 'none',
                  stroke: frame.borderColor,
                  strokeWidth: borderInPixel,
                  fill: '#ff0000',
                  // fillOpacity:"0.4",
                  fillOpacity: '0',
                }}
              />
            )
          }

          {
            // --------------------- text and spine borders ------------------------
            !isForPrint &&
              !isPreviewMode &&
              (frame.type === FRAME_TYPE.SPINE ||
                frame.type === FRAME_TYPE.SPINE_NUM) && (
                <rect
                  x={contentRect.x}
                  y={contentRect.y}
                  width={contentRect.width}
                  height={contentRect.height}
                  className="frame_text_border"
                />
              )
          }

          {
            // --------------------- selectionBox ------------------------
            !isPreviewMode && (
              <rect
                x="0"
                y="0"
                width={frame.width}
                height={frame.height}
                className={`editionFrame${
                  this.state.draggingOver
                    ? '_dragOver'
                    : this.state.isOver && frameCanBeHovered(frame)
                    ? '_hover'
                    : ''
                } `}
              />
            )
          }

          {
            // --------------------- quality indicator ------------------------

            !isForPrint &&
              !isPreviewMode &&
              frame.photo &&
              imageQuality !== IMAGE_QUALITY.GOOD && (
                <ForeignObjectUnscaled
                  editionScale={this.props.editionScale}
                  // x={(frameIndex===0)?t.width-60 : t.width-30}
                  // y={(frameIndex===0)?t.height-60 : t.height-30}
                  className="unselectable unclickable"
                  height={contentRect.height}
                  width={contentRect.width}
                  style={
                    {
                      // display:"table-cell",
                      // verticalAlign:"bottom",
                      // backgroundColor:"#ff0000"
                    }
                  }
                >
                  <div className="qualityIndicator_wrapper">
                    <Tooltip
                      overlayClassName="unselectable"
                      visible={
                        this.props.isSelected && !this.state.qualityWarningHide
                      }
                      title={
                        <span
                          role="button"
                          tabIndex="0"
                          onClick={() => {
                            this.setState({ qualityWarningHide: true });
                          }}
                        >
                          {imageQuality === IMAGE_QUALITY.BAD
                            ? GetText('tooltip.frame.quality.ko')
                            : GetText('tooltip.frame.quality.low')}
                        </span>
                      }
                    >
                      <WarningTwoTone
                        className="unselectable"
                        onClick={() => {
                          this.setState({
                            qualityWarningHide: !this.state.qualityWarningHide,
                          });
                        }}
                        twoToneColor={
                          imageQuality === IMAGE_QUALITY.BAD
                            ? [Colors.YELLOW_FLASH, Colors.RED_FLASH]
                            : [Colors.BLACK, Colors.YELLOW_FLASH]
                        }
                        style={{
                          fontSize: '20px',
                          color:
                            imageQuality === IMAGE_QUALITY.BAD
                              ? Colors.RED_FLASH
                              : Colors.YELLOW_FLASH,
                        }}
                      />
                    </Tooltip>
                  </div>
                </ForeignObjectUnscaled>
              )
          }

          {/* }
                        <text //id={frame.id}
                            height={frame.transform.height}
                            width={frame.transform.width}
                            x={frame.transform.width/2}
                            y={frame.transform.height/2}
                            pointerEvents="none"
                            //backgroundcolor="#ffffff"
                            //transform={"rotate("+ frame.transform.angle +"," + w/2 +"," + h/2 +")"}
                            //preserveAspectRatio="none"
                            style={ { overflow:"hidden",
                                    textAnchor:"middle",
                                    fontSize:15,
                                    fontWeight:"bold",
                                    fontFamily:"Verdana",
                                    fill:"white",
                                    stroke:"black",
                                    strokeWidth:2}}

                            //onClick={(e)=> { this.onFrameSelect( e.target, frame ) ;}}
                        >
                        {JSON.stringify(frame)}
                </text>
                </g>
            )

    }) */}
        </g>
      </g>
    );
  }
}

// --------------------- proptypes ------------------------

// --------------------- export ------------------------

export { FrameArea };
