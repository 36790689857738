import { GetDoc } from './ProductHelper';
import { mmToPoint } from './MeasureUtils';
import { IsCanvasEditor } from '../data/config';
import { CanvasHelper } from './canvas/CanvasHelper';
import { Project } from '../data/Project';

/**
 * Retrieve the print cut border for the project
 * Cut border is in point/pixel (but in document, it is in mm)
 */
export function GetPrintCutBorderInPixel(
  project: Project,
  forCover = false
): number {
  // cut border from doc is in mm, so we need to convert it to point

  if (IsCanvasEditor())
    return mmToPoint(CanvasHelper.GetCutBorderMM(project.type));

  const doc = GetDoc(project.docID);

  if (forCover) {
    if (doc.cover && doc.cover.cutBorder) return mmToPoint(doc.cover.cutBorder);
    return mmToPoint(doc.cutBorder * 2); // if not, just double the simple cut border
  }
  return mmToPoint(doc.cutBorder);
}
