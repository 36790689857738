import { isEmpty } from 'lodash';

export function ObjectIsEmpty(obj) {
  // return !obj || Object.keys(myObject).length === 0;
  return isEmpty(obj);
}

// shortcut to loooooong has own property method
export const hasOwn = (obj, key) =>
  Object.prototype.hasOwnProperty.call(obj, key);

/**
 * Stringify complex object containing nodes or dom elements
 * this is used for events for example
 */
export function StringifyObject(object, depth = 0, max_depth = 2) {
  // change max_depth to see more levels, for a touch event, 2 is good
  if (depth > max_depth) return 'Object';

  const obj = {};
  // eslint-disable-next-line guard-for-in
  for (const key in object) {
    let value = object[key];
    if (value instanceof Node) {
      // specify which properties you want to see from the node
      value = { id: value.id };
    } else if (value instanceof Window) value = 'Window';
    else if (value instanceof Object)
      value = StringifyObject(value, depth + 1, max_depth);

    obj[key] = value;
  }

  return depth ? obj : JSON.stringify(obj);
}

export function SwapObjectKeyValue(obj) {
  const ret = {};
  Object.keys(obj).forEach((key) => {
    ret[obj[key]] = key;
  });
  return ret;
}

/**
 * use  this instead _.isEqual() --> from lodash
 */
export function isEquivalent(a, b) {
  // Create arrays of property names
  const aProps = Object.getOwnPropertyNames(a);
  const bProps = Object.getOwnPropertyNames(b);

  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length !== bProps.length) {
    return false;
  }

  for (let i = 0; i < aProps.length; i++) {
    const propName = aProps[i];

    // If values of same property are not equal,
    // objects are not equivalent
    if (a[propName] !== b[propName]) {
      return false;
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true;
}
