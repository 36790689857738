export function DistanceBetweenPoints(x1, y1, x2, y2) {
  return Math.hypot(x2 - x1, y2 - y1);
}

export function MidPointsBetweenPoints(x1, y1, x2, y2) {
  const centerPoint = {};
  centerPoint.x = (x1 + x2) / 2;
  centerPoint.y = (y1 + y2) / 2;
  return centerPoint;
}

export function ToRadians(degree) {
  return degree * (Math.PI / 180);
}

export function rotatePointAround(
  pointToRotateX,
  pointToRotateY,
  originX,
  originY,
  angleDegree
) {
  const angle = (angleDegree * Math.PI) / 180.0;
  return {
    x:
      Math.cos(angle) * (pointToRotateX - originX) -
      Math.sin(angle) * (pointToRotateY - originY) +
      originX,
    y:
      Math.sin(angle) * (pointToRotateX - originX) +
      Math.cos(angle) * (pointToRotateY - originY) +
      originY,
  };
}

/*
 *  https://stackoverflow.com/questions/38297082/get-rotated-rectangle-points-from-x-y-width-height-and-rotation

 */
export function getRectFourPoints(x, y, width, height, ang, isDeg = false) {
  if (isDeg) ang *= Math.PI / 180;

  const points = { first: { x, y } };
  const sinAng = Math.sin(ang);
  const cosAng = Math.cos(ang);

  let upDiff = sinAng * width;
  let sideDiff = cosAng * width;
  const sec = { x: x + sideDiff, y: y + upDiff };
  points.sec = sec;

  upDiff = cosAng * height;
  sideDiff = sinAng * height;
  points.third = { x: x + sideDiff, y: y - upDiff };

  const fourth = { x: sec.x + sideDiff, y: sec.y - upDiff };
  points.fourth = fourth;
  return points;
}

export function GetRotatedRectBounds(x, y, width, height, angleDeg) {
  const points = getRectFourPoints(x, y, width, height, angleDeg, true);
  const c1 = points.first;
  const c2 = points.sec;
  const c3 = points.third;
  const c4 = points.fourth;

  const bx1 = Math.min(c1.x, c2.x, c3.x, c4.x);
  const by1 = Math.min(c1.y, c2.y, c3.y, c4.y);
  const bx2 = Math.max(c1.x, c2.x, c3.x, c4.x);
  const by2 = Math.max(c1.y, c2.y, c3.y, c4.y);

  return {
    x: bx1,
    y: by1,
    width: bx2 - bx1,
    height: by2 - by1,
  };
}

export function ToDegree(radians) {
  return radians * (180 / Math.PI);
}

// Calculates angle of a right-angle triangle in radians
export function CalcAngle(adjacent, hypotenuse) {
  return Math.acos(adjacent / hypotenuse);
}

// Function to generate random number
export function RandomInt(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

export function RandomRange(min, max) {
  return Math.random() * (max - min) + min;
}

export function RandomArrayElement(arr: Array) {
  return arr[Math.floor(Math.random() * arr.length)];
}

/**
 * Snap a number to a modulo number
 * For example here if we want to snap to an angle 45 with a snap treshold of 5 we do SnapNumberTo (myAngle, 45, 5)
 * @param {*} modulo
 * @param {*} treshold
 */
export function SnapNumberToRange(myNum, moduloRange, treshold) {
  if (Math.abs((Math.abs(myNum) % moduloRange) - moduloRange) < treshold) {
    myNum = Math.round(myNum / moduloRange) * moduloRange;
  }
  return myNum;
}

export function SnapNumberTo(myNum, snapTo, treshold) {
  if (Math.abs(myNum - snapTo) < treshold) {
    myNum = snapTo;
  }
  return myNum;
}

// Calculate rotated rectangle size from known bounding box coordinates
// https://stackoverflow.com/questions/9971230/calculate-rotated-rectangle-size-from-known-bounding-box-coordinates
// Given bounding box dimensions bx by by and t being the anticlockwise rotation of rectangle sized x by y:

// x = (1/(cos(t)^2-sin(t)^2)) * (  bx * cos(t) - by * sin(t))
// y = (1/(cos(t)^2-sin(t)^2)) * (- bx * sin(t) + by * cos(t))
