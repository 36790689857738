const globalSelectors = {
  get_authentication: (state) => state.authentication,
  get_projects: (state) => state.projects,
  get_photos: (state) => state.photos,
  get_layouts: (state) => state.layouts,
  get_edition: (state) => state.edition,
  get_backgrounds: (state) => state.backgrounds,
  get_cliparts: (state) => state.cliparts,
};

export default globalSelectors;
