import { FRAME_TYPE, CreateFrame } from '../edition/frameHelper';
import { CreateDefaultImageAsset } from '../photoList/photoHelper';

/**
 *
 * @param {string} id
 * @param {number} width
 * @param {number} height
 * @returns {Clipart}
 */
export function CreateClipart(id, width, height) {
  return {
    ...CreateDefaultImageAsset(id, id),
    width: Number(width),
    height: Number(height),
    proxy: null,
  };
}

/**
 *
 * @param {IPage} page : the page that needs to be updated
 * @param {Clipart} clipartItem : the new layout to apply to this page
 */
export function AddClipartToPage(page, clipartItem, dropPoint) {
  const clipartRatio = clipartItem.width / clipartItem.height;
  const clipartWidth = page.width / 3;
  const clipartHeight = clipartWidth / clipartRatio;
  let dropPointRef = dropPoint;

  if (!dropPointRef) {
    dropPointRef = {
      x: page.width / 2,
      y: page.height / 2,
    };
  }

  const clipartFrame = CreateFrame({
    type: FRAME_TYPE.CLIPART,
    width: clipartWidth,
    height: clipartHeight,
    x: dropPointRef.x,
    y: dropPointRef.y,
    clipart: clipartItem.id,
  });

  page.frames.push(clipartFrame);
}
