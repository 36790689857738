import { Project } from '../../data/Project';
import { GetClassicCoverOptions } from '../../utils/coverHelper';

/**
 * @param {Project} project
 * @returns {ProjectOptions}
 */
export const GetProjectOptions = (project: Project) => {
  if (!project) return null;

  return {
    // common
    docID: project.docID,
    type: project.type,
    numPages: project.pageList.length,
    coated: project.coated,
    matte: project.matte,

    // album
    paper: project.paper,
    pageNumber: project.pageNumber,
    pagePaperQuality: project.pagePaperQuality,
    cover_matte: project.cover_matte,
    flyleaf: project.flyleaf,
    coverClassicOptions: GetClassicCoverOptions(project),

    // calendar only
    startYear: project.startYear,
    startMonth: project.startMonth,
    startDay: project.startDay,
    calendarColorOptions: project.calendarColorOptions,

    // card only
    cardPack: project.cardPack,
    envelope: project.envelope,

    // canvas  only
    canvasFrameColor: project.canvasFrameColor,
    canvasFormat: project.canvasFormat,
    width: project.width,
    height: project.height,
  };
};
