/// /////////////////////////////////////////////////////////////
// overriding console log
/// /////////////////////////////////////////////////////////////

const formerLog = console.log;
const formerWarn = console.warn;
const formerError = console.error;
const allLogs = [];
console.log = function (msg, ...optionalParams) {
  formerLog(msg, ...optionalParams); // maintains existing logging via the console.
  // do not store log prevstate and newstate from redux
  if (msg !== '%c prev state' && msg !== '%c next state') {
    if (msg === '%c action    ')
      allLogs.push(`[LOG] ${msg}:${optionalParams[1].type}`);
    else {
      allLogs.push(`[LOG] ${msg}`);
    }
  }
};
console.warn = function (msg, ...optionalParams) {
  formerWarn(msg); // maintains existing logging via the console.
  allLogs.push(`[WARN] ${msg}`);
};
console.error = function (msg, ...optionalParams) {
  formerError(msg, ...optionalParams); // maintains existing logging via the console.
  allLogs.push(`[ERROR] ${msg}`);
};

export function GetAllLogs(): Array<string> {
  return allLogs;
}

/// /////////////////////////////////////////////////////////////

/**
 *
 * @param {*} title
 * @param {*} text
 */
export function OpenTextInBlankWindow(content, optionalTitle) {
  try {
    const x: Window = window.open('');

    x.document.write(
      `<title>${optionalTitle || 'DebugTextContent'}</title>${content}`
    );

    // var data = "<p>This is 'myWindow'</p>";
    // myWindow = window.open("data:text/html," + encodeURIComponent(data),
    //                       "_blank", "width=200,height=100");
    // myWindow.focus();
  } catch (error) {
    alert(`popup blocked: ${error}`);
  }
}
