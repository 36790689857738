import { ENVIRONEMENT, PROJECT_CLASS } from './Constants';
import { GetCurrentLanguage } from './LanguageHelper';

// --------------------- Const ------------------------

const windowLocation = `${window.location}`;
let projectClass;
if (windowLocation.includes(PROJECT_CLASS.CALENDAR)) {
  projectClass = PROJECT_CLASS.CALENDAR;
} else if (windowLocation.includes(PROJECT_CLASS.CARD)) {
  projectClass = PROJECT_CLASS.CARD;
} else if (windowLocation.includes(PROJECT_CLASS.CANVAS)) {
  projectClass = PROJECT_CLASS.CANVAS;
} else {
  projectClass = PROJECT_CLASS.ALBUM;
}

export const PROJECT_CONST = {
  editor_type: 3, // Current editor type for html is 3
  project_class: projectClass,
  project_class_no_s: projectClass.slice(0, -1), // TODO: modify this for all products

  // url frames
  use_PROMO_URL_FRAMES: false, // TODO: change this depending on vendor (used in dateaction:[url])
  displayUrl: 'www.tictacphoto.com', // TODO: change this depending on vendor (used in dateaction:[url])

  /// /////////////////////////////////////////////////////////////
  // HTML editor build start is formated this way 3xyyzz : 3{major}{minor}{patched}
  build_start: getBackendBuildFormatted(), // old: 300000
  /// /////////////////////////////////////////////////////////////

  // List of accepted formats for the editor
  acceptedImageFileFormats: 'image/jpeg, image/jpg',
  // acceptedImageFileFormats : "image/jpeg, image/jpg, image/png, image/heic, image/heif",

  vendor: 1,
  campaign: null,
  env: `${window.location}`.includes('editor.tictacphoto.com')
    ? 'prod'
    : `${window.location}`.includes('editor2.tictacphoto.com')
    ? 'staging'
    : `${window.location}`.includes('editor2.jtlf.fr')
    ? 'staging'
    : 'dev',
  branding: `${window.location}`.includes('editor2.jtlf.fr') ? 'sude' : '',
};

/**
 * Retrieve the build formatted for backend
 * IN: 0.1.90
 * OUT: 300190
 */
function getBackendBuildFormatted() {
  const split = process.env.REACT_APP_VERSION.split('.');
  const major = `0${split[0]}`;
  const minor = `0${split[1]}`;
  const patch = `0${split[2]}`;
  return parseInt(
    `3${major.substr(major.length - 1, 1)}${minor.substr(
      minor.length - 2,
      2
    )}${patch.substr(patch.length - 2, 2)}`,
    10
  );
}

export function IsAlbumEditor() {
  return PROJECT_CONST.project_class === PROJECT_CLASS.ALBUM;
}
export function IsCalendarEditor() {
  return PROJECT_CONST.project_class === PROJECT_CLASS.CALENDAR;
}
export function IsCanvasEditor() {
  return PROJECT_CONST.project_class === PROJECT_CLASS.CANVAS;
}
export function IsCardEditor() {
  return PROJECT_CONST.project_class === PROJECT_CLASS.CARD;
}

export function GetEnvironementURL(): string {
  if (PROJECT_CONST.env === ENVIRONEMENT.PROD)
    return 'https://editor.tictacphoto.com/';
  if (PROJECT_CONST.env === ENVIRONEMENT.STAGING)
    return 'https://editor2.tictacphoto.com/';
  return 'https://editor2dev.tictacphoto.com/';
}

export const config = {
  rootUrl: process.env.PUBLIC_URL,
  baseUrl: 'https://editor.tictacphoto.com',
  apiUrl: 'https://editor.tictacphoto.com/json',
  apiUrl_XML: 'https://editor.tictacphoto.com/flex',
  getApiUrl: 'https://editorget.tictacphoto.com/json',
  thumbApiUrl: 'https://editortn.tictacphoto.com/json',
  apiPhoto: GetEnvironementURL(),
  apiPriceUrl: 'https://api.tictacphoto.com',

  secureUrl: 'https://secure.tictacphoto.com/',
  statServerUrl: 'https://www.antho.be/prod/tictac/',

  timestamp: `t=${new Date().getTime()}`,
  // defaultResponseHandler,

  forgetPasswordLink: 'http://editor.tictacphoto.com/wizard/login.php?fp',
  logoutURL: 'http://editor.tictacphoto.com/wizard/login.php?fp',

  // language
  spreadSheetID: '1Obm3dHlQRvlSeJ8SdJMnVrCKad_9mlKxekjeNTR9rGk', // Tictac
};

if (`${window.location}`.includes('editor2.jtlf.fr')) {
  config.baseUrl = 'https://editor.jtlf.fr';
  config.apiUrl = 'https://editor.jtlf.fr/json';
  config.apiUrl_XML = 'https://editor.jtlf.fr/flex';
  config.getApiUrl = 'https://editor.jtlf.fr/json';
  config.thumbApiUrl = 'https://editor.jtlf.fr/json';
  config.apiPhoto = 'https://editor2.jtlf.fr';
  config.apiPriceUrl = 'https://api.tictacphoto.com';
  config.secureUrl = 'https://secure.jtlf.fr/';
}

// --------------------- URL composer ------------------------

// TODO: find a way to compose this so everything is stored here
// TODO: also add a timestamp to be sure we are up to date?
export function GetLayoutURL() {
  // return config.baseUrl + "/" + PROJECT_CONST.project_class  + "/assets/xml/" + PROJECT_CONST.project_class  + "/layouts.xml";
  return `${config.rootUrl}/xml/${PROJECT_CONST.project_class}/layouts.xml?${config.timestamp}`; // TODO: avoid caching for now.. but we might update this later!
}

export function GetBackgroundURL() {
  // TODO : var fixedCalendarPathPath:String = (classPath === "calendars/")?"calendar/":classPath;
  // return config.apiPhoto + "/" + projectClass  + "/assets/xml/" + projectClass + "/backgrounds.xml" + config.timestamp;
  // return config.baseUrl + "/" + PROJECT_CONST.project_class  + "/assets/xml/" + PROJECT_CONST.project_class  + "/backgrounds.xml?" + config.timestamp ; // TODO: avoid caching for now.. but we might update this later!
  return `${config.baseUrl}/albums/assets/xml/${PROJECT_CONST.project_class}/backgrounds.xml?${config.timestamp}`; // TODO: avoid caching for now.. but we might update this later!
}
export function GetClipartsURL() {
  return `${config.baseUrl}/flex/loadcliparts.php?${config.timestamp}`;
}
export function GetOverlayersURL() {
  return `${config.baseUrl}/flex/loadoverlays.php?${config.timestamp}`;
}

export function GetOrderPriceURL() {
  return `${config.secureUrl}get_price.php`;
}

export function GetTextileURL() {
  return 'https://www.tictacphoto.com/fr/textiles.html';
}
export function GetFunProductURL() {
  return `https://www.tictacphoto.com/${GetCurrentLanguage()}#funproducts-.funproducts`;
}

export function GetOldFlashEditorDownloadURL() {
  const lang = GetCurrentLanguage();
  return `https://www.tictacphoto.com/${lang}/flash-download-${lang}.html`;
}

export function GetFaceMaskProductUrl() {
  switch (GetCurrentLanguage()) {
    case 'fr':
      return 'https://www.tictacphoto.com/fr/masques-buccaux.html';
    case 'nl':
      return 'https://www.tictacphoto.com/nl/mondmaskers.html';
    case 'es':
      return 'https://www.tictacphoto.com/es/mascaras-bucales.html';
    case 'de':
      return 'https://www.tictacphoto.com/de/mundmasken.html';
    default:
      return 'https://www.tictacphoto.com/en/face-masks.html';
  }
}

export function GetPrintsProductUrl() {
  switch (GetCurrentLanguage()) {
    case 'fr':
      return 'https://prints.tictacphoto.com/?language=fr';
    case 'nl':
      return 'https://prints.tictacphoto.com/?language=nl';
    case 'es':
      return 'https://prints.tictacphoto.com/?language=es';
    case 'de':
      return 'https://prints.tictacphoto.com/?language=en';
    default:
      return 'https://prints.tictacphoto.com/?language=en';
  }
}

/*
<assetPath data="assets/" />
<xmlPath data="assets/xml/" />
<imagePath data="assets/images/" />
<resourcePath data="resources/" />
<themePath data="assets/themes/" />

var documentsUrl : String = xmlPath + classPath + "documents.xml"+timeStamp;
var projectsUrl : String = xmlPath + classPath + "projects.xml"+timeStamp;
var layoutsUrl : String = xmlPath + classPath + "layouts.xml"+timeStamp;
var menuUrl : String = xmlPath + classPath + "menu.xml" +timeStamp;
var themesUrl : String = xmlPath + classPath + "themes.xml"+timeStamp;
var coversDocumentsUrl : String = xmlPath + classPath + "covers_documents.xml" + timeStamp;
var coversClassicOptionsUrl : String = xmlPath + classPath + "covers_classic_options_92305.xml" + timeStamp;
var coversClassicOptionsOnlineUrl : String = Infos.config.serverNormalUrl + "/" + classPath + "assets/xml/albums/covers_classic_options_92305.xml"+timeStamp;

var fixedPath:String = (classPath === "calendars/")?"calendar/":classPath;
var backgroundsUrl : String = Infos.config.serverNormalUrl + "/" + fixedPath + "assets/xml/"+ classPath + "backgrounds.xml"+timeStamp;
var clipartUrl : String = Infos.config.serverNormalUrl + "/flex/loadcliparts.php"+timeStamp;
var overLayerUrl : String = Infos.config.serverNormalUrl + "/flex/loadoverlays.php"+timeStamp;
var customLayoutUrl : String = Infos.config.serverNormalUrl + "/flex/custom.php?xml=custom.xml";
var customBackgroundUrl : String = Infos.config.serverNormalUrl + "/flex/custombkg.php?action=load";

// price list
var priceListUrl : String = Infos.config.serverApiUrl + "/pricing.php" + timeStamp +  "&vendor="+Infos.config.vendorId+"&build="+Infos.buildVersion;
var optionsPriceList : String = Infos.config.serverApiUrl + "/options.php" + timeStamp +  "&vendor="+Infos.config.vendorId+"&build="+Infos.buildVersion;
var canvasCustomPriceList : String = Infos.config.serverApiUrl + "/pricing.php" + timeStamp +  "&vendor="+Infos.config.vendorId+"&ccp=1"+"&build="+Infos.buildVersion;
var envelopePriceList : String = Infos.config.serverApiUrl + "/envelopes.php" + timeStamp +  "&vendor="+Infos.config.vendorId+"&build="+Infos.buildVersion;
*/

/*
<settingsEditorProtocol data="https"/>
<settingsSecureProtocol data="https"/>
<settingsEditorHost data="editor.tictacphoto.com" />
<settingsOrderHost data="secure.tictacphoto.com"/>
<settingsApiHost data="api.tictacphoto.com"/>
<settingsOrderPricePage data="get_price.php"/>
<settingsCheckJobPage data="check_job.php"/>
<settingsLoginPage data="wizard/login.php"/>
<settingsBordersPage data="flex/loadborders.php"/>
<settingsOverlaysPage data="flex/loadoverlays.php"/>
<settingsClipartsPage data="flex/loadcliparts.php"/>
<settingsBackgroundsPage data="flex/loadbackgrounds.php"/>
<settingsTextFramesPage data="flex/loadtextframe.php"/>
<settingsImageListPage data="flex/img_list.php"/>
<settingsProjectListPage data="flex/project_list.php"/>
<settingsProjectManagerPage data="myphotos/"/>
<settingsProjectSavePage data="flex/project_save.php"/>
<settingsProjectRenamePage data="flex/project_rename.php"/>
<settingsCheckSessionPage data="flex/chksess.php"/>
<settingsSavePreferencesPage data="flex/savepref.php"/>
<settingsSessionLoginPage data="flex/login.php"/>
<settingsImageUploadPage data="flex/img_upload_tmp.php"/>
<settingsImageReplacePage data="flex/img_replace.php"/>
<settingsNewRegistrationPage data="flex/register.php"/>
<settingsEditorMenus data="flex/Editor/menus.xml"/>
<settingsLoadTranslations data="flex/load_trans.php"/>
<settingsResxTranslations data="flex/PhotoForLife"/>
<settingsHTTPUploadPage data="online_image.php"/>
<settingsHTTPTextileUploadPage data="textile_image.php"/>
<settingsOrderPage data="flex/load_order.php"/>
<settingsVCVerifyPage data="flex/vcverify.php"/>
<settingsNewProjectSession data="flex/project_new.php"/>
<settingsOpenProjectSession data="flex/project_open.php"/>
<settingsCoverPreviewPage data="wizard/images/preview-cover.php"/>
<settingsVendorCode data="1"/>
<settingsVisitOnlineProductsTextiles data="https://www.tictacphoto.com/fr/textiles.html"/>
<settingsVisitOnlineProductsFun data="https://www.tictacphoto.com/fr/objets-photo.html"/>
<settingsOfflineAssetsToDownloadUrl data="https://secure.tictacphoto.com/resources/"/>
<settingsNews data="http://cdn-1.tictacphoto.com/resources/news.xml"/>
*/
