import React from 'react';
import { connect, batch } from 'react-redux';
import {
  Icon,
  Button,
  Progress,
  Radio,
  Upload,
  Tooltip,
  Popover,
  Spin,
  Modal,
  Select,
} from 'antd';

import { CheckCircleTwoTone, InboxOutlined } from '@ant-design/icons';
import type { Photo } from '../../../types/types';
import {
  photoListActions,
  photoListSelector,
} from '../../../feature/photoList/photoList';
import { AddDragData, DRAG_TYPE } from '../../../utils/DragUtils';
import {
  CreateTempPhoto,
  PHOTO_FILTER_OPTIONS,
} from '../../../feature/photoList/photoHelper';
import PhotoItem from './PhotoItem';

import {
  editionSelectors,
  editionActions,
} from '../../../feature/edition/edition';
import { GetText } from '../../../data/LanguageHelper';
import { GetUID } from '../../../utils/UID';
import { PROJECT_CONST } from '../../../data/config';

class PhotosArea extends React.Component {
  // --------------------- construtor ------------------------

  constructor(props) {
    super(props);

    this.state = {
      currentCategory: null,
      displayUnusedOnly: false,
      draggerKey: GetUID(), // this is the dragger uniq key, this allows to reset fully the dragger once the uploading system is started..
      // uploadingFileList:null,
    };

    this.draggerRef = React.createRef();
  }

  // --------------------- react ------------------------

  componentDidMount() {
    // message.info("component did mount, we will now retrieve the photo list!");
    // retreive full list of photos
    // this.props.dispatch( photoListActions.getAll() );
  }

  // --------------------- methods ------------------------

  // https://programmer.help/blogs/5cd3442a9997f.html
  // Reference link: https://www.jianshu.com/p/f356f050b3c9
  handleBeforeUpload = async (file) => {
    const newTime = performance.now();
    if (this.lastUploadTime) {
      console.log(
        `Time between imports: ${newTime - this.lastUploadTime} milliseconds.`
      );
    }
    this.lastUploadTime = performance.now();
    // //Restrict image format, size, resolution
    // const isJPG = file.type === 'image/jpeg';
    // const isJPEG = file.type === 'image/jpeg';
    // const isGIF = file.type === 'image/gif';
    // const isPNG = file.type === 'image/png';
    // if (!(isJPG || isJPEG || isGIF || isPNG)) {
    // Modal.error({
    //     title: 'Upload only JPG ,JPEG ,GIF, PNG Pictures in format~',
    // });
    // return;
    // }
    // const isLt2M = file.size / 1024 / 1024 < 2;
    // if (!isLt2M) {
    // Modal.error({
    //     title: 'More than 2 M Restrictions do not allow uploads~',
    // });
    // return;
    // }
    // return (isJPG || isJPEG || isGIF || isPNG) && isLt2M && this.checkImageWH(file);

    if (!this.importPromise) {
      this.importPromise = this.checkImageWH(file);
      Promise.resolve(this.importPromise);
    } else {
      this.importPromise.then(() => {
        Promise.resolve(this.checkImageWH(file));
      });
    }

    //     if(!this.importPromises)
    //         this.importPromises = [];

    //     if(this.importPromises.length > 0)
    //         await this.importPromises[this.importPromises.length-1];

    //     let promise = this.checkImageWH(file);
    //     this.importPromises.push(promise);
    //     return promise;  //return this.checkImageWH(file);
    //    // return false;
  };

  // Returns a promise: Resolution is returned when the detection passes; reject is returned when the failure occurs, and the picture upload is prevented.
  checkImageWH(file) {
    // TODO: thy this library instead!!
    // https://github.com/blueimp/JavaScript-Load-Image
    return new Promise((resolve, reject) => {
      const filereader = new FileReader();
      const t1 = performance.now();
      filereader.onload = (e) => {
        const src = e.target.result;
        const image = new Image();
        image.onload = function () {
          // Get the width and height of the picture and store it in the file object
          console.log(`file width :${this.width}`);
          console.log(`file height :${this.height}`);
          const t2 = performance.now();
          console.log(`import time: ${t2 - t1} milliseconds.`);
          file.width = this.width;
          file.height = this.height;
          resolve();
        };
        image.onerror = reject;
        image.src = src;
      };
      filereader.readAsDataURL(file);
    });
  }

  handleCategoryChange(newCategory) {
    this.setState({
      currentCategory: newCategory,
    });
  }

  /**
   * Update photo filter
   */
  handlePhotoFilterChange(newFilter) {
    this.props.dispatch(photoListActions.updateSortFilter(newFilter));
  }

  // _handleSubmit(e) {
  //   e.preventDefault();

  //     alert("TODO : here we upload!");

  //   // TODO: do something with -> this.state.file
  //   //console.log('handle uploading-', this.state.file);
  // }

  // _handleImageChange(e) {

  //     e.preventDefault();

  //     // save file data
  //     var files = e.target.files;
  //     this.setState({ files:files, urlList:[] }, ()=>{ this.checkRenderPreview(0); });
  //     //this.checkRenderPreview(0);

  //     /*
  //   let reader = new FileReader();
  //   let files = e.target.files;
  //   let imageUrls = []

  //   for (let index = 0; index < files.length; index++) {
  //       let f = files[index];
  //       reader.onloadend = () => {
  //             imageUrls.push(reader.result);
  //             this.setState({
  //                 files: files,
  //                 urlList: imageUrls
  //         });
  //       }
  //       reader.readAsDataURL(f)
  //   }
  //   */
  // }

  // checkRenderPreview( fileIndex )
  // {
  //     let {files, urlList} = this.state;

  //     // break
  //     if(fileIndex > files.length -1 )
  //         return;

  //     let reader = new FileReader();
  //     let f = files[fileIndex].originFileObj;
  //     reader.onloadend = (e) => {
  //         e.preventDefault();
  //         const tempPhoto = CreateTempPhoto( f, reader.result );
  //         this.props.dispatch(photoListActions.importPhotos( tempPhoto ));

  //         urlList.push(reader.result);
  //         this.setState({
  //             files: files,
  //             urlList: urlList
  //         }, ()=>{
  //             this.checkRenderPreview( fileIndex +1  );
  //         });
  //     }
  //     reader.readAsDataURL(f)
  // }

  handleDragStart = (evt, photoObj: Photo) => {
    console.log('dragstart: image id: ', photoObj.id);
    // evt.dataTransfer.setData("id", id);
    // evt.dataTransfer.setData("src", src);
    AddDragData(evt, DRAG_TYPE.PHOTO, photoObj.id);
  };

  // handleDragOver( evt, id, src )
  // {
  //     console.log("dragOver: image id: ", id);
  // }

  // handleDrop( evt, id, src )
  // {
  //     console.log("Drop: image id: ", id);
  // }

  handleAutoFillClicked() {
    // on confirm continue
    const onConfirmContinue = () => {
      this.props.dispatch(editionActions.makeAutoFill());
      // alert("let's make the auto fill");
    };
    const onConfirmCancel = () => {
      // do nothing..
    };

    // show warning modal
    Modal.confirm({
      title: GetText('popup.autoFill.warning.title'),
      content: GetText('popup.autoFill.warning.description').replace(
        /<br \/>/g,
        '\n'
      ),
      okText: GetText('common.ok'),
      cancelText: GetText('common.cancel'),
      onOk() {
        onConfirmContinue();
      },
      onCancel() {
        onConfirmCancel();
      },
    });
  }

  handleUploaderChange = (file) => false; // prevent upload using antd uploader
  // console.log(`typeOf: ${typeof file}`);
  // console.log(`handleUploaderChange: ${JSON.stringify(file)}`);

  handleDraggerChange = (info) => {
    const { status } = info.file;
    // console.log("---------------------- Files:" + this.draggerRef.current.fileList.length);
    // console.log("---------------------- Files : " +info.file.status + " - "+info.fileList.length);
    // console.log("VS:" + info.file.status +" -- "+ info.fileList.length);
    // console.log("Full info:" + JSON.stringify(info));
    // console.log(`FileInfo: ${info.file.name} : ${info.file.status}`);
    // console.log(`OriginalUrl: ${info.file.originFileObj}`);
    // message.info("File change: (" + info.file.name + "): " + status );

    // if status is done, start the import process.. (done as we don't have a real upload )
    // Warning, this works as we don't have the upload process here!
    if (info.file.status === 'done') {
      // this.setState( {uploadingFileList:info.fileList } );
      this.batchImportFiles(info.fileList);
      // let reader = new FileReader();
      // let f = files[fileIndex].originFileObj;

      // const imageURL = window.URL.createObjectURL(info.file.originFileObj);
      // const tempPhoto = CreateTempPhoto( info.file.originFileObj, imageURL );
      // //console.log("---------------------- imageURL:" + imageURL);

      // //this.props.dispatch(photoListActions.importPhotos( tempPhoto ));

      // // if using 'before upload'
      // // tempPhoto.width = info.file.width;
      // // tempPhoto.height = info.file.height;
      // // this.props.dispatch(photoListActions.importPhotos( tempPhoto ));

      // // if not
      // this.props.dispatch(photoListActions.importPhotos( tempPhoto ));

      // reader.onloadend = (e) => {
      //     e.preventDefault();
      //     const tempPhoto = CreateTempPhoto( f, reader.result );
      //     this.props.dispatch(photoListActions.importPhotos( tempPhoto ));

      //     urlList.push(reader.result);
      //     this.setState({
      //         files: files,
      //         urlList: urlList
      //     }, ()=>{
      //         this.checkRenderPreview( fileIndex +1  );
      //     });
      // }
      // reader.readAsDataURL(f)
    }

    // const files = info.fileList;
    // this.setState({
    //     files:files,
    //     urlList:[]
    // },
    // // on complete
    // ()=>{
    //     this.checkRenderPreview(0);
    // });

    /*

        const status = info.file.status;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
        */
  };

  batchImportFiles = (fileList) => {
    if (this.fileListTimeout) {
      clearTimeout(this.fileListTimeout);
    }

    // keep latest fileList in memory
    this.fileList = fileList;

    // batch import file at once to avoid a big re-rendering if we have many items (like 400 pictures!)
    this.fileListTimeout = setTimeout(() => {
      console.log(
        `----- Start Batch import of ${this.fileList.length} files ----------`
      );
      batch(() => {
        this.fileList.forEach((file) => {
          const imageURL = window.URL.createObjectURL(file.originFileObj);
          const tempPhoto = CreateTempPhoto(file.originFileObj, imageURL);
          // if not
          this.props.dispatch(photoListActions.importPhotos(tempPhoto));

          // reset dragger and fileList
          this.setState({ draggerKey: GetUID() }); // reset dragger!
          this.fileList = null;
        });
      });
    }, 100);
  };

  // SEE DOC : https://stackoverflow.com/questions/51514757/action-function-is-required-with-antd-upload-control-but-i-dont-need-it
  dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 2); // the more time we add here, the more delay we have before starting the import process (multiplied by the number of photos..) 2*500 photos => 1second to start the upload
  };

  /// ///////////////////////////////////////////////////
  // Render
  /// ///////////////////////////////////////////////////
  render() {
    const {
      photosAreLoading,
      photosCategories,
      photosByCategory,
      unusedPhotoList,
      allPhotoList,
      tempPhotosList,
      projectPhotosList,
      photoUsed,
    } = this.props;
    const { currentCategory } = this.state;
    const { Option } = Select;

    const { Dragger } = Upload;

    console.log('RENDERING PHOTO AREA NOW');

    const displayPhotoList = this.state.displayUnusedOnly
      ? unusedPhotoList
      : allPhotoList;

    const uploadingDetail =
      tempPhotosList && tempPhotosList.length > 0
        ? ` ( ${tempPhotosList.length} uploading... )`
        : '';

    return (
      <div className="tabContent photosArea">
        {
          // --------------------- Import area ------------------------ */
          <div className="importArea">
            <Dragger
              name="file"
              multiple
              className={`uploadDragger${
                allPhotoList.length === 0 ? '_noPhotos' : ''
              }`}
              key={this.state.draggerKey}
              // ref={this.draggerRef}
              accept={PROJECT_CONST.acceptedImageFileFormats}
              customRequest={this.dummyRequest}
              onChange={this.handleDraggerChange}
              // beforeUpload={this.handleUploaderChange}
              // showUploadList={true}
              // beforeUpload={this.handleBeforeUpload}
              showUploadList={false}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined
                  className={allPhotoList.length === 0 ? 'breathing' : ''}
                />
              </p>
              <p
                className={`ant-upload-text${
                  allPhotoList.length === 0 ? ' breathing' : ''
                }`}
              >
                {GetText('lefttab.photos.import.area')}
              </p>

              {/* <p className="ant-upload-hint">Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files</p> */}
            </Dragger>
          </div>
        }

        <div className="content">
          <Spin spinning={photosAreLoading}>
            {/* <div className="photosContainer"
                    // style={{
                    //         width:"100%",
                    //         display:"grid",
                    //         padding:"10px 0px",
                    //         gridTemplateColumns: "1fr 1fr",
                    //         gridTemplateRows: "auto",
                    //     }}
                    > */}
            <div>
              {/* {   // --------------------- Temp photos ------------------------
                    ( tempPhotosList && tempPhotosList.length > 0 ) &&

                    <div>
                        <h4>{tempPhotosList.length } photo(s) uploading</h4>
                        {/* <div className="photosContainer" >
                            {
                                tempPhotosList.map((tempPhoto, key) => {
                                //let photo = tempPhotosList[itemID];
                                return (

                                    <PhotoItem
                                        key={tempPhoto.id}
                                        photo={tempPhoto}
                                        used={photoUsed[tempPhoto.id]}
                                        // scrollContainer={ this.scrollContainer }
                                        onDragStart = {(e)=> this.handleDragStart(e, tempPhoto )} />

                                )
                                })
                            }
                        </div> * /}
                    </div>
                } */}

              {
                // --------------------- project photos ------------------------
                // ( displayPhotoList && displayPhotoList.length > 0 ) &&
                displayPhotoList && (
                  <div className="content">
                    {
                      // --------------------- uploading box detail ------------------------ */
                      this.props.tempPhotosList.length > 0 && (
                        <div className="uploadingBox">
                          <div className="title">
                            {`Uploading ${
                              this.props.totalUploadingItems -
                              this.props.tempPhotosList.length +
                              1
                            }/${this.props.totalUploadingItems}`}
                          </div>
                          <Progress
                            percent={this.props.uploadingPercent}
                            size="small"
                            style={{ width: '100%' }}
                          />
                          <div className="detail">
                            {this.props.tempPhotosList[0].name}
                          </div>
                        </div>
                      )
                    }

                    {/* // --------------------- Auto fill button ------------------------ */}
                    {displayPhotoList.length > 0 && (
                      <Tooltip
                        title={GetText('tooltip.folder.autoFill')}
                        placement="right"
                      >
                        <Button
                          className="autoFillButton"
                          type="primary"
                          onClick={(e) => this.handleAutoFillClicked()}
                        >
                          {GetText('edition.toolbar.btn.autofill.label')}
                        </Button>
                      </Tooltip>
                    )}

                    {/* <Radio.Group onChange={onChange} defaultValue="a" size="small"> */}
                    {/* <Radio.Group defaultValue="a" size="small" style={{width:"100%", fontSize:"60%"}}>
                            {/* <Radio.Button value="a"> ({allPhotoList.length })</Radio.Button> * /}
                            <Radio.Button value="b">Unused only ({this.props.UnUsedPhotoNum})</Radio.Button>
                        </Radio.Group> */}

                    {/* <h4>{allPhotoList.length } photo(s) {uploadingDetail} */}
                    {/* <div style={{ width:"100%", textAlign:"right"}}><Switch checkedChildren={`display ${this.props.unusedPhotoList.length} unused only`} unCheckedChildren={`display all ${allPhotoList.length } photo(s)`} checked={!this.state.displayUnusedOnly} onChange={()=>{this.setState({displayUnusedOnly: !this.state.displayUnusedOnly})}} /></div> */}

                    {
                      // --------------------- Filter bar ------------------------
                      allPhotoList.length > 0 && (
                        <div className="filterBar">
                          {this.state.displayUnusedOnly
                            ? unusedPhotoList.length
                            : allPhotoList.length}{' '}
                          {GetText('common.photos')}
                          <span className="spacer" />
                          <Popover
                            placement="right"
                            trigger="click"
                            content={
                              <Radio.Group
                                value={this.props.photoFilter}
                                onChange={(e) =>
                                  this.handlePhotoFilterChange(e.target.value)
                                }
                              >
                                {Object.keys(PHOTO_FILTER_OPTIONS).map(
                                  (filterOption, index, arr) => {
                                    const radioStyle = {
                                      display: 'block',
                                      height: '30px',
                                      lineHeight: '30px',
                                    };

                                    return (
                                      <Radio
                                        key={filterOption}
                                        style={radioStyle}
                                        value={filterOption}
                                      >
                                        {GetText(filterOption)}
                                      </Radio>
                                    );
                                  }
                                )}
                              </Radio.Group>
                              // )} ><Button size="small">{GetText(this.props.photoFilter)}</Button></Popover>
                            }
                          >
                            <Button size="small">
                              {GetText('photoarea.sort.btn')}
                            </Button>
                          </Popover>
                          {/* <Switch checkedChildren={`display ${this.props.unusedPhotoList.length} unused only`} unCheckedChildren={`display all ${allPhotoList.length } photo(s)`} checked={!this.state.displayUnusedOnly} onChange={()=>{this.setState({displayUnusedOnly: !this.state.displayUnusedOnly})}} /> */}
                          <span className="spacer" />
                          <Tooltip
                            title={
                              !this.state.displayUnusedOnly
                                ? GetText(
                                    'lefttab.photos.unused.tooltip'
                                  ).replace('{NUM}', unusedPhotoList.length)
                                : GetText('lefttab.photos.all.tooltip').replace(
                                    '{NUM}',
                                    allPhotoList.length
                                  )
                            }
                          >
                            <CheckCircleTwoTone
                              twoToneColor={
                                this.state.displayUnusedOnly
                                  ? '#52c41a'
                                  : '#cccccc'
                              }
                              onClick={() => {
                                this.setState({
                                  displayUnusedOnly:
                                    !this.state.displayUnusedOnly,
                                });
                              }}
                            />
                          </Tooltip>
                          {/* <Switch checkedChildren={`display ${this.props.unusedPhotoList.length} unused only`} unCheckedChildren={`display all ${allPhotoList.length } photo(s)`} checked={!this.state.displayUnusedOnly} onChange={()=>{this.setState({displayUnusedOnly: !this.state.displayUnusedOnly})}} /> */}
                        </div>
                      )
                    }

                    {/* </h4>
                        {
                            DebugFlags.DEBUGGING &&
                            <h5>unused:{this.props.UnUsedPhotoNum}</h5>
                        } */}

                    {
                      // --------------------- photo container ------------------------
                      <div className="photosContainer">
                        {displayPhotoList.map((photo, key) => (
                          <PhotoItem
                            key={photo.id}
                            photo={photo}
                            used={photoUsed[photo.id]}
                            dispatch={this.props.dispatch}
                            // scrollContainer={ this.scrollContainer }
                            onDragStart={(e) => this.handleDragStart(e, photo)}
                          />
                        ))}
                      </div>
                    }
                  </div>
                )
              }

              {/* {  // --------------------- Temp photos ------------------------
                    ( tempPhotosList ) &&
                        tempPhotosList.map((tempPhoto, key) => {

                            //let photo = tempPhotosList[itemID];
                            return (

                                <PhotoItem
                                    key={tempPhoto.id}
                                    photo={tempPhoto}
                                    // scrollContainer={ this.scrollContainer }
                                    onDragStart = {(e)=> this.handleDragStart(e, tempPhoto )} />

                            )
                        })
                }      */}

              {/* { (DebugFlags.PHOTOAREA_SHOW_SELECTBOX) &&

                        <Select defaultValue={currentCategory}
                            placeholder="select a category"
                            onChange={ (value)=>{ this.handleCategoryChange(value); } }
                            className="filterSelect"

                            style={{ width: "100%" }} >
                            {
                                photosCategories.map( ( category, index )=>{
                                return (<Option key={category} value={category}>{category}</Option>)
                                })
                            }
                        </Select>

                        {   ( photosByCategory && currentCategory ) &&

                            <div>
                                {/ * <h4>Photos: {tempPhotosList.length - 1 }</h4> * /}
                                <div className="photosContainer" >
                                    {
                                        (photosByCategory && photosByCategory[currentCategory]) &&
                                        photosByCategory[currentCategory].map(( photo:Photo, key) => {

                                        return (

                                            <PhotoItem
                                                key={ photo.id }
                                                photo={ photo }
                                                // scrollContainer={ this.scrollContainer }
                                                onDragStart = { this.handleDragStart } />

                                        )
                                        })
                                    }
                                </div>
                            </div>
                        }
                    } */}
            </div>
          </Spin>
        </div>

        {/*
                <form onSubmit={(e)=>this._handleSubmit(e)}>
                    <Button className="fileInput"
                        type="upload"
                        onChange={(e)=>this._handleImageChange(e)} multiple />
                    <button styleclassName="submitButton"
                        type="submit"
                        onClick={(e)=>this._handleSubmit(e)}>Upload Image</button>
                    </form>
                    <div className="imgPreview">
                    {$imagePreview}
                    </div>
                    */}
      </div>
    );
  }
}

// --------------------- redux ------------------------

// Redux map
function mapStateToProps(state) {
  const { photos } = state;

  // we need user and projects
  return {
    photosAreLoading: photos.isLoading,
    photosByID: photoListSelector.getAllPhotosByID(state),
    projectPhotosList: photoListSelector.getProjectPhotosList(state),
    tempPhotosList: photoListSelector.getTempPhotosList(state),
    allPhotoList: photoListSelector.getAllPhotoList(state),
    photosByCategory: photoListSelector.getPhotosByCategories(state),
    photosCategories: photoListSelector.getPhotoCategories(state),
    photoFilter: photoListSelector.sortFilterSelector(state),
    photoUsed: editionSelectors.GetPhotoUsedSelector(state),
    unusedPhotoList: editionSelectors.GetAllUnusedPhotosSelector(state),
    uploadingPercent: photoListSelector.getUploadingPercentSelector(state),
    totalUploadingItems:
      photoListSelector.getTotalUploadingItemsSelector(state),
    // UnUsedPhotoNum: editionSelectors.GetAllUnusedPhotosSelector(state).length,
  };
}

// --------------------- export ------------------------

// Redux connect
const reduxConnected = connect(mapStateToProps)(PhotosArea);
export { reduxConnected as PhotosArea };
