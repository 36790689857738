// $(window).bind('keydown', function(event) {
//   window.addEventListener(

export const KEYBOARD_ACTIONS = {
  ESCAPE: 'ESCAPE',
  SAVE: 'SAVE',
  COPY: 'COPY',
  PASTE: 'PASTE',
  UNDO: 'UNDO',
  REDO: 'REDO',
};

export function initializeKeyboard(keyboardActionCallback: () => string) {
  // nofity function
  const notify = (event, action) => {
    // alert("KEYBOARD_ACTION:" + action);

    if (keyboardActionCallback) keyboardActionCallback(action);
  };

  // listener
  window.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      notify(event, KEYBOARD_ACTIONS.ESCAPE);
      return;
    }

    // case ctrl is clicked + another key
    if (event.ctrlKey || event.metaKey) {
      switch (String.fromCharCode(event.which).toLowerCase()) {
        case 's':
          event.preventDefault();
          notify(event, KEYBOARD_ACTIONS.SAVE);
          break;

        // case 'f':
        //     event.preventDefault();
        //     alert('ctrl-f');
        //     break;

        // case 'g':
        //       event.preventDefault();
        //       alert('ctrl-g');
        //       break;

        case 'c':
          notify(event, KEYBOARD_ACTIONS.COPY);
          break;

        case 'z':
          event.preventDefault();
          notify(event, KEYBOARD_ACTIONS.UNDO);
          break;

        case 'v':
          notify(event, KEYBOARD_ACTIONS.PASTE);
          break;

        case 'y':
          event.preventDefault();
          notify(event, KEYBOARD_ACTIONS.REDO);
          break;
      }
    }
  });
}
