export const CATALOGUE_CALENDAR = {
  // // --------------------- WALL CALENDARS ------------------------

  // WCAL  : wall calendar --PORTRAIT-- (250x330) -- (25 cm * 32 cm)
  // WCAL2  : wall calendar XL --LANDSCAPE-- (325x245)  -- (32,5 cm * 49 cm)
  // WCAL3 : wall calendar XXL --LANDSCAPE-- (490x320) -- (49 cm * 64 cm)
  // WCAL4 : Birthday wall calendar --PORTRAIT-- (250x330) -- (25 cm * 32 cm)
  // WCAL5 : Luxury wall calendar A3 --PORTRAIT-- (297x420) -- (29,7 cm * 42 cm)
  // WCAL6 : Luxury wall calendar A4 --PORTRAIT-- (297x420) -- (21 cm * 29,7 cm)

  // // --------------------- DESK CALENDARS ------------------------

  // DCAL  : Panorama desk calendar --LANDSCAPE-- (330x110) -- (32 cm * 11 cm)
  // DCAL2  : desk calendar A5 --LANDSCAPE-- (210x148) -- (21 cm * 14,8 cm)

  // // --------------------- MAGNET CALENDARS ------------------------

  // MCAL1 : magnet calendar A3 --PORTRAIT-- (297x420) -- (29,7 cm * 42 cm)
  // MCAL2 : magnet calendar A4 --PORTRAIT-- (297x420) -- (21 cm * 29,7 cm)
  // MCAL3 : magnet calendar A5 --PORTRAIT-- (297x420) -- (14,8 cm * 21 cm)

  // // --------------------- ORGNANIZERS ------------------------

  // OCAL1  : organizer/diary A4 --PORTRAIT-- (297x420) -- (21 cm * 29,7 cm)
  // OCAL2  : organizer/diary A5 --PORTRAIT-- (297x420) -- (14,8 cm * 21 cm)
  // OCAL3  : organizer/diary A6 --PORTRAIT-- (297x420) -- (10,5 cm * 14,8 cm)

  // // --------------------- PORTRAIT CALENDARS ------------------------

  // PCAL1   : portrait calendar A5 --LANDSCAPE-- (297x420) -- (14,8 cm * 21 cm)
  // PCAL2   : portrait calendar A6 --LANDSCAPE-- (297x420) -- (10,5 cm * 14,8 cm)

  products: {
    wall: {
      docs: ['WCAL', 'WCAL2', 'WCAL3', 'WCAL4', 'WCAL5', 'WCAL6'],
      default: 'WCAL3',
    },
    desk: {
      docs: ['DCAL', 'DCAL2', 'PCAL1', 'PCAL2'],
      default: 'DCAL',
    },
    magnet: {
      docs: ['MCAL1', 'MCAL2', 'MCAL3'],
      default: 'MCAL2',
    },
    // "organizer" :
    // {
    //   "docs" : [ "OCAL2"], //, "OCAL1", "OCAL2" , "OCAL3" ],
    //   "default" : "OCAL2",
    // },
    // "portrait" :
    // {
    //   "docs" : [ "PCAL1", "PCAL2" ],
    //   "default" : "PCAL2",
    // },
  },

  upgrades: [
    // [ "WCAL2","WCAL3"], // wall cal

    ['WCAL5', 'WCAL6'], // luxuary wall

    ['MCAL1', 'MCAL2', 'MCAL3'], // magnet cals

    // [ "DCAL","DCAL2"], // desk cal

    ['PCAL1', 'PCAL2'], // portrait calendars

    // [ "OCAL1","OCAL2","OCAL3"], // organizer
  ],

  docs: {
    //   <node id="types_calendars_wall_xxl" docidstr="WCAL3" size="49 cm * 64 cm">
    //   <page_coating enabled="true" defaultValue="false,//
    // <node id="types_calendars_wall_xl" docidstr="WCAL2" size="32,5 cm * 49 cm">
    //   <page_coating enabled="true" defaultValue="false,//
    // <node id="types_calendars_wall_a3" docidstr="WCAL5" size="29,7 cm * 42 cm">
    //   <page_coating enabled="false" defaultValue="true,//
    // <node id="types_calendars_wall_a4" docidstr="WCAL6" size="21 cm * 29,7 cm">
    //   <page_coating enabled="false" defaultValue="true,//
    // <node id="types_calendars_wall_wall" docidstr="WCAL" size="25 cm * 32 cm">
    //   <page_coating enabled="true" defaultValue="false,//
    // <node id="types_calendars_wall_birthday" docidstr="WCAL4" size="25 cm * 32 cm">
    //   <page_coating enabled="true" defaultValue="false,//
    // <node id="types_calendars_desk" docidstr="DCAL" size="32 cm * 11 cm">
    //   <page_coating enabled="true" defaultValue="false,//
    // <node id="types_calendars_desk_a5" docidstr="DCAL2" size="21 cm * 14,8 cm">
    //   <page_coating enabled="true" defaultValue="false,//
    // <node id="types_calendars_portrait_a5" docidstr="PCAL1" size="14,8 cm * 21 cm">
    //   <page_coating enabled="true" defaultValue="false,//
    // <node id="types_calendars_portrait_a6" docidstr="PCAL2" size="10,5 cm * 14,8 cm">
    //   <page_coating enabled="true" defaultValue="false,//
    // <node id="types_calendars_magnet_a3" docidstr="MCAL1" size="29,7 cm * 42 cm">
    //   <page_coating enabled="false" defaultValue="false,//
    // <node id="types_calendars_magnet_a4" docidstr="MCAL2" size="21 cm * 29,7 cm">
    //   <page_coating enabled="false" defaultValue="false,//
    // <node id="types_calendars_magnet_a5" docidstr="MCAL3" size="14,8 cm * 21 cm">
    //   <page_coating enabled="false" defaultValue="false,//
    // <node id="types_calendars_organizer_a4" docidstr="OCAL1" size="21 cm * 29,7 cm">
    //   <page_coating enabled="false" defaultValue="false,//
    // <node id="types_calendars_organizer_a5" docidstr="OCAL2" size="14,8 cm * 21 cm">
    //   <page_coating enabled="false" defaultValue="false,//
    // <node id="types_calendars_organizer_a6" docidstr="OCAL3" size="10,5 cm * 14,8 cm">
    //   <page_coating enabled="false" defaultValue="false,//

    // --------------------- WCAL ------------------------

    // Wall calendar (portrait)
    WCAL: {
      code: 14,
      width: 250,
      height: 330,
      numPages: 13,
      pages_per_group: 1,
      pages_group_orientation: 'horizontal',

      multiplier: 2.777952,
      cutBorder: 12.5,

      coating: true,
      coating_default: false,

      layouts_default: [
        542, 544, 552, 544, 544, 543, 544, 547, 544, 544, 546, 544, 544,
      ],
      layouts_cover: [542],
      layouts_all: [
        542, 543, 544, 545, 546, 547, 548, 549, 550, 551, 552, 553, 554,
      ],
    },

    // Wall calendar xl (landscape)
    WCAL2: {
      code: 16,
      width: 325,
      height: 245,
      numPages: 25,
      pages_per_group: 2,
      pages_group_orientation: 'horizontal',

      multiplier: 2.834645,
      cutBorder: 12.5,

      coating: true,
      coating_default: false,

      layouts_default: [
        701, 702, 700, 703, 700, 704, 700, 705, 700, 706, 700, 714, 700, 715,
        700, 716, 700, 717, 700, 718, 700, 719, 700, 720, 700,
      ],
      layouts_cover: [701],
      layouts_all: [
        700, 701, 702, 703, 704, 705, 706, 714, 715, 716, 717, 718, 719, 720,
        721, 722, 723, 724, 725, 726, 727, 728, 729, 730, 731, 732, 739, 741,
        742, 743, 744, 745,
      ],
    },

    // WCAL3 : wall calendar XXL --LANDSCAPE-- (490x320) -- (49 cm * 64 cm)
    WCAL3: {
      code: 17,
      width: 490,
      height: 320,
      numPages: 25,
      pages_per_group: 2,
      pages_group_orientation: 'horizontal',

      multiplier: 2.834645,
      cutBorder: 12.5,

      coating: true,
      coating_default: false,

      layouts_default: [
        801, 839, 800, 803, 800, 804, 800, 805, 800, 806, 800, 814, 800, 815,
        800, 816, 800, 817, 800, 818, 800, 819, 800, 820, 800,
      ],
      layouts_cover: [801],
      layouts_all: [
        800, 801, 802, 803, 804, 805, 806, 814, 815, 816, 817, 818, 819, 820,
        821, 822, 823, 824, 825, 826, 827, 828, 829, 830, 831, 832, 839, 841,
        842, 843, 844, 845,
      ],
    },

    // WCAL4 : Birthday wall calendar --PORTRAIT-- (250x330) -- (25 cm * 32 cm)
    WCAL4: {
      code: 18,
      width: 250,
      height: 330,
      numPages: 13,
      pages_per_group: 1,
      pages_group_orientation: 'horizontal',

      multiplier: 2.777952,
      cutBorder: 12.5,

      coating: true,
      coating_default: false,

      layouts_default: [
        572, 574, 582, 574, 574, 573, 574, 577, 574, 574, 576, 574, 574,
      ],
      layouts_cover: [572],
      layouts_all: [
        572, 573, 574, 575, 576, 577, 578, 579, 580, 581, 582, 583, 584,
      ],
    },

    // WCAL5 : Luxury wall calendar A3 --PORTRAIT-- (297x420) -- (29,7 cm * 42 cm)
    WCAL5: {
      code: 19,
      width: 297,
      height: 420,
      numPages: 13,
      pages_per_group: 1,
      pages_group_orientation: 'horizontal',

      multiplier: 2.834645,
      cutBorder: 12.5,

      coating: false,
      coating_default: false,

      layouts_default: [
        900, 901, 901, 901, 901, 901, 901, 901, 901, 901, 901, 901, 901,
      ],
      layouts_cover: [900],
      layouts_all: [900, 901, 902, 903, 904, 905, 906, 907, 908, 909, 910],
    },

    // WCAL6 : Luxury wall calendar A4 --PORTRAIT-- (297x420) -- (21 cm * 29,7 cm)
    WCAL6: {
      code: 21,
      width: 297,
      height: 420,
      numPages: 13,
      pages_per_group: 1,
      pages_group_orientation: 'horizontal',

      multiplier: 2.0045,
      cutBorder: 12.5,

      coating: false,
      coating_default: false,

      layouts_default: [
        900, 901, 901, 901, 901, 901, 901, 901, 901, 901, 901, 901, 901,
      ],
      layouts_cover: [900],
      layouts_all: [900, 901, 902, 903, 904, 905, 906, 907, 908, 909, 910],
      layoutTextScaling: 0.7,
    },

    // // --------------------- DESK CALENDARS ------------------------

    // DCAL  : Panorama desk calendar --LANDSCAPE-- (330x110) -- (32 cm * 11 cm)
    DCAL: {
      code: 15,
      width: 330,
      height: 110,
      numPages: 13,
      pages_per_group: 1,
      pages_group_orientation: 'horizontal',

      multiplier: 2.75725,
      cutBorder: 12.5,

      coating: true,
      coating_default: false,

      layouts_default: [
        760, 761, 761, 761, 761, 761, 761, 761, 761, 761, 761, 761, 761,
      ],
      layouts_cover: [760],
      layouts_all: [
        760, 761, 761, 761, 761, 761, 761, 761, 761, 761, 761, 761, 761,
      ],
    },

    // DCAL2  : desk calendar A5 --LANDSCAPE-- (210x148) -- (21 cm * 14,8 cm)
    DCAL2: {
      code: 20,
      width: 210,
      height: 148,
      numPages: 13,
      pages_per_group: 1,
      pages_group_orientation: 'horizontal',

      multiplier: 2.834645,
      cutBorder: 12.5,

      coating: true,
      coating_default: false,

      layouts_default: [
        950, 952, 952, 952, 952, 952, 952, 952, 952, 952, 952, 952, 952,
      ],
      layouts_cover: [950],
      layouts_all: [950, 951, 952, 953, 954, 955, 956, 957, 958, 959, 960, 961],
    },

    // // --------------------- MAGNET CALENDARS ------------------------

    // MCAL1 : magnet calendar A3 --PORTRAIT-- (297x420) -- (29,7 cm * 42 cm)
    MCAL1: {
      code: 30,
      width: 297,
      height: 420,
      numPages: 1,
      pages_per_group: 1,
      pages_group_orientation: 'horizontal',

      multiplier: 2.834645,
      cutBorder: 12.5,

      coating: false,
      coating_default: false,

      layouts_default: [930],
      layouts_cover: [930, 931, 932],
      layouts_all: [930, 931, 932],
      layoutTextScaling: 2,
    },

    // MCAL2 : magnet calendar A4 --PORTRAIT-- (297x420) -- (21 cm * 29,7 cm)
    MCAL2: {
      code: 31,
      width: 297,
      height: 420,
      numPages: 1,
      pages_per_group: 1,
      pages_group_orientation: 'horizontal',

      multiplier: 2.0045,
      cutBorder: 12.5,

      coating: false,
      coating_default: false,

      layouts_default: [930],
      layouts_cover: [930, 931, 932],
      layouts_all: [930, 931, 932],
      layoutTextScaling: 1.4,
    },

    // MCAL3 : magnet calendar A5 --PORTRAIT-- (297x420) -- (14,8 cm * 21 cm)
    MCAL3: {
      code: 32,
      width: 297,
      height: 420,
      numPages: 1,
      pages_per_group: 1,
      pages_group_orientation: 'horizontal',

      multiplier: 1.417323,
      cutBorder: 12.5,

      coating: false,
      coating_default: false,

      layouts_default: [930],
      layouts_cover: [930, 931, 932],
      layouts_all: [930, 931, 932],
      layoutTextScaling: 1,
    },

    // // --------------------- ORGNANIZERS ------------------------

    // OCAL1  : organizer/diary A4 --PORTRAIT-- (297x420) -- (21 cm * 29,7 cm)
    OCAL2: {
      code: 33,
      width: 297,
      height: 420,
      numPages: 125,
      pages_per_group: 2,
      pages_group_orientation: 'horizontal',

      multiplier: 2.004377,
      cutBorder: 12.5,

      coating: false,
      coating_default: false,

      layouts_default: [
        // cover
        986,
        // blank pages
        985,
        // year summary
        980,
        // notes pages
        984, 983, 984,
        // weeks pages
        981, 982, 981, 982, 981, 982, 981, 982, 981, 982, 981, 982, 981, 982,
        981, 982, 981, 982, 981, 982, 981, 982, 981, 982, 981, 982, 981, 982,
        981, 982, 981, 982, 981, 982, 981, 982, 981, 982, 981, 982, 981, 982,
        981, 982, 981, 982, 981, 982, 981, 982, 981, 982, 981, 982, 981, 982,
        981, 982, 981, 982, 981, 982, 981, 982, 981, 982, 981, 982, 981, 982,
        981, 982, 981, 982, 981, 982, 981, 982, 981, 982, 981, 982, 981, 982,
        981, 982, 981, 982, 981, 982, 981, 982, 981, 982, 981, 982, 981, 982,
        981, 982, 981, 982, 981, 982, 981, 982, 981, 982, 981, 982, 981, 982,
        // notes pages
        983, 983, 983,
        // photos
        985, 985, 987,
      ],
      layouts_cover: [986],
      layouts_all: [986, 980, 981, 982, 983, 984, 985, 987],
    },

    // OCAL2  : organizer/diary A5 --PORTRAIT-- (297x420) -- (14,8 cm * 21 cm)
    // OCAL3  : organizer/diary A6 --PORTRAIT-- (297x420) -- (10,5 cm * 14,8 cm)

    // // --------------------- PORTRAIT CALENDARS ------------------------

    // PCAL1   : portrait calendar A5 --LANDSCAPE-- (297x420) -- (14,8 cm * 21 cm)
    PCAL1: {
      code: 36,
      width: 297,
      height: 420,
      numPages: 13,
      pages_per_group: 1,
      pages_group_orientation: 'horizontal',

      multiplier: 1.417323,
      cutBorder: 12.5,

      coating: true,
      coating_default: false,

      layouts_default: [
        950, 1001, 1001, 1001, 1001, 1001, 1001, 1001, 1001, 1001, 1001, 1001,
        1001,
      ],
      layouts_cover: [950],
      layouts_all: [
        950, 1001, 1002, 1003, 1004, 1005, 1006, 1007, 1008, 1009, 1010, 1011,
        1012,
      ],
    },

    // PCAL2   : portrait calendar A6 --LANDSCAPE-- (297x420) -- (10,5 cm * 14,8 cm)
    PCAL2: {
      code: 37,
      width: 297,
      height: 420,
      numPages: 13,
      pages_per_group: 1,
      pages_group_orientation: 'horizontal',

      multiplier: 1.002189,
      cutBorder: 12.5,

      coating: true,
      coating_default: false,

      layouts_default: [
        950, 1001, 1001, 1001, 1001, 1001, 1001, 1001, 1001, 1001, 1001, 1001,
        1001,
      ],
      layouts_cover: [950],
      layouts_all: [
        950, 1001, 1002, 1003, 1004, 1005, 1006, 1007, 1008, 1009, 1010, 1011,
        1012,
      ],
      layoutTextScaling: 0.7,
    },
  },
};
