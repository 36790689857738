import React from 'react';
import { createReducer, createSelector } from '@reduxjs/toolkit';
import { Modal } from 'antd';
import { batch } from 'react-redux';
import { API } from '../../utils/API';
import { DebugFlags } from '../../debug/DebugFlags';
import { editionActions } from '../edition/edition';
import { ORDER_SUFFIX, OrderHelper } from './orderHelper';
import { GetText } from '../../data/LanguageHelper';
import { verifyProjectBeforeOrder } from '../../utils/projectHelper';
import { HasCustomCover } from '../../utils/coverHelper';
import { FrameExporter } from './frameExporter';
import { popupHelper } from '../alert/popupHelper';
import { UIActions } from '../ui/ui';
import { Analytic } from '../../App/Analytic';
import { authSelectors } from '../auth/authentification';
import { GetProjectCode } from '../../utils/ProductHelper';
import { Project } from '../../data/Project';

/** **********************************
// ACTIONS TYPES
************************************ */

// LIST
const ORDER_RESET = 'ORDER/RESET';
const ORDER_START = 'ORDER/START';
const JOBID_SUCCESS = 'ORDER/JOBID/SUCCESS';
const ORDER_SUCCESS = 'ORDER/SUCCESS';
const ORDER_ERROR = 'ORDER/ERROR';

/** **********************************
// REDUCERS
************************************ */

const initialState = {
  orderString: null, // the order string
  orderJobID: null, // the current order job ID
  orderJobPrice: 0, // the current order retrieved price
  orderJobVerified: false, // was the job verified by backend?

  isUploadingFiles: false, // is currently uploading a file
  isLoading: false, // is currently loading list

  error: null, // current error message
};

const reducer = createReducer(initialState, {
  [ORDER_RESET]: (state, action) => {
    state.orderString = null;
    state.orderJobID = null;
    state.orderJobPrice = 0;
    state.isLoading = false;
    state.isUploadingFiles = false;
    state.error = null;
  },

  // --------------------- ORDER ------------------------

  [ORDER_START]: (state, action) => {
    state.isLoading = true;
  },
  [ORDER_SUCCESS]: (state, action) => {
    state.isLoading = false;
  },
  [ORDER_ERROR]: (state, action) => {
    state.isLoading = false;
    state.error = action.error;
  },
  [JOBID_SUCCESS]: (state, action) => {
    state.orderJobID = action.jobID;
    state.orderJobPrice = action.jobPrice;
  },
});

/** **********************************
// internal helpers
************************************ */

/** **********************************
// SIMPLE ACTIONS (creator)
************************************ */

// function sort (sortFilter){
//   return { type: SORT, sortFilter };
// }

/** **********************************
// COMPLEX ASYNC ACTIONS
************************************ */

function resetOrder() {
  return { type: ORDER_RESET };
}

// -> Step1 we save project if it was not saved
// -> step 1 is to get JobID and price
// -> once we have job, we upload all possible "custom" frames to backend.
// -> the we generate the order string and send it to backend
// -> then we verify the job did complete correctly
// -> then we redirect
function startOrder() {
  Analytic.TrackPage('/order/start');
  Analytic.TrackCustomEvent('order', 'start_order');

  return (dispatch, getState) => {
    const { project } = getState().edition;
    const vendorID = authSelectors.GetVendorID(getState());

    // verify project first
    const orderError = verifyProjectBeforeOrder(project);
    if (orderError) {
      Modal.error({
        title: 'Project cannot be ordered',
        content: `This project had an issue during ordering: ${orderError}`,
      });
      Analytic.TrackPage('/order/error');
      Analytic.TrackCustomEvent('order', `error:${orderError}`);
      return;
    }

    // start
    dispatch(onOrderStart());
    API.getProjectPriceServerSide(project, vendorID, true)
      .then(
        (response) => {
          // error in response
          if (response.indexOf('*') === 0 || response === '-1') {
            dispatch(handleOrderError(`Server Error:${response}`));
            return;
          }

          // try to split to get jobID
          const result: Array = response.split('|', 2);
          if (result.length > 1) {
            let jobId = result[0];
            const jobPrice = result[1];

            // jobId is replaced totaly if we are working with PDF generation (as this is for testing)
            if (DebugFlags.ORDER_TO_PDF) {
              jobId = DebugFlags.PDF_JOB_ID;
            }

            dispatch(handleCheckJobIDResult(jobId, jobPrice));
            dispatch(sendOrder(project, jobId));
          } else {
            dispatch(handleOrderError(`Parse Error:${response}`));
          }
        },
        (error) => {
          dispatch(handleOrderError(error));
        }
      )
      .catch((reason) => {
        dispatch(handleOrderError(reason));
      });
  };

  // simple creator

  function handleCheckJobIDResult(jobID, jobPrice) {
    return { type: JOBID_SUCCESS, jobID, jobPrice };
  }
}

function onOrderStart() {
  return (dispatch) => {
    batch(() => {
      dispatch(UIActions.ShowLoading(GetText('loading.order.generate.start')));
      dispatch({ type: ORDER_START });
    });
  };
}

function onOrderFail(error) {
  return (dispatch) => {
    batch(() => {
      dispatch(UIActions.UpdateMainLoading(false));
      dispatch({ type: ORDER_ERROR, error });
      DebugFlags.ORDER_TO_PDF = false; // reset debug flag
    });
  };
}

function onOrderSuccess() {
  return (dispatch) => {
    Analytic.TrackPage('/order/completed');
    Analytic.TrackCustomEvent('order', 'order_success');

    batch(() => {
      dispatch(UIActions.UpdateMainLoading(false));
      dispatch({ type: ORDER_SUCCESS });
      DebugFlags.ORDER_TO_PDF = false; // reset debug flag
    });
  };
}

function sendOrder(
  project: Project,
  jobID: string,
  orderSuffix = ORDER_SUFFIX.ALBUM
) {
  // OrderHelper.log("SendOrder : " + jobID );

  return (dispatch, getState) => {
    // export project frames
    const preOrderExportPromise =
      orderSuffix !== ORDER_SUFFIX.ALBUM
        ? new Promise((resolve) => {
            resolve();
          })
        : FrameExporter.ExportProjectFrames(dispatch);

    // then send order
    preOrderExportPromise
      .then(() => {
        let orderString = '';

        // create order string
        orderString = OrderHelper.writeOrder(getState(), orderSuffix);

        // use a debug string
        if (DebugFlags.PRINT_DEMO) {
          orderString = OrderHelper.getDemoOrderString();
        }

        // alert("WriteOrder:"+orderString);
        console.log(`OrderString:${orderString}`);

        if (DebugFlags.ORDER_TO_PDF) {
          dispatch(
            UIActions.ShowLoading(GetText('loading.order.pdf.generate'))
          );

          // change order suffix to cover if we print to pdf only the cover!
          if (DebugFlags.PDF_PRINT_SPECIFIC_PAGES === '0') {
            orderSuffix = ORDER_SUFFIX.COVER;
          }

          API.printToPDF(
            orderString,
            GetProjectCode(project),
            jobID,
            orderSuffix
          ).then(
            (_response) => {
              dispatch(onOrderSuccess());
            },
            (error) => {
              dispatch(handleOrderError(`PrintToPDF error:${error}`));
            }
          );
        } else {
          dispatch(
            UIActions.ShowLoading(GetText('loading.order.generate.progress'))
          );

          // Send order
          API.sendOrder(orderString, jobID, orderSuffix)

            // handle send response
            .then(
              (responseText) => {
                // check possible error in response
                // TODO: generic function to check tictac errors...
                if (responseText.indexOf('*') === 0 || responseText === '-1') {
                  dispatch(handleOrderError(`Server Error:${responseText}`));
                  return;
                }

                // TODO: maybe confirm here that the byte size is correct
                const byteSizeConfirmed = responseText;

                // check if we need to send cover order before checking job
                if (
                  !DebugFlags.PRINT_DEMO &&
                  orderSuffix === ORDER_SUFFIX.ALBUM &&
                  HasCustomCover(project)
                ) {
                  dispatch(sendOrder(project, jobID, ORDER_SUFFIX.COVER));
                  return;
                }

                dispatch(
                  UIActions.ShowLoading(
                    GetText('loading.order.generate.validating')
                  )
                );

                // lets check the job now
                API.checkOrderJob(jobID).then((responseText) => {
                  // TODO: check job is ok or not?
                  if (
                    responseText.indexOf('*') === 0 ||
                    responseText === '-1'
                  ) {
                    dispatch(handleOrderError(`Server Error:${responseText}`));
                    return;
                  }

                  // get the order pass
                  const orderPass = responseText;
                  const vendorID = authSelectors.GetVendorID(getState());

                  // then go to new order page
                  const finalUrl = OrderHelper.generateOrderFinalURL(
                    project,
                    jobID,
                    vendorID,
                    orderPass
                  );

                  // before redirection, display feedback popup
                  if (DebugFlags.DISPLAY_FEEDBACK_BEFORE_ORDER_REDIRECT) {
                    popupHelper
                      .showFeedbackPopup(
                        GetText('popup.beta.afterOrder.feedback.title'),
                        GetText('popup.beta.afterOrder.feedback.desc')
                      )
                      .then(() => {
                        window.open(finalUrl, '_self');
                      });
                  } else {
                    window.open(finalUrl, '_self');
                  }

                  //
                  dispatch(onOrderSuccess());
                });
              },

              // promise error
              (error) => {
                dispatch(handleOrderError(`Sending Error: ${error}`));
              }
            );
        }
      })

      // export error
      .catch((reason) =>
        dispatch(handleOrderError(`FrameExport Error: ${reason}`))
      );
  };
}

/**
 * Handle error in order
 */
function handleOrderError(error) {
  const errorMessage = error.toString();
  // analytic
  Analytic.TrackPage('/order/error');
  Analytic.TrackCustomEvent('order', `error:${errorMessage}`);
  Analytic.TrackError(errorMessage, false);

  return (dispatch) => {
    dispatch(onOrderFail(`OrderError: ${errorMessage}`));

    // TODO: maybe we should replace this later
    // dispatch(alertActions.error("OrderError: "+ error.toString()));
    Modal.error({
      title: GetText('popup.error.order.title'),
      content: (
        <span>
          {GetText('popup.error.order.description', true)} :
          <br />
          <br />
          <i>{errorMessage}</i>
        </span>
      ),
      cancelText: GetText('common.cancel'),
      okText: GetText('common.ok'),
      onOk: () => {
        API.sendDevMail(error, `Ordering Error: ${errorMessage}`).then(() =>
          popupHelper.showFeedbackPopup()
        );
      },
    });
  };
}

/** **********************************
// SELECTORS
************************************ */

const getJobIDSelector = (state) => state.order && state.order.orderJobID;
// const backendByIDSelector = state => state.photos && state.photos.backendPhotosByID;
// const projectPhotosByIDSelector = state => state.photos && state.photos.projectPhotosByID;
// const projectPhotosByIDSelector = state => state.photos && state.photos.projectPhotosByID;

// // const getAllPhotosByID = createSelector(
// //   tempByIDSelector,
// //   backendByIDSelector,
// //   (tempById, backendById) => {
// //     // merge both!
// //     return { ...tempById, ...backendById };
// //   }
// // );

// const getAllPhotosByID = createSelector(
//   tempByIDSelector,
//   projectPhotosByIDSelector,
//   (tempById, projectPhotosById) => {
//     // merge both!
//     return { ...tempById, ...projectPhotosById };
//   }
// );

// const getTempPhotosList = createSelector(
//   tempByIDSelector,
//   ( byID ) => {
//     let photoList = Object.keys(byID).map( ( key, index )=> {
//       return byID[key];
//     });
//     return sortPhotos(null, photoList);
//   }
// );

// const getProjectPhotosList = createSelector(
//   projectPhotosByIDSelector,
//   ( byID ) => {
//     let photoList = Object.keys(byID).map( ( key, index )=> {
//       return byID[key]; //let item = byID[key]; // get item
//       //return item;
//     });
//     return sortPhotos(null, photoList);
//   }
// );

// const getAllPhotoList = createSelector(
//   getAllPhotosByID,
//   ( byID ) => {
//     let photoList = Object.keys(byID).map( ( key, index )=> {
//       return byID[key]; //let item = byID[key]; // get item
//       //return item;
//     });
//     return sortPhotos(null, photoList);
//   }
// );

// const getPhotoCategories = createSelector(
//   getAllPhotosByID,
//   ( byID ) => {
//     let categories = Object.keys(byID).map( ( key, index )=> byID[key].cat );
//     return uniq( categories );
//   }
// );

// const getJobID = createSelector(
//   getAllPhotosByID,
//   ( byID ) => {
//     let photosCategories = {};
//     Object.keys(byID).map( ( key, index )=> {
//       let item = byID[key]; // get item
//       if(!photosCategories[item.cat]) // create category arry if not existing
//       photosCategories[item.cat] = [];
//       photosCategories[item.cat].push(item);// push item in correct category object
//     });
//     return photosCategories;
//   }
// );

/** **********************************
// EXPORT PUBLIC ACTIONS
************************************ */

export default reducer;

export const orderActions = {
  resetOrder,
  startOrder,
};

export const orderSelector = {
  getJobIDSelector,
};
