import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { connect, batch } from 'react-redux';

// import { Button } from 'reactstrap';
// import { Row, Col } from 'reactstrap';
import {
  Radio,
  Layout,
  Row,
  Col,
  Button,
  Avatar,
  Dropdown,
  Spin,
  Popover,
  Empty,
  Modal,
  Menu,
  Tooltip,
  Progress,
  Divider,
} from 'antd';
import {
  FlagOutlined,
  SaveOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { EditionArea } from './homePage/EditionArea';
import { LeftArea } from './homePage/LeftArea';
import { GetProjectDescription } from '../utils/projectHelper';

import { editionActions, editionSelectors } from '../feature/edition/edition';
import { photoListSelector } from '../feature/photoList/photoList';
import { PageNavigator } from './homePage/pageNavigator/PageNavigator';

import { ProjectListView } from './homePage/ProjectListView';
import { DebugFlags } from '../debug/DebugFlags';
import { authActions, authSelectors } from '../feature/auth/authentification';
import { UIActions, UISelectors } from '../feature/ui/ui';
import { pricingSelectors } from '../feature/pricing/pricing';
import { API } from '../utils/API';
import { orderActions } from '../feature/order/order';
import {
  GetText,
  GetAvailableLanguages,
  GetCurrentLanguage,
  SetLanguage,
} from '../data/LanguageHelper';
import { popupHelper } from '../feature/alert/popupHelper';
import { Colors } from '../data/Colors';
import { ErrorBoundary } from '../_components/ErrorBoundary';
import { ProjectClassSelectionView } from './homePage/ProjectClassSelectionView';

import { AlbumProjectCreationView } from './homePage/projectCreation/AlbumProjectCreationView';
import { CalendarProjectCreationView } from './homePage/projectCreation/CalendarProjectCreationView';
import { initializeKeyboard, KEYBOARD_ACTIONS } from '../utils/keyboardHelper';
import { OpenTextInBlankWindow } from '../utils/debugging';
import { CardProjectCreationView } from './homePage/projectCreation/CardProjectCreationView';
import { OrderDetailPopover } from '../feature/order/OrderDetailPopover';
import { CanvasProjectCreationView } from './homePage/projectCreation/CanvasProjectCreationView';
import { history, ROUTE_CONST } from '../utils/history';
import { PROJECT_CONST } from '../data/config';
import { ENVIRONEMENT, PROJECT_CLASS } from '../data/Constants';
import { domElementToPng, downloadDataURL } from '../utils/HtmlUtils';
import { EmbedAssets } from '../images/EmbedAssets';

class HomePage extends React.Component {
  // --------------------- React ------------------------

  componentDidMount() {
    this.testImageRef = React.createRef();

    // initialize keyboard listener
    initializeKeyboard((action) => {
      switch (action) {
        case KEYBOARD_ACTIONS.ESCAPE:
          if (this.props.isPreviewMode) {
            this.props.dispatch(UIActions.SetPreviewMode(false));
          }
          break;
        case KEYBOARD_ACTIONS.SAVE:
          if (this.props.project) {
            this.props.dispatch(editionActions.SaveProject());
          }
          break;
        case KEYBOARD_ACTIONS.COPY:
          if (this.props.project && this.props.allowCopy) {
            this.props.dispatch(editionActions.CopyFrame());
          }
          break;

        case KEYBOARD_ACTIONS.PASTE:
          if (this.props.project && this.props.allowPaste) {
            this.props.dispatch(editionActions.PasteFrame());
          }
          break;

        case KEYBOARD_ACTIONS.UNDO:
          if (this.props.allowUndo) {
            this.props.dispatch(editionActions.Undo());
          }
          break;
        case KEYBOARD_ACTIONS.REDO:
          if (this.props.allowRedo) {
            this.props.dispatch(editionActions.Redo());
          }
          break;
      }
    });

    // new project creation route
    /*
        else if(!this.props.project)
            history.push(ROUTE_CONST.NEW_PROJECT);
            */
  }

  // --------------------- Methods ------------------------

  /*
    handleDeleteUser(id) {
        return (e) => this.props.dispatch(projectActions.delete(id));
    }
    */

  handleSaveClick() {
    // DEBUGGING
    if (DebugFlags.SIMULATE_ERROR_ON_SAVE) {
      throw new Error('This is a generated error on click to save');
      // let testError = JSON.parse("<xml></xml>"); // this will not work on developement, only on prod
    } else {
      this.props.dispatch(editionActions.SaveProject());
    }
  }

  handleSaveAsClick = () => {
    // ask for a new name
    popupHelper.showRenameProjectPopup(
      // success
      (newProjectName) => {
        batch(() => {
          this.props.dispatch(editionActions.UpdateProjectName(newProjectName));
          this.props.dispatch(editionActions.SaveProject(true));
        });
      },
      // cancel
      () => {},
      // default project name
      `${this.props.project.name} (copy)`
    );
  };

  checkShowSaveWarningPopup(dispatch): Promise {
    return new Promise((resolve, reject) => {
      if (this.props.hasPhotosStillImporting) {
        popupHelper.ShowLeaveWarningPopup(true);
        reject();
        return;
      }

      if (this.props.needSave) {
        Modal.confirm({
          title: GetText('popup.project.save.warning.title'),
          content: GetText('popup.project.save.warning.description', true),
          cancelText: GetText('popup.project.save.warning.donotsave.button'),
          okText: GetText('common.ok'),
          onOk: () => {
            this.props.dispatch(editionActions.SaveProject());
            resolve();
          },
          onCancel: () => {
            resolve();
          },
        });
      } else resolve();
    });
  }

  handleOpenProjectList() {
    this.checkShowSaveWarningPopup().then(() => {
      history.push(ROUTE_CONST.PROJECT_LIST);
    });
  }

  handleCreateNewProject() {
    this.checkShowSaveWarningPopup().then(() => {
      // history.push(ROUTE_CONST.NEW_PROJECT);
      history.push(ROUTE_CONST.SELECT_PROJECT_CLASS);
    });
  }

  handleLogout() {
    this.props.dispatch(editionActions.ClearProject());
    this.props.dispatch(authActions.logout());
    history.push(ROUTE_CONST.LOGIN);
  }

  handleTabsCollapse(collapsed) {
    this.props.dispatch(UIActions.changeMainTab(null, !collapsed));
  }

  onDragEnd(e) {
    console.log(`end -->${e}`);
  }

  onDragStart(e) {
    console.log(`start -> ${e}`);
  }

  handleDebugMenuClick = (
    clickParam // :import('antd/lib/menu').ClickParam) {
  ) => {
    const { project } = this.props;
    switch (clickParam.key) {
      case 'project_detail':
        alert(JSON.stringify(project));
        break;

      case 'page_detail': {
        const message = JSON.stringify(
          project.pageList[this.props.selectedPageIndex]
        );
        alert(message);
        OpenTextInBlankWindow(message, 'page detail');
        break;
      }

      case 'test_sendError':
        API.sendDevMail(new Error('This is a simulated Error')).then(
          (response) => alert(`Send Mail response:${JSON.stringify(response)}`)
        );
        break;

      case 'export_pageNav':
        this.debugTestExportImage();
        break;

      case 'test_imageEditor':
        this.debugTestImageEditor();
        break;

      // case "export_frameText":
      //     // find a frame text not empty
      //     project.pageList.forEach((page, pageIndex)=>
      //     {
      //         page.frames.forEach( (frame:Frame, frameIndex )=>
      //         {
      //             if(!IsFrameEmpty( frame ) && frame.type === FRAME_TYPE.TEXT)
      //             {
      //                 FrameExporter.generateFrameForUpload( frame, pageIndex, frameIndex )
      //                     .then(( pngDataUrl )=>
      //                     {
      //                         alert("Exported Frame:" + pngDataUrl);
      //                         downloadDataURL(pngDataUrl, "frameText_page"+pageIndex+ "_frame"+frameIndex+".png");
      //                     })
      //             }
      //         })
      //     })
      //     break;

      case 'test_exportPDF':
        this.debugTestExportPDF();
        break;

      case 'test_export_selected_PDF':
        this.debugTestExportPDF(true);
        break;

      case 'test_export_choice_PDF':
        this.debugTestExportPDF(true, true);
        break;

      default:
        alert(`No action for  :${clickParam.key}`);
    }
  };

  debugTestExportImage = () => {
    // alert("Test export image");

    // get dom node
    const element = this.testImageRef.current;
    const itemID = 'navigator_0';
    const printArea = document.getElementById(itemID);
    if (!printArea) {
      alert(`no item found with ID:${itemID}`);
      return;
    }
    //
    this.props.dispatch(UIActions.ShowLoading('Debug exporting image'));
    domElementToPng(printArea, 3, true)
      .then((dataURL) => downloadDataURL(dataURL))
      .catch((reason) => alert(`Error exporting:${reason}`))
      .finally(() => this.props.dispatch(UIActions.HideLoading()));
  };

  debugTestImageEditor = () => {
    popupHelper.showImageEditor({
      full_url: 'https://editor2.tictacphoto.com//flex/img.php?img=19727139',
    });
  };

  debugTestExportPDF = (selectedPageOnly: boolean, askForPages: boolean) => {
    DebugFlags.ORDER_TO_PDF = true;

    if (askForPages) {
      const index = this.props.selectedPageIndex;
      const currentPageAndOthers =
        index === 0
          ? `${index},${index + 1}`
          : `${index - 1},${index},${index + 1}`;
      popupHelper.AskSomething(
        'What pages? (example: 1,2,3)',
        (response) => {
          DebugFlags.PDF_PRINT_SPECIFIC_PAGES = response;
          this.props.dispatch(orderActions.startOrder());
        },
        null,
        currentPageAndOthers
      );
    } else {
      DebugFlags.PDF_PRINT_SPECIFIC_PAGES = selectedPageOnly
        ? `${this.props.selectedPageIndex}`
        : null;
      this.props.dispatch(orderActions.startOrder());
    }
  };

  // --------------------- Render ------------------------

  render() {
    const { user, projects, project, ui, rebranding } = this.props;
    const { Header, Footer, Sider, Content, orderButton } = Layout;

    const { mainLoadingProps } = this.props;

    const logoPopover = (
      <div className="appVersion">
        Version:
        {process.env.REACT_APP_VERSION}
      </div>
    );

    const userPopover = (
      <div className="userProfile" style={{ padding: '5px', width: 'auto' }}>
        <span style={{ paddingRight: '20px' }}>
          {GetText('userprofile.hello')} <b>{user.email}!</b>
        </span>

        {/* <Button type="danger"><Link to={ROUTE_CONST.LOGIN}>Logout</Link></Button> */}
        <Button
          type="danger"
          onClick={(e) => {
            this.handleLogout();
          }}
        >
          {GetText('topbar.logout.btn.label')}
        </Button>
        {/* <div className="appVersion">Version: {process.env.REACT_APP_VERSION}</div> */}

        <Divider style={{ width: '200px' }}>
          {GetText('change.language')}
        </Divider>
        <div style={{ textAlign: 'center' }}>
          <Radio.Group
            size="default"
            buttonStyle="solid"
            onChange={(e) => {
              SetLanguage(e.target.value);
              window.location.reload(); /* this.handleBorderSizeChange(e.target.value) */
            }}
            value={GetCurrentLanguage()}
          >
            {GetAvailableLanguages().map((lang, index, arr) => (
              <Radio.Button key={index} value={lang}>
                {lang}
              </Radio.Button>
            ))}
          </Radio.Group>
        </div>

        <Divider style={{ width: '200px' }} />
        <Button
          type="primary"
          icon={<FlagOutlined />}
          style={{ width: '100%', marginLeft: '0%' }}
          onClick={() => {
            popupHelper.showFeedbackPopup();
          }}
        >
          {GetText('btn.contact.us')}
        </Button>
      </div>
    );

    const logoMenu = (
      <div>
        {(DebugFlags.DEBUGGING || PROJECT_CONST.env === ENVIRONEMENT.DEV) && (
          <Menu onClick={this.handleDebugMenuClick}>
            {/* <Menu.Item key="0">Version: {process.env.REACT_APP_VERSION}</Menu.Item> */}
            {/* <Menu.Divider /> */}
            <Menu.Item key="project_detail">
              Debug: Display project detail
            </Menu.Item>
            <Menu.Item key="page_detail">
              Debug: Display current page detail
            </Menu.Item>
            <Menu.Item key="export_pageNav">
              Debug: Test Export navigator
            </Menu.Item>
            {/* <Menu.Item key="export_frameText">Debug: Test Export frameText</Menu.Item> */}
            <Menu.Item key="test_sendError">Debug: test send error</Menu.Item>
            <Menu.Item key="test_export_selected_PDF">
              Debug: Export current page To PDF
            </Menu.Item>
            <Menu.Item key="test_export_choice_PDF">
              Debug: Export specified pages
            </Menu.Item>
            <Menu.Item key="test_exportPDF">
              Debug: Export project as PDF
            </Menu.Item>
          </Menu>
        )}
        {PROJECT_CONST.env !== ENVIRONEMENT.DEV && (
          <Menu>
            {/*
                    <Menu.Item key="project_detail">Debug: Display project detail</Menu.Item>
                    <Menu.Item key="export_pageNav">Debug: Test Export navigator</Menu.Item>
                    <Menu.Item key="export_frameText">Debug: Test Export frameText</Menu.Item>
                    <Menu.Item key="test_sendError">Debug: test send error</Menu.Item>
                    <Menu.Item key="test_export_selected_PDF">Debug: Export current page To PDF</Menu.Item>
                    <Menu.Item key="test_exportPDF">Debug: Export project as PDF</Menu.Item> */}
          </Menu>
        )}
      </div>
    );

    return (
      <>
        <Layout
          className="homepage"
          style={{
            height: '100vh',
          }}
        >
          <Routes>
            <Route
              path={ROUTE_CONST.PROJECT_LIST}
              element={<ProjectListView />}
            />
            <Route
              path={ROUTE_CONST.SELECT_PROJECT_CLASS}
              element={<ProjectClassSelectionView />}
            />
            <Route
              path={ROUTE_CONST.NEW_PROJECT}
              element={
                <>
                  {PROJECT_CONST.project_class === PROJECT_CLASS.ALBUM && (
                    <AlbumProjectCreationView />
                  )}
                  {PROJECT_CONST.project_class === PROJECT_CLASS.CALENDAR && (
                    <CalendarProjectCreationView />
                  )}
                  {PROJECT_CONST.project_class === PROJECT_CLASS.CARD && (
                    <CardProjectCreationView />
                  )}
                  {PROJECT_CONST.project_class === PROJECT_CLASS.CANVAS && (
                    <CanvasProjectCreationView />
                  )}
                </>
              }
            />
          </Routes>

          {
            // --------------------- header ------------------------
            !this.props.isPreviewMode && (
              <Spin spinning={this.props.projectLoadingOrSavingOrOrdering}>
                <Layout
                  className="header"
                  id="testPrintImage"
                  ref={this.testImageRef}
                >
                  <Row type="flex" justify="space-between" align="top">
                    {/* left area */}
                    <Col xs={6} md={8}>
                      <Row
                        type="flex"
                        justify="start"
                        align="top"
                        style={{ flexWrap: 'nowrap' }}
                      >
                        <Col>
                          <Popover content={logoPopover} placement="right">
                            <Dropdown
                              overlay={logoMenu}
                              trigger={['contextMenu']}
                            >
                              <div>
                                <img
                                  src={rebranding?.logo || EmbedAssets.Logo}
                                  width="75"
                                  height="45"
                                />
                                {DebugFlags.DEBUGGING && (
                                  <div
                                    style={{
                                      color: '#ffff00',
                                      position: 'absolute',
                                      left: '2px',
                                      top: '2px',
                                      fontWeight: 'bold',
                                      fontStyle: 'italic',
                                      fontSize: '8px',
                                    }}
                                  >
                                    DEV
                                  </div>
                                )}
                              </div>
                            </Dropdown>
                          </Popover>
                        </Col>

                        {
                          /* project name */
                          project && project.name && (
                            <div>
                              <div className="projectName">{project.name}</div>
                              <div className="projectDescription">
                                {GetProjectDescription(project)}
                              </div>
                            </div>
                          )
                        }
                      </Row>
                    </Col>

                    <Col xs={18} md={16} style={{ paddingTop: '6px' }}>
                      <Row
                        type="flex"
                        justify="end"
                        align="middle"
                        style={{ flexWrap: 'nowrap' }}
                      >
                        <Button
                          hidden={!project}
                          className="button"
                          type={this.props.needSave ? 'danger' : 'primary'}
                          icon={<SaveOutlined />}
                          onClick={(e) => {
                            this.handleSaveClick();
                          }}
                        >
                          {GetText('topbar.project.save.btn.label')}
                        </Button>
                        <Button
                          hidden={!project}
                          className="button"
                          type="primary"
                          // icon="save"
                          onClick={(e) => {
                            this.handleSaveAsClick();
                          }}
                        >
                          {GetText('topbar.project.saveAs.btn.label')}
                        </Button>
                        <Button
                          className="button"
                          type="primary"
                          // icon="folder-open"
                          onClick={(e) => {
                            this.handleOpenProjectList();
                          }}
                        >
                          {GetText('topbar.project.open.btn.label')}
                        </Button>
                        <Button
                          className="button"
                          type="primary"
                          // icon="folder-add"
                          onClick={(e) => {
                            this.handleCreateNewProject();
                          }}
                        >
                          {GetText('topbar.project.new.btn.label')}
                        </Button>

                        <div className="spacer" />

                        <OrderDetailPopover>
                          <Button
                            hidden={!project}
                            className="orderButton"
                            icon={<ShoppingCartOutlined />}
                          >
                            {GetText('topbar.order.btn.label')} (
                            {this.props.projectPrice}
                            €)
                          </Button>
                        </OrderDetailPopover>

                        <div className="spacer" />

                        <Popover
                          content={userPopover}
                          trigger="click"
                          placement="bottomRight"
                          className="avatar"
                        >
                          {/* <span style={{padding:"20px"}}>Hi <b>{user.email}!</b></span> */}
                          <Avatar icon={<UserOutlined />} />
                        </Popover>
                        <div className="spacer" />
                      </Row>
                    </Col>
                  </Row>
                </Layout>
              </Spin>
            )
          }

          <Layout>
            {
              /* ------------------- LEFT AREA ---------------- */
              project && !this.props.isPreviewMode && (
                <Sider width={300} collapsible>
                  <LeftArea />
                </Sider>
              )
            }

            {/* ------------------- EDITION AREA ---------------- */}
            {project && (
              <ErrorBoundary>
                <Layout
                  className="rightArea"
                  style={{ backgroundColor: '#fff000' }}
                >
                  {/* <Sider></Sider> */}
                  <Content
                    className="rightArea"
                    style={{ backgroundColor: null }}
                  >
                    <EditionArea />
                  </Content>
                  {!this.props.isPreviewMode && project.pageList.length > 1 && (
                    <PageNavigator />
                  )}
                </Layout>
              </ErrorBoundary>
            )}

            {/* ------------------- EMPTY AREA ---------------- */}
            {!project &&
              this.props.resourcesAreLoaded &&
              !this.props.projectLoadingOrSavingOrOrdering && (
                <Content
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    style={
                      {
                        // backgroundColor:"#fff000",
                      }
                    }
                    imageStyle={{
                      height: 60,
                    }}
                    description={<span>{GetText('noProject.title')}</span>}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Button
                        type="primary"
                        onClick={(e) => {
                          this.handleCreateNewProject();
                        }}
                      >
                        {GetText('noProject.create.button')}
                      </Button>
                      <div className="spacer" />
                      <Button
                        type="primary"
                        onClick={(e) => {
                          this.handleOpenProjectList();
                        }}
                      >
                        {GetText('noProject.open.button')}
                      </Button>
                    </div>
                  </Empty>
                </Content>
              )}
          </Layout>

          {/*
                <div id="TestTransform" style={{zIndex:100000, left:-20, top:10, padding:"0px 25px", transform:'rotate(-28deg)', position:"absolute", backgroundColor:"#fff000"}}
                    draggable="true"
                    onDragStart={(e)=>{ this.onDragStart(e); }}
                    onDragEnd={(e)=>{ this.onDragEnd(e); }}  >Beta version</div>
                */}

          {/* ------------------- FOOTER ----------------
                <Footer className="footer">
                    <p>this is the footer of {user.email}</p>
                </Footer>
                */}

          {/* END OF HOMEPAGE */}
        </Layout>

        {/* Feedback Icon */}
        <div style={{ position: 'absolute', left: '25px', bottom: '70px' }}>
          <Tooltip placement="topRight" title={GetText('feedback.icon')}>
            <FlagOutlined
              style={{ color: Colors.YELLOW, fontSize: '150%' }}
              onClick={() => {
                popupHelper.showFeedbackPopup();
              }}
            />
          </Tooltip>
        </div>
        <div
          style={{
            position: 'absolute',
            left: '15px',
            bottom: '52px',
            fontSize: '10px',
            color: '#cccccc',
          }}
        >
          v {process.env.REACT_APP_VERSION}
        </div>

        {/* ------------------- MAIN LOADING AREA ---------------- */}
        <Modal
          open={mainLoadingProps.visibility}
          zIndex={9999} // on top
          centered
          closable={false}
          transitionName={null}
          maskStyle={{ backgroundColor: '#00000022' }}
          footer={null}
        >
          {mainLoadingProps.percent !== -1 ? (
            <span>
              {mainLoadingProps.title}
              <Progress percent={mainLoadingProps.percent} status="active" />
            </span>
          ) : (
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Spin size="default" style={{ marginRight: '20px' }} />
              {mainLoadingProps.title}
            </div>
          )}
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { projects, authentication, edition, ui, order } = state;
  const { user } = authentication;
  const { tabsOpen } = ui;
  const { project, projectIsLoading } = edition;
  const projectLoadingOrSavingOrOrdering =
    edition.projectIsLoading || edition.project_saving || order.isLoading;

  return {
    user,
    projects,
    project,
    allowUndo: editionSelectors.allowUndo(state),
    allowRedo: editionSelectors.allowRedo(state),
    allowPaste: editionSelectors.allowPaste(state),
    allowCopy: editionSelectors.allowCopy(state),
    allowGrid: UISelectors.allowGrid(state),
    projectPrice: pricingSelectors.GetProjectPrice(state),
    tabsOpen,
    selectedPageIndex: edition.selectedPage,
    projectLoadingOrSavingOrOrdering,
    needSave: editionSelectors.NeedSave(state),
    hasPhotosStillImporting: photoListSelector.hasPhotosStillImporting(state),
    isPreviewMode: UISelectors.IsPreviewMode(state),
    sessionID: authSelectors.GetSessionID(state),
    rebranding: authSelectors.GetRebrandingInfo(state),
    // resourcesAreLoading: authSelectors.ResourcesAreLoading(state),
    // resourcesAreLoaded: authSelectors.ResourcesAreLoaded(state),
    resourcesAreLoaded: authSelectors.ResourcesAreLoaded(state),
    mainLoadingProps: UISelectors.GetMainLoadingProps(state),
  };
}

const connectedHomePage = connect(mapStateToProps)(HomePage);
export { connectedHomePage as HomePage };
