import { CreateCalendarColorOptions } from '../utils/calendar/CreateCalendarColorOptions';
import { GetDoc } from '../utils/ProductHelper';
import {
  PROJECT_CONST,
  IsCalendarEditor,
  IsCardEditor,
  IsCanvasEditor,
} from './config';
import { PAPER_QUALITY } from './Constants';

export class Project {
  constructor(classname, projectName, type, docID) {
    // file
    this.id = null; // ID is defined by by backend after the first save.
    this.name = projectName; // name is defined after the project has been saved once
    this.classname = classname;
    this.build_created = PROJECT_CONST.build_start; // TODO: get the build of the current version
    this.build_saved = PROJECT_CONST.build_start;

    // document
    this.type = type; // classic, contemporary, regular, etc... = ALBUM_TYPES
    this.docID = docID; // document ID (from product cataglogue)

    // recover DOC infos
    const doc = GetDoc(docID);
    // this.docCode = doc.code; // doc code (for backend) // EDIT: now doc code is not saved in the project, this is generated based on document properties and catalogue

    // from previous editor :
    // old docType is now docCode => backend code => 101, 102, etc...
    // old docCode is now docID => product code => S30, S40
    // public var docType:String  // Server Document type (101, 102, etc...)
    // public var docCode:String;  // Product code (S30, S40, etc...)

    this.width = doc.width * doc.multiplier; // the width in pixel
    this.height = doc.height * doc.multiplier; // the height in pixel
    this.cutBorder = doc.cutBorder;

    // album only
    this.paper = false; // Use or not paper between pages
    this.pagePaperQuality = PAPER_QUALITY.QUALITY_250; // Use 200gr or 250 type of paper for pages. 250 is the default value because all older ablums were coated
    this.pageNumber = false; // show or hide page number
    this.pageNumberCentered = false; // are page numbers centered
    this.flyleaf = 'white'; // colors used to draw flyeaf in editionArea. Defautl white
    this.matte = false; // matte paper
    this.cover_matte = false; // matte cover finish

    // common
    this.coated = false; // (VARNISH) Use or not coated/varnish pages. TRUE is the default value because all older ablums were coated

    // calendar only
    if (IsCalendarEditor()) {
      this.startYear = new Date().getFullYear();
      this.startMonth = 0;
      this.startDay = 0;
      this.calendarColorOptions = CreateCalendarColorOptions();
    }

    // card only
    if (IsCardEditor()) {
      this.cardPack = '1X1';
      this.envelope = 'none';
    }

    // canvas only
    if (IsCanvasEditor()) {
      this.canvasFrameColor = null; // some canvas (kadapak) can have a frame color
      this.canvasFormat = null;
    }

    // create page list
    /** @type {Array.<IPage>} * */
    this.pageList = [];
  }
}
