// language here : https://docs.google.com/spreadsheets/d/1Obm3dHlQRvlSeJ8SdJMnVrCKad_9mlKxekjeNTR9rGk/edit#gid=1415409064

// See doc: https://www.npmjs.com/package/react-localization

import LocalizedStrings from 'react-localization';
import { DebugFlags } from '../debug/DebugFlags';
import { trimHtmlTags } from '../utils/HtmlUtils';

// keep object in memory
let localizedStrings: LocalizedStrings;

export function GetCurrentLanguage(): string {
  return localizedStrings.getLanguage();
}
export function SetLanguage(language: string): string {
  localStorage.setItem('lang', language);
  return localizedStrings.setLanguage(language);
}
export function GetAvailableLanguages(): string[] {
  return localizedStrings.getAvailableLanguages();
}

const loadLanguageFile = (lang: string): Promise<Record> => {
  const jsonURL: string = `${process.env.PUBLIC_URL}/locales/${lang}.json`;
  return fetch(jsonURL).then((response) => response.json());
};

export function LoadLanguages(languagesToUse: Array, spreadSheetID: string) {
  const languageDic = {};
  const languagePromises = languagesToUse.map((lang) =>
    loadLanguageFile(lang).then((json) => (languageDic[lang] = json))
  );
  return Promise.all(languagePromises).then(() => {
    // update localized strings
    localizedStrings = new LocalizedStrings(languageDic);

    // set default language after creation
    const cookieLang = localStorage.getItem('lang');
    const browserLang = localizedStrings.getLanguage();
    const urlLang = new URL(window.location).searchParams.get('lang');

    // if language in url
    if (urlLang) SetLanguage(urlLang);
    // if browser lang differ from cookie, use cookie
    else if (cookieLang && browserLang !== cookieLang) SetLanguage(cookieLang);

    // check if available in dic!
    const lang = localizedStrings.getLanguage();
    if (!languageDic[lang]) {
      console.warn(`No language pack for: ${lang}`);
      SetLanguage('en'); // default to EN
    }

    return languageDic;
  });
}

// get text by key funciton
export function GetText(key: string, trimHtml = false): string {
  if (DebugFlags.DISPLAY_LANGUAGE_LINKS) return '******';

  if (localizedStrings && localizedStrings[key]) {
    if (trimHtml) return trimHtmlTags(localizedStrings[key]);
    return localizedStrings[key];
  }
  return `??${key}??`;
}
