/**
 * Transform a simple object to formdata
 */
export function ObjectToFormData(object) {
  const formData = new FormData();
  Object.keys(object).forEach((key) => formData.append(key, object[key]));
  return formData;
}

/**
 * Convert a string to file
 */
export function StringToFile(string, filename) {
  return new File([new Blob([string])], filename);
}

/**
 * Convert a string to blob
 */
export function StringToBlob(string) {
  return new Blob([string]);
}
