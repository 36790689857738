import { CATALOGUE_ALBUM } from './catalogue/albumCatalogue';
import { CATALOGUE_CALENDAR } from './catalogue/calendarCatalogue';
import { CATALOGUE_CARD } from './catalogue/cardCatalogue';
import { CATALOGUE_CANVAS } from './catalogue/canvasCatalogue';
import { PROJECT_CONST } from './config';
import { PROJECT_CLASS } from './Constants';

export function GetProductCatalogue(optionalClassname: string) {
  let catalogue = null;
  const classname = optionalClassname || PROJECT_CONST.project_class;
  switch (classname) {
    case PROJECT_CLASS.ALBUM:
      catalogue = { ...CATALOGUE_ALBUM };
      break;

    case PROJECT_CLASS.CALENDAR:
      catalogue = { ...CATALOGUE_CALENDAR };
      break;

    case PROJECT_CLASS.CARD:
      catalogue = { ...CATALOGUE_CARD };
      break;

    case PROJECT_CLASS.CANVAS:
      catalogue = { ...CATALOGUE_CANVAS };
      break;

    default:
      catalogue = null;
  }
  if (!catalogue)
    throw new Error(
      `Get Catalogue for product type <${classname}> is not valid`
    );
  return catalogue;
}
