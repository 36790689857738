// --------------------- EXPORTS / PUBLIC -----------------------

import { cloneDeep } from 'lodash';
import type { Frame } from '../../types/types';
import { CATALOGUE_CANVAS } from '../../data/catalogue/canvasCatalogue';
import { mmToPoint } from '../MeasureUtils';
import { ScaleFrameAndContent } from '../../feature/edition/frameHelper';

// --------------------- interfaces ------------------------

export const CANVAS_TYPES = {
  CANVAS: 'canvas',
  WOOD: 'wood',
  POSTER: 'poster',
  PLEXI: 'plexi',
  KADAPAK: 'kadapak',
  ALUMINIUM: 'aluminium',
  ALUMINIUM_MOUNTING: 'aluminium_mounting',
  FOREX: 'forex',
  REAL_ALUMINIMUM: 'real_aluminium',
};

export const CANVAS_FORMAT = {
  PORTRAIT: 'port',
  LANDSCAPE: 'land',
  SQUARE: 'square',
  FREE: 'freeformat',
  // MULTIPLE TODO:
};

// --------------------- Methods ------------------------

let canvasFormmatedCatalogue;
// for canvas documents, the doc id is a composition of the format and the size
// CL_30x20 = canvas landscape 30x20 cm
function getDoc(docID) {
  if (!canvasFormmatedCatalogue) reformatCanvasCatalogue();

  return canvasFormmatedCatalogue.docs[docID];
}

/* Recover docID for canvas using the project type, canvas format, and size string
 * */
function GetCanvasDocID(project_type, canvasFormat, sizeString) {
  try {
    const prefix = CATALOGUE_CANVAS.products[project_type][canvasFormat].doc;

    // freeformat do not contains the size in its docID
    if (prefix === 'CF') return prefix;

    // security here
    if (!sizeString) return null;

    // other formats looks like this : "CP_20X30"
    return `${prefix}_${sizeString}`;
  } catch (e) {
    console.warn(
      `GetCanvasDocID error: project_type:${project_type} canvasFormat:${canvasFormat} sizeString:${sizeString} --> error:${e}`
    );
    return null;
  }
}

function reformatCanvasCatalogue() {
  canvasFormmatedCatalogue = cloneDeep(CATALOGUE_CANVAS);
  canvasFormmatedCatalogue.docs = {};
  Object.keys(CATALOGUE_CANVAS.docs).forEach((prefix) => {
    const node = CATALOGUE_CANVAS.docs[prefix];
    const defaultNode = cloneDeep(node);

    // free format is correctly formatted
    if (prefix === 'CF') canvasFormmatedCatalogue.docs[prefix] = defaultNode;
    else {
      Object.keys(node.codes).forEach((sizeFormat) => {
        const docID = `${prefix}_${sizeFormat}`; // CL_30X40
        const doc = cloneDeep(defaultNode);
        delete doc.codes;
        doc.code = defaultNode.codes[sizeFormat];
        doc.width = parseInt(sizeFormat.split('x')[0], 10) * 10; // sizes are in cm
        doc.height = parseInt(sizeFormat.split('x')[1], 10) * 10; // sizes are in cm
        canvasFormmatedCatalogue.docs[docID] = doc;
      });
    }
  });
}

function GetCodeModifier(canvasType: string): number {
  return CATALOGUE_CANVAS.producCodeModifier[canvasType];
}

function GetFinalDocCode(docID, project_type) {
  const { code } = getDoc(docID);
  return code + GetCodeModifier(project_type);
}

function GetPreviewUrl(canvasType: string, canvasFormat: string) {
  const ext = canvasType === CANVAS_TYPES.KADAPAK ? 'png' : 'jpg';
  if (
    canvasType !== CANVAS_TYPES.KADAPAK &&
    canvasType !== CANVAS_TYPES.PLEXI &&
    canvasType !== CANVAS_TYPES.POSTER
  )
    canvasType = CANVAS_TYPES.CANVAS;
  return `${process.env.PUBLIC_URL}/images/ui/menu/lefttabs/project/canvas/${canvasType}_${canvasFormat}_1.${ext}`;
}

function GetEdgeSizeMM(canvasType: string): number {
  if (CATALOGUE_CANVAS.edgeSize[canvasType])
    return CATALOGUE_CANVAS.edgeSize[canvasType];
  return CATALOGUE_CANVAS.edgeSize.default;
}
function GetCutBorderMM(canvasType: string): number {
  if (CATALOGUE_CANVAS.cutBorder[canvasType])
    return CATALOGUE_CANVAS.cutBorder[canvasType];
  return CATALOGUE_CANVAS.cutBorder.default;
}

function GetFrameColorByID(colorID) {
  return CATALOGUE_CANVAS.kadapakFrameColors[colorID];
}

// check a frame and it's limit, if it goes outside the page, we adapt it to go through the edge
function CheckModifyFrameForEdge(
  canvasType,
  pageWidth: number,
  pageHeight: number,
  newFrame: Frame
) {
  // [EDIT] old, offset was too big.. will take always 5mm of offset after edge
  // const cutBorder = mmToPoint(CanvasHelper.GetCutBorderMM(canvasType));
  // const edgeSize = mmToPoint(CanvasHelper.GetEdgeSizeMM(canvasType));
  // const frameOffset = edgeSize + (cutBorder-edgeSize)/2; // the offset of a frame must be bigger than the edge size, but not as big as the cut border

  // [NEW] 5mm of offset after edge
  const edgeSize = mmToPoint(CanvasHelper.GetEdgeSizeMM(canvasType));
  const frameOffset = edgeSize + mmToPoint(5);

  let diff = 0;
  const originFrameWidth = newFrame.width;
  const originFrameHeight = newFrame.height;

  // left offset
  const left = newFrame.x - newFrame.width / 2;
  if (left < 0) {
    diff = left + frameOffset;
    newFrame.x -= diff / 2;
    newFrame.width += diff;
  }
  // check right offset
  const right = newFrame.x + newFrame.width / 2;
  if (right > pageWidth) {
    diff = pageWidth + frameOffset - right;
    newFrame.x += diff / 2;
    newFrame.width += diff;
  }
  // top offset
  const top = newFrame.y - newFrame.height / 2;
  if (top < 0) {
    diff = top + frameOffset;
    newFrame.y -= diff / 2;
    newFrame.height += diff;
  }
  // check bottom offset
  const bottom = newFrame.y + newFrame.height / 2;
  if (bottom > pageHeight) {
    diff = pageHeight + frameOffset - bottom;
    newFrame.y += diff / 2;
    newFrame.height += diff;
  }

  // if we have a phtoo inside the frame, we need to scale the content too
  if (newFrame.photo) {
    const newWidth = newFrame.width;
    const newHeight = newFrame.height;
    // put back previeous width/height to make correct scaling calculation!
    newFrame.width = originFrameWidth;
    newFrame.height = originFrameHeight;
    ScaleFrameAndContent(newFrame, newWidth, newHeight);
  }
}

// --------------------- Helpers for project creation params from session ------------------------

// returns the canvas type by doc code
const GetCanvasDocIDByDocCode = (docCode: number): string => {
  const modifier = GetCanvasModifierByDocCode(docCode);
  docCode -= modifier;

  const catalogue = CATALOGUE_CANVAS;

  // special case for custom
  if (docCode === CATALOGUE_CANVAS.docs.CF.code) {
    return 'CF';
  }

  // otherwise compose docID
  for (const prefix of Object.keys(catalogue.docs)) {
    const { codes } = catalogue.docs[prefix];
    for (const size of Object.keys(codes)) {
      if (codes[size] === docCode) return `${prefix}_${size}`;
    }
  }
  return null;
};

// returns the canvas type by doc code
const GetCanvasTypeByDocCode = (docCode: number): string => {
  const canvasModifier = GetCanvasModifierByDocCode(docCode);
  for (const type of Object.keys(CATALOGUE_CANVAS.producCodeModifier)) {
    if (CATALOGUE_CANVAS.producCodeModifier[type] === canvasModifier)
      return type;
  }
  return null;
};

// canvas codes are modified based on the canvas type (kadapak, aluminium, etc.. )
// All canvas codes are between 500 and 600 at start, then are modified with canvas types modifiers
function GetCanvasModifierByDocCode(docCode: number): number {
  return docCode > 600 ? (Math.floor(docCode / 100) - 5) * 100 : 0;
}

// retrieve format for the canvas
function GetCanvasFormatByDocCode(docCode: number): string {
  const prefix = GetCanvasDocIDByDocCode(docCode).split('_')[0];
  const type = GetCanvasTypeByDocCode(docCode);

  for (const format of Object.keys(CATALOGUE_CANVAS.products[type])) {
    if (CATALOGUE_CANVAS.products[type][format].doc === prefix) return format;
  }

  // return null;
}

//   private function roundNearest5(_value:number):number
//   {
//    var n:number = _value/10;
//    var result:String = (Math.round(n*2)/2).toFixed(1);
//    return int(parseFloat(result)*10);
//   }

// function GetCanvasEdgeDetail( project:Project ):ICanvasEdge
// {
//  let canvasEdge : ICanvasEdge = {
//   size:0,

//  }
//  // in case of canvas editor, we need to add the "edge size" to the margin
//  let canvasEdge = {
//   size:0,
//   style:{},
//   left:0,
//   top:0,
//   right:0,
//   bottom:0,
// }
// if(IsCanvasEditor())
// {
//   canvasEdge.size = mmToPoint(CanvasHelper.GetEdgeSizeMM(proj.type));
//   outsideMargin += canvasEdge.size;
//   canvasEdge.style = {
//     // strokeWidth:canvasEdgeSize,
//     fill:"transparent",
//     strokeWidth:2,
//     stroke: "red",
//     strokeOpacity:0.5,
//   }
//   let canvasPage:IPage = pageGroup[0];
//   canvasEdge.left = outsideMargin - canvasEdge.size;
//   canvasEdge.top = outsideMargin -canvasEdge.size;
//   canvasEdge.right = outsideMargin + canvasPage.width + canvasEdge.size;
//   canvasEdge.bottom = outsideMargin + canvasPage.height + canvasEdge.size;
// }
// }

// /***************************************************************
//  COPYRIGHT   : Anthony De Brackeleire (http://www.antho.be)
//  YEAR   : 2014
//  ****************************************************************/
// package manager
// {
//  import data.FrameVo;
//  import data.Infos;
//  import data.PhotoVo;

//  import flash.geom.Point;

//  import view.edition.EditionArea;
//  import view.edition.Frame;
//  import view.edition.FrameBackground;
//  import view.edition.FrameMultiple;
//  import view.edition.FramePhoto;

//  /**
//   * Helper for canvas content
//   */
//  public class CanvasManager
//  {
//   ////////////////////////////////////////////////////////////////
//   // PROPERTIES
//   ////////////////////////////////////////////////////////////////

//   /**
//    * CANVAS TYPES = Material
//    */
//   public static const TYPE_CANVAS : String = "canvas_types";
//   public static const TYPE_WOOD : String = "wood_types";
//   public static const TYPE_POSTER : String = "poster_types";
//   public static const TYPE_PLEXI : String = "plexi_types";
//   public static const TYPE_KADAPAK : String = "kadapak_types";
//   public static const TYPE_ALUMINIUM : String = "aluminium_types";
//   public static const TYPE_FOREX : String = "forex_types";
//   public static const TYPE_REAL_ALUMINIUM : String = "real_aluminium_types";
//   public static const TYPE_ALUMINIUM_MOUNTING : String = "aluminium_mounting_types";

//   // Note : "Aluminium" is also called "Dibond"
//   private static var modifierMap : Array = [
//    {name:TYPE_CANVAS, value:0},
//    {name:TYPE_WOOD, value:6900},
//    {name:TYPE_PLEXI, value:7000},
//    {name:TYPE_KADAPAK, value:7100},
//    {name:TYPE_ALUMINIUM, value:7200},
//    {name:TYPE_FOREX, value:7300},
//    {name:TYPE_POSTER, value:7400},
//    {name:TYPE_REAL_ALUMINIUM, value:7500},
//    {name:TYPE_ALUMINIUM_MOUNTING, value:7800} //7600
//   ];

//   /* INFOS :
//   TYPES
//   <key id="canvas.type.canvas_types"><![CDATA[Canvas]]></key>
//   <key id="canvas.type.wood_types"><![CDATA[45 mm Canvas]]></key>
//   <key id="canvas.type.poster_types"><![CDATA[Poster]]></key>
//   <key id="canvas.type.plexi_types"><![CDATA[Plexi]]></key>
//   <key id="canvas.type.kadapak_types"><![CDATA[Kadapak]]></key>
//   <key id="canvas.type.aluminium_types"><![CDATA[Dibond® (Aluminium)]]></key>
//   <key id="canvas.type.forex_types"><![CDATA[Forex®]]></key>

//   ORIENTATIONS
//   <key id="canvas.orientation.types_canvas_port"><![CDATA[Portait]]></key>
//   <key id="canvas.orientation.types_canvas_land"><![CDATA[Landscape]]></key>
//   <key id="canvas.orientation.types_canvas_square"><![CDATA[Square]]></key>
//   <key id="canvas.orientation.types_canvas_freeformat"><![CDATA[Custom Made]]></key>
//   <key id="canvas.orientation.types_canvas_multiple"><![CDATA[Multiple]]></key>
//   */

//   public var multipleFrame : FrameMultiple;
//   public var isMultipleFirstAdd:Boolean = true; // is it the first time we inject a frame as multiple or not (used for to display or not the multiple canvas update popup on drag/drop)

//   ////////////////////////////////////////////////////////////////
//   // CONSTRUCTOR / INSTANCE
//   ////////////////////////////////////////////////////////////////

//   public function CanvasManager(sf:SingletonEnforcer)
//   {
//    if(!sf) {
//     throw new Error("CanvasManager is a singleton, use instance");
//     return;
//    }
//    init();
//   }

//   private static var _instance:CanvasManager;

//   public static function get instance():CanvasManager
//   {
//    if (!_instance) _instance = new CanvasManager(new SingletonEnforcer())
//    return _instance;
//   }

//   ////////////////////////////////////////////////////////////////
//   // GETTERS SETTERS
//   ////////////////////////////////////////////////////////////////

//   /**
//    * get format type (also named orientation in some cases.. but it's not just an orientation, was bad naming)
//    */
//   public function get format():String
//   {
//    if(!Infos.project) return null;
//    return getFormatByPrefix( Infos.project.docPrefix );
//   }

//   /**
//    * shortcut to know if the current canvas project is a multiple one
//    * if projectVo has a canvasMLayout (multiple layout) we are in a multiple canvas project type
//    */
//   public function get isMultiple():Boolean
//   {
//    return (Infos.project.canvasMLayout !==null)? true : false;
//   }

//   public function get isPopart():Boolean
//   {
//    return (Infos.project.canvasStyle === "styles_style5")? true : false;
//   }

//   public function get isPelemele():Boolean
//   {
//    return (Infos.project.canvasStyle === "styles_style2")? true : false;
//   }

//   /**
//    * KADAPAK FRAME COLOR
//    */
//   public function getKadapakFrameColor(str:String):uint {
//    var color:uint = 0;
//    switch(str.toLowerCase()) {
//     case "black":
//      color = 0x000000;
//      break;
//     case "silver":
//      color = 0xA6A6A6;
//      break;
//     case "gold":
//      color = 0xD1B181;
//      break;
//     case "red":
//      color = 0x8B0000;
//      break;
//     case "blue":
//      color = 0x123572;
//      break;
//    }
//    return color | 0xFF000000;
//   }

//   /**
//    * retrieve canvas edge in pixel
//    */
//   public function get edgeSize():number
//   {
//    switch (Infos.project.canvasType) {
//     case TYPE_CANVAS :
//      return MeasureManager.millimeterToPixel( 20 ); // 20 mm side
//      break;
//     case TYPE_WOOD :
//      return MeasureManager.millimeterToPixel( 50 ); // 45 mm side + 5 mm for bend
//      break;
//      /* TODO : get correct edge for other project types
//     case TYPE_POSTER :
//      return MeasureManager.millimeterToPixel( 20 );
//     case TYPE_PLEXI :
//      return MeasureManager.millimeterToPixel( 20 );
//      */
//     default :
//      return MeasureManager.millimeterToPixel( 1 ); // 5 mm margin
//    }
//   }

//   /**
//    * OLD EDITOR CANVAS MODIFIER
//    * looks like the old way of doing so I just copy this from old editor
//    */
//   public function get CanvasModifier():number
//   {
//    return getCanvasModifierByCanvasType( Infos.project.canvasType );
//    /*
//    switch (Infos.project.canvasType) {
//     case TYPE_WOOD :
//      return 6900 ;
//     case TYPE_PLEXI :
//      return 7000;
//     case TYPE_KADAPAK :
//      return 7100;
//     case TYPE_ALUMINIUM :
//      return 7200;
//     case TYPE_FOREX :
//      return 7300;
//     case TYPE_POSTER :
//      return 7400;
//     default :
//      return 0;
//    }
//    */
//   }

//   /**
//    * OLD EDITOR CANVAS MODIFIER
//    * looks like the old way of doing so I just copy this from old editor
//    */
//   public function getCanvasModifierFromDash(canvasType:String):number
//   {
//    return getCanvasModifierByCanvasType( canvasType );
//   }

//   /**
//    * get canvas modifier by canvas type
//    */
//   public function getCanvasModifierByCanvasType( canvasType : String ):number
//   {
//    for (var i:number = 0; i < modifierMap.length; i++)
//    {
//     if(modifierMap[i].name === canvasType) return modifierMap[i].value;
//    }
//    return 0;
//   }

//   /**
//    * get canvas modifier by canvas type
//    */
//   public function getCanvasTypeByModifier( modifier :number ):String
//   {
//    for (var i:number = 0; i < modifierMap.length; i++)
//    {
//     if(modifierMap[i].value === modifier) return modifierMap[i].name;
//    }
//    return TYPE_CANVAS;
//   }

//   /**
//    * Return the canvas type by modifier product id
//    */
//   public function getCanvasTypeByModifiedProductId( productId :number ):String
//   {
//    var canvasModifier :number = 0;
//    if( productId > 1000 ) // 599 is max product id for canvas right now, be careful if this limit changes
//    {
//     canvasModifier = (Math.floor(productId/100) - 5) * 100;
//    }

//    return getCanvasTypeByModifier( canvasModifier );
//   }

//   /**
//    * Overriding canvas cut border as values from xml are not correct
//    * > return value is in mm
//    */
//   public function get canvasCutBorder():number
//   {
//    switch (Infos.project.canvasType) {
//     case TYPE_CANVAS :
//      return 40;
//     case TYPE_WOOD :
//      return 70;
//     default :
//      return 25;
//    }
//   }

//   /**
//    * OLD EDITOR CANVAS COLOR SYSTEM
//    THIS NEEDS TO BE CLEAR AS IT'S IMPORTANT FOR canvas
//    */
//   public function get CanvasColor():String
//   {
//    if(isKadapak)
//    {
//     return Infos.project.canvasFrameColor;
//    }
//    return "none";
//   }

//   /**
//    * check if we are in kadapak
//    */
//   public function get isKadapak():Boolean
//   {
//    if(Infos.isCanvas && Infos.project && Infos.project.canvasType === TYPE_KADAPAK) return true;
//    return false;
//   }

//   /**
//    * retrieve current gap between pages
//    */
//   public function get gapBetweenPages():number
//   {
//    if(Infos.project.docPrefix === "CPM")
//    {
//     var gap :number = MeasureManager.millimeterToPixel(Infos.project.canvasMargin);
//     if(gap === 0) gap = 2; // a thin line to separate canvas is the minimum
//     return gap;
//    }
//    return 0;
//   }

//   /**
//    * return amount of rows for the multiple canvas type
//    */
//   public function get multipleRowsCount():number
//   {
//    if(!Infos.project || !Infos.project.canvasMLayout) return 1;
//    return parseInt(Infos.project.canvasMLayout.split("x")[0]);
//   }

//   /**
//    * return amount of cols for the multiple canvas type
//    */
//   public function get multipleColsCount():number
//   {
//    if(!Infos.project || !Infos.project.canvasMLayout) return 1;
//    return parseInt(Infos.project.canvasMLayout.split("x")[1]);
//   }

//   ////////////////////////////////////////////////////////////////
//   // PUBLIC METHODS
//   ////////////////////////////////////////////////////////////////

//   /**
//    * initialize canvas manager
//    */
//   public function init():void
//   {

//   }

//   /**
//    * retrieve a format by canvas prefix
//    */
//   public function getFormatByPrefix( prefix:String ):String
//   {
//    switch (prefix)
//    {
//     case "CPP" :
//      return "types_canvas_port";
//      break;
//     case "CPL" :
//      return "types_canvas_land";
//      break;
//     case "CPF" :
//      return "types_canvas_freeformat";
//      break;
//     case "CPS" :
//      return "types_canvas_square";
//      break;
//     case "CPM" :
//      return "types_canvas_multiple";
//      break;
//    }

//    return null;
//   }

//   /**
//    * get amount of pages for a multiple canvas
//    */
//   public function getMultiplePageAmount():number
//   {
//    if(!Infos.project || !Infos.project.canvasMLayout) return -1;
//    var numRow :number = parseInt(Infos.project.canvasMLayout.split("x")[0]);
//    var numCols :number = parseInt(Infos.project.canvasMLayout.split("x")[1]);
//    return numRow * numCols;
//   }

//   /**
//    * get page position by index
//    * as canvas are only one page for non "CPM"
//    */
//   public function getPagePosition( pageIndex :number, isPreview : Boolean = false):Point
//   {
//    if(!Infos.project || !Infos.project.canvasMLayout) return new Point();
//    var numRow :number = parseInt(Infos.project.canvasMLayout.split("x")[0]);
//    var numCols :number = parseInt(Infos.project.canvasMLayout.split("x")[1]);

//    var margin :number = gapBetweenPages;
//    var edgeValue :number = (isPreview)? 1 : edgeSize;
//    var posX :number = ((pageIndex+1) % numCols) * (Infos.project.getPageBounds().width + edgeValue*2 + margin) ;
//    var posY :number = Math.floor((pageIndex+1)/ numCols) * (Infos.project.getPageBounds().height + edgeValue*2 +  margin) ;
//    return new Point(posX, posY);
//   }

//   /**
//    * Layouts for canvas are weird as they must fit to canvas edge but the info is not well documented in xml
//    * So we change it at source to make it fit current project properties
//    * Frames having 20 as offset should be updated to fit current project edge/bleed
//    */
//   public function modifyCanvasLayoutXMLNode( _layoutNode : XML, layoutScaleX :number, layoutScaleY :number ):XML
//   {
//    // make a copy to avoid modify original xml ! (it was an issue for upgrades)
//    var layoutNode : XML = new XML(_layoutNode.toString());

//    var layoutWidth :number = parseFloat(layoutNode.@pagewidth);
//    var layoutHeight :number = parseFloat(layoutNode.@pageheight);
//    var frameNode : XML ;
//    var w:number,h:number,top:number, left:number;
//    //var edgeX:number = MeasureManager.pixelToPFL(edgeSize)/layoutScaleX; // modified 20160629 from layoutmanager (layout scale is done now from elements inside xml node)
//    //var edgeY:number = MeasureManager.pixelToPFL(edgeSize)/layoutScaleY; // modified 20160629 from layoutmanager (layout scale is done now from elements inside xml node)
//    var edgeX:number = MeasureManager.pixelToPFL(edgeSize/layoutScaleX); // modified 20160629 from layoutmanager (layout scale is done now from elements inside xml node)
//    var edgeY:number = MeasureManager.pixelToPFL(edgeSize/layoutScaleY); // modified 20160629 from layoutmanager (layout scale is done now from elements inside xml node)

//    // check frames and apply scale factor
//    for (var j:number = 0; j < layoutNode.frame.length(); j++)
//    {
//     frameNode = layoutNode.frame[j];
//     w = Number(frameNode.@width);
//     h = Number(frameNode.@height);
//     left = Number(frameNode.@left);
//     top = Number(frameNode.@top);

//     if(left + w === layoutWidth + 20)
//     {
//      w = w -20 + edgeX;
//     }
//     if(left === -20)
//     {
//      left = -edgeX;
//      w = w -20 + edgeX;
//     }

//     if(top + h === layoutHeight + 20)
//     {
//      h = h -20 + edgeY;
//     }
//     if(top === -20)
//     {
//      top = -edgeY;
//      h = h -20 + edgeY;
//     }

//     frameNode.@width = w
//     frameNode.@height = h
//     frameNode.@left = left
//     frameNode.@top = top
//    }

//    return layoutNode;
//   }

//   public function getFrame( layoutNode : XML, layoutScaleX :number, layoutScaleY :number ):XML
//   {
//    var layoutWidth :number = parseFloat(layoutNode.@pagewidth);
//    var layoutHeight :number = parseFloat(layoutNode.@pageheight);
//    var frameNode : XML ;
//    var w:number,h:number,top:number, left:number;
//    var edgeX:number = MeasureManager.pixelToPFL(edgeSize)/layoutScaleX;
//    var edgeY:number = MeasureManager.pixelToPFL(edgeSize)/layoutScaleY;

//    // check frames and apply scale factor
//    for (var j:number = 0; j < layoutNode.frame.length(); j++)
//    {
//     frameNode = layoutNode.frame[j];
//     w = Number(frameNode.@width);
//     h = Number(frameNode.@height);
//     left = Number(frameNode.@left);
//     top = Number(frameNode.@top);

//     if(left + w === layoutWidth + 20)
//     {
//      w = w -20 + edgeX;
//     }
//     if(left === -20)
//     {
//      left = -edgeX;
//      w = w -20 + edgeX;
//     }

//     if(top + h === layoutHeight + 20)
//     {
//      h = h -20 + edgeY;
//     }
//     if(top === -20)
//     {
//      top = -edgeY;
//      h = h -20 + edgeY;
//     }

//     frameNode.@width = w
//     frameNode.@height = h
//     frameNode.@left = left
//     frameNode.@top = top
//    }

//    return layoutNode;
//   }

//   /**
//    * > init multiple frame
//    * > this is done when project is ready (after project load or a new project creation)
//    * > if there is a photo vo, it's frame creation
//    * > if not, it means it's a save recover or an upgrade
//    */
//   public function initMultipleFrame( isUpdate : Boolean = false):void
//   {
//    // if not multipleFrameVo
//    isMultipleFirstAdd = (!Infos.project.multipleFrameVo)? true : false;

//    // create multiple frame vo
//    var frameVo:FrameVo = (Infos.project.multipleFrameVo)? Infos.project.multipleFrameVo : new FrameVo();
//    frameVo.type = FrameVo.TYPE_PHOTO;
//    frameVo.width =  ( multipleColsCount * Infos.project.getPageBounds().width )  +  ( 2 * edgeSize )  +  ((multipleColsCount-1) * gapBetweenPages); // num cols * page width + 2*edge (left and right) + gaps between
//    frameVo.height =  ( multipleRowsCount * Infos.project.getPageBounds().height )  +  ( 2 * edgeSize )  +  ((multipleRowsCount-1) * gapBetweenPages);

//    /*
//    if(!Infos.project.multipleFrameVo || isUpdate){
//     frameVo.type = FrameVo.TYPE_PHOTO;
//     frameVo.width =  ( multipleColsCount * Infos.project.getPageBounds().width )  +  ( 2 * edgeSize )  +  ((multipleColsCount-1) * gapBetweenPages); // num cols * page width + 2*edge (left and right) + gaps between
//     frameVo.height =  ( multipleRowsCount * Infos.project.getPageBounds().height )  +  ( 2 * edgeSize )  +  ((multipleRowsCount-1) * gapBetweenPages);

//    }
//    */

//    // check if there is already a photo in a possible updated multiple frame
//    var tempPhotoVo : PhotoVo = (Infos.project.multipleFrameVo)?Infos.project.multipleFrameVo.photoVo:null

//    // save it
//    Infos.project.multipleFrameVo = frameVo;

//    // create virtual frame for calculation
//    if(!multipleFrame) {
//     if(tempPhotoVo)frameVo.photoVo = tempPhotoVo;
//     multipleFrame = new FrameMultiple(Infos.project.multipleFrameVo)
//     multipleFrame.init();
//    } else {
//     multipleFrame.frameVo = frameVo;
//     if(tempPhotoVo)updateMultipleFramePhoto(tempPhotoVo, true);
//     else multipleFrame.update(null);
//    }
//   }

//   /**
//    * clear a multiple frame content
//    */
//   public function clearMultipleFrame():void
//   {
//    // reset multipleFrame vo
//    Infos.project.multipleFrameVo.photoVo = null;
//    Infos.project.multipleFrameVo.cLeft = 0;
//    Infos.project.multipleFrameVo.cTop = 0;
//    Infos.project.multipleFrameVo.zoom = 1;

//    // clear sub frames
//    var subFrame : FrameVo;
//    for (var i:number = 0; i < Infos.project.pageList.length; i++)
//    {
//     // find background of all pages
//     subFrame = Infos.project.pageList[i].layoutVo.frameList[0];
//     if(subFrame.isMultiple) { // only update photo if it's still a multiple frame
//      var f : FramePhoto = EditionArea.getVisibleFrameByFrameVo( subFrame ) as FrameBackground;
//      f.clearMe();
//     }
//    }
//   }

//   /**
//    * Inject a photo on all background frames of project (multiple canvas layout)
//    * > this action is only done when drag and drop of a photo on the background of a multiple canvas page
//    */
//   public function updateMultipleFramePhoto( photoVo : PhotoVo , forceAllFrames : Boolean = true):void
//   {
//    isMultipleFirstAdd = false;

//    // update multiple frame
//    multipleFrame.update( photoVo );

//    // update all visible subFrames (which compose all together the big frame)
//    var subFrame : FrameVo;
//    for (var i:number = 0; i < Infos.project.pageList.length; i++)
//    {
//     // find background of all pages
//     subFrame = Infos.project.pageList[i].layoutVo.frameList[0];
//     if(forceAllFrames) subFrame.isMultiple = true; // put the frame as multiple frame

//     if(subFrame.isMultiple) { // only update photo if it's still a multiple frame
//      subFrame.photoVo = photoVo;
//      var f : FramePhoto = EditionArea.getVisibleFrameByFrameVo( subFrame ) as FrameBackground;
//      f.update(photoVo);
//     }
//    }

//    // update subFrames relative to main multiple frame
//    updateMultipleFramesPosition();
//   }

//   /**
//    * Update multiple frames position relative to main virtual frame
//    */
//   public function updateMultipleFramesPosition():void
//   {
//    var subFrame : FrameVo;
//    var numCols :number = multipleColsCount;
//    var numRow :number = multipleRowsCount;
//    var margin :number = gapBetweenPages;
//    var pageW :number = Infos.project.getPageBounds().width;
//    var pageH :number = Infos.project.getPageBounds().height;
//    for (var i:number = 0; i < Infos.project.pageList.length; i++)
//    {
//     subFrame = Infos.project.pageList[i].layoutVo.frameList[0];
//     if(subFrame.isMultiple){
//      subFrame.zoom = multipleFrame.frameVo.zoom;
//      //subFrame.cLeft = multipleFrame.frameVo.cLeft  +  ((i) % numCols) * (pageW + edgeSize*2 + margin)   - ;
//      //subFrame.cTop = multipleFrame.frameVo.cTop  +  Math.floor((i)/ numCols) * (pageH + edgeSize*2 +  margin)  -
//      subFrame.cLeft = multipleFrame.frameVo.cLeft   +  ((i) % numCols) * (pageW + margin)  ;
//      //if( i % numCols === 0 ) subFrame.cLeft -= edgeSize;
//      subFrame.cTop = multipleFrame.frameVo.cTop  + Math.floor((i)/ numCols) * (pageH +  margin)
//      //if( Math.floor( i/numCols ) === 0 ) subFrame.cTop -= edgeSize;

//      // find visible frame
//      var f : FramePhoto = EditionArea.getVisibleFrameByFrameVo( subFrame ) as FrameBackground;
//      f.update(null);
//     }
//    }

//    // store changes
//    UserActionManager.instance.addAction(UserActionManager.TYPE_MULTIPLE_FRAME_CHANGE);
//   }

//   ////////////////////////////////////////////////////////////////
//   // PRIVATE METHODS
//   ////////////////////////////////////////////////////////////////

//  }
// }
// class SingletonEnforcer{}

// --------------------- CANVAS PRICE CALCULATION ------------------------

// private function getCanvasOptionPrice( docType:String, canvasPriceInfo:CanvasPriceInfo = null, useCanvasPriceFile:Boolean = false ):number
// {
//  if(!priceList)
//   loadPricingFile();

//  // if not in canvas xml, use classic get price system
//  if(!useCanvasPriceFile)
//   return getBasicCostByDocType(docType);

//  var priceObj:Object;
//  for (var i:number = 0; i < canvasCustomPriceList.length; i++)
//  {
//   priceObj = canvasCustomPriceList[i];
//   if(priceObj.product_id === docType && priceObj.width === canvasPriceInfo.width && priceObj.height === canvasPriceInfo.height){
//    var priceNumber :number = priceObj.cost * canvasPriceInfo.coeficient;
//    priceNumber = Math.round(priceNumber * 100)/100; // be sure to round it to 2 decimals
//    return priceNumber;
//   }
//  }
//  Debug.log("PriceManager.getPriceByDocType: Could not find price for docType: "+docType);
//  return 0;
// }

// private function getCanvasProjectPrice( docType : String, canvasPriceInfo : CanvasPriceInfo = null ) :number
//    {
//     var price :number = 0;
//     if( canvasPriceInfo !==null)
//     {
//      var modifiedDocType:number = parseInt(docType) + CanvasManager.instance.getCanvasModifierFromDash(canvasPriceInfo.type);
//      var useCanvasPriceFile:Boolean = ( canvasPriceInfo.orientation === ProductsCatalogue.CANVAS_ORIENTATION_MULTIPLE || canvasPriceInfo.orientation === ProductsCatalogue.CANVAS_ORIENTATION_CUSTOM );
//      docType = String(modifiedDocType);
//      price = getCanvasOptionPrice (docType, canvasPriceInfo , useCanvasPriceFile);
//     }
//     else price = getBasicCostByDocType( docType );

//     return price;
//    }

//    package offline.data
// {
//  import data.ProductsCatalogue;

//  import utils.Debug;

//  public class CanvasPriceInfo
//  {
//   public var docType:String;
//   public var type:String;
//   public var orientation:String;
//   public var width:number;
//   public var height:number;
//   public var coeficient :number;

//   // constructor
//   public function CanvasPriceInfo(_docType:String, _type:String, _orientation:String = "", _width:number = 0, _height:number = 0, _coeficient :number = 1):void
//   {
//    docType = _docType;
//    type = _type;
//    orientation = _orientation;
//    width = roundNearest5(_width);
//    height = roundNearest5(_height);

//    if( width > height )
//    {
//     var temp:number = width;
//     width = height;
//     height = temp;
//    }

//    coeficient = _coeficient;
//   }

//   private function roundNearest5(_value:number):number
//   {
//    var n:number = _value/10;
//    var result:String = (Math.round(n*2)/2).toFixed(1);
//    return int(parseFloat(result)*10);
//   }

//   /**
//    * ------------------------------------ STATIC HELPER to get price coefficient for canvas multiple -------------------------------------
//    */

//   public static function getPriceCoeficient(selectedMLayout:String, orientation:String):number
//   {
//    Debug.log("CanvasPriceInfo.selectedMLayout: "+selectedMLayout);

//    // MULTIPLE CANVAS
//    if(orientation === ProductsCatalogue.CANVAS_ORIENTATION_MULTIPLE)
//    {
//     /*
//     2 = 95%
//     3 = 90%
//     4 = 85%
//     6 = 80%
//     9 = 75%
//     */

//     // security
//     if(!selectedMLayout || selectedMLayout.indexOf("x") === -1){
//      Debug.warn("CanvasPriceInfo.getPriceCoeficient : wrong selectedMLayout : "+selectedMLayout);
//      return 1;
//     }

//     var coef:number = int(selectedMLayout.split("x")[0]) * int(selectedMLayout.split("x")[1]);
//     switch (coef)
//     {
//      case 2 :
//       coef *= .95;
//       break;
//      case 3 :
//       coef *= .90;
//       break;
//      case 4 :
//       coef *= .85;
//       break;
//      case 6 :
//       coef *= .80;
//       break;
//      case 9 :
//       coef *= .75;
//       break;
//      default :
//       Debug.warn("CanvasPriceInfo.getPriceCoeficient : Coef '"+coef+"' for multiple canvas is not allowed");
//       break;
//     }
//     return coef;
//    }
//    else
//     return 1;
//   }

//   /**
//    * ------------------------------------ STATIC HELPER to create a priceInfo object from save or history ----------------
//    */
//   public static function CreateFromSave( obj : Object ):CanvasPriceInfo
//   {
//    if(obj)
//    {
//     try
//     {
//      var priceInfo : CanvasPriceInfo = new CanvasPriceInfo(obj.docType,obj.type,obj.orientation,obj.width,obj.height,obj.coeficient);
//      return priceInfo;
//     }
//     catch(e:Error)
//     {
//      Debug.warn("CanvasPriceInfo.CreateFromSave error : "+e.message);
//     }
//    }
//    return null;
//   }

export const CanvasHelper = {
  GetDoc: getDoc,
  GetCanvasDocID,
  GetPreviewUrl,
  GetFrameColorByID, // kadaapak only for now
  GetCodeModifier,
  GetFinalDocCode,

  CheckModifyFrameForEdge,
  GetEdgeSizeMM,
  GetCutBorderMM,

  // helpers for project creation based on docCode
  GetCanvasDocIDByDocCode,
  GetCanvasTypeByDocCode,
  GetCanvasModifierByDocCode,
  GetCanvasFormatByDocCode,
};
