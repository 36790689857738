import { batch } from 'react-redux';
import * as Sentry from '@sentry/react';
import { createSelector, createReducer } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { Modal } from 'antd';
import type {
  Frame,
  IPage,
  ProjectCreationParams,
  BackgroundItem,
  ProjectOptions,
  TextOptions,
  Photo,
  IFrameShadow,
} from '../../types/types';
import {
  CleanAndVerifyProject,
  CleanProjectPhotoList,
  CreateProjectPages,
  InjectPagesAtIndex,
  UpgradeProjectSize,
  cleanAndVerifyCustomCover,
  ApplyOptionsToProject,
  IsClassicCoverProject,
} from '../../utils/projectHelper';
import { API } from '../../utils/API';
import { alertActions } from '../alert/alert';
import {
  GetPageGroupList,
  PageContainsFrame,
  getPageGroup,
} from '../../utils/pageHelper';
import { photoListActions, photoListSelector } from '../photoList/photoList';
import { UIActions } from '../ui/ui';
import { GetDoc } from '../../utils/ProductHelper';
import { GetUID } from '../../utils/UID';
import {
  InjectPhotoIntoFrame,
  FRAME_TYPE,
  IsFrameEmpty,
  ClearFrame,
} from './frameHelper';
import {
  HasCustomCover,
  GetProjectCover,
  IsClassicCoverPage,
  IsCover,
  GetClassicCoverOptions,
  CoverLabelNameToCoverDisplayName,
} from '../../utils/coverHelper';
import {
  ApplyBackgroundFillToPage,
  ApplyBackgroundToPage,
  getPageBackgroundRect,
} from '../backgrounds/backgroundHelper';
import { backgroundSelectors } from '../backgrounds/background';

import { TICTAC_COOKIES } from '../../data/Cookies';
import { GetText } from '../../data/LanguageHelper';
import {
  Layout,
  ApplyLayoutToPage,
  GetPageLayoutType,
} from '../layouts/layoutHelper';
import { layoutListSelectors } from '../layouts/layoutList';
import { CANVAS_FORMAT } from '../../utils/canvas/CanvasHelper';
import { cmToPixel } from '../../utils/MeasureUtils';
import { pricingSelectors } from '../pricing/pricing';
import { IsDebug } from '../../debug/DebugFlags';
import { popupHelper } from '../alert/popupHelper';
import { history, ROUTE_CONST } from '../../utils/history';
import {
  COVER_CLASSIC_FABRIC_TYPE,
  PAPER_QUALITY,
  PROJECT_CLASS,
} from '../../data/Constants';
import {
  IsAlbumEditor,
  IsCalendarEditor,
  IsCanvasEditor,
  PROJECT_CONST,
} from '../../data/config';
import { Project } from '../../data/Project';
import { TABS_ENUM } from '../../pages/homePage/leftArea/TABS_ENUM';
import { GetProjectOptions } from '../project/GetProjectOptions';

/** **********************************
// ACTIONS TYPES
************************************ */

//
const CHANGE_SELECTED_PAGE = 'EDITION/CHANGE_PAGE';
const CHANGE_SELECTED_FRAME = 'EDITION/CHANGE_FRAME';
const PAGE_UPDATE = 'EDITION/PAGE_UPDATE';
const FRAME_UPDATE = 'EDITION/FRAME_UPDATE';
const UPDATE_PAGE_LIST = 'EDITION/UPDATE_PAGE_LIST';
const FRAME_COPY = 'EDITION/FRAME_COPY';
const FRAME_CUT = 'EDITION/FRAME_CUT';
const FRAME_PASTE_COMPLETED = 'EDITION/FRAME_PASTE_COMPLETED';

// Project creation
// const SET_PROJECT_CLASSNAME = "EDITION/SET_PROJECT_CLASSNAME";
const CREATE_PROJECT = 'EDITION/CREATE_PROJECT';

// Project update
const CLEAR_PROJECT = 'EDITION/CLEAR_PROJECT';
const UPGRADE_PROJECT = 'EDITION/UPGRADE_PROJECT';
const UPDATE_PROJECT_NAME = 'EDITION/UPDATE_PROJECT_NAME';
const UPDATE_PROJECT_OPTIONS = 'EDITION/UPDATE_PROJECT_OPTIONS';
const REMAP_TEMP_PHOTOS = 'EDITION/REMAP_TEMP_PHOTOS';
const AUTOFILL = 'EDITION/AUTOFILL';

// Load project
const PROJECT_LOAD_START = 'EDITION/PROJECT_LOAD_START';
const PROJECT_LOAD_SUCCESS = 'EDITION/PROJECT_LOAD_SUCCESS';
const PROJECT_LOAD_FAIL = 'EDITION/PROJECT_LOAD_FAIL';

// Save project
const PROJECT_SAVE_START = 'EDITION/PROJECT_SAVE_START';
const PROJECT_SAVE_SUCCESS = 'EDITION/PROJECT_SAVE_SUCCESS';
const PROJECT_SAVE_FAIL = 'EDITION/PROJECT_SAVE_FAIL';

// undo redo
const ADD_UNDOABLE_ACTION = 'EDITION/ADD_UNDOABLE_ACTION';
const UNDO = 'EDITION/UNDO';
const REDO = 'EDITION/REDO';

// const DELETE_FRAME = "EDITION/PAGE_UPDATE"; // is part of page update

const MAX_HISTORY_ITEMS = 15;

/** **********************************
// REDUCERS
************************************ */

const initialState = {
  // project
  projectIsLoading: false,
  project_saving: false,

  /** @type {Project} */
  project: null, // the currently loaded project

  lastSaveTime: 0,
  lastEditTime: 0,

  // history
  history: [],
  historyIndex: 0,

  selectedPage: 0, // currently selected page index
  selectedFrameID: null, // currently selected frame
  clipboardFrame: null, // clipboard frame copied
};

// see doc here for immutabiliyt with createReducer function instead
// https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns/
// export default function reducer(state = initialState, action = {})

const reducer = createReducer(initialState, {
  // --------------------- UNDO REDO ------------------------
  [ADD_UNDOABLE_ACTION]: (state, action) => {
    if (!state.history || state.history.length === 0) {
      resetHistory(state, state.project);
    }

    // if we have already undo some actions, we need to slice array
    if (state.historyIndex > 0) {
      state.history = state.history.slice(state.historyIndex);
      state.historyIndex = 0;
    }

    // if selected page has been changed in between, put an intermediate state so when we rollback, we do not change page before viewing the change on the current page
    if (state.selectedPage !== state.history[0].selectedPage) {
      state.history.unshift({
        project: state.history[0].project,
        selectedPage: state.selectedPage,
      });
    }

    // add current Version
    state.history.unshift({
      project: state.project,
      selectedPage: state.selectedPage,
    });

    // limit to max history actions
    if (state.history.length > MAX_HISTORY_ITEMS) {
      state.history.pop();
    }

    // keep track of last edit time
    state.lastEditTime = new Date().getTime();
  },
  [UNDO]: (state, action) => {
    state.historyIndex++;
    const historyObj = state.history[state.historyIndex];
    state.project = CheckRemapProjectTempPhotos(
      historyObj.project,
      action.tempPhotoMapping
    );
    state.selectedPage = historyObj.selectedPage;
    state.selectedFrameID = null;
  },
  [REDO]: (state, action) => {
    state.historyIndex--;
    const historyObj = state.history[state.historyIndex];
    state.project = CheckRemapProjectTempPhotos(
      historyObj.project,
      action.tempPhotoMapping
    );
    state.selectedPage = historyObj.selectedPage;
    state.selectedFrameID = null;
  },

  // CHANGE PAGE
  [CHANGE_SELECTED_PAGE]: (state, action) => {
    state.selectedPage = action.selectedPage;
  },

  // CHANGE PAGE
  [CHANGE_SELECTED_FRAME]: (state, action) => {
    state.selectedFrameID = action.selectedFrameID;
  },

  // CLEAR PROJECT
  [CLEAR_PROJECT]: (state, action) => {
    state.project = null;
    state.selectedFrameID = null;
    state.selectedPage = 0;
    resetHistory(state, null);
  },

  [UPDATE_PROJECT_NAME]: (state, action) => {
    state.project.name = action.newName;
  },

  [UPDATE_PROJECT_OPTIONS]: (state, action) => {
    const { project } = state;
    const { options } = action;

    // apply options
    ApplyOptionsToProject(project, options);

    // when changing project options, we need to be sure the spine is changed also
    // CheckAndUpdateProjectSpineWidth(project); // NOTE: just updating spine do not update width
    if (project.classname === PROJECT_CLASS.ALBUM) {
      cleanAndVerifyCustomCover(state.project);
    }
  },

  [UPGRADE_PROJECT]: (state, action) => {
    state.project = action.project;
    if (state.selectedPage >= action.project.pageList.length) {
      state.selectedPage = action.project.pageList.length - 1;
    }
    // state.selectedPage = 0;
    state.selectedFrameID = null;
  },

  // TODO: this can be a slow process.. maybe we should put frames into a "frameByID" object somehow..
  [FRAME_UPDATE]: (state, action) => {
    state.project.pageList.forEach((page: IPage, pageIndex) => {
      page.frames.forEach((frame: Frame, frameIndex) => {
        if (frame.id === action.frame.id) {
          page.frames[frameIndex] = action.frame; // replace frame
          state.selectedFrameID = frame.id;
          state.selectedPage = page.index;
        }
      });
    });
  },

  [FRAME_COPY]: (state, action) => {
    // create copy of frame and add small "offset"
    const newFrame: Frame = {
      ...getFrameByID(state.selectedFrameID, state.project.pageList),
    };
    state.clipboardFrame = newFrame;
  },

  [FRAME_CUT]: (state, action) => {
    const frameID = state.selectedFrameID;
    let selectedPageIndex = -1;
    let selectedFrameIndex = -1;

    // retrive frame
    state.project.pageList.forEach((page: IPage, pageIndex) => {
      page.frames.forEach((frame: Frame, frameIndex) => {
        if (frame.id === frameID) {
          // put in clipboard
          state.clipboardFrame = { ...frame };
          // keep index in memory to remove
          selectedPageIndex = pageIndex;
          selectedFrameIndex = frameIndex;
        }
      });
    });

    // now remove frame from page
    state.project.pageList[selectedPageIndex].frames.splice(
      selectedFrameIndex,
      1
    );
  },

  [FRAME_PASTE_COMPLETED]: (state, action) => {
    state.selectedFrameID = action.selectedFrameID;
    state.clipboardFrame = null;
  },

  [UPDATE_PAGE_LIST]: (state, action) => {
    state.project.pageList = action.newPageList;
    // when changing page we want to display last one!
    state.selectedFrameID = null;
    if (action.newSelectedPage) {
      state.selectedPage = action.newSelectedPage;
    }

    // when changing project pages, we need to be sure the cover and spine is changed also
    cleanAndVerifyCustomCover(state.project);
  },

  // --------------------- AUTOFILL ------------------------
  [AUTOFILL]: (state, action) => {
    state.project.pageList.forEach((page: IPage, pageIndex: number) => {
      // be sure to update page id so it's refreshing everywhere.
      page.id = GetUID(); // TODO: check if necessary, it could lead to other issues.
      // for each page inject possible photos in empty frames
      page.frames.forEach((frame: Frame, frameIndex: number, arr) => {
        if (
          (frame.type === FRAME_TYPE.PHOTO && !frame.photo) ||
          (frame.type === FRAME_TYPE.BKG &&
            arr.length === 1 &&
            !frame.photo &&
            !frame.background) // case there is only one frame (background)
        ) {
          if (action.unusedPhotos.length > 0) {
            InjectPhotoIntoFrame(frame, action.unusedPhotos.shift());
          }
          // else // not enough photo to fill..
        }
      });
    });
  },

  // REMAP TEMP PHOTOS
  // -> run through pages and if a frame photo correspond to the tempPhotoID, we replace it.
  [REMAP_TEMP_PHOTOS]: (state, action) => {
    if (state.project) {
      state.project.pageList.forEach((page: IPage, pageIndex) => {
        page.frames.forEach((frame: Frame, frameIndex) => {
          if (frame.photo === action.tempPhotoID) {
            frame.photo = action.newPhotoID;
            // keep track of last edit time to force a SAVE after a remap (once photos are uploaded)
            state.lastEditTime = new Date().getTime();
          }
        });
      });
    }
  },

  // UPDATE PAGE
  [PAGE_UPDATE]: (state, action) => {
    state.project.pageList[action.page.index] = action.page;

    // if updated page is cover
    // when changing project pages, we need to be sure the cover and spine is changed also
    if (action.page.isCover && HasCustomCover(state.project)) {
      cleanAndVerifyCustomCover(state.project);
    }

    // when updating page, we need to be sure the currently selected frame is still there, otherwise we need to remove it!
    if (!PageContainsFrame(action.page, state.selectedFrameID)) {
      state.selectedFrameID = null;
    }
  },

  // CREATE PROJECT
  [CREATE_PROJECT]: (state, action) => {
    state.project = action.project;
    state.projectIsLoading = false;
    state.selectedPage = 0;
    state.selectedFrameID = null;

    // // when changing project options, we need to be sure the spine is changed also
    // CheckAndUpdateProjectSpineWidth(state.project);
    resetHistory(state, action.project);
    state.lastEditTime = new Date().getTime();
  },

  // LOAD PROJECT
  [PROJECT_LOAD_START]: (state, action) => {
    state.project = null;
    state.projectIsLoading = true;
    state.project = null;
    state.selectedFrameID = null;
    state.selectedPage = 0;

    resetHistory(state, null);

    localStorage.removeItem(TICTAC_COOKIES.LATEST_PROJECT);
  },

  [PROJECT_LOAD_FAIL]: (state, action) => {
    state.projectIsLoading = false;
    state.project_load_error = action.error;
  },

  [PROJECT_LOAD_SUCCESS]: (state, action) => {
    state.selectedPage = 0;
    state.project = action.project;
    state.projectIsLoading = false;

    resetHistory(state, action.project);

    // each time a project is loaded, we store de id in cookie
    localStorage.setItem(TICTAC_COOKIES.LATEST_PROJECT, action.project.id);
    localStorage.setItem(
      TICTAC_COOKIES.LATEST_CLASSNAME,
      action.project.classname
    );
  },

  // SAVE PROJECT
  [PROJECT_SAVE_START]: (state, action) => {
    state.project_saving = true;
  },
  [PROJECT_SAVE_FAIL]: (state, action) => {
    state.project_saving = false;
    state.project_load_error = action.error;
  },
  [PROJECT_SAVE_SUCCESS]: (state, action) => {
    state.project.id = action.projectID;
    state.project_saving = false;
    state.lastSaveTime = new Date().getTime();
  },
});
export default reducer;

// Helpers

function resetHistory(state, defaultProject: Project) {
  state.history = [];
  if (defaultProject) {
    state.history.push({
      project: defaultProject,
      selectedPage: state.selectedPage,
    });
  }
  state.historyIndex = 0;
  state.lastEditTime = 0;
  state.clipboardFrame = null;
}

function checkReplaceTempPhoto(frame: Frame, state) {
  const photosByID = photoListSelector.getAllBackendPhotosByID(state);
  const tempPhotoMapping =
    photoListSelector.getTempPhotoToNewPhotoMapping(state);

  if (frame.photo) {
    let photoID = frame.photo;
    const photoObj = photosByID[photoID];
    if (!photoObj) {
      if (photoID in tempPhotoMapping) {
        photoID = tempPhotoMapping[photoID];
      } else {
        API.sendDevMail(
          new Error(
            `Edition.checkReplaceTempPhoto: Not able to find photo object for ID:${photoID}`
          )
        );
      }

      frame.photo = photoID;
    }
  }
  return frame;
}

/** **********************************
// ACTIONS Creators
************************************ */
/*
function UpagePage( newPageObj ){

}
*/

function getProjectClass() {
  return PROJECT_CONST.project_class;
}

function AddUndoableAction() {
  return { type: ADD_UNDOABLE_ACTION };
}
function Undo() {
  return (dispatch, getState) => {
    const tempPhotoMapping = photoListSelector.getTempPhotoToNewPhotoMapping(
      getState()
    );
    dispatch({ type: UNDO, tempPhotoMapping });
  };
}
function Redo() {
  return (dispatch, getState) => {
    const tempPhotoMapping = photoListSelector.getTempPhotoToNewPhotoMapping(
      getState()
    );
    dispatch({ type: REDO, tempPhotoMapping });
  };
}
function CopyFrame() {
  return { type: FRAME_COPY };
}
function CutFrame() {
  return (dispatch, getState) => {
    batch(() => {
      dispatch({ type: FRAME_CUT });
      dispatch(AddUndoableAction());
    });
  };
}

function PasteFrame() {
  return (dispatch, getState) => {
    const editionState = getState().edition;
    const { backendPhotosByID } = getState().photos;

    let pastedFrame: Frame = cloneDeep(editionState.clipboardFrame);
    pastedFrame = checkReplaceTempPhoto(pastedFrame, getState());
    const selectedPage: IPage = cloneDeep(
      editionState.project.pageList[editionState.selectedPage]
    );
    const selectedFrame: Frame = editionState.selectedFrameID
      ? getFrameByID(editionState.selectedFrameID, [selectedPage])
      : null;

    // case paste photo inside another frame
    if (
      selectedFrame &&
      selectedFrame.id !== pastedFrame.id &&
      pastedFrame.photo &&
      (selectedFrame.type === FRAME_TYPE.PHOTO ||
        selectedFrame.type === FRAME_TYPE.BKG)
    ) {
      // paste inside another frame
      InjectPhotoIntoFrame(selectedFrame, backendPhotosByID[pastedFrame.photo]);
    }
    // case paste background (replacing)
    else if (pastedFrame.type === FRAME_TYPE.BKG) {
      const oldBkgID = selectedPage.frames[0].id;
      selectedPage.frames[0] = pastedFrame;
      pastedFrame.id = oldBkgID;
    }
    // case paste as new in current page
    else {
      // paste as new frame in current selected page
      pastedFrame.id = GetUID();
      pastedFrame.x = selectedPage.width / 2;
      pastedFrame.y = selectedPage.height / 2;
      pastedFrame.rotation = 0;
      selectedPage.frames.push(pastedFrame);
    }

    // make the action
    batch(() => {
      dispatch({ type: PAGE_UPDATE, page: selectedPage });
      dispatch({
        type: FRAME_PASTE_COMPLETED,
        selectedFrameID: pastedFrame.id,
      });
      dispatch(AddUndoableAction());
    });
  };
}

function ClearProject(): Promise {
  return { type: CLEAR_PROJECT };
}

function UpdatePage(page: IPage) {
  return { type: PAGE_UPDATE, page };
}

function UpdateFrame(frame: Frame) {
  return { type: FRAME_UPDATE, frame };
}

function SwapPages(fromPageIndex: number, toPageIndex: number) {
  console.log(
    `Swap from pageIndex: ${fromPageIndex} to page index: ${toPageIndex}`
  );

  return (dispatch, getState) => {
    const pageList: Array<IPage> = cloneDeep(
      getState().edition.project.pageList
    );
    const fromPage = pageList[fromPageIndex];
    const toPage = pageList[toPageIndex];
    pageList[fromPageIndex] = toPage;
    pageList[toPageIndex] = fromPage;

    // update index inside page too
    fromPage.index = toPageIndex;
    toPage.index = fromPageIndex;

    // dispatch
    batch(() => {
      dispatch(updatePageList(pageList, toPageIndex));
      // add undoable
      dispatch(AddUndoableAction());
    });
  };
}

function MovePageGroup(groupFromIndex: number, groupToIndex: number) {
  console.log(
    `Move group of page from index: ${groupFromIndex} to index: ${groupToIndex}`
  );

  return (dispatch, getState) => {
    // get Group!
    const groups = editionSelectors.GetPageGroupListSelector(getState());
    const pagesToMove = groups[groupFromIndex].map(
      (page, index, arr) => page.index
    );
    const newIndex =
      groups[groupToIndex][groups[groupToIndex].length - 1].index + 1; // +1 because we inject just after!
    console.log(`Moving pages ${pagesToMove} at ${newIndex}`);

    const pageList: Array<IPage> = cloneDeep(
      getState().edition.project.pageList
    );

    // remove pages to move
    const numPagesToMove = pagesToMove.length;
    const startMoveIndex = pagesToMove[0];
    const pagesMoved = pageList.splice(startMoveIndex, numPagesToMove);

    // now insert back
    const insertIndex =
      startMoveIndex < newIndex ? newIndex - numPagesToMove : newIndex;
    pagesMoved.forEach((page, i, arr) => {
      pageList.splice(insertIndex + i, 0, page);
    });

    // now modify page indexes
    pageList.forEach((page, index, array) => {
      page.index = index;
    });

    // dispatch
    batch(() => {
      dispatch(updatePageList(pageList, insertIndex));
      // add undoable
      dispatch(AddUndoableAction());
    });
  };
}

function UpdateProjectPageAmount(
  newPageAmount: number,
  stayAtCurrentPage: boolean,
  warnIfPageChange = false
) {
  return (dispatch, getState) => {
    const { project } = getState().edition;
    let pageDiff = 0;

    if (IsAlbumEditor()) {
      newPageAmount += 1;
    }

    const pageList = cloneDeep(project.pageList);
    const currentPageAmount = pageList.length;

    // case remove at the end
    if (currentPageAmount > newPageAmount) {
      pageDiff = currentPageAmount - newPageAmount;
      pageList.splice(currentPageAmount - pageDiff, pageDiff);
    }

    // case add at the end
    else if (currentPageAmount < newPageAmount) {
      pageDiff = newPageAmount - currentPageAmount;
      const newPages = CreateProjectPages(
        project,
        pageDiff,
        currentPageAmount,
        getState().layouts
      );
      InjectPagesAtIndex(pageList, newPages, currentPageAmount);
    }

    // warn page change
    if (
      IsAlbumEditor() &&
      warnIfPageChange &&
      currentPageAmount !== newPageAmount
    ) {
      popupHelper.showProjectNumPageChange(
        currentPageAmount - 1,
        newPageAmount - 1,
        null
      );
    }

    let newSelectedPage = getState().edition.selectedPage;
    if (!stayAtCurrentPage || newSelectedPage >= pageList.length) {
      newSelectedPage = pageList.length - 1;
    }

    batch(() => {
      dispatch(updatePageList(pageList, newSelectedPage));
      // add undoable
      dispatch(AddUndoableAction());
    });
  };
}

// simple action
function updatePageList(newPageList: Array<IPage>, newSelectedPage: number) {
  return { type: UPDATE_PAGE_LIST, newPageList, newSelectedPage };
}

/**
 * Upgrade current project
 * -> recover project from global state
 * -> upgrade docID and type
 * -> apply optional option changes
 * -> upgrade size
 * -> update project on global state
 * -> check stock and update stock options
 * -> check project pages and update pages.
 * @param params : project creation params that will override current project options
 * @param warnAfterPageChange : show a popup if project page amount did change
 */
function UpgradeCurrentProject(
  params: ProjectCreationParams,
  warnAfterPageChange = true
) {
  return (dispatch, getState) => {
    // retrieve current product
    const projectCopy: Project = cloneDeep(getState().edition.project);
    const oldDocId = projectCopy.docID;

    // update common
    if (params.type) {
      projectCopy.type = params.type;
    }
    if (params.docID) {
      projectCopy.docID = params.docID;
    }

    // retrieve new doc
    const doc = GetDoc(projectCopy.docID);

    // apply options
    if (params.options) {
      ApplyOptionsToProject(projectCopy, params.options);
    }

    // update size
    let newWidth = doc.width * doc.multiplier;
    let newHeight = doc.height * doc.multiplier;
    if (IsCanvasEditor() && projectCopy.canvasFormat === CANVAS_FORMAT.FREE) {
      newWidth = cmToPixel(params.canvasFreeWidth);
      newHeight = cmToPixel(params.canvasFreeHeight);
    }

    // upgrade project size
    UpgradeProjectSize(
      projectCopy,
      newWidth,
      newHeight,
      getState().layouts,
      backgroundSelectors.getAllBackgroundsByID(getState())
    );

    // for calendar upgrades, we need to update calendar fontsize scaling
    if (IsCalendarEditor()) {
      // if there is a "font scaling" option in the catalogue, use this. Otherwise use calssic width/heigh ratio
      let newFontScaling = doc.layoutTextScaling;
      let previousFontScaling = GetDoc(oldDocId).layoutTextScaling;
      let upgradeScaling = 1;

      // case no font scaling for layouts
      if (!newFontScaling && !previousFontScaling) {
        const oldDoc = GetDoc(oldDocId);
        upgradeScaling = newHeight / (oldDoc.height * oldDoc.multiplier);
      }
      // case there is a layout text scaling to apply
      else {
        if (!newFontScaling) newFontScaling = 1;
        if (!previousFontScaling) previousFontScaling = 1;
        upgradeScaling = newFontScaling / previousFontScaling;
      }

      if (IsDebug) {
        alert(
          `upgrade font scaling (${previousFontScaling} to ${newFontScaling})= X${upgradeScaling}`
        );
      }

      projectCopy.pageList.forEach((page) => {
        page.frames.forEach((frame) => {
          if (frame.type === FRAME_TYPE.CALENDAR) {
            frame.calendarOptions.fontSize = Math.round(
              frame.calendarOptions.fontSize * upgradeScaling
            );
          }
        });
      });
    }

    // make the action
    batch(() => {
      // upgrade
      dispatch({ type: UPGRADE_PROJECT, project: projectCopy });
      // check stock
      dispatch(checkProjectStock());
      // update project page amount
      if (params.numPages) {
        dispatch(
          UpdateProjectPageAmount(params.numPages, true, warnAfterPageChange)
        );
      } else {
        dispatch(AddUndoableAction());
      }
    });
  };
}

/**
 * Add pages at specific index in album
 */
function AddPagesAtIndex(pageIndex: number) {
  return (dispatch, getState) => {
    const { project } = getState().edition;
    const numPagesToAdd = IsAlbumEditor() ? 2 : 1; // TODO:check this depending on classname

    // add pages to page list copy
    const newPages = CreateProjectPages(
      project,
      numPagesToAdd,
      pageIndex,
      getState().layouts
    );
    const pageList = cloneDeep(project.pageList);
    InjectPagesAtIndex(pageList, newPages, pageIndex);

    // dispatch page list update
    dispatch(updatePageList(pageList, pageIndex));
    // add undoable
    dispatch(AddUndoableAction());
  };
}

function UpdateProjectOptions(newProjectOptions: ProjectOptions) {
  return (dispatch) => {
    batch(() => {
      dispatch({ type: UPDATE_PROJECT_OPTIONS, options: newProjectOptions });
      dispatch(AddUndoableAction());
    });
  };
}

function CheckRemapProjectTempPhotos(project: Project, tempPhotoMapping) {
  if (project && tempPhotoMapping) {
    project.pageList.forEach((page: IPage, pageIndex) => {
      page.frames.forEach((frame: Frame, frameIndex) => {
        if (frame.photo && tempPhotoMapping[frame.photo]) {
          console.log(
            `CheckRemapPhoto: we replaced ${frame.photo} by  ${
              tempPhotoMapping[frame.photo]
            }`
          );
          frame.photo = tempPhotoMapping[frame.photo];
        }
      });
    });
  }
  return project;
}

function ClearPerPageCalendarOptions() {
  console.log('Clear calendar color options per pages');
  return (dispatch, getState) => {
    const newPageList: Array<IPage> = cloneDeep(
      getState().edition.project.pageList
    );
    newPageList.forEach((page) => {
      page.calendarColorOptions = null;
    });

    // dispatch
    batch(() => {
      dispatch(updatePageList(newPageList));
    });
  };
}

function ChangePage(newPageIndex) {
  return { type: CHANGE_SELECTED_PAGE, selectedPage: newPageIndex };
}
function ChangeSelectedFrame(frameID) {
  return { type: CHANGE_SELECTED_FRAME, selectedFrameID: frameID };
}

function UpdateProjectName(projectName) {
  return (dispatch) => {
    batch(() => {
      dispatch({ type: UPDATE_PROJECT_NAME, newName: projectName });
      dispatch(AddUndoableAction());
    });
  };
}

function RemapProjectTempPhotosWithUploadedPhotos(tempPhotoID, newPhotoID) {
  return { type: REMAP_TEMP_PHOTOS, tempPhotoID, newPhotoID };
}

function CreateProject(projectObj) {
  return (dispatch) => {
    // clear
    dispatch(ClearProject());

    // create project
    dispatch({ type: CREATE_PROJECT, project: projectObj });

    // be sure to clean the current project photo list
    dispatch(photoListActions.updateProjectPhotoList({}));

    // update tabls
    dispatch(UIActions.changeMainTab(TABS_ENUM.PHOTO, true));

    // update history
    history.push(ROUTE_CONST.HOME);
  };
}

/** **********************************
// COMPLEX ASYNC ACTIONS
************************************ */

function LoadProject(projectID) {
  return (dispatch, getState) => {
    const layoutStore = getState().layouts;

    // Case NO ID, should only happen on debug mode
    if (!projectID) {
      alert('LoadProject without project ID is not allowed..');
      // const proj = ConvertOldEditorJson();
      // CleanAndVerifyProject(proj, layoutStore );
      // dispatch( onSuccess(proj));
    }

    // otherwise we load!
    else {
      // update store by telling we are "requesting"
      dispatch(onStart());

      // call service
      API.loadProject(projectID).then(
        (jsonResponse) => {
          let project = null;
          let projectPhotosByID = null;

          // previous object was only the project, but now we have an object composed of multiple elements (project, photos)
          if (jsonResponse.project) {
            // new with photos included!
            project = jsonResponse.project;
            projectPhotosByID = jsonResponse.photosByID;
          } else {
            // old
            project = jsonResponse;
          }

          // the first time we save the project, we receive the ID from the backend.
          // it means the project ID is not saved inside the project. Which means we need to override this!
          if (!project.id) {
            project.id = projectID;
          }

          // add sentry context
          Sentry.setContext('project', {
            id: project.id,
            classname: project.classname,
            docID: project.docID,
            type: project.type,
          });

          // this should never happen
          if (projectID !== project.id) {
            dispatch(
              onFailure(
                `Project loaded has not the same id as the one requested : ${projectID} vs ${project.id}`
              )
            );
            return;
          }

          // Check if we are in the correct editor version!
          if (project.classname !== PROJECT_CONST.project_class) {
            // TODO: open a window to ask if we want to load this project, and so change editor.
            // TODO: FOR now we do nothing.. just display a simple message..
            const error = `Project of type "${project.classname}" cannot be loaded in "${PROJECT_CONST.project_class}" editor`;
            dispatch(onFailure(error));
            return;
          }

          // remap possibly temp photos with temp photo map from cookie
          project = CheckRemapProjectTempPhotos(
            project,
            photoListSelector.getTempPhotoToNewPhotoMapping(getState())
          );

          // once a project has been loaded, we just make a simple clean and verify.
          const verifyError = CleanAndVerifyProject(project, getState());
          if (verifyError) {
            console.error(verifyError);

            Modal.warn({
              title: 'Project decompress error',
              content: (
                <>
                  <p>
                    {`There was an issue while trying to open this project:`}{' '}
                    <b>{project?.name}</b>
                  </p>
                  <code style={{ fontSize: '0.8em' }}>{verifyError}</code>
                </>
              ),
            });

            dispatch(onFailure(verifyError));
            return;
          }

          // clean project photo list
          const { backendPhotosByID } = getState().photos;
          // TODO: keep this rule?
          // if(!backendPhotosByID || isEmpty(backendPhotosByID) && !isEmpty(projectPhotosByID))
          // {
          //   alert("THIS SHOULD NOT HAPPEN, photo list is empty! " + JSON.stringify(backendPhotosByID));
          // }

          // recover and clean project photo list
          projectPhotosByID = CleanProjectPhotoList(
            project,
            projectPhotosByID,
            backendPhotosByID
          );

          // once project is loaded, update the project photo list!
          batch(() => {
            dispatch(
              photoListActions.updateProjectPhotoList(projectPhotosByID)
            );
            // also we can notify we had some success to load
            // dispatch(alertActions.success(`Successfully loaded project with id ${projectID}`));
            dispatch(onSuccess(project));
            // be sure also to go to correct top
            dispatch(UIActions.changeMainTab(TABS_ENUM.PHOTO, true));
            // store user details in local storage to keep user logged in between page refreshes
            // localStorage.setItem('user', JSON.stringify(user));
            // history.push('/');
            dispatch(checkProjectStock());
          });
        },
        (error) => {
          dispatch(onFailure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
    }

    function onStart() {
      return (dispatch) => {
        batch(() => {
          dispatch(
            UIActions.UpdateMainLoading(true, GetText('loading.project.load'))
          );
          dispatch({ type: PROJECT_LOAD_START });
        });
      };
    }
    function onSuccess(project) {
      return (dispatch, getState) => {
        batch(() => {
          dispatch(UIActions.UpdateMainLoading(false));
          project = CheckRemapProjectTempPhotos(
            project,
            photoListSelector.getTempPhotoToNewPhotoMapping(getState())
          );
          dispatch({ type: PROJECT_LOAD_SUCCESS, project });
        });
      };
    }
    function onFailure(error) {
      return (dispatch) => {
        batch(() => {
          dispatch(UIActions.UpdateMainLoading(false));
          dispatch({ type: PROJECT_LOAD_FAIL, error });
        });
      };
    }
  };

  // TODO: make correct project load
  /*
  return ( dispatch ) => {

      // update store by telling we are "requesting"
      dispatch(request({ username }));

      // call service
      API.login(username, password)
        .then(
            user => {
                dispatch(success(user));
                // store user details in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
                history.push('/');
              },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
          );
  };

  function request(user) { return { type: LOGIN_REQUEST, user };}
  function success(user) { return { type: LOGIN_SUCCESS, user };}
  function failure(error) { return { type: LOGIN_FAILURE, error };}
  */
}

/**
 * save project online
 * -> get back the project ID
 */
function SaveProject(asCopy) {
  // dispatch
  return (dispatch, getState) => {
    // update store by telling we are "requesting"
    dispatch(onStart());

    // recover project
    const project: Project = cloneDeep(getState().edition.project);

    // recover project photos
    const { projectPhotosByID } = getState().photos;

    // if we need a copy, just remove the project ID
    if (asCopy) {
      project.id = null;
    }

    // call service
    API.saveProject(project, projectPhotosByID).then(
      (projectID) => {
        dispatch(onSuccess(projectID));
        // alert not needed as we have a spinner and this is not translated
        dispatch(alertActions.success(GetText('loading.project.save.success')));
        // store user details in local storage to keep user logged in between page refreshes
        // localStorage.setItem('user', JSON.stringify(user));
        // history.push('/');
      },
      (error) => {
        dispatch(onFailure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function onStart() {
    return { type: PROJECT_SAVE_START };
  }
  function onSuccess(projectID) {
    return { type: PROJECT_SAVE_SUCCESS, projectID };
  }
  function onFailure(error) {
    return { type: PROJECT_SAVE_FAIL, error };
  }
}

function makeAutoFill() {
  return (dispatch, getState) => {
    const state = getState();
    const unusedPhotos = cloneDeep(GetAllUnusedPhotosSelector(state));
    // alert("Magic fill with " + unusedPhotos + " unused images");

    dispatch({ type: AUTOFILL, unusedPhotos });
    dispatch(AddUndoableAction());
  };
}

function ApplyBackgroundToAllPages(backgroundID: string, colorHex: string) {
  return (dispatch, getState) => {
    const pageList = cloneDeep(getState().edition.project.pageList);
    const { selectedPage } = getState().edition;

    // find background item
    let backgroundItem: BackgroundItem;
    if (backgroundID) {
      backgroundItem = backgroundSelectors.getAllBackgroundsByID(getState())[
        backgroundID
      ];
    }

    pageList.forEach((page: IPage, index, arr) => {
      // apply if:
      //    - not classic cover
      //    - the current background frame do not have a photo
      //    - do not apply to cover neither, nor we are already on a cover page
      if (
        !IsClassicCoverPage(page) &&
        !page.frames[0].photo &&
        !(page.isCover && selectedPage !== 0)
      ) {
        if (backgroundID) {
          ApplyBackgroundToPage(page, backgroundItem);
        } else if (colorHex !== null) {
          ApplyBackgroundFillToPage(page, colorHex);
        } else {
          console.warn(
            `Cannot apply background to all pages for backgroundID:${backgroundID} && color:${colorHex}`
          );
        }
      }
    });

    // make the action
    batch(() => {
      dispatch(updatePageList(pageList, selectedPage));
      dispatch(AddUndoableAction());
    });
  };
}

function ApplyLayoutToAllPages(layoutID: string) {
  return (dispatch, getState) => {
    const proj = getState().edition.project;
    const pageList = cloneDeep(proj.pageList);
    const { selectedPage } = getState().edition;

    // find background item
    const layoutItem: Layout = layoutListSelectors.getLayoutsByID(getState())[
      layoutID
    ];
    const { docID } = getState().edition.project;

    pageList.forEach((page: IPage, index, arr) => {
      if (!IsCover(docID, index)) {
        // do not apply to all for covers!
        // check if we can apply
        if (layoutItem.type === GetPageLayoutType(page, docID)) {
          ApplyLayoutToPage(
            proj,
            page,
            layoutItem,
            photoListSelector.getAllPhotosByID(getState()),
            backgroundSelectors.getAllBackgroundsByID(getState()),
            getPageBackgroundRect(getState().edition.project)
          );
        }
      }
    });

    // make the action
    batch(() => {
      dispatch(updatePageList(pageList, selectedPage));
      dispatch(AddUndoableAction());
    });
  };
}

function ApplyBorderToAll(borderSize: number, borderColorHex: string) {
  return (dispatch, getState) => {
    const pageList = cloneDeep(getState().edition.project.pageList);
    const { selectedPage } = getState().edition;

    // run through pages
    pageList.forEach((page: IPage, index, arr) => {
      if (!IsClassicCoverPage(page)) {
        page.frames.forEach((frame: Frame, index, arr) => {
          if (frame.type === FRAME_TYPE.PHOTO) {
            frame.border = borderSize;
            frame.borderColor = borderColorHex;
          }
        });
      }
    });

    // make the action
    batch(() => {
      dispatch(updatePageList(pageList, selectedPage));
      dispatch(AddUndoableAction());
    });
  };
}

function ApplyBorderRadiusToAll(borderRadius: number) {
  return (dispatch, getState) => {
    const pageList = cloneDeep(getState().edition.project.pageList);
    const { selectedPage } = getState().edition;

    // run through pages
    pageList.forEach((page: IPage, index, arr) => {
      if (!IsClassicCoverPage(page)) {
        page.frames.forEach((frame, index, arr) => {
          if (frame.type === FRAME_TYPE.PHOTO && frame.photo) {
            frame.borderRadius = borderRadius;
          }
        });
      }
    });

    // make the action
    batch(() => {
      dispatch(updatePageList(pageList, selectedPage));
      dispatch(AddUndoableAction());
    });
  };
}

function ApplyTextOptionsToAll(editedFrame: Frame) {
  return (dispatch, getState) => {
    const textOptions: TextOptions = editedFrame.text;
    const pageList = cloneDeep(getState().edition.project.pageList);
    const { selectedPage } = getState().edition;

    // run through pages
    pageList.forEach((page: IPage, index, arr) => {
      if (!IsClassicCoverPage(page)) {
        page.frames.forEach((frame: Frame, index, arr) => {
          if (frame.type === editedFrame.type && frame.text) {
            const tval = frame.text.value;
            frame.text = cloneDeep(textOptions);
            frame.text.value = tval;
            frame.fillColor = editedFrame.fillColor;
          }
        });
      }
    });

    // make the action
    batch(() => {
      dispatch(updatePageList(pageList, selectedPage));
      dispatch(AddUndoableAction());
    });
  };
}

function ApplyShadowToAll(shadow: IFrameShadow) {
  return (dispatch, getState) => {
    const pageList = cloneDeep(getState().edition.project.pageList);
    const { selectedPage } = getState().edition;

    // run through pages
    pageList.forEach((page: IPage, index, arr) => {
      if (!IsClassicCoverPage(page)) {
        page.frames.forEach((frame: Frame, index, arr) => {
          if (frame.type === FRAME_TYPE.PHOTO && !IsFrameEmpty(frame)) {
            frame.shadow = cloneDeep(shadow);
          }
        });
      }
    });

    // make the action
    batch(() => {
      dispatch(updatePageList(pageList, selectedPage));
      dispatch(AddUndoableAction());
    });
  };
}

// ---- SWAP FRAME CONTENT (photos only for now) ----
function SwapFrameContent(firstFrameID: string, secondFrameID: string) {
  return (dispatch, getState) => {
    const state = getState();
    const pageList = cloneDeep(getState().edition.project.pageList);
    let firstFrame;
    let secondFrame;

    // run through pages to find frames
    pageList.forEach((page: IPage, pageIndex, pageArr) => {
      // run through all frames
      page.frames.forEach((frame: Frame, frameIndex, frameArr) => {
        if (frame.id === firstFrameID) {
          firstFrame = frame;
        } else if (frame.id === secondFrameID) {
          secondFrame = frame;
        }
      });
    });

    // swap frame photos
    const firstPhoto = firstFrame.photo;
    const secondPhoto = secondFrame.photo;
    ClearFrame(firstFrame);
    ClearFrame(secondFrame);
    const photosByID = photoListSelector.getAllPhotosByID(state);
    if (firstPhoto) {
      InjectPhotoIntoFrame(secondFrame, photosByID[firstPhoto]);
    }
    if (secondPhoto) {
      InjectPhotoIntoFrame(firstFrame, photosByID[secondPhoto]);
    }

    // update pages
    batch(() => {
      dispatch(
        updatePageList(pageList, editionSelectors.GetSelectedPageIndex(state))
      );
      dispatch(AddUndoableAction());
    });
  };
}

/**
 * check project stock
 * -> for albums, check cover classic options and update project if needed
 */
function checkProjectStock() {
  return (dispatch, getState) => {
    // verify stock !
    const project = GetProjectSelector(getState());
    if (IsAlbumEditor() && IsClassicCoverProject(project.type)) {
      const coverClassicStock = pricingSelectors.GetClassicCoverStock(
        getState()
      );
      const coverClassicOptions = GetClassicCoverOptions(project);
      const coverID = coverClassicOptions.coverLabelName;

      if (!(coverID in coverClassicStock) || !coverClassicStock[coverID]) {
        alert(`NO MORE STOCK FOR COVER:${coverClassicOptions.cover}`);
        // console.log("No more stock for cover:" + coverClassicOptions.cover + ", setting default");
        const cover = cloneDeep(GetProjectCover(project));
        // update correct element
        cover.coverClassicOptions.coverLabelName = 'leatherblack';
        cover.coverClassicOptions.coverFabric =
          COVER_CLASSIC_FABRIC_TYPE.LEATHER;
        cover.coverClassicOptions.cover =
          CoverLabelNameToCoverDisplayName('leatherblack');

        batch(() => {
          dispatch(editionActions.UpdatePage(cover));
        });
      }
    }
  };
}

// --------------------- SELECTORS ------------------------

// https://medium.com/@matthew.holman/what-is-a-redux-selector-a517acee1fe8

// simple selectors (not combined)
const GetProjectSelector = (state) =>
  state.edition.project ? state.edition.project : null;
const GetPageListSelector = (state) =>
  state.edition.project ? state.edition.project.pageList : null;
const GetProjectName = (state) =>
  state.edition.project ? state.edition.project.name : null;
const GetProjectDocID = (state) =>
  state.edition.project ? state.edition.project.docID : null;
const GetSelectedPageIndex = (state) => state.edition.selectedPage;
const GetAllPhotoListSelector = (state) =>
  photoListSelector.getAllPhotoList(state);

// TODO: this should probably be renameed as "editorClassname"
// const GetProjectClassName = state => state.edition.projectClassname;

const GetProjectCoverSelector = createSelector(
  GetPageListSelector,
  (pageList) => {
    if (IsAlbumEditor()) return pageList[0];
    return null;
  }
);

const GetProjectSizeRatioSelector = createSelector(
  (state) => (state.edition.project ? state.edition.project.width : 0),
  (state) => (state.edition.project ? state.edition.project.height : 0),
  (projectWidth, projectHeight) => projectWidth / projectHeight
  // return (Math.round(projectWidth) === Math.round(projectHeight))? "square" : (projectWidth>projectHeight)? "landscape" : "portrait";
);

const GetProjectCoverSizeRatioSelector = createSelector(
  GetProjectCoverSelector,
  (coverPage: IPage) => (coverPage ? coverPage.width / coverPage.height : 0)
);

const NeedSave = createSelector(
  (state) => state.edition.project,
  (state) => state.edition.lastSaveTime,
  (state) => state.edition.lastEditTime,
  (state) => photoListSelector.hasPhotosStillImporting(state),
  (project, lastSaveTime, lastEditTime, hasPhotosStillImporting) =>
    project && (lastSaveTime < lastEditTime || hasPhotosStillImporting)
  // BIG BUG Here, if we make an autofill with importing images, then we save the project,
  // -> then images are updated automatically (remap) but project do not seem to need a save..
  // -> client leave without warning, when opening again, still haveing "rc-upload" images..
  // -> WE NEED TO BE SURE ALL IMAGES ON THE LEFT ARE IN THE "project" LIST
  // [ EDIT ] we updatd the lastEditTime after a photo upload!
);

const GetProjectDocumentSelector = createSelector(
  (state) => (state.edition.project ? state.edition.project.docID : null),
  (docID) => (docID ? GetDoc(docID) : null)
);

const GetProjectOptionsSelector = createSelector(
  (state) => (state.edition.project ? state.edition.project : null),
  (project: Project) => {
    if (!project) return null;
    const options: ProjectOptions = GetProjectOptions(project);
    return options;
  }
);

const GetFrameListSelector = createSelector(GetPageListSelector, (pageList) => {
  const frameList = [];
  if (pageList) {
    pageList.forEach((page, index) => {
      page.frames.forEach((frame, index) => {
        frameList.push(frame);
      });
    });
  }
  return frameList;
});

const HasMaxPages = createSelector(
  (state) =>
    state.edition.project ? state.edition.project.pageList.length : 0,
  (state) =>
    state.edition.project ? state.edition.project.pagePaperQuality : 0,
  GetProjectDocumentSelector,
  (numPages, pagePaperQuality, document) => {
    if (document) {
      const maxPages =
        pagePaperQuality === PAPER_QUALITY.QUALITY_250
          ? document.maxPages
          : 200; // max pages for all albums = 200 if not premium 250gr
      return numPages >= maxPages;
    }
    return false;
  }
);

const GetPhotoUsedSelector = createSelector(
  GetFrameListSelector,
  (frameList) => {
    const photosUsed = {};
    if (frameList) {
      frameList.forEach((frame, index) => {
        if (frame.photo) {
          photosUsed[frame.photo] = true;
        } // TODO: maybe put amount here so we know how many times it is used!
      });
    }
    return photosUsed;
  }
);

/**
 * Retreive list of all unused photos
 */
const GetAllUnusedPhotosSelector = createSelector(
  GetPhotoUsedSelector,
  GetAllPhotoListSelector,
  (photoUsed, allPhotosList: Array) =>
    allPhotosList.filter((item: Photo) => !photoUsed[item.id] && item.width) // if item has a width we are ok
);

const GetPageGroupListSelector = createSelector(
  GetPageListSelector, // recover pagelist
  GetProjectDocID,
  (pageList, docID) => GetPageGroupList(docID, pageList)
);

const GetCurrentVisiblePages: Array<IPage> = createSelector(
  GetProjectDocID,
  (state) => state.edition.selectedPage,
  GetPageListSelector,
  (docID, selectedPageIndex, pageList) =>
    getPageGroup(docID, selectedPageIndex, pageList)
);

const GetAllVisibleFramesByID: Record<string> = createSelector(
  GetCurrentVisiblePages, // recover visible pages
  (visiblePageList) => {
    const visibleFrames = {};
    visiblePageList.forEach((page) => {
      page.frames.forEach((frame) => {
        visibleFrames[frame.id] = cloneDeep(frame);
      });
    });
    return visibleFrames;
  }
);

const GetSelectedPage: IPage = createSelector(
  (state) => state.edition.selectedPage,
  GetPageListSelector,
  (selectedPageIndex, pageList) => {
    if (!pageList || pageList.length <= selectedPageIndex) return null;
    return pageList[selectedPageIndex];
  }
);

const GetSelectedFrame: Frame = createSelector(
  (state) => state.edition.selectedFrameID,
  GetPageListSelector,
  (selectedFrameID, pageList: Array) => getFrameByID(selectedFrameID, pageList)
);

const allowUndo = createSelector(
  (state) => state.edition,
  (edition) =>
    edition.history &&
    edition.history.length > 1 &&
    edition.historyIndex < edition.history.length - 1
);

function allowRedo(state) {
  const { edition } = state;
  return edition.history && edition.historyIndex > 0;
}

const allowCopy = createSelector(
  GetSelectedFrame,
  // TODO: updat this rule!
  (selectedFrame: Frame) =>
    selectedFrame &&
    (selectedFrame.type === FRAME_TYPE.PHOTO ||
      selectedFrame.type === FRAME_TYPE.CLIPART ||
      selectedFrame.type === FRAME_TYPE.OVERLAYER ||
      selectedFrame.type === FRAME_TYPE.TEXT)
);

const allowPaste = createSelector(
  (state) => state.edition.clipboardFrame,
  GetSelectedPage,
  (clipboardFrame, selectedPage: IPage) => {
    // no frame to paste
    if (!clipboardFrame) return false;
    // do not allow to paste on a classic page
    if (!selectedPage || IsClassicCoverPage(selectedPage)) return false;
    // otherwise we are good..
    return true;
  }
);

// --------------------- Helpers ------------------------

function getFrameByID(frameID: string, pageList: Array<IPage>): Frame {
  let theFrame = null;
  if (pageList && frameID) {
    pageList.forEach((page: IPage, pageIndex, pagesArr) => {
      page.frames.forEach((frame: Frame, frameIndex, framesArr) => {
        if (frame.id === frameID) {
          theFrame = frame;
        }
      });
    });
  }
  return theFrame;
}

/**
 * TODO: this is not working as if we change something in the pagelist, this is not "re rendered"
 * we should use some kind of "page by id" to get this working correctly
 */
// const GetCurrentPageGroup = createSelector(
//   state => state.edition.selectedPage,
//   state => (state.edition.project)? state.edition.project.pageList : null,
//   state => state.edition.projectClassname,
//   ( selectedPageIndex, pageList, projectClassname ) => {
//     return getPageGroup( selectedPageIndex, pageList, projectClassname );
//   }
// )

/** **********************************
// EXPORT PUBLIC ACTIONS & selectors
************************************ */

export const editionSelectors = {
  GetProjectSelector,
  GetProjectName,
  GetProjectCoverSelector,
  GetProjectSizeRatioSelector,
  GetProjectCoverSizeRatioSelector,

  GetSelectedPageIndex, // page index
  GetSelectedPage, // page
  allowUndo,
  allowRedo,
  allowCopy,
  allowPaste,
  GetPhotoUsedSelector,
  GetAllUnusedPhotosSelector,
  HasMaxPages,
  NeedSave,
  GetAllVisibleFramesByID,

  GetPageGroupListSelector,
  GetProjectOptionsSelector,
};

export const editionActions = {
  // set project/editor calssname
  // SetProjectClassname,

  // change currently edited page
  ChangePage,
  ChangeSelectedFrame,
  SwapFrameContent,

  // update page
  UpdatePage,
  UpdateProjectPageAmount,
  AddPagesAtIndex,
  SwapPages,
  MovePageGroup,

  // auto fill
  makeAutoFill,

  // update a single frame
  UpdateFrame,
  // remap temp photos that has been uploaded
  RemapProjectTempPhotosWithUploadedPhotos,

  // project options
  UpdateProjectName,
  UpdateProjectOptions,
  UpgradeCurrentProject,

  // create a new project
  CreateProject,
  // load a project
  LoadProject,
  // save currently edited project
  SaveProject,
  // clear project
  ClearProject,

  // global frame apply
  ApplyBackgroundToAllPages,
  ApplyBorderToAll,
  ApplyLayoutToAllPages,
  ApplyBorderRadiusToAll,
  ApplyShadowToAll,
  ApplyTextOptionsToAll,

  // calendar only
  ClearPerPageCalendarOptions,

  // --- UNDO ---
  AddUndoableAction,
  Undo,
  Redo,
  CopyFrame,
  CutFrame,
  PasteFrame,
};
