import { isObject } from 'lodash';
import ReactGA from 'react-ga';

let initialized: boolean;

function Initialize() {
  const trackingId = 'UA-56676170-4'; // Replace with your Google Analytics tracking ID
  ReactGA.initialize(trackingId);
  initialized = true;

  // ReactGA.set({
  //   userId: auth.currentUserId(),
  //   // any data that is relevant to the user session
  //   // that you would like to track with google analytics
  // })
}

function TrackPage(pathName: string) {
  if (!initialized) return;

  ReactGA.set({ page: pathName }); // Update the user's current page
  ReactGA.pageview(pathName); // Record a pageview for the given page
}

function TrackError(errorMessage, fatal = false) {
  if (!initialized) return;

  ReactGA.exception({
    description: isObject(errorMessage)
      ? errorMessage.errorMessage
      : errorMessage,
    fatal,
  });
}

function TrackCustomEvent(category: string, action: string) {
  if (!initialized) return;

  ReactGA.event({
    category, // "Sign Up",
    action, // "User pressed the big blue sign up button",
  });
}

export const Analytic = {
  Initialize,
  TrackPage,
  TrackError,
  TrackCustomEvent,
};
