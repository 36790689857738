import React from 'react';
import { connect } from 'react-redux';
import { Popover, InputNumber, Button, Row, Col } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import {
  GetProjectMaxPages,
  GetProjectMinPages,
} from '../../../utils/projectHelper';
import { editionActions } from '../../../feature/edition/edition';
import { GetText } from '../../../data/LanguageHelper';
import { popupHelper } from '../../../feature/alert/popupHelper';

/**
 * Photo item
 */
class ProjectPageModifierPopup extends React.Component {
  // --------------------- Constructor ------------------------

  constructor(props) {
    super(props);
    this.state = {
      numPages: this.props.pageList.length - 1,
      pageStep: 2,
      popoverVisible: false, // to manage popup visibility..
      // popOverVisible : false,
    };
  }

  // --------------------- react ------------------------

  // shouldComponentUpdate( nextProps, nextState )
  // {
  //     const projectPagesChange = this.props.pageList.length !== nextProps.pageList.length;
  //     const usedChange = nextProps.used !== this.props.used;
  //     const loadingChange = nextState.isLoading !== this.state.isLoading;
  //     const tempPhotoChange = ((nextProps.photo.temp))?true:false;// && (nextProps.photo.width !==this.props.photo.width))? true : false;
  //     const popOverVisibilityChange = nextState.popOverVisible !== this.state.popOverVisible;

  //     //selected:PropTypes.bool,
  //     //page: PageType,
  //     //photos: PropTypes.arrayOf(PhotoType)
  //     return selectionChange || tempPhotoChange || loadingChange || usedChange || popOverVisibilityChange;
  //     // return selectionChange;
  // }

  componentDidUpdate(nextProps, nextState) {
    if (nextProps.pageList.length !== this.props.pageList.length) {
      this.setState({
        numPages: this.props.pageList.length - 1,
      });
    }
  }

  // --------------------- Methods ------------------------

  handleTriggerClickChange = (visible) => {
    this.setState({
      popoverVisible: visible,
    });
  };

  handlePageChange = (newValue) => {
    if (newValue % 2 !== 0) newValue += 1;
    if (newValue > this.props.maxPages) newValue = this.props.maxPages;
    if (newValue < this.props.minPages) newValue = this.props.minPages;

    this.setState({
      numPages: newValue,
    });
  };

  handleConfirm = () => {
    // on confirm continue
    const onConfirmContinue = () => {
      this.props.dispatch(
        editionActions.UpdateProjectPageAmount(this.state.numPages)
      );
    };
    const onConfirmCancel = () => {
      // do nothing but display previous popover
      this.setState({
        popoverVisible: true,
      });
    };

    // close this view on confirm.. (will be reopened if canceled below)
    this.setState({
      popoverVisible: false,
    });

    // warn if less pages
    if (this.props.pageList.length - 1 > this.state.numPages) {
      const numPagesDeleted =
        this.props.pageList.length - 1 - this.state.numPages;
      popupHelper.showChangePageDeleteWarning(
        numPagesDeleted,
        onConfirmContinue,
        onConfirmCancel
      );
    } else {
      onConfirmContinue();
    }
  };

  // handleLoadStart(){

  //     this.setState({
  //         isLoading : true
  //     })
  //     //alert("loaded!");
  // }

  // handleLoadComplete(){

  //     this.setState({
  //         isLoading : false
  //     })
  //     //alert("loaded!");
  // }

  // getOverContent( item ){
  //     const highURL = item.temp? item.temp_url : item.working_url;
  //     const w = 350;
  //     return <div style={{width:w, maxWidth:w, wordWrap:"break-word",
  //                 fontSize:12}}
  //                 onMouseOver={(e)=>{this.handlePopoverVisibleChange(false)}}
  //                 onDragOver={(e)=>{this.handlePopoverVisibleChange(false)}}
  //                 >
  //                 <img width={w} height={w* item.height/item.width} src={ highURL } />
  //                 {DebugFlags.DEBUGGING? `${JSON.stringify(item)}` : `${item.name} (${item.width}x${item.height}px)`}
  //             </div>
  // }

  // handlePopoverVisibleChange = (show) => {
  //     console.log("coucou:" + show);
  //     this.setState({
  //         popOverVisible : show,
  //      })
  //      console.log("coucou:" + JSON.stringify(this.state));
  //      this.render();
  // }

  /// ///////////////////////////////////////////////////
  // Render
  /// ///////////////////////////////////////////////////
  render() {
    // const { isLoading } = this.state;
    // // console.log("RENDERING PHOTO ITEM");

    // // const MAX_HEIGHT = 75;

    // const item:Photo = this.props.photo;
    // const imageUrl = item.temp? item.temp_url : item.thumb_url;
    // const highURL = item.temp? item.temp_url : item.working_url;
    // // const imageUrl = item.thumb_url;
    // const highURL = item.working_url;

    // console.log(`PhotoItem scrollcontainer = ${this.props.scrollContainer}`);

    // const page = this.props.page;
    // const selected = this.props.selected;
    // const photos = this.props.photos;
    // const frameList = page.frames;
    // const pageIndex = this.props.key;

    // const scaleRatio = MAX_HEIGHT / page.height;
    // const pageHeight = page.height * scaleRatio;
    // const pageWidth = page.width * scaleRatio;

    // const uploadingIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

    return (
      <Popover
        {...this.props}
        content={
          <div // className="pic"
            className=""
          >
            <h4>{GetText('popup.pagemodifier.title')}</h4>
            <Row type="flex" justify="center" align="middle">
              <Col>
                {/* <Tooltip title="remove pages" placement="right"> */}
                <Button
                  disabled={this.state.numPages <= this.props.minPages}
                  type="primary"
                  icon={<MinusOutlined />}
                  onClick={() =>
                    this.handlePageChange(
                      this.state.numPages - this.state.pageStep
                    )
                  }
                />
                {/* </Tooltip> */}
              </Col>
              <Col>
                <InputNumber
                  value={this.state.numPages}
                  //  disabled={!this.state.pages}
                  // value={this.props.pageList.length}
                  min={this.props.minPages}
                  max={this.props.maxPages}
                  //  // defaultValue={30}
                  step={this.state.pageStep}
                  onChange={this.handlePageChange}
                />
              </Col>
              <Col>
                {/* <Tooltip title="add pages" placement="right"> */}
                <Button
                  disabled={this.state.numPages >= this.props.maxPages}
                  onClick={() =>
                    this.handlePageChange(
                      this.state.numPages + this.state.pageStep
                    )
                  }
                  type="primary"
                  icon={<PlusOutlined />}
                />
                {/* </Tooltip> */}
              </Col>
              <Col style={{ paddingLeft: '12px' }}>
                <Button type="primary" onClick={() => this.handleConfirm()}>
                  {GetText('common.confirm')}
                </Button>
              </Col>
            </Row>
          </div>
        }
        trigger="click"
        open={this.state.popoverVisible}
        onOpenChange={this.handleTriggerClickChange}
      >
        {this.props.children}
      </Popover>
    );
  }
}

// --------------------- redux ------------------------

// Redux map
function mapStateToProps(state) {
  // const { authentication, edition, photos, backgrounds, cliparts, layouts } = state;
  // const { project,selectedPage, selectedFrameID, projectClassname } = edition;
  // const { pageList } = project;
  const { edition } = state;
  const { project } = edition;
  const { pageList } = project;
  // const projectDocument:IDoc = GetDoc( project.docID );
  const maxPages = GetProjectMaxPages(edition.project);
  const minPages = GetProjectMinPages(edition.project);

  // we need user, project and layouts
  return {
    pageList,
    minPages,
    maxPages,
    // currentPage : (pageList)? pageList[selectedPage] : null,
    // //currentPageGroup : (pageList)? editionSelectors.GetCurrentPageGroup(state) : [],
    // projectClassname,
    // selectedPageIndex : selectedPage,
    // selectedFrameID,
    // layoutsByID: layouts.byID,
    // backgroundsByID: backgrounds.byID,
    // clipartsByID: cliparts.byID,
    // // user : authentication.user,
    // //photos: photosByID,
    // photosByID: photoListSelector.getAllPhotosByID(state),

    // // selectors
    // allowUndo: editionSelectors.allowUndo(state),
    // allowRedo: editionSelectors.allowRedo(state),
  };
}

// --------------------- export ------------------------

const reduxConnected = connect(mapStateToProps)(ProjectPageModifierPopup);
export { reduxConnected as ProjectPageModifierPopup };
