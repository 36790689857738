/* eslint-disable global-require */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-var-requires */
import React from 'react';
import { Modal } from 'antd';
import type { Photo, RebrandingInfo } from '../types/types';
import { OpenTextInBlankWindow, GetAllLogs } from './debugging';
import {
  GetLayoutURL,
  PROJECT_CONST,
  GetBackgroundURL,
  GetClipartsURL,
  config,
  GetOverlayersURL,
} from '../data/config';

import { ObjectToFormData, StringToFile } from './FetchUtils';
import { blobURLToFile } from './fileUtils';
import { GetProjectDescription } from './projectHelper';
import { CreatePhoto } from '../feature/photoList/photoHelper';
import { generatePriceUrl } from '../feature/pricing/priceHelper';
import { OrderHelper } from '../feature/order/orderHelper';
import { dataURLtoFile } from './HtmlUtils';
import { DebugFlags, IsLocalhost } from '../debug/DebugFlags';
import { authSelectors } from '../feature/auth/authentification';
import { GetCurrentLanguage } from '../data/LanguageHelper';
import { IsMobileOrTablet } from './BrowserHelper';
import { GetProjectCode } from './ProductHelper';
import { Project } from '../data/Project';

let StoreRef;

// TODO: store should not be accessed like this!
export function InitializeAPI(store) {
  StoreRef = store;
}
const getStore = () => StoreRef.getState();

// needed because backend require form data and not json formatted data
const convertParamsToQueryString = (params: object) =>
  // let queryString = require('querystring');
  // Axios.post(url, queryString.stringify(postValues), { headers:headers, withCredentials:true })
  `${new URLSearchParams(params).toString()}`;

/**
 * Handle default json response
 * @param {*} response
 */
function handleJSONResponse(response: Response, error) {
  return response.text().then((text: string) => {
    // data as js object
    let data = null;
    text = text.trim();

    // TODO: remove this later, this is an utility to convert XML to json, but we should not have any call anymore in XML in the end
    // check if xml, and if so, convert to js
    if (text.charAt(0) === '<') {
      // alert("API.js :: we will convert an xml to Json");
      console.log(
        `API: Xml received, we will convert an xml to JSON : ${response.url}`
      );

      // WITHOUT PARSER
      //   const parseString = require('xml2js').parseString;
      //   parseString(text, function (err, result) {
      //      data = result;
      //      //alert("Json converted:" + data);
      //      //alert("Content:" + JSON.stringify(data));
      //   });

      // // OLD METHOD
      // // https://github.com/Leonidas-from-XIV/node-xml2js
      // const xml2js = require('xml2js');
      // const parser = new xml2js.Parser();// {preserveChildrenOrder:false, explicitArray:true, explicitChildren:true, mergeAttrs:false} );
      // parser.parseString(text, function (err, result) {
      //          data = result;
      //          //alert("Json converted:" + data);
      //          //alert("Content:" + JSON.stringify(data));
      //       });

      // security : https://stackoverflow.com/questions/24877085/invalid-character-entity-parsing-xml
      // EDIT: result should now be JSON formatted
      text = text.replace(/&/g, '&amp;');

      // convert xml to json
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const convertXML = require('xml-js');
      const jsonString = convertXML.xml2json(text, {
        compact: true,
        spaces: 0,
        ignoreDeclaration: true,
        attributesKey: '$',
      });
      data = JSON.parse(jsonString);

      //
      // alert(jsonString);

      // replace prop value by "_text" if there is only this key.
      // function checkForTextNode( node ){
      //     Object.keys(node).forEach((key, index, arr)=>
      //     {
      //         // object content is replaced _text if there is only this in the object
      //         if(Object.keys(node[key]).length === 1 && node[key]["_text"])
      //             node[key] = node[key]["_text"];
      //         // otherwise check children
      //         else checkForTextNode(node[key]);
      //     });
      // }
      // checkForTextNode(data);
    }

    // case return an error as string
    // TODO: ask Keith to format all errors in JSON
    // example : *ERROR: Invalid Request
    else if (text.charAt(0) === '*' || text === '-1') {
      // if (responseText.indexOf("*") === 0 || responseText === "-1")
      return Promise.reject(text);
    }

    // classic formatted result as json
    else {
      // Data must be in JSON Format, otherwise it will fail here
      try {
        data = text && JSON.parse(text);
      } catch (e) {
        data = text;
      }
    }

    // DEBUG open page with result of call
    if (DebugFlags.OPEN_SERVICE_RESULT_IN_NEW_WINDOW)
      OpenTextInBlankWindow(text, response.url);

    // --- CASE ERROR
    if (data.status === 'error') {
      // alert("ERROR:" + data.message); // TODO change this!
      /*
          if (response.status === 401) {
              // auto logout if 401 response returned from api
              logout();
              window.location.reload(true);
          }
          */
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

function getTimeStamp(prefix = '&t='): string {
  return `${prefix}${new Date().getTime()}`;
}

function handleGeneralError(error) {
  if (error === 'TypeError: Failed to fetch')
    error = 'Impossible to reach tictac server, please check your connection'; // "No Internet connection"; // TODO: translate this!
  return Promise.reject(error);
}

// --------------------- AUTH ------------------------

function checkSession() {
  console.log('API: checkSession');

  const requestOptions = {
    credentials: 'include',
  };

  return (
    fetch(`${config.apiUrl}/chksess.php`, requestOptions)
      .then(handleJSONResponse)

      // .then((data) => data.session)

      .then((data) => {
        if (DebugFlags.SIMULATE_REBRANDING) {
          const rebrandingTest: RebrandingInfo = {
            code: 'test',
            title: 'The new rebranding stuff',
            editorHost: 'https://rebrandingtest.tictacphoto.com',
            logo: 'https://d1csarkz8obe9u.cloudfront.net/posterpreviews/business-logo-design-template-78655edda18bc1196ab28760f1535baa_screen.jpg?ts=1617645324',
            tictacColorLight: '#d966ce',
            tictacColorDark: '#4f0451',
            selectionColor: '#ff00cc',
            selectionColor_text: '#ffffff',
            productFilter: ['albums', 'canvas'],
            subProductFilter: {
              albums: {
                contemporary: {
                  docs: ['S'],
                  default: 'S',
                },
                regular: {
                  docs: ['RA', 'RB', 'RC'],
                  default: 'RB',
                },
              },
            },
          };
          data.session.rebranding = rebrandingTest;
        }
        return data.session;
      })

      // alert("data: "+  JSON.stringify(data));
      // FOR TESTING : WORKING!
      // data.session.recent_project_id = "273295";

      .catch(handleGeneralError)
  );
}

/** use this when opening a project or creating new project to clear existing session parameters */
function resetSessionProject() {
  console.log('API: resetSessionProject');

  const requestOptions = {
    credentials: 'include',
  };
  return fetch(`${config.apiUrl}/project_new.php`, requestOptions)
    .then(handleJSONResponse)
    .then((data) => {})
    .catch(handleGeneralError);
}

function login(username, password) {
  console.log('API: login');

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: convertParamsToQueryString({ email: username, password }),
    credentials: 'include',
    // headers: { 'Content-Type': 'application/json' },
    // body: JSON.stringify({ username, password })
  };

  // alert(requestOptions.body);
  return (
    fetch(`${config.apiUrl}/login.php`, requestOptions)
      // No need for multiple login anymore
      // .then( response => {
      //     return fetch(`${config.getApiUrl}/login.php`, requestOptions)
      // })
      .then(handleJSONResponse)
      .then((data) => data.session)
      // alert("data: "+  JSON.stringify(data));

      .catch(handleGeneralError)
  );
}

function register(username, password) {
  console.log('API: register');

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: convertParamsToQueryString({
      email: username,
      password1: password,
      password2: password,
    }),
    credentials: 'include',
    // headers: { 'Content-Type': 'application/json' },
    // body: JSON.stringify({ username, password })
  };

  // alert(requestOptions.body);
  return (
    fetch(`${config.apiUrl}/register.php`, requestOptions)
      .then(handleJSONResponse)
      .then((data) => data.session)

      // alert("data: "+  JSON.stringify(data));

      .catch(handleGeneralError)
  );
}

function logout(language) {
  console.log('API: logout');

  const requestOptions = {
    // method: 'POST',
    // headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    // body: queryString.stringify({email:username, password1:password, password2:password}),
    credentials: 'include',
    // headers: { 'Content-Type': 'application/json' },
    // body: JSON.stringify({ username, password })
  };

  // alert(requestOptions.body);
  return (
    fetch(
      `${config.baseUrl}/wizard/login.php?lang=?${language}&act=logout`,
      requestOptions
    )
      // .then(handleJSONResponse)
      .then((data) => true)
      // alert("data: "+  JSON.stringify(data));
      // alert("data: "+ data);

      .catch(handleGeneralError)
  );

  // https://editor.tictacphoto.com/wizard/login.php?lang=en&act=logout
}

// --------------------- PROJECT ------------------------

/**
 * Get Project List
 * @returns a list of project
 */
function getUserProjects(forOldEditor = false) {
  console.log('API: getUserProjects');

  // Post params
  const params = {
    build: forOldEditor ? '91005' : PROJECT_CONST.build_start,
    editor_type: forOldEditor ? '2' : PROJECT_CONST.editor_type,
  };

  // request
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: convertParamsToQueryString(params),
    credentials: 'include',
    // headers: { 'Content-Type': 'application/json' },
    // body: JSON.stringify({ username, password })
  };

  // fetch
  return (
    fetch(`${config.apiUrl}/project_list.php`, requestOptions)
      .then(handleJSONResponse)
      // .then((projectList) => projectList)
      // alert("data: "+  JSON.stringify(data));

      .catch(handleGeneralError)
  );
}

/** *******************************************
 * Save a project online
 * @param {Project} projectObj : the project object to be sent to backend
 * @returns {string} The projecte ID generated on the backend
 *
 */
function saveProject(projectObj: Project, photosByID) {
  console.log(
    `API: SaveProject -> ID:"${projectObj.id}" > name:"${projectObj.name}"`
  );

  const docCode: number = GetProjectCode(projectObj);

  // Post params
  const params = {
    classname: projectObj.classname,
    name: projectObj.name,
    editor_type: PROJECT_CONST.editor_type,
    build: PROJECT_CONST.build_start,
    description: GetProjectDescription(projectObj), // projectObj.docCode // THIS IS A SAVED STRING THAT WILL BE RE-exported in the descripotion of project, so we can use a complex JSON here for example...
    product: docCode, // THIS IS A SAVED STRING THAT WILL BE RE-exported in the descripotion of project, so we can use a complex JSON here for example...
  };
  // case override current project
  if (projectObj.id) params.id = projectObj.id;

  // FROM actionscript:
  //     var docTypeStr:String = (Infos.isCanvas)?String( parseInt(project.docType) + CanvasManager.instance.CanvasModifier ) : project.docType;
  //     saveUploader.addVariable("classname", project.classname);
  //     saveUploader.addVariable("build", project.build_created);
  //     saveUploader.addVariable("product", docTypeStr);
  //     if(project.IsCustomRebrandedProduct) saveUploader.addVariable("promoEditor", Infos.REBRANDING_ID);

  // convert params to form data
  const formData = ObjectToFormData(params);

  // add file
  const saveObject = {
    project: projectObj,
    photosByID,
  };

  // security!
  if (!projectObj || !photosByID) {
    return Promise.reject(
      `Empty project or photo library: project:${saveObject.project} photos:${saveObject.photosByID}`
    );
  }

  formData.append(
    'uploadfile',
    StringToFile(JSON.stringify(saveObject), 'filename')
  );

  // request
  const requestOptions = {
    method: 'POST',
    // headers: {
    //         "Content-Type": "multipart/form-data",
    // },
    // body: require('querystring').stringify(params),
    body: formData,
    credentials: 'include',
  };

  // handleUploadfile = (event) => {
  //     event.preventDefault();
  //     const data = new FormData();
  //     data.append('photo',event.target.files[0] );
  //     data.append('name', 'Test Name');
  //     data.append('desc', 'Test description');
  //     fetch("http://localhost:3001/todo/upload", {
  //          method: 'POST',
  //          headers: {
  //              'Accept': 'application/json',
  //              'Content-Type': 'application/x-www-form-urlencoded'
  //          },
  //          body: data
  //     }).then((response) =>  {
  //        return response.text();
  //     })
  // }

  // fetch
  return fetch(`${config.apiUrl}/project_save.php`, requestOptions)
    .then(handleJSONResponse)
    .then((jsonResponse) => {
      // -- Recover project ID and return it
      // TODO: retro compatibility during migration process.. should be removed for next release!
      if (jsonResponse.project.id && jsonResponse.project.id._text)
        return jsonResponse.project.id._text;
      return jsonResponse.project.id;
    })
    .catch((reason) => {
      throw new Error(`Issue while saving project:${reason}`);
      return null;
    });
}

/** *******************************************
 * Load a project online
 * @param {string} projectID : id of the project
 * @returns { object } The project generated from the json data
 */
function loadProject(projectID) {
  console.log(`API: LoadProject -> projectID:${projectID}`);

  // Post params
  const params = {
    id: projectID,
    // classname: projectClass
  };

  // request
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    // headers: {'Content-Type': 'application/x-amf;charset=x-user-defined'},
    // headers: {
    //         'Content-Type': 'application/octet-stream',
    //         "Transfer-Encoding" : 'chunked',
    //         "Accept-Encoding" : "gzip",
    //     },

    // headers: {'Content-Type': 'text/plain; charset=x-user-defined'},

    body: convertParamsToQueryString(params),
    credentials: 'include',
    // headers: { 'Content-Type': 'application/json' },
    // body: JSON.stringify({ username, password })
  };

  return fetch(`${config.apiUrl}/project.php`, requestOptions)
    .then((response) => response.json())
    .then((jsonResponse) => jsonResponse);

  // // fetch
  // // return fetch(`${config.apiUrl}/project.php`, requestOptions)
  // //const fetch = require('node-fetch');
  // //const fileType = require('file-type');
  // return fetch(`http://antho.be/prod/tictac/test/project.tic`, requestOptions)

  //         //.then( response => response.body )
  //          .then( response => response.arrayBuffer() )
  //         // .then( res => res.body.buffer() )
  //         // .then(buffer => fileType(buffer))
  //         //  .then( type =>{
  //         //.then(body =>{
  //         .then(arrayBuffer =>{

  //             // alert(type);

  //             // return;

  //             let Duplex = require('stream').Duplex;
  //             function bufferToStream(buffer) {
  //                 let stream = new Duplex();
  //                 stream.push(buffer);
  //                 stream.push(null);
  //                 return stream;
  //             }

  //             function toBuffer(ab) {

  //                 // OLD
  //                 var buf = Buffer.alloc(ab.byteLength);
  //                 var view = new Uint8Array(ab);
  //                 for (var i = 0; i < buf.length; ++i) {
  //                     buf[i] = view[i];
  //                 }
  //                 return buf;

  //                //return Buffer.from( new Uint8Array(ab) );
  //                //return Buffer.from( new Uint8Array(ab) );
  //             //    return Buffer.from( ab,0,ab.byteLength );
  //             }

  //         //     /*
  //         //     const reader = body.getReader();
  //         //     return new ReadableStream({
  //         //         start(controller){
  //         //             return pump();
  //         //             function pump(){
  //         //                 return reader.read().then(({done, value}) => {
  //         //                     // When no more data needs to be consumed, close the stream
  //         //                     if (done) {
  //         //                         controller.close();
  //         //                         return;
  //         //                     }
  //         //                     // Enqueue the next data chunk into our target stream
  //         //                     controller.enqueue(value);
  //         //                     return pump();
  //         //                 });
  //         //             }
  //         //         }
  //         //     })})
  //         //     .then( stream => {
  //         //         */

  //         // //        const ByteArray = require('bytearray-node')
  //         // //         const ba = new ByteArray( toBuffer(buffer) );
  //         // //         ba.objectEncoding = 0;
  //         // //         //const ba = new ByteArray( buffer );

  //         // //        //ba.uncompress("zlib");
  //         // //        //const obj = ba.toString();
  //         // //        const obj = ba.readObject();
  //         // //        alert(JSON.stringify(obj));
  //         // //    //    return obj;

  //         // DECODE AMF
  //                 var amfjs = require("amfjs")
  //                 //var longInt8View = new Uint8Array(buffer);
  //                 var decoder = new amfjs.AMFDecoder(bufferToStream(toBuffer(arrayBuffer)))
  //                 // var decoder = new amfjs.AMFDecoder(buffer)
  //                 //var value = decoder.decode(amfjs.AMF0) //Decode an AMF0 object
  //                 //console.log("TCL: getUserProjects -> AMF0", value)

  //                 //alert("untill there it was fine!");

  //                 var val = decoder.decode(amfjs.AMF3) //Decode an AMF3 object

  //                 console.log("TCL: getUserProjects -> AMF3", val)
  //                 alert("object decoded!");

  //                 return val;

  //             })

  //             // // return response.arrayBuffer();
  //             // return response.blob().then( blob => {
  //                         // // case the return is too small, or error :  there is an error, so handle the message like text
  //             // if( response.status !==200 || blob.size < 500)
  //             // {
  //             //     return blob.text().then( message => {

  //             //         // check first if there is an console.error();
  //             //         // var decoder = new TextDecoder("utf-8");
  //             //         // let message = decoder.decode(buffer);
  //             //         return Promise.reject("loadProject: " + message);
  //             //     });
  //             // }

  //             // // otherwise, status ok and content big enough, try to parse content

  //             // else
  //             // {
  //                 // return response.body.then( readableStream => {

  //                     // alert("data: "+  JSON.stringify(data));
  //                     // try to convert amf bytes to json

  //                 // })

  //             // }

  //             // if(!done)
  //             // {
  //             //     //alert("not done!");
  //             //     return;
  //             // }

  //             // (async()=>{

  //             //     alert("Text:" + await blob.text());

  //             // })();

  //             // const buffer = value;

  //             // // error if bytes are really small!
  //             // //alert("buffer.bytes:" + buffer.read)
  //             // if(buffer.byteLength < 100)
  //             // {
  //             //     // check first if there is an console.error();
  //             //     var decoder = new TextDecoder("utf-8");
  //             //     let message = decoder.decode(buffer);
  //             //     return Promise.reject("loadProject: "+message);
  //             // }

  //         // });
  //     // })
}

// --------------------- PHOTOS ------------------------

/**
 * Retrieve photo list
 */
function getUserPhotos() {
  console.log('API: getUserPhotos');

  // Post params
  const params = {
    build: '91005',
    //   classname:"albums"
  };

  // request
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: convertParamsToQueryString(params),
    credentials: 'include',
    // headers: { 'Content-Type': 'application/json' },
    // body: JSON.stringify({ username, password })
  };

  // fetch
  return fetch(`${config.apiUrl}/img_list.php`, requestOptions)
    .then(handleJSONResponse)
    .then((result) => {
      // alert("result: "+  JSON.stringify(result));
      // transform content to valid image content
      const template = result.templates;
      /*
            thumbnail_path: "/flex/tn.php?img={id}"
            working_path: "/flex/work.php?img={id}"
            fullsize_path: "/flex/img.php?img={id}"
            suffix_path: "001/255650/images/{id}.{ext}"
           */

      // map photos
      const photoList = result.images.map((item) => {
        const photo: Photo = CreatePhoto(item.id, item.name);
        photo.ext = `${item.ext}`;
        photo.creation_date = `${item.d_created}`;
        photo.modification_date = `${item.d_modified}`;
        photo.width = Number(item.w);
        photo.height = Number(item.h);
        photo.cat = `${item.cat}`;
        photo.working_url =
          config.apiPhoto + template.working_path.replace('{id}', item.id);
        photo.full_url =
          config.apiPhoto + template.fullsize_path.replace('{id}', item.id);
        photo.thumb_url =
          config.apiPhoto + template.thumbnail_path.replace('{id}', item.id);
        photo.orderSuffix = template.suffix_path
          .replace('{id}', item.id)
          .replace('{ext}', item.ext);
        photo.md5 = item.md5;

        /*
                id: "9493003"
                name: "R-mVCDOv-D8.jpg"
                ext: "jpg"
                cat: "11.02.16"
                w: "500"
                h: "667"
                d_created: "1455187727000"
                d_modified: "1455187727000"
                rot: null
                md5: "ef189a40f162e26a1effe80eaebe1ffa"
                */

        return photo;
      });

      // alert("photoList: "+  JSON.stringify(photoList));
      return photoList;
    });
}

/**
 * Retrieve photo list
 */
function savePhoto(photoName, dataURL) {
  console.log('API: savePhoto');

  // First we need to create a place holder on backend
  // Post params
  const params = {
    // cat:(projectID)? `@@${projectID}@@`: tempImageFolderName, // the category of the image
    cat: 'editor2',
    filename: photoName, // the name of the image
  };

  // request
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: convertParamsToQueryString(params),
    credentials: 'include',
    // headers: { 'Content-Type': 'application/json' },
    // body: JSON.stringify({ username, password })
  };

  // fetch
  return fetch(`${config.getApiUrl}/img_upload_tmp.php`, requestOptions)
    .then(handleJSONResponse)
    .then((result) => {
      // in the result we receive the temporary place holder, we need the id to start the upload process
      if (result.image.id._text) {
        const newID = result.image.id._text;
        return uploadPhoto(newID, photoName, dataURL);
      }
      return Promise.reject(`No ID found for image: ${JSON.stringify(result)}`);
    });
}

/**
 * Retrieve photo list
 */
function uploadPhoto(newID, photoName, dataURL) {
  const t0 = performance.now();
  // fetch
  return blobURLToFile(dataURL, photoName).then((file) => {
    const t1 = performance.now();
    console.log(`Time to blobToFile =>${t1 - t0} ms`);

    // datas
    const formData = new FormData();
    formData.append('img', newID); // id of the iage
    formData.append('uploadfile', file, photoName); // "filename"

    // request
    const requestOptions = {
      method: 'POST',
      body: formData,
      credentials: 'include',
    };

    return fetch(`${config.getApiUrl}/img_upload_tmp.php`, requestOptions)
      .then(handleJSONResponse)
      .then((result) => {
        const { image } = result;
        if (image.id._text) {
          // XML RESULT
          // <image>
          //     <id>18512862</id>
          //     <name>image name</name>
          //     <category>PicFromPostman</category>
          //     <tn>/flex/tn.php?img=18512862</tn>
          //     <working>/flex/work.php?img=18512862</working>
          //     <width>1920</width>
          //     <height>1080</height>
          //     <fullsize>/flex/img.php?img=18512862</fullsize>
          //     <filename>18512862</filename>
          //     <suffix>001/255650/images/18512862.jpg</suffix>
          //     <size>283222</size>
          //     <last_modified>1576750804000</last_modified>
          //     <original_date>1576750804000</original_date>
          //     <was_rotated></was_rotated>
          // </image>

          return {
            id: image.id._text,
            name: image.name._text,
            creation_date: Number(image.original_date._text),
            modification_date: Number(image.last_modified._text),
            width: Number(image.width._text),
            height: Number(image.height._text),
            cat: image.category._text,
            working_url: config.apiPhoto + image.working._text,
            full_url: config.apiPhoto + image.fullsize._text,
            thumb_url: config.apiPhoto + image.tn._text,
            orderSuffix: image.suffix._text,
          };
        }
        return Promise.reject(
          `No ID found for image: ${JSON.stringify(result)}`
        );
      });
  });
}

function renameCategory(oldFolderName, newFolderName) {
  console.log('API: renameCategory');

  const requestOptions = {
    credentials: 'include',
  };

  // fetch
  const url = `${config.apiUrl}/rename_cat.php?f=${encodeURI(
    oldFolderName
  )}&t=${encodeURI(newFolderName)}&time=${new Date().time}`;
  return fetch(url, requestOptions)
    .then(handleJSONResponse)
    .then((jsonResponse) => {
      alert(`renameCategory result: ${JSON.stringify(jsonResponse)}`);
      return jsonResponse;
    });
}

// --------------------- cliparts ------------------------

function getCliparts(projectClass) {
  console.log('API: getCliparts');

  const requestOptions = {
    // method: 'POST',
    // mode: "no-cors", Not working as the response is opaque.. not readable by javascript
    // headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    // body: require('querystring').stringify(params),
    credentials: 'include',
    // headers: { 'Content-Type': 'application/json' },
    // body: JSON.stringify({ username, password })
  };

  // fetch
  return fetch(GetClipartsURL(projectClass), requestOptions)
    .then(handleJSONResponse)
    .then((jsonResponse) => jsonResponse);
  // alert("result: "+  JSON.stringify(result));
}

// --------------------- overlayers ------------------------

function getOverlayers(projectClass) {
  console.log('API: getOverlayers');

  const requestOptions = {
    credentials: 'include',
  };

  // fetch
  return fetch(GetOverlayersURL(projectClass), requestOptions)
    .then(handleJSONResponse)
    .then((jsonResponse) => jsonResponse);
}

// --------------------- backgrounds ------------------------

function getBackgrounds(projectClass) {
  console.log('API: getBackgrounds');

  const requestOptions = {
    // method: 'POST',
    // mode: "no-cors", Not working as the response is opaque.. not readable by javascript
    // headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    // body: require('querystring').stringify(params),
    credentials: 'include',
    // headers: { 'Content-Type': 'application/json' },
    // body: JSON.stringify({ username, password })
  };

  // fetch
  return fetch(GetBackgroundURL(projectClass), requestOptions).then(
    handleJSONResponse
  );
  // .then((jsonResponse) => jsonResponse);
  // alert("result: "+  JSON.stringify(result));
}

// --------------------- LAYOUT ------------------------

function getLayouts(projectClass) {
  console.log('API: getLayouts');

  // request
  const requestOptions = {
    method: 'GET',
    credentials: 'include',
  };

  // result object
  const resultObject = {
    layoutList: null,
    customLayoutList: null,
  };

  // fetch
  return fetch(GetLayoutURL(projectClass), requestOptions)
    .then(handleJSONResponse)
    .then((jsonResponse) => {
      resultObject.layoutList = jsonResponse;
      return GetCustomLayouts();
    })
    .then((customLayoutsJSONResponse) => {
      /// /////////////////////////////////////////////////////////////
      // [HACK] TODO: see with Keith..
      if (`${customLayoutsJSONResponse}`.includes('*ERROR: Invalid Request')) {
        [customLayoutsJSONResponse] =
          customLayoutsJSONResponse.split('*ERROR:');
        customLayoutsJSONResponse = JSON.parse(customLayoutsJSONResponse);
      }
      /// /////////////////////////////////////////////////////////////

      resultObject.customLayoutList = Array.isArray(customLayoutsJSONResponse)
        ? customLayoutsJSONResponse
        : [];
      return resultObject;
    })
    .catch(handleGeneralError);
}

function SaveCustomLayout(customLayoutList: Array): Promise {
  console.log('API: SaveCustomLayout');

  // see discussion : https://mail.google.com/mail/u/0/?shva=1#search/from%3Akeith/KtbxLwgxGCkpPnWHRLdgcwWwMPbkKsnZFL

  // const params = {
  //     // code: "tyd8uqtzuv7kmz2xmm8j3k6tp72e2pya",
  //     // name: "uploadfile",
  //     // filename: "uploadfile.xml",
  // };
  // // convert params to form data
  // const formData = ObjectToFormData( params );

  const formData = new FormData();
  const file = StringToFile(JSON.stringify(customLayoutList), 'custom.json');
  formData.append('uploadfile', file, file.name);
  formData.append('code', 'tyd8uqtzuv7kmz2xmm8j3k6tp72e2pya');
  formData.append('name', 'uploadfile');
  formData.append('filename', 'custom.json');

  /// /////////////////////////////////
  // const params = {
  //     code: "tyd8uqtzuv7kmz2xmm8j3k6tp72e2pya",
  //     name: "uploadfile",
  //     filename: "uploadfile.xml",
  // };
  // // convert params to form data
  // const formData = ObjectToFormData( params );

  // const file = StringToFile( orderString, "upload"+orderSuffix);
  // //const blob = StringToBlob( orderString );

  // formData.append('uploadfile', file, "upload"+orderSuffix);
  /// ////////////////////////

  // alert("file length:"+file.size);

  // request
  const requestOptions = {
    method: 'POST',
    // headers: {
    //         "Content-Type": "multipart/form-data",
    // },
    // body: require('querystring').stringify(params),
    body: formData,
    credentials: 'include',
  };

  // // datas
  // const formData = new FormData();
  // formData.append('img', newID); // id of the iage
  // formData.append('uploadfile', file, tempPhotoObj.name); //"filename"

  // // request
  // const requestOptions = {
  //     method: 'POST',
  //     body: formData,
  //     credentials: 'include'
  // };

  const url = `${config.apiUrl}/custom_upload.php?json=custom.json`; // + "&" + config.timestamp;
  return fetch(url, requestOptions)
    .then(handleJSONResponse)
    .catch(handleGeneralError);

  // // Post params
  // const params = {
  //     build:"91005",
  //     classname: projectClass
  // };

  // ml.addFile(bytearray, "custom", "uploadfile");
  //    var urlstr:String = serverNormalUrl+"/flex/custom_upload.php?xml=custom.xml";

  // //alert("Get Layouts for: " + projectClass);

  // // request
  // const requestOptions = {
  //     method: 'GET',
  //     //mode: "no-cors", Not working as the response is opaque.. not readable by javascript
  //    // headers: {'Content-Type': 'application/x-www-form-urlencoded'},
  //    // body: require('querystring').stringify(params),
  //     credentials: 'include'

  // };

  // // fetch
  // return fetch(GetLayoutURL(projectClass), requestOptions)
  //     .then(handleJSONResponse)
  //     .then(jsonResponse => {

  //         // retrieve custom layouts
  //         return GetCustomLayouts();
  //         //alert("result: "+  JSON.stringify(result));
  //         //const layoutList = layoutHelper.ConvertOldEditorJSON( result );
  //         // transform content to valid image content
  //         /*
  //         const template = result.templates;
  //         const photoList = result.images.map( item => {
  //             return {
  //                 id: item.id,
  //                 name: item.name,
  //                 ext:item.ext,
  //                 creation_date:item.d_created,
  //                 modification_date:item.d_modified,
  //                 width:item.width,
  //                 height:item.height,
  //                 cat:item.cat,
  //                 working_url: config.apiPhoto + template.working_path.replace("{id}", item.id),
  //                 full_url: config.apiPhoto + template.fullsize_path.replace("{id}", item.id),
  //                 thumb_url: config.apiPhoto + template.thumbnail_path.replace("{id}", item.id),
  //             }
  //         });
  //         */
  //        //alert("photoList: "+  JSON.stringify(photoList));
  //        //return layoutList;
  //        return jsonResponse;
  //     });
}

function GetCustomLayouts(): Promise {
  console.log('API: GetCustomLayouts');

  // request
  const requestOptions = {
    method: 'GET',
    credentials: 'include',
  };

  // fetch
  const url = `${config.getApiUrl}/custom.php?json=custom.json&${config.timestamp}`;
  return fetch(url, requestOptions).then(handleJSONResponse);
}

// --------------------- pricing ------------------------

function getPricing(vendorID: string) {
  console.log('API: getPricing');

  // TODO: later we might need to pass those arguments
  const build = `&build=${PROJECT_CONST.build_start}`;
  // const build = `&build=92336`;
  const vendor = `&vendor=${vendorID}`;
  // const timeStamp = `&time=${new Date().time}`;
  const timeStamp = config.timestamp;
  const ccp = '&ccp=1'; // canvas

  // multiple files to load
  const pricingUrl = `${config.apiPriceUrl}/pricing.php?${timeStamp}${vendor}${build}`;
  const optionPricingUrl = `${config.apiPriceUrl}/options.php?${timeStamp}${vendor}${build}`;
  const canvasCustomPricingUrl = `${config.apiPriceUrl}/pricing.php?${timeStamp}${ccp}${vendor}${build}`;
  const envelopPricingUrl = `${config.apiPriceUrl}/envelopes.php?${timeStamp}${vendor}${build}`;
  // const classicCoverOptionsUrl : String = `${config.baseUrl}/albums/assets/xml/albums/covers_classic_options_92305.xml?${timeStamp}${vendor}${build}`;
  const classicCoverOptionsUrl = `${config.baseUrl}/albums/assets/xml/albums/covers_classic_options_92305.xml?${timeStamp}${vendor}${build}`;

  const requestOptions = {
    // method: 'POST',
    // mode: "no-cors", Not working as the response is opaque.. not readable by javascript
    // headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    // body: require('querystring').stringify(params),
    credentials: 'include',
    // headers: { 'Content-Type': 'application/json' },
    // body: JSON.stringify({ username, password })
  };

  const resultObject = {
    priceList: null,
    optionList: null,
    canvasCustomPriceList: null,
    envelopePriceList: null,
    classicCoverOptions: null,
  };

  // fetch
  return fetch(pricingUrl, requestOptions)
    .then(handleJSONResponse)
    .then((priceListJsonresponse) => {
      // alert("priceListJsonresponse: "+  JSON.stringify(priceListJsonresponse));
      resultObject.priceList = priceListJsonresponse;
      return fetch(optionPricingUrl, requestOptions);
    })

    .then(handleJSONResponse)
    .then((optionListJsonresponse) => {
      // alert("optionListJsonresponse: "+  JSON.stringify(optionListJsonresponse));
      resultObject.optionList = optionListJsonresponse;
      return fetch(canvasCustomPricingUrl, requestOptions);
    })

    .then(handleJSONResponse)
    .then((canvasCustomPriceListJsonresponse) => {
      // alert("canvasCustomPriceList: "+  JSON.stringify(canvasCustomPriceListJsonresponse));
      resultObject.canvasCustomPriceList = canvasCustomPriceListJsonresponse;
      return fetch(envelopPricingUrl, requestOptions);
    })

    .then(handleJSONResponse)
    .then((envelopePriceListJsonresponse) => {
      // alert("envelopePriceListJsonresponse: "+  JSON.stringify(envelopePriceListJsonresponse));
      resultObject.envelopePriceList = envelopePriceListJsonresponse;
      return fetch(classicCoverOptionsUrl, requestOptions);
    })

    .then(handleJSONResponse)
    .then((classicCoverOptionJsonResponse) => {
      // reformat response for better readability
      /*
        leatherblack:{$: {…}, node: Array(3)}
            $:{type: 'leather', color: '0x000000'}
                node:(3) [{…}, {…}, {…}]
                    0:{$: {…}}
                    1:{$: {…}}
                    2:{$: {…}}
        */
      const coverStock = {};
      for (const optionName in classicCoverOptionJsonResponse.root
        .cover_stock) {
        if (optionName !== '_comment') {
          coverStock[optionName] = {};
          const nodeArr =
            classicCoverOptionJsonResponse.root.cover_stock[optionName].node;
          nodeArr.forEach((productEl) => {
            coverStock[optionName][productEl.$.product] = {
              stock: productEl.$.stock,
              spine: productEl.$.spine,
            };
          });
        }
      }

      // resultObject.classicCoverOptions = classicCoverOptionJsonResponse;
      resultObject.classicCoverOptions = coverStock;
      return resultObject;
    })

    .catch(handleGeneralError);
}

/**
 * Get "calculated" backend project price
 * Optional: recover the jobID if this is for an order!
 */
function getProjectPriceServerSide(
  project: Project,
  vendorID: string,
  getJobId = false
) {
  // generate the URL
  const url = generatePriceUrl(project, vendorID, getJobId);
  const requestOptions = {
    credentials: 'include',
  };

  return fetch(url, requestOptions)
    .then((response) => response.text())
    .then((resultText) => {
      console.log(`API.getProjectPriceServerSide:${resultText}`);
      return resultText;
    })

    .catch(handleGeneralError);
}

// --------------------- ORDER ------------------------

function printToPDF(
  orderString: string,
  docCode,
  getJobId = false,
  orderSuffix: string = null
) {
  console.log('PrintToPDF');
  // FROM actionscript:
  // var mu : MultipartURLLoader = new MultipartURLLoader();
  // mu.addVariable("code", "tyd8uqtzuv7kmz2xmm8j3k6tp72e2pya");
  // mu.addVariable("name", "uploadfile");
  // mu.addVariable("filename", "uploadfile.xml");
  // mu.addFile(bytes, "upload"+orderSuffix, "uploadfile");

  const params = {
    code: 'tyd8uqtzuv7kmz2xmm8j3k6tp72e2pya',
    prodcode: docCode,
    name: 'uploadfile',
    filename: 'uploadfile.xml',
  };
  // convert params to form data
  const formData = ObjectToFormData(params);

  const file = StringToFile(orderString, `upload${orderSuffix}`);
  // const blob = StringToBlob( orderString );

  formData.append('uploadfile', file, `upload${orderSuffix}`);

  // alert("file length:"+file.size);

  // request
  const requestOptions = {
    method: 'POST',
    // headers: {
    //         "Content-Type": "multipart/form-data",
    // },
    // body: require('querystring').stringify(params),
    body: formData,
    credentials: 'include',
  };

  const url = `${config.secureUrl}test-new-flex.php?length=${file.size}`; // TODO : change this url with real one

  return fetch(url, requestOptions)
    .then((response) => response.text())
    .then((responseText) => {
      // alert("response: "+responseText);
      console.log(`Response:${responseText}`);
      let pdfUrl = '';

      let modalContent = null;

      if (responseText.indexOf('<a href=') !== -1) {
        pdfUrl = responseText.split('<a href="')[1];
        pdfUrl = pdfUrl.split('">pdf file')[0];

        // desc = "New genereated pdf url : "+pdfUrl+"<br /><br />Click ok to open it";
        const maxChars = 200;
        modalContent = (
          <div>
            PDF generated successfully :{' '}
            {responseText.length < maxChars
              ? responseText
              : `${responseText.substr(0, maxChars)}[ ... ]`}
          </div>
        );
        // check to open pdf url!
        Modal.success({
          title: 'Test PDF GENERATED',
          content: modalContent,
          okText: 'Open PDF',
          onOk: () => {
            window.open(pdfUrl, '_blank');
          },
        });
      } else {
        modalContent = <div>Error: {responseText}</div>;

        Modal.error({
          title: 'Test PDF GENERATION ERROR',
          content: modalContent,
          okText: 'CLOSE',
          // onOk: ()=>{ window.open( pdfUrl, "_blank" )}),
        });
      }
    });
  // .then(handleJSONResponse)
  // .then( jsonResponse =>{
  //     alert("JSON Response:" + jsonResponse);
  // });
}

/**
 *
 * Send order to backend
 */
function sendOrder(
  orderString: string,
  jobID: string,
  orderSuffix: string = null
) {
  const params = {
    code: 'tyd8uqtzuv7kmz2xmm8j3k6tp72e2pya',
    name: 'uploadfile',
    filename: 'uploadfile.xml',
  };
  // convert params to form data
  const formData = ObjectToFormData(params);
  // create file
  const fileName = `${jobID}${orderSuffix}`;
  const file = StringToFile(orderString, fileName);
  // add file to formdata
  formData.append('uploadfile', file, fileName);
  // const file = StringToFile( orderString, "upload"+orderSuffix);
  // // add file to formdata
  // formData.append('uploadfile', file, "upload"+orderSuffix);

  // request
  const requestOptions = {
    method: 'POST',
    body: formData,
    credentials: 'include',
  };

  const url = `${config.secureUrl}online_image.php?length=${file.size}&jobid=${jobID}`;
  return fetch(url, requestOptions)
    .then((response) => response.text())
    .then((responseText) => responseText);
}

/**
 * Upload image that will be used in the order
 */
function uploadImageForOrder(
  fileName: string,
  dataURL: string,
  jobID: string
): Promise<string> {
  // create file
  const file: File = dataURLtoFile(dataURL, fileName);

  const params = {
    code: 'tyd8uqtzuv7kmz2xmm8j3k6tp72e2pya',
    name: 'uploadfile',
    filename: fileName,
  };

  // convert params to form data
  const formData = ObjectToFormData(params);
  formData.append('uploadfile', file, fileName);

  // request
  const requestOptions = {
    method: 'POST',
    body: formData,
    credentials: 'include',
  };

  const url = `${config.secureUrl}online_image.php?length=${file.size}&jobid=${jobID}`;
  return fetch(url, requestOptions).then(handleJSONResponse);
  // .then( bytesSent =>
  // {
  //     alert("Resonse of uploading:" + data);
  //     return data;
  // })

  // .then(handle => response.text())
  // .then(responseText => {
  //     return responseText;
  // });
}

/**
 * once we sent the order, we check the job was ok!
 */
function checkOrderJob(jobID: string): void {
  // request
  const requestOptions = {
    method: 'POST',
    credentials: 'include',
  };

  const url: string = OrderHelper.generateCheckJobUrl(jobID);
  return fetch(url, requestOptions).then(handleJSONResponse);
  // .then(response => response.text())
  // .then(responseText => {
  //     console.log("checkOrderJob Response: " + responseText );
  //     return responseText;
  // })
}

/// //////////////////////////////////////////////////////////////
/// FEEDBACK AND DEV MAILS
/// //////////////////////////////////////////////////////////////

/**
 * Send a dev mail
 */
function sendDevMail(
  error: Error,
  details: string,
  isFeedback: boolean,
  optionalSubject: string
): Promise {
  // recover store infos
  const state = getStore();
  const sessionID = authSelectors.GetSessionID(state);
  const userMail = authSelectors.GetUserEmail(state);
  const userID = authSelectors.GetUserID(state);

  // error detail
  const variables = {};
  let body = 'HTML EDITOR error:\n';
  // body += "\n App Version: " + process.env.REACT_APP_VERSION;

  body += GetSessionInfos();

  body += '\n\n[ MESSAGE ]:';
  body += `\n${error.message}`;
  body += '\n\n';

  if (details) {
    body += '\nDETAILS:';
    body += `\n${details}`;
    body += '\n***************************';
  }

  if (error.stack) {
    body += '\nSTACK: ';
    body += `\n${error.stack}`;
    body += '\n***************************';
    body += `\n\nDETAIL: ${JSON.stringify(
      error,
      Object.getOwnPropertyNames(error)
    )}`;
  }

  body += '\n***************************';
  body += '\nALL LOGS: ';
  body += '\n***************************\n';
  GetAllLogs().forEach((log) => {
    body += `${log}\n`;
  });

  const subject =
    optionalSubject || `Tictac HTML ${isFeedback ? 'FEEDBACK' : 'ERROR'}`;

  variables.msg = encodeURIComponent(body);

  let url = `${config.statServerUrl}mail.php`;
  url += `?email=${encodeURIComponent(userMail)}&ticket=${
    encodeURIComponent(sessionID) // GetUID())
  }&dev=${
    IsLocalhost // isDevSession
  }&silent=${
    false // isSilentWarning
  }&feedback=${
    isFeedback // isFeedback
  }&user=${encodeURIComponent(userID)}&offline=${
    false // Infos.IS_DESKTOP;
  }&subject=${subject}`;

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: convertParamsToQueryString(variables),
    credentials: 'include',
    // headers: { 'Content-Type': 'application/json' },
    // body: JSON.stringify({ username, password })
  };

  return fetch(url, requestOptions).then(handleJSONResponse);
  // .then( response => {alert("TODO:sendDevMailResponse:"+JSON.stringify(response))})
}

/**
 *
 */
function sendUserFeedbackToDev(feedback: string): Promise {
  // recover store infos
  const storeRef = getStore();
  const sessionID = authSelectors.GetSessionID(storeRef);
  const userMail = authSelectors.GetUserEmail(storeRef);
  const userID = authSelectors.GetUserID(storeRef);
  const { project } = storeRef.edition;

  // error detail
  const variables = {};
  let body = GetFeedbackWithDetails(feedback);

  // add project
  if (project)
    body += `\n\n ---------------- PROJECT ---------------- \n\n${JSON.stringify(
      project
    )}`;

  variables.msg = encodeURIComponent(body);

  let url = `${config.statServerUrl}contact.php`;
  url +=
    `?email=${encodeURIComponent(userMail)}&dev=${
      IsLocalhost
      // +"&topic="+topic
    }&topic=HTML Beta Feedback` +
    `&user=${encodeURIComponent(userID)}&offline=false${getTimeStamp()}`;

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: convertParamsToQueryString(variables),
    credentials: 'include',
    // headers: { 'Content-Type': 'application/json' },
    // body: JSON.stringify({ username, password })
  };

  return fetch(url, requestOptions).then(handleJSONResponse);
  // .then( response => {alert("TODO:sendUserFeedback:"+JSON.stringify(response))})
}

/**
 * Retrieve full feedback message with details
 * @param {string} feedback
 */
function GetFeedbackWithDetails(feedback: string): string {
  const storeRef = getStore();
  const sessionID = authSelectors.GetSessionID(storeRef);
  const userMail = authSelectors.GetUserEmail(storeRef);
  const userID = authSelectors.GetUserID(storeRef);
  const { project } = storeRef.edition;

  let message = '';
  message += '[ Message ]:';
  message += `\n${feedback}`;

  message += GetSessionInfos();
  return message;
}

function GetSessionInfos() {
  let message = '\n\n';
  message += '[ Infos ]:';
  message += `\n - build: ${process.env.REACT_APP_VERSION}`;
  message += `\n - userAgent: ${navigator.userAgent.toLowerCase()}`;
  message += `\n - brower size: ${window.innerWidth}x${window.innerHeight}`;
  message += `\n - IsMobile: ${IsMobileOrTablet()}`;

  try {
    const storeRef = getStore();
    const sessionID = authSelectors.GetSessionID(storeRef);
    const userMail = authSelectors.GetUserEmail(storeRef);
    const lang = GetCurrentLanguage();
    const userID = authSelectors.GetUserID(storeRef);
    const { project } = storeRef.edition;

    message += `\n - lang: ${lang}`;
    message += `\n - session ID: ${sessionID}`;
    message += `\n - user ID: ${userID}`;
    message += `\n - userEmail: ${userMail}`;

    if (project) {
      message += `\n - project ID: ${project.id}`;
      message += `\n - project infos: ${GetProjectDescription(project)}`;
    }
  } catch (e) {
    message += `Error catched in session infos:${e}`;
  }

  message += '\n\n';

  return message;
}

/**
 *
 * Send order to backend
 */
function sendUserFeedbackToSupport(feedback: string) {
  // recover store infos
  const storeRef = getStore();
  const userMail = authSelectors.GetUserEmail(storeRef);
  const vendorID = authSelectors.GetVendorID(storeRef);

  const params = {
    subject: 'Contact Form Editor HTML',
    topic: 'Feedback from HTML Editor',
    email: userMail,
    build: process.env.REACT_APP_VERSION,
    vendor: vendorID,
    lang: GetCurrentLanguage(),
    useragent: window.navigator.userAgent.toString(),

    message: GetFeedbackWithDetails(feedback),
  };

  // request
  const requestOptions = {
    method: 'POST',
    body: ObjectToFormData(params),
    credentials: 'include',
  };

  const url = `${config.secureUrl}cs/editor_contact.php${getTimeStamp('?t=')}`;
  return fetch(url, requestOptions)
    .then((response) => response.text())
    .then((responseText) => responseText);
}

// /**
//  *  send contact form to Support system
//  */
// public function sendContactToSupport(message:String, userEmail:String, topic:String, onSuccess:Function = null, onError:Function = null):void
// {
//  var variables:URLVariables = new URLVariables();
//  variables.subject = "Contact Form "
//  variables.subject += (Infos.IS_DESKTOP)?"(OFFLINE)":"(ONLINE)";
//  variables.email = userEmail;
//  variables.build = ""+Infos.buildVersion;
//  variables.message = message;
//  variables.topic = topic;
//  variables.vendor = Infos.config.vendorId;
//  variables.lang = Infos.lang;
//  variables.useragent = getUserAgent();

//  DataLoadingManager.instance.showLoading(false, null, ResourcesManager.getString("loading.order"));
//  var url:String = serverSecureUrl + "cs/editor_contact.php";
//  executeService(url, variables, onSuccess, onError);
// }

// /**
//  * Send message from the contact popup
//  */
// public function sendContactMail(message:String, userEmail:String, topic:String, onSuccess:Function = null, onError:Function = null):void
// {
//  var variables:URLVariables = new URLVariables();
//  variables.msg = encodeURIComponent(message);

//  var isDevSession:String = ( Debug.CONSOLE ) ? "true":"false";

//  var url:String = statServerURL + "contact.php";
//  url += "?email="+encodeURIComponent(userEmail)
//   +"&dev="+isDevSession
//   +"&topic="+topic
//   +"&user="+Infos.session.userId
//   +"&offline="+Infos.IS_DESKTOP;
//  executeService(url, variables, onSuccess, onError);

//  // when user send a contact mail, we also send logs
//  Debug.sendDevMail("Contact Form : "+message,false, true);

// }

/// //////////////////////////////////////////////////////////////
/// /// TODO: remove this

// private function sendOrder():void
// {
//     Debug.log("OrderManager > SEND ORDER with suffix : "+orderSuffix);

//     if(Infos.IS_DESKTOP) sendProjectSaveString();

//     var crc32:CRC32 = new CRC32();
//     var bytes:ByteArray = new ByteArray();
//     bytes.writeUTFBytes(orderStr);
//     var crc:number = crc32.ComputeChecksum(bytes);
//     var ml:MultipartURLLoader = new MultipartURLLoader();
//     ml.addEventListener(Event.COMPLETE, orderUploadComplete);
//     ml.addEventListener(IOErrorEvent.IO_ERROR, orderSendError);
//     ml.addEventListener(SecurityErrorEvent.SECURITY_ERROR, orderSendError);
//     ml.dataFormat = URLLoaderDataFormat.TEXT;
//     ml.addFile(bytes, jobId+orderSuffix, "uploadfile");
//     var url:String = Infos.config.serverSecureUrl + "/" + Infos.config.getSettingsValue("HTTPUploadPage") + "?length="+bytes.length.toString()+"&jobid="+jobId;
//     ml.load(url, false);
// }
// private function orderUploadComplete(event:Event):void
// {
//     sendRetryCount = 0;
//     MultipartURLLoader(event.currentTarget).removeEventListener(Event.COMPLETE, orderUploadComplete);
//     var response:String = MultipartURLLoader(event.currentTarget).loader.data;
//     Debug.log("OrderManager > SEND ORDER result : "+response);
//     MultipartURLLoader(event.currentTarget).dispose();
//     orderCheckJob(response);
// }

/// ///////////////////////////////////////////////////////////////

//     DataLoadingManager.instance.updateText(ResourcesManager.getString("loading.order.pdf.generate"));

//     var mu : MultipartURLLoader = new MultipartURLLoader();
//     mu.addVariable("code", "tyd8uqtzuv7kmz2xmm8j3k6tp72e2pya");
//     mu.addVariable("name", "uploadfile");
//     mu.addVariable("filename", "uploadfile.xml");

//     var bytes:ByteArray = new ByteArray();
//     bytes.writeUTFBytes(orderStr);

//     mu.dataFormat = URLLoaderDataFormat.TEXT;
//     //mu.addFile(bytes, "uploadfile.xml", "uploadfile");
//     mu.addFile(bytes, "upload"+orderSuffix, "uploadfile");
//     mu.addEventListener(Event.COMPLETE, onPrintToPDFResult);
//     mu.addEventListener(SecurityErrorEvent.SECURITY_ERROR, onPrintToPDFFail);
//     mu.addEventListener(IOErrorEvent.IO_ERROR, onPrintToPDFFail);
//     var url : String = Infos.config.serverSecureUrl + "/" + "test-new-flex.php?length="+bytes.length.toString();// TODO : change this url with real one
//     Debug.log(" > url : "+url);
//     mu.load(url, false);
// }
// private function onPrintToPDFFail(event:ErrorEvent):void
// {
//     DataLoadingManager.instance.hideLoading();
//     PopupAbstract.Alert("Error while uploading PDF", "Do you want to retry? \nERROR : "+event.toString() + "\n"+event.text, true, function(p:PopupAbstract):void{
//         // GOTO PDF
//         DataLoadingManager.instance.showLoading();
//         printToPDF();
//     });
//     Debug.warn("OrderManager.onPrintToPDFFail: "+event.text);
//     Debug.warn("OrderManager.onPrintToPDFFail: "+event.toString());

//     var mu:MultipartURLLoader = event.target as MultipartURLLoader;
//     Debug.warn("OrderManager.onPrintToPDFFail: result: "+mu.loader.data);
// }
// private function onPrintToPDFResult(event:Event):void
// {
//     var result : String = "" + MultipartURLLoader(event.currentTarget).loader.data;
//     Debug.log("OrderManager > onPrintToPDFResult : "+result);
//     DataLoadingManager.instance.hideLoading();

//     // Display result
//     var title : String ;
//     var pdfUrl : String;
//     var desc : String;
//     if( result.indexOf('<a href=') !==-1 ){
//         pdfUrl = result.split('<a href="')[1];
//         pdfUrl = pdfUrl.split('">pdf file')[0];
//         title = "PDF GENERATED !";
//         desc = "New genereated pdf url : "+pdfUrl+"<br /><br />Click ok to open it";
//     } else {
//         title = "PDF GENERATION ERROR";
//         // security to display maximum of 5 lines of errors!
//         var splittedResult : Array = result.split("\n");
//         var maxLine:number = 5;
//         if(splittedResult.length > maxLine)
//         {
//             desc ="<b>";
//             for (var i:number = splittedResult.length-1; i > splittedResult.length-maxLine; i--)
//             {
//                 desc += "\n" + splittedResult[i];
//                 if(i===splittedResult.length-1) desc+="\n";
//             }
//             desc += "</b>\n[...]";
//         }
//         else
//             desc = "" + result;
//         pdfUrl = "#";
//     }

//     PopupAbstract.Alert(title, desc, true, function(p:PopupAbstract):void{
//         // GOTO PDF
//         navigateToURL(new URLRequest(pdfUrl),"_blank");
//     });
//     isOrdering = false;
// }

/*
function getById(id) {
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  };

  return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}

function update(user) {
  const requestOptions = {
      method: 'PUT',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify(user)
  };

  return fetch(`${config.apiUrl}/users/${user.id}`, requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
      method: 'DELETE',
      headers: authHeader()
  };

  return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}

*/

/// //////////////////////////
/*
  let postValues = {
   email:this.state.email,
   password:this.state.password
  }

  console.log(JSON.stringify(postValues));

  let headers= {
   //'Authorization': 'Basic Y2xpZW50OnNlY3JldA===',
   'Content-Type': 'application/x-www-form-urlencoded'
        }

  // USING AXIOS

  let queryString = require('querystring');
  Axios.post(url, queryString.stringify(postValues), { headers:headers, withCredentials:true })
  .then( response => {
   console.log(response);
   //alert("Status:" + response.status);
   //alert(response.data);
   //alert(postValues.email)

   // convert xml to json :
   let convert = require('xml-js');
   let resultJson = JSON.parse(convert.xml2json(response.data, {compact:true, spaces:4}));

   console.log("resultJson: " + JSON.stringify(resultJson));
   alert("You are logged in, userID: " + resultJson.root.session.user_id._text);

   //alert(JSON.stringify(this.props));
   // notify authenticated
   this.props.userHasAuthenticated(true);
   this.setState({isLoading:false});
   this.handleLoginSuccess();
  })
  .catch(error => {
   console.log("error:" + error);
   alert("error, check logs");
   this.setState({isLoading:false});
        });
        * /
/////////////////////////////

    return fetch(`${config.apiUrl}/users/authenticate`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));

            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${config.apiUrl}/users/register`, requestOptions).then(handleResponse);
}

/**
 * Handle default json response
 * @param {*} response
 * /
function handleResponse(response) {
    return response.text().then(text => {

        // Data must be in JSON Format, otherwise it will fail here
        const data = text && JSON.parse(text);

        // --- CASE ERROR
        if (data.status === "error") {
            // alert("ERROR:" + data.message); // TODO change this!
            /*
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
            }
            * /
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

/**
 * Handle default json response
 * @param {*} response
 * /
function handleResponse(response) {
  return response.text().then(text => {

      // Data must be in JSON Format, otherwise it will fail here
      const data = text && JSON.parse(text);

      // --- CASE ERROR
      if (data.status === "error") {
          // alert("ERROR:" + data.message); // TODO change this!
          /*
          if (response.status === 401) {
              // auto logout if 401 response returned from api
              logout();
              window.location.reload(true);
          }
          * /
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
      }

      return data;
  });
}

/* ORIGINAL FUNCTION
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
*/

// --;

export const API = {
  // Auth
  checkSession,
  resetSessionProject, // this allows to reset the session parameters for projects
  login,
  register,
  logout,

  // projects
  getUserProjects,
  loadProject,
  saveProject,

  // pricing
  getPricing,
  getProjectPriceServerSide, // to validate price just before ordering

  // photos
  getUserPhotos,
  savePhoto,

  // assets
  getLayouts,
  SaveCustomLayout,
  getBackgrounds,
  getCliparts,
  getOverlayers,

  // order
  printToPDF,
  sendOrder,
  uploadImageForOrder,
  checkOrderJob,

  // feedback
  sendDevMail,
  sendUserFeedbackToDev,
  sendUserFeedbackToSupport,
};
