import React from 'react';
import { connect } from 'react-redux';

import { Button, Tooltip, Popover, Empty, Select } from 'antd';
import { cloneDeep } from 'lodash';
import {
  layoutListActions,
  layoutListSelectors,
} from '../../../feature/layouts/layoutList';
import {
  ApplyLayoutToPage,
  Layout,
} from '../../../feature/layouts/layoutHelper';

import {
  editionActions,
  editionSelectors,
} from '../../../feature/edition/edition';
import { photoListSelector } from '../../../feature/photoList/photoList';
import { DRAG_TYPE, AddDragData } from '../../../utils/DragUtils';
import { DebugFlags } from '../../../debug/DebugFlags';
import { GetText } from '../../../data/LanguageHelper';
import { FRAME_TYPE } from '../../../feature/edition/frameHelper';
import { backgroundSelectors } from '../../../feature/backgrounds/background';
import {
  getCoverBackgroundRect,
  getPageBackgroundRect,
} from '../../../feature/backgrounds/backgroundHelper';
import { Colors } from '../../../data/Colors';
import { IsCover } from '../../../utils/coverHelper';
import { TABS_ENUM } from './TABS_ENUM';

class LayoutsArea extends React.Component {
  // --------------------- constructor ------------------------

  constructor(props) {
    super(props);
    this.state = {
      currentCategory: 'all',
      files: null,
      urlList: [],
    };

    // this.handleSubmit = this.handleSubmit.bind(this);
    // this.handleImageChange = this.handleImageChange.bind(this);
    // this.checkRenderPreview = this.checkRenderPreview.bind(this);
  }

  // --------------------- react ------------------------

  componentDidMount() {
    // message.info("component did mount, we will now retrieve the photo list!");
    // alert("Get Layouts");
    // retreive full list of photos // Done in homepage now!
    // this.props.dispatch( layoutListActions.getAll( this.props.projectClassname)  );
  }

  shouldComponentUpdate(nextProps, nextState) {
    // const differentTitle = this.props.title !== nextProps.title;
    // const differentDone = this.props.done !== nextProps.done
    // return differentTitle || differentDone;

    // we should update layouts only if layouts did change, or if we switch from cover to page
    const tabIsActive = this.props.selectedTab === TABS_ENUM.LAYOUT;
    const tabWillBecomeActive =
      nextProps.selectedTab !== this.props.selectedTab &&
      nextProps.selectedTab === TABS_ENUM.LAYOUT;
    const coverChange =
      this.props.selectedPageIndex !== nextProps.selectedPageIndex &&
      (this.props.selectedPageIndex === 0 || nextProps.selectedPageIndex === 0);
    const categoryChange =
      this.state.currentCategory !== nextState.currentCategory;
    const projectDidChnage =
      this.props.project &&
      nextProps.project &&
      this.props.project.id !== nextProps.project.id;

    // Conditions to re render this component, only if the layout list is different!
    // const layoutsDifferent = this.props.layouts !== nextProps.layouts;
    const layoutsDifferent =
      this.props.currentPageLayouts !== nextProps.currentPageLayouts;
    return (
      tabWillBecomeActive ||
      (tabIsActive &&
        (layoutsDifferent || coverChange || categoryChange || projectDidChnage))
    );
  }

  // --------------------- methods ------------------------

  handleSubmit(e) {
    e.preventDefault();

    alert('TODO : here we upload!');

    // TODO: do something with -> this.state.file
    // console.log('handle uploading-', this.state.file);
  }

  handleImageChange(e) {
    e.preventDefault();

    // save file data
    const { files } = e.target;
    this.setState({ files, urlList: [] }, () => {
      this.checkRenderPreview(0);
    });
    // this.checkRenderPreview(0);

    /*
      let reader = new FileReader();
      let files = e.target.files;
      let imageUrls = []

      for (let index = 0; index < files.length; index++) {
          let f = files[index];
          reader.onloadend = () => {
                imageUrls.push(reader.result);
                this.setState({
                    files: files,
                    urlList: imageUrls
            });
          }
          reader.readAsDataURL(f)
      }
      */
  }

  checkRenderPreview(fileIndex) {
    const { files, urlList } = this.state;

    // break
    if (fileIndex > files.length - 1) return;

    const reader = new FileReader();
    const f = files[fileIndex].originFileObj;
    reader.onloadend = () => {
      urlList.push(reader.result);
      this.setState(
        {
          files,
          urlList,
        },
        () => {
          this.checkRenderPreview(fileIndex + 1);
        }
      );
    };
    reader.readAsDataURL(f);
  }

  handleLayoutClick(layoutItem) {
    const { project, photosByID, backgroundsByID } = this.props;

    // get a copy of page to work with
    const pageCopy = cloneDeep(
      this.props.project.pageList[this.props.selectedPageIndex]
    );

    // apply layout to copy
    ApplyLayoutToPage(
      project,
      pageCopy,
      layoutItem,
      photosByID,
      backgroundsByID,
      pageCopy.isCover
        ? getCoverBackgroundRect(project)
        : getPageBackgroundRect(project)
    );

    // clear possible selected frame
    this.props.dispatch(editionActions.ChangeSelectedFrame(null));

    // dispatch page update
    this.props.dispatch(editionActions.UpdatePage(pageCopy));

    // layout applied, allow undoable action
    this.props.dispatch(editionActions.AddUndoableAction());
  }

  handleApplyToAll(item) {
    this.props.dispatch(editionActions.ApplyLayoutToAllPages(item.id));
  }

  handleSaveCurrentLayout = () => {
    this.props.dispatch(layoutListActions.SaveCurrentPageLayout());
    this.setState({ currentCategory: 'custom' });
  };

  handleDeleteCustomLayout = (item: Layout) => {
    this.props.dispatch(layoutListActions.DeleteCustomLayout(item.id));
  };

  handleItemDragStart(evt, item) {
    AddDragData(evt, DRAG_TYPE.LAYOUT, item.id);
  }

  handleCategoryChange(newCategory) {
    this.setState({
      currentCategory: newCategory,
    });
  }

  // handleDraggerChange = ( info )=> {

  //     const status = info.file.status;
  //     message.info("File change: (" + info.file.name + "): " + status );

  //     const files = info.fileList;
  //     this.setState({
  //         files:files,
  //         urlList:[]
  //     },
  //     // on complete
  //     ()=>{
  //         this.checkRenderPreview(0);
  //     });

  //     /*

  //     const status = info.file.status;
  //     if (status !== 'uploading') {
  //         console.log(info.file, info.fileList);
  //     }
  //     if (status === 'done') {
  //         message.success(`${info.file.name} file uploaded successfully.`);
  //     } else if (status === 'error') {
  //         message.error(`${info.file.name} file upload failed.`);
  //     }
  //     */

  // }

  // SEE DOC : https://stackoverflow.com/questions/51514757/action-function-is-required-with-antd-upload-control-but-i-dont-need-it
  dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  /// ///////////////////////////////////////////////////
  // Render
  /// ///////////////////////////////////////////////////
  render() {
    const {
      layouts,
      layoutsByID,
      layoutsByPhotoCategories,
      project,
      selectedPageIndex,
      currentPage,
    } = this.props;
    const { currentCategory } = this.state;
    // const pageRatio = (project) ? project.width / project.height : -1;
    let pageRatio = project
      ? project.pageList[selectedPageIndex].width /
        project.pageList[selectedPageIndex].height
      : -1;

    const { Option } = Select;

    console.log('RENDER LAYOUT AREA');

    // list of layouts to display depending on the current pages opened!
    // let layoutsByID = layouts.byID; // from props
    const layoutsFiltered = layouts.filtered;

    const isClassicCover = !!(currentPage && currentPage.coverClassicOptions);

    // TODO: handle other cases here!
    // let layoutType = (selectedPageIndex === 0)? LAYOUT_TYPE.ALBUM_COVER : LAYOUT_TYPE.ALBUM;
    // let layoutsToDisplay = (!isClassicCover && layouts.filtered && layouts.filtered[layoutType])? layouts.filtered[layoutType].all : [];

    const layoutsToDisplay =
      !isClassicCover &&
      this.props.currentPageLayouts &&
      this.props.currentPageLayouts[currentCategory]
        ? this.props.currentPageLayouts[currentCategory]
        : [];

    return (
      <div className="tabContent layoutsArea">
        {/* // --------------------- HEADER ------------------------ */}
        <div className="tabHeader">
          <h3>{GetText('lefttab.layouts')}</h3>
          {/* <Spin spinning={layouts.isLoading}> */}

          <Select
            // defaultValue={currentCategory}
            value={currentCategory}
            // placeholder="select a category"
            onChange={(value) => {
              this.handleCategoryChange(value);
            }}
            className="dropdown"
          >
            {layoutsByPhotoCategories.map((category, index) => {
              const label =
                category === 'all'
                  ? GetText('lefttab.layouts.allLayouts')
                  : category === 'custom'
                  ? GetText('lefttab.layouts.customLayouts')
                  : `${category} ${GetText('common.photos')}`;
              return (
                <Option key={category} value={category}>
                  {label}
                </Option>
              );
            })}
          </Select>
        </div>

        {
          // --------------------- CONTENT SCROLLABLE  ------------------------
          <div className="tabScrollContent">
            {
              // --------------------- layout LIST ------------------------
              <div className="layoutsContainer">
                {
                  // --------------------- CASE layouts available ------------------------
                  layoutsToDisplay &&
                    layoutsToDisplay.map((layoutID, key) => {
                      const item: Layout = layoutsByID[layoutID];
                      // const overContent = (
                      //     <div style={{width:500, wordWrap:"break-word"}}>
                      //         {JSON.stringify(item)}
                      //     </div>
                      // );
                      let overContent = (
                        <div
                          className="backgroundItemOverlay"
                          style={DebugFlags.DEBUGGING ? { width: 200 } : null}
                        >
                          {/* <b>{item.id}</b> */}

                          {
                            // --- DEBUG - show json format
                            DebugFlags.DEBUGGING && (
                              <Button
                                type="danger"
                                size="small"
                                className="button"
                                onClick={() => {
                                  alert(JSON.stringify(item));
                                }}
                              >
                                Debug: SHOW LAYOUT
                              </Button>
                            )
                          }

                          <Button className="button" type="primary">
                            {GetText('lefttab.layouts.apply')}
                          </Button>

                          {
                            // --- APPLY TO ALL
                            // allow apply to all button only if not on cover page
                            project &&
                              !IsCover(
                                project.docID,
                                this.props.selectedPageIndex
                              ) && (
                                <Button
                                  className="button"
                                  type="primary"
                                  onClick={() => this.handleApplyToAll(item)}
                                >
                                  {GetText('lefttab.layouts.applytoall')}
                                </Button>
                              )
                          }
                          {
                            // --- DELETE
                            item.isCustom && (
                              <Button
                                type="danger"
                                className="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.handleDeleteCustomLayout(item);
                                }}
                              >
                                {GetText('lefttab.layouts.delete')}
                              </Button>
                            )
                          }
                        </div>
                      );
                      let overContentDelay = DebugFlags.DEBUGGING ? 0.5 : 0.5;
                      if (currentPage.isCover) {
                        overContent = null;
                        overContentDelay = 999999;
                      }

                      if (pageRatio === -1) {
                        // security if no project loaded
                        pageRatio = item.width / item.height;
                      }

                      const contentW = 90;
                      const contentH = contentW / pageRatio;

                      const scaleX = contentW / item.width;
                      const scaleY = contentH / item.height;

                      return (
                        // TODO:check to use antd Grid here with list item card
                        <Popover
                          key={item.id}
                          // title={"id:" + item.id}
                          content={overContent} // title={"id:" + item.id}
                          placement="right"
                          trigger="hover"
                          mouseEnterDelay={overContentDelay}
                        >
                          <div
                            role="button"
                            tabIndex="0"
                            className="layoutItem"
                            style={{
                              width: contentW,
                              height: contentH,
                            }}
                            onDragStart={(e) =>
                              this.handleItemDragStart(e, item)
                            }
                            onClick={(e) => this.handleLayoutClick(item)}
                            draggable
                          >
                            {
                              // see doc of popover that extends the tooltip
                              // https://gary-shen.github.io/ant-design/components/tooltip/
                            }
                            {/* <Tooltip placement="right" title={"id:"+item.id + "\nname:"+item.name}> */}

                            <svg viewBox={`0 0 ${contentW} ${contentH}`}>
                              {item.frames &&
                                /// ////////////////
                                // DRAW FRAMES
                                item.frames.map((frame, i) => {
                                  // with frame from json
                                  const t = {
                                    // x : (frame.x - frame.width/2)*scaleRatio,
                                    // y : (frame.y - frame.height/2)*scaleRatio,
                                    x: frame.x * scaleX,
                                    y: frame.y * scaleY,
                                    width: frame.width * scaleX,
                                    height: frame.height * scaleY,
                                    angle: frame.rotation,
                                  };

                                  // SEcurity, sometimes we got undifined width
                                  // TODO: we should validate this higher when we generate layouts.. some kind of "verify" function with logs
                                  if (!Number.isNaN(frame.width)) {
                                    // Draw frame
                                    return (
                                      <g key={i}>
                                        <rect
                                          x={t.x - t.width / 2}
                                          y={t.y - t.height / 2}
                                          width={t.width}
                                          height={t.height}
                                          // transform={"rotate("+ t.angle +"," + t.width/2 +"," + t.height/2 +")"}
                                          transform={`rotate(${t.angle})`}
                                          className={
                                            frame.type === FRAME_TYPE.TEXT
                                              ? 'frameText'
                                              : frame.type ===
                                                FRAME_TYPE.CLIPART
                                              ? 'frameClipart'
                                              : 'frame'
                                          }
                                        />
                                        {frame.type === FRAME_TYPE.TEXT && (
                                          <text
                                            height={t.height}
                                            width={t.width}
                                            y={t.y}
                                            x={t.x}
                                            className="unselectable"
                                            style={{
                                              // overflow:"hidden",
                                              textAnchor: 'middle', // halign
                                              dominantBaseline: 'middle', // valign
                                              fontSize: '10px',
                                              // fontWeight:"bold",
                                              fontFamily: 'Helvetica',
                                              fill: Colors.BLUE_6,
                                            }}
                                          >
                                            T
                                          </text>
                                        )}
                                      </g>
                                    );
                                  }
                                  return <g />;
                                })}
                            </svg>
                            {/* </Popover> */}
                          </div>
                        </Popover>
                      );
                    })
                }
              </div>
            }

            {
              // --------------------- CASE EMPTY ------------------------
              (!layoutsToDisplay || layoutsToDisplay.length === 0) && (
                <Empty
                  style={{ width: '100%' }}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={GetText('lefttab.layout.empty.label')}
                />
              )
            }
          </div>
        }

        {
          // --------------------- FOOTER ------------------------
          <div className="tabFooter">
            {
              //* SAVE CUSTOM LAYOUT */
              <Tooltip
                title={GetText('tooltip.customLayout.save')}
                placement="right"
              >
                <Button
                  className="customSaveButton"
                  type="primary"
                  disabled={!this.props.allowSavePageLayout}
                  onClick={(e) => this.handleSaveCurrentLayout()}
                >
                  {GetText('customLayout.save')}
                </Button>
              </Tooltip>
            }
          </div>
        }

        {/* </Spin> */}
      </div>
    );
  }
}

// --------------------- redux ------------------------

// Redux map
function mapStateToProps(state) {
  const { layouts, edition, photos, ui } = state;
  const { project } = edition;

  // we need user, project and layouts
  return {
    project,
    selectedTab: ui.selectedTab,
    selectedPageIndex: edition.selectedPage,
    currentPage: editionSelectors.GetSelectedPage(state),
    allowSavePageLayout: layoutListSelectors.AllowSavePageLayout(state),
    // photoList: photos.photosList,
    photosByID: photoListSelector.getAllPhotosByID(state),
    backgroundsByID: backgroundSelectors.getAllBackgroundsByID(state),

    layouts,
    layoutsByID: layoutListSelectors.getLayoutsByID(state),
    layoutsByPhotoCategories:
      layoutListSelectors.getLayoutPhotosCategories(state),
    currentPageLayouts: layoutListSelectors.getCurrentPageLayouts(state),
  };
}

// --------------------- export ------------------------

const reduxConnected = connect(mapStateToProps)(LayoutsArea);
export { reduxConnected as LayoutsArea };
