import React from 'react';
import { connect } from 'react-redux';
import { Button, Spin, Modal, Select, InputNumber, Row, Tooltip } from 'antd';
import { cloneDeep } from 'lodash';
import { InfoCircleFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import type { IDocument, ProjectOptions } from '../../../types/types';
import {
  GetProjectDisplayPage,
  GetProjectMaxPages,
  GetProjectMinPages,
  IsClassicCoverProject,
} from '../../../utils/projectHelper';
import { editionActions } from '../../../feature/edition/edition';
import { GetProductCatalogue } from '../../../data/productCatalogue';
// import { classicNameResolver } from 'typescript';
import { GetText } from '../../../data/LanguageHelper';
import { GetDoc, GetProjectDocSizeCM } from '../../../utils/ProductHelper';
import { popupHelper } from '../../../feature/alert/popupHelper';
import { Colors } from '../../../data/Colors';
import { pricingSelectors } from '../../../feature/pricing/pricing';
import { CreateClassicCoverOptions } from '../../../utils/coverHelper';
import { history, ROUTE_CONST } from '../../../utils/history';
import { PROJECT_CONST } from '../../../data/config';
import { GetProjectOptions } from '../../../feature/project/GetProjectOptions';
import { CreateProject } from '../../../feature/project/CreateProject';

interface AlbumProjectCreationViewProps {
  isUpgrade: boolean;
  onCloseCallback: () => void;
}

class AlbumProjectCreationView extends React.Component<AlbumProjectCreationViewProps> {
  // --------------------- Constructor ------------------------

  constructor(props) {
    super(props);

    // bind functions
    // this.handleDeleteProject = this.handleDeleteProject.bind(this);
    this.handleProductChange = this.handleProductChange.bind(this);
    this.handleSizeChange = this.handleSizeChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);

    // retrieve project list
    // this.props.dispatch(projectListActions.getAll());
    const catalogue = GetProductCatalogue(); // make a copy, in case of
    // rebranding filter products
    if (
      !this.props.isUpgrade &&
      this.props.rebranding?.subProductFilter?.albums
    ) {
      catalogue.products = this.props.rebranding.subProductFilter.albums;
    }
    const productList = catalogue.products;
    const productOptionKeys = Object.keys(productList).map(
      (key, index, arr) => key
    );

    this.state = {
      catalogue,
      product: null, // take the first from list
      productOptions: productOptionKeys,
      size: null,
      sizeOptions: null,
      pages: -1,
      minPages: 0,
      maxPages: 0,
      // projectName: null,
    };
  }

  // --------------------- react ------------------------

  componentDidMount() {
    // automatically set the first product
    if (this.props.isUpgrade) {
      const { project } = this.props;
      this.handleProductChange(project.type)
        .then(() => this.handleSizeChange(project.docID))
        .then(() => this.handlePageChange(GetProjectDisplayPage(project)));
    } // default
    else {
      this.handleProductChange(this.state.productOptions[0]);
    }
  }

  // shouldComponentUpdate(nextProps) {
  //     // const differentTitle = this.props.title !== nextProps.title;
  //     // const differentDone = this.props.done !== nextProps.done
  //     // return differentTitle || differentDone;

  //     // COnditions to re render this component, only if the layout list is different!
  //     const projectlistDifferent = this.props.projects !== nextProps.projects;
  //     const projectIDDifferent = (
  //         // case no project
  //         (!this.props.project) ||
  //         (!nextProps.project)  ||
  //         // case project ids are different
  //         (this.props.project.id !== nextProps.project.id))? true : false;

  //     return (projectlistDifferent || projectIDDifferent);
  // }

  // --------------------- methods ------------------------

  handleClose = () => {
    // if not upgrade be sure to be on HOME
    if (!this.props.isUpgrade) {
      history.push(ROUTE_CONST.HOME);
    }

    // invoke callback if there is one
    this.props.onCloseCallback?.();
  };

  handleChange(value) {
    console.log(`selected ${value}`);
  }

  handleProductChange(val): Promise {
    return new Promise((resolve, reject) => {
      const product = this.state.catalogue.products[val];
      const sizeOptions = product.docs;
      let { size } = this.state;
      if (!sizeOptions.includes(size)) size = product.default;

      this.setState(
        {
          product: val,
          sizeOptions,
          size,
        },
        () => {
          this.handleSizeChange(size).then(() => resolve());
        }
      ); // after setting the product, update also the size
    });
  }

  handleSizeChange(val): Promise {
    return new Promise((resolve, reject) => {
      const doc: IDocument = this.state.catalogue.docs[this.state.size];
      let { pages } = this.state;
      const maxPages = this.props.isUpgrade
        ? GetProjectMaxPages(this.props.project)
        : doc.maxPages;
      const minPages = this.props.isUpgrade
        ? GetProjectMinPages(this.props.project)
        : doc.minPages;

      if (!pages) {
        pages = doc.defaultPages;
      }
      if (pages < minPages) pages = minPages;
      if (pages > maxPages) pages = maxPages;

      this.setState(
        {
          size: val,
          pages,
          minPages,
          maxPages,
        },
        () => {
          resolve();
        }
      );
    });
  }

  handlePageChange(val): Promise {
    return new Promise((resolve, reject) => {
      // security, be sure that value is even for album projects
      if (val % 2 !== 0) {
        val += 1;
      }

      //
      this.setState(
        {
          pages: val,
        },
        () => {
          resolve();
        }
      );
    });
  }

  handleConfirm() {
    const docID = this.state.size;
    const doc = GetDoc(docID);

    // --------------------- UPGRADE ------------------------
    // case upgrade
    if (this.props.isUpgrade) {
      let targetPages = this.state.pages;
      const currentProjectPages = GetProjectDisplayPage(this.props.project);

      // confirm function
      const onConfirm = () => {
        this.props.dispatch(
          editionActions.UpgradeCurrentProject({
            type: this.state.product,
            docID,
            numPages: targetPages,
            options: {},
          })
        );
        this.handleClose();
      };

      // check min pages max pages
      if (targetPages < doc.minPages) {
        targetPages = doc.minPages;
      } else if (targetPages > doc.maxPages) {
        targetPages = doc.maxPages;
      }

      // if target pages is smaller than current pages, warn user before updating
      if (currentProjectPages > targetPages) {
        const numPagesDeleted = currentProjectPages - targetPages;
        popupHelper.showChangePageDeleteWarning(numPagesDeleted, onConfirm);
      } else {
        onConfirm();
      }

      return;
    }

    // ---------------------  NEW PROJECT  ------------------------
    // create new project
    const proj = CreateProject({
      classname: PROJECT_CONST.project_class,
      docID,
      type: this.state.product,
      projectName: 'new album project', //
      numPages: this.state.pages,
      layoutStore: this.props.layouts,
      options: {
        pagePaperQuality: doc.pagePaperQuality[doc.pagePaperQuality.length - 1], // highest quality as default
        coated: doc.coating_default, // highest quality as default
      },
    });

    // update state
    this.setState({
      project: proj,
    });

    popupHelper.showRenameProjectPopup(
      // success
      (newProjectName) => {
        const { project } = this.state;
        project.name = newProjectName;
        this.props.dispatch(editionActions.CreateProject(cloneDeep(project)));
      },
      // cancel
      () => {
        // nothing
      }
    );
  }

  getProjectPrice() {
    let options: ProjectOptions = this.props.project
      ? GetProjectOptions(this.props.project)
      : {};
    const docID = this.state.size;
    if (docID) {
      const doc = GetDoc(docID);
      options = {
        ...options,
        ...{
          docID,
          type: this.state.product,
          numPages: this.state.pages,
          coverClassicOptions:
            !options.coverClassicOptions &&
            IsClassicCoverProject(this.state.product) &&
            !options.coverClassicOptions
              ? CreateClassicCoverOptions()
              : options.coverClassicOptions,
          pagePaperQuality:
            options.pagePaperQuality === undefined
              ? doc.pagePaperQuality[doc.pagePaperQuality.length - 1]
              : options.pagePaperQuality, // highest quality as default
          coated:
            options.coated === undefined ? doc.coating_default : options.coated, // highest quality as default
        },
      };

      // TODO: find a way to put this in generic system as it is also in the "applyProjectOptions"
      // securities
      if (options.paper && !doc.insert) options.paper = false;
      if (!doc.pagePaperQuality.includes(options.pagePaperQuality))
        options.pagePaperQuality =
          doc.pagePaperQuality[doc.pagePaperQuality.length - 1]; // take highest quality
      if (!options.flyleaf || !doc.flyleaf) options.flyleaf = 'white';

      return pricingSelectors.GetProjectPrice(this.props.storeRef, options);
    }

    return 0;
  }

  // --------------------- render ------------------------

  render() {
    const { user, projects } = this.props;
    const currentProject = this.props.project;
    const projectClass = PROJECT_CONST.project_class;
    const { Option } = Select;

    const previewURL = `${process.env.PUBLIC_URL}/images/ui/menu/lefttabs/project/${projectClass}/types_${projectClass}_${this.state.product}_${this.state.size}.png`;
    const projectPrice = this.getProjectPrice();

    return (
      <Modal
        title={
          this.props.isUpgrade
            ? GetText('upgradeProject.title')
            : GetText('newProject.title')
        }
        open
        footer={null}
        // width="80%"
        width="600px"
        // onOk={this.handleOk}
        // confirmLoading={confirmLoading}
        onCancel={this.handleClose}
      >
        <Spin spinning={projects.loading}>
          <h3>{GetText('newProject.album.desc')}</h3>

          <h4>{GetText('album.type')}</h4>
          <Select
            // defaultValue="lucy"
            style={{ width: 400 }}
            value={this.state.product}
            placeholder="Select a Product"
            onChange={this.handleProductChange}
          >
            {this.state.productOptions.map((key) => (
              <Option key={key} value={key}>
                {GetText(`album.type.types_albums_${key}`)}
              </Option>
            ))}

            {/* <Option value="jack">Jack</Option>
                    <Option value="lucy">Lucy</Option>
                    <Option value="disabled" disabled>
                        Disabled
                    </Option>
                    <Option value="Yiminghe">yiminghe</Option> */}
          </Select>

          <div className="spacerV" />

          <h4>{GetText('album.size')}</h4>
          <Select
            // defaultValue="lucy"
            disabled={!this.state.size}
            value={this.state.size}
            style={{ width: 400 }}
            onChange={this.handleSizeChange}
          >
            {this.state.sizeOptions &&
              this.state.sizeOptions.map((key) => (
                <Option key={key} value={key}>
                  {GetText(`album.prefix.${key}`)} ({GetProjectDocSizeCM(key)})
                </Option>
              ))}
          </Select>

          <div className="spacerV" />

          <h4>{GetText('page.number')}</h4>
          <InputNumber
            // disabled={this.state.pages<0}
            value={this.state.pages}
            min={this.state.minPages}
            max={this.state.maxPages}
            // defaultValue={30}
            step={10}
            onChange={this.handlePageChange}
          />
          <div className="spacer" />
          <Tooltip title={GetText('tooltip.pages.max.info')}>
            <InfoCircleFilled
              style={{ fontSize: '16px', color: Colors.BLUE_6 }}
            />
          </Tooltip>

          <Row>
            <img src={previewURL} />
          </Row>

          <Row type="flex" justify="space-between">
            <Link to={ROUTE_CONST.SELECT_PROJECT_CLASS}>
              <Button type="link">
                {GetText('newProject.changeEditorClass.button')}
              </Button>
            </Link>
            <Button
              type="primary"
              disabled={projectPrice === 0}
              onClick={this.handleConfirm}
            >
              {GetText('common.continue')}
              {` (${projectPrice}€)`}
            </Button>
          </Row>
        </Spin>
      </Modal>
    );
  }
}

// Redux map
function mapStateToProps(state) {
  const { authentication, projects, edition, layouts } = state;
  const { user, rebranding } = authentication;
  const { project } = edition;

  // we need user and projects
  return {
    storeRef: state,
    user,
    rebranding,
    project,
    projects,
    layouts,
  };
}

// Redux connect
const reduxConnected = connect(mapStateToProps)(AlbumProjectCreationView);
export { reduxConnected as AlbumProjectCreationView };
