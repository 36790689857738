import * as Sentry from '@sentry/react';

/**
 * Log console errors and warnings in Sentry
 */
export const SetupReportingConsole = () => {
  // creating function declarations for better stacktraces (otherwise they'd be anonymous function expressions)
  const oldConsoleError = console.error;
  console.error = reportingConsoleError; // defined via function hoisting
  function reportingConsoleError() {
    const args = Array.prototype.slice.call(arguments);
    Sentry.captureException(reduceConsoleArgs(args), { level: 'error' });
    return oldConsoleError.apply(console, args);
  }

  const oldConsoleWarn = console.warn;
  console.warn = reportingConsoleWarn; // defined via function hoisting
  function reportingConsoleWarn() {
    const args = Array.prototype.slice.call(arguments);
    Sentry.captureMessage(reduceConsoleArgs(args), { level: 'warning' });
    return oldConsoleWarn.apply(console, args);
  }

  function reduceConsoleArgs(args) {
    let errorMsg = args[0];
    // Make sure errorMsg is either an error or string.
    // It's therefore best to pass in new Error('msg') instead of just 'msg' since
    // that'll give you a stack trace leading up to the creation of that new Error
    // whereas if you just pass in a plain string 'msg', the stack trace will include
    // reportingConsoleError and reportingConsoleCall
    if (!(errorMsg instanceof Error)) {
      // stringify all args as a new Error (which creates a stack trace)
      errorMsg = new Error(
        args.reduce(
          (accumulator, currentValue) =>
            `${accumulator.toString()} ${currentValue.toString()}`,
          ''
        )
      );
    }
    return errorMsg;
  }
};

export const sentryBeforeBreadCrumb = (breadcrumb) => {
  // check this
  // https://github.com/getsentry/sentry/issues/8139
  if (breadcrumb.category === 'console') {
    // remove logs from react that are not correctly formatted // TODO: maybe find a wayt to read this later
    if (breadcrumb.message.includes('%c')) return null;

    // reduce too long logs in the breadcrumb
    const maxLength = 250;
    if (breadcrumb.message.length > maxLength) {
      breadcrumb.message = `${breadcrumb.message.substring(0, maxLength)}...`;
    }
    breadcrumb.data = null;
  }
  return breadcrumb;
};
