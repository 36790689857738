import React from 'react';
import { connect } from 'react-redux';
import { Button, Spin, Modal, Select, Row } from 'antd';
import { cloneDeep } from 'lodash';
import { Link } from 'react-router-dom';
import type { IDocument, ProjectOptions } from '../../../types/types';
import { GetProjectDisplayPage } from '../../../utils/projectHelper';

import { editionActions } from '../../../feature/edition/edition';
import { GetProductCatalogue } from '../../../data/productCatalogue';
import { GetText } from '../../../data/LanguageHelper';
import {
  GetProjectDocSizeCM,
  GetDoc,
  GetPossibleUpgrades,
} from '../../../utils/ProductHelper';
import { popupHelper } from '../../../feature/alert/popupHelper';
import { CardHelper } from '../../../utils/card/CardHelper';
import { pricingSelectors } from '../../../feature/pricing/pricing';
import { history, ROUTE_CONST } from '../../../utils/history';
import { PROJECT_CONST } from '../../../data/config';
import { GetProjectOptions } from '../../../feature/project/GetProjectOptions';
import { CreateProject } from '../../../feature/project/CreateProject';

interface CardProjectCreationViewProps {
  isUpgrade: boolean;
  onCloseCallback: void;
}

class CardProjectCreationView extends React.Component<CardProjectCreationViewProps> {
  // --------------------- Constructor ------------------------

  constructor(props) {
    super(props);

    // bind functions
    this.handleProductChange = this.handleProductChange.bind(this);
    this.handleFormatChange = this.handleFormatChange.bind(this);
    this.handleSizeChange = this.handleSizeChange.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);

    // retreive catalogue
    let catalogue = GetProductCatalogue();
    // rebranding filter products
    if (
      !this.props.isUpgrade &&
      this.props.rebranding?.subProductFilter?.cards
    ) {
      catalogue.products = this.props.rebranding.subProductFilter.cards;
    }

    // ----  FILTER CATALOGUE FOR UPGRADES ----
    // in case of upgrade, we filter the catalogue
    if (this.props.project) {
      const filteredCatalogue = cloneDeep(catalogue);
      const projectDocID = this.props.project.docID;
      const projectDoc = GetDoc(projectDocID);
      const possibleUpgrades = GetPossibleUpgrades(projectDocID);

      // for (let productKey in catalogue.products) {
      Object.keys(catalogue.products).forEach((productKey) => {
        const productNode = catalogue.products[productKey];
        let keepProduct = false;
        // for (let formatKey in productNode.formats) {
        Object.keys(productNode.formats).forEach((formatKey) => {
          const formatNode = productNode.formats[formatKey];
          // keep only projects with the same amount of pages per group
          // return GetDoc(doc_id).pages_per_group === projectDoc.pages_per_group;
          const filteredDocs: Array = formatNode.docs.filter((doc_id) =>
            possibleUpgrades.includes(doc_id)
          );
          if (filteredDocs.length > 0) {
            filteredCatalogue.products[productKey].formats[formatKey].docs =
              cloneDeep(filteredDocs);
            keepProduct = true;
          } else
            delete filteredCatalogue.products[productKey].formats[formatKey];
        });
        if (!keepProduct) {
          delete filteredCatalogue.products[productKey];
        }
      });
      catalogue = filteredCatalogue;
    }
    // ---- END OF CATALOGUE FILTER ----

    const productList = catalogue.products;
    const productOptionKeys = Object.keys(productList).map(
      (key, index, arr) => key
    );

    this.state = {
      // whole card catalogue
      catalogue,

      // = type :postcard, anouncement, greetings, magnet
      product: null, // Current product selected
      productOptions: productOptionKeys, // WALL, DESK, ETC...

      // format : port, land, pano, square, bookmark
      format: null, // current format type selected
      formatOptions: null,

      // = docID
      size: null,
      sizeOptions: null,
    };
  }

  // --------------------- react ------------------------

  componentDidMount() {
    // automatically set the first product
    if (this.props.isUpgrade) {
      const { project } = this.props;
      const currentFormat = CardHelper.GetProjectFormat(project.docID);
      this.handleProductChange(project.type)
        .then(() => this.handleFormatChange(currentFormat))
        .then(() => this.handleSizeChange(project.docID));
    } // default
    else {
      this.handleProductChange(this.state.productOptions[0]);
    }
  }

  // --------------------- methods ------------------------

  handleClose = () => {
    // if not upgrade be sure to be on HOME
    if (!this.props.isUpgrade) {
      history.push(ROUTE_CONST.HOME);
    }

    // invoke callback if there is one
    this.props.onCloseCallback?.();
  };

  handleChange(value) {
    console.log(`selected ${value}`);
  }

  handleProductChange(val): Promise {
    return new Promise((resolve, reject) => {
      const product = this.state.catalogue.products[val];

      // find format options
      const formatOptions: Array = Object.keys(product.formats);
      const currentFormat =
        this.state.format && formatOptions.includes(currentFormat)
          ? this.state.format
          : product.default;

      this.setState(
        {
          product: val,
          formatOptions,
          format: currentFormat,
        },
        () => {
          this.handleFormatChange(currentFormat).then(() => resolve());
        }
      );
    });
  }

  handleFormatChange(val): Promise {
    return new Promise((resolve, reject) => {
      const product = this.state.catalogue.products[this.state.product];

      // find possible size/docID
      const formatNode = product.formats[val];
      const sizeOptions = cloneDeep(formatNode.docs);
      const currentSize =
        this.state.size && sizeOptions.includes(currentSize)
          ? this.state.size
          : formatNode.default;

      this.setState(
        {
          format: val,
          sizeOptions,
          size: currentSize,
        },
        () => {
          this.handleSizeChange(currentSize).then(() => resolve());
        }
      ); // after setting the product, update also the size
    });
  }

  handleSizeChange(val): Promise {
    return new Promise((resolve, reject) => {
      const doc: IDocument = this.state.catalogue.docs[val];

      this.setState(
        {
          size: val,
        },
        () => {
          resolve();
        }
      );
    });
  }

  handleConfirm() {
    // --------------------- UPGRADE ------------------------
    // case upgrade
    if (this.props.isUpgrade) {
      const onConfirm = () => {
        this.props.dispatch(
          editionActions.UpgradeCurrentProject({
            type: this.state.product,
            docID: this.state.size,
            numPages: this.state.numPages, // TODO: no num pages???
            options: GetProjectOptions(this.props.project),
          })
        );
        this.handleClose();
      };
      const currentProjectPages = GetProjectDisplayPage(this.props.project);
      if (currentProjectPages > this.state.pages) {
        const numPagesDeleted = currentProjectPages - this.state.pages;
        popupHelper.showChangePageDeleteWarning(numPagesDeleted, onConfirm);
      } else {
        onConfirm();
      }

      return;
    }
    // ----- END OF UPGRADE ------

    const docID = this.state.size;
    const doc = GetDoc(docID);
    const cardPack = doc.packs_default; // TODO: change this if we have the dropdown in this view!
    const numOfPages = CardHelper.GetTotalNumberOfPages(docID, cardPack);

    // ---------------------  NEW PROJECT  ------------------------
    // create new project
    const proj = CreateProject({
      classname: PROJECT_CONST.project_class,
      docID,
      type: this.state.product,
      projectName: 'new card project', // TODO:
      numPages: numOfPages,
      layoutStore: this.props.layouts,
      options: {
        cardPack,
        envelope: doc.envelopes_default,
      },
    });

    // update state
    this.setState({
      project: proj,
    });

    popupHelper.showRenameProjectPopup(
      // success
      (newProjectName) => {
        const { project } = this.state;
        project.name = newProjectName;
        this.props.dispatch(editionActions.CreateProject(cloneDeep(project)));
      },
      // cancel
      () => {
        // nothing
      }
    );
  }

  getProjectPrice() {
    let options: ProjectOptions = this.props.project
      ? GetProjectOptions(this.props.project)
      : {};
    const docID = this.state.size;

    if (docID) {
      const doc = GetDoc(this.state.size);
      const cardPack = doc.packs_default; // TODO: change this if we have the dropdown in this view!
      const numOfPages = CardHelper.GetTotalNumberOfPages(docID, cardPack);

      options = {
        ...options,
        ...{
          docID,
          type: this.state.product,
          numPages: numOfPages,
          cardPack,
          envelope: options.envelope ? options.envelope : doc.envelopes_default,
        },
      };

      // securities
      // TODO: put this outside in a generic CheckProjectOptions
      if (!doc.envelopes.includes(options.envelope))
        options.envelope = doc.envelopes_default;
      if (options.matte && !doc.finish.includes('mate')) options.matte = false;
      if (options.coated && !doc.finish.includes('coating'))
        options.coated = false;

      return pricingSelectors.GetProjectPrice(this.props.storeRef, options);
    }
    return 0;
  }

  // --------------------- render ------------------------

  render() {
    const { user, projects } = this.props;
    const currentProject = this.props.project;
    const projectClass = PROJECT_CONST.project_class;
    const { Option } = Select;

    const previewURL = `${process.env.PUBLIC_URL}/images/ui/menu/lefttabs/project/${projectClass}/types_${projectClass}_${this.state.product}_${this.state.size}.jpg`;
    const projectPrice = this.getProjectPrice();

    return (
      <Modal
        title={
          this.props.isUpgrade
            ? GetText('upgradeProject.title')
            : GetText('newProject.title')
        }
        open
        footer={null}
        width="600px"
        onCancel={this.handleClose}
      >
        <Spin spinning={projects.loading}>
          <h3>{GetText('newProject.card.desc')}</h3>

          {/** ---- PRODUCT TYPE (postcard, annoucement, greetings, etc.. ) ------ */}
          <h4>{GetText('card.type')}</h4>
          <Select
            style={{ width: 400 }}
            value={this.state.product}
            placeholder="Select a Product"
            onChange={this.handleProductChange}
          >
            {this.state.productOptions.map((key) => (
              <Option key={key} value={key}>
                {GetText(`card.type.types_cards_${key}`)}
              </Option>
            ))}
          </Select>

          <div className="spacerV" />

          {/** ---- PRODUCT FORMAT (port, land, pano, square, bookmark ) ------ */}
          <h4>{GetText('card.format')}</h4>
          <Select
            disabled={
              !this.state.format ||
              (this.state.formatOptions && this.state.formatOptions.length < 2)
            }
            style={{ width: 400 }}
            value={this.state.format}
            onChange={this.handleFormatChange}
          >
            {this.state.formatOptions &&
              this.state.formatOptions.map((key) => (
                <Option key={key} value={key}>
                  {GetText(`card.format.format_${key}`)}
                </Option>
              ))}
          </Select>

          <div className="spacerV" />

          {/** ---- Product size (=== doc ID )------ */}
          <h4>{GetText('card.size')}</h4>
          <Select
            disabled={!this.state.size}
            value={this.state.size}
            style={{ width: 400 }}
            onChange={this.handleSizeChange}
          >
            {this.state.sizeOptions &&
              this.state.sizeOptions.map((key) => (
                <Option key={key} value={key}>
                  {GetText(`card.prefix.${key}`)} ({GetProjectDocSizeCM(key)})
                </Option>
              ))}
          </Select>

          <div className="spacerV" />
          <div className="spacerV" />

          {/** -----------  PREVIEW ----------- */}

          <Row className="preview">
            <img src={previewURL} />
          </Row>

          <Row type="flex" justify="space-between">
            <Link to={ROUTE_CONST.SELECT_PROJECT_CLASS}>
              <Button type="link">
                {GetText('newProject.changeEditorClass.button')}
              </Button>
            </Link>
            <Button
              type="primary"
              disabled={projectPrice === 0}
              onClick={this.handleConfirm}
            >
              {GetText('common.continue')}
              {` (${projectPrice}€)`}
            </Button>
          </Row>
        </Spin>
      </Modal>
    );
  }
}

// Redux map
function mapStateToProps(state) {
  const { authentication, projects, edition, layouts } = state;
  const { user, rebranding } = authentication;
  const { project } = edition;

  // we need user and projects
  return {
    storeRef: state,
    user,
    rebranding,
    project,
    projects,
    layouts,
  };
}

// Redux connect
const reduxConnected = connect(mapStateToProps)(CardProjectCreationView);
export { reduxConnected as CardProjectCreationView };
