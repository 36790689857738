export const DRAG_TYPE = {
  PHOTO: 'drag_type_photo',
  BACKGROUND: 'drag_type_background',
  LAYOUT: 'drag_type_layout',
  CLIPART: 'drag_type_clipart',
  OVERLAYER: 'drag_type_overlayer',
  NAVIGATOR_ITEM: 'drag_type_navigator_item',
  NAVIGATOR_GROUP: 'drag_type_navigator_group',
};

/**
 * Creates drag data for data transfer
 * @param {*} dragType
 * @param {*} objectString :> data transfer object can only contain string data.. but we could store the drag data inside redux store if needed
 */
export function AddDragData(
  evt: DragEvent,
  dragType: string,
  objectString: string
): void {
  evt.dataTransfer.setData(dragType, objectString);
}
export function GetDragData(evt: DragEvent, dragType: string): string {
  return evt.dataTransfer.getData(dragType);
}
