import React from 'react';
import { API } from '../utils/API';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error.message };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error: error.message, details: JSON.stringify(errorInfo) });

    // log..
    // API.sendDevMail(error, JSON.stringify(errorInfo), false); // already working actualy with the global error catching..

    // alert("Error catched in boundary: "+JSON.stringify(error) +"("+ JSON.stringify(errorInfo)+")");
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            padding: '10%',
            backgroundColor: 'red',
            color: 'white',
          }}
        >
          <h1>Something went wrong.</h1>
          <h2>Error:</h2>
          <h5>{this.state.error}</h5>
          <h2>Details:</h2>
          <h5>{this.state.details}</h5>
        </div>
      );
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
