export const IsLocalhost = `${window.location}`.includes('localhost');
export const IsDebug =
  `${window.location}`.includes('debug') ||
  `${window.location}`.includes('editor2dev') ||
  IsLocalhost;

export const DebugFlags = {
  DEBUGGING: IsDebug, // allow debugging options
  IS_LOCALHOST: IsLocalhost, // is local host

  STORE_REDUX_STATE_AT_EACH_CHANGE: false, // store redux state on a cookie!
  USE_PERSISTED_STATE_COOKIE: false, // Do we store state in a cookie to reopen exactly at the same stage afterwards

  // SESSIOn
  USE_SESSION_LOCAL_STORAGE: false,
  SIMULATE_SESSION_PRODUCT: false,

  // language
  DISPLAY_LANGUAGE_LINKS: false,

  // PHOTOS
  AUTO_FILL_LOGIN: null, // IsLocalhost? {user:"anthodb@gmail.com", pass:"coucou"} : null, // {user:"anthodb@gmail.com", pass:"coucou"},
  RANDOM_EMPTY_IMAGE: false,
  DIRECT_LOAD_PROJECT: null, // 269809

  // LAYOUT
  APPLY_TO_ALL_WHEN_SAVING_CUSTOM_LAYOUT: false,

  // API
  OPEN_SERVICE_RESULT_IN_NEW_WINDOW: false,

  // UPLOAD
  CLEAN_UPLOAD_AT_START: true, // clean all temp items at start
  UPLOAD_DELAYED: IsLocalhost ? 1 : 0, // delay (in seconds) before the image start uploading after import, allowing to test "uploadding" projects!
  DO_NOT_CHECK_DUPLICATES: IsLocalhost, // Set to true to avoid dupplicate check via MD5

  // UI
  ALLOW_ALL_FRAME_SELECTION: IsLocalhost, // allow to select all frame for debugging
  USE_TRANSFORM_TOOL_DEBUG: false, // display additionnal transforml tool debug information
  PHOTOAREA_SHOW_SELECTBOX: false,
  DO_NOT_SHOW_OTHER_PRODUCTS: false,

  // REBRANDING
  SIMULATE_REBRANDING: false,

  // ERROR / FEEDBACK
  SIMULATE_ERROR_ON_SAVE: false,
  SIMULATE_ERROR_ON_FRAME_LOAD: false,

  // ---- CALENDAR ----
  PRINT_ALL_CALENDAR_FRAME_WITH_STROKE: false,
  DO_NOT_ALLOW_CALENDAR_UPGRADES: false,
  ALLOW_CALENDAR_XL_UPGRADES: IsDebug,

  // ORDER
  ORDER_TO_PDF: false, // IsLocalhost,
  PDF_JOB_ID: '99998',
  ORDER_NO_COVER: false,
  PRINT_TEXT_WITHOUT_PNG: false,
  PRINT_ALL_FRAMES_WITH_STROKE: false,
  ORDER_DO_NOT_SAVE_BEFORE_ORDER: false,
  PDF_PRINT_SPECIFIC_PAGES: '6,7', // "1",// null "1,2,3"
  DOWNLOAD_EXPORTED_FRAMES: IsLocalhost, // IsLocalhost, // download exported frame in browser
  PRINT_DEMO: false, // use the "printDemo" function of orderHelper, allow to test print system..
  DISPLAY_FEEDBACK_BEFORE_ORDER_REDIRECT: false,
};

if (`${window.location}`.includes('editor2.jtlf.fr')) {
  DebugFlags.DO_NOT_SHOW_OTHER_PRODUCTS = true;
}

window.TODO = function (message) {
  alert(message);
};
