import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App/App';

// CSS
// import './reset.css'; TODO: this doesn't actually work as once built, the css files are merged.
// This is a real issue and must be fixed with async loading or javascript css (or sass)
import './index.css';
import './font_embed.css';
import { store } from './utils/store';
import {
  sentryBeforeBreadCrumb,
  SetupReportingConsole,
} from './debug/SentryReportingConsole';

// app monitoring using Sentry
// check this out to add source map: https://medium.com/@vshab/create-react-app-and-sentry-cde1f15cbaa
Sentry.init({
  dsn: 'https://8ee1d4733342442a991b073cb73517f9@o879385.ingest.sentry.io/5832020',
  integrations: [new Integrations.BrowserTracing()],
  release: process.env.REACT_APP_VERSION,
  beforeBreadcrumb(breadcrumb) {
    return sentryBeforeBreadCrumb(breadcrumb);
  },
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.5,
});
SetupReportingConsole();

// render in DOM
render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
